@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./typography/fonts.scss";
@import "./typography/icons.scss";
@import "swiper/swiper-bundle.css";
// needs codylab rework
@layer base {
    html,
    body {
        @apply text-black-700;
        font-family: $font;
        -webkit-font-smoothing: antialiased;
    }
    h1 {
        @apply text-2xl font-bold;
    }
    h2 {
        @apply text-xl font-bold;
    }
    h3 {
        @apply text-lg font-bold;
    }
    h4 {
        @apply text-base;
    }
    small {
        @apply text-xs leading-3;
    }
    h1,
    h2,
    h3 {
        @apply text-brandedDarkGrey-700;
    }
    h4 {
        @apply text-brandedDarkGrey-500;
    }
    p,
    ul,
    ol,
    li {
        @apply text-brandedDarkGrey-500 text-sm;
    }
    ul,
    ol {
        list-style-position: inside;
        li::marker {
            @apply text-brandedPrimary-600;
            margin-right: theme("margin.1");
            // content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
            // font-weight: bold; /* If you want it to be bold */
            // display: inline-block; /* Needed to add space between the bullet and the text */
            // width: 1em; /* Also needed for space (tweak if needed) */
            // margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
    }
    pre {
        white-space: inherit;
    }
}
html,
body,
main {
    height: 100%;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #9e9e9e #ffffff;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 14px;
}
*::-webkit-scrollbar-track {
    background: #ffffff6b;
}
*::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
    border: 4px solid #ffffff;
}
// Move to components
.card-container {
    display: grid;
    gap: theme("spacing.6");
    grid-template-columns: repeat(auto-fill, minmax(theme("templateCards.cardMinWidth"), 1fr));
}
.msc-banner {
    position: relative;
    .msc-banner__gradient {
        position: absolute;
        top: calc(0rem - theme("padding.8"));
        left: calc(0rem - theme("padding.8"));
        z-index: -1;
        height: 300px;
        width: calc(100% + 2 * theme("padding.8"));
        margin: 0;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: linear-gradient(160deg, theme("colors.primary.200"), theme("colors.primary.600"));
            opacity: 0.9;
        }
        & > div {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
        }
    }
}
.breadcrumb-v2 {
    display: flex;
    gap: theme("spacing.2");
    align-items: center;
    &--back {
        cursor: pointer;
        &:hover {
            color: theme("colors.primary.500");
        }
    }
}
.menu-actions {
    box-shadow: theme("boxShadow.md");
    border-radius: theme("borderRadius.md");
    background-color: theme("colors.white");
    border: none;
    width: fit-content;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    animation: fadeDown 0.15s ease;
    li {
        padding: theme("padding.2") theme("padding.3");
        gap: theme("spacing.2");
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
            font-size: theme("fontSize.sm");
        }
        &:hover,
        &.active {
            background-color: theme("colors.gray.100");
            color: theme("colors.primary.500");
        }
    }
}

.transactionEditForm {
    .mt-1 {
        display: none !important;
    }
}

#chatbase-bubble-button {
    display: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 2px;
}

#chatbase-message-bubbles {
    display: none !important;
}
