@font-face {
    font-family: 'myskillcamp';
    src: url('./icons/myskillcamp.eot?5055492');
    src: url('./icons/myskillcamp.eot?5055492#iefix') format('embedded-opentype'),
    url('./icons/myskillcamp.woff2?5055492') format('woff2'),
    url('./icons/myskillcamp.woff?5055492') format('woff'),
    url('./icons/myskillcamp.ttf?5055492') format('truetype'),
    url('./icons/myskillcamp.svg?5055492#myskillcamp') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: 'myskillcamp';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-activity-1:before { content: '\e800'; } /* '' */
.icon-add-message:before { content: '\e801'; } /* '' */
.icon-alert-circle:before { content: '\e802'; } /* '' */
.icon-alert-triangle:before { content: '\e803'; } /* '' */
.icon-all-done:before { content: '\e804'; } /* '' */
.icon-archive:before { content: '\e805'; } /* '' */
.icon-arrow-circle-down:before { content: '\e806'; } /* '' */
.icon-arrow-circle-left:before { content: '\e807'; } /* '' */
.icon-arrow-circle-right:before { content: '\e808'; } /* '' */
.icon-arrow-circle-up:before { content: '\e809'; } /* '' */
.icon-arrow-down:before { content: '\e80a'; } /* '' */
.icon-arrow-ios-down:before { content: '\e80b'; } /* '' */
.icon-arrow-ios-left:before { content: '\e80c'; } /* '' */
.icon-arrow-ios-right:before { content: '\e80d'; } /* '' */
.icon-arrow-ios-up:before { content: '\e80e'; } /* '' */
.icon-arrow-left:before { content: '\e80f'; } /* '' */
.icon-arrow-right:before { content: '\e810'; } /* '' */
.icon-arrow-up:before { content: '\e811'; } /* '' */
.icon-arrowhead-down:before { content: '\e812'; } /* '' */
.icon-arrowhead-left:before { content: '\e813'; } /* '' */
.icon-arrowhead-right:before { content: '\e814'; } /* '' */
.icon-arrowhead-up:before { content: '\e815'; } /* '' */
.icon-arrrow-left:before { content: '\e816'; } /* '' */
.icon-at:before { content: '\e817'; } /* '' */
.icon-attach-2:before { content: '\e818'; } /* '' */
.icon-attach:before { content: '\e819'; } /* '' */
.icon-award:before { content: '\e81a'; } /* '' */
.icon-backspace:before { content: '\e81b'; } /* '' */
.icon-bar-chart-2:before { content: '\e81c'; } /* '' */
.icon-bar-chart:before { content: '\e81d'; } /* '' */
.icon-battery:before { content: '\e81e'; } /* '' */
.icon-batttery-charging:before { content: '\e81f'; } /* '' */
.icon-behance:before { content: '\e820'; } /* '' */
.icon-bell-off:before { content: '\e821'; } /* '' */
.icon-bell:before { content: '\e822'; } /* '' */
.icon-bluetooth:before { content: '\e823'; } /* '' */
.icon-book-open:before { content: '\e824'; } /* '' */
.icon-book:before { content: '\e825'; } /* '' */
.icon-bookmark:before { content: '\e826'; } /* '' */
.icon-briefcase:before { content: '\e827'; } /* '' */
.icon-browser:before { content: '\e828'; } /* '' */
.icon-brush:before { content: '\e829'; } /* '' */
.icon-bulb:before { content: '\e82a'; } /* '' */
.icon-bullet-point:before { content: '\e82b'; } /* '' */
.icon-calendar-add:before { content: '\e82c'; } /* '' */
.icon-calendar:before { content: '\e82d'; } /* '' */
.icon-camp:before { content: '\e82e'; } /* '' */
.icon-camera:before { content: '\e82f'; } /* '' */
.icon-car:before { content: '\e830'; } /* '' */
.icon-cast:before { content: '\e831'; } /* '' */
.icon-catalogue:before { content: '\e832'; } /* '' */
.icon-checkmark-circle-2:before { content: '\e833'; } /* '' */
.icon-checkmark-circle:before { content: '\e834'; } /* '' */
.icon-checkmark-square-2:before { content: '\e835'; } /* '' */
.icon-checkmark-square:before { content: '\e836'; } /* '' */
.icon-checkmark:before { content: '\e837'; } /* '' */
.icon-chevron-down:before { content: '\e838'; } /* '' */
.icon-chevron-left:before { content: '\e839'; } /* '' */
.icon-chevron-right:before { content: '\e83a'; } /* '' */
.icon-chevron-up:before { content: '\e83b'; } /* '' */
.icon-clipboard:before { content: '\e83c'; } /* '' */
.icon-clock:before { content: '\e83d'; } /* '' */
.icon-close-circle:before { content: '\e83e'; } /* '' */
.icon-close:before { content: '\e83f'; } /* '' */
.icon-cloud-download:before { content: '\e840'; } /* '' */
.icon-cloud-upload:before { content: '\e841'; } /* '' */
.icon-code-download:before { content: '\e842'; } /* '' */
.icon-code:before { content: '\e843'; } /* '' */
.icon-collapse:before { content: '\e844'; } /* '' */
.icon-color-palette:before { content: '\e845'; } /* '' */
.icon-color-picker:before { content: '\e846'; } /* '' */
.icon-compass:before { content: '\e847'; } /* '' */
.icon-content:before { content: '\e848'; } /* '' */
.icon-copy:before { content: '\e849'; } /* '' */
.icon-corner-down-left:before { content: '\e84a'; } /* '' */
.icon-corner-down-right:before { content: '\e84b'; } /* '' */
.icon-corner-left-down:before { content: '\e84c'; } /* '' */
.icon-corner-left-up:before { content: '\e84d'; } /* '' */
.icon-corner-right-down:before { content: '\e84e'; } /* '' */
.icon-corner-right-up:before { content: '\e84f'; } /* '' */
.icon-corner-up-left:before { content: '\e850'; } /* '' */
.icon-corner-up-right:before { content: '\e851'; } /* '' */
.icon-credit-card:before { content: '\e852'; } /* '' */
.icon-crop:before { content: '\e853'; } /* '' */
.icon-cube:before { content: '\e854'; } /* '' */
.icon-dashboard:before { content: '\e855'; } /* '' */
.icon-diagonal-arrow-left-down:before { content: '\e856'; } /* '' */
.icon-diagonal-arrow-left-up:before { content: '\e857'; } /* '' */
.icon-diagonal-arrow-right-down:before { content: '\e858'; } /* '' */
.icon-diagonal-arrow-right-up:before { content: '\e859'; } /* '' */
.icon-download:before { content: '\e85a'; } /* '' */
.icon-droplet-off:before { content: '\e85b'; } /* '' */
.icon-droplet:before { content: '\e85c'; } /* '' */
.icon-edit-2:before { content: '\e85d'; } /* '' */
.icon-edit:before { content: '\e85e'; } /* '' */
.icon-email:before { content: '\e85f'; } /* '' */
.icon-external-link:before { content: '\e860'; } /* '' */
.icon-expand:before { content: '\e861'; } /* '' */
.icon-eye-off-2:before { content: '\e862'; } /* '' */
.icon-eye-off:before { content: '\e863'; } /* '' */
.icon-eye:before { content: '\e864'; } /* '' */
.icon-facebook:before { content: '\e865'; } /* '' */
.icon-file-add:before { content: '\e866'; } /* '' */
.icon-file-remove:before { content: '\e867'; } /* '' */
.icon-file-text:before { content: '\e868'; } /* '' */
.icon-file:before { content: '\e869'; } /* '' */
.icon-film:before { content: '\e86a'; } /* '' */
.icon-fire:before { content: '\e86b'; } /* '' */
.icon-flag:before { content: '\e86c'; } /* '' */
.icon-flash-off:before { content: '\e86d'; } /* '' */
.icon-flash:before { content: '\e86e'; } /* '' */
.icon-flip-2:before { content: '\e86f'; } /* '' */
.icon-flip:before { content: '\e870'; } /* '' */
.icon-folder-add:before { content: '\e871'; } /* '' */
.icon-folder-remove:before { content: '\e872'; } /* '' */
.icon-folder:before { content: '\e873'; } /* '' */
.icon-funnel:before { content: '\e874'; } /* '' */
.icon-gift:before { content: '\e875'; } /* '' */
.icon-github:before { content: '\e876'; } /* '' */
.icon-globe-2:before { content: '\e877'; } /* '' */
.icon-globe:before { content: '\e878'; } /* '' */
.icon-google:before { content: '\e879'; } /* '' */
.icon-grid:before { content: '\e87a'; } /* '' */
.icon-hard-drive:before { content: '\e87b'; } /* '' */
.icon-hash:before { content: '\e87c'; } /* '' */
.icon-headphones:before { content: '\e87d'; } /* '' */
.icon-heart:before { content: '\e87e'; } /* '' */
.icon-height:before { content: '\e87f'; } /* '' */
.icon-home:before { content: '\e880'; } /* '' */
.icon-image:before { content: '\e881'; } /* '' */
.icon-inbox:before { content: '\e882'; } /* '' */
.icon-info:before { content: '\e883'; } /* '' */
.icon-keypad:before { content: '\e884'; } /* '' */
.icon-layers:before { content: '\e885'; } /* '' */
.icon-layout:before { content: '\e886'; } /* '' */
.icon-like-social:before { content: '\e887'; } /* '' */
.icon-link-2:before { content: '\e888'; } /* '' */
.icon-link:before { content: '\e889'; } /* '' */
.icon-linkedin:before { content: '\e88a'; } /* '' */
.icon-list:before { content: '\e88b'; } /* '' */
.icon-loader:before { content: '\e88c'; } /* '' */
.icon-lock:before { content: '\e88d'; } /* '' */
.icon-log-in:before { content: '\e88e'; } /* '' */
.icon-log-out:before { content: '\e88f'; } /* '' */
.icon-logo-myskillcamp:before { content: '\e890'; } /* '' */
.icon-map:before { content: '\e891'; } /* '' */
.icon-maximize:before { content: '\e892'; } /* '' */
.icon-mentee:before { content: '\e893'; } /* '' */
.icon-mentor:before { content: '\e894'; } /* '' */
.icon-menu-2:before { content: '\e895'; } /* '' */
.icon-menu-arrow:before { content: '\e896'; } /* '' */
.icon-menu:before { content: '\e897'; } /* '' */
.icon-message-circle:before { content: '\e898'; } /* '' */
.icon-message-square:before { content: '\e899'; } /* '' */
.icon-mic-off:before { content: '\e89a'; } /* '' */
.icon-mic:before { content: '\e89b'; } /* '' */
.icon-minimize:before { content: '\e89c'; } /* '' */
.icon-minus-circle:before { content: '\e89d'; } /* '' */
.icon-minus-square:before { content: '\e89e'; } /* '' */
.icon-minus:before { content: '\e89f'; } /* '' */
.icon-monitor:before { content: '\e8a0'; } /* '' */
.icon-moon:before { content: '\e8a1'; } /* '' */
.icon-more-horizontal:before { content: '\e8a2'; } /* '' */
.icon-more-vertical:before { content: '\e8a3'; } /* '' */
.icon-move:before { content: '\e8a4'; } /* '' */
.icon-music:before { content: '\e8a5'; } /* '' */
.icon-navigation-2:before { content: '\e8a6'; } /* '' */
.icon-navigation:before { content: '\e8a7'; } /* '' */
.icon-npm:before { content: '\e8a8'; } /* '' */
.icon-options-2:before { content: '\e8a9'; } /* '' */
.icon-options:before { content: '\e8aa'; } /* '' */
.icon-pantone:before { content: '\e8ab'; } /* '' */
.icon-paper-plane:before { content: '\e8ac'; } /* '' */
.icon-pause-circle:before { content: '\e8ad'; } /* '' */
.icon-people:before { content: '\e8ae'; } /* '' */
.icon-percent:before { content: '\e8af'; } /* '' */
.icon-person-add:before { content: '\e8b0'; } /* '' */
.icon-person-delete:before { content: '\e8b1'; } /* '' */
.icon-person-done:before { content: '\e8b2'; } /* '' */
.icon-person-remove:before { content: '\e8b3'; } /* '' */
.icon-person:before { content: '\e8b4'; } /* '' */
.icon-phone-call:before { content: '\e8b5'; } /* '' */
.icon-phone-missed:before { content: '\e8b6'; } /* '' */
.icon-phone-off:before { content: '\e8b7'; } /* '' */
.icon-phone:before { content: '\e8b8'; } /* '' */
.icon-pie-chart:before { content: '\e8b9'; } /* '' */
.icon-pin:before { content: '\e8ba'; } /* '' */
.icon-play-circle:before { content: '\e8bb'; } /* '' */
.icon-plus-circle:before { content: '\e8bc'; } /* '' */
.icon-plus-square:before { content: '\e8bd'; } /* '' */
.icon-plus:before { content: '\e8be'; } /* '' */
.icon-power:before { content: '\e8bf'; } /* '' */
.icon-pricetag:before { content: '\e8c0'; } /* '' */
.icon-printer:before { content: '\e8c1'; } /* '' */
.icon-question-mark-circle:before { content: '\e8c2'; } /* '' */
.icon-question-mark:before { content: '\e8c3'; } /* '' */
.icon-radio-button-off:before { content: '\e8c4'; } /* '' */
.icon-radio-button-on:before { content: '\e8c5'; } /* '' */
.icon-radio:before { content: '\e8c6'; } /* '' */
.icon-recording:before { content: '\e8c7'; } /* '' */
.icon-refresh:before { content: '\e8c8'; } /* '' */
.icon-repeat:before { content: '\e8c9'; } /* '' */
.icon-rewind-left:before { content: '\e8ca'; } /* '' */
.icon-rewind-right:before { content: '\e8cb'; } /* '' */
.icon-save:before { content: '\e8cc'; } /* '' */
.icon-scissors:before { content: '\e8cd'; } /* '' */
.icon-search:before { content: '\e8ce'; } /* '' */
.icon-settings-2:before { content: '\e8cf'; } /* '' */
.icon-settings:before { content: '\e8d0'; } /* '' */
.icon-shake:before { content: '\e8d1'; } /* '' */
.icon-share:before { content: '\e8d2'; } /* '' */
.icon-shield-off:before { content: '\e8d3'; } /* '' */
.icon-shield:before { content: '\e8d4'; } /* '' */
.icon-shopping-bag:before { content: '\e8d5'; } /* '' */
.icon-shopping-cart:before { content: '\e8d6'; } /* '' */
.icon-shuffle-2:before { content: '\e8d7'; } /* '' */
.icon-shuffle:before { content: '\e8d8'; } /* '' */
.icon-skip-back:before { content: '\e8d9'; } /* '' */
.icon-skip-forward:before { content: '\e8da'; } /* '' */
.icon-slash:before { content: '\e8db'; } /* '' */
.icon-smartphone:before { content: '\e8dc'; } /* '' */
.icon-speaker:before { content: '\e8dd'; } /* '' */
.icon-square:before { content: '\e8de'; } /* '' */
.icon-star:before { content: '\e8df'; } /* '' */
.icon-stop-circle:before { content: '\e8e0'; } /* '' */
.icon-sun:before { content: '\e8e1'; } /* '' */
.icon-swap:before { content: '\e8e2'; } /* '' */
.icon-sync:before { content: '\e8e3'; } /* '' */
.icon-text:before { content: '\e8e4'; } /* '' */
.icon-thermometer-minus:before { content: '\e8e5'; } /* '' */
.icon-thermometer-plus:before { content: '\e8e6'; } /* '' */
.icon-thermometer:before { content: '\e8e7'; } /* '' */
.icon-timer:before { content: '\e8e8'; } /* '' */
.icon-toggle-left:before { content: '\e8e9'; } /* '' */
.icon-toggle-right:before { content: '\e8ea'; } /* '' */
.icon-trash-2:before { content: '\e8eb'; } /* '' */
.icon-trash:before { content: '\e8ec'; } /* '' */
.icon-trending-down:before { content: '\e8ed'; } /* '' */
.icon-trending-up:before { content: '\e8ee'; } /* '' */
.icon-tv:before { content: '\e8ef'; } /* '' */
.icon-twitter:before { content: '\e8f0'; } /* '' */
.icon-umbrella:before { content: '\e8f1'; } /* '' */
.icon-undo:before { content: '\e8f2'; } /* '' */
.icon-unlock:before { content: '\e8f3'; } /* '' */
.icon-upload:before { content: '\e8f4'; } /* '' */
.icon-video-off:before { content: '\e8f5'; } /* '' */
.icon-video:before { content: '\e8f6'; } /* '' */
.icon-volume-down:before { content: '\e8f7'; } /* '' */
.icon-volume-off:before { content: '\e8f8'; } /* '' */
.icon-volume-up:before { content: '\e8f9'; } /* '' */
.icon-volume:before { content: '\e8fa'; } /* '' */
.icon-weight:before { content: '\e8fb'; } /* '' */
.icon-wifi-off:before { content: '\e8fc'; } /* '' */
.icon-wifi:before { content: '\e8fd'; } /* '' */
.icon-activity:before { content: '\e8fe'; } /* '' */
.icon-alert-circle-1:before { content: '\e8ff'; } /* '' */
.icon-alert-triangle-1:before { content: '\e900'; } /* '' */
.icon-all-done-1:before { content: '\e901'; } /* '' */
.icon-archive-1:before { content: '\e902'; } /* '' */
.icon-arrow-circle-down-1:before { content: '\e903'; } /* '' */
.icon-arrow-circle-left-1:before { content: '\e904'; } /* '' */
.icon-arrow-circle-right-1:before { content: '\e905'; } /* '' */
.icon-arrow-circle-up-1:before { content: '\e906'; } /* '' */
.icon-arrow-down-1:before { content: '\e907'; } /* '' */
.icon-arrow-ios-down-1:before { content: '\e908'; } /* '' */
.icon-arrow-ios-left-1:before { content: '\e909'; } /* '' */
.icon-arrow-ios-right-1:before { content: '\e90a'; } /* '' */
.icon-arrow-ios-up-1:before { content: '\e90b'; } /* '' */
.icon-arrow-left-1:before { content: '\e90c'; } /* '' */
.icon-arrow-right-1:before { content: '\e90d'; } /* '' */
.icon-arrow-up-1:before { content: '\e90e'; } /* '' */
.icon-arrowhead-down-1:before { content: '\e90f'; } /* '' */
.icon-arrowhead-left-1:before { content: '\e910'; } /* '' */
.icon-arrowhead-right-1:before { content: '\e911'; } /* '' */
.icon-arrowhead-up-1:before { content: '\e912'; } /* '' */
.icon-arrrow-left-1:before { content: '\e913'; } /* '' */
.icon-at-1:before { content: '\e914'; } /* '' */
.icon-attach-1:before { content: '\e915'; } /* '' */
.icon-attach-3:before { content: '\e916'; } /* '' */
.icon-award-1:before { content: '\e917'; } /* '' */
.icon-backspace-1:before { content: '\e918'; } /* '' */
.icon-bar-chart-1:before { content: '\e919'; } /* '' */
.icon-bar-chart-3:before { content: '\e91a'; } /* '' */
.icon-battery-1:before { content: '\e91b'; } /* '' */
.icon-batttery-charging-1:before { content: '\e91c'; } /* '' */
.icon-behance-1:before { content: '\e91d'; } /* '' */
.icon-bell-off-1:before { content: '\e91e'; } /* '' */
.icon-bell-1:before { content: '\e91f'; } /* '' */
.icon-bluetooth-1:before { content: '\e920'; } /* '' */
.icon-bold:before { content: '\e921'; } /* '' */
.icon-book-open-1:before { content: '\e922'; } /* '' */
.icon-book-1:before { content: '\e923'; } /* '' */
.icon-bookmark-1:before { content: '\e924'; } /* '' */
.icon-briefcase-1:before { content: '\e925'; } /* '' */
.icon-browser-1:before { content: '\e926'; } /* '' */
.icon-brush-1:before { content: '\e927'; } /* '' */
.icon-bulb-1:before { content: '\e928'; } /* '' */
.icon-student:before { content: '\ea00'; } /* '' */
.icon-student-1:before { content: '\e929'; } /* '' */
.icon-calendar-add-1:before { content: '\e92a'; } /* '' */
.icon-calendar-1:before { content: '\e92b'; } /* '' */
.icon-camera-1:before { content: '\e92c'; } /* '' */
.icon-camp-1:before { content: '\e92d'; } /* '' */
.icon-cast-1:before { content: '\e92e'; } /* '' */
.icon-car-1:before { content: '\e92f'; } /* '' */
.icon-catalogue-1:before { content: '\e930'; } /* '' */
.icon-checkmark-circle-1:before { content: '\e931'; } /* '' */
.icon-checkmark-circle-3:before { content: '\e932'; } /* '' */
.icon-checkmark-square-copy:before { content: '\e933'; } /* '' */
.icon-checkmark-square-1:before { content: '\e934'; } /* '' */
.icon-checkmark-1:before { content: '\e935'; } /* '' */
.icon-chevron-left-1:before { content: '\e936'; } /* '' */
.icon-chevron-down-1:before { content: '\e937'; } /* '' */
.icon-chevron-right-1:before { content: '\e938'; } /* '' */
.icon-chevron-up-1:before { content: '\e939'; } /* '' */
.icon-clipboard-1:before { content: '\e93a'; } /* '' */
.icon-clock-1:before { content: '\e93b'; } /* '' */
.icon-close-circle-1:before { content: '\e93c'; } /* '' */
.icon-close-1:before { content: '\e93d'; } /* '' */
.icon-cloud-download-1:before { content: '\e93e'; } /* '' */
.icon-cloud-upload-1:before { content: '\e93f'; } /* '' */
.icon-code-download-1:before { content: '\e940'; } /* '' */
.icon-code-1:before { content: '\e941'; } /* '' */
.icon-color-palette-1:before { content: '\e942'; } /* '' */
.icon-collapse-1:before { content: '\e943'; } /* '' */
.icon-compass-1:before { content: '\e944'; } /* '' */
.icon-content-1:before { content: '\e945'; } /* '' */
.icon-color-picker-1:before { content: '\e946'; } /* '' */
.icon-copy-1:before { content: '\e947'; } /* '' */
.icon-corner-down-left-1:before { content: '\e948'; } /* '' */
.icon-corner-down-right-1:before { content: '\e949'; } /* '' */
.icon-corner-left-down-1:before { content: '\e94a'; } /* '' */
.icon-corner-left-up-1:before { content: '\e94b'; } /* '' */
.icon-corner-right-down-1:before { content: '\e94c'; } /* '' */
.icon-corner-right-up-1:before { content: '\e94d'; } /* '' */
.icon-corner-up-left-1:before { content: '\e94e'; } /* '' */
.icon-corner-up-right-1:before { content: '\e94f'; } /* '' */
.icon-credit-card-1:before { content: '\e950'; } /* '' */
.icon-crop-1:before { content: '\e951'; } /* '' */
.icon-cube-1:before { content: '\e952'; } /* '' */
.icon-dashboard-1:before { content: '\e953'; } /* '' */
.icon-diagonal-arrow-left-down-1:before { content: '\e954'; } /* '' */
.icon-diagonal-arrow-left-up-1:before { content: '\e955'; } /* '' */
.icon-diagonal-arrow-right-down-1:before { content: '\e956'; } /* '' */
.icon-diagonal-arrow-right-up-1:before { content: '\e957'; } /* '' */
.icon-download-1:before { content: '\e958'; } /* '' */
.icon-droplet-off-1:before { content: '\e959'; } /* '' */
.icon-droplet-1:before { content: '\e95a'; } /* '' */
.icon-edit-1:before { content: '\e95b'; } /* '' */
.icon-edit-3:before { content: '\e95c'; } /* '' */
.icon-expand-1:before { content: '\e95d'; } /* '' */
.icon-email-1:before { content: '\e95e'; } /* '' */
.icon-external-link-1:before { content: '\e95f'; } /* '' */
.icon-eye-off-1:before { content: '\e960'; } /* '' */
.icon-eye-off-3:before { content: '\e961'; } /* '' */
.icon-eye-1:before { content: '\e962'; } /* '' */
.icon-facebook-1:before { content: '\e963'; } /* '' */
.icon-file-add-1:before { content: '\e964'; } /* '' */
.icon-file-remove-1:before { content: '\e965'; } /* '' */
.icon-file-text-1:before { content: '\e966'; } /* '' */
.icon-file-1:before { content: '\e967'; } /* '' */
.icon-film-1:before { content: '\e968'; } /* '' */
.icon-fire-1:before { content: '\e969'; } /* '' */
.icon-flag-1:before { content: '\e96a'; } /* '' */
.icon-flash-off-1:before { content: '\e96b'; } /* '' */
.icon-flash-1:before { content: '\e96c'; } /* '' */
.icon-flip-1:before { content: '\e96d'; } /* '' */
.icon-flip-3:before { content: '\e96e'; } /* '' */
.icon-folder-add-1:before { content: '\e96f'; } /* '' */
.icon-folder-remove-1:before { content: '\e970'; } /* '' */
.icon-folder-1:before { content: '\e971'; } /* '' */
.icon-funnel-1:before { content: '\e972'; } /* '' */
.icon-gift-1:before { content: '\e973'; } /* '' */
.icon-github-1:before { content: '\e974'; } /* '' */
.icon-globe-1:before { content: '\e975'; } /* '' */
.icon-globe-3:before { content: '\e976'; } /* '' */
.icon-google-1:before { content: '\e977'; } /* '' */
.icon-grid-1:before { content: '\e978'; } /* '' */
.icon-hard-drive-1:before { content: '\e979'; } /* '' */
.icon-hash-1:before { content: '\e97a'; } /* '' */
.icon-headphones-1:before { content: '\e97b'; } /* '' */
.icon-heart-1:before { content: '\e97c'; } /* '' */
.icon-height-1:before { content: '\e97d'; } /* '' */
.icon-home-1:before { content: '\e97e'; } /* '' */
.icon-image-1:before { content: '\e97f'; } /* '' */
.icon-inbox-1:before { content: '\e980'; } /* '' */
.icon-info-1:before { content: '\e981'; } /* '' */
.icon-keypad-1:before { content: '\e982'; } /* '' */
.icon-layers-1:before { content: '\e983'; } /* '' */
.icon-layout-1:before { content: '\e984'; } /* '' */
.icon-link-1:before { content: '\e985'; } /* '' */
.icon-link-3:before { content: '\e986'; } /* '' */
.icon-linkedin-1:before { content: '\e987'; } /* '' */
.icon-list-1:before { content: '\e988'; } /* '' */
.icon-loader-1:before { content: '\e989'; } /* '' */
.icon-lock-1:before { content: '\e98a'; } /* '' */
.icon-log-in-1:before { content: '\e98b'; } /* '' */
.icon-log-out-1:before { content: '\e98c'; } /* '' */
.icon-pie-stats:before { content: '\e98d'; } /* '' */
.icon-map-1:before { content: '\e98e'; } /* '' */
.icon-maximize-1:before { content: '\e98f'; } /* '' */
.icon-menu-1:before { content: '\e990'; } /* '' */
.icon-menu-arrow-1:before { content: '\e991'; } /* '' */
.icon-menu-3:before { content: '\e992'; } /* '' */
.icon-message-circle-1:before { content: '\e993'; } /* '' */
.icon-message-square-1:before { content: '\e994'; } /* '' */
.icon-mic-off-1:before { content: '\e995'; } /* '' */
.icon-mic-1:before { content: '\e996'; } /* '' */
.icon-minimize-1:before { content: '\e997'; } /* '' */
.icon-minus-circle-1:before { content: '\e998'; } /* '' */
.icon-minus-square-1:before { content: '\e999'; } /* '' */
.icon-minus-1:before { content: '\e99a'; } /* '' */
.icon-monitor-1:before { content: '\e99b'; } /* '' */
.icon-moon-1:before { content: '\e99c'; } /* '' */
.icon-more-horizontal-1:before { content: '\e99d'; } /* '' */
.icon-more-vertical-1:before { content: '\e99e'; } /* '' */
.icon-move-1:before { content: '\e99f'; } /* '' */
.icon-music-1:before { content: '\e9a0'; } /* '' */
.icon-navigation-1:before { content: '\e9a1'; } /* '' */
.icon-navigation-3:before { content: '\e9a2'; } /* '' */
.icon-npm-1:before { content: '\e9a3'; } /* '' */
.icon-options-1:before { content: '\e9a4'; } /* '' */
.icon-options-3:before { content: '\e9a5'; } /* '' */
.icon-pantone-1:before { content: '\e9a6'; } /* '' */
.icon-paper-plane-1:before { content: '\e9a7'; } /* '' */
.icon-pause-circle-1:before { content: '\e9a8'; } /* '' */
.icon-people-1:before { content: '\e9a9'; } /* '' */
.icon-percent-1:before { content: '\e9aa'; } /* '' */
.icon-person-add-1:before { content: '\e9ab'; } /* '' */
.icon-person-delete-1:before { content: '\e9ac'; } /* '' */
.icon-person-done-1:before { content: '\e9ad'; } /* '' */
.icon-person-remove-1:before { content: '\e9ae'; } /* '' */
.icon-person-1:before { content: '\e9af'; } /* '' */
.icon-phone-call-1:before { content: '\e9b0'; } /* '' */
.icon-phone-missed-1:before { content: '\e9b1'; } /* '' */
.icon-phone-off-1:before { content: '\e9b2'; } /* '' */
.icon-phone-1:before { content: '\e9b3'; } /* '' */
.icon-pie-chart-1:before { content: '\e9b4'; } /* '' */
.icon-pin-1:before { content: '\e9b5'; } /* '' */
.icon-play-circle-1:before { content: '\e9b6'; } /* '' */
.icon-plus-circle-1:before { content: '\e9b7'; } /* '' */
.icon-plus-square-1:before { content: '\e9b8'; } /* '' */
.icon-plus-1:before { content: '\e9b9'; } /* '' */
.icon-power-1:before { content: '\e9ba'; } /* '' */
.icon-pricetag-1:before { content: '\e9bb'; } /* '' */
.icon-printer-1:before { content: '\e9bc'; } /* '' */
.icon-question-mark-circle-1:before { content: '\e9bd'; } /* '' */
.icon-question-mark-1:before { content: '\e9be'; } /* '' */
.icon-radio-button-off-1:before { content: '\e9bf'; } /* '' */
.icon-radio-button-on-1:before { content: '\e9c0'; } /* '' */
.icon-radio-1:before { content: '\e9c1'; } /* '' */
.icon-recording-1:before { content: '\e9c2'; } /* '' */
.icon-refresh-1:before { content: '\e9c3'; } /* '' */
.icon-repeat-1:before { content: '\e9c4'; } /* '' */
.icon-rewind-left-1:before { content: '\e9c5'; } /* '' */
.icon-rewind-right-1:before { content: '\e9c6'; } /* '' */
.icon-save-1:before { content: '\e9c7'; } /* '' */
.icon-scissors-1:before { content: '\e9c8'; } /* '' */
.icon-search-1:before { content: '\e9c9'; } /* '' */
.icon-settings-1:before { content: '\e9ca'; } /* '' */
.icon-settings-3:before { content: '\e9cb'; } /* '' */
.icon-shake-1:before { content: '\e9cc'; } /* '' */
.icon-share-1:before { content: '\e9cd'; } /* '' */
.icon-shield-off-1:before { content: '\e9ce'; } /* '' */
.icon-shield-1:before { content: '\e9cf'; } /* '' */
.icon-shopping-bag-1:before { content: '\e9d0'; } /* '' */
.icon-shopping-cart-1:before { content: '\e9d1'; } /* '' */
.icon-shuffle-1:before { content: '\e9d2'; } /* '' */
.icon-shuffle-3:before { content: '\e9d3'; } /* '' */
.icon-skip-back-1:before { content: '\e9d4'; } /* '' */
.icon-skip-forward-1:before { content: '\e9d5'; } /* '' */
.icon-slash-1:before { content: '\e9d6'; } /* '' */
.icon-smartphone-1:before { content: '\e9d7'; } /* '' */
.icon-speaker-1:before { content: '\e9d8'; } /* '' */
.icon-square-1:before { content: '\e9d9'; } /* '' */
.icon-star-1:before { content: '\e9da'; } /* '' */
.icon-stop-circle-1:before { content: '\e9db'; } /* '' */
.icon-sun-1:before { content: '\e9dc'; } /* '' */
.icon-swap-1:before { content: '\e9dd'; } /* '' */
.icon-sync-1:before { content: '\e9de'; } /* '' */
.icon-text-1:before { content: '\e9df'; } /* '' */
.icon-thermometer-minus-1:before { content: '\e9e0'; } /* '' */
.icon-thermometer-plus-1:before { content: '\e9e1'; } /* '' */
.icon-thermometer-1:before { content: '\e9e2'; } /* '' */
.icon-timer-1:before { content: '\e9e3'; } /* '' */
.icon-toggle-left-1:before { content: '\e9e4'; } /* '' */
.icon-toggle-right-1:before { content: '\e9e5'; } /* '' */
.icon-trash-1:before { content: '\e9e6'; } /* '' */
.icon-trash-3:before { content: '\e9e7'; } /* '' */
.icon-trending-down-1:before { content: '\e9e8'; } /* '' */
.icon-trending-up-1:before { content: '\e9e9'; } /* '' */
.icon-tv-1:before { content: '\e9ea'; } /* '' */
.icon-twitter-1:before { content: '\e9eb'; } /* '' */
.icon-umbrella-1:before { content: '\e9ec'; } /* '' */
.icon-undo-1:before { content: '\e9ed'; } /* '' */
.icon-unlock-1:before { content: '\e9ee'; } /* '' */
.icon-upload-1:before { content: '\e9ef'; } /* '' */
.icon-video-off-1:before { content: '\e9f0'; } /* '' */
.icon-video-1:before { content: '\e9f1'; } /* '' */
.icon-volume-down-1:before { content: '\e9f2'; } /* '' */
.icon-volume-off-1:before { content: '\e9f3'; } /* '' */
.icon-volume-up-1:before { content: '\e9f4'; } /* '' */
.icon-volume-1:before { content: '\e9f5'; } /* '' */
.icon-weight-1:before { content: '\e9f6'; } /* '' */
.icon-wifi-off-1:before { content: '\e9f7'; } /* '' */
.icon-wifi-1:before { content: '\e9f8'; } /* '' */
.icon-pie-stats-1:before { content: '\e9fa'; } /* '' */
.icon-add-people:before { content: '\e9fb'; } /* '' */
.icon-library:before { content: '\e9fc'; } /* '' */
.icon-megaphone:before { content: '\e9fd'; } /* '' */
.icon-pin-2:before { content: '\e9fe'; } /* '' */
.icon-rocket:before { content: '\e9f9'; } /* '' */
.icon-evaluation:before { content: '\e9ff'; } /* '' */
.icon-company-building:before { content: '\ea01'; } /* '' */
.icon-hourglass:before { content: '\ea02'; } /* '' */
.icon-hourglass-1:before { content: '\ea03'; } /* '' */
.icon-unlink:before { content: '\ea04'; } /* '' */
