/*=====CUSTOM CSS=====*/
/*==== CUSTOM STYLES V2 =====*/
:root{
    --text-color: #465b8d; /*Escala 400 color primary por defecto*/
    --text-sub: #9e9e9e;
}
* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
ul,
ol,
li,
p
{
    color: var(--text-color);
}


/*scroll touch*/
.scroll-touch {
    -webkit-overflow-scrolling: touch;
}

.scroll-auto {
    -webkit-overflow-scrolling: auto;
}

/*overview-panel*/
.stats-block {
    border-radius: 4px !important;
    padding: 2.5rem 1rem !important;
    height: calc(12rem - 45px - 0em) !important;
}

/*@media===== -panel*/
.timeline {
    min-height: calc(54.5rem - 45px - 0em) !important;
    height: calc(54.5rem - 45px - 0em) !important;
}

/*overview card-recursos*/
.block-recursos {
    max-width: 100%;
    min-height: 200px !important;
    height: calc(102% - 32px - 1em);
}

/*BTN GENERAL*/
button {
    transition: all 0.3s ease-in-out;
}

button:hover {
    transform: translateY(3px);
    box-shadow: none;
}

button.secondary {
    transition: all 0.3s ease-in-out;
}

button.secondary:hover {
    transform: translateY(3px);
    box-shadow: none;
    background-color: var(--v2-color-secondary-600) !important;
    opacity: 5;
}

button.primary {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 15px 30px -6px rgb(169 169 169 / 20%);
}

button.primary:hover {
    transform: translateY(3px);
    box-shadow: none;
    background-color: var(--v2-color-primary-600) !important;
    opacity: 5;
    color: white !important;
}

msc-button.btn-opt-1 button {
    border: 1px solid #dddddd !important;
    background: transparent !important;
    padding: 0.5rem;
  }
  msc-button.btn-opt-1 button:hover {
    color: white !important;
    border: none !important;
}
msc-button.btn-refresh button:hover {
    background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
    color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
}

.na
.navigation-filter__separator .separator__actions__extension msc-button:hover {
    animation: none !important;
}

button.fab:hover {
    color: white !important;
}

button.slate:hover {
    color: rgb(100 116 139 / var(--tw-text-opacity)) !important;
}

msc-button.btn-subs>button {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 15px 30px -6px rgb(169 169 169 / 20%);
}

msc-button.btn-subs>button:hover {
    transform: translateY(3px);
    box-shadow: none;
    background-color: var(--v2-color-primary-600) !important;
    opacity: 5;
    color: var(--v2-color-primary-100) !important;
}


/*==== OVERVIEW===*/

/*btn-overview catalogue*/
button.slate:hover:not(:disabled) {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0), -4px -4px 6px 0 rgb(116 125 136 / 8%), inset -4px -4px 6px 0 rgba(255, 255, 255, .2), inset 4px 4px 6px 0 rgb(0 0 0 / 11%);

}

.head-miscursos {
    margin-bottom: -2rem;
}

/*card catalogue*/
content-card {
    border-radius: 8px !important;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;

}

.card-picture {
    border-top: 5px solid white;
}

/*bullet slider overview*/
.swiper-container .swiper-pagination-bullet-active {
    background-color: var(--v2-color-secondary-500) !important;

}

.swiper-pagination-bullet {
    background: var(--v2-color-primary-500);
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 9px)) !important;
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 9px)) !important;
}

.swiper-pagination-bullet:hover {
    opacity: .5;
}

/*pin modal*/
.cdk-overlay-pane {
    width: auto !important;
}

/*box catalogue*/
.box {
    box-shadow: rgb(0 0 0 / 6%) 0px 3px 3px 0px;
}

/*box usser*/
.content-invitation {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
}

/*card company*/
.card-slider.card-company.duration-500.hover\:shadow-md.bg-white.h-64.p-4.border-2.rounded.flex.flex-col.gap-4.hover\:border-primary-500.hover\:cursor-pointer.overflow-hidden.border-primary-500 {
    box-shadow: rgb(0 0 0 / 20%) 0px 25px 20px -20px;
}

a.link {
    font-size: 14px !important;
}

/*overview sidebar*/

nav a,
nav a:hover,
nav a:visited {
    display: flex !important;
    font-size: 16px !important;
}

/*sidebar*/

.collapsed {
    height: 100% !important;
}

/*header*/

/*resett*/
msc-button.msc-button--primary.self-center {
    width: 100% !important;
}

/*btn trnslate*/
/*btn--select translate */

.option-container {
    width: auto !important;
}

/*scroll*/

*::-webkit-scrollbar-thumb {
    background-color: var(--v2-color-primary-200) !important;
    box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 20%), inset -2px -2px 2px rgb(0 0 0 / 15%) !important;
}

*::-webkit-scrollbar-track {
    background: #f7f7f7 !important;
}

/* ** */
#bg-login {
    overflow-x: hidden;
}

/*/* ======CUSTOM login signup 2 ======***/
#login-signup {
    box-shadow: rgb(0 0 0 / 57%) 0px 5px 15px;
    padding: 57px;
    border-radius: 30px;
    margin-top: 0.8rem;
}

.login--signup .login__body {
    max-width: 700px !important;
}

.login--signup .login__body .signup__form {
    padding: 20px !important;
}

.text-error {
    margin: 0.5rem 1rem !important;
}

/*/* ======CUSTOM adm exportar ======***/

.block-export {
    padding-left: 2.25rem;
    /* 36px */
    padding-right: 2.25rem;
    /* 36px */
}

/* ======CUSTOM COMPANY ======***/
.login .job {
    margin-top: 50px !important;
}

/*cards company*/
.login .job__list .job__item {
    min-width: 0px !important;
    min-height: 0px !important;
}

input:checked+label {
    border-radius: 100px !important;
}

/* ======CUSTOM COMPANY AND OVEVIEW ======** */
.grid-banner {
    padding: 1%;
    background-color: var(--v2-color-primary-600) !important;
    height: calc(12rem - 45px - 0em) !important;
}

/* LOGIN SSO*/
.animacion-trans {
    /* background: linear-gradient(90deg, var(--v2-color-primary-400) 07%,
            var(--v2-color-primary-400) 17%,
            var(--v2-color-primary-500) 37%,
            var(--v2-color-primary-500) 57%,
            var(--v2-color-primary-600) 67%,
            var(--v2-color-primary-700) 87%,
            var(--v2-color-primary-800) 100%);*/
    background: linear-gradient(90deg, var(--v2-color-primary-400) 07%,
            var(--v2-color-primary-400) 17%,
            var(--v2-color-primary-500) 37%,
            var(--v2-color-primary-600) 57%,
            var(--v2-color-primary-700) 67%,
            var(--v2-color-primary-800) 87%,
            var(--v2-color-primary-800) 100%);
    background-size: 300% 100%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.w-9-12 {
    width: 75%;
}

.contenedor-sso {
    height: 6.8rem;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    padding-left: 1rem;
}

.provider-options {
    padding: 5px !important;
}

.provider-options .provider-options-container .provider-item img {
    margin-left: 10px !important;
}

.provider-options .provider-options-container .provider-item span {
    text-align: center;
}

/*==== nav- component tags =====*/
nav>msc-common-tree>dl {
    display: flex !important;
    flex-direction: row;
    gap: 1.5rem;
    border-bottom: 2px solid #ececec !important;
    margin-bottom: 1.1rem;
}

/*==== =====*/
/*==== campamanet=====*/
.container-camps__content {
    margin-top: 0;
}

aside.container-camps__navigation {
    margin-bottom: 1.5rem;
}

/*==== tabla - table =====*/
td>p {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Custom reset ===== LOGIN RESET*/
/* contenedor translate =====reset */
.translate-reset {
    right: 0rem !important;
    bottom: auto !important;
    position: absolute !important;
    top: 0rem !important;
    margin: 0 !important;
    z-index: 0 !important;
}

/* Custom reset ===== LOGIN  */
.login__header {
    max-width: 100% !important;
}

/*login*/
.content-img-login {
    position: relative;
    width: 1000px;
    min-width: 1000px;
    min-height: 750px;
    height: 740px;
    padding: 25px;
    overflow: hidden;
    border: 7px solid #f7f7f7;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

span.btn__text {
    font-size: 0.9rem !important;
    font-weight: 500 !important;
}

.btn__text {
    font-size: 0.9rem !important;
}

/*logo*/
.h-15 {
    height: 4rem !important;
}

.h-100 {
    height: 100px !important;
}

.w-70 {
    width: 70% !important;
}

/*btn sso*/
a.provider-item:hover {
    background-color: rgb(165 165 165 / 12%) !important;
}

a.provider-item {
    border-radius: 10px !important;
    width: 300px;
}

.msc-button--primary button.primary:disabled {
    background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}

.msc-button--primary button.primary {
    width: 100%;
}

/*mensaje*/
span#isPasted {
    font-style: italic;
    color: #41478be8 !important;
}

/*bg */
.bg-color-primary {
    background: linear-gradient(0deg, var(--v2-color-primary-500) 5%, var(--v2-color-primary-700) 50%, var(--v2-color-primary-900) 100%);
}

/*contenedor */
.content-img {
    background-repeat: no-repeat;
    background-position: left !important;
    background-size: cover;
}

.login {
    padding-top: 0px !important;
}

.login__custom__content {
    margin: 0 !important;
}

.login__custom__content .froala-view {
    padding: 24px 30px !important;
    text-align: center !important;
}

.login__custom {
    margin: 1rem 0rem !important;
    background: #fafafa !important;
    border-radius: 20px 20px 0px 20px !important;
}

label.flex.text-sm.mb-1.mr-2 {
    font-size: 1rem !important;
}

/*contenedor login  form*/
.main-login {
    border: 7px solid #f7f7f7 !important;
    border-radius: 30px;
    background-position: left;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.content-login {
    background-color: #f7f7f7 !important;
    box-shadow: rgb(0 0 0 / 57%) 0px 5px 15px;
    margin: 1rem;
    padding: 43px;
    border-radius: 30px;
}

button:not(.option) {
    border: none !important;
}

/*contenedor sso*/
.provider-options-custom {
    background-color: transparent !important;
    height: auto !important;
    box-shadow: unset !important;
    min-width: auto !important;
    max-height: none !important;
}

.provider-options .provider-options-container .provider-item span {
    margin: auto !important;
}

.text-sso-login {
    font-size: 1rem;
    color: #414776;
    font-weight: 300;
    text-align: center;
}

/*input form*/
.input-container input {
    border-radius: 18px !important;
    background: transparent !important;
}

/*input boclip*/
div>msc-input>.input-container>input {
    width: auto !important;
    height: 35px !important;
}

/*custom responsive == html HEADER */
li.tabs {
    display: flex;
    flex-direction: column;
}

/*custom responsive HEADER*/
.toggle,
[id^="drop"] {
    display: none;
    cursor: pointer;

}

.bbt-menu {
    width: 50%;
    z-index: 100;
}

nav {
    width: 100%;
    padding: 0;
}

nav:after {
    content: "";
    display: table;
    clear: both;
}

nav ul {
    float: left;
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
}

nav ul li {
    margin: 0px;
    display: inline-block;
    float: left;
    background: white;
    /*** Warna latar horizontal menu***/
}

nav ul ul {
    display: none;
    position: absolute;
    top: 60px;
}

nav ul li:hover>ul {
    display: inherit;
}

nav ul ul li {
    width: 170px;
    float: none;
    display: list-item;
    position: relative;
}

nav ul ul ul li {
    position: relative;
    top: -60px;
    left: 170px;
}

nav label span {
    float: right;
    cursor: pointer;
}

.toggle,
[id^="drop"] {
    display: none;

}

nav input[type="checkbox"] {
    display: none;
}

/*====== @MEDIA CUSTOM STYLES V2 generales PHONE ======*/
/*============*/

/*media to 425px*/
@media (max-width: 425px) {

    /*users*/
    .block-profile__dataset {

        margin-left: 0px !important;
        flex-direction: column;
    }

    /*campamentos*/
    .content-camps-stats__sidebar {
        position: unset !important;
        display: flex;
        right: 0px !important;
        flex-direction: column;
        align-items: center;
    }

    .content-camps-stats__search {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem !important;
    }

    .box-stats-adoption {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-bottom: 2rem;
    }

    .menu-action {
        flex-direction: column;
        gap: 1rem;
    }

    .content-camps .content-corrections-temp__actions {
        flex-direction: column;
        gap: 1rem;
    }

    .content-camps-stats__header {
        display: flex;
        justify-content: center;
    }

    .stats-card {
        width: 100% !important;
    }

    .toolbar {
        flex-direction: column;
        gap: 1rem;
    }

    .content-invitation__header {
        flex-direction: column;
    }

    .slide__ariane__step:not(:last-child):before {
        width: 30px !important;
    }

    .slide__ariane__step:not(:last-child) {
        margin-right: 30px !important;
    }

    .content-configuration .form-filter {
        margin-left: 0px !important;
    }

    .content-configuration__header {
        margin-bottom: 2em !important;
        flex-direction: column !important;
    }

    .content-configuration .form-filter-step__item span.label {
        display: none;
    }

    .flex-import {
        display: flex !important;
        flex-direction: column;

    }

    .content-configuration {
        padding: 30px 15px !important;
    }

    .container-user__main {
        margin-top: 0.5rem !important;
    }

    .content-configuration__input {
        margin: 0 calc(0em*3) 8px 0 !important;
    }
}

@media only screen and (max-width: 480px) {

    /*nav tablero*/
    nav>msc-common-tree>dl {
        display: flex !important;
        flex-direction: column !important;
        gap: 0.3rem;
    }

    .block-module__widgets {
        gap: 1rem;
    }

    msc-header {
        width: 100vw;
        z-index: 100;
    }

    msc-sidebar {
        width: 55vw;
    }

    msc-sidebar {
        z-index: 10 !important;
        box-shadow: 210px 10px 0px 250px rgb(79 79 79 / 27%) !important;
    }

    .collapsed {
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important
    }
}
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    msc-header {
        width: 100vw;
        z-index: 100;
    }

    msc-sidebar {
        width: 55vw;
    }

    msc-sidebar {
        z-index: 10 !important;
        box-shadow: 210px 10px 0px 250px rgb(79 79 79 / 27%) !important;
    }

    .collapsed {
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important
    }
  }
  /* screnn mas ancho mobile */
@media only screen and (device-width: 1440px) and (device-height: 3200px) and (-webkit-device-pixel-ratio: 3.5) {
    msc-header {
        width: 100vw;
        z-index: 100;
    }

    msc-sidebar {
        width: 55vw;
    }

    msc-sidebar {
        z-index: 10 !important;
        box-shadow: 210px 10px 0px 250px rgb(79 79 79 / 27%) !important;
    }

    .collapsed {
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important
    }
  }

@media only screen and (max-width: 768px) {
    .content-summary__main .journey-timeline__item .block-task__title .block-task__data {
    flex-direction: column;
}
    .block-course {
        overflow: hidden;
    }

    .content-summary__main .journey-timeline .block-module__image {
        min-height: 120px;
    }

    .content-summary__main .journey-timeline__item .block-task__header--media {
        display: flex !important;
        flex-direction: column !important;
        overflow:hidden;
    }

    .content-summary__main .journey-timeline__item .block-task__top {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        gap: 0.5rem;
    }

    .block-task__title {
        display: flex !important;
        justify-content: flex-start;
        align-items: center !important;
        flex-direction: column !important;
    }

    .content-summary__main .journey-timeline__item .block-task__title .block-task__tag {
        width: 80% !important;
        padding: 15px;
    }

    .content-summary__main .journey-timeline:before {
        display: none !important;
    }

    .content-summary__main .journey-timeline__item:after {
        display: none !important;
    }

    .content-summary__main .journey-timeline {
        padding-left: 0px !important;
    }
}

@media only screen and (min-width: 769px) {
    .block-task__title {
        display: flex !important;
        justify-content: flex-start;
        align-items: center !important;
        flex-direction: row !important;
    }


    .content-summary__main .journey-timeline:before {
        display: flex !important;
    }

    .content-summary__main .journey-timeline__item:after {
        display: flex !important;
    }

    .content-summary__main .journey-timeline__item .block-task__title {
        width: 100% !important;
    }
}

/*media to 768px*/
@media (max-width: 768px) {
    #login-signup {
        margin-top: 3rem;
    }

    /*presentation*/
    ul.navigation-breadcrumb.navigation-breadcrumb--light {
        display: flex;
        flex-direction: column;
        margin-top: -0.5rem;
    }

    .skill--level {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .block-date-presentation__header {
        flex-direction: column;
    }

    .block-date-presentation__actions {
        margin-left: 0 !important;
    }

    .block-date-presentation__stats {
        margin-left: 0 !important;
    }

    aside.container-integration__aside {
        margin: 0;
    }

    /*branding*/
    .box.box-experience .box-experience__content .content__login__picture .content-logo__container {
        width: auto !important;
    }

    .box.box-experience .box-experience__language {
        flex-direction: column;
    }

    .container-experience__name {
        font-size: 14px !important;
        color: #020d38;
    }

    .container-applications__item {
        margin: 10px 0 !important;
    }

    /*catalogo*/
    .container-catalog__title span {
        margin-right: 10px;
        font-size: 1rem;
        text-overflow: ellipsis;
    }

    /*acompanar*/
    msc-manage-team-timeline {
        flex-direction: column;
    }

    .content-details__title {
        margin: 1rem !important;
    }

    .content-calendar .calendar__item {
        flex-direction: column;
        gap: 1rem;
    }

    .col-gap-xs {

        flex-direction: column;
        gap: 1rem;
    }

    .description {

        font-size: 14px;
    }

    .notify-xs {
        gap: 1rem;
        align-items: center;
    }

    .gap--1rm-xs {
        gap: 1rem;
    }

    aside footer {
        align-items: center;
        flex-direction: column;
    }

    .container-sessions__row {
        flex-direction: column;
    }

    .container-sessions__main {
        flex-direction: column;
    }

    .navigations-sessions {
        padding: 10px 0 !important;
        margin: 0 !important;

    }

    /*users*/
    .block-profile__header .block-profile__infos.edit .block-profile__avatar {
        min-width: 100% !important;
        flex-direction: row !important;
    }

    .block-profile__avatar,
    .block-profile__avatar .block-profile__add-avatar {
        flex-direction: row !important;
    }

    .block-profile__dataset {
        margin-right: 0px !important;
        flex-direction: column;

    }

    .block-profile__data {
        margin: 16px 0px !important;
        min-width: 100% !important;
    }

    .container-account__aside {
        width: 100% !important;
    }

    .col-xxs-12.no-gutter {
        width: 100% !important;

    }

    .block-profile__cta {
        gap: 1rem;
        flex-direction: column;
    }

    .container-account__main {
        margin-left: 0 !important;
    }

    .container-account__aside {
        width: 100% !important;
    }

    .container-account__name {
        font-size: 1rem !important;
    }

    .navigations-access {
        padding: 10px 0 !important;
        margin: 0px !important;
    }


    .flex--colum {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    .block-profile__header .block-profile__infos {
        width: 100%;
        margin-top: 1rem;
        flex-direction: column;
    }

    header.container-account__header {
        margin-left: 0 !important;
        flex-direction: column;
        overflow: hidden;
    }

    .container-camps__content {
        width: 100% !important;
    }

    .menu-action {
        max-height: fit-content !important;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .stats-cards {
        flex-direction: column;
        gap: 1rem;

    }

    .stats-card {
        width: 100% !important;
    }

    .toggle-campamentos {
        line-height: unset !important;

    }


    .filter-campament {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .div-filter-campament {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .block-course__title {
        font-size: 1rem !important;
    }

    msc-button.btn-subs>button {
        width: 100% !important;
    }

    .title-tag {
        display: flex !important;
        flex-direction: column;
        padding-bottom: 1rem;
    }

    /*filter-modal*/

    .checkbox-filter {
        display: flex;
        flex-direction: column;
    }

    .base {
        width: 80vw !important;
    }

    .swiper-pagination {
        z-index: 0;
    }

    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
        z-index: 10;
        height: 50px;
    }

    .radio-filter {
        display: flex;
        flex-direction: column;
    }

    .login .job {
        margin-top: 40px !important;
    }

    .comp-radio-filter {
        width: 100% !important;
    }

    /*img curso inter*/
    .container__aside__preview .preview__image {
        height: 150px !important;
    }

    /*campaments*/
    aside.container-camps__navigation {
        width: 100% !important;
    }

    .navigations-access li {
        padding: 15px 25px !important;
    }

    .content-access__organize__data {
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
    }

    .description {
        white-space: pre-line !important;
    }

    .content-access__organize__data .data__form {
        margin: 0 0 0 10px !important;
    }
}

/*media generales TABLET */
/*media max to 1280px*/
@media (max-width: 1280px) {
    .content-camps.content-camps__sessions {
        margin-top: 1rem;
    }

    .container-integration .navigation-integration {
        padding: 10px 0 !important;
        margin: 0;
    }

    .content-calendar .calendar__item {
        flex-direction: column;
        gap: 1rem;
    }

    .content-calendar .box-sessions__header {
        flex-direction: column;
    }

    .content-calendar .calendar__progress {
        display: none;
    }
}

/*media min to 1280px*/
@media (min-width: 1280px) {
    .link.link-back {
        margin: auto !important;
    }
}
/*media generales DESKTOP*/
/*media to 1360px*/
@media (max-width: 1360px) {
    .content-profile .footer-privacy {
        margin: 1rem 0 !important;
    }

    .link.link-back {
        padding: 0px;
    }
}

/* media min - max 700px*/
/*======*/
/*media panel overview */
@media (min-width: 755px) {
    .title-noticias-card {
        display: none !important;
    }
}

@media (max-width: 767px) {

    .title-noticias-card {
        display: block !important;
    }

    .card-container {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
}

/* media min - max 1536px*/
@media (min-width: 1536px) {
    .grid-eventos {
        display: block !important;
    }

}

@media (max-width: 1536px) {
    .widget-resource-pdf {
        margin-top: -2rem !important;
    }

    .col-span-1-xl {
        grid-column-start: 1 !important;
    }

    .widget-contact-card {
        display: block !important;
    }

    .widget-contact-over {
        display: none !important;
    }
}

/* media min - max 768px*/
@media (min-width: 768px) {
    .block-event--centered {
        height: 400px !important;
    }
}

@media (max-width: 768px) {
    .post__footer .link__action {
        display: flex;
        flex-direction: column;
    }

    .post__header {
        display: flex;
        flex-direction: column;
    }

    .block-event {
        height: 400px !important;
    }
}

/* media max and min 360px to 1024px */
@media (min-width: 360px) and (max-width: 64rem) {
    .block-date-presentation {
        padding: 30px 12px 40px !important;
    }

    .navigations-aside {
        padding: 10px 0 !important;
        margin: 0 !important;
    }

    .content-configuration {
        overflow: hidden;
    }

    .container-user__main {
        margin-top: calc(0em*3.5) !important;
    }

    .col-xxs-12 {
        /*width: calc(100% - 24px) !important;*/
        width: 100% !important;
    }

    .content-configuration__header {
        margin-bottom: 2em !important;
        flex-direction: column !important;
    }

    /*campaments*/
    .container-camps .navigation-camps {
        padding: 0 !important;
        margin: 1rem 0 !important;
    }
}

@media (min-width: 64rem) and (max-width:80rem) {
    .col-md-9 {
        width: calc(100% - 20px) !important;
    }

    .col-md-3 {
        width: calc(50% - 20px) !important;
    }
}

@media (min-width: 425px) and (max-width: 768px) {

    .content-invitation__header {
        display: flex;
        flex-direction: column;
    }

    .content-configuration .form-filter {
        margin-left: 0px !important;
    }

    .content-configuration__header {
        margin-bottom: 2em !important;
        flex-direction: column !important;
    }

    .content-configuration .form-filter-step__item span.label {
        position: absolute !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        width: 3.8rem !important;
        white-space: nowrap !important;
        margin: -20px 14rem !important;
        display: none;
    }

    /*campamentos*/
    .slide__ariane__step:not(:last-child):before {
        width: 50px !important;
    }

    .slide__ariane__step:not(:last-child) {
        margin-right: 50px !important;
    }

    /**/
}

@media (min-width: 768px) and (max-width: 1024px) {

    /*usser*/
    .block-profile__header .block-profile__infos.edit .block-profile__avatar {
        min-width: 25%;
        flex-direction: column !important;
    }

}

@media (min-width: 598px) and (max-width: 755px) {

    .grid-noticias {
        display: block;
    }


}

@media (max-width: 598px) and (max-width: 767px) {
    .itle-noticias-card {
        display: none;
    }
}

@media (min-width: 758px) and (max-width: 767px) {
    .grid-cursos-card {

        grid-column: span 1 / span 1 !important;
    }

}

@media (min-width: 768px) and (max-width: 1177px) {
    .grid-noticias {
        margin-top: -4.5rem !important;
    }
}

@media (max-width: 768px) and (max-width: 1024px) {
    content-card {
        min-width: 50% !important;
        max-width: 100% !important;
    }
}


/*@media  1024px to 1900px*/
@media (min-width: 1024px) and (max-width: 1177px) {
    .grid-eventos {
        grid-column: span 1 / span 1 !important;
    }
}

@media (min-width: 1024px) and (max-width: 1177px) {
    .grid-principal {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }

    .grid-noticias {
        grid-column: 1 / span 1 !important;
    }
}

@media (min-width:1024px) and (max-width:1280px) {
    .container-experience__body {
        margin: 40px 0 !important;
    }

    .grid-cursos-card {
        grid-column: span 2 / span 1 !important;
    }

}

@media (min-width: 1360px) and (min-width: 1536px) {
    .col-span-3-2xl {
        grid-column: span 3 / span 3 !important;
    }

    .row-span-3-2xl {
        grid-row: span 3 / span 3 !important;
    }

    .stats-block {
        padding: 3rem !important;
    }
}

@media (min-width: 1537px) and (max-width: 1890px) {
    .widget-contact-card {
        display: none !important;
    }

    .text-event {
        display: none !important;
    }
}

@media (min-width: 1537px) and (max-width: 1891px) {
    .block-event-card {
        height: calc(100% - 32px - 1em) !important;
    }
}

@media (min-width: 1890px) {
    .timeline {
        min-height: calc(54.5rem - 45px - 0em) !important;
        height: calc(54.5rem - 45px - 0em) !important;
    }

    .widget-resource-pdf {
        margin-top: -2rem !important;
    }

    .widget-contact-card {
        display: block !important;
    }

    .text-event {
        display: block !important;

    }

    .grid-recursos {
        grid-column: span 1 / span 1 !important;
        grid-column-start: 1 !important;
    }

    .block-event {
        height: 400px !important;
    }

    .widget-contact-over {
        display: none !important;
    }
}

@media (min-width: 1025px) and (max-width: 1180px) {
    .timeline {
        min-height: calc(46.5rem - 45px - 0em) !important;
        height: calc(46.5rem - 45px - 0em) !important;
    }
}

@media (min-width: 1535px) and (max-width: 1889px) {

    .timeline {
        min-height: calc(46.5rem - 45px - 0em) !important;
        height: calc(46.5rem - 45px - 0em) !important;
    }
}

/*max to 1024px*/
@media (max-width: 1024px) {
    .row-banner--col {
        display: flex !important;
        flex-direction: column;
    }

    .container-integration__main {
        padding-left: inherit !important;
    }
}

/*min to 1024px*/
@media (min-width: 1024px) {
    .link.link-back {
        margin-top: 1.5rem;
    }

    /* campament interno */
    .block-task__title span {
        padding-right: 0.5rem;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .grid-banner {
        width: 100% !important;
    }

    .grid-statistics {
        width: 100% !important;
    }

    .grid-principal {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}

/*==== media and vistas  ===*/

/*==== cursos internos ===*/
@media (max-width: 1024px) {
    .md--flex-row {
        flex-direction: column;
        gap: 1rem;
    }
}

/*==== overview ===*/
/*==== media 330px to 1020 ===*/

@media (min-width: 329px) and (max-width: 1020px) {
    msc-common-select {
        top: 0.5rem !important;
    }
}

/*ovewview */
@media (max-width: 768px) {
    msc-common-select {
        top: 0.5rem !important;
        right: 2.7rem !important;
    }
}

/*company overview*/

@media (max-width: 768px) {
    .stats-block-flex {
        display: flex !important;
        flex-direction: column;
    }
}

@media (max-width: 1024px) {
    .grid-banner {
        padding: 2%;
        height: auto !important;
    }

    .stats-block {
        height: auto !important;
    }
}

@media (min-width: 1024px) {
    .grid-banner {
        height: calc(280px - 45px - 0em) !important;
    }

    .stats-block {
        height: calc(280px - 45px - 0em) !important;
    }
}

@media (min-width: 320px) and (max-width: 479px) {
    .content-stats-adoption-date {
        flex-direction: column !important;
        /* width: 50% !important;*/
        grid-column-gap: inherit !important;

    }

    .box-stats-adoption-canvas {
        /* width:50% !important;*/
        display: flex !important;
        flex-direction: column !important;
    }

}


/* LOGIN SSO*/
/*custom responsive ======PHONE=======*/
@media (min-width: 320px) and (max-width: 479px) {
    .login .job__list {
        display: flex !important;
        flex-direction: column !important;
    }

}

/*custom responsive ======PHONE AND TABLET=======*/
@media (min-width: 480px) and (max-width: 768px) {}

/*custom responsive ======TABLET AND DESKTOP=======*/
@media (min-width: 769px) and (max-width: 1020px) {}


/* Media Queries
*******************************************/
/*custom responsive ======LOGIN=======*/

/*custom responsive ======PHONE=======*/

@media (min-width: 320px) and (max-width: 479px) {
    .provider-options .provider-options-container .provider-item {
        padding: 10px 10% !important;
        width: 230px !important;
        font-size: 14px !important;
    }

    span.btn__text {
        font-size: 0.8rem !important;
    }

    button,
    .btn__text {
        font-size: 0.8rem !important;
    }

    .content-img-login {
        position: unset !important;
        width: auto !important;
        min-width: auto !important;
        min-height: auto !important;
        height: auto !important;
        padding: 25px !important;
        overflow: hidden !important;
    }

    #bg-login {
        padding: 0 1px;
    }

    .divisor-sso {
        padding: 0px !important;
    }

    .sm-w-auto {
        width: auto !important;
    }

    .content-login {
        margin: 0rem !important;
        padding: 20px !important;
    }

    .sm-translate {
        --tw-translate-y: 0px !important;
        bottom: auto !important;
        position: absolute !important;
        right: 8.5rem !important;
        top: 0 !important;
        margin: 0 !important;
        z-index: 0 !important;
    }

    /*
    button:not(.option) {
        border-radius: 10px !important;
    }*/
    .main-login {
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        margin-top: 2rem !important;
    }
}

/*custom responsive ======PHONE AND TABLET=======*/

@media (min-width: 480px) and (max-width: 768px) {

    .content-img-login {
        position: unset !important;
        width: auto !important;
        min-width: auto !important;
        min-height: auto !important;
        height: auto !important;
        padding: 25px !important;
        overflow: hidden !important;
    }

    #bg-login {
        padding: 0 10px;
    }

    .divisor-sso {
        padding: 0px !important;
    }

    .sm-w-auto {
        width: auto !important;
    }

    .content-login {
        margin: 0rem !important;
        padding: 20px !important;
    }

    .sm-translate {
        --tw-translate-y: 0px !important;
        bottom: auto !important;
        position: absolute !important;
        right: 8.5rem !important;
        top: 0 !important;
        margin: 0 !important;
        z-index: 0 !important;
    }

    .main-login {
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        margin-top: 2rem !important;
    }
}

/*custom responsive ======TABLET AND DESKTOP=======*/
@media (min-width: 769px) and (max-width: 1020px) {
    #bg-login {
        padding: 0 20px;
    }

    .md-w-10-12 {
        width: 83%;
    }

    .sm-translate-reset {
        --tw-translate-y: 0px !important;
        bottom: auto !important;
        position: absolute !important;
        right: -1rem !important;
    }

    .sm-translate {
        --tw-translate-y: 0px !important;
        position: absolute !important;
        left: 8rem !important;
        margin: 0 !important;
        z-index: 0 !important;
    }

    .main-login {
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;

    }

    .content-login {
        margin: 0rem !important;
        padding: 20px !important;
    }

    .sm-w-auto {
        width: auto !important;
    }

    .md-w-100 {
        /* width: 100% !important; */
        width: 85% !important;
    }

    .divisor-sso {
        padding: 0px !important;
    }

}

@media (min-width: 1450px) and (max-width: 2560px) {
    #bg-login {
        padding: 0 20px;
    }

    .custom-xl {
        position: relative;
        width: 1200px !important;
        min-width: 1200px !important;
        min-height: 780px !important;
        /*height: 780px !important;*/
        padding: 25px !important;
        overflow: hidden !important;
    }
}

@media (min-width: 2561px) and (max-width: 7681px) {
    #bg-login {
        padding: 0 20px;
    }

    .custom-xl {
        position: relative;
        width: 1200px !important;
        min-width: 1200px !important;
        min-height: 780px !important;
        height: 780px !important;
        padding: 25px !important;
        overflow: hidden !important;
    }
}


/* Media Queries
*******************************************/
/*custom responsive ======HEADER=======*/

@media (min-width: 760px) and (max-width: 780px) {

    .nav-header-sm {
        display: none !important;
    }
}

@media (min-width: 320px) and (max-width: 460px) {

    .content .action-trash {
        gap: 0.5rem !important;
    }

    .content,
    .actions {
        gap: 0.5rem !important;
    }

    .content .actions .profile {
        padding: 0 1rem !important;
    }

    /* btn select nombre usuario */
    .profile-list .menu-item.company-list {
        left: -0.3px !important;
        width: auto !important;
        top: 2.5rem !important;
    }

    /**/
    .profile-list .menu-item .help-list {
        width: auto !important;
        left: -5.5rem !important;
        top: 3rem !important;
    }

    [id^="drop"]:checked+ul {
        display: block;
        width: 100%;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        border-radius: 0px 0px 5px 5px;
    }

    .content .tabs {
        padding: 5px 0px 15px 0px;
    }

    .search-sm {
        box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0.1), 0 0px 20px -2px rgb(0 0 0 / 0.1);
        padding-bottom: 13px;
        border-radius: 0px 0px 5px 5px;
    }

    .content {
        display: flex !important;
        align-items: normal !important;
        gap: 0rem !important;

    }

    /*logo menu lateral */
    .logo-container {
        width: 6rem !important;
    }

    .composer__footer .composer__actions .composer__send>* {
        margin: 0 !important;
    }
}

@media all and (min-width: 360px) {

    th>span {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media all and (max-width: 759px) {
    td>p {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .tab.active {
        border-left: 2px solid var(--v2-color-secondary-500) !important;
    }

    .content .tabs .tab:hover {
        border-bottom: none !important;
        border-left: 2px solid var(--v2-color-secondary-500) !important;
    }

    [id^="drop"]:checked+ul {
        display: block;
        width: 100%;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        border-radius: 0px 0px 5px 5px;
    }

    /*
    .content,
    .tabs,
    .tab,
    :hover,
    .content,
    .tabs,
    .tab.active {
        border-bottom: none !important;
    }*/

    .content,
    .actions {
        gap: 1rem !important;
    }

    .content .tabs {
        padding: 5px 0px 15px 0px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 15px;
    }

    .search-sm {
        box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0.1), 0 0px 20px -2px rgb(0 0 0 / 0.1);
        padding-bottom: 13px;
        border-radius: 0px 0px 5px 5px;
    }

    .content {
        display: flex !important;
        align-items: normal !important;
        gap: 0rem !important;


    }

    nav {
        margin: 0;
    }

    .toggle+a,
    .menu {
        display: none;
    }

    .toggle {
        padding: 0 10px;
        display: block;
        font-size: 20px;
        line-height: 70px;
        text-decoration: none;
        border: none;
        justify-content: center;
    }


    nav ul li {
        display: block;
        width: 100%;
    }

    nav ul ul .toggle,
    nav ul ul a {
        padding: 0 40px;
    }

    nav ul ul ul a {
        padding: 0 80px;
    }

    nav ul li ul li .toggle,
    nav ul ul a {
        background-color: #212121;
    }

    nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
    }

    nav ul ul li:hover>ul,
    nav ul li:hover>ul {
        display: none;
    }

    nav ul ul li {
        display: block;
        width: 100%;
    }

    nav ul ul ul li {
        position: static;
    }
}

@media (min-width: 760px) and (max-width: 1400px) {

    .tabs {
        display: flex !important;
        gap: 1.5rem;
        height: 100%;
    }
}

/*custom responsive ======OVERVIEW=======*/
/*===custom responsive OVERVIEW integration ===*/

@media (min-width: 320px) and (max-width: 768px) {
    .card-app-list {
        display: flex;
        flex-direction: column;
    }

    .block-provider__card {
        height: 50% !important;
    }
}

@media (min-width: 320px) and (max-width: 460px) {
    .grid-autofill {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
        /*
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)) !important;*/
    }

    .toolbar-content-nav {
        flex-direction: column;
        gap: 1rem;
    }

    .content-stats-adoption .box-stats-adoption {
        width: 100% !important;
    }

    .content-stats-adoption__search {
        display: flex;
        flex-direction: column;
    }

    .content-stats-adoption__sidebar {
        position: unset !important;
        display: flex;
        right: 0px !important;
        flex-direction: column;
        align-items: center;
    }

    canvas {
        width: 100% !important;
        height: auto !important;
    }

    /*head overview */

    .navdata-overview {

        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }

    .tab.active:hover {
        border-left: 2px solid var(--v2-color-primary-800) !important;
        border-bottom: none !important;
    }

    .tab.active {
        border-bottom: none !important;
    }

    .content-50w {
        width: 50%;
        overflow: hidden;
    }

    .card-container {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        width: 100%;
    }

    content-card {
        min-width: 50% !important;
        max-width: 100% !important;
    }

    .empty__state {
        width: 100% !important;
    }

    .box-composer {
        width: 100% !important;
    }

    .composer__content {
        display: flex !important;
    }
}

@media (min-width: 461px) and (max-width: 890px) {

    .box-stats-adoption__number {
        font-size: 28px !important;
    }

    .box-stats-adoption {
        flex-direction: column;
        gap: 2rem;
    }

    .content-stats-adoption .box-stats-adoption {
        width: 100% !important;
    }

    .content-camps-stats__sidebar {
        position: unset !important;
        display: flex;
        right: 0px !important;
        flex-direction: column;
        align-items: center;
    }

    .content-stats-adoption__search {
        display: flex;
        flex-direction: column;
    }

    .content-stats-adoption__sidebar {
        position: unset !important;
        display: flex;
        right: 0px !important;
        flex-direction: column;
        align-items: center;
    }

    canvas {
        width: 100% !important;
        height: auto !important;
    }

    /*head overview */

    .navdata-overview {

        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }

    .tab.active:hover {
        /*  border-left: 2px solid var(--v2-color-primary-800) !important;*/
        border-bottom: none !important;
    }

    .tab.active {
        border-bottom: none !important;
    }


    content-card {
        min-width: 50% !important;
        max-width: 100% !important;
    }
}

@media (max-width: 579px) {
    content-card {
        min-width: 100% !important;
        max-width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 890px) {
    .card-container {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)) !important;
    }
}

@media (min-width: 891px) and (max-width: 1020px) {
    .content-stats-adoption .box-stats-adoption {
        width: 100% !important;
    }

    .content-stats-adoption__sidebar {
        position: unset !important;
        display: flex;
        right: 0px !important;
        flex-direction: column;
        align-items: center;
    }

    canvas {
        width: 100% !important;
        height: auto !important;
    }

    /*head overview */


}

/*lg*/
@media (max-width: 1025px) {

    .navigation-filter__separator,
    .navigation-filter__separator .separator__filters {
        flex-direction: column;
        gap: 1rem;
    }
}

@media (min-width: 560px) and (max-width: 1366px) {

    content-card {
        min-width: 100% !important;
        max-width: 100% !important;
    }
}


/* FIN CUSTOM Media Queries
***********************************************/

/*========= FIN CUSTOM STYLES V2 =============
***********************************************/


.froala-editor .fr-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 2px solid #d3d5e4;
    border-bottom: 0
}

.froala-editor .fr-toolbar .fr-newline {
    background: rgba(211, 213, 228, .5) !important
}

.froala-editor .fr-toolbar .fr-command.fr-open {
    background: rgba(211, 213, 228, .3) !important
}

.froala-editor .fr-toolbar .fr-command:hover {
    background: rgba(211, 213, 228, .3) !important
}

.froala-editor .fr-toolbar .fr-command.fr-active svg path {
    fill: #f4997d !important
}

.froala-editor .fr-toolbar .fr-command:hover svg path {
    fill: #f4997d !important
}

.froala-editor .fr-toolbar .fr-command.fr-btn svg path {
    fill: #656c9b
}

.froala-editor .fr-toolbar .fr-more-toolbar {
    background-color: rgba(211, 213, 228, .3) !important
}

.froala-editor .fr-wrapper {
    border: 2px solid #d3d5e4 !important;
    border-top: 0 !important;
    border-bottom: 1px solid rgba(211, 213, 228, .5) !important
}

.froala-editor .fr-wrapper .fr-element p {
    color: #8c91b4 !important
}

.froala-editor .fr-wrapper .fr-element ol {
    list-style-type: decimal
}

.froala-editor .fr-wrapper .fr-element li,
.froala-editor .fr-wrapper .fr-element ul {
    list-style-type: inherit
}

.froala-editor .fr-wrapper .fr-placeholder {
    color: #d3d5e4 !important
}

.froala-editor .fr-second-toolbar {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 2px solid #d3d5e4;
    border-top: 0
}

.froala-editor .fr-second-toolbar .fr-counter {
    color: #8c91b4 !important;
    font-family: Circular Std Book !important
}

.froala-view ol {
    list-style-position: inside;
    list-style-type: decimal
}

.froala-view li,
.froala-view ul {
    list-style-position: inside;
    list-style-type: inherit
}

@media screen and (min-width:20rem) {
    .col-xxs-1 {
        width: calc(8.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-1.no-gutter {
        width: 8.33333%;
        margin: 0
    }

    .col-xxs-offset-1 {
        margin-left: calc(8.33333% + 12px)
    }

    .col-xxs-offset-1.no-gutter {
        margin-left: 8.33333%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-2 {
        width: calc(16.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-2.no-gutter {
        width: 16.66667%;
        margin: 0
    }

    .col-xxs-offset-2 {
        margin-left: calc(16.66667% + 12px)
    }

    .col-xxs-offset-2.no-gutter {
        margin-left: 16.66667%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-3 {
        width: calc(25% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-3.no-gutter {
        width: 25%;
        margin: 0
    }

    .col-xxs-offset-3 {
        margin-left: calc(25% + 12px)
    }

    .col-xxs-offset-3.no-gutter {
        margin-left: 25%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-4 {
        width: calc(33.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-4.no-gutter {
        width: 33.33333%;
        margin: 0
    }

    .col-xxs-offset-4 {
        margin-left: calc(33.33333% + 12px)
    }

    .col-xxs-offset-4.no-gutter {
        margin-left: 33.33333%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-5 {
        width: calc(41.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-5.no-gutter {
        width: 41.66667%;
        margin: 0
    }

    .col-xxs-offset-5 {
        margin-left: calc(41.66667% + 12px)
    }

    .col-xxs-offset-5.no-gutter {
        margin-left: 41.66667%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-6 {
        width: calc(50% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-6.no-gutter {
        width: 50%;
        margin: 0
    }

    .col-xxs-offset-6 {
        margin-left: calc(50% + 12px)
    }

    .col-xxs-offset-6.no-gutter {
        margin-left: 50%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-7 {
        width: calc(58.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-7.no-gutter {
        width: 58.33333%;
        margin: 0
    }

    .col-xxs-offset-7 {
        margin-left: calc(58.33333% + 12px)
    }

    .col-xxs-offset-7.no-gutter {
        margin-left: 58.33333%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-8 {
        width: calc(66.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-8.no-gutter {
        width: 66.66667%;
        margin: 0
    }

    .col-xxs-offset-8 {
        margin-left: calc(66.66667% + 12px)
    }

    .col-xxs-offset-8.no-gutter {
        margin-left: 66.66667%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-9 {
        width: calc(75% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-9.no-gutter {
        width: 75%;
        margin: 0
    }

    .col-xxs-offset-9 {
        margin-left: calc(75% + 12px)
    }

    .col-xxs-offset-9.no-gutter {
        margin-left: 75%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-10 {
        width: calc(83.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-10.no-gutter {
        width: 83.33333%;
        margin: 0
    }

    .col-xxs-offset-10 {
        margin-left: calc(83.33333% + 12px)
    }

    .col-xxs-offset-10.no-gutter {
        margin-left: 83.33333%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-11 {
        width: calc(91.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xxs-11.no-gutter {
        width: 91.66667%;
        margin: 0
    }

    .col-xxs-offset-11 {
        margin-left: calc(91.66667% + 12px)
    }

    .col-xxs-offset-11.no-gutter {
        margin-left: 91.66667%
    }
}

@media screen and (min-width:20rem) {
    .col-xxs-12 {
        /*   width: calc(100% - 24px);*/
        float: left;
        /* margin: 12px;*/
        min-height: 40px
    }

    .col-xxs-12.no-gutter {
        width: 100%;
        margin: 0
    }

    .col-xxs-offset-12 {
        margin-left: calc(100% + 12px)
    }

    .col-xxs-offset-12.no-gutter {
        margin-left: 100%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-1 {
        width: calc(8.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-1.no-gutter {
        width: 8.33333%;
        margin: 0
    }

    .col-xs-offset-1 {
        margin-left: calc(8.33333% + 12px)
    }

    .col-xs-offset-1.no-gutter {
        margin-left: 8.33333%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-2 {
        width: calc(16.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-2.no-gutter {
        width: 16.66667%;
        margin: 0
    }

    .col-xs-offset-2 {
        margin-left: calc(16.66667% + 12px)
    }

    .col-xs-offset-2.no-gutter {
        margin-left: 16.66667%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-3 {
        width: calc(25% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-3.no-gutter {
        width: 25%;
        margin: 0
    }

    .col-xs-offset-3 {
        margin-left: calc(25% + 12px)
    }

    .col-xs-offset-3.no-gutter {
        margin-left: 25%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-4 {
        width: calc(33.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-4.no-gutter {
        width: 33.33333%;
        margin: 0
    }

    .col-xs-offset-4 {
        margin-left: calc(33.33333% + 12px)
    }

    .col-xs-offset-4.no-gutter {
        margin-left: 33.33333%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-5 {
        width: calc(41.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-5.no-gutter {
        width: 41.66667%;
        margin: 0
    }

    .col-xs-offset-5 {
        margin-left: calc(41.66667% + 12px)
    }

    .col-xs-offset-5.no-gutter {
        margin-left: 41.66667%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-6 {
        width: calc(50% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-6.no-gutter {
        width: 50%;
        margin: 0
    }

    .col-xs-offset-6 {
        margin-left: calc(50% + 12px)
    }

    .col-xs-offset-6.no-gutter {
        margin-left: 50%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-7 {
        width: calc(58.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-7.no-gutter {
        width: 58.33333%;
        margin: 0
    }

    .col-xs-offset-7 {
        margin-left: calc(58.33333% + 12px)
    }

    .col-xs-offset-7.no-gutter {
        margin-left: 58.33333%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-8 {
        width: calc(66.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-8.no-gutter {
        width: 66.66667%;
        margin: 0
    }

    .col-xs-offset-8 {
        margin-left: calc(66.66667% + 12px)
    }

    .col-xs-offset-8.no-gutter {
        margin-left: 66.66667%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-9 {
        width: calc(75% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-9.no-gutter {
        width: 75%;
        margin: 0
    }

    .col-xs-offset-9 {
        margin-left: calc(75% + 12px)
    }

    .col-xs-offset-9.no-gutter {
        margin-left: 75%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-10 {
        width: calc(83.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-10.no-gutter {
        width: 83.33333%;
        margin: 0
    }

    .col-xs-offset-10 {
        margin-left: calc(83.33333% + 12px)
    }

    .col-xs-offset-10.no-gutter {
        margin-left: 83.33333%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-11 {
        width: calc(91.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-11.no-gutter {
        width: 91.66667%;
        margin: 0
    }

    .col-xs-offset-11 {
        margin-left: calc(91.66667% + 12px)
    }

    .col-xs-offset-11.no-gutter {
        margin-left: 91.66667%
    }
}

@media screen and (min-width:32rem) {
    .col-xs-12 {
        width: calc(100% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xs-12.no-gutter {
        width: 100%;
        margin: 0
    }

    .col-xs-offset-12 {
        margin-left: calc(100% + 12px)
    }

    .col-xs-offset-12.no-gutter {
        margin-left: 100%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-1 {
        width: calc(8.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-1.no-gutter {
        width: 8.33333%;
        margin: 0
    }

    .col-sm-offset-1 {
        margin-left: calc(8.33333% + 12px)
    }

    .col-sm-offset-1.no-gutter {
        margin-left: 8.33333%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-2 {
        width: calc(16.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-2.no-gutter {
        width: 16.66667%;
        margin: 0
    }

    .col-sm-offset-2 {
        margin-left: calc(16.66667% + 12px)
    }

    .col-sm-offset-2.no-gutter {
        margin-left: 16.66667%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-3 {
        width: calc(25% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-3.no-gutter {
        width: 25%;
        margin: 0
    }

    .col-sm-offset-3 {
        margin-left: calc(25% + 12px)
    }

    .col-sm-offset-3.no-gutter {
        margin-left: 25%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-4 {
        width: calc(33.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-4.no-gutter {
        width: 33.33333%;
        margin: 0
    }

    .col-sm-offset-4 {
        margin-left: calc(33.33333% + 12px)
    }

    .col-sm-offset-4.no-gutter {
        margin-left: 33.33333%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-5 {
        width: calc(41.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-5.no-gutter {
        width: 41.66667%;
        margin: 0
    }

    .col-sm-offset-5 {
        margin-left: calc(41.66667% + 12px)
    }

    .col-sm-offset-5.no-gutter {
        margin-left: 41.66667%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-6 {
        width: calc(50% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-6.no-gutter {
        width: 50%;
        margin: 0
    }

    .col-sm-offset-6 {
        margin-left: calc(50% + 12px)
    }

    .col-sm-offset-6.no-gutter {
        margin-left: 50%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-7 {
        width: calc(58.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-7.no-gutter {
        width: 58.33333%;
        margin: 0
    }

    .col-sm-offset-7 {
        margin-left: calc(58.33333% + 12px)
    }

    .col-sm-offset-7.no-gutter {
        margin-left: 58.33333%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-8 {
        width: calc(66.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-8.no-gutter {
        width: 66.66667%;
        margin: 0
    }

    .col-sm-offset-8 {
        margin-left: calc(66.66667% + 12px)
    }

    .col-sm-offset-8.no-gutter {
        margin-left: 66.66667%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-9 {
        width: calc(75% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-9.no-gutter {
        width: 75%;
        margin: 0
    }

    .col-sm-offset-9 {
        margin-left: calc(75% + 12px)
    }

    .col-sm-offset-9.no-gutter {
        margin-left: 75%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-10 {
        width: calc(83.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-10.no-gutter {
        width: 83.33333%;
        margin: 0
    }

    .col-sm-offset-10 {
        margin-left: calc(83.33333% + 12px)
    }

    .col-sm-offset-10.no-gutter {
        margin-left: 83.33333%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-11 {
        width: calc(91.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-11.no-gutter {
        width: 91.66667%;
        margin: 0
    }

    .col-sm-offset-11 {
        margin-left: calc(91.66667% + 12px)
    }

    .col-sm-offset-11.no-gutter {
        margin-left: 91.66667%
    }
}

@media screen and (min-width:48rem) {
    .col-sm-12 {
        width: calc(100% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-sm-12.no-gutter {
        width: 100%;
        margin: 0
    }

    .col-sm-offset-12 {
        margin-left: calc(100% + 12px)
    }

    .col-sm-offset-12.no-gutter {
        margin-left: 100%
    }
}

@media screen and (min-width:64rem) {
    .col-md-1 {
        width: calc(8.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-1.no-gutter {
        width: 8.33333%;
        margin: 0
    }

    .col-md-offset-1 {
        margin-left: calc(8.33333% + 12px)
    }

    .col-md-offset-1.no-gutter {
        margin-left: 8.33333%
    }
}

@media screen and (min-width:64rem) {
    .col-md-2 {
        width: calc(16.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-2.no-gutter {
        width: 16.66667%;
        margin: 0
    }

    .col-md-offset-2 {
        margin-left: calc(16.66667% + 12px)
    }

    .col-md-offset-2.no-gutter {
        margin-left: 16.66667%
    }
}

@media screen and (min-width:64rem) {
    .col-md-3 {
        width: calc(25% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-3.no-gutter {
        width: 25%;
        margin: 0
    }

    .col-md-offset-3 {
        margin-left: calc(25% + 12px)
    }

    .col-md-offset-3.no-gutter {
        margin-left: 25%
    }
}

@media screen and (min-width:64rem) {
    .col-md-4 {
        width: calc(33.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-4.no-gutter {
        width: 33.33333%;
        margin: 0
    }

    .col-md-offset-4 {
        margin-left: calc(33.33333% + 12px)
    }

    .col-md-offset-4.no-gutter {
        margin-left: 33.33333%
    }
}

@media screen and (min-width:64rem) {
    .col-md-5 {
        width: calc(41.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-5.no-gutter {
        width: 41.66667%;
        margin: 0
    }

    .col-md-offset-5 {
        margin-left: calc(41.66667% + 12px)
    }

    .col-md-offset-5.no-gutter {
        margin-left: 41.66667%
    }
}

@media screen and (min-width:64rem) {
    .col-md-6 {
        width: calc(50% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-6.no-gutter {
        width: 50%;
        margin: 0
    }

    .col-md-offset-6 {
        margin-left: calc(50% + 12px)
    }

    .col-md-offset-6.no-gutter {
        margin-left: 50%
    }
}

@media screen and (min-width:64rem) {
    .col-md-7 {
        width: calc(58.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-7.no-gutter {
        width: 58.33333%;
        margin: 0
    }

    .col-md-offset-7 {
        margin-left: calc(58.33333% + 12px)
    }

    .col-md-offset-7.no-gutter {
        margin-left: 58.33333%
    }
}

@media screen and (min-width:64rem) {
    .col-md-8 {
        width: calc(66.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-8.no-gutter {
        width: 66.66667%;
        margin: 0
    }

    .col-md-offset-8 {
        margin-left: calc(66.66667% + 12px)
    }

    .col-md-offset-8.no-gutter {
        margin-left: 66.66667%
    }
}

@media screen and (min-width:64rem) {
    .col-md-9 {
        width: calc(75% - 24px);
        float: left;
        /* margin: 12px;*/
        min-height: 40px
    }

    .col-md-9.no-gutter {
        width: 75%;
        margin: 0
    }

    .col-md-offset-9 {
        margin-left: calc(75% + 12px)
    }

    .col-md-offset-9.no-gutter {
        margin-left: 75%
    }
}

@media screen and (min-width:64rem) {
    .col-md-10 {
        width: calc(83.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-10.no-gutter {
        width: 83.33333%;
        margin: 0
    }

    .col-md-offset-10 {
        margin-left: calc(83.33333% + 12px)
    }

    .col-md-offset-10.no-gutter {
        margin-left: 83.33333%
    }
}

@media screen and (min-width:64rem) {
    .col-md-11 {
        width: calc(91.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-11.no-gutter {
        width: 91.66667%;
        margin: 0
    }

    .col-md-offset-11 {
        margin-left: calc(91.66667% + 12px)
    }

    .col-md-offset-11.no-gutter {
        margin-left: 91.66667%
    }
}

@media screen and (min-width:64rem) {
    .col-md-12 {
        width: calc(100% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-md-12.no-gutter {
        width: 100%;
        margin: 0
    }

    .col-md-offset-12 {
        margin-left: calc(100% + 12px)
    }

    .col-md-offset-12.no-gutter {
        margin-left: 100%
    }
}

@media screen and (min-width:80rem) {
    .col-1 {
        width: calc(8.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-1.no-gutter {
        width: 8.33333%;
        margin: 0
    }

    .col-offset-1 {
        margin-left: calc(8.33333% + 12px) !important
    }

    .col-offset-1.no-gutter {
        margin-left: 8.33333%
    }
}

@media screen and (min-width:80rem) {
    .col-2 {
        width: calc(16.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-2.no-gutter {
        width: 16.66667%;
        margin: 0
    }

    .col-offset-2 {
        margin-left: calc(16.66667% + 12px) !important
    }

    .col-offset-2.no-gutter {
        margin-left: 16.66667%
    }
}

@media screen and (min-width:80rem) {
    .col-3 {
        width: calc(25% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-3.no-gutter {
        width: 25%;
        margin: 0
    }

    .col-offset-3 {
        margin-left: calc(25% + 12px) !important
    }

    .col-offset-3.no-gutter {
        margin-left: 25%
    }
}

@media screen and (min-width:80rem) {
    .col-4 {
        width: calc(33.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-4.no-gutter {
        width: 33.33333%;
        margin: 0
    }

    .col-offset-4 {
        margin-left: calc(33.33333% + 12px) !important
    }

    .col-offset-4.no-gutter {
        margin-left: 33.33333%
    }
}

@media screen and (min-width:80rem) {
    .col-5 {
        width: calc(41.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-5.no-gutter {
        width: 41.66667%;
        margin: 0
    }

    .col-offset-5 {
        margin-left: calc(41.66667% + 12px) !important
    }

    .col-offset-5.no-gutter {
        margin-left: 41.66667%
    }
}

@media screen and (min-width:80rem) {
    .col-6 {
        width: calc(50% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-6.no-gutter {
        width: 50%;
        margin: 0
    }

    .col-offset-6 {
        margin-left: calc(50% + 12px) !important
    }

    .col-offset-6.no-gutter {
        margin-left: 50%
    }
}

@media screen and (min-width:80rem) {
    .col-7 {
        width: calc(58.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-7.no-gutter {
        width: 58.33333%;
        margin: 0
    }

    .col-offset-7 {
        margin-left: calc(58.33333% + 12px) !important
    }

    .col-offset-7.no-gutter {
        margin-left: 58.33333%
    }
}

@media screen and (min-width:80rem) {
    .col-8 {
        width: calc(66.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-8.no-gutter {
        width: 66.66667%;
        margin: 0
    }

    .col-offset-8 {
        margin-left: calc(66.66667% + 12px) !important
    }

    .col-offset-8.no-gutter {
        margin-left: 66.66667%
    }
}

@media screen and (min-width:80rem) {
    .col-9 {
        width: calc(75% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-9.no-gutter {
        width: 75%;
        margin: 0
    }

    .col-offset-9 {
        margin-left: calc(75% + 12px) !important
    }

    .col-offset-9.no-gutter {
        margin-left: 75%
    }
}

@media screen and (min-width:80rem) {
    .col-10 {
        width: calc(83.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-10.no-gutter {
        width: 83.33333%;
        margin: 0
    }

    .col-offset-10 {
        margin-left: calc(83.33333% + 12px) !important
    }

    .col-offset-10.no-gutter {
        margin-left: 83.33333%
    }
}

@media screen and (min-width:80rem) {
    .col-11 {
        width: calc(91.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-11.no-gutter {
        width: 91.66667%;
        margin: 0
    }

    .col-offset-11 {
        margin-left: calc(91.66667% + 12px) !important
    }

    .col-offset-11.no-gutter {
        margin-left: 91.66667%
    }
}

@media screen and (min-width:80rem) {
    .col-12 {
        width: calc(100% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-12.no-gutter {
        width: 100%;
        margin: 0
    }

    .col-offset-12 {
        margin-left: calc(100% + 12px) !important
    }

    .col-offset-12.no-gutter {
        margin-left: 100%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-1 {
        width: calc(8.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-1.no-gutter {
        width: 8.33333%;
        margin: 0
    }

    .col-xl-offset-1 {
        margin-left: calc(8.33333% + 12px)
    }

    .col-xl-offset-1.no-gutter {
        margin-left: 8.33333%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-2 {
        width: calc(16.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-2.no-gutter {
        width: 16.66667%;
        margin: 0
    }

    .col-xl-offset-2 {
        margin-left: calc(16.66667% + 12px)
    }

    .col-xl-offset-2.no-gutter {
        margin-left: 16.66667%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-3 {
        width: calc(25% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-3.no-gutter {
        width: 25%;
        margin: 0
    }

    .col-xl-offset-3 {
        margin-left: calc(25% + 12px)
    }

    .col-xl-offset-3.no-gutter {
        margin-left: 25%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-4 {
        width: calc(33.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-4.no-gutter {
        width: 33.33333%;
        margin: 0
    }

    .col-xl-offset-4 {
        margin-left: calc(33.33333% + 12px)
    }

    .col-xl-offset-4.no-gutter {
        margin-left: 33.33333%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-5 {
        width: calc(41.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-5.no-gutter {
        width: 41.66667%;
        margin: 0
    }

    .col-xl-offset-5 {
        margin-left: calc(41.66667% + 12px)
    }

    .col-xl-offset-5.no-gutter {
        margin-left: 41.66667%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-6 {
        width: calc(50% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-6.no-gutter {
        width: 50%;
        margin: 0
    }

    .col-xl-offset-6 {
        margin-left: calc(50% + 12px)
    }

    .col-xl-offset-6.no-gutter {
        margin-left: 50%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-7 {
        width: calc(58.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-7.no-gutter {
        width: 58.33333%;
        margin: 0
    }

    .col-xl-offset-7 {
        margin-left: calc(58.33333% + 12px)
    }

    .col-xl-offset-7.no-gutter {
        margin-left: 58.33333%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-8 {
        width: calc(66.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-8.no-gutter {
        width: 66.66667%;
        margin: 0
    }

    .col-xl-offset-8 {
        margin-left: calc(66.66667% + 12px)
    }

    .col-xl-offset-8.no-gutter {
        margin-left: 66.66667%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-9 {
        width: calc(75% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-9.no-gutter {
        width: 75%;
        margin: 0
    }

    .col-xl-offset-9 {
        margin-left: calc(75% + 12px)
    }

    .col-xl-offset-9.no-gutter {
        margin-left: 75%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-10 {
        width: calc(83.33333% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-10.no-gutter {
        width: 83.33333%;
        margin: 0
    }

    .col-xl-offset-10 {
        margin-left: calc(83.33333% + 12px)
    }

    .col-xl-offset-10.no-gutter {
        margin-left: 83.33333%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-11 {
        width: calc(91.66667% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-11.no-gutter {
        width: 91.66667%;
        margin: 0
    }

    .col-xl-offset-11 {
        margin-left: calc(91.66667% + 12px)
    }

    .col-xl-offset-11.no-gutter {
        margin-left: 91.66667%
    }
}

@media screen and (min-width:90rem) {
    .col-xl-12 {
        width: calc(100% - 24px);
        float: left;
        margin: 12px;
        min-height: 40px
    }

    .col-xl-12.no-gutter {
        width: 100%;
        margin: 0
    }

    .col-xl-offset-12 {
        margin-left: calc(100% + 12px)
    }

    .col-xl-offset-12.no-gutter {
        margin-left: 100%
    }
}

@font-face {
    font-family: myskillcamp;
    src: url(https://static.myskillcamp.com/icons/myskillcamp.eot?5055492=);
    src: url(https://static.myskillcamp.com/icons/myskillcamp.eot?5055492=#iefix) format("embedded-opentype"), url(https://static.myskillcamp.com/icons/myskillcamp.woff2?5055492=) format("woff2"), url(https://static.myskillcamp.com/icons/myskillcamp.woff?5055492=) format("woff"), url(https://static.myskillcamp.com/icons/myskillcamp.ttf?5055492=) format("truetype"), url(https://static.myskillcamp.com/icons/myskillcamp.svg?5055492=#myskillcamp) format("svg");
    font-weight: 400;
    font-style: normal
}

[class*=" icon-"]:before,
[class^=icon-]:before {
    font-family: myskillcamp;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-activity-1:before {
    content: "\e800"
}

.icon-add-message:before {
    content: "\e801"
}

.icon-alert-circle:before {
    content: "\e802"
}

.icon-alert-triangle:before {
    content: "\e803"
}

.icon-all-done:before {
    content: "\e804"
}

.icon-archive:before {
    content: "\e805"
}

.icon-arrow-circle-down:before {
    content: "\e806"
}

.icon-arrow-circle-left:before {
    content: "\e807"
}

.icon-arrow-circle-right:before {
    content: "\e808"
}

.icon-arrow-circle-up:before {
    content: "\e809"
}

.icon-arrow-down:before {
    content: "\e80a"
}

.icon-arrow-ios-down:before {
    content: "\e80b"
}

.icon-arrow-ios-left:before {
    content: "\e80c"
}

.icon-arrow-ios-right:before {
    content: "\e80d"
}

.icon-arrow-ios-up:before {
    content: "\e80e"
}

.icon-arrow-left:before {
    content: "\e80f"
}

.icon-arrow-right:before {
    content: "\e810"
}

.icon-arrow-up:before {
    content: "\e811"
}

.icon-arrowhead-down:before {
    content: "\e812"
}

.icon-arrowhead-left:before {
    content: "\e813"
}

.icon-arrowhead-right:before {
    content: "\e814"
}

.icon-arrowhead-up:before {
    content: "\e815"
}

.icon-arrrow-left:before {
    content: "\e816"
}

.icon-at:before {
    content: "\e817"
}

.icon-attach-2:before {
    content: "\e818"
}

.icon-attach:before {
    content: "\e819"
}

.icon-award:before {
    content: "\e81a"
}

.icon-backspace:before {
    content: "\e81b"
}

.icon-bar-chart-2:before {
    content: "\e81c"
}

.icon-bar-chart:before {
    content: "\e81d"
}

.icon-battery:before {
    content: "\e81e"
}

.icon-batttery-charging:before {
    content: "\e81f"
}

.icon-behance:before {
    content: "\e820"
}

.icon-bell-off:before {
    content: "\e821"
}

.icon-bell:before {
    content: "\e822"
}

.icon-bluetooth:before {
    content: "\e823"
}

.icon-book-open:before {
    content: "\e824"
}

.icon-book:before {
    content: "\e825"
}

.icon-bookmark:before {
    content: "\e826"
}

.icon-briefcase:before {
    content: "\e827"
}

.icon-browser:before {
    content: "\e828"
}

.icon-brush:before {
    content: "\e829"
}

.icon-bulb:before {
    content: "\e82a"
}

.icon-bullet-point:before {
    content: "\e82b"
}

.icon-calendar-add:before {
    content: "\e82c"
}

.icon-calendar:before {
    content: "\e82d"
}

.icon-camp:before {
    content: "\e82e"
}

.icon-camera:before {
    content: "\e82f"
}

.icon-car:before {
    content: "\e830"
}

.icon-cast:before {
    content: "\e831"
}

.icon-catalogue:before {
    content: "\e832"
}

.icon-checkmark-circle-2:before {
    content: "\e833"
}

.icon-checkmark-circle:before {
    content: "\e834"
}

.icon-checkmark-square-2:before {
    content: "\e835"
}

.icon-checkmark-square:before {
    content: "\e836"
}

.icon-checkmark:before {
    content: "\e837"
}

.icon-chevron-down:before {
    content: "\e838"
}

.icon-chevron-left:before {
    content: "\e839"
}

.icon-chevron-right:before {
    content: "\e83a"
}

.icon-chevron-up:before {
    content: "\e83b"
}

.icon-clipboard:before {
    content: "\e83c"
}

.icon-clock:before {
    content: "\e83d"
}

.icon-close-circle:before {
    content: "\e83e"
}

.icon-close:before {
    content: "\e83f"
}

.icon-cloud-download:before {
    content: "\e840"
}

.icon-cloud-upload:before {
    content: "\e841"
}

.icon-code-download:before {
    content: "\e842"
}

.icon-code:before {
    content: "\e843"
}

.icon-collapse:before {
    content: "\e844"
}

.icon-color-palette:before {
    content: "\e845"
}

.icon-color-picker:before {
    content: "\e846"
}

.icon-compass:before {
    content: "\e847"
}

.icon-content:before {
    content: "\e848"
}

.icon-copy:before {
    content: "\e849"
}

.icon-corner-down-left:before {
    content: "\e84a"
}

.icon-corner-down-right:before {
    content: "\e84b"
}

.icon-corner-left-down:before {
    content: "\e84c"
}

.icon-corner-left-up:before {
    content: "\e84d"
}

.icon-corner-right-down:before {
    content: "\e84e"
}

.icon-corner-right-up:before {
    content: "\e84f"
}

.icon-corner-up-left:before {
    content: "\e850"
}

.icon-corner-up-right:before {
    content: "\e851"
}

.icon-credit-card:before {
    content: "\e852"
}

.icon-crop:before {
    content: "\e853"
}

.icon-cube:before {
    content: "\e854"
}

.icon-dashboard:before {
    content: "\e855"
}

.icon-diagonal-arrow-left-down:before {
    content: "\e856"
}

.icon-diagonal-arrow-left-up:before {
    content: "\e857"
}

.icon-diagonal-arrow-right-down:before {
    content: "\e858"
}

.icon-diagonal-arrow-right-up:before {
    content: "\e859"
}

.icon-download:before {
    content: "\e85a"
}

.icon-droplet-off:before {
    content: "\e85b"
}

.icon-droplet:before {
    content: "\e85c"
}

.icon-edit-2:before {
    content: "\e85d"
}

.icon-edit:before {
    content: "\e85e"
}

.icon-email:before {
    content: "\e85f"
}

.icon-external-link:before {
    content: "\e860"
}

.icon-expand:before {
    content: "\e861"
}

.icon-eye-off-2:before {
    content: "\e862"
}

.icon-eye-off:before {
    content: "\e863"
}

.icon-eye:before {
    content: "\e864"
}

.icon-facebook:before {
    content: "\e865"
}

.icon-file-add:before {
    content: "\e866"
}

.icon-file-remove:before {
    content: "\e867"
}

.icon-file-text:before {
    content: "\e868"
}

.icon-file:before {
    content: "\e869"
}

.icon-film:before {
    content: "\e86a"
}

.icon-fire:before {
    content: "\e86b"
}

.icon-flag:before {
    content: "\e86c"
}

.icon-flash-off:before {
    content: "\e86d"
}

.icon-flash:before {
    content: "\e86e"
}

.icon-flip-2:before {
    content: "\e86f"
}

.icon-flip:before {
    content: "\e870"
}

.icon-folder-add:before {
    content: "\e871"
}

.icon-folder-remove:before {
    content: "\e872"
}

.icon-folder:before {
    content: "\e873"
}

.icon-funnel:before {
    content: "\e874"
}

.icon-gift:before {
    content: "\e875"
}

.icon-github:before {
    content: "\e876"
}

.icon-globe-2:before {
    content: "\e877"
}

.icon-globe:before {
    content: "\e878"
}

.icon-google:before {
    content: "\e879"
}

.icon-grid:before {
    content: "\e87a"
}

.icon-hard-drive:before {
    content: "\e87b"
}

.icon-hash:before {
    content: "\e87c"
}

.icon-headphones:before {
    content: "\e87d"
}

.icon-heart:before {
    content: "\e87e"
}

.icon-height:before {
    content: "\e87f"
}

.icon-home:before {
    content: "\e880"
}

.icon-image:before {
    content: "\e881"
}

.icon-inbox:before {
    content: "\e882"
}

.icon-info:before {
    content: "\e883"
}

.icon-keypad:before {
    content: "\e884"
}

.icon-layers:before {
    content: "\e885"
}

.icon-layout:before {
    content: "\e886"
}

.icon-like-social:before {
    content: "\e887"
}

.icon-link-2:before {
    content: "\e888"
}

.icon-link:before {
    content: "\e889"
}

.icon-linkedin:before {
    content: "\e88a"
}

.icon-list:before {
    content: "\e88b"
}

.icon-loader:before {
    content: "\e88c"
}

.icon-lock:before {
    content: "\e88d"
}

.icon-log-in:before {
    content: "\e88e"
}

.icon-log-out:before {
    content: "\e88f"
}

.icon-logo-myskillcamp:before {
    content: "\e890"
}

.icon-map:before {
    content: "\e891"
}

.icon-maximize:before {
    content: "\e892"
}

.icon-mentee:before {
    content: "\e893"
}

.icon-mentor:before {
    content: "\e894"
}

.icon-menu-2:before {
    content: "\e895"
}

.icon-menu-arrow:before {
    content: "\e896"
}

.icon-menu:before {
    content: "\e897"
}

.icon-message-circle:before {
    content: "\e898"
}

.icon-message-square:before {
    content: "\e899"
}

.icon-mic-off:before {
    content: "\e89a"
}

.icon-mic:before {
    content: "\e89b"
}

.icon-minimize:before {
    content: "\e89c"
}

.icon-minus-circle:before {
    content: "\e89d"
}

.icon-minus-square:before {
    content: "\e89e"
}

.icon-minus:before {
    content: "\e89f"
}

.icon-monitor:before {
    content: "\e8a0"
}

.icon-moon:before {
    content: "\e8a1"
}

.icon-more-horizontal:before {
    content: "\e8a2"
}

.icon-more-vertical:before {
    content: "\e8a3"
}

.icon-move:before {
    content: "\e8a4"
}

.icon-music:before {
    content: "\e8a5"
}

.icon-navigation-2:before {
    content: "\e8a6"
}

.icon-navigation:before {
    content: "\e8a7"
}

.icon-npm:before {
    content: "\e8a8"
}

.icon-options-2:before {
    content: "\e8a9"
}

.icon-options:before {
    content: "\e8aa"
}

.icon-pantone:before {
    content: "\e8ab"
}

.icon-paper-plane:before {
    content: "\e8ac"
}

.icon-pause-circle:before {
    content: "\e8ad"
}

.icon-people:before {
    content: "\e8ae"
}

.icon-percent:before {
    content: "\e8af"
}

.icon-person-add:before {
    content: "\e8b0"
}

.icon-person-delete:before {
    content: "\e8b1"
}

.icon-person-done:before {
    content: "\e8b2"
}

.icon-person-remove:before {
    content: "\e8b3"
}

.icon-person:before {
    content: "\e8b4"
}

.icon-phone-call:before {
    content: "\e8b5"
}

.icon-phone-missed:before {
    content: "\e8b6"
}

.icon-phone-off:before {
    content: "\e8b7"
}

.icon-phone:before {
    content: "\e8b8"
}

.icon-pie-chart:before {
    content: "\e8b9"
}

.icon-pin:before {
    content: "\e8ba"
}

.icon-play-circle:before {
    content: "\e8bb"
}

.icon-plus-circle:before {
    content: "\e8bc"
}

.icon-plus-square:before {
    content: "\e8bd"
}

.icon-plus:before {
    content: "\e8be"
}

.icon-power:before {
    content: "\e8bf"
}

.icon-pricetag:before {
    content: "\e8c0"
}

.icon-printer:before {
    content: "\e8c1"
}

.icon-question-mark-circle:before {
    content: "\e8c2"
}

.icon-question-mark:before {
    content: "\e8c3"
}

.icon-radio-button-off:before {
    content: "\e8c4"
}

.icon-radio-button-on:before {
    content: "\e8c5"
}

.icon-radio:before {
    content: "\e8c6"
}

.icon-recording:before {
    content: "\e8c7"
}

.icon-refresh:before {
    content: "\e8c8"
}

.icon-repeat:before {
    content: "\e8c9"
}

.icon-rewind-left:before {
    content: "\e8ca"
}

.icon-rewind-right:before {
    content: "\e8cb"
}

.icon-save:before {
    content: "\e8cc"
}

.icon-scissors:before {
    content: "\e8cd"
}

.icon-search:before {
    content: "\e8ce"
}

.icon-settings-2:before {
    content: "\e8cf"
}

.icon-settings:before {
    content: "\e8d0"
}

.icon-shake:before {
    content: "\e8d1"
}

.icon-share:before {
    content: "\e8d2"
}

.icon-shield-off:before {
    content: "\e8d3"
}

.icon-shield:before {
    content: "\e8d4"
}

.icon-shopping-bag:before {
    content: "\e8d5"
}

.icon-shopping-cart:before {
    content: "\e8d6"
}

.icon-shuffle-2:before {
    content: "\e8d7"
}

.icon-shuffle:before {
    content: "\e8d8"
}

.icon-skip-back:before {
    content: "\e8d9"
}

.icon-skip-forward:before {
    content: "\e8da"
}

.icon-slash:before {
    content: "\e8db"
}

.icon-smartphone:before {
    content: "\e8dc"
}

.icon-speaker:before {
    content: "\e8dd"
}

.icon-square:before {
    content: "\e8de"
}

.icon-star:before {
    content: "\e8df"
}

.icon-stop-circle:before {
    content: "\e8e0"
}

.icon-sun:before {
    content: "\e8e1"
}

.icon-swap:before {
    content: "\e8e2"
}

.icon-sync:before {
    content: "\e8e3"
}

.icon-text:before {
    content: "\e8e4"
}

.icon-thermometer-minus:before {
    content: "\e8e5"
}

.icon-thermometer-plus:before {
    content: "\e8e6"
}

.icon-thermometer:before {
    content: "\e8e7"
}

.icon-timer:before {
    content: "\e8e8"
}

.icon-toggle-left:before {
    content: "\e8e9"
}

.icon-toggle-right:before {
    content: "\e8ea"
}

.icon-trash-2:before {
    content: "\e8eb"
}

.icon-trash:before {
    content: "\e8ec"
}

.icon-trending-down:before {
    content: "\e8ed"
}

.icon-trending-up:before {
    content: "\e8ee"
}

.icon-tv:before {
    content: "\e8ef"
}

.icon-twitter:before {
    content: "\e8f0"
}

.icon-umbrella:before {
    content: "\e8f1"
}

.icon-undo:before {
    content: "\e8f2"
}

.icon-unlock:before {
    content: "\e8f3"
}

.icon-upload:before {
    content: "\e8f4"
}

.icon-video-off:before {
    content: "\e8f5"
}

.icon-video:before {
    content: "\e8f6"
}

.icon-volume-down:before {
    content: "\e8f7"
}

.icon-volume-off:before {
    content: "\e8f8"
}

.icon-volume-up:before {
    content: "\e8f9"
}

.icon-volume:before {
    content: "\e8fa"
}

.icon-weight:before {
    content: "\e8fb"
}

.icon-wifi-off:before {
    content: "\e8fc"
}

.icon-wifi:before {
    content: "\e8fd"
}

.icon-activity:before {
    content: "\e8fe"
}

.icon-alert-circle-1:before {
    content: "\e8ff"
}

.icon-alert-triangle-1:before {
    content: "\e900"
}

.icon-all-done-1:before {
    content: "\e901"
}

.icon-archive-1:before {
    content: "\e902"
}

.icon-arrow-circle-down-1:before {
    content: "\e903"
}

.icon-arrow-circle-left-1:before {
    content: "\e904"
}

.icon-arrow-circle-right-1:before {
    content: "\e905"
}

.icon-arrow-circle-up-1:before {
    content: "\e906"
}

.icon-arrow-down-1:before {
    content: "\e907"
}

.icon-arrow-ios-down-1:before {
    content: "\e908"
}

.icon-arrow-ios-left-1:before {
    content: "\e909"
}

.icon-arrow-ios-right-1:before {
    content: "\e90a"
}

.icon-arrow-ios-up-1:before {
    content: "\e90b"
}

.icon-arrow-left-1:before {
    content: "\e90c"
}

.icon-arrow-right-1:before {
    content: "\e90d"
}

.icon-arrow-up-1:before {
    content: "\e90e"
}

.icon-arrowhead-down-1:before {
    content: "\e90f"
}

.icon-arrowhead-left-1:before {
    content: "\e910"
}

.icon-arrowhead-right-1:before {
    content: "\e911"
}

.icon-arrowhead-up-1:before {
    content: "\e912"
}

.icon-arrrow-left-1:before {
    content: "\e913"
}

.icon-at-1:before {
    content: "\e914"
}

.icon-attach-1:before {
    content: "\e915"
}

.icon-attach-3:before {
    content: "\e916"
}

.icon-award-1:before {
    content: "\e917"
}

.icon-backspace-1:before {
    content: "\e918"
}

.icon-bar-chart-1:before {
    content: "\e919"
}

.icon-bar-chart-3:before {
    content: "\e91a"
}

.icon-battery-1:before {
    content: "\e91b"
}

.icon-batttery-charging-1:before {
    content: "\e91c"
}

.icon-behance-1:before {
    content: "\e91d"
}

.icon-bell-off-1:before {
    content: "\e91e"
}

.icon-bell-1:before {
    content: "\e91f"
}

.icon-bluetooth-1:before {
    content: "\e920"
}

.icon-bold:before {
    content: "\e921"
}

.icon-book-open-1:before {
    content: "\e922"
}

.icon-book-1:before {
    content: "\e923"
}

.icon-bookmark-1:before {
    content: "\e924"
}

.icon-briefcase-1:before {
    content: "\e925"
}

.icon-browser-1:before {
    content: "\e926"
}

.icon-brush-1:before {
    content: "\e927"
}

.icon-bulb-1:before {
    content: "\e928"
}

.icon-student:before {
    content: "\ea00"
}

.icon-student-1:before {
    content: "\e929"
}

.icon-calendar-add-1:before {
    content: "\e92a"
}

.icon-calendar-1:before {
    content: "\e92b"
}

.icon-camera-1:before {
    content: "\e92c"
}

.icon-camp-1:before {
    content: "\e92d"
}

.icon-cast-1:before {
    content: "\e92e"
}

.icon-car-1:before {
    content: "\e92f"
}

.icon-catalogue-1:before {
    content: "\e930"
}

.icon-checkmark-circle-1:before {
    content: "\e931"
}

.icon-checkmark-circle-3:before {
    content: "\e932"
}

.icon-checkmark-square-copy:before {
    content: "\e933"
}

.icon-checkmark-square-1:before {
    content: "\e934"
}

.icon-checkmark-1:before {
    content: "\e935"
}

.icon-chevron-left-1:before {
    content: "\e936"
}

.icon-chevron-down-1:before {
    content: "\e937"
}

.icon-chevron-right-1:before {
    content: "\e938"
}

.icon-chevron-up-1:before {
    content: "\e939"
}

.icon-clipboard-1:before {
    content: "\e93a"
}

.icon-clock-1:before {
    content: "\e93b"
}

.icon-close-circle-1:before {
    content: "\e93c"
}

.icon-close-1:before {
    content: "\e93d"
}

.icon-cloud-download-1:before {
    content: "\e93e"
}

.icon-cloud-upload-1:before {
    content: "\e93f"
}

.icon-code-download-1:before {
    content: "\e940"
}

.icon-code-1:before {
    content: "\e941"
}

.icon-color-palette-1:before {
    content: "\e942"
}

.icon-collapse-1:before {
    content: "\e943"
}

.icon-compass-1:before {
    content: "\e944"
}

.icon-content-1:before {
    content: "\e945"
}

.icon-color-picker-1:before {
    content: "\e946"
}

.icon-copy-1:before {
    content: "\e947"
}

.icon-corner-down-left-1:before {
    content: "\e948"
}

.icon-corner-down-right-1:before {
    content: "\e949"
}

.icon-corner-left-down-1:before {
    content: "\e94a"
}

.icon-corner-left-up-1:before {
    content: "\e94b"
}

.icon-corner-right-down-1:before {
    content: "\e94c"
}

.icon-corner-right-up-1:before {
    content: "\e94d"
}

.icon-corner-up-left-1:before {
    content: "\e94e"
}

.icon-corner-up-right-1:before {
    content: "\e94f"
}

.icon-credit-card-1:before {
    content: "\e950"
}

.icon-crop-1:before {
    content: "\e951"
}

.icon-cube-1:before {
    content: "\e952"
}

.icon-dashboard-1:before {
    content: "\e953"
}

.icon-diagonal-arrow-left-down-1:before {
    content: "\e954"
}

.icon-diagonal-arrow-left-up-1:before {
    content: "\e955"
}

.icon-diagonal-arrow-right-down-1:before {
    content: "\e956"
}

.icon-diagonal-arrow-right-up-1:before {
    content: "\e957"
}

.icon-download-1:before {
    content: "\e958"
}

.icon-droplet-off-1:before {
    content: "\e959"
}

.icon-droplet-1:before {
    content: "\e95a"
}

.icon-edit-1:before {
    content: "\e95b"
}

.icon-edit-3:before {
    content: "\e95c"
}

.icon-expand-1:before {
    content: "\e95d"
}

.icon-email-1:before {
    content: "\e95e"
}

.icon-external-link-1:before {
    content: "\e95f"
}

.icon-eye-off-1:before {
    content: "\e960"
}

.icon-eye-off-3:before {
    content: "\e961"
}

.icon-eye-1:before {
    content: "\e962"
}

.icon-facebook-1:before {
    content: "\e963"
}

.icon-file-add-1:before {
    content: "\e964"
}

.icon-file-remove-1:before {
    content: "\e965"
}

.icon-file-text-1:before {
    content: "\e966"
}

.icon-file-1:before {
    content: "\e967"
}

.icon-film-1:before {
    content: "\e968"
}

.icon-fire-1:before {
    content: "\e969"
}

.icon-flag-1:before {
    content: "\e96a"
}

.icon-flash-off-1:before {
    content: "\e96b"
}

.icon-flash-1:before {
    content: "\e96c"
}

.icon-flip-1:before {
    content: "\e96d"
}

.icon-flip-3:before {
    content: "\e96e"
}

.icon-folder-add-1:before {
    content: "\e96f"
}

.icon-folder-remove-1:before {
    content: "\e970"
}

.icon-folder-1:before {
    content: "\e971"
}

.icon-funnel-1:before {
    content: "\e972"
}

.icon-gift-1:before {
    content: "\e973"
}

.icon-github-1:before {
    content: "\e974"
}

.icon-globe-1:before {
    content: "\e975"
}

.icon-globe-3:before {
    content: "\e976"
}

.icon-google-1:before {
    content: "\e977"
}

.icon-grid-1:before {
    content: "\e978"
}

.icon-hard-drive-1:before {
    content: "\e979"
}

.icon-hash-1:before {
    content: "\e97a"
}

.icon-headphones-1:before {
    content: "\e97b"
}

.icon-heart-1:before {
    content: "\e97c"
}

.icon-height-1:before {
    content: "\e97d"
}

.icon-home-1:before {
    content: "\e97e"
}

.icon-image-1:before {
    content: "\e97f"
}

.icon-inbox-1:before {
    content: "\e980"
}

.icon-info-1:before {
    content: "\e981"
}

.icon-keypad-1:before {
    content: "\e982"
}

.icon-layers-1:before {
    content: "\e983"
}

.icon-layout-1:before {
    content: "\e984"
}

.icon-link-1:before {
    content: "\e985"
}

.icon-link-3:before {
    content: "\e986"
}

.icon-linkedin-1:before {
    content: "\e987"
}

.icon-list-1:before {
    content: "\e988"
}

.icon-loader-1:before {
    content: "\e989"
}

.icon-lock-1:before {
    content: "\e98a"
}

.icon-log-in-1:before {
    content: "\e98b"
}

.icon-log-out-1:before {
    content: "\e98c"
}

.icon-pie-stats:before {
    content: "\e98d"
}

.icon-map-1:before {
    content: "\e98e"
}

.icon-maximize-1:before {
    content: "\e98f"
}

.icon-menu-1:before {
    content: "\e990"
}

.icon-menu-arrow-1:before {
    content: "\e991"
}

.icon-menu-3:before {
    content: "\e992"
}

.icon-message-circle-1:before {
    content: "\e993"
}

.icon-message-square-1:before {
    content: "\e994"
}

.icon-mic-off-1:before {
    content: "\e995"
}

.icon-mic-1:before {
    content: "\e996"
}

.icon-minimize-1:before {
    content: "\e997"
}

.icon-minus-circle-1:before {
    content: "\e998"
}

.icon-minus-square-1:before {
    content: "\e999"
}

.icon-minus-1:before {
    content: "\e99a"
}

.icon-monitor-1:before {
    content: "\e99b"
}

.icon-moon-1:before {
    content: "\e99c"
}

.icon-more-horizontal-1:before {
    content: "\e99d"
}

.icon-more-vertical-1:before {
    content: "\e99e"
}

.icon-move-1:before {
    content: "\e99f"
}

.icon-music-1:before {
    content: "\e9a0"
}

.icon-navigation-1:before {
    content: "\e9a1"
}

.icon-navigation-3:before {
    content: "\e9a2"
}

.icon-npm-1:before {
    content: "\e9a3"
}

.icon-options-1:before {
    content: "\e9a4"
}

.icon-options-3:before {
    content: "\e9a5"
}

.icon-pantone-1:before {
    content: "\e9a6"
}

.icon-paper-plane-1:before {
    content: "\e9a7"
}

.icon-pause-circle-1:before {
    content: "\e9a8"
}

.icon-people-1:before {
    content: "\e9a9"
}

.icon-percent-1:before {
    content: "\e9aa"
}

.icon-person-add-1:before {
    content: "\e9ab"
}

.icon-person-delete-1:before {
    content: "\e9ac"
}

.icon-person-done-1:before {
    content: "\e9ad"
}

.icon-person-remove-1:before {
    content: "\e9ae"
}

.icon-person-1:before {
    content: "\e9af"
}

.icon-phone-call-1:before {
    content: "\e9b0"
}

.icon-phone-missed-1:before {
    content: "\e9b1"
}

.icon-phone-off-1:before {
    content: "\e9b2"
}

.icon-phone-1:before {
    content: "\e9b3"
}

.icon-pie-chart-1:before {
    content: "\e9b4"
}

.icon-pin-1:before {
    content: "\e9b5"
}

.icon-play-circle-1:before {
    content: "\e9b6"
}

.icon-plus-circle-1:before {
    content: "\e9b7"
}

.icon-plus-square-1:before {
    content: "\e9b8"
}

.icon-plus-1:before {
    content: "\e9b9"
}

.icon-power-1:before {
    content: "\e9ba"
}

.icon-pricetag-1:before {
    content: "\e9bb"
}

.icon-printer-1:before {
    content: "\e9bc"
}

.icon-question-mark-circle-1:before {
    content: "\e9bd"
}

.icon-question-mark-1:before {
    content: "\e9be"
}

.icon-radio-button-off-1:before {
    content: "\e9bf"
}

.icon-radio-button-on-1:before {
    content: "\e9c0"
}

.icon-radio-1:before {
    content: "\e9c1"
}

.icon-recording-1:before {
    content: "\e9c2"
}

.icon-refresh-1:before {
    content: "\e9c3"
}

.icon-repeat-1:before {
    content: "\e9c4"
}

.icon-rewind-left-1:before {
    content: "\e9c5"
}

.icon-rewind-right-1:before {
    content: "\e9c6"
}

.icon-save-1:before {
    content: "\e9c7"
}

.icon-scissors-1:before {
    content: "\e9c8"
}

.icon-search-1:before {
    content: "\e9c9"
}

.icon-settings-1:before {
    content: "\e9ca"
}

.icon-settings-3:before {
    content: "\e9cb"
}

.icon-shake-1:before {
    content: "\e9cc"
}

.icon-share-1:before {
    content: "\e9cd"
}

.icon-shield-off-1:before {
    content: "\e9ce"
}

.icon-shield-1:before {
    content: "\e9cf"
}

.icon-shopping-bag-1:before {
    content: "\e9d0"
}

.icon-shopping-cart-1:before {
    content: "\e9d1"
}

.icon-shuffle-1:before {
    content: "\e9d2"
}

.icon-shuffle-3:before {
    content: "\e9d3"
}

.icon-skip-back-1:before {
    content: "\e9d4"
}

.icon-skip-forward-1:before {
    content: "\e9d5"
}

.icon-slash-1:before {
    content: "\e9d6"
}

.icon-smartphone-1:before {
    content: "\e9d7"
}

.icon-speaker-1:before {
    content: "\e9d8"
}

.icon-square-1:before {
    content: "\e9d9"
}

.icon-star-1:before {
    content: "\e9da"
}

.icon-stop-circle-1:before {
    content: "\e9db"
}

.icon-sun-1:before {
    content: "\e9dc"
}

.icon-swap-1:before {
    content: "\e9dd"
}

.icon-sync-1:before {
    content: "\e9de"
}

.icon-text-1:before {
    content: "\e9df"
}

.icon-thermometer-minus-1:before {
    content: "\e9e0"
}

.icon-thermometer-plus-1:before {
    content: "\e9e1"
}

.icon-thermometer-1:before {
    content: "\e9e2"
}

.icon-timer-1:before {
    content: "\e9e3"
}

.icon-toggle-left-1:before {
    content: "\e9e4"
}

.icon-toggle-right-1:before {
    content: "\e9e5"
}

.icon-trash-1:before {
    content: "\e9e6"
}

.icon-trash-3:before {
    content: "\e9e7"
}

.icon-trending-down-1:before {
    content: "\e9e8"
}

.icon-trending-up-1:before {
    content: "\e9e9"
}

.icon-tv-1:before {
    content: "\e9ea"
}

.icon-twitter-1:before {
    content: "\e9eb"
}

.icon-umbrella-1:before {
    content: "\e9ec"
}

.icon-undo-1:before {
    content: "\e9ed"
}

.icon-unlock-1:before {
    content: "\e9ee"
}

.icon-upload-1:before {
    content: "\e9ef"
}

.icon-video-off-1:before {
    content: "\e9f0"
}

.icon-video-1:before {
    content: "\e9f1"
}

.icon-volume-down-1:before {
    content: "\e9f2"
}

.icon-volume-off-1:before {
    content: "\e9f3"
}

.icon-volume-up-1:before {
    content: "\e9f4"
}

.icon-volume-1:before {
    content: "\e9f5"
}

.icon-weight-1:before {
    content: "\e9f6"
}

.icon-wifi-off-1:before {
    content: "\e9f7"
}

.icon-wifi-1:before {
    content: "\e9f8"
}

.icon-pie-stats-1:before {
    content: "\e9fa"
}

.icon-add-people:before {
    content: "\e9fb"
}

.icon-library:before {
    content: "\e9fc"
}

.icon-megaphone:before {
    content: "\e9fd"
}

.icon-pin-2:before {
    content: "\e9fe"
}

.icon-rocket:before {
    content: "\e9f9"
}

.icon-evaluation:before {
    content: "\e9ff"
}

.icon-company-building:before {
    content: "\ea01"
}

.icon-hourglass:before {
    content: "\ea02"
}

.icon-hourglass-1:before {
    content: "\ea03"
}

.icon-unlink:before {
    content: "\ea04"
}

.icon-flag-2:before {
    content: "\ea05"
}

.icon-certificate:before {
    content: "\2739"
}

body {
    background-color: #f9f9f9
}

html {
    box-sizing: border-box
}

a {
    color: #020d38;
    cursor: pointer;
    text-decoration: none
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

#custom-app {
    display: none
}

.dynamic-table__head,
.dynamic-table__row {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    position: relative
}

.dynamic-table__head__sort {
    display: flex;
    align-items: center
}

.dynamic-table__head__sort .head-sort {
    margin: 0 .5rem 0 0;
    overflow: initial !important
}

.dynamic-table__head__sort .head-sort .icon {
    font-size: 13px;
    display: block;
    margin: -7px 0;
    transition: color .3s ease;
    cursor: pointer
}

.dynamic-table__head__sort .head-sort .icon.active {
    color: #f4997d
}

.dynamic-table__head__sort .head-sort .icon:hover {
    color: #f4997d
}

.dynamic-table__head__sort .label-sort.active {
    color: #f4997d
}

@media (-ms-high-contrast:none) {

    .dynamic-table__head__sort .head-sort,
    .dynamic-table__head__sort ::-ms-backdrop {
        overflow: visible !important
    }
}

.dynamic-table__separator {
    width: 100%;
    height: 1px;
    background-color: rgba(211, 213, 228, .5);
    margin: 1.5rem 0
}

.dynamic-table__row {
    min-height: 60px;
    background-color: #fff;
    margin: 1rem 0
}

.dynamic-table__row.row-hover {
    cursor: pointer;
    transition: all .3s ease
}

.dynamic-table__row.row-hover:hover {
    z-index: 5;
    transform: scale(1.02)
}

.dynamic-table__row.row-hover:hover .cell-actions .cell-actions__icon {
    color: #535a8b
}

.dynamic-table__row.row-shadow {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.dynamic-table__row.row-radius {
    border-radius: 4px
}

.dynamic-table__row.row-with-contents {
    flex-direction: column;
    align-items: start
}

.dynamic-table__row.row--state:before {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.dynamic-table__content {
    display: flex;
    align-items: center;
    width: 100%
}

.dynamic-table__subcontent {
    margin: 1.5rem 0;
    display: none
}

.dynamic-table__subcontent.active {
    display: flex
}

.dynamic-table__cell {
    width: 20%;
    display: flex;
    align-items: center;
    flex: auto;
    font-size: .75rem;
    color: #8c91b4;
    padding: 0 0 0 1rem
}

@media screen and (min-width:20rem) {
    .dynamic-table__cell {
        display: none
    }
}

@media screen and (min-width:32rem) {
    .dynamic-table__cell {
        display: flex
    }
}

@media screen and (min-width:20rem) {
    .dynamic-table__cell.cell-important {
        display: flex
    }
}

.dynamic-table__cell.cell-head-icon i {
    font-size: 1.25rem
}

.dynamic-table__cell.cell-head-icon--small i {
    font-size: 1rem
}

.dynamic-table__cell.cell-head-icon--hover {
    transition: all .3s ease
}

.dynamic-table__cell.cell-head-icon--hover.active {
    color: #feb88a
}

.dynamic-table__cell.cell-head-icon--hover:hover {
    color: #feb88a
}

.dynamic-table__cell.cell-with-icon i {
    font-size: 1.25rem;
    margin: 0 .5rem 0 0
}

.dynamic-table__cell.cell-spacing {
    width: 5%;
    padding: 0
}

.dynamic-table__cell.cell-title {
    padding: 0;
    white-space: nowrap;
    width: 350px
}

.dynamic-table__cell.cell-title,
.dynamic-table__cell.cell-title div {
    overflow: hidden;
    text-overflow: ellipsis
}

.dynamic-table__cell.cell-title div.avatar,
.dynamic-table__cell.cell-title div.cell-session__options,
.dynamic-table__cell.cell-title div.tooltip {
    overflow: visible !important
}

@media screen and (min-width:20rem) {
    .dynamic-table__cell.cell-title {
        width: 150px;
        display: block
    }
}

@media screen and (min-width:32rem) {
    .dynamic-table__cell.cell-title {
        width: 200px
    }
}

@media screen and (min-width:48rem) {
    .dynamic-table__cell.cell-title {
        width: 250px
    }
}

@media screen and (min-width:64rem) {
    .dynamic-table__cell.cell-title {
        width: 350px
    }
}

.dynamic-table__cell.cell-title--small {
    width: 200px
}

@media screen and (min-width:20rem) {
    .dynamic-table__cell.cell-title--small {
        width: 75px;
        display: block
    }
}

@media screen and (min-width:32rem) {
    .dynamic-table__cell.cell-title--small {
        width: 100px
    }
}

@media screen and (min-width:48rem) {
    .dynamic-table__cell.cell-title--small {
        width: 150px
    }
}

@media screen and (min-width:64rem) {
    .dynamic-table__cell.cell-title--small {
        width: 200px
    }
}

.dynamic-table__cell.cell-title--tooltip:hover {
    overflow: initial
}

.dynamic-table__cell.cell-small {
    width: 10%
}

.dynamic-table__cell.cell-medium {
    width: 15%
}

.dynamic-table__cell.cell-large {
    width: 30%
}

.dynamic-table__cell.cell-chart {
    padding: 0
}

.dynamic-table__cell.cell-chart .tooltip i {
    font-size: 1rem
}

.dynamic-table__cell.cell-actions {
    width: auto;
    position: absolute;
    right: 20px
}

.dynamic-table__cell.cell-actions .cell-actions__icon {
    font-size: 1rem;
    color: #8c91b4;
    transition: all .3s ease
}

.dynamic-table__cell.cell-actions .cell-actions__icon:hover {
    color: #535a8b
}

@media screen and (min-width:20rem) {
    .dynamic-table__cell.cell-actions {
        display: flex
    }
}

@media (-ms-high-contrast:none) {

    .cell-title--tooltip:hover,
    .cell-title--tooltip:hover div,
    ::-ms-backdrop {
        overflow: visible
    }
}

.tooltip {
    position: relative
}

.tooltip:after {
    content: attr(data-tooltip);
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    text-align: center;
    white-space: nowrap;
    font-size: .75rem
}

.tooltip:after {
    background: #414776;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 5
}

.tooltip:before {
    background: #414776;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 5
}

.tooltip:before {
    content: " ";
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    border-radius: 30%
}

.tooltip:hover:after,
.tooltip:hover:before {
    opacity: 1;
    visibility: visible
}

.tooltip--medium:after {
    white-space: pre-wrap;
    min-width: 120px
}

.tooltip--large:after {
    white-space: pre-wrap;
    min-width: 180px
}

.tooltip--white:after {
    color: #feb88a
}

.tooltip--white:after {
    background: #fff
}

.tooltip--white:before {
    background: #fff
}

.tooltip--top:after {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 10px))
}

.tooltip--top:before {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 7px)) rotate(45deg)
}

.tooltip--bottom:after {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 10px))
}

.tooltip--bottom:before {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 7px)) rotate(45deg)
}

.tooltip--right:after {
    top: 50%;
    right: 0;
    transform: translate(calc(100% + 18px), -50%);
    text-align: right
}

.tooltip--right:before {
    top: 50%;
    left: 100%;
    transform: translate(calc(100% + 7px), -50%) rotate(45deg)
}

.tooltip--left:after {
    top: 0;
    left: 0;
    transform: translateX(calc(-100% - 10px));
    text-align: left
}

.tooltip--left:before {
    top: 50%;
    left: 0;
    transform: translate(calc(-100% - 7px), calc(-50% - 3px)) rotate(45deg)
}

.tooltip--delay:after,
.tooltip--delay:before {
    transition-delay: .4s
}

.tooltip--delay--long:after,
.tooltip--delay--long:before {
    transition-delay: .8s
}

.tooltip--over:after,
.tooltip--over:before {
    z-index: 2000
}

@media (-ms-high-contrast:none) {

    .tooltip--top:before,
    ::-ms-backdrop {
        transform: translate(-50%, -101%) rotate(45deg)
    }
}

@media (-ms-high-contrast:none) {

    .tooltip--top:after,
    ::-ms-backdrop {
        transform: translate(-50%, -108%)
    }
}

@media (-ms-high-contrast:none) {

    .tooltip--right:before,
    ::-ms-backdrop {
        transform: translate(101%, -50%) rotate(45deg)
    }
}

@media (-ms-high-contrast:none) {

    .tooltip--right:after,
    ::-ms-backdrop {
        transform: translate(108%, -50%)
    }
}

@media (-ms-high-contrast:none) {

    .tooltip--bottom:before,
    ::-ms-backdrop {
        transform: translate(-50%, -101%) rotate(45deg)
    }
}

@media (-ms-high-contrast:none) {

    .tooltip--bottom:after,
    ::-ms-backdrop {
        transform: translate(-50%, 108%)
    }
}

@media (-ms-high-contrast:none) {

    .tooltip--left:before,
    ::-ms-backdrop {
        transform: translate(-101%, -50%) rotate(45deg)
    }
}

@media (-ms-high-contrast:none) {

    .tooltip--left:after,
    ::-ms-backdrop {
        transform: translateX(-103%)
    }
}

.filters-dynamic {
    display: flex;
    height: 30px
}

.simplebar-track.simplebar-horizontal {
    display: none
}

.vimeo-player {
    text-align: center;
    max-width: 100%
}

.vimeo-not-ready {
    height: 10rem;
    background-color: rgba(211, 213, 228, .5);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600
}

.unordered-list {
    margin: 1rem 0 1rem 2rem
}

.unordered-list,
.unordered-list li {
    list-style-type: disc
}

#weavrForm #password {
    border-radius: 4px;
    border: 2px solid #d3d5e4;
    padding: 12px;
    font-size: .875rem;
    background: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all .35s ease;
    margin: 0 0 2rem
}

#weavrForm #password:hover {
    border: 2px solid #5150c4
}

.kyb-container {
    width: 100%
}

.inner-html h1,
.inner-html h2,
.inner-html h3,
.inner-html h4,
.inner-html h5,
.inner-html h6 {
    margin: initial !important;
    padding: initial !important
}

.inner-html ol,
.inner-html ul {
    margin: 14px 0;
    padding-left: 40px
}

.inner-html li {
    margin: 14px 0;
    list-style-type: disc !important
}

.inner-html p {
    margin: 14px 0;
    padding: initial !important;
    line-height: normal !important
}

.inner-html iframe {
    width: 100% !important
}

.inner-html a {
    color: #1e90ff
}

.inner-html a:hover {
    text-decoration: underline
}

.pointer-events-none {
    pointer-events: none
}

.flex {
    display: flex !important
}

.wrap {
    flex-wrap: wrap
}

.flex-grouped {
    display: flex !important;
    margin: 1rem 0 0
}

.center {
    justify-content: center !important
}

.between {
    justify-content: space-between !important
}

.around {
    justify-content: space-around !important
}

.justify-start {
    justify-content: flex-start !important
}

.justify-end {
    justify-content: flex-end !important
}

.align-center {
    align-items: center !important
}

.align-start {
    align-items: flex-start !important
}

.align-end {
    align-items: flex-end !important
}

.align-stretch {
    align-items: stretch !important
}

.direction-column {
    flex-direction: column !important
}

.direction-row {
    flex-direction: row !important
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.display-none {
    display: none !important
}

.overflow-auto {
    overflow: auto
}

.disable-overflow {
    overflow: hidden
}

.cursor-pointer {
    cursor: pointer !important
}

.cursor-not-allowed {
    cursor: not-allowed !important
}

.max-height-100 {
    max-height: 100px
}

.max-height-200 {
    max-height: 200px
}

.max-height-300 {
    max-height: 300px
}

.max-height-400 {
    max-height: 400px
}

.max-height-500 {
    max-height: 500px
}

.max-height-600 {
    max-height: 600px
}

.box {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    padding: 16px
}

.box--shadow {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.color--blue {
    color: #261e6d !important
}

.color--red {
    color: #ffa2a2 !important
}

.color--green {
    color: #49e2af !important
}

.color--purple {
    color: #7c6cde !important
}

.color--orange {
    color: #f9aa81 !important
}

.color--white {
    color: #fff !important
}

.color--grey {
    color: #d3d5e4 !important
}

i.color--blue:before {
    color: #261e6d !important
}

i.color--red:before {
    color: #ffa2a2 !important
}

i.color--green:before {
    color: #49e2af !important
}

i.color--purple:before {
    color: #7c6cde !important
}

i.color--orange:before {
    color: #f9aa81 !important
}

i.color--white:before {
    color: #fff !important
}

i.color--grey:before {
    color: #d3d5e4 !important
}

@media screen and (max-width:20rem) {
    .hidden-xxs {
        display: none !important
    }
}

@media screen and (max-width:32rem) {
    .hidden-xs {
        display: none !important
    }
}

@media screen and (max-width:48rem) {
    .hidden-sm {
        display: none !important
    }
}

@media screen and (max-width:64rem) {
    .hidden-md {
        display: none !important
    }
}

@media screen and (max-width:80rem) {
    .hidden-lg {
        display: none !important
    }
}

@media screen and (max-width:90rem) {
    .hidden-xl {
        display: none !important
    }
}

@media screen and (max-width:20rem) {
    .hidden-text-xxs span {
        display: none !important
    }
}

@media screen and (max-width:32rem) {
    .hidden-text-xs span {
        display: none !important
    }
}

@media screen and (max-width:48rem) {
    .hidden-text-sm span {
        display: none !important
    }
}

@media screen and (max-width:64rem) {
    .hidden-text-md span {
        display: none !important
    }
}

@media screen and (max-width:80rem) {
    .hidden-text-lg span {
        display: none !important
    }
}

@media screen and (max-width:90rem) {
    .hidden-text-xl span {
        display: none !important
    }
}

.text-error {
    margin-top: 1rem;
    font-size: .8rem;
    transition: color .2s cubic-bezier(.175, .885, .32, 2)
}

.text-error.active {
    color: #F44336 !important;
}

.text-error.valid {
    color: #008000 !important;
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadein {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fadeout {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.msc-draggable__dragged {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none
}

.msc-draggable__base-element {
    opacity: .3
}

.read-more {
    max-height: 150px;
    height: 125px;
    -webkit-mask-image: linear-gradient(180deg, #000 50%, transparent);
    mask-image: linear-gradient(180deg, #000 50%, transparent);
    overflow-y: hidden;
    transition: all 1s ease-in;
    margin-bottom: 1rem
}

.read-more~a:first-of-type {
    color: #7d83aa
}

.read-more.extend {
    -webkit-mask-image: none;
    mask-image: none
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    height: 80px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.header--transparent,
.header--transparent .header__nav {
    background-color: transparent !important
}

.header__logo {
    border-right: 1px solid rgba(211, 213, 228, .5);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 21px 30px;
    width: 285px;
    background-color: #fff
}

.header__logo .link-toggle {
    margin-right: 15px;
    display: block
}

@media screen and (min-width:80rem) {
    .header__logo .link-toggle {
        display: none
    }
}

.header__logo .link-toggle i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #b2b5cd;
    transform: rotate(-90deg)
}

.header__logo .logo {
    width: 100%;
    height: 64px;
    max-width: 100%;
    max-height: 64px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer
}

.header__nav .dropdown-search {
    top: 85px;
    left: 30px
}

@media screen and (max-width:48rem) {
    .header__nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        transition: top .35s;
        width: auto;
        padding: 22px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .header__nav.active {
        top: 80px
    }

    .header__nav.active+.header__mobile a {
        transform: rotate(-180deg)
    }
}

@media screen and (min-width:48rem) {
    .header__nav {
        position: relative;
        z-index: -1;
        width: calc(100% - 301px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px
    }
}

.header__nav .nav__void {
    width: 20%
}

.header__nav .nav__camp {
    display: flex;
    align-items: center;
    cursor: pointer
}

@media screen and (min-width:20rem) {
    .header__nav .nav__camp {
        display: none
    }
}

@media screen and (min-width:32rem) {
    .header__nav .nav__camp {
        display: flex
    }
}

.header__nav .nav__camp p {
    margin: 0 20px;
    font-size: 1.125rem
}

.header__nav .nav__group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 -22px;
    min-width: 100%
}

@media screen and (min-width:48rem) {
    .header__nav .nav__group {
        min-width: 385px
    }
}

@media screen and (min-width:20rem) {
    .header__nav .nav__group>* {
        margin: 0 4px
    }
}

@media screen and (min-width:32rem) {
    .header__nav .nav__group>* {
        margin: 0 16px
    }
}

@media screen and (min-width:32rem) {
    .header__nav .nav__group .link-help i {
        margin: 0 10px 0 0
    }
}

.header__nav .nav__group .dropdown-add,
.header__nav .nav__group .dropdown-help {
    top: 80px;
    right: 0
}

.header .header__mobile {
    position: relative;
    width: 75%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.header .header__mobile a {
    padding: 22px;
    transform: rotate(0);
    transition: transform .35s
}

.header .header__mobile a i {
    font-size: 1.375rem
}

@media screen and (min-width:48rem) {
    .header .header__mobile {
        display: none
    }
}

@media (-ms-high-contrast:none) {

    .header .dropdown-add,
    .header .dropdown-help,
    .header ::-ms-backdrop {
        top: 80px;
        right: 0
    }
}

@-webkit-keyframes pulsing {
    0% {
        box-shadow: 0 0 0 0 rgba(249, 170, 129, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(249, 170, 129, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(249, 170, 129, 0)
    }
}

@keyframes pulsing {
    0% {
        box-shadow: 0 0 0 0 rgba(249, 170, 129, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(249, 170, 129, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(249, 170, 129, 0)
    }
}

@-webkit-keyframes pulsing-black {
    0% {
        box-shadow: 0 0 0 0 rgba(140, 145, 180, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(140, 145, 180, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(140, 145, 180, 0)
    }
}

@keyframes pulsing-black {
    0% {
        box-shadow: 0 0 0 0 rgba(140, 145, 180, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(140, 145, 180, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(140, 145, 180, 0)
    }
}

@-webkit-keyframes shaking {
    0% {
        transform: translate(2px, 1px) rotate(0deg)
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg)
    }

    20% {
        transform: translate(-3px) rotate(1deg)
    }

    30% {
        transform: translateY(2px) rotate(0deg)
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg)
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg)
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg)
    }

    70% {
        transform: translate(2px, 1px) rotate(-1deg)
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg)
    }

    90% {
        transform: translate(2px, 2px) rotate(0deg)
    }

    to {
        transform: translate(1px, -2px) rotate(-1deg)
    }
}

@keyframes shaking {
    0% {
        transform: translate(2px, 1px) rotate(0deg)
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg)
    }

    20% {
        transform: translate(-3px) rotate(1deg)
    }

    30% {
        transform: translateY(2px) rotate(0deg)
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg)
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg)
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg)
    }

    70% {
        transform: translate(2px, 1px) rotate(-1deg)
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg)
    }

    90% {
        transform: translate(2px, 2px) rotate(0deg)
    }

    to {
        transform: translate(1px, -2px) rotate(-1deg)
    }
}

.timeline-container {
    z-index: 5
}

.timeline-container .simplebar-mask {
    z-index: 1
}

.timeline-container.timeline-simplebar-hidden .simplebar-track {
    z-index: -1 !important;
    visibility: hidden !important;
    opacity: 0 !important
}

.link-preview {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 0 0 1rem;
    cursor: pointer
}

.link-preview--grey {
    background-color: rgba(211, 213, 228, .2);
    border-radius: 4px;
    transition: all .3s ease
}

.link-preview--grey:hover {
    background-color: rgba(211, 213, 228, .3)
}

.link-preview--timeline {
    flex-direction: column !important
}

.link-preview--timeline .link-preview__content {
    margin: 1rem 0 0 1rem
}

@media screen and (min-width:20rem) {
    .link-preview {
        flex-direction: column
    }
}

@media screen and (min-width:48rem) {
    .link-preview {
        flex-direction: row
    }
}

.link-preview__cover {
    max-width: 250px;
    border-radius: 4px
}

.link-preview__content {
    margin: 0 0 0 1rem;
    font-size: .875rem;
    color: #8c91b4
}

@media screen and (min-width:20rem) {
    .link-preview__content {
        margin: 1rem 0 0 1rem
    }
}

@media screen and (min-width:48rem) {
    .link-preview__content {
        margin: 0 0 0 1rem
    }
}

.link-preview__content__url {
    margin: 0 0 1rem;
    word-break: break-all
}

.link-preview__content__title {
    word-break: break-all
}

.link-preview__content__desc {
    margin: 1rem 0 0;
    word-break: break-all
}

.menu-action {
    display: flex;
    justify-content: space-between;
    max-height: 30px
}

.menu-action__filters {
    display: flex
}

.menu-action__filters msc-button {
    height: 30px;
    margin: 0 0 0 1.5rem
}

.menu-action__filters label {
    margin: .375rem 0 0 1.5rem
}

.menu-action__filters__item {
    position: relative;
    z-index: 10
}

.menu-action__filters__item--no-index {
    z-index: 1
}

.menu-action__buttons {
    display: flex
}

.menu-action__buttons>* {
    margin: 0 0 0 1.5rem
}

.menu-action__buttons__dropdown {
    position: relative
}

.navigation-progress {
    width: 100%;
    height: 1.5rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    opacity: 1;
    transition: all .5s ease
}

.navigation-progress--hidden {
    opacity: 0
}

.navigation-progress-bar {
    height: .18rem;
    width: 0
}

.navigation-progress-bar--start {
    width: 100%;
    transition: all 7s ease-in
}

.navigation-progress-bar--full {
    width: 100%
}

.pinpad {
    max-width: 265px;
    padding: calc(.5em*4);
    background-color: #fff
}

.pinpad__header {
    text-align: center;
    margin-bottom: calc(.5em*3)
}

.pinpad__header h3 {
    color: #7d83aa;
    font-size: 1.25rem;
    line-height: 1.5rem
}

.pinpad__header p {
    color: #b2b5cd;
    font-size: .875rem;
    line-height: 1.125rem
}

.pinpad__body {
    margin: calc(calc(.5em*1)*-1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap
}

.pinpad__footer {
    display: none;
    background: #d3d5e4;
    margin: calc(.5em*2.5) calc(calc(.5em*4)*-1) calc(calc(.5em*4)*-1) calc(calc(.5em*4)*-1);
    padding: calc(.5em*2) calc(.5em*4)
}

.pinpad__footer p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff !important;
    font-size: .875rem;
    line-height: 1.125rem
}

.pinpad__footer i {
    font-size: 1.5rem;
    margin-right: calc(.5em*1)
}

.pinpad__input {
    display: flex;
    align-items: center;
    justify-content: center
}

.pinpad__input span {
    width: 8px;
    height: 8px;
    margin: 24px 10px 0;
    border-radius: 50%;
    background-color: #d3d5e4;
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.pinpad__input span.active {
    background-color: #feb88a
}

.pinpad__input span.pulse {
    background-color: #feb88a
}

.pinpad__input span.pulse {
    transform: scale(1.4)
}

.pinpad__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    font-size: 1.375rem;
    color: #7d83aa;
    background-color: #fff;
    border: 1px solid #d3d5e4;
    margin: calc(.5em*1);
    box-shadow: 0 0 14px 6px rgba(90, 113, 139, .05);
    transform: scale(1)
}

.pinpad__button.pulse {
    background-color: #feb88a;
    color: #fff;
    border-color: #feb88a;
    transition: all 15ms ease-in-out
}

.pinpad__button:hover {
    background-color: #feb88a;
    color: #fff;
    border-color: #feb88a;
    transition: all 15ms ease-in-out
}

.pinpad__button.pulse {
    -webkit-animation: pulse .25s;
    animation: pulse .25s
}

.pinpad__button--check i {
    color: #6cd4a8 !important;
    margin: 0
}

.pinpad__button--back i {
    color: #f44436 !important;
    margin: 0
}

.pinpad__button--disabled {
    cursor: default;
    background-color: rgba(211, 213, 228, .3) !important;
    opacity: .4;
    transition: none
}

.pinpad__button--disabled:hover {
    border: 1px solid #d3d5e4;
    background-color: initial
}

.pinpad--success .pinpad__input span {
    background-color: #6cd4a8
}

.pinpad--success .pinpad__footer {
    display: block;
    background: #6cd4a8
}

.pinpad--alert .pinpad__input span {
    background-color: #f44436
}

.pinpad--alert .pinpad__footer {
    display: block;
    background: #f44436
}

.burger-menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: all .6s ease-in-out
}

.burger-menu.active .burger-menu__item {
    background: transparent;
    transform: translateX(-50px)
}

.burger-menu.active .burger-menu__item:before {
    transform: rotate(45deg) translate(35px, -35px)
}

.burger-menu.active .burger-menu__item:after {
    transform: rotate(-45deg) translate(35px, 35px)
}

.burger-menu__item {
    width: 15px;
    height: 2px;
    background: #f9aa81;
    border-radius: 6px;
    transition: all .6s ease-in-out
}

.burger-menu__item:after {
    width: 15px;
    height: 2px;
    background: #f9aa81;
    border-radius: 6px;
    transition: all .6s ease-in-out
}

.burger-menu__item:before {
    width: 15px;
    height: 2px;
    background: #f9aa81;
    border-radius: 6px;
    transition: all .6s ease-in-out
}

.burger-menu__item:after,
.burger-menu__item:before {
    content: "";
    position: absolute
}

.burger-menu__item:before {
    transform: translateY(-5px)
}

.burger-menu__item:after {
    transform: translateY(5px)
}

.btn-reaction {
    position: relative;
    padding: 0 calc(.5em*1);
    cursor: pointer
}

.btn-reaction__text {
    display: flex;
    align-items: center;
    color: #7d83aa;
    font-size: .75rem;
    transition: .2s
}

.btn-reaction__text i {
    font-size: 1.125rem;
    margin-right: calc(.5em*1)
}

.btn-reaction__text.active {
    color: #ffa2a2
}

.btn-reaction__text.active img {
    margin-right: calc(.5em*1.5)
}

.btn-reaction__text:hover {
    color: #282d58
}

.btn-reaction__text:hover+.btn-reaction__box {
    opacity: 1;
    visibility: visible;
    transition-delay: .3s
}

.btn-reaction__text:hover+.btn-reaction__box .btn-reaction__icon {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: .3s cubic-bezier(.175, .885, .32, 2)
}

.btn-reaction__text:hover+.btn-reaction__box .btn-reaction__icon:first-child {
    transition-delay: .05s
}

.btn-reaction__text:hover+.btn-reaction__box .btn-reaction__icon:nth-child(2) {
    transition-delay: .1s
}

.btn-reaction__text:hover+.btn-reaction__box .btn-reaction__icon:nth-child(3) {
    transition-delay: .15s
}

.btn-reaction__text:hover+.btn-reaction__box .btn-reaction__icon:nth-child(4) {
    transition-delay: .2s
}

.btn-reaction__box {
    position: absolute;
    top: -50px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 0;
    visibility: hidden;
    transform: translateY(16px);
    background: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    border-radius: 50px;
    transition: all .1s ease
}

.btn-reaction__box:hover {
    opacity: 1;
    visibility: visible
}

.btn-reaction__box:hover .btn-reaction__icon {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: transform .2s ease
}

.btn-reaction__box:hover .btn-reaction__icon:hover {
    transform: scale(1.35) translateY(-3px)
}

.btn-reaction__box .btn-reaction__icon {
    width: 42px;
    height: 42px;
    cursor: pointer;
    margin: 4px -3px;
    opacity: 0;
    transform: scale(.6) translateY(25px);
    transition: .2s cubic-bezier(.175, .885, .32, 2)
}

.btn-reaction__box .btn-reaction__icon i {
    color: #fff;
    font-size: 1.125rem;
    background: #5150c4;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    transform: scale(1) translateY(0);
    transition: transform .2s cubic-bezier(.175, .885, .32, 2)
}

.btn-reaction__box .btn-reaction__icon i,
.load-more {
    display: flex;
    align-items: center;
    justify-content: center
}

.load-more {
    margin: 1rem 0
}

@-webkit-keyframes pulsing-btn-load-more {
    0% {
        box-shadow: 0 0 0 0 rgba(244, 153, 125, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(244, 153, 125, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(244, 153, 125, 0)
    }
}

@keyframes pulsing-btn-load-more {
    0% {
        box-shadow: 0 0 0 0 rgba(244, 153, 125, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(244, 153, 125, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(244, 153, 125, 0)
    }
}

.loader__content {
    display: flex;
    width: 65px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto
}

.loader__content span:first-child {
    -webkit-animation: bounce 1s ease-in-out infinite;
    animation: bounce 1s ease-in-out infinite
}

.loader__content span:nth-child(2) {
    -webkit-animation: bounce 1s ease-in-out .33s infinite;
    animation: bounce 1s ease-in-out .33s infinite
}

.loader__content span:nth-child(3) {
    -webkit-animation: bounce 1s ease-in-out .66s infinite;
    animation: bounce 1s ease-in-out .66s infinite
}

@-webkit-keyframes bounce {

    0%,
    75%,
    to {
        transform: translateY(0)
    }

    25% {
        transform: translateY(-20px)
    }
}

.loader__content--light {
    height: auto;
    width: auto
}

.loader__content--light span {
    width: 6px;
    height: 6px;
    background-color: #282d58;
    margin: 8px 3px
}

@keyframes bounce {

    0%,
    75%,
    to {
        transform: translateY(0)
    }

    25% {
        transform: translateY(-10px)
    }
}

.block-notification {
    display: flex;
    flex-direction: row;
    align-content: center;
    width: auto;
    margin: 1rem 0 2rem;
    padding: 20px;
    font-size: 1rem;
    color: #fff;
    border-radius: 4px
}

.block-notification--info {
    background-color: #7986ef
}

.block-notification--info .block-notification__cta:focus {
    background-color: #5360bf;
    border: 1px solid transparent
}

.block-notification--info .block-notification__cta:hover {
    background-color: #5360bf;
    border: 1px solid transparent
}

.block-notification--success {
    background-color: #50c58f
}

.block-notification--success .block-notification__cta:focus {
    background-color: #349d6e;
    border: 1px solid transparent
}

.block-notification--success .block-notification__cta:hover {
    background-color: #349d6e;
    border: 1px solid transparent
}

.block-notification--warning {
    background-color: #edbf5d
}

.block-notification--warning .block-notification__cta:focus {
    background-color: #d39718;
    border: 1px solid transparent
}

.block-notification--warning .block-notification__cta:hover {
    background-color: #d39718;
    border: 1px solid transparent
}

.block-notification--error {
    background-color: #e53936
}

.block-notification--error .block-notification__cta:focus {
    background-color: #b81b1c;
    border: 1px solid transparent
}

.block-notification--error .block-notification__cta:hover {
    background-color: #b81b1c;
    border: 1px solid transparent
}

.block-notification--branded {
    background-color: #4443af
}

.block-notification--branded .block-notification__cta:focus {
    background-color: #17105a;
    border: 1px solid transparent
}

.block-notification--branded .block-notification__cta:hover {
    background-color: #17105a;
    border: 1px solid transparent
}

.block-notification__sentence {
    align-self: center
}

.block-notification__icon {
    margin: 0 10px 0 0;
    font-size: 1.625rem;
    vertical-align: middle
}

.block-notification__cta {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: auto;
    background-color: transparent;
    border: 1px solid #fff
}

.block-notification__cta .icon {
    margin-right: 3px
}

@media screen and (min-width:20rem) {
    .block-notification__cta {
        flex: none
    }
}

.block-notification__close {
    font-size: 1.625rem
}

.block-notification__close--isolated {
    margin-left: auto
}

.block-notification__close:hover {
    cursor: pointer
}

@media screen and (max-width:20rem) {

    .block-notification__close,
    .block-notification__cta,
    .block-notification__icon {
        align-self: center
    }
}

.overlay {
    position: fixed;
    width: calc(100% - 285px);
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(101, 108, 155, .5);
    opacity: 0;
    visibility: hidden
}

@media screen and (max-width:80rem) {
    .overlay {
        width: 100% !important
    }
}

.overlay.active,
.overlay.force-active {
    opacity: 1;
    visibility: visible;
    z-index: 20;
    transition: all .4s cubic-bezier(.455, .03, .515, .955)
}

.overlay.active--light,
.overlay.force-active--light {
    z-index: 10
}

.rate {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.rate .star {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    color: #b2b5cd;
    cursor: pointer
}

.rate .star:last-child {
    margin-right: 0
}

.rate .star.active {
    color: #ffc688
}

.catalogue-glide {
    position: relative
}

.catalogue-glide__margin-card {
    margin: 0 8px !important
}

.catalogue-glide__track {
    overflow: hidden
}

.catalogue-glide__track--light .catalogue-glide__track__content .card-highlight {
    width: 48%;
    margin-right: 1rem
}

.catalogue-glide__track__content {
    display: flex;
    margin: 1rem 0
}

.catalogue-glide__track__content .col-3:first-child {
    margin-left: 0 !important
}

.catalogue-glide__track__content .active {
    border: 2px solid #5150c4;
    border-radius: 6px;
    transition: all .3s ease
}

.catalogue-glide__track__content--modal-on {
    transform: none !important
}

.catalogue-glide__controls .glide-arrow {
    position: absolute;
    top: 40%;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    background: #fff;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    width: 40px;
    height: 40px;
    border-radius: 30px;
    transition: all .3s ease
}

.catalogue-glide__controls .glide-arrow i:before {
    transition: all .3s ease
}

.catalogue-glide__controls .glide-arrow:hover i:before {
    transform: scale(1.25);
    transition: all .3s ease
}

@media only screen and (min-width:768px) and (max-width:1200px) {
    .catalogue-glide__controls .glide-arrow--left {
        top: 17em !important;
        left: 2em !important
    }

    .catalogue-glide__controls .glide-arrow--right {
        top: 17em !important;
        right: 2em !important
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {

    .catalogue-glide__controls .glide-arrow--left,
    .catalogue-glide__controls .glide-arrow--right {
        display: none
    }
}

.catalogue-glide__controls .glide-arrow--left {
    left: -1.5rem
}

.catalogue-glide__controls .glide-arrow--right {
    right: -1.5rem
}

.catalogue-glide__controls .glide-arrow--hidden {
    display: none
}

.catalogue-glide__bullets {
    margin: 20px 0 0;
    text-align: center
}

.catalogue-glide__bullets--flat {
    position: absolute;
    top: -60px;
    right: 0
}

.catalogue-glide__bullets--flat .catalogue-glide__bullets__item {
    background-color: rgba(211, 213, 228, .5);
    width: 25px;
    height: 3px;
    padding: 0;
    cursor: pointer;
    margin: .3rem;
    border-radius: 0;
    transition: all .3s ease
}

.catalogue-glide__bullets--flat .catalogue-glide__bullets__item:hover {
    background-color: #b2b5cd;
    transition: all .3s ease
}

.catalogue-glide__bullets--flat .catalogue-glide__bullets__item.glide__bullet--active {
    background-color: #b2b5cd
}

.catalogue-glide__bullets--white .catalogue-glide__bullets__item {
    background-color: #fff;
    transition: all .3s ease
}

.catalogue-glide__bullets--white .catalogue-glide__bullets__item:hover {
    background-color: #8c91b4;
    transition: all .3s ease
}

.catalogue-glide__bullets--white .catalogue-glide__bullets__item.glide__bullet--active {
    background-color: #8c91b4
}

.glide {
    max-width: 1000px;
    margin: 0 auto;
    position: relative
}

.glide__track {
    overflow: hidden;
    height: 240px
}

@media only screen and (max-width:425px) {
    .glide__track__content li {
        margin: 0 0 10px
    }
    .block-task__title span {
         white-space: nowrap !important;
        }
}

.glide__track--light .glide__track__content {
    width: 960px !important
}

@media only screen and (min-width:700px) and (max-width:768px) {
    .glide__track--light .glide__track__content {
        width: 700px !important
    }
}

.glide__track--light .glide__track__content li {
    width: 245px;
    margin: 0 5px
}

.glide__track__content {
    display: flex;
    justify-content: center
}

.glide__controls .glide-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 30px));
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    background: #fff;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    width: 40px;
    height: 40px;
    border-radius: 30px;
    transition: all .3s ease
}

.glide__controls .glide-arrow i:before {
    transition: all .3s ease
}

.glide__controls .glide-arrow:hover i:before {
    transform: scale(1.25);
    transition: all .3s ease
}

.glide__controls .glide-arrow--left {
    left: -3em
}

.glide__controls .glide-arrow--right {
    right: -3em
}

.glide__controls .glide-arrow--hidden {
    display: none
}

@media (-ms-high-contrast:none) {

    .glide__controls .glide-arrow,
    .glide__controls ::-ms-backdrop {
        transform: none;
        top: 5.5em !important
    }
}

.dynamic-table__row.row--state:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: #d3d5e4
}

.dynamic-table__row.row--succeed:before {
    background-color: #6cd4a8
}

.dynamic-table__row.row--in-progress:before {
    background-color: #8290fd
}

.dynamic-table__row .cell-activity {
    min-width: 30%;
    margin-left: 0;
    color: #8290fd
}

.dynamic-table__row .cell-activity span {
    color: #282d58;
    font-size: .875rem
}

.table__header .table__row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 32px
}

.table__header .table__head {
    display: inline-flex;
    padding: 0;
    min-width: calc(20% - 30px);
    margin-left: 30px
}

.table__header .table__head--large {
    min-width: 25%
}

.table__header .table__head--center {
    margin-left: 30px
}

.table__header .table__head--center>* {
    margin: 0 auto
}

.table__header .table__head--checkbox {
    width: 28px;
    margin-right: 44px
}

.table__header .table__head--checkbox i {
    font-size: 1.5rem;
    color: #b2b5cd
}

.table__item {
    margin: 16px 0
}

.table__separator {
    margin-top: 32px;
    padding-top: 32px;
    border-bottom: 1px solid #d3d5e4
}

.table__section-line {
    display: flex;
    flex-direction: row;
    color: #b2b5cd;
    font-size: .875rem;
    padding-right: 10px
}

.table__section-line:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #d3d5e4;
    margin: auto
}

.table__section-line-text {
    padding: 0 10px 0 0
}

.table-stats-question {
    margin-top: 2rem
}

.table-stats-question .dynamic-table__row {
    border-radius: .3rem
}

.table-stats-question .dynamic-table__row.row--state:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: #d3d5e4
}

.table-stats-question .dynamic-table__row.row--succeed:before {
    background-color: #6cd4a8
}

.table-stats-question .dynamic-table__row.row--in-progress:before {
    background-color: #8290fd
}

.slide-mail-user .slide__mail-tab {
    margin-top: 2%;
    height: 50%
}

.slide-mail-user .slide__mail-tab p {
    margin: 10px 0
}

.slide-mail-user .slide__mail-tab .textarea {
    height: 60%
}

.slide-filter .group__checkbox {
    margin: 20px 0 -12px
}

.slide-filter .group__radio {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
    margin-left: -16px;
    margin-right: -16px
}

.slide-filter .group__radio>* {
    margin-left: 16px;
    margin-right: 16px
}

.slide-filter .slide__tab span {
    margin-bottom: 12px
}

.slide-filter .slide__tab__description {
    font-size: .625rem;
    font-style: italic
}

.slide-filter .slide__tab .slide__range {
    margin: 20px 0;
    width: 95%
}

.slide-filter .slide__tab--large {
    padding: 26px 38px 46px
}

.slide-filter .slide__checkbox {
    margin: 12px 0;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.slide-filter .slide__checkbox label {
    color: #020d38;
    font-size: .75rem;
    margin: 0 !important
}

.slide-filter .group__select .multi-select {
    flex-direction: column
}

.slide-filter .group__select .select__content {
    width: 100%;
    min-height: 18px
}

.slide-filter .group__select .dropdown--simple {
    top: 50px;
    left: 0
}

.slide-filter .group__select .dropdown-select--filters {
    position: absolute;
    top: 10px;
    width: 100%
}

.slide-filter .group__skill,
.slide-filter .group__skill form {
    margin: 0 0 1rem
}

.slide-filter .group__skill .skill-list--collection .skill {
    background-color: rgba(211, 213, 228, .3);
    transition: all .3s ease
}

.slide-filter .group__skill .skill-list--collection .skill:hover {
    background-color: rgba(211, 213, 228, .8)
}

.slide-filter .group__skill .skill-list--selected .skill {
    background-color: rgba(211, 213, 228, .3);
    transition: all .3s ease
}

.slide-filter .group__skill .skill-list--selected .skill:hover {
    background-color: rgba(211, 213, 228, .8)
}

.slide-filter .group__skill .skill-list--choice {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0 0
}

.slide-filter .group__skill .skill-list--choice .skill {
    display: flex;
    align-items: center;
    height: 28px
}

.slide-filter .group__skill .skill-list--choice .skill__level span {
    margin-bottom: 0
}

.slide-filter .group__skill .skill-list--choice .skill.active {
    background-color: rgba(211, 213, 228, .8)
}

.slide-filter .group__skill .skill-list--choice .skill:hover {
    background-color: rgba(211, 213, 228, .8)
}

.banner-alert {
    width: 100%;
    height: 45px;
    background-color: #e57373;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    position: fixed;
    top: 0;
    z-index: 2000;
    transform: translateY(-45px);
    transition: all .5s ease
}

.banner-alert.active {
    transform: translateY(0);
    transition: all .5s ease
}

@media screen and (max-width:32rem) {
    .banner-alert {
        height: 80px;
        transform: translateY(-80px);
        flex-direction: column
    }
}

.banner-alert__title {
    display: flex;
    align-items: center;
    font-size: 1rem
}

@media screen and (max-width:32rem) {
    .banner-alert__title {
        flex-direction: column;
        font-size: .625rem;
        margin: .5rem 0
    }
}

@media screen and (min-width:48rem) {
    .banner-alert__title {
        font-size: .625rem
    }
}

@media screen and (min-width:64rem) {
    .banner-alert__title {
        font-size: .875rem
    }
}

@media screen and (min-width:80rem) {
    .banner-alert__title {
        font-size: 1rem
    }
}

.banner-alert__title .title-desc {
    color: #fff;
    margin: 0 0 0 .5rem
}

.banner-alert__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 40px
}

.banner-alert__close {
    font-size: 1.125rem;
    color: #fff;
    width: 40px;
    height: auto;
    justify-content: flex-end;
    display: flex;
    cursor: pointer
}

.banner-alert__close i {
    color: #fff;
    transition: color .5s ease
}

.banner-alert__close:hover i {
    color: #261e6d;
    transition: color .5s ease
}

@media screen and (max-width:48rem) {
    .banner-alert__close {
        position: absolute;
        top: 5px;
        right: 2%
    }
}

.banner {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    visibility: hidden;
    position: fixed;
    top: 0;
    z-index: 2000;
    transform: translateY(-1000px);
    transition: all .5s cubic-bezier(.77, 0, .175, 1)
}

.banner.active {
    visibility: visible;
    transform: translateY(0)
}

.banner a {
    color: #fff;
    font-size: .75rem
}

.banner p {
    color: #fff;
    font-size: .75rem
}

.banner a {
    display: block
}

.banner a:hover {
    cursor: pointer
}

@media screen and (max-width:80rem) {
    .banner {
        width: 100%;
        left: 0
    }
}

@media screen and (max-width:48rem) {
    .banner {
        flex-direction: column;
        height: 60px;
        padding: 10px
    }
}

@media screen and (max-width:32rem) {
    .banner {
        text-align: center;
        height: 80px;
        padding-top: 30px;
        height: 120px
    }
}

.banner__right,
.banner__right p {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.banner__right p {
    margin-right: 15px
}

.banner__right .banner__link {
    font-weight: 600;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 10px;
    transition: all .3s ease
}

.banner__right .banner__close {
    font-size: 1.125rem;
    color: #fff;
    width: 40px;
    height: auto;
    justify-content: flex-end;
    display: flex;
    transition: color .8s ease;
    cursor: pointer
}

@media screen and (max-width:48rem) {
    .banner__right .banner__close {
        position: absolute;
        top: 5px;
        right: 2%
    }
}

.banner__left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff
}

.banner__left .banner__description {
    margin-right: 40px
}

.banner__left .banner__description span {
    color: #fff;
    font-weight: 700;
    font-size: .875rem
}

.banner__left .banner__date {
    color: #fff
}

@media screen and (max-width:20rem) {
    .banner__left .banner__date {
        margin-top: 5px
    }
}

.banner__left .banner__number {
    font-weight: 700;
    margin-left: 10px
}

@media screen and (max-width:20rem) {
    .banner__left {
        flex-direction: column
    }
}

.banner-theme {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    position: fixed;
    top: 0;
    z-index: 2000;
    transform: translateY(-45px);
    transition: all .5s ease
}

.banner-theme.active {
    transform: translateY(0);
    transition: all .5s ease
}

@media screen and (max-width:32rem) {
    .banner-theme {
        height: 80px;
        transform: translateY(-80px);
        flex-direction: column
    }
}

.banner-theme__title {
    display: flex;
    align-items: center;
    font-size: 1rem
}

@media screen and (max-width:32rem) {
    .banner-theme__title {
        flex-direction: column;
        font-size: .625rem;
        margin: .5rem 0
    }
}

@media screen and (min-width:48rem) {
    .banner-theme__title {
        font-size: .625rem
    }
}

@media screen and (min-width:64rem) {
    .banner-theme__title {
        font-size: .875rem
    }
}

@media screen and (min-width:80rem) {
    .banner-theme__title {
        font-size: 1rem
    }
}

.banner-theme__title .title-preview {
    text-transform: uppercase
}

.banner-theme__title .title-preview span {
    margin: 0 0 0 .5rem
}

.banner-theme__title .title-desc {
    font-size: 1rem;
    color: #fff;
    margin: 0 0 0 .5rem
}

.banner-theme__title .title-content {
    font-size: 1rem;
    color: #fff;
    margin: 0 1.5rem
}

.banner-theme__progress {
    width: 30%
}

.banner-theme__actions {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem
}

.banner-theme__actions msc-button {
    height: 32px
}

@media screen and (min-width:20rem) {
    .banner-theme__actions msc-button {
        height: 22px;
        padding: 0 10px;
        font-size: .5rem;
        margin: 0 0 .5rem
    }
}

@media screen and (min-width:48rem) {
    .banner-theme__actions msc-button {
        height: 22px;
        padding: 0 10px;
        font-size: .625rem;
        margin: 0 0 0 1rem
    }
}

@media screen and (min-width:64rem) {
    .banner-theme__actions msc-button {
        height: 32px;
        padding: 8px 15px;
        font-size: .875rem
    }
}

.banner-theme__actions msc-button:first-child {
    margin: 0 1rem 0 0
}

@media screen and (max-width:32rem) {
    .banner-theme__actions msc-button:first-child {
        margin: 0 1rem .5rem 0
    }
}

.bar {
    position: fixed;
    background-color: #fff;
    width: calc(100% - 285px);
    left: 285px;
    padding: 25px 100px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    display: flex;
    justify-content: space-between;
    align-items: center
}

.bar--bottom {
    bottom: 0
}

.bar__data {
    font-size: .875rem
}

.bar__actions {
    display: flex
}

.bar__actions__item {
    margin: 0 5px
}

.bar__actions__item:hover i {
    color: #ffa2a2;
    transition: all .3s ease
}

.bar__actions__item i {
    font-size: 1.25rem;
    color: rgba(2, 13, 56, .7);
    transition: all .3s ease
}

.block-coach {
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    padding: 15px 15px 35px;
    text-align: center;
    cursor: pointer;
    transform: scale(1);
    box-shadow: 0;
    transition: all .2s ease
}

.block-coach * {
    display: block
}

.block-coach__pending {
    position: absolute;
    top: 0;
    right: 0
}

@media screen and (min-width:48rem) {
    .block-coach__pending {
        top: -4px;
        right: 24px
    }
}

.block-coach__pending i {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ed8371;
    color: #fff;
    width: 20px;
    height: 20px;
    font-size: 1rem
}

@media screen and (min-width:48rem) {
    .block-coach__pending i {
        width: 38px;
        height: 38px;
        font-size: 1.5rem
    }
}

.block-coach__avatar {
    position: relative;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 100%
}

@media screen and (min-width:80rem) {
    .block-coach__avatar {
        width: 100px;
        height: 100px
    }
}

@media screen and (min-width:90rem) {
    .block-coach__avatar {
        width: 132px;
        height: 132px
    }
}

.block-coach__status {
    bottom: -2px
}

.block-coach__action,
.block-coach__status {
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
}

.block-coach__action {
    bottom: 15px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease
}

.block-coach__infos {
    margin-bottom: 10px
}

.block-coach .avatar {
    width: 100%;
    height: auto;
    margin: 0 auto
}

@media screen and (min-width:48rem) {
    .block-coach .avatar {
        width: 132px
    }
}

@media screen and (min-width:80rem) {
    .block-coach .avatar {
        width: 90px
    }
}

@media screen and (min-width:90rem) {
    .block-coach .avatar {
        width: 132px
    }
}

.block-coach h2 {
    font-weight: 300;
    margin-bottom: 30px
}

.block-coach p {
    font-size: .875rem;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 400
}

.block-coach span {
    margin-top: 12px;
    font-size: .875rem;
    color: #7d83aa
}

.block-coach msc-button {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto
}

.block-coach--hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1);
    transform: scale(1.01)
}

.block-coach--hover:hover .block-coach__action {
    opacity: 1;
    visibility: visible
}

.blocks-header-sessions {
    position: relative;
    background: #fff;
    display: block;
    height: auto;
    margin-bottom: calc(.5em*3);
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:48rem) {
    .blocks-header-sessions {
        display: flex
    }
}

@media screen and (min-width:64rem) {
    .blocks-header-sessions {
        align-items: center;
        justify-content: space-between;
        height: 70px;
        margin-bottom: 0
    }
}

.blocks-header-sessions>* {
    padding: calc(.5em*2)
}

@media screen and (min-width:64rem) {
    .blocks-header-sessions>* {
        padding: calc(.5em*4)
    }
}

.blocks-header-sessions__bookmark {
    position: absolute;
    top: 0;
    left: 0
}

.blocks-header-sessions__name {
    display: flex;
    align-items: center;
    margin: 0
}

@media screen and (min-width:20rem) {
    .blocks-header-sessions__name {
        margin: 0 0 0 15px
    }
}

@media screen and (min-width:64rem) {
    .blocks-header-sessions__name {
        margin: 0 0 0 20px
    }
}

.blocks-header-sessions__name img {
    max-height: 10px;
    margin: 0 .5rem 0 0
}

.blocks-header-sessions__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

@media screen and (min-width:48rem) {
    .blocks-header-sessions__title {
        min-width: 40%
    }
}

.blocks-header-sessions__title h3 {
    font-size: 1.375rem;
    color: #020d38
}

.blocks-header-sessions__title--alert h3 {
    position: relative
}

.blocks-header-sessions__title--alert h3:after {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    top: 0;
    left: -8px;
    border-radius: 50%;
    background-color: #feb88a
}

.blocks-header-sessions__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -16px
}

@media screen and (min-width:48rem) {
    .blocks-header-sessions__actions {
        justify-content: flex-end;
        width: 60%
    }
}

.blocks-header-sessions__actions>* {
    margin: 0 16px
}

.blocks-header-sessions__actions p {
    color: #8c91b4;
    margin: 0 8px
}

.blocks-header-sessions__actions i {
    font-size: 1.25rem
}

.blocks-header-sessions__dropdown {
    position: absolute;
    top: calc(.5em*2);
    right: calc(.5em/4)
}

@media screen and (min-width:32rem) {
    .blocks-header-sessions__dropdown {
        position: static
    }
}

.blocks-header-sessions__counts {
    display: flex;
    align-items: center;
    margin: 0 10px
}

.blocks-header-sessions__switch {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.blocks-header-sessions__nav {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: calc(.5em*2.5) calc(.5em*2) 0 calc(.5em*2) !important
}

@media screen and (min-width:32rem) {
    .blocks-header-sessions__nav {
        margin: 0 calc(.5em*2) !important
    }
}

.blocks-header-sessions__nav a {
    display: inline-block;
    outline: none;
    margin: 10px;
    font-size: 1.5rem;
    color: #feb88a;
    background-color: rgba(254, 184, 138, .1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.blocks-header-sessions__nav a:hover {
    transform: scale(1.04);
    color: #ed8371
}

.blocks-header-sessions__nav a i {
    font-size: 1.5rem
}

.blocks-header-sessions .bookmark--large.bookmark {
    position: relative;
    display: block;
    height: 42px;
    width: 42px;
    background-color: #d3d5e4;
    -webkit-clip-path: polygon(0 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 100%, 0 100%);
    transform: rotate(90deg);
    border-radius: 0 0 0 4px;
    cursor: pointer
}

.blocks-header-sessions .bookmark--large.bookmark .bookmark__star {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3px;
    color: #fff;
    font-size: 1rem;
    transform: rotate(-20deg)
}

@media (-ms-high-contrast:none) {

    .blocks-header-sessions .bookmark,
    .blocks-header-sessions ::-ms-backdrop {
        width: 24px !important;
        height: 24px !important
    }
}

.box-header-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 32px
}

.box-header-stats__data {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.box-header-stats__data:first-child p {
    margin-right: 42px
}

.box-header-stats p {
    font-size: .875rem;
    color: #b2b5cd
}

.box-header-user-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 32px
}

.box-header-user-stats__data {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.box-header-user-stats__data:first-child p {
    margin-right: 42px
}

.box-header-user-stats p {
    font-size: .75rem;
    color: #656c9b
}

.box-header-user-stats__status {
    color: #8290fd !important
}

.box-header-user-stats__training-time span {
    color: #8290fd !important
}

.box-header-user-stats__button msc-button {
    border-color: #5150c4;
    color: #5150c4;
    font-size: .75rem;
    font-weight: lighter;
    padding: 4px 8px
}

.block-participant {
    margin-top: -16px
}

.block-participant__body {
    padding: 6px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-participant__body:not(:first-child) {
    margin-top: 1rem
}

.block-participant__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .875rem;
    color: #b2b5cd;
    margin: 8px 0;
    cursor: pointer
}

.block-participant__title i {
    font-size: .875rem;
    transform: scale(1);
    transition: transform .2s ease
}

.block-participant__title:hover i {
    transform: scale(1.5)
}

.block-participant__title:hover .icon-add-people {
    transform: scale(1)
}

.block-participant__title .icon-add-people {
    font-size: 1.5rem
}

.block-participant__title.active i {
    transform: rotate(180deg)
}

.block-participant__title.active+.block-participant__list {
    display: block
}

.block-participant__list {
    margin: -5px 0;
    display: none
}

.block-participant__item {
    margin: 12px 3px;
    border: 1px solid #d3d5e4;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px
}

.block-participant__item--no-border {
    border: none
}

.block-participant__infos {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: 70%
}

.block-participant__infos .avatar {
    width: 32px;
    min-width: 32px;
    height: 32px
}

.block-participant__infos p {
    font-size: .8rem
}

.block-participant__status {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #b2b5cd;
    margin: 0 5px
}

.block-participant__status--online {
    background-color: #49e2af
}

.block-participant__status--offline {
    background-color: #ffa2a2
}

.block-participant__actions {
    margin: 0 0 0 -4px;
    width: 30%;
    display: flex;
    justify-content: flex-end
}

@media screen and (min-width:80rem) {
    .block-participant__actions {
        margin: 0 0 0 -8px
    }
}

.block-participant__actions a {
    margin: 0 4px
}

@media screen and (min-width:80rem) {
    .block-participant__actions a {
        margin: 0 8px
    }
}

.block-participant__actions a i {
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2);
    color: #d3d5e4
}

.block-participant__actions a:hover i {
    color: #414776;
    transform: scale(1.5)
}

.box-table-participant-stats>* {
    max-width: 240px;
    width: 100%
}

.box-table-participant-stats>:first-child {
    max-width: 320px;
    width: 100%
}

.box-table-participant-stats>:last-child {
    width: auto
}

.box-table-participant-stats__chart {
    margin: 0 20px 0 -20px
}

.box-table-participant-stats__paragraph {
    font-size: .75rem;
    color: #b2b5cd
}

.box-table-participant-stats__paragraph--red {
    color: #ffa2a2
}

.box-table-participant-stats__actions {
    position: absolute;
    top: 50%;
    right: 32px;
    z-index: 1;
    transform: translateY(-50%)
}

.box-table-participant-stats--disabled .box-table-participant-stats__contact {
    max-width: 170px;
    width: 100%
}

.box-table-stats {
    box-shadow: none;
    border: 1px solid #d3d5e4;
    padding-top: 8px;
    padding-bottom: 8px
}

.box-table-stats--unsubscribe {
    opacity: .5
}

.box-table-stats .box-table__chart {
    width: 20% !important;
    margin-left: -10px
}

.box-table-stats-details {
    position: relative;
    padding: 8px 32px;
    border: 1px solid #d3d5e4;
    cursor: pointer;
    transform: scale(1);
    box-shadow: 0 1px 1px 0 rgba(10, 22, 70, .1);
    transition: all .2s ease
}

.box-table-stats-details:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: #d3d5e4
}

.box-table-stats-details:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.box-table-stats-details:hover .box-table__actions i {
    color: #282d58
}

.box-table-stats-details.disabled-hover {
    cursor: default !important
}

.box-table-stats-details.disabled-hover:hover {
    transform: scale(1) !important;
    box-shadow: 0 1px 1px 0 rgba(10, 22, 70, .1)
}

.box-table-stats-details>.box-table__paragraph {
    width: 16.66667% !important
}

.box-table-stats-details .box-table__chart {
    width: 140px !important
}

.box-table-stats-details .box-table__actions--arrow {
    right: 16px;
    top: calc(50% - 13px)
}

.box-table-stats-details--succeed:before {
    background-color: #6cd4a8
}

.box-table-stats-details--in-progress:before {
    background-color: #8290fd
}

.box-table-stats-details .box-table__activity {
    min-width: 30%;
    margin-left: 0
}

.box-table-stats-question {
    position: relative;
    padding: 8px 22px;
    border: 1px solid #d3d5e4;
    cursor: pointer;
    transform: scale(1);
    box-shadow: 0 1px 1px 0 rgba(10, 22, 70, .1);
    transition: all .2s ease
}

.box-table-stats-question:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: #d3d5e4
}

.box-table-stats-question:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.box-table-stats-question:hover .box-table__actions i {
    color: #282d58
}

.box-table-stats-question>* {
    min-width: calc(20% - 30px);
    margin-left: 30px
}

.box-table-stats-question .box-table__buttons {
    min-width: 24px
}

.box-table-stats-question .box-table__buttons .icon-refresh {
    display: block;
    color: #a6b0fd;
    transform: rotate(0deg);
    transition: all .4s ease;
    font-size: 1.5rem
}

.box-table-stats-question .box-table__buttons .icon-refresh:hover {
    color: #8290fd;
    transform: rotate(1turn)
}

.box-table-stats-question .box-table__actions {
    text-align: right
}

.box-table-stats-question .box-table__actions--arrow {
    right: 16px;
    top: calc(50% - 13px)
}

.box-table-stats-question .box-table__chart:last-child {
    display: none
}

.box-table-stats-question--succeed:before {
    background-color: #6cd4a8
}

.box-table-stats-question--in-progress:before {
    background-color: #8290fd
}

.box-table-stats-question .box-table__activity {
    min-width: 30%;
    margin-left: 0
}

.composer {
    background-color: #fff;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    padding: 15px;
    position: relative
}

.composer--grey {
    background-color: rgba(211, 213, 228, .2);
    border: none;
    border-radius: 0
}

.composer__content {
    display: flex
}

.composer__content .composer__message {
    width: 100%;
    margin: 0 25px;
    transform: translateY(25%)
}

.composer__content .composer__message--no-transform {
    transform: none
}

.composer__content .composer__message .composer__text {
    background: transparent;
    resize: none;
    width: 100%;
    min-height: 30px;
    padding: 0;
    margin-bottom: 0;
    border: none
}

@media (-ms-high-contrast:none) {

    .composer__content .composer__message .composer__text,
    .composer__content .composer__message ::-ms-backdrop {
        overflow: hidden
    }
}

.composer__footer {
    margin: 10px 0 0
}

.composer__footer .composer__input {
    margin: 0 0 10px
}

.composer__footer .composer__input input {
    color: #17105a;
    width: 100%;
    padding: 10px;
    background-color: rgba(211, 213, 228, .6);
    border: none;
    border-radius: 4px
}

.composer__footer .composer__tags {
    display: flex;
    font-size: .75rem
}

.composer__footer .composer__tags .tag {
    margin: 0 5px 0 0
}

.composer__footer .composer__tags .tag.active {
    background-color: #5150c4
}

.composer__footer .composer__tags .tag.active .tag__text {
    color: #fff
}

.composer__footer .composer__tags .tag:hover {
    background-color: #5150c4;
    transition: all .3s ease
}

.composer__footer .composer__tags .tag:hover .tag__text {
    color: #fff
}

.composer__footer .composer__actions {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d3d5e4;
    margin: 10px 0 0;
    padding: 10px 0 0
}

.composer__footer .composer__actions .composer__quick a {
    color: #b2b5cd;
    font-size: 1.375rem
}

.composer__footer .composer__actions .composer__quick a i {
    cursor: pointer;
    transition: all .3s ease;
    margin: 0 5px 0 0
}

.composer__footer .composer__actions .composer__quick a i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.composer__footer .composer__actions .composer__send {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 -8px
}

.composer__footer .composer__actions .composer__send>* {
    margin: 0 8px
}

.composer__footer .composer__actions .composer__send .composer__camp .dropdown-camp .dropdown-actions__content {
    min-width: 300px;
    top: 45px;
    right: -70px
}

@media screen and (max-width:32rem) {
    .composer__footer .composer__actions .composer__send .composer__camp .dropdown-camp .dropdown-actions__content {
        top: 50px;
        right: -110px
    }
}

.composer__footer .composer__preview {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    margin: 20px 0 0;
    position: relative
}

.composer__footer .composer__preview .preview__picture {
    display: flex
}

.composer__footer .composer__preview .preview__picture img {
    width: 100%;
    border-radius: 4px
}

.composer__footer .composer__preview .preview__embed {
    display: flex;
    width: 100%;
    min-height: 300px;
    max-height: 300px
}

.composer__footer .composer__preview .preview__file {
    display: flex
}

.composer__footer .composer__preview .preview__close {
    font-size: 1.5rem;
    color: #b2b5cd;
    position: absolute;
    right: 0;
    cursor: pointer;
    transition: all .3s ease
}

.composer__footer .composer__preview .preview__close:hover {
    color: #7d83aa;
    transition: all .3s ease
}

.composer__footer .composer__preview .preview__remove {
    position: absolute;
    z-index: 10;
    top: -5px;
    right: -5px
}

.composer--light .composer__footer {
    height: 0;
    margin: 0
}

.composer--light .composer__footer .composer__input,
.composer--light .composer__footer .composer__tags {
    opacity: 0;
    transition: all .1s ease
}

.composer--light .composer__footer .composer__actions {
    border: none
}

.composer--light .composer__footer .composer__actions .composer__quick {
    opacity: 0;
    transition: all .1s ease
}

.composer--light .composer__footer .composer__actions .composer__send {
    position: absolute;
    top: 25%;
    right: 15px
}

.composer--light .composer__footer .composer__preview {
    display: none
}

.composer--light.active .composer__footer {
    height: auto;
    margin: 10px 0 0
}

.composer--light.active .composer__footer .composer__input,
.composer--light.active .composer__footer .composer__tags {
    opacity: 1;
    transition: all 1.5s ease
}

.composer--light.active .composer__footer .composer__actions {
    border-top: 1px solid #d3d5e4
}

.composer--light.active .composer__footer .composer__actions .composer__quick {
    opacity: 1;
    transition: all 1.5s ease
}

.composer--light.active .composer__footer .composer__actions .composer__send {
    position: relative;
    top: auto;
    right: auto
}

.composer--light.active .composer__footer .composer__preview {
    display: flex
}

.composer--tiny {
    padding: 8px;
    margin: 8px 0 16px;
    border-radius: 0 0 4px 4px
}

.composer--tiny .composer__content {
    margin-top: -6px
}

.composer--tiny .composer__content .composer__message {
    transform: translateY(0);
    margin: 0 16px
}

.composer--tiny .composer__content .composer__text {
    margin-top: 12px;
    padding: 6px 12px;
    background: #fff;
    width: 100%;
    min-height: auto;
    margin-bottom: 0;
    border-radius: 0 0 4px 4px;
    border: 2px solid #b2b5cd;
    color: #17105a
}

.composer--tiny .composer__actions,
.composer--tiny .composer__profile {
    margin-top: 8px
}

.datepicker .actions {
    bottom: -40px !important
}

.datepicker {
    position: absolute;
    z-index: 1000;
    padding: calc(.5em*3);
    background: #fff;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 800px
}

.daterangepicker {
    position: absolute;
    z-index: 1000;
    padding: calc(.5em*3);
    background: #fff;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 800px
}

.datepicker--menu,
.daterangepicker--menu {
    top: 190px
}

.datepicker--menu,
.datepicker--menu .actions,
.daterangepicker--menu,
.daterangepicker--menu .actions {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px
}

.datepicker.daterangepicker--side-by-side,
.daterangepicker.daterangepicker--side-by-side {
    flex-direction: row;
    flex-wrap: wrap;
    left: 50%;
    min-width: 800px;
    transform: translate(-50%)
}

.datepicker.daterangepicker--side-by-side .calendar,
.daterangepicker.daterangepicker--side-by-side .calendar {
    width: auto
}

.datepicker:before {
    position: absolute;
    top: -3px;
    left: 40px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 1px;
    background: #fff;
    transform: rotate(-45deg)
}

/* .daterangepicker:before{
    position:absolute;
    top:-3px;
    left:40px;
    content:"";
    width:6px;
    height:6px;
    border-radius:1px;
    background:#fff;
    transform:rotate(-45deg)
} */
.datepicker .calendar,
.daterangepicker .calendar {
    width: 100%
}

.datepicker .calendar .next .icon:before,
.datepicker .calendar .prev .icon:before,
.daterangepicker .calendar .next .icon:before,
.daterangepicker .calendar .prev .icon:before {
    transform: rotate(0deg) !important
}

.datepicker .calendar.left,
.daterangepicker .calendar.left {
    margin-right: 20px
}

.datepicker .calendar.left .table-head .month,
.datepicker .calendar.left .table-head .prev,
.daterangepicker .calendar.left .table-head .month,
.daterangepicker .calendar.left .table-head .prev {
    text-align: left
}

.datepicker .calendar.left .table-head .month,
.daterangepicker .calendar.left .table-head .month {
    padding-left: 24px
}

.datepicker .calendar.left .table-head .prev,
.daterangepicker .calendar.left .table-head .prev {
    width: 50px
}

.datepicker .calendar.right,
.daterangepicker .calendar.right {
    margin-left: 20px
}

.datepicker .calendar.right .table-head .month,
.datepicker .calendar.right .table-head .next,
.daterangepicker .calendar.right .table-head .month,
.daterangepicker .calendar.right .table-head .next {
    text-align: right
}

.datepicker .calendar.right .table-head .month,
.daterangepicker .calendar.right .table-head .month {
    padding-right: 24px
}

.datepicker .calendar.right .table-head .next,
.daterangepicker .calendar.right .table-head .next {
    width: 50px;
    margin-right: 0
}

.datepicker .calendar.right .table-head .month-row,
.daterangepicker .calendar.right .table-head .month-row {
    justify-content: flex-end
}

.datepicker .calendar .calendar-table,
.daterangepicker .calendar .calendar-table {
    width: 100%
}

.datepicker .calendar .table-head,
.daterangepicker .calendar .table-head {
    width: 350px
}

.datepicker .calendar .table-head .day-row {
    font-size: .875rem;
    color: #7d83aa;
    vertical-align: middle
}

.datepicker .calendar .table-head .month-row {
    font-size: .875rem;
    color: #7d83aa;
    vertical-align: middle
}

.daterangepicker .calendar .table-head .day-row {
    font-size: .875rem;
    color: #7d83aa;
    vertical-align: middle
}

.daterangepicker .calendar .table-head .month-row {
    font-size: .875rem;
    color: #7d83aa;
    vertical-align: middle
}

.datepicker .calendar .table-head .day-row.month {
    font-size: 1rem;
    font-weight: 300;
    color: #020d38
}

.datepicker .calendar .table-head .month-row.month {
    font-size: 1rem;
    font-weight: 300;
    color: #020d38
}

.daterangepicker .calendar .table-head .day-row.month {
    font-size: 1rem;
    font-weight: 300;
    color: #020d38
}

.daterangepicker .calendar .table-head .month-row.month {
    font-size: 1rem;
    font-weight: 300;
    color: #020d38
}

.datepicker .calendar .table-head .day-row i {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border-radius: 50%;
    color: #5150c4;
    background: rgba(211, 213, 228, .5);
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease
}

.datepicker .calendar .table-head .month-row i {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border-radius: 50%;
    color: #5150c4;
    background: rgba(211, 213, 228, .5);
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease
}

.daterangepicker .calendar .table-head .day-row i {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border-radius: 50%;
    color: #5150c4;
    background: rgba(211, 213, 228, .5);
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease
}

.daterangepicker .calendar .table-head .month-row i {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border-radius: 50%;
    color: #5150c4;
    background: rgba(211, 213, 228, .5);
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease
}

.datepicker .calendar .table-head .day-row i:hover {
    background: rgba(211, 213, 228, .8)
}

.datepicker .calendar .table-head .month-row i:hover {
    background: rgba(211, 213, 228, .8)
}

.daterangepicker .calendar .table-head .day-row i:hover {
    background: rgba(211, 213, 228, .8)
}

.daterangepicker .calendar .table-head .month-row i:hover {
    background: rgba(211, 213, 228, .8)
}

.datepicker .calendar .table-head .month-row,
.daterangepicker .calendar .table-head .month-row {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.datepicker .calendar .table-head .day-row,
.daterangepicker .calendar .table-head .day-row {
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 16px
}

.datepicker .calendar .table-head .day-row div,
.daterangepicker .calendar .table-head .day-row div {
    width: 50px;
    text-align: center
}

.datepicker .calendar .table-body>div,
.daterangepicker .calendar .table-body>div {
    margin: 2px 0
}

.datepicker .calendar .table-body div,
.daterangepicker .calendar .table-body div {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.datepicker .calendar .table-body div div,
.daterangepicker .calendar .table-body div div {
    width: 50px
}

.datepicker .calendar .table-body div span,
.daterangepicker .calendar .table-body div span {
    width: 33px;
    margin: 0 auto;
    text-align: center
}

.datepicker .calendar .table-body div {
    color: #7d83aa;
    width: 350px;
    height: 33px;
    cursor: pointer
}

.daterangepicker .calendar .table-body div {
    color: #7d83aa;
    width: 350px;
    height: 33px;
    cursor: pointer
}

.datepicker .calendar .table-body div span,
.daterangepicker .calendar .table-body div span {
    position: relative;
    z-index: 2
}

.datepicker .calendar .table-body .off {
    color: #d3d5e4;
    cursor: default
}

.daterangepicker .calendar .table-body .off {
    color: #d3d5e4;
    cursor: default
}

.datepicker .calendar .table-body .off.in-range,
.daterangepicker .calendar .table-body .off.in-range {
    background-color: transparent !important
}

.datepicker .calendar .table-body .today {
    color: #5150c4;
    font-weight: 600
}

.daterangepicker .calendar .table-body .today {
    color: #5150c4;
    font-weight: 600
}

.datepicker .calendar .table-body .active {
    position: relative;
    color: #fff;
    font-weight: 600
}

.daterangepicker .calendar .table-body .active {
    position: relative;
    color: #fff;
    font-weight: 600
}

.datepicker .calendar .table-body .active:before {
    content: "";
    position: absolute;
    width: 33px;
    height: 33px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #feb88a;
    border-radius: 50%
}

.daterangepicker .calendar .table-body .active:before {
    content: "";
    position: absolute;
    width: 33px;
    height: 33px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #feb88a;
    border-radius: 50%
}

.datepicker .calendar .table-body .active.end {
    color: #fff;
    font-weight: 600
}

.datepicker .calendar .table-body .active.start {
    color: #fff;
    font-weight: 600
}

.daterangepicker .calendar .table-body .active.end {
    color: #fff;
    font-weight: 600
}

.daterangepicker .calendar .table-body .active.start {
    color: #fff;
    font-weight: 600
}

.datepicker .calendar .table-body .active.end:before {
    background-color: #feb88a !important
}

.datepicker .calendar .table-body .active.start:before {
    background-color: #feb88a !important
}

.daterangepicker .calendar .table-body .active.end:before {
    background-color: #feb88a !important
}

.daterangepicker .calendar .table-body .active.start:before {
    background-color: #feb88a !important
}

.datepicker .calendar .table-body .active.start,
.daterangepicker .calendar .table-body .active.start {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.datepicker .calendar .table-body .active.end,
.daterangepicker .calendar .table-body .active.end {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.datepicker .calendar .table-body .in-range {
    background: rgba(211, 213, 228, .45) !important
}

.daterangepicker .calendar .table-body .in-range {
    background: rgba(211, 213, 228, .45) !important
}

.datepicker .calendar .table-body .in-range:first-child,
.daterangepicker .calendar .table-body .in-range:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.datepicker .calendar .table-body .in-range:last-child,
.daterangepicker .calendar .table-body .in-range:last-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.datepicker .actions {
    width: 100%;
    background: #fff;
    padding-top: calc(.5em*2.5)
}

.daterangepicker .actions {
    width: 100%;
    background: #fff;
    padding-top: calc(.5em*2.5)
}

.datepicker .actions__inputs,
.daterangepicker .actions__inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.datepicker .actions msc-button--close {
    color: #7d83aa
}

.daterangepicker .actions msc-button--close {
    color: #7d83aa
}

.datepicker .calendar .table-head .month,
.datepicker .calendar .table-head .month-row {
    text-align: center;
    margin: 0 auto;
    height: 25px
}

@media (-ms-high-contrast:none) {

    .daterangepicker,
    ::-ms-backdrop {
        width: 800px
    }
}

.daterangepicker .table-head .month-row {
    justify-content: space-between !important
}

.daterangepicker .table-head .month-row .month {
    padding: 0 !important
}

.daterangepicker .table-body>div {
    margin: 0 !important
}

.daterangepicker .actions {
    display: flex
}

.daterangepicker .actions .select {
    padding: 12px !important;
    height: 32px !important;
    border: 1px solid #d3d5e4 !important
}

.daterangepicker .actions .select:after {
    top: auto
}

.daterangepicker .actions__inputs {
    justify-content: flex-end !important
}

.daterangepicker .actions__inputs msc-button.close {
    margin: 0 2rem
}

.dropdown-contact {
    cursor: pointer;
    position: relative
}

.dropdown-contact.active .dropdown-contact__content {
    display: block
}

.dropdown-contact__content {
    position: absolute;
    display: none;
    background: #fff;
    padding: 1rem;
    top: 50px;
    width: 100%;
    height: 200px
}

.dropdown-contact__item {
    padding: .5rem .25rem;
    transition: all .3s ease
}

.dropdown-contact__item:hover {
    background: rgba(211, 213, 228, .3)
}

.dropdown--filter-type {
    top: 40px;
    width: 300px
}

.dropdown--filter-type:before {
    position: absolute;
    top: -3px;
    left: 40px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 1px;
    background: #fff;
    transform: rotate(-45deg)
}

.dropdown--filter-type .dropdown__content {
    padding: 16px 0
}

.dropdown--filter-type .dropdown__content .list-item,
.dropdown--filter-type .dropdown__content .list-item .list-sub-item {
    flex-direction: column
}

.dropdown--filter-type .dropdown__content .list-item .sub-item {
    flex-direction: column;
    margin: 0 0 0 30px
}

.dropdown--filter-type .dropdown__content__button {
    margin: 2rem 0 0;
    display: flex;
    justify-content: space-between
}

.dropdown--filter-type .dropdown__content__button msc-button {
    margin: 0 !important
}

.dropdown--filter-type .dropdown__content__button msc-button--close {
    color: #7d83aa
}

.dropdown-flag {
    position: relative;
    background: transparent;
    border-radius: 4px;
    box-shadow: none;
    padding: 8px 12px !important;
    width: 72px !important;
    min-width: auto;
    display: block !important
}

.dropdown-flag__selected {
    display: flex;
    align-items: center;
    position: relative
}

.dropdown-flag__selected img {
    width: 1.5rem
}

.dropdown-flag__selected i {
    position: absolute;
    right: -5px;
    color: #b2b5cd;
    transition: all .3s ease
}

.dropdown-flag__selected i:before {
    transform: rotate(0deg);
    transition: all .3s ease
}

.dropdown-flag__selected:hover i {
    color: #656c9b;
    transition: all .3s ease
}

.dropdown-flag__selected span {
    color: #656c9b;
    transition: all .3s ease
}

.dropdown-flag__content {
    display: none;
    transition: transform .3s cubic-bezier(.175, .885, .32, 2)
}

.dropdown-flag__content li {
    margin: 8px 0;
    text-align: left;
    cursor: pointer
}

.dropdown-flag__content li:last-child {
    margin-bottom: 0
}

.dropdown-flag__content li span {
    color: #d3d5e4;
    transition: all .3s ease
}

.dropdown-flag__content li:hover span {
    color: #656c9b;
    transition: all .3s ease
}

.dropdown-flag.active {
    background: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.dropdown-flag.active .dropdown-flag__content {
    display: block;
    margin: 0
}

.dropdown-flag.active a i:before {
    transform: rotate(180deg);
    transition: all .3s ease
}

.dropdown-help {
    max-width: 200px;
    padding: 20px 14px !important
}

.dropdown-help li {
    align-items: flex-start !important;
    margin-bottom: 10px !important
}

.dropdown-help__content {
    display: flex;
    flex-direction: column
}

.dropdown-help__content:hover .dropdown-help__description {
    color: #7d83aa
}

.dropdown-help__description {
    font-size: .75rem;
    color: #b2b5cd;
    transition: all .3s ease
}

.dropdown-help--appcues .header__appcues {
    margin: 0 0 1rem
}

.dropdown-help--appcues .header__appcues li {
    transition: all .3s ease;
    font-size: 1.125rem
}

.dropdown-help--appcues .header__appcues li:hover,
.dropdown-help--appcues .header__appcues li i:before {
    transition: all .3s ease
}

.dropdown-help--appcues .header__appcues li:hover i:before {
    transform: translateX(-5px);
    transition: all .3s ease
}

.dropdown-help--appcues li {
    align-items: center !important;
    margin: 4px 0 !important;
    font-size: .875rem
}

.dropdown-help--appcues li i:before {
    transition: all .3s ease
}

.dropdown-help--appcues li .dropdown-help__title {
    color: #7d83aa !important
}

.dropdown-help--appcues li:hover i:before {
    transform: translateX(5px);
    transition: all .3s ease
}

.dropdown-help--appcues li:hover .dropdown-help__title {
    color: #656c9b !important
}

.dropdown-select-camp .select__user {
    margin-top: 12px
}

.dropdown-status {
    position: relative;
    background: transparent;
    border-radius: 4px;
    box-shadow: none;
    max-width: 180px;
    z-index: 1
}

.dropdown-status a img {
    width: 1.5em
}

.dropdown-status a i {
    color: initial !important;
    font-size: .75rem !important;
    margin: 0 0 0 8px !important
}

.dropdown-status a i:before {
    transform: rotate(0deg);
    transition: transform .25s ease
}

.dropdown-status__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 30px;
    right: 0;
    margin: 0;
    padding: 8px;
    min-width: 160px;
    background: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    border-radius: 4px;
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.dropdown-status__content li {
    margin: 8px;
    text-align: left
}

.dropdown-status__content li:last-child {
    margin-bottom: 0
}

.dropdown-status .dropdown-status__item {
    background: #fff;
    border-radius: 15px;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.dropdown-status .dropdown-status__item:hover {
    transform: scale(1.04)
}

.dropdown-status .dropdown-status__item a {
    font-size: .75rem;
    color: #fff;
    outline: none;
    cursor: pointer
}

.dropdown-status .dropdown-status__item--purple {
    background: rgba(124, 108, 222, .2)
}

.dropdown-status .dropdown-status__item--purple a {
    color: #7c6cde
}

.dropdown-status .dropdown-status__item--orange {
    background: rgba(254, 184, 138, .2)
}

.dropdown-status .dropdown-status__item--orange a {
    color: #feb88a
}

.dropdown-status .dropdown-status__item--green {
    background: rgba(73, 226, 175, .2)
}

.dropdown-status .dropdown-status__item--green a {
    color: #6cd4a8
}

.dropdown-status.active {
    z-index: 100
}

.dropdown-status.active .dropdown-status__content {
    opacity: 1;
    visibility: visible;
    top: 40px
}

.dropdown-status.active a i:before {
    transform: rotate(-180deg)
}

.dropdown-user-extension {
    position: relative;
    padding: 0;
    min-width: 220px;
    border-radius: 6px
}

.dropdown-user-extension:after {
    content: "";
    position: absolute;
    top: -4px;
    right: 10px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 4px;
    transform: rotate(45deg)
}

.dropdown-user-extension a {
    font-family: Circular Std Book;
    font-size: .875rem;
    color: #8c91b4;
    transition: color .1s ease
}

.dropdown-user-extension p {
    font-family: Circular Std Book;
    font-size: .875rem;
    color: #8c91b4;
    transition: color .1s ease
}

.dropdown-user-extension p {
    color: #ffa2a2
}

.dropdown-user-extension .avatar,
.dropdown-user-extension i {
    width: 32px;
    height: 32px;
    margin-right: 8px
}

.dropdown-user-extension .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: #4443af
}

.dropdown-user-extension .avatar i {
    color: #fff !important
}

.dropdown-user-extension .avatar span {
    color: #fff !important
}

.dropdown-user-extension .avatar--icon {
    font-size: 1.125rem
}

.dropdown-user-extension .avatar--acronym {
    font-size: .875rem
}

.dropdown-user-extension .avatar--red {
    background-color: #ffa2a2
}

.dropdown-user-extension i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem
}

.dropdown-user-extension__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f8f9ff;
    margin-bottom: 16px;
    padding: 16px 16px 12px
}

.dropdown-user-extension__content a {
    display: flex;
    align-items: center;
    transition: color .4s ease;
    padding: 4px 16px
}

.dropdown-user-extension__content a:hover {
    color: #282d58
}

.dropdown-user-extension__content li:last-child {
    background: #f8f9ff
}

.dropdown-user-extension__content li:last-child i {
    color: #ffa2a2
}

.dropdown-virtual-card {
    position: absolute;
    top: 60px;
    right: 175px;
    transform: translateY(-25px);
    visibility: hidden;
    opacity: 0;
    transition: all .25s ease-in-out;
    min-width: 260px !important;
    padding: 0 !important
}

.dropdown-virtual-card.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0)
}

.dropdown-virtual-card__close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #b2b5cd;
    font-size: 1.25rem;
    cursor: pointer;
    transform: rotate(0);
    transition: all .3s ease;
    z-index: 10
}

.dropdown-virtual-card__close:hover {
    color: #7d83aa;
    transform: scale(1.15) rotate(90deg)
}

.dropdown-virtual-card__step {
    display: none;
    width: 480px;
    padding: calc(.5em*4);
    background-color: #fff
}

.dropdown-virtual-card__step h3 {
    color: #7d83aa
}

.dropdown-virtual-card__step h4 {
    color: #7d83aa
}

.dropdown-virtual-card__step p {
    color: #7d83aa
}

.dropdown-virtual-card__step h3 {
    position: relative;
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-bottom: calc(.5em*1.5);
    display: inline-block
}

.dropdown-virtual-card__step h3 span {
    position: absolute;
    top: 10px;
    right: -40px;
    font-size: .875rem;
    color: #ffa2a2;
    margin-top: calc(calc(.5em*2)*-1)
}

.dropdown-virtual-card__step h4 {
    font-size: 1.125rem;
    margin-bottom: calc(.5em*2.5);
    text-align: center
}

.dropdown-virtual-card__step p {
    font-size: .875rem;
    margin: calc(.5em*1.5) 0
}

.dropdown-virtual-card__step--pin {
    max-width: 260px;
    padding: 0
}

.dropdown-virtual-card__step.active {
    display: block
}

.dropdown-virtual-card__action {
    margin-top: calc(.5em*2.5)
}

.dropdown--search__content .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: #4443af
}

.dropdown--search__content .avatar i {
    color: #fff !important
}

.dropdown--search__content .avatar span {
    color: #fff !important
}

.dropdown--search__content .avatar--icon {
    font-size: 1.125rem
}

.dropdown--search__content .avatar--acronym {
    font-size: .875rem
}

.dropdown {
    position: absolute;
    display: none;
    min-width: 285px;
    padding: 24px;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1);
    z-index: 5;
    transition: all .4s ease
}

.dropdown.active {
    display: block
}

.dropdown--flag {
    top: 40px;
    max-height: 200px;
    overflow: auto
}

.dropdown__content .dropdown__list {
    display: block
}

.dropdown ul {
    margin: -8px 0
}

.dropdown li {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 8px 0
}

.dropdown li span {
    font-family: Circular Std Book;
    font-size: .875rem;
    color: #7d83aa;
    transition: color .1s ease
}

.dropdown li:hover span {
    color: #4443af
}

.dropdown--search ul {
    margin: 16px 0 -8px
}

.dropdown--search li {
    display: flex;
    align-items: center;
    margin: 8px
}

.dropdown--search .avatar {
    margin-right: 16px
}

.dropdown--search p {
    color: #b2b5cd
}

.dropdown--filter-date {
    top: 40px;
    left: 422.75px;
    right: auto
}

.dropdown-type {
    position: relative;
    z-index: 1
}

.dropdown-type p {
    font-size: 100%
}

.dropdown-type__selected {
    cursor: pointer;
    color: #7d83aa;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .75rem;
    transition: all .3s ease
}

.dropdown-type__selected i {
    font-size: 1.25rem
}

.dropdown-type__selected i:before {
    color: #5150c4;
    transform: rotate(0);
    transition: all .3s ease
}

.dropdown-type__selected:hover {
    color: #535a8b
}

.dropdown-type__selected:hover i:before {
    transform: scale(1.15);
    color: #535a8b
}

.dropdown-type__content {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    min-width: 225px;
    opacity: 0;
    visibility: hidden;
    border-radius: 4px;
    transform: translateY(-20px);
    transition: all .3s ease
}

.dropdown-type__list {
    margin-top: 32px;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    background: #fff;
    border-radius: 4px
}

.dropdown-type__item {
    color: #535a8b;
    background: #fff;
    transition: background .15s ease
}

.dropdown-type__item a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    font-size: .75rem;
    color: #656c9b;
    transition: color .4s .455, .03, .515, .955
}

.dropdown-type__item a:hover {
    color: #5150c4
}

.dropdown-type__item a i {
    color: #5150c4
}

.dropdown-type__item a i {
    margin-right: 10px;
    font-size: .875rem
}

.dropdown-type__item--trash {
    background: #f8f9ff
}

.dropdown-type__item--trash a i {
    color: #ffa2a2
}

.dropdown-type__item span {
    color: #ffa2a2
}

.dropdown-type__item:hover {
    background: #f8f9ff
}

.dropdown-type.active {
    z-index: 10
}

.dropdown-type.active .dropdown-type__selected {
    color: #17105a
}

.dropdown-type.active .dropdown-type__selected i:before {
    transform: rotate(-180deg)
}

.dropdown-type.active .dropdown-type__content {
    opacity: 1;
    visibility: visible;
    transform: translateY(0)
}

.dropdown-type.active .dropdown-type__content:hover {
    display: block
}

.dropdown-search-member {
    position: relative;
    z-index: 1
}

.dropdown-search-member p {
    font-size: 100%
}

.dropdown-search-member__text .link {
    cursor: pointer;
    font-size: .875rem;
    color: #b2b5cd;
    transition: color .2s ease
}

.dropdown-search-member__text .link i {
    color: #5150c4;
    font-size: 1.25rem;
    transition: all .2s ease;
    transform: scale(1)
}

.dropdown-search-member__text .link i:hover {
    transform: scale(1.05)
}

.dropdown-search-member__icon {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    color: #fff;
    background-color: #4c77ff;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition: transform .2s cubic-bezier(.175, .885, .32, 2)
}

.dropdown-search-member__icon i {
    font-size: 1.375rem
}

.dropdown-search-member__icon:hover {
    transform: scale(1.02)
}

.dropdown-search-member__content {
    position: absolute;
    z-index: 100;
    width: calc(100% - 40px);
    min-width: 280px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    background: #fff;
    border-radius: 4px;
    padding: 16px 24px;
    margin: 0 20px;
    transform: translateY(-20px);
    transition: all .15s ease
}

@media screen and (min-width:20rem) {
    .dropdown-search-member__content {
        top: 0;
        left: 30px;
        right: 0;
        transform: translate(-50%, -20px)
    }
}

@media screen and (min-width:48rem) {
    .dropdown-search-member__content {
        top: -10px;
        left: -10px;
        right: auto;
        transform: translateY(-20px)
    }
}

.dropdown-search-member__form {
    margin-bottom: 12px
}

.dropdown-search-member__item {
    color: #535a8b;
    background: #fff;
    transition: background .15s ease;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.dropdown-search-member__item a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: .75rem;
    color: #fff;
    transform: scale(1);
    transition: transform .2s cubic-bezier(.175, .885, .32, 2)
}

.dropdown-search-member__item a:hover {
    transform: scale(1.02)
}

.dropdown-search-member__item a i {
    font-size: 1rem
}

.dropdown-search-member__item--trash {
    background: #f8f9ff
}

.dropdown-search-member__item--trash a i {
    color: #ffa2a2
}

.dropdown-search-member.active {
    z-index: 10
}

.dropdown-search-member.active .dropdown-search-member__content {
    opacity: 1;
    visibility: visible;
    transform: translateY(46px)
}

@media screen and (min-width:20rem) {
    .dropdown-search-member.active .dropdown-search-member__content {
        transform: translate(-50%, 46px)
    }
}

@media screen and (min-width:48rem) {
    .dropdown-search-member.active .dropdown-search-member__content {
        transform: translateY(46px)
    }
}

.dropdown-search-member.active .dropdown-search-member__content:hover {
    display: block
}

.dropdown-actions {
    position: relative;
    z-index: 1
}

.dropdown-actions p {
    font-size: 100%
}

.dropdown-actions__selected {
    cursor: pointer;
    color: #535a8b;
    transition: all .15s ease
}

.dropdown-actions__selected i {
    font-size: 1.25rem
}

.dropdown-actions__selected i:before {
    color: #b2b5cd;
    transform: rotate(0deg);
    transition: all .15s ease
}

.dropdown-actions__selected i:hover:before {
    color: #656c9b
}

.dropdown-actions__content {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    min-width: 225px;
    opacity: 0;
    visibility: hidden;
    border-radius: 4px;
    transform: translateY(-20px);
    transition: all .25s ease
}

.dropdown-actions__list {
    margin-top: 32px;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    background: #fff;
    border-radius: 4px
}

.dropdown-actions__item {
    color: #535a8b;
    background: #fff;
    transition: background .15s ease
}

.dropdown-actions__item a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    font-size: .75rem;
    color: #656c9b;
    transition: color .4s .455, .03, .515, .955
}

.dropdown-actions__item a:hover {
    color: #5150c4
}

.dropdown-actions__item a i {
    color: #5150c4
}

.dropdown-actions__item a i {
    margin-right: 10px;
    font-size: .875rem
}

.dropdown-actions__item--grey {
    background: #f8f9ff
}

.dropdown-actions__item--trash {
    background: #f8f9ff
}

.dropdown-actions__item--trash a i {
    color: #ffa2a2
}

.dropdown-actions__item span {
    color: #ffa2a2
}

.dropdown-actions__item:hover {
    background: #f8f9ff
}

.dropdown-actions.active {
    z-index: 10
}

.dropdown-actions.active .dropdown-actions__selected {
    color: #17105a
}

.dropdown-actions.active .dropdown-actions__selected i:before {
    transform: rotate(0deg)
}

.dropdown-actions.active .dropdown-actions__content {
    opacity: 1;
    visibility: visible;
    transform: translateY(0)
}

.dropdown-actions.active .dropdown-actions__content:hover {
    display: block
}

.dropdown-manage {
    width: 225px
}

.dropdown-manage .dropdown-actions__content:before {
    content: "";
    position: absolute;
    top: 27px;
    right: 12px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    transform: rotate(45deg);
    background-color: #fff
}

.dropdown-camp p {
    font-size: 100%
}

.dropdown-camp .dropdown-actions__selected i {
    font-size: 1.5rem
}

.dropdown-camp .dropdown-actions__selected i:before {
    color: #7d83aa !important;
    transition: all .3s ease
}

.dropdown-camp .dropdown-actions__selected i:hover:before {
    color: #5150c4 !important;
    transition: all .3s ease
}

.dropdown-camp .dropdown-actions__content {
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    top: 35px;
    left: -2px;
    z-index: 10;
    transition: all .4s ease;
    width: calc(100% + 4px)
}

.dropdown-camp .dropdown-actions__list {
    margin-top: 0;
    max-height: 160px;
    overflow-x: hidden;
    overflow-y: scroll
}

.dropdown-camp .dropdown-actions__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    transition: background .15s
}

.dropdown-camp .dropdown-actions__item:hover {
    background: #f8f9ff
}

.dropdown-camp .dropdown-actions__item i {
    font-size: 1.25rem;
    margin-right: 10px;
    color: #ffa2a2
}

.dropdown-camp .dropdown-actions__item p {
    color: #7d83aa
}

.dropdown-camp .dropdown-actions__checkbox {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none
}

.dropdown-add,
.dropdown-export,
.dropdown-help {
    min-width: 380px
}

.dropdown-add p,
.dropdown-export p,
.dropdown-help p {
    font-size: 100%
}

.dropdown-add li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #7d83aa;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .3s ease
}

.dropdown-export li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #7d83aa;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .3s ease
}

.dropdown-help li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #7d83aa;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .3s ease
}

.dropdown-add li:last-child,
.dropdown-export li:last-child,
.dropdown-help li:last-child {
    margin-bottom: 0
}

.dropdown-add li:hover {
    color: #4443af
}

.dropdown-export li:hover {
    color: #4443af
}

.dropdown-help li:hover {
    color: #4443af
}

.dropdown-add i {
    margin-right: 20px;
    font-size: 1.375rem;
    color: #4443af
}

.dropdown-export i {
    margin-right: 20px;
    font-size: 1.375rem;
    color: #4443af
}

.dropdown-help i {
    margin-right: 20px;
    font-size: 1.375rem;
    color: #4443af
}

.dropdown-select-camp {
    padding: 15px;
    width: 100%;
    top: 50px;
    left: 0
}

.dropdown-select-camp p {
    font-size: 100%
}

.dropdown-select-camp .search-bar__form {
    margin-bottom: 25px;
    width: 100%
}

.dropdown-select-camp .search-bar__form input {
    border: none !important;
    padding: 0 !important;
    height: 25px !important;
    outline: none
}

.dropdown-select-camp .camp__name {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.dropdown-select-camp .camp__name>i {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #fff;
    background-color: #b2b5cd;
    display: flex;
    align-items: center;
    justify-content: center
}

.dropdown-select-camp .camp__name img {
    margin-right: 10px
}

.dropdown-select-camp .camp__name p {
    margin: 0 0 0 10px !important
}

.dropdown-select-camp ul {
    overflow-x: hidden;
    overflow-y: scroll
}

.dropdown-select-camp li {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.dropdown-select-camp li:last-child {
    background-color: transparent;
    padding-bottom: 0
}

.dropdown-select-camp .checkbox__container {
    margin-bottom: 20px
}

.dropdown-edit p {
    font-size: 100%
}

.dropdown-edit .dropdown__content {
    top: 32px;
    min-width: auto;
    width: 150px;
    transition: all .15s ease
}

.dropdown-edit .dropdown__list {
    margin-top: 0
}

.dropdown-edit .dropdown__list li {
    text-align: left;
    cursor: pointer
}

.dropdown-edit .dropdown__list li .icon-edit-2-outline {
    margin-bottom: 10px
}

.dropdown-edit .dropdown__list li i {
    color: #7d83aa
}

.dropdown-edit .dropdown__list li:last-child i {
    color: #ffa2a2
}

.dropdown-edit .dropdown__list li:hover {
    background: #f8f9ff
}

.dropdown-edit .dropdown__list i {
    margin-right: 10px
}

.dropdown-edit.active .dropdown__selected i:before {
    transform: rotate(0deg)
}

.dropdown-add-member {
    top: 20px;
    left: 40px;
    width: 310px
}

.dropdown-add-member p {
    font-size: 100%
}

.dropdown-add-member .input__member {
    border: 2px solid #d3d5e4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%
}

.dropdown-learning-language {
    display: none;
    top: 45px;
    left: 0;
    border-top: none;
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    min-width: auto
}

.dropdown-learning-language p {
    font-size: 100%
}

.dropdown-learning-language .dropdown-learning-language__list {
    margin: -8px 0
}

.dropdown-learning-language .dropdown-learning-language__item {
    margin: 8px 0
}

.dropdown-learning-language .dropdown-learning-language__content {
    margin: -16px -20px -18px -16px
}

.dropdown-learning-language .dropdown-learning-language__filter {
    color: #535a8b;
    border: 1px solid #d3d5e4;
    border-radius: 2px;
    height: 30px;
    margin-bottom: 15px;
    padding: 0 10px;
    width: 100%
}

.dropdown-learning-language .dropdown-learning-language__flag {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px;
    margin-left: -8px;
    margin-right: 8px
}

.dropdown-learning-language .dropdown-learning-language__flag img {
    width: 24px;
    height: auto;
    margin-right: 12px
}

.dropdown-learning-language .dropdown-learning-language__flag:hover {
    background: #f8f9ff
}

.dropdown-experience {
    display: none;
    top: 35px;
    left: 0;
    border-top: none;
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.dropdown-experience p {
    font-size: 100%
}

.dropdown-experience__item {
    cursor: pointer;
    padding: 8px 16px;
    margin-left: -8px;
    margin-right: 8px
}

.dropdown-experience__item:hover {
    background: #f8f9ff
}

.dropdown-experience__content {
    margin: -16px -32px -16px -16px
}

.dropdown-notification {
    display: none;
    top: 45px;
    left: 0;
    border-top: none;
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.dropdown-notification p {
    font-size: 100%
}

.dropdown-notification__list {
    margin-bottom: -8px
}

.dropdown-notification__item {
    cursor: pointer;
    padding: 8px 16px;
    width: calc(100% - 16px)
}

.dropdown-notification__item:hover {
    background: #f8f9ff
}

.dropdown-notification__content {
    margin: -16px -32px -16px -16px
}

.dropdown-search p {
    font-size: 100%
}

.dropdown-search .search__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.dropdown-search .search__content .tag {
    min-width: 58px;
    text-align: center
}

.dropdown-search .search__text {
    margin: 0 15px
}

.dropdown-search .search__infos {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

@media (-ms-high-contrast:none) {

    .dropdown-search,
    ::-ms-backdrop {
        top: 80px;
        left: 80px
    }
}

.dropdown-job {
    padding: 1rem;
    margin-top: 0;
    min-width: 200px !important;
    width: calc(100% - 4rem) !important
}

.dropdown-job p {
    font-size: 100%
}

.dropdown-job .job__item {
    padding: 8px 0;
    font-size: .875rem;
    cursor: pointer
}

.dropdown-job .job__item a {
    color: #d3d5e4;
    font-size: .75rem;
    transition: .25s
}

.dropdown-job .job__item i {
    font-size: 1rem;
    margin-right: 8px;
    width: 18px;
    height: 18px
}

.dropdown-job .job__item.active {
    cursor: auto
}

.dropdown-job .job__item.active a {
    cursor: default;
    color: #4443af
}

.dropdown-job .job__item:hover a {
    color: #4443af
}

.dropdown p {
    font-size: 100%
}

.dropdown--filter-duration {
    top: 40px;
    width: 400px
}

.dropdown--filter-duration:before {
    position: absolute;
    top: -3px;
    left: 40px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 1px;
    background: #fff;
    transform: rotate(-45deg)
}

.dropdown--filter-duration .dropdown__content {
    padding: 16px 0;
    margin: 1rem 0 0
}

.dropdown--filter-duration .dropdown__content .range {
    width: 340px;
    height: 20px
}

.container-rangeslider .range {
    width: 340px;
    height: 20px
}

.dropdown--filter-duration .dropdown__content__button {
    margin: 2rem 0 0;
    display: flex;
    justify-content: space-between
}

.dropdown--filter-duration .dropdown__content__button msc-button {
    margin: 0 !important
}

.dropdown--filter-duration .dropdown__content__button msc-button--close {
    color: #7d83aa
}

.dropdown-manager,
.dropdown-subscription {
    background: transparent
}

.dropdown-manager p,
.dropdown-subscription p {
    font-size: 100%
}

.dropdown-manager .dropdown-actions__selected,
.dropdown-subscription .dropdown-actions__selected {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.dropdown-manager .dropdown-actions__selected i,
.dropdown-subscription .dropdown-actions__selected i {
    display: flex;
    align-items: center;
    margin: 0 0 0 .25rem
}

.dropdown-manager .dropdown-actions__selected i:before {
    color: #f4997d;
    transition: all .3s ease
}

.dropdown-subscription .dropdown-actions__selected i:before {
    color: #f4997d;
    transition: all .3s ease
}

.dropdown-manager .dropdown-actions__selected:hover i:before {
    color: #db7564;
    transition: all .3s ease
}

.dropdown-subscription .dropdown-actions__selected:hover i:before {
    color: #db7564;
    transition: all .3s ease
}

.dropdown-manager .dropdown-actions__selected p,
.dropdown-subscription .dropdown-actions__selected p {
    margin-left: 12px
}

.dropdown-manager .dropdown-actions__content {
    left: 0;
    right: auto;
    top: 32px;
    padding: 16px;
    background: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.dropdown-subscription .dropdown-actions__content {
    left: 0;
    right: auto;
    top: 32px;
    padding: 16px;
    background: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.dropdown-manager .dropdown-actions__list,
.dropdown-subscription .dropdown-actions__list {
    max-height: 185px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: none;
    margin-top: 12px
}

.dropdown-manager .dropdown-actions__item,
.dropdown-subscription .dropdown-actions__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 8px
}

.dropdown-manager .dropdown-actions__item p,
.dropdown-subscription .dropdown-actions__item p {
    padding-left: 8px
}

.dropdown-manager--text-only .dropdown-actions__selected p,
.dropdown-subscription--text-only .dropdown-actions__selected p {
    margin-left: 0
}

.dropdown-subscription p {
    font-size: 100%
}

.dropdown-subscription .dropdown-actions__content {
    left: auto;
    right: 0
}

.dropdown-users p {
    font-size: 100%
}

.dropdown-users .dropdown-actions__selected:hover i:before {
    color: #ffa2a2
}

.dropdown-manager p {
    font-size: 100%
}

.dropdown-manager .dropdown-actions__selected i:before {
    transform: rotate(0deg);
    transition: .3s ease
}

.dropdown-manager.active .dropdown-actions__selected i:before {
    transform: rotate(180deg);
    transition: .3s ease
}

.dropdown-experience {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 180px
}

.dropdown-price {
    color: #7d83aa;
    font-size: .75rem;
    padding: calc(.5em*2.5);
    width: 355px
}

.dropdown-price p {
    font-size: 100%
}

.dropdown-price__header {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*1.5);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.dropdown-price__illus {
    position: relative;
    width: 58px
}

.dropdown-price__illus img {
    border-radius: 4px;
    max-width: 58px;
    min-height: 44px;
    height: auto
}

.dropdown-price__logo {
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #f8f9ff;
    padding: 2px
}

.dropdown-price__logo img {
    max-width: 60%;
    height: auto
}

.dropdown-price__title {
    font-size: .875rem;
    margin-left: calc(.5em*3)
}

.dropdown-price__btn msc-button {
    display: block;
    margin: 4px 0;
    width: 100%
}

.dropdown-price__item {
    justify-content: flex-end;
    color: #656c9b;
    height: 50px
}

.dropdown-price__item,
.dropdown-price__item__label {
    display: flex;
    align-items: center
}

.dropdown-price__item__label i {
    margin: 0 0 0 .25rem;
    color: #d3d5e4;
    font-size: 1.125rem
}

.dropdown-price__item__total {
    margin: 0 0 0 1.5rem;
    font-size: 1rem
}

.dropdown-price__item--textarea {
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    height: auto;
    padding: 0 0 .5rem;
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.dropdown-price__item--textarea div {
    text-align: center
}

.dropdown-price__item--textarea div:first-child {
    margin: 0 0 1rem
}

.dropdown-price__item--border {
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.dropdown-price__item .input {
    height: 30px;
    width: 60px;
    margin: 0 0 0 1.5rem
}

.dropdown-price__item .input__text {
    text-align: right
}

.dropdown-price .paragraph-price__total {
    color: #656c9b;
    font-size: 1rem
}

.dropdown-price__item .input__text {
    color: #656c9b;
    font-size: 1rem
}

.dropdown-overflow {
    height: 255px;
    overflow: auto
}

.empty__state {
    width: 300px;
    text-align: center;
    padding: 10px;
    margin: 0 auto
}

.empty__state .state__icon img {
    position: relative;
    margin: auto;
}

.empty__state--white img path {
    fill: red
}

.empty__state--white .state__icon i {
    background-color: #fff
}

.empty__state--transactions {
    text-align: center;
    width: 100%
}

.empty__state--transactions .state__icon i {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: rgba(130, 144, 253, .3)
}

.empty__state--transactions .state__icon i:before {
    color: #8290fd
}

.empty__state--transactions .state__content {
    max-width: 330px;
    margin: 0 auto
}

.empty__state--transactions .state__content>* {
    margin: 4px 0
}

.range--selectable .step {
    cursor: pointer
}

.range--selectable .step:hover {
    width: 20px !important;
    height: 20px !important;
    background-color: #b2b5cd !important
}

.range .range-max,
.range .range-min {
    cursor: pointer
}

.range {
    position: relative
}

.range .range-line {
    z-index: 10;
    position: absolute;
    background-color: rgba(211, 213, 228, .7)
}

.range .range-line,
.range .range-line__between {
    width: 100%;
    height: 6px;
    border-radius: 4px;
    transition: all .4s ease
}

.range .range-line__between {
    display: block;
    z-index: 20
}

.range .steps {
    position: absolute;
    top: -7px;
    width: 100%
}

.range .steps .step {
    z-index: 20;
    display: inline-block;
    position: absolute;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #d3d5e4
}

.range .steps .step,
.range .steps .step.active {
    transition: all .4s ease
}

.range .steps .step.displayStep:before {
    transform: translateX(-18%);
    content: attr(data-translate);
    position: absolute;
    top: -25px;
    font-size: .75rem;
    width: 60px;
    color: #b2b5cd;
    font-weight: 600
}

.range .steps .step.displayBottom:before {
    top: 25px !important
}

.range .range-max,
.range .range-min {
    transform: translateY(-40%);
    z-index: 30;
    position: absolute;
    border-radius: 50%;
    transition: all .4s ease
}

.range .range-max.selected,
.range .range-min.selected {
    transition: all .4s ease
}

.audio-system .audio-canvas canvas {
    width: 350px;
    height: 150px
}

.audio-player {
    position: relative;
    width: 350px;
    height: 50px;
    color: #fff;
    font-size: .875rem;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.audio-player,
.audio-player__timeline {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.audio-player__timeline {
    background: #d3d5e4;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    cursor: pointer
}

.audio-player__timeline .audio-player__progress {
    width: 0;
    height: 100%;
    transition: .3s
}

.audio-player__controls {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 20px
}

.audio-player__controls>* {
    display: flex;
    justify-content: center;
    align-items: center
}

.audio-player__controls .audio-player__play {
    cursor: pointer
}

.audio-player__controls .audio-player__play i {
    font-size: 1.5rem
}

.audio-player__controls .audio-player__play i:before {
    transform: rotate(180deg)
}

.audio-player__controls .audio-player__play.active i {
    opacity: 0
}

.audio-player__controls .audio-player__play.active span {
    opacity: 1
}

.audio-player__controls .audio-player__play span {
    opacity: 0;
    position: relative
}

.audio-player__controls .audio-player__play span:before {
    right: 14px
}

.audio-player__controls .audio-player__play span:after {
    left: -8px
}

.audio-player__controls .audio-player__play span:after,
.audio-player__controls .audio-player__play span:before {
    position: absolute;
    top: 0;
    background: #fff;
    content: "";
    width: 3px;
    height: 12px
}

.audio-player__controls .audio-player__time {
    display: flex
}

.audio-player__controls .audio-player__time>* {
    padding: 2px
}

.audio-player__controls .audio-player__volume-container {
    cursor: pointer;
    position: relative;
    z-index: 2
}

.audio-player__controls .audio-player__volume-container .audio-player__volume-button {
    height: 26px;
    display: flex;
    align-items: center
}

.audio-player__controls .audio-player__volume-container .audio-player__volume-button .audio-player__volume i {
    font-size: 1.125rem
}

.audio-player__controls .audio-player__volume-container .audio-player__volume-button .audio-player__volume.active .up {
    display: block
}

.audio-player__controls .audio-player__volume-container .audio-player__volume-button .audio-player__volume.active .muted,
.audio-player__controls .audio-player__volume-container .audio-player__volume-button .audio-player__volume .up {
    display: none
}

.audio-player__controls .audio-player__volume-container .audio-player__volume-slider {
    position: absolute;
    left: -3px;
    z-index: -1;
    width: 0;
    height: 15px;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(10, 22, 70, .1);
    transition: .3s
}

.audio-player__controls .audio-player__volume-container .audio-player__volume-slider .audio-player__volume-percentage {
    height: 100%;
    width: 75%
}

.audio-player__controls .audio-player__volume-container:hover .audio-player__volume-slider {
    left: -123px;
    width: 120px
}

.modal-budget-summary {
    width: 404px
}

.modal-budget-summary .modal__header {
    background-color: #f8f9ff;
    margin: -28px -28px 0;
    padding: 18px 28px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.modal-budget-summary .modal__title {
    font-size: 16px;
    color: #979bb7;
    margin-bottom: 0
}

.modal-budget-summary .modal__close {
    position: static
}

.modal-budget-summary .modal__subtitle {
    font-size: 1rem;
    color: #7d83aa;
    margin-bottom: 20px
}

.modal-budget-summary .modal__paragraph {
    font-size: .875rem;
    line-height: 1.375rem;
    color: #b2b5cd
}

.modal-budget-summary .modal__price {
    font-size: 1rem;
    line-height: 1.25rem;
    color: #feb88a
}

.modal-budget-summary .modal__entry {
    margin-top: 36px;
    padding-top: 20px;
    border-top: 1px solid #b2b5cd
}

.modal-budget-summary .modal__entry:first-child {
    margin-top: 0;
    border-top: none
}

.modal-budget-summary .modal__entry:first-child .modal__subtitle {
    margin-bottom: 0
}

.modal-budget-summary .modal__entry:last-child {
    margin-bottom: 40px
}

.modal-budget-summary .modal__sum .modal__subtitle {
    font-size: .875rem;
    line-height: 1.5rem;
    color: #535a8b;
    margin-bottom: 0
}

.modal-budget-summary .modal__footer {
    position: static
}

.modal-communities-description__picture {
    display: flex;
    flex-direction: column;
    align-items: center
}

.modal-communities-description__picture img {
    max-width: 160px
}

.modal-communities-description__desc,
.modal-communities-description__title {
    margin: 1rem 0 0
}

.modal-communities-description__action {
    margin: 1rem 0 0;
    display: flex;
    justify-content: center
}

.modal-communities-description__team {
    margin: 1rem 0 0
}

.modal-invitation-manage .modal__content {
    min-height: auto
}

.modal-invitation-manage .modal__title {
    line-height: 24px
}

.modal-invitation-manage .modal__title span {
    display: block;
    color: #ffa2a2
}

.modal-invitation-manage .modal__form {
    display: inline-block;
    margin-top: 16px
}

.modal-invitation-manage .modal__buttons {
    margin-top: 32px
}

.modal-invitation-manage .modal__buttons>* {
    margin: 8px 0
}

.modal-invitation-manage .modal__buttons.flex msc-button {
    margin: 8px
}

.modal-invitation-manage .modal__body .modal__form {
    width: 100%
}

.modal-invitation-manage .modal__body p {
    width: 75%;
    margin: 0 auto;
    line-height: 18px
}

.modal-invitation-manage .modal__footer {
    position: relative;
    top: auto;
    bottom: auto
}

.modal-invitation-manage .modal__footer msc-button {
    display: block;
    margin: 8px auto
}

.modal-invitation-notify .modal__body {
    margin-bottom: 16px
}

.modal-invitation-notify .modal__body--simplebar {
    height: 350px
}

.modal-invitation-notify__description {
    color: #656c9b;
    margin: 1rem 0
}

.modal-invitation-notify__input {
    padding: 8px 0
}

.modal-invitation-notify__input label {
    display: block;
    margin-bottom: 8px;
    font-size: .875rem
}

.modal-invitation-reopen .modal__header {
    margin-top: 30px
}

.modal-invitation-reopen .modal__content {
    min-height: 240px
}

.modal-invitation-reopen .modal__form {
    display: inline-block;
    margin-top: 16px
}

.modal-invitation-reopen .modal__body {
    margin-bottom: 32px
}

.modal-invitation-reopen .modal__footer {
    position: relative;
    top: auto;
    bottom: auto
}

.modal-invitation-reopen .modal__footer msc-button {
    display: block;
    margin: 8px auto
}

.modal-invitation-reopen .modal__buttons {
    margin: -8px
}

.modal-invitation-reopen .modal__buttons>* {
    margin: 8px
}

.modal-invitation-reopen .modal__buttons__actions {
    display: flex;
    justify-content: center
}

.modal-invitation-reopen .modal__buttons__actions msc-button {
    margin: 10px
}

.modal-invitation-reopen .modal__reminder .modal__footer {
    display: flex;
    align-items: center;
    justify-content: center
}

.modal-invitation-reopen .modal__reminder .modal__footer msc-button {
    margin: 8px
}

.modal-player-light .modal__content {
    max-width: none;
    display: flex;
    flex-direction: column
}

@media screen and (min-width:48rem) {
    .modal-player-light .modal__content {
        width: 35rem
    }
}

.modal-player-light .modal__content .modal__body {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 5rem
}

.modal-player-light .modal__content .modal__body__logo {
    margin: 20px auto;
    width: 50%;
    max-width: 300px
}

.modal-player-light .modal__content .modal__body__content {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.modal-player-light .modal__content .modal__body__content__input {
    display: flex;
    align-items: center
}

.modal-player-light .modal__content .modal__body__content__input>* {
    margin: 0 8px
}

.modal-player-light .modal__content .modal__body__content__input .input {
    background: transparent
}

.modal-player-light .modal__content .modal__body__content .input-number,
.modal-player-light .modal__content .modal__body__content .select,
.modal-player-light .modal__content .modal__body__content msc-button {
    height: 35px
}

@media screen and (max-width:32rem) {

    .modal-player-light .modal__content .modal__body__content .input-number,
    .modal-player-light .modal__content .modal__body__content .select,
    .modal-player-light .modal__content .modal__body__content msc-button {
        margin: .3rem 0
    }
}

.modal-player-light .modal__content .modal__body__content .select {
    min-width: 120px
}

.modal-player-light .modal__content .modal__body__content .select:after {
    top: auto
}

.modal-player-light .modal__content .modal__body__title {
    margin: 1rem 0
}

.modal-player-light .modal__content .modal__body__title span {
    font-size: 1.125rem;
    color: #656c9b
}

.modal-player-light .modal__content .modal__body__actions {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.modal-player-light .modal__content .modal__body__actions msc-button {
    margin: 1rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 140px
}

.modal-player-light .modal__content .modal__body__actions msc-button i {
    font-size: 1.375rem;
    margin: 0 .5rem 0 0
}

.modal-player-light .modal__content .modal__footer {
    background-color: rgba(211, 213, 228, .2);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem
}

@media screen and (max-width:32rem) {
    .modal-player-light .modal__content .modal__footer {
        padding: 0;
        margin: -28px;
        position: relative;
        width: auto
    }
}

.modal-player-light .modal__content .modal__footer .modal__buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: initial;
    width: 100%
}

@media screen and (max-width:32rem) {
    .modal-player-light .modal__content .modal__footer .modal__buttons {
        flex-direction: column
    }
}

.modal-player-light .modal__content .modal__footer .modal__buttons__label {
    font-size: .875rem;
    color: #8c91b4
}

.modal-player-light .modal__content .modal__footer .modal__buttons msc-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 140px
}

.modal-player-light .modal__content .modal__footer .modal__buttons msc-button i {
    font-size: 1.375rem;
    margin: 0 .5rem 0 0
}

@-webkit-keyframes pulsing-player-light {
    0% {
        box-shadow: 0 0 0 0 rgba(219, 218, 255, .3)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(219, 218, 255, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(219, 218, 255, 0)
    }
}

@keyframes pulsing-player-light {
    0% {
        box-shadow: 0 0 0 0 rgba(219, 218, 255, .3)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(219, 218, 255, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(219, 218, 255, 0)
    }
}

.modal-reaction .modal__content {
    border-radius: 0;
    padding: calc(.5em*1.5)
}

.modal-reaction__tabs-controls {
    border-bottom: 1px solid #d3d5e4;
    margin-top: calc(calc(.5em*4)*-1);
    margin-bottom: calc(.5em*2);
    padding: 0 calc(calc(.5em*1)*-1)
}

.modal-reaction__tabs-control {
    position: relative;
    cursor: pointer;
    margin: 0 calc(.5em*1);
    padding: 0 calc(.5em*1);
    border-bottom: 1px solid transparent;
    transition: all .2s ease;
    flex-direction: row-reverse
}

.modal-reaction__tabs-control {
    color: #7d83aa;
    display: flex;
    justify-content: center;
    align-items: center
}

.modal-reaction__tabs-control a {
    color: #7d83aa;
    display: flex;
    justify-content: center;
    align-items: center
}

.modal-reaction__tabs-control a {
    margin: calc(.5em*2) 0
}

.modal-reaction__tabs-control a span {
    margin-left: calc(.5em*1.5)
}

.modal-reaction__tabs-control a img {
    transition: transform .2s ease;
    transform: scale(1)
}

.modal-reaction__tabs-control:first-child a span {
    margin-left: 0
}

.modal-reaction__tabs-control:hover {
    border-bottom: 1px solid #7d83aa
}

.modal-reaction__tabs-control:hover a {
    color: #282d58
}

.modal-reaction__tabs-control:hover a img {
    transform: scale(1.02)
}

.modal-reaction__tabs-control.active {
    border-bottom: 1px solid #7d83aa
}

.modal-reaction__tabs-control.active a {
    color: #282d58
}

.modal-reaction__tabs-panels {
    padding: calc(.5em*0.5) 0;
    max-height: 360px
}

.modal-reaction__tabs-panels .modal-reaction__tabs-panel {
    max-height: 360px
}

.modal-reaction__user {
    margin: calc(calc(.5em*2.5)*-1) 0
}

.modal-reaction__user li {
    margin: calc(.5em*2.5) 0
}

.modal__content {
    min-height: 180px
}

.modal-reset-password .modal__form {
    display: inline-block;
    margin-top: 16px
}

.modal-reset-password .modal__buttons {
    margin: 0 -8px
}

.modal-reset-password .modal__buttons msc-button {
    margin: 16px 8px 0;
    display: block
}

.modal-reset-password .modal__password .modal__input {
    width: calc(100% - 110px)
}

.modal-reset-password .modal__password msc-button {
    margin-left: 32px
}

.modal-reset-password .modal__checkbox {
    margin-top: 16px
}

.modal-reset-password .modal__alert {
    color: #ffa2a2;
    margin: 32px 0 16px
}

.modal-reset-password .modal__infos {
    margin-top: 32px
}

.modal-reset-password .modal__step {
    margin: 16px 0
}

.modal-reset-password .modal__step p {
    margin-bottom: 8px
}

.modal-reset-password .modal__footer {
    position: relative;
    bottom: 0;
    right: 0
}

.modal-reset-password .input__label {
    display: block;
    color: #b2b5cd;
    margin-bottom: 4px;
    font-size: .875rem
}

.onboarding_navigation-filter .navigation-filter__separator .separator__filters .separator__filters__item {
    z-index: 3
}

.onboarding_navigation-filter .navigation-filter__separator .separator__filters .separator__filters__item>msc-button {
    border-color: #ebf0f8;
    color: #6e75a1
}

.onboarding_navigation-filter .navigation-filter__separator .separator__filters .separator__filters__item>msc-button.active {
    color: #fff
}

.onboarding_navigation-filter .navigation-filter__separator .separator__filters .separator__filters__item>msc-button i {
    margin: auto;
    color: #6e75a1;
    font-size: .875rem
}

.onboarding_navigation-filter .navigation-filter__separator .separator__filters .separator__filters__item .form-selection__switch {
    margin-left: 15px
}

.onboarding_navigation-filter .navigation-filter__separator .separator__filters .separator__filters__item .form-selection__switch p {
    margin: 0 0 0 calc(.5em*1);
    color: #7d83aa
}

.navigations__action msc-button i {
    margin: 0
}

.navigations {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #d3d5e4;
    padding: 15px 0;
    margin-bottom: 30px
}

.navigations__search {
    margin-right: 16px
}

.navigations__filter {
    margin-left: 16px
}

.navigations__action {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.navigations__action msc-button {
    margin: 0 8px
}

.navigations__dropdown {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 1.125rem;
    background-color: rgba(211, 213, 228, .5)
}

.navigations-aside {
    padding: 20px 30px 20px 0;
    margin: -15px 0 -15px -12px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.navigations-aside .navigations-aside-content__notifications {
    background: #feb88a;
    padding: 4px 10px;
    border-radius: 12px;
    line-height: 100%;
    color: #fff;
    font-size: .625rem
}

.navigations-aside li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 35px;
    cursor: pointer
}

.navigations-aside li.displayed .toggle i:before {
    transform: rotate(180deg)
}

.navigations-aside p {
    font-size: .875rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center
}

.navigations-aside p {
    color: #656c9b;
    transition: all .3s ease
}

.navigations-aside p i {
    color: #656c9b;
    transition: all .3s ease
}

.navigations-aside p i {
    font-size: 1.25rem;
    margin-right: 8px
}

.navigations-aside .navigations-aside-sub {
    display: none
}

.navigations-aside .navigations-aside-sub li {
    padding: 10px 10px 10px 20px;
    width: 100%;
    transition: all .3s ease
}

.navigations-aside .navigations-aside-sub li.active {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.navigations-aside .navigations-aside-sub li:hover {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.navigations-aside .navigations-aside-sub li.active p {
    color: #656c9b
}

.navigations-aside .navigations-aside-sub li:hover p {
    color: #656c9b
}

.navigations-aside .navigations-aside-sub__item {
    margin: 5px 0
}

.navigations-aside .navigations-aside-sub__item .icon-job {
    font-size: .75rem
}

.navigations-aside .navigations-aside-sub__item__notifications {
    background: #feb88a;
    padding: 4px 10px;
    border-radius: 12px;
    line-height: 100%;
    color: #fff !important;
    font-size: .625rem
}

.navigations-aside .navigations-aside-content__item.displayed+.navigations-aside-sub {
    display: block
}

.navigation-breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #7d83aa;
    font-size: .875rem
}

@media screen and (min-width:20rem) {
    .navigation-breadcrumb {
        padding: 0 0 5px;
        margin-bottom: 5px
    }
}

.navigation-breadcrumb a {
    position: relative;
    color: #7d83aa;
    transition: .5s
}

.navigation-breadcrumb a:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    background: #7d83aa;
    transition: .15s
}

.navigation-breadcrumb a.active:after,
.navigation-breadcrumb a:hover:after {
    width: 100%
}

.navigation-breadcrumb li:after {
    content: "\e83a";
    font-family: myskillcamp;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px
}

.navigation-breadcrumb li.disable:after {
    content: ""
}

.navigation-breadcrumb li.disable a {
    cursor: default;
    color: #7d83aa
}

.navigation-breadcrumb li.disable a:hover:after {
    width: 0
}

.navigation-breadcrumb li:last-child:after {
    content: ""
}

.navigation-breadcrumb--light a {
    color: #fff !important
}

.navigation-breadcrumb--light a:after {
    background: #fff
}

.navigation-breadcrumb--light li {
    color: #fff !important
}

.navigation-breadcrumb--light li:after {
    color: #fff
}

.navigation-breadcrumb--large a {
    font-size: .875rem
}

@media screen and (min-width:32rem) {
    .navigation-breadcrumb--large a {
        font-size: 1.25rem
    }
}

.navigation-breadcrumb--large li:after {
    font-size: .875rem
}

@media screen and (min-width:32rem) {
    .navigation-breadcrumb--large li:after {
        font-size: 1.25rem
    }
}

.navigation-breadcrumb--nopadding {
    padding: 0
}

.navigation-breadcrumb--transform-none a {
    color: #b2b5cd
}

.navigation-breadcrumb--transform-none li:after {
    color: #b2b5cd
}

.navigation-breadcrumb--transform-none a:after {
    display: none
}

.nav-group {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 0;
    margin: 0 0 0 1rem
}

@media screen and (max-width:20rem) {
    .nav-group {
        width: 100%
    }
}

@media screen and (min-width:32rem) {
    .nav-group {
        align-items: center;
        justify-content: flex-start
    }
}

@media screen and (min-width:48rem) {
    .nav-group {
        justify-content: flex-end
    }
}

@media screen and (min-width:64rem) {
    .nav-group {
        justify-content: flex-start
    }
}

.nav-group__toggle {
    display: none;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    color: #fec6a1;
    text-decoration: none
}

@media screen and (max-width:32rem) {
    .nav-group__toggle {
        display: flex;
        margin-left: 15px
    }
}

@media screen and (max-width:20rem) {
    .nav-group__toggle {
        margin-left: 0
    }
}

.nav-group__toggle:before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #656c9b;
    transform: scale(1);
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    transition: all .35s cubic-bezier(.17, .67, .83, .67)
}

.nav-group__toggle .icon {
    z-index: 1;
    font-size: 1.375rem
}

.nav-group__toggle:hover:before {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    transform: scale(1.1)
}

.nav-group__content {
    padding: 0;
    margin-right: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap
}

.nav-group__item {
    cursor: pointer;
    text-decoration: none;
    color: #d3d5e4;
    font-size: .875rem;
    font-weight: 500;
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    margin: 25px 60px 0;
    display: inline-flex;
    align-items: center;
    min-height: 44px;
    transition: border-bottom .15s ease
}

.nav-group__item a {
    color: #b2b5cd
}

@media screen and (min-width:20rem) {
    .nav-group__item a {
        display: none
    }
}

@media screen and (min-width:48rem) {
    .nav-group__item a {
        display: block
    }
}

@media screen and (max-width:80rem) {
    .nav-group__item {
        margin-right: 40px;
        margin-left: 40px
    }
}

@media screen and (max-width:48rem) {
    .nav-group__item {
        margin-right: 18px;
        margin-left: 18px
    }
}

@media screen and (max-width:20rem) {
    .nav-group__item {
        margin-right: 11px;
        margin-left: 11px
    }
}

.nav-group .admin__nav {
    padding: 25px 0 !important;
    margin-bottom: 20px
}

.nav-group .admin__nav .nav__item {
    margin-left: 0
}

.nav-group .admin__nav .nav__item:hover {
    border-bottom: 2px solid #5150c4 !important
}

.nav-group .admin__nav .nav__item:hover a {
    color: #5150c4 !important
}

.navigation-dots {
    max-width: 650px;
    width: 100%;
    margin: 0 -6px
}

.navigation-dots__item {
    display: inline-block;
    outline: none;
    margin: 0 6px
}

.navigation-dots__item:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #b2b5cd;
    transition: .2s
}

.navigation-dots__item.active:after {
    background-color: #feb88a
}

.navigation-dots__item:hover:after {
    background-color: #feb88a
}

.navigations-access {
    padding: 20px 30px 20px 0;
    margin: -15px 0 15px -12px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.navigations-access .navigations-content__notifications {
    background: #feb88a;
    padding: 4px 10px;
    border-radius: 12px;
    line-height: 100%;
    color: #fff;
    font-size: .625rem
}

.navigations-access li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 35px;
    cursor: pointer
}

.navigations-access li.displayed .toggle i:before {
    transform: rotate(180deg)
}

.navigations-access p {
    font-size: .875rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center
}

.navigations-access p {
    color: #656c9b;
    transition: all .3s ease
}

.navigations-access p i {
    color: #656c9b;
    transition: all .3s ease
}

.navigations-access p i {
    font-size: 1.25rem;
    margin-right: 8px
}

.navigations-access .toggle i:before {
    transform: rotate(0deg);
    transition: all .3s ease;
    font-size: 1.125rem
}

.navigations-access .navigations-sub {
    display: none
}

.navigations-access .navigations-sub li {
    padding: 10px 10px 10px 20px;
    width: 100%;
    transition: all .3s ease
}

.navigations-access .navigations-sub li.active {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.navigations-access .navigations-sub li:hover {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.navigations-access .navigations-sub li.active p {
    color: #656c9b
}

.navigations-access .navigations-sub li:hover p {
    color: #656c9b
}

.navigations-access .navigations-sub__item {
    margin: 5px 0
}

.navigations-access .navigations-sub__item .icon-job {
    font-size: .75rem
}

.navigations-access .navigations-sub__item__notifications {
    background: #feb88a;
    padding: 4px 10px;
    border-radius: 12px;
    line-height: 100%;
    color: #fff !important;
    font-size: .625rem
}

.navigations-access .navigations-content__item.displayed+.navigations-sub {
    display: block
}

@media screen and (min-width:20rem) {
    .navigation-settings {
        margin-bottom: 10px;
        width: 100%;
        flex-direction: column
    }
}

@media screen and (min-width:48rem) {
    .navigation-settings {
        margin-bottom: 20px
    }
}

@media screen and (min-width:64rem) {
    .navigation-settings {
        flex-direction: row
    }
}

.navigation-settings__right .bookmark-learn-later {
    cursor: pointer;
    transition: all .3s ease
}

.navigation-settings__right .bookmark-learn-later i {
    font-size: 1.25rem;
    color: #fff;
    transition: all .3s ease
}

.navigation-settings__right .bookmark-learn-later:hover i {
    color: #fff;
    transition: all .3s ease
}

.navigation-settings__right .bookmark-learn-later.active i {
    color: #fff
}

.navigation-settings__right .bookmark-learn-later.active i:before {
    content: "\e924"
}

.navigation-settings__right msc-button {
    margin-left: 16px
}

@media screen and (min-width:20rem) {
    .navigation-settings--responsive ul {
        flex-direction: column;
        align-items: center
    }

    .navigation-settings--responsive ul .navigation-settings__item.active,
    .navigation-settings--responsive ul .navigation-settings__item:hover {
        border: none
    }

    .navigation-settings--responsive ul .navigation-settings__item--right {
        position: relative !important;
        transform: translateY(0) !important
    }
}

@media screen and (min-width:48rem) {
    .navigation-settings--responsive ul {
        align-items: flex-start
    }
}

@media screen and (min-width:64rem) {
    .navigation-settings--responsive ul {
        flex-direction: row;
        align-items: center
    }

    .navigation-settings--responsive ul .navigation-settings__item.active {
        border-bottom: 2px solid #261e6d
    }

    .navigation-settings--responsive ul .navigation-settings__item:hover {
        border-bottom: 2px solid #261e6d
    }

    .navigation-settings--responsive ul .navigation-settings__item--right {
        position: absolute !important;
        transform: translateY(-50%) !important
    }
}

@media screen and (min-width:20rem) {
    .navigation-settings__main {
        flex-direction: column
    }
}

@media screen and (min-width:48rem) {
    .navigation-settings__main {
        flex-direction: row
    }
}

.navigation-settings__list {
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    overflow-scrolling: touch;
    padding-top: 4px;
    border-bottom: 1px solid #d3d5e4;
    margin-bottom: 20px;
    width: 100%
}

@media screen and (min-width:20rem) {
    .navigation-settings__list {
        padding-top: 0;
        flex-direction: column
    }
}

@media screen and (min-width:48rem) {
    .navigation-settings__list {
        flex-direction: row
    }
}

.navigation-settings__item {
    font-size: .875rem;
    margin-right: 50px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 24px;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    border-bottom: 2px solid transparent;
    transition: border-bottom .3s ease
}

@media screen and (min-width:20rem) {
    .navigation-settings__item {
        margin-right: 10px
    }
}

@media screen and (min-width:48rem) {
    .navigation-settings__item {
        font-size: .875rem;
        margin-right: 50px
    }
}

.navigation-settings__item:last-child {
    margin-right: 0
}

.navigation-settings__back {
    width: 60px;
    margin-top: -25px;
    margin-left: 0
}

@media screen and (min-width:80rem) {
    .navigation-settings__back {
        margin-left: -50px
    }
}

.navigation-settings--light .navigation-settings__item a {
    color: rgba(255, 255, 255, .6)
}

.navigation-settings--light .navigation-settings__item:hover {
    border-bottom: 2px solid #fff
}

.navigation-settings--light .navigation-settings__item:hover a {
    color: #fff
}

.navigation-settings--light .navigation-settings__item.active {
    border-bottom: 2px solid #fff
}

.navigation-settings--light .navigation-settings__item.active a {
    color: #fff
}

.navigation-settings__logout a span {
    display: none
}

.navigation-settings__logout a:before {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-family: myskillcamp;
    font-size: .875rem;
    content: "\e88f";
    color: #fff;
    border-radius: 50%;
    background-color: #4443af;
    transform: scale(1)
}

.navigation-settings__logout a:before:hover {
    transform: scale(1.1);
    transition: .2s .175, .885, .32, 2
}

@media screen and (min-width:48rem) {
    .navigation-settings__logout a span {
        display: block
    }

    .navigation-settings__logout a:after,
    .navigation-settings__logout a:before {
        display: none
    }
}

.navigation-settings__logout:hover {
    border-bottom: none
}

.navigation-settings__logout:hover a {
    color: #ffa2a2
}

.navigations-sessions {
    padding: 20px 30px 20px 0;
    margin: -15px 0 -15px -12px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.navigations-sessions .navigations-content__notifications {
    background: #feb88a;
    padding: 4px 10px;
    border-radius: 12px;
    line-height: 100%;
    color: #fff;
    font-size: .625rem
}

.navigations-sessions li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 35px;
    cursor: pointer
}

.navigations-sessions li.displayed .toggle i:before {
    transform: rotate(180deg)
}

.navigations-sessions p {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #656c9b;
    transition: all .3s ease;
    display: flex;
    align-items: center
}

.navigations-sessions p i {
    font-size: 1.25rem;
    color: #656c9b;
    margin-right: 8px;
    transition: all .3s ease
}

.navigations-sessions .toggle i:before {
    transform: rotate(0deg);
    transition: all .3s ease;
    font-size: 1.125rem
}

.navigations-sessions .navigations-sub {
    display: none
}

.navigations-sessions .navigations-sub li {
    padding: 10px 10px 10px 20px;
    width: 100%;
    transition: all .3s ease
}

.navigations-sessions .navigations-sub li.active {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.navigations-sessions .navigations-sub li:hover {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.navigations-sessions .navigations-sub__item {
    margin: 5px 0
}

.navigations-sessions .navigations-sub__item__notifications {
    background: #feb88a;
    padding: 4px 10px;
    border-radius: 12px;
    line-height: 100%;
    color: #fff !important;
    font-size: .625rem
}

.navigations-sessions .navigations-content__item.displayed+.navigations-sub {
    display: block
}

.new-post {
    background-color: #fff;
    padding: 1.5rem 1.5rem .5rem
}

.new-post.active {
    background-color: transparent;
    padding: 0
}

.new-post.active .new-post__head {
    display: none
}

.new-post.active .new-post__timeline {
    display: block
}

.new-post.active .new-post__actions i {
    transform: rotate(-180deg);
    transition: all .3s ease
}

.new-post__head {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.new-post__timeline {
    display: none
}

.new-post__actions {
    display: flex;
    justify-content: center
}

.new-post__actions i {
    color: #535a8b;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all .3s ease
}

.new-post__actions i:hover {
    transform: scale(1.1);
    transition: all .3s ease
}

.new-post__left {
    display: flex;
    align-items: center;
    width: 75%
}

.new-post__right {
    width: 20%
}

.new-post__message {
    color: #535a8b;
    margin-left: 1rem
}

.box-composer {
    position: relative;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    margin-bottom: 20px
}

.box-composer {
    background-color: #fff
}

.comments {
    background-color: #fff
}

.comments {
    margin-top: 20px;
    margin-bottom: 30px;
    border-top: 1px solid rgba(211, 213, 228, .5)
}

.comments .box-composer {
    box-shadow: none
}

.comments__list {
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px
}

.comments .comments__showmore {
    transition: all .3s ease
}

.comments .comments__showmore:hover {
    color: #282d58
}

.comments .comment {
    padding: 0 calc(.5em*3.5) 0 calc(.5em*5);
    margin-bottom: 40px
}

.comments .comment__header {
    width: 100%;
    justify-content: space-between
}

.comments .comment__header,
.comments .comment__header .comment__profile {
    display: flex;
    align-items: center
}

.comments .comment__header .comment__profile .profile__name {
    margin-left: 22px
}

.comments .comment__header span {
    color: #b2b5cd;
    font-size: .75rem
}

.comments .comment__header__delete {
    cursor: pointer
}

.comments .comment__header__delete i {
    color: #b2b5cd;
    transition: all .3s ease
}

.comments .comment__header__delete:hover i {
    color: #282d58;
    transition: all .3s ease
}

.comments .comment__content {
    margin-left: 65px;
    font-size: .875rem;
    color: #7d83aa;
    max-width: 500px;
    text-align: left
}

.comments .comment__resources {
    display: flex;
    align-items: center;
    max-width: 400px;
    margin: 1rem 0 0 65px
}

.comments .comment__resources iframe,
.comments .comment__resources img,
.comments .comment__resources video {
    width: 100%;
    border-radius: 4px
}

.comments .comment__resources .resource-download {
    margin: 0 0 0 20px;
    background-color: rgba(211, 213, 228, .5);
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease
}

.comments .comment__resources .resource-download .icon {
    padding: 5px
}

.comments .comment__resources .resource-download:hover {
    background-color: #d3d5e4;
    transition: all .3s ease
}

.comments .comment__resources .resource-download .icon-download:hover {
    color: #fff
}

.comments .comment__preview {
    margin: 1rem 0 0 65px;
    cursor: pointer
}

.comments .comment__preview i {
    font-size: 1.125rem
}

.comments .comment__preview span {
    transition: all .3s ease
}

.comments .comment__preview:hover span {
    color: #f9aa81;
    transition: all .3s ease
}

.comments .comment__preview__status {
    margin: 0 0 0 .2rem;
    font-size: .875rem;
    color: #d3d5e4
}

.comments .comment__preview__status--failed {
    color: #f44436
}

.comments .comment__preview--processing {
    cursor: not-allowed !important
}

.comments .comment__preview--processing:hover span {
    color: #282d58 !important
}

.comments .comment__footer {
    margin-left: 70px;
    margin-top: 10px
}

.comments .comment__footer i {
    color: #8c91b4;
    cursor: pointer;
    transition: .35s
}

.comments .comment__footer i:hover {
    color: #282d58
}

.comments__response .box-composer {
    margin-left: 35px
}

.comments__footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(211, 213, 228, .5);
    padding: calc(.5em*3.5);
    font-size: .75rem;
    color: #b2b5cd
}

.comments__footer p:first-child {
    cursor: pointer
}

.post {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    margin-top: 15px;
}

.post__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0;
    padding: 10px 15px 5px;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.post__header .profile__infos {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.post__header .profile__infos .profile__name {
    font-size: .875rem;
    font-weight: 300
}

.post__header .profile__infos .profile__job {
    font-size: .875rem;
    color: #7d83aa;
    text-align: left
}

.post__header .profile__infos .profile__job i {
    margin-right: 5px;
    font-size: 1rem
}

.post__header .profile__infos .profile__job .profile__time {
    font-size: .75rem;
    color: #b2b5cd
}

.post__actions {
    margin: 0 -4px;
    display: flex
}

@media screen and (min-width:64rem) {
    .post__actions {
        margin: 0 -8px
    }
}

.post__actions>* {
    margin: 0 2px
}

@media screen and (min-width:64rem) {
    .post__actions>* {
        margin: 0 8px
    }
}

.post__actions .post__action {
    color: #7d83aa;
    font-size: .75rem;
    transition: color .3s ease
}

.post__actions .post__action i {
    margin-right: 6px;
    color: #b2b5cd;
    font-size: 1rem;
    transition: color .3s ease
}

.post__actions .post__action:hover {
    color: #282d58
}

.post__actions .post__action:hover i {
    color: #282d58
}

.post__actions .post__action:hover span i {
    color: #fff
}

.post__actions .post__notification {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 10px;
    height: 10px;
    text-align: center;
    border-radius: 50%;
    background-color: #49e2af
}

.post__actions .post__notification i {
    font-size: .5rem;
    color: #fff;
    margin-right: 0
}

.post__body {
    text-align: justify;
    padding: 5px 15px
}

.post__body .post__text {
    font-size: .875rem;
    color: #7d83aa;
    line-height: 18px;
    margin: 15px 0
}

.post__body .post__text .skill .flag,
.post__body .post__text .skill .icon {
    margin-right: .2rem
}

.post__body .post__text .skill .flag {
    max-width: 1.2rem
}

.post__body .post__text .skill br {
    display: none
}

.post__body .post__text__external-image {
    width: 100%;
    height: 100vh;
    max-height: 15rem;
    margin-top: 1rem;
    background-size: cover;
    background-position: 50%
}

@media screen and (min-width:48rem) {
    .post__body .post__text__external-image {
        max-height: 20rem
    }
}

.post__body .post__text__link {
    word-break: break-all;
    transition: all .3s ease
}

.post__body .post__text__link:hover {
    color: #ed8371;
    text-decoration: underline
}

.post__body .post__tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px
}

.post__body .post__tags .tag {
    margin: 0 5px
}

.post__body .post__resources {
    text-align: center
}

.post__body .post__resources img {
    max-width: 100%;
    border-radius: 4px
}

.post__body .post__resources iframe {
    width: 100%;
    height: 400px;
    max-height: 400px
}

.post__body .post__preview {
    margin: 1rem 0 0;
    cursor: pointer
}

.post__body .post__preview i {
    font-size: 1.125rem
}

.post__body .post__preview__status {
    margin: 0 0 0 .2rem;
    font-size: .875rem;
    color: #d3d5e4
}

.post__body .post__preview__status--failed {
    color: #f44436
}

.post__body .post__preview--processing {
    cursor: not-allowed !important
}

.post__body .post__preview--processing:hover span {
    color: #282d58 !important
}

.post__body .post__edited {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 0;
    color: #b2b5cd;
    font-size: .75rem
}

.post__footer {
    margin-top: 2rem;
    padding: 5px 15px
}

.post__footer .post__interaction {
    display: block;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

@media screen and (min-width:32rem) {
    .post__footer .post__interaction {
        display: flex
    }
}

.post__footer .post__counter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer
}

.post__footer .counter__comment {
    color: #7d83aa;
    font-size: .75rem;
    font-weight: 600;
    display: flex;
    margin-right: 20px;
    align-items: center
}

.post__footer .counter__reaction {
    color: #7d83aa;
    font-size: .75rem;
    font-weight: 600;
    display: flex;
    margin-right: 20px;
    align-items: center
}

.post__footer .counter__sharing {
    color: #7d83aa;
    font-size: .75rem;
    font-weight: 600;
    display: flex;
    margin-right: 20px;
    align-items: center
}

.post__footer .counter__reaction {
    margin-right: calc(.5em*1.5)
}

.post__footer .counter__comment {
    margin-right: calc(.5em*2)
}

.post__footer .counter__animation {
    position: relative;
    margin: 0
}

.post__footer .post__save {
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.post__footer .post__save .icon {
    margin: 0 10px 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.post__footer .post__save .icon:hover {
    color: #feb88a;
    transition: all .3s ease
}

.post__footer .post__save .save__download {
    background-color: rgba(211, 213, 228, .5);
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease
}

.post__footer .post__save .save__download .icon {
    margin: 0
}

.post__footer .post__save .save__download:hover {
    background-color: #d3d5e4;
    transition: all .3s ease
}

.post__footer .post__save .save__download .icon-download:hover {
    color: #fff
}

.post__footer .post__action {
    padding-left: 0;
    position: relative;
    margin-left: -22px;
    margin-right: -22px
}

.post__footer .link__action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-left: 22px;
    margin-right: 22px
}

.post__footer .link__action p {
    color: #7d83aa
}

.post__footer .link__action i {
    font-size: 1.25rem;
    margin-right: 10px;
    margin-left: 10px;
    color: #8c91b4
}

.post--approval {
    border: 1px solid #f44436
}

.post .post-approval {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px
}

.post .post-approval__text {
    display: flex;
    align-items: center;
    font-size: .75rem;
    color: #b2b5cd
}

.post .post-approval__text i {
    font-size: 1.375rem;
    color: #f44436;
    margin-right: 8px
}

.post .post-approval__actions {
    display: flex;
    margin: 0 -4px
}

.post .post-approval__actions msc-button {
    margin: 0 4px;
    padding: 4px 6px;
    font-size: .75rem
}

.post .post-approval__overlay {
    position: absolute;
    z-index: 100;
    top: 45px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(151, 155, 183, 0.5)
}

.post .post-tag {
    display: inline-block;
    margin: 16px 0 0;
    padding: 4px 4px 4px 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgba(211, 213, 228, .25)
}

.post .post-tag__text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .75rem;
    line-height: 18px;
    color: #7d83aa;
    font-weight: 900
}

.post .post-tag__text i {
    font-size: .875rem;
    color: #d3d5e4;
    margin-left: 10px
}

.post .post-tag__text i:nth-child(2) {
    margin: 0 6px;
    font-size: 1.125rem;
    color: #7d83aa
}

.window-embed {
    position: fixed;
    top: 80px;
    left: 285px;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 500;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease
}

@media screen and (min-width:20rem) {
    .window-embed {
        left: 0
    }
}

@media screen and (min-width:80rem) {
    .window-embed {
        left: 285px
    }
}

.window-embed.active {
    opacity: 1;
    visibility: visible
}

@media screen and (min-width:80rem) {
    .window-embed .window-hubspot-container {
        margin-left: -250px
    }
}

.alert-toast {
    position: fixed;
    z-index: 1000
}

.alert {
    min-height: 50px;
    max-width: 460px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    right: .5rem;
    padding: .5rem 1.25rem;
    border-radius: 4px;
    z-index: 5;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1)
}

.alert__message {
    margin-right: 20px;
    margin-left: 20px
}

.alert__left {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.alert__left i {
    font-size: 1.375rem
}

.alert.alert--white {
    background-color: #fff;
    color: #020d38;
    border: 1px solid #d3d5e4
}

.alert--warning {
    background-color: #efc772
}

.alert--warning.alert--white i:first-child {
    color: #efc772
}

.alert--info {
    background-color: #8290fd
}

.alert--info.alert--white i:first-child {
    color: #8290fd
}

.alert--success {
    background-color: #6cd4a8
}

.alert--success.alert--white i:first-child {
    color: #6cd4a8
}

.alert--error {
    background-color: #e57373
}

.alert--error .alert__left i {
    transform: rotate(45deg)
}

.alert--error.alert--white i:first-child {
    color: #e57373
}

.alert--notification {
    background-color: #17105a
}

.alert--notification .alert__left i {
    color: #e5a51d
}

.alert--notification.alert--white {
    border: 1px solid #5150c4;
    color: #5150c4
}

.alert--notification.alert--white i:first-child {
    color: #5150c4
}

.alert--notification.alert--white i:nth-child(2) {
    color: #020d38
}

.toaster {
    min-height: 80px;
    max-width: 320px;
    align-items: center;
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    right: .5rem;
    padding: 8px;
    border-radius: 4px;
    z-index: 5;
    background-color: #fff;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1);
    border: 1px solid #d3d5e4
}

.toaster,
.toaster__left {
    display: flex;
    justify-content: space-between
}

.toaster__left {
    padding-top: 8px
}

.toaster__left i {
    font-size: 1.375rem;
    color: #feb88a;
    margin-top: -8px
}

.toaster__left .toaster__content {
    margin: 0 8px
}

.toaster__left .toaster__content .content__title {
    font-weight: 700;
    font-size: .875rem;
    margin-bottom: 10px
}

.toaster__left .toaster__content .content__desc {
    color: #b2b5cd;
    font-size: .75rem
}

.toaster--warning i:first-child {
    color: #5150c4
}

.avatar {
    width: 42px;
    height: 42px;
    min-width: 42px;
    border-radius: 100%;
    background-size: cover;
    background-position: 50%;
    position: relative
}

.avatar--min {
    width: 28px;
    height: 28px;
    min-width: 28px
}

.avatar--tiny {
    width: 24px;
    height: 24px;
    min-width: 24px
}

.avatar--small {
    width: 30px;
    height: 30px;
    min-width: 30px
}

.avatar--regular {
    width: 40px;
    height: 40px;
    min-width: 40px
}

.avatar--square {
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 4px
}

.avatar span {
    position: absolute;
    bottom: 0;
    right: 1px;
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #6cd4a8
}

.avatar span.offline {
    background-color: #ffa2a2
}

.avatar-contact {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.avatar-contact .avatar {
    margin-right: 10px
}

.avatar-contact .avatar__content {
    font-size: .875rem
}

.avatar-contact .avatar__content .profile__name {
    margin-bottom: 3px
}

.avatar-contact .avatar__content .profile__job {
    color: #b2b5cd
}

.avatar-contact--font-big .avatar__content {
    font-size: 1rem
}

.avatar-contact--color-light .avatar__content .profile__name {
    color: #7d83aa
}

.avatar-lang {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.avatar-lang .avatar {
    margin-right: 10px
}

.avatar-lang .avatar__content {
    font-size: .875rem
}

.avatar-lang .avatar__content .profile__lang {
    margin-top: 5px;
    display: flex;
    align-items: center
}

.avatar-lang .avatar__content .profile__lang .profile__img {
    width: 20px;
    height: auto;
    padding-right: 5%
}

.avatar-lang .avatar__content .profile__lang .profile__lang-desc {
    color: #b2b5cd
}

.avatar-editable {
    position: relative
}

.avatar-editable a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #ffa2a2;
    color: #fff
}

.avatar-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
    margin-right: 10px;
    overflow: visible
}

.avatar-group li {
    position: relative;
    margin-left: -10px;
    font-size: .875rem;
    font-weight: 700
}

.avatar-group li:nth-child(5) {
    margin-left: 5px
}

.avatar-group li:hover>.tooltip {
    display: block
}

.avatar-group__text {
    font-size: .75rem;
    margin-left: 5px
}

.avatar-group--add .avatar {
    border: 2px solid #fff
}

.avatar-group--add .avatar-group__text {
    margin-left: 16px;
    color: #b2b5cd
}

.avatar-checked,
.avatar-profile {
    position: relative;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.avatar-checked a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 16px);
    right: -20px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.avatar-profile a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 16px);
    right: -20px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.avatar-checked p {
    font-size: .875rem;
    color: #020d38;
    text-align: left
}

.avatar-profile p {
    font-size: .875rem;
    color: #020d38;
    text-align: left
}

.avatar-checked p span {
    display: block;
    margin-top: 4px;
    font-size: .75rem;
    color: #7d83aa
}

.avatar-profile p span {
    display: block;
    margin-top: 4px;
    font-size: .75rem;
    color: #7d83aa
}

.avatar-checked .name {
    font-size: .875rem;
    color: #020d38;
    text-align: left
}

.avatar-profile .name {
    font-size: .875rem;
    color: #020d38;
    text-align: left
}

.avatar-checked>span {
    position: absolute;
    bottom: 1px;
    left: 31px;
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    z-index: 100;
    border-radius: 50%;
    background-color: #6cd4a8
}

.avatar-profile>span {
    position: absolute;
    bottom: 1px;
    left: 31px;
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    z-index: 100;
    border-radius: 50%;
    background-color: #6cd4a8
}

.avatar-checked>span.offline {
    background-color: #ffa2a2
}

.avatar-profile>span.offline {
    background-color: #ffa2a2
}

.avatar-group-participant .avatar-group__text {
    color: #7d83aa;
    font-size: .75rem
}

.avatar-picto {
    background-color: #261e6d;
    display: flex;
    align-items: center;
    justify-content: center
}

.avatar-picto i {
    color: #fff;
    font-size: 1rem
}

.avatar-tag {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(211, 213, 228, .4);
    border-radius: 4px;
    padding: 6px
}

.avatar-tag p {
    color: #535a8b;
    margin: 0 6px
}

.avatar-tag i {
    cursor: pointer;
    font-size: .875rem;
    color: #7d83aa;
    transition: transform .2s cubic-bezier(.175, .885, .32, 2)
}

.avatar-tag i,
.avatar-tag i:hover {
    transform: scale(1.1)
}

.avatar-tiny,
.avatar-user {
    display: flex;
    align-items: center
}

.avatar-tiny .profile__name {
    font-size: .875rem;
    color: #7d83aa;
    margin-left: 8px
}

.avatar-user .profile__name {
    font-size: .875rem;
    color: #7d83aa;
    margin-left: 8px
}

.avatar-tiny .notification {
    top: -3px;
    right: -5px;
    background: #f44436;
    border: none;
    width: 4px;
    height: 4px
}

.avatar-user .notification {
    top: -3px;
    right: -5px;
    background: #f44436;
    border: none;
    width: 4px;
    height: 4px
}

.avatar-camp {
    background-color: #261e6d;
    display: flex;
    align-items: center;
    justify-content: center
}

.avatar-camp i {
    color: #fff;
    font-size: 1.75rem
}

.chart-pie {
    position: relative
}

.chart-pie__value {
    font-size: 1.125rem;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%)
}

.chart-pie--x-small {
    width: 80px;
    margin-top: -10px
}

.chart-pie--x-small .chart-pie__value {
    font-size: .75rem
}

.chart-pie--small {
    width: 112px;
    margin-top: -10px
}

.chart-pie--small .chart-pie__value {
    font-size: .75rem
}

.chart-pie--medium {
    width: 140px;
    margin-top: -10px
}

.chart-pie--medium .chart-pie__value {
    font-size: .75rem
}

.chart-pie--regular {
    width: 200px;
    margin-top: -10px
}

.chart-pie--regular .chart-pie__value {
    font-size: .75rem
}

.chart-pie--large {
    width: 300px;
    margin-top: -10px
}

.chart-pie--large .chart-pie__value {
    font-size: .75rem
}

.chart-pie--x-large {
    width: 400px;
    margin-top: -10px
}

.chart-pie--x-large .chart-pie__value {
    font-size: .75rem
}

.checkbox {
    display: flex;
    align-items: center;
    position: relative;
    height: 18px;
    padding-left: 30px;
    cursor: pointer;
    font-size: .875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #b2b5cd
}

.checkbox span {
    margin-bottom: 0 !important
}

.checkbox:hover input~.checkbox__checkmark {
    border-color: #d3d5e4
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.checkbox input:checked~.checkbox__checkmark {
    background-color: #5150c4;
    border-color: #5150c4
}

.checkbox input:checked~.checkbox__checkmark:after {
    display: block
}

.checkbox__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid #b2b5cd;
    border-radius: 3px;
    transition: 1s ease
}

.checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 0;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg)
}

.checkbox--disabled {
    cursor: not-allowed !important
}

.checkbox--disabled .input__checkmark {
    border: 2px solid #b2b5cd
}

.checkbox--disabled .checkbox__checkmark {
    border: 2px solid #b2b5cd;
    transition: all .3s ease
}

.checkbox--disabled input:checked~.checkbox__checkmark {
    background-color: #b2b5cd;
    border-color: #b2b5cd
}

.checkbox--disabled:hover .checkbox__checkmark {
    border: 2px solid #656c9b
}

.checkbox--intermediate input:checked~.checkbox__checkmark {
    background-color: #5150c4;
    border-color: #5150c4
}

.checkbox--intermediate input:checked~.checkbox__checkmark:after {
    content: "";
    display: block;
    width: 11px;
    height: 3px;
    border-radius: 50%;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg)
}

.checkbox--rounded .checkbox__checkmark {
    border-radius: 50%
}

.checkbox--orange input:checked~.checkbox__checkmark {
    background-color: #feb88a;
    border-color: #feb88a
}

.checkbox--grey input:checked~.checkbox__checkmark {
    background-color: #414776;
    border-color: #414776
}

.checkbox--rounded-orange .checkbox__checkmark {
    border-radius: 50%
}

.checkbox--rounded-orange input:checked~.checkbox__checkmark {
    background-color: #feb88a;
    border-color: #feb88a
}

.checkbox--alert input:checked~.checkbox__checkmark {
    background-color: #ffa2a2;
    border-color: #ffa2a2
}

.checkbox--cta {
    width: 100%
}

.checkbox--cta:hover .checkbox--cta__button {
    opacity: 1;
    visibility: visible
}

.checkbox--cta__button {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #d3d5e4;
    color: #8c91b4;
    transition: all .3s ease
}

.checkbox--cta__button:hover {
    color: #535a8b;
    text-decoration: underline
}

.animate-search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s ease
}

.animate-search--shadow-sm {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.animate-search.active {
    width: 170px;
    transition: all .3s ease
}

.animate-search.active .animate-search__input {
    display: block;
    margin: 0 0 0 .75rem
}

.animate-search.active .animate-search__icon {
    background-color: #343392;
    border-radius: 0 4px 4px 0;
    transition: all .3s ease
}

.animate-search.active .animate-search__icon:hover i {
    transform: scale(1.25);
    transition: all .3s ease
}

.animate-search.active .animate-search__icon i {
    color: #fff;
    transition: all .3s ease
}

.animate-search.active:hover {
    background-color: #fff
}

.animate-search:hover {
    background-color: #343392;
    transition: all .3s ease
}

.animate-search:hover .animate-search__icon i {
    color: #fff
}

.animate-search__icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center
}

.animate-search__icon i {
    font-size: .875rem;
    color: #343392;
    transition: all .3s ease
}

.animate-search__input {
    display: none
}

.animate-search__input input {
    width: 100%;
    height: 100%;
    background: transparent;
    resize: none;
    outline: none;
    border: none;
    font-family: Circular Std Book;
    font-size: .875rem;
    color: #7d83aa
}

.input--date {
    position: relative
}

.input--date .input__icon {
    border-left: 2px solid #d3d5e4
}

.input--date .input__icon i {
    font-size: 1.25rem;
    margin: 0 !important
}

.input--date .input__icon.input__icon--hover:hover {
    cursor: pointer
}

.input--date .input__icon--trash {
    right: 40px !important;
    border-left: none;
    cursor: pointer
}

.input--date .input__icon--trash i {
    color: #d3d5e4 !important;
    transition: all .3s ease
}

.input--date .input__icon--trash:hover i {
    color: #8c91b4 !important;
    transition: all .3s ease
}

.input--date.active+.dropdown-datepicker,
.input--date.active .dropdown-datepicker {
    display: block
}

.input--date.active .input__icon--trash {
    border: none
}

.dropdown-datepicker {
    display: none
}

.dropdown-datepicker--right .datepicker {
    left: -90px
}

.dropdown-datepicker--right .datepicker:before {
    left: 120px
}

.dropdown-datepicker--top {
    top: 0;
    position: absolute
}

.dropdown-datepicker--top .datepicker {
    transform: translateY(-100%)
}

.dropdown-datepicker--top .datepicker:before {
    top: calc(100% - 3px)
}

.input-number {
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    border: 2px solid #d3d5e4;
    text-align: center;
    color: #7d83aa
}

.input-number--tiny {
    width: 2.5rem;
    height: 2.25rem;
    border: 1px solid #d3d5e4
}

.input-number--transparent {
    background-color: transparent;
    border-color: #fff
}

.multi-select {
    position: relative
}

.multi-select.active .multi-select__content {
    border: 2px solid #5150c4
}

.multi-select.active .multi-select__content:after {
    color: #5150c4;
    transform: rotate(180deg)
}

.multi-select__label {
    display: block;
    color: #b2b5cd;
    margin-bottom: 4px;
    font-size: .875rem
}

.multi-select__content {
    margin-top: 8px;
    padding: 12px;
    cursor: pointer;
    color: #8c91b4;
    transition: .2s ease
}

.multi-select__content label {
    margin: 0;
    padding-right: 5px
}

.multi-select__content i {
    margin-right: 8px
}

.multi-select__content:after {
    top: 8px;
    transform: rotate(0);
    transition: .2s ease
}

.multi-select__container {
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1);
    padding: 15px;
    position: absolute;
    transition: all .4s ease;
    width: 100%;
    z-index: 5
}

.multi-select__options {
    max-height: 250px;
    overflow: auto;
    margin-top: 10px
}

.multi-select__options .options__item {
    color: #7d83aa;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .75rem;
    margin-right: 12px;
    padding: 5px 23px 5px 10px;
    word-break: break-word
}

.multi-select__options .options__item--with-avatar p {
    padding-left: 8px
}

.multi-select__options .options__item--select-all {
    font-size: .875rem;
    font-weight: 700
}

.multi-select__options .options__checkbox {
    margin-left: auto;
    padding-left: calc(.5em*1.5)
}

.multi-select__options .options__checkbox .checkbox {
    padding: 0;
    margin: 0
}

.multi-select__data {
    width: 100%;
    max-height: 250px;
    overflow: auto;
    padding: 20px;
    position: absolute;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: all .4s ease;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1);
    z-index: 5
}

.multi-select__data .data-search {
    border-bottom: 1px solid #d3d5e4
}

.multi-select__data .data-list {
    margin: 15px 0 0
}

.multi-select__data .data-list__item {
    cursor: pointer;
    margin: calc(.5em*1.5);
    font-size: .875rem;
    color: #7d83aa;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px
}

.multi-select__data .data-list__item .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #8c91b4;
    color: #fff;
    margin-right: calc(.5em*1.5)
}

.multi-select__data .data-list__item p {
    margin: 0
}

.multi-select__data .data-list__checkbox {
    margin-left: auto;
    padding-left: calc(.5em*1.5)
}

.multi-select__data .data-list__checkbox .checkbox {
    padding: 0
}

.multi-select--avatar .data-search {
    padding: 0 0 1rem
}

.multi-select--avatar .data-list__item {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.multi-select--avatar .data-list__item .avatar {
    margin: 0 1rem 0 0
}

.multi-select--tiny .multi-select__content {
    border: 1px solid #d3d5e4;
    padding: 4px 6px;
    height: 30px
}

.multi-select--tiny .multi-select__content select {
    font-size: .75rem
}

.multi-select--tiny .multi-select__content:after {
    top: auto
}

.multi-select--single .multi-select__data {
    padding: 0
}

.multi-select--single .data-search {
    margin: calc(.5em*2.5)
}

.multi-select--single .data-list__item {
    padding: calc(.5em*1.5);
    margin: 0
}

.multi-select--single .data-list__item p {
    margin: 0
}

.multi-select--single .data-list__item:hover {
    background: #f8f9ff
}

.multi-select--no-border.active .multi-select__content {
    border: none
}

.multi-select--no-border.active .multi-select__content:focus-within {
    border: none !important
}

.multi-select--no-border .multi-select__content {
    border: none;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.multi-select--no-dropdown-arrow .multi-select__content:after {
    content: "" !important
}

.radio {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 30px;
    margin-bottom: 16px;
    cursor: pointer;
    font-size: .875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #7d83aa
}

.radio:focus input~.radio__checkmark {
    border: 2px solid #d3d5e4
}

.radio:hover input~.radio__checkmark {
    border: 2px solid #d3d5e4
}

.radio:focus input:checked~.radio__checkmark {
    border: 2px solid #b2b5cd
}

.radio:hover input:checked~.radio__checkmark {
    border: 2px solid #b2b5cd
}

.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.radio input:checked~.radio__checkmark {
    border: 2px solid #261e6d
}

.radio input:checked~.radio__checkmark:after {
    background-color: #261e6d;
    display: block
}

.radio--secondary input:checked~.radio__checkmark {
    border-color: #feb88a
}

.radio--secondary input:checked~.radio__checkmark:after {
    background-color: #feb88a
}

.radio--tertiary input:checked~.radio__checkmark {
    border-color: #ff6b70
}

.radio--tertiary input:checked~.radio__checkmark:after {
    background-color: #ff6b70
}

.radio--disabled {
    cursor: not-allowed
}

.radio--disabled .radio__checkmark {
    border-color: #b2b5cd
}

.radio--disabled:focus input~.radio__checkmark {
    border: 2px solid #b2b5cd
}

.radio--disabled:hover input~.radio__checkmark {
    border: 2px solid #b2b5cd
}

.radio--tiny {
    padding-left: 20px;
    height: 12px
}

.radio--tiny .radio__checkmark {
    height: 14px;
    width: 14px
}

.radio--tiny .radio__checkmark:after {
    width: 6px;
    height: 6px
}

.radio__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #8c91b4;
    transition: .2s ease
}

.radio__checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    transform: translate(-50%, -50%)
}

.form-search {
    padding: 6px 10px;
    border-radius: 5px;
    z-index: 5
}

.form-search:hover .input .icon {
    transform: scale(1.15)
}

.form-search .input {
    color: #fff;
    background: transparent;
    position: relative;
    padding: 0;
    border: none;
    height: auto;
    display: flex;
    align-items: center
}

.form-search .input .icon {
    font-size: 1.25rem;
    color: #f9aa81;
    margin-right: 10px;
    transform: scale(1);
    transition: transform .2s cubic-bezier(.175, .885, .32, 2)
}

.form-search .input__search {
    border: none;
    width: 100%;
    height: 100%;
    background: transparent;
    resize: none;
    outline: none;
    font-family: Circular Std Book;
    font-size: .875rem;
    color: #7d83aa
}

.form-search .icon {
    color: #fff
}

.form-search--large {
    width: 280px
}

.form-search--square {
    border-radius: 4px;
    border: 2px solid #d3d5e4
}

.form-search--grey {
    background-color: rgba(211, 213, 228, .7)
}

.form-search--grey .input input::-moz-placeholder {
    color: #fff
}

.form-search--grey .input input:-ms-input-placeholder {
    color: #fff
}

.form-search--grey .input input {
    color: #fff
}

.form-search--grey .input input::placeholder {
    color: #fff
}

.form-search--grey .input .icon {
    color: #fff
}

.form-search--white {
    background: #fff
}

.form-search--white {
    color: #feb88a
}

.form-search--white .icon {
    color: #feb88a
}

.form-search--orange {
    background-color: rgba(237, 131, 113, .4);
    color: #fff
}

.form-search--orange .input input::-moz-placeholder {
    color: #fff
}

.form-search--orange .input input:-ms-input-placeholder {
    color: #fff
}

.form-search--orange .input input {
    color: #fff
}

.form-search--orange .input input::placeholder {
    color: #fff
}

.form-search--orange .input .icon {
    color: #fff !important
}

.form-search--black {
    background-color: rgba(211, 213, 228, .3);
    color: #8c91b4
}

.form-search--black .input input::-moz-placeholder {
    color: #8c91b4
}

.form-search--black .input input:-ms-input-placeholder {
    color: #8c91b4
}

.form-search--black .input input {
    color: #8c91b4
}

.form-search--black .input input::placeholder {
    color: #8c91b4
}

.form-search--black .input .icon {
    color: #8c91b4
}

.form-search--transparent {
    background-color: rgba(65, 71, 118, .1)
}

.form-search--no-border {
    border: none
}

.form-search--tiny .input--search .icon {
    font-size: .875rem
}

.form-search--tiny input[placeholder] {
    font-size: .75rem
}

.form-search--shadow {
    box-shadow: 0 13px 24px 0 rgba(0, 0, 0, .03)
}

.form-search--shadow-sm {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.form-search--light {
    border: 1px solid #d3d5e4
}

.form-search--light .input--search .icon {
    font-size: .875rem;
    color: #5150c4
}

.form-search--light input[placeholder] {
    font-size: .75rem;
    color: #b2b5cd
}

.form-search--sort .form__wrapper.input .icon {
    display: block;
    font-size: 1rem;
    margin-left: 10px;
    margin-right: 0;
    width: 20px;
    height: 20px;
    color: #4443af !important
}

.form-search--rounded {
    border-radius: 24px
}

.input.form-select .select {
    min-width: 160px
}

.input.form-select--tiny .select {
    border: 1px solid #d3d5e4;
    padding: 4px 6px;
    height: 30px
}

.input.form-select--tiny .select:after {
    top: auto
}

.input.form-select--tiny .select select {
    font-size: .75rem
}

.input.form-select--shadow {
    box-shadow: 0 1px 1px 0 rgba(10, 22, 70, .1)
}

.input.form-select--no-border .select {
    border: none
}

.input.form-select--transparent {
    background-color: transparent
}

.input.form-select--transparent .select {
    background: transparent;
    border-color: #fff
}

.input.form-select--transparent .select select {
    color: #fff
}

.input.form-select--transparent .select:before {
    background: transparent
}

.input.form-select--transparent .select:after {
    color: transparent
}

.select-input {
    background: #fff;
    padding: 12px;
    border-radius: 4px;
    border: 2px solid #d3d5e4
}

.select-input,
.select-input__dropdown {
    display: flex;
    align-items: center;
    height: 45px
}

.select-input__dropdown {
    position: relative;
    width: 85px;
    cursor: pointer
}

.select-input__dropdown.active:after {
    transform: translateY(-50%) rotate(180deg);
    transition: all .3s ease
}

.select-input__dropdown:hover:after {
    color: #8c91b4;
    transition: all .3s ease
}

.select-input__dropdown__text {
    color: #8c91b4;
    font-size: .875rem
}

.select-input__dropdown:after {
    font-family: myskillcamp;
    display: inline-block;
    content: "\e80b";
    font-size: 1.25rem;
    color: #d3d5e4;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    pointer-events: none;
    transition: all .3s ease
}

.select-input__dropdown .dropdown-phone {
    display: none;
    position: absolute;
    top: 43px;
    left: -10px;
    min-width: 93px;
    padding: 12px;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: all .3s ease;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1);
    z-index: 5;
    font-size: .875rem;
    max-height: 200px;
    overflow: auto
}

.select-input__dropdown .dropdown-phone.active {
    display: block
}

.select-input__dropdown .dropdown-phone__item {
    cursor: pointer;
    color: #8c91b4;
    font-size: .875rem;
    padding: .3rem 0;
    transition: all .3s ease
}

.select-input__dropdown .dropdown-phone__item:hover {
    color: #656c9b;
    transition: all .3s ease
}

.select-input__input {
    border-left: 2px solid #d3d5e4;
    width: 100%
}

.select-input__input input {
    border: none;
    width: 100%;
    height: 100%;
    color: #535a8b;
    padding: 12px;
    font-size: .875rem;
    background: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.select .select__tag__ctn {
    display: inline-block
}

.select .select__tag__ctn:not(:first-child) {
    margin-left: .4rem
}

.select .select__tag {
    display: flex;
    align-items: center;
    overflow: hidden
}

.select .select__tag .tag {
    white-space: nowrap;
    margin: 0 5px
}

@media (-ms-high-contrast:none) {

    .select .select__tag>*,
    .select ::-ms-backdrop {
        margin: 0 5px
    }
}

.select .dropdown-learning-language {
    width: 100%;
    top: 45px;
    left: 0
}

.switch {
    position: relative;
    width: 53px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.switch--orange .switch-inner:before {
    background-color: #feb88a !important
}

.switch.disabled {
    cursor: not-allowed !important;
    opacity: .5 !important
}

.switch--transparent .switch-inner:before {
    background-color: rgba(23, 16, 90, .4) !important
}

.switch--transparent .switch-inner:after {
    background-color: rgba(23, 16, 90, .1) !important
}

.switch .switch-checkbox {
    display: none
}

.switch .switch-checkbox:checked+.switch-label .switch-inner {
    margin-left: 0
}

.switch .switch-checkbox:checked+.switch-label .switch-actions {
    right: 0;
    background-color: #fff
}

.switch .switch-checkbox:checked+.switch-label .switch-actions .icon:before {
    content: "\e935";
    color: #5150c4
}

.switch .switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px
}

.switch .switch-label .switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin .3s ease-in 0s
}

.switch .switch-label .switch-inner:after,
.switch .switch-label .switch-inner:before {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: .875rem;
    color: #fff;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: 700;
    box-sizing: border-box;
    content: ""
}

.switch .switch-label .switch-inner:before {
    padding-left: 10px;
    background-color: #343392
}

.switch .switch-label .switch-inner:after {
    padding-right: 10px;
    background-color: rgba(52, 51, 146, .2)
}

.switch .switch-label .switch-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    margin: 6.5px;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 19px;
    border-radius: 20px;
    transition: all .3s ease-in 0s;
    background-color: #fff
}

.switch .switch-label .switch-actions .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important
}

.switch .switch-label .switch-actions .icon:before {
    color: #5150c4;
    font-size: .75rem
}

.switch--tiny {
    width: 30px
}

.switch--tiny .switch-label {
    border-radius: 10px
}

.switch--tiny .switch-label .switch-inner:after,
.switch--tiny .switch-label .switch-inner:before {
    height: 18px
}

.switch--tiny .switch-label .switch-actions {
    width: 12px;
    height: 12px;
    margin: 3px;
    right: 12px
}

.textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 16px;
    border-radius: 4px;
    border: 2px solid #d3d5e4;
    outline: none;
    color: #535a8b;
    font-size: .875rem;
    font-family: Circular Std Book !important;
    transition: all .35s ease;
    resize: none
}

.textarea__content {
    position: relative
}

.textarea__icon {
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 1.125rem;
    color: #7d83aa
}

.textarea::-moz-placeholder {
    font-family: Circular Std Book !important;
    color: #b2b5cd
}

.textarea:-ms-input-placeholder {
    font-family: Circular Std Book !important;
    color: #b2b5cd
}

.textarea::placeholder {
    font-family: Circular Std Book !important;
    color: #b2b5cd
}

.textarea__label {
    display: block;
    color: #b2b5cd;
    margin-bottom: 4px;
    font-size: .875rem
}

.textarea--disabled {
    cursor: not-allowed;
    background-color: rgba(211, 213, 228, .35);
    border: none
}

.textarea--error {
    border: 2px solid #f44436
}

.file-upload {
    display: flex;
    justify-content: space-between;
    min-width: 250px;
    height: 54px;
    padding-left: 2rem;
    cursor: pointer
}

.file-upload .input__text {
    border-radius: .25rem 0 0 .25rem;
    padding-left: 3rem
}

.file-upload .input__text.icon-upload {
    padding-left: 1rem
}

.file-upload .input__text:not(:first-child) {
    padding-left: 3rem
}

.file-upload input {
    display: none
}

.file-upload__ctn {
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px solid #d3d5e4;
    padding: .5rem 1rem
}

.file-upload__ctn img {
    margin-right: .5rem;
    width: 2rem
}

.file-upload__ctn p {
    font-size: .8rem
}

.file-upload__action {
    display: flex;
    align-items: center;
    width: 2.5rem;
    border-radius: 0 .25rem .25rem 0
}

.file-upload__action.validate {
    background-color: #80dab4
}

.file-upload__action .spinner {
    cursor: pointer;
    margin: auto;
    color: #fff
}

.file-upload__action i {
    cursor: pointer;
    margin: auto;
    color: #fff
}

.file-upload__action .spinner.icon-loader,
.file-upload__action i.icon-loader {
    width: 1rem;
    height: auto;
    transform-origin: center;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}

.media-manager__container {
    font-size: 1.125rem;
    margin: 0
}

.media-manager__container .file-upload {
    padding-left: 0;
    margin-top: 1rem
}

.media-manager__container .file-upload__ctn {
    color: #8c91b4;
    border-width: 2px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none
}

.media-manager__container .file-upload__ctn img {
    width: 32px
}

.media-manager__container .file-upload__sentence {
    margin-bottom: 0
}

.media-manager__container .file-upload--advertising {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: .5rem .5rem 1rem 0;
    padding: 0 .5rem;
    font-size: .75rem;
    font-style: italic
}

.media-manager__container .file-upload--advertising--sentence {
    margin: 0;
    font-size: .75rem !important;
    color: #d3d5e4 !important
}

.media-manager__container .file-upload--advertising--sentence span {
    font-weight: 700;
    letter-spacing: .05rem;
    color: #b2b5cd
}

.media-manager__container .file-upload--advertising--sentence span .icon {
    font-size: .875rem
}

.media-manager__container .medias--container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 .5rem 1rem;
    justify-content: normal
}

.media-manager__container .media--item {
    width: calc(33% - .4rem);
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    margin: .5rem 1rem 0 0;
    padding: .75rem;
    background-color: rgba(211, 213, 228, .2);
    border-radius: 6px
}

.media-manager__container .media--item:nth-child(3n) {
    margin-right: 0
}

.media-manager__container .media--item__downloadable {
    transition: all .3s ease-in-out
}

.media-manager__container .media--item__downloadable:hover {
    cursor: pointer;
    background-color: rgba(211, 213, 228, .3)
}

@media screen and (max-width:1920px) {
    .media-manager__container .media--item {
        width: calc(33% - .6rem)
    }
}

@media screen and (max-width:1680px) {
    .media-manager__container .media--item {
        width: calc(50% - .5rem)
    }

    .media-manager__container .media--item:nth-child(2n) {
        margin-right: 0
    }

    .media-manager__container .media--item:nth-child(3n) {
        margin-right: 1rem
    }
}

@media screen and (max-width:32rem) {
    .media-manager__container .media--item {
        width: 100%;
        margin-right: 0
    }

    .media-manager__container .media--item:nth-child(2n),
    .media-manager__container .media--item:nth-child(3n) {
        margin-right: 0
    }
}

.media-manager__container .media--file {
    margin-right: 10px;
    width: 2rem
}

.media-manager__container .media--description {
    margin-right: 10px
}

.media-manager__container .media--description .resource__name {
    font-size: .75rem;
    color: #8c91b4
}

.media-manager__container .media--actions {
    margin-left: auto;
    font-size: 1rem
}

.media-manager__container .media--actions .icon {
    color: #7d83aa;
    transition: all .3s ease-in-out
}

.media-manager__container .media--actions .icon:hover {
    cursor: pointer;
    color: #414776
}

@-webkit-keyframes spin {
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    to {
        transform: rotate(1turn)
    }
}

.upload-media {
    position: relative
}

.upload-media__file {
    cursor: pointer;
    position: absolute;
    font-size: 1rem;
    color: #8c91b4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    opacity: 1
}

.upload-media__file::-webkit-file-upload-button {
    display: none
}

.upload-media__edit {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 36px;
    border: 2px solid #d3d5e4;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.upload-media__edit .upload-media__text {
    width: calc(100% - 18px);
    height: 100%;
    padding: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    color: #8c91b4
}

.upload-media__sources {
    width: 18px;
    height: auto;
    margin: 0 0 0 8px
}

.upload-media__custom {
    display: flex;
    align-items: center;
    justify-content: center
}

.upload-media__input {
    font-size: 1rem;
    color: #8c91b4;
    width: 100%;
    border: 2px solid #d3d5e4;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 8px 12px
}

.upload-media__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: 36px;
    height: 36px;
    background-color: #5150c4;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff
}

.upload-media__submit i {
    font-size: 1.25rem
}

.upload-media__submit .spinner-lines-v2 {
    padding: 4px 6px
}

.upload-media__upload {
    display: block
}

.upload-media--loading .upload-media__file,
.upload-media--loading .upload-media__input,
.upload-media--loading .upload-media__upload,
.upload-media__error,
.upload-media__sent,
.upload-media__spinner,
.upload-media__success {
    display: none
}

.upload-media--loading .upload-media__edit {
    display: flex
}

.upload-media--loading .upload-media__spinner {
    display: block
}

.upload-media--loading .upload-media__error,
.upload-media--loading .upload-media__success,
.upload-media--sent .upload-media__file,
.upload-media--sent .upload-media__input {
    display: none
}

.upload-media--sent .upload-media__submit {
    cursor: pointer
}

.upload-media--sent .upload-media__upload {
    display: none
}

.upload-media--sent .upload-media__edit {
    display: flex
}

.upload-media--sent .upload-media__sent {
    display: block
}

.upload-media--sent .upload-media__error,
.upload-media--sent .upload-media__success,
.upload-media--success .upload-media__file,
.upload-media--success .upload-media__input {
    display: none
}

.upload-media--success .upload-media__submit {
    background-color: #49e2af
}

.upload-media--success .upload-media__upload {
    display: none
}

.upload-media--success .upload-media__edit {
    display: flex
}

.upload-media--success .upload-media__success {
    display: block
}

.upload-media--error .upload-media__file,
.upload-media--error .upload-media__input,
.upload-media--success .upload-media__error {
    display: none
}

.upload-media--error .upload-media__submit {
    background-color: #49e2af
}

.upload-media--error .upload-media__upload {
    display: none
}

.upload-media--error .upload-media__edit {
    display: flex
}

.upload-media--error .upload-media__submit {
    background-color: #ffa2a2
}

.upload-media--error .upload-media__success {
    display: none
}

.upload-media--error .upload-media__error {
    display: block
}

.link-add--white {
    color: #fff !important
}

.link-add--white:hover i:after {
    background-color: #fff
}

.link-add--white i {
    background-color: #fff !important;
    color: #feb88a !important
}

.link-add--icon-left {
    flex-direction: row-reverse;
    display: inline-flex
}

.link-add--icon-left i {
    margin-right: 8px !important;
    margin-left: 0 !important
}

.link-add--outline {
    transition: none !important
}

.link-add--outline i {
    font-size: 1.125rem !important;
    color: #5150c4 !important;
    transition: all .3s ease
}

.link-add--outline i,
.link-add--outline i:after {
    background-color: transparent !important
}

.link-add--outline:hover i {
    transform: scale(1.1)
}

.link-help--white {
    color: #fff !important
}

.link-help--white i {
    color: #feb88a !important
}

.link-virtual-card {
    font-size: .875rem
}

.link-virtual-card i {
    margin: 0 10px 0 0;
    font-size: 1.25rem !important;
    transition: all .3s ease
}

.link-virtual-card:hover i {
    color: #feb88a
}

@media screen and (min-width:20rem) {
    .link span {
        display: none
    }
}

@media screen and (min-width:32rem) {
    .link span {
        display: block
    }
}

.progress {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.progress-content {
    width: 100%;
    position: relative
}

.progress-content--label {
    width: calc(100% - 50px)
}

.progress-content__bar {
    width: 100%;
    background-color: rgba(211, 213, 228, .7);
    height: 6px;
    border-radius: 6px
}

.progress-content__full {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    background: #93dfbf;
    border-radius: 6px
}

.progress-content__icon {
    z-index: 1;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%)
}

.progress-content__icon i {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #93dfbf;
    color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: .875rem
}

.progress__label {
    color: #8c91b4
}

.progress--theme .progress-content__full {
    background: linear-gradient(90deg, #feb88a 0%, #db7564)
}

.progress--theme .progress-content__bar {
    background: #fff
}

.progress--theme .progress__label {
    color: #fff
}

.progress--theme .progress-content__icon i {
    background: #fff
}

.progress--bookboon .progress-content__full {
    background-color: #f59833 !important
}

.progress--bookboon .progress-content__bar {
    background: rgba(211, 213, 228, .7)
}

.progress--udemy .progress-content__full {
    background-color: #ea5252 !important
}

.progress--udemy .progress-content__bar {
    background: rgba(211, 213, 228, .7)
}

.progress--openclassroom .progress-content__full {
    background-color: #7550eb !important
}

.progress--openclassroom .progress-content__bar {
    background: rgba(211, 213, 228, .7)
}

.progress--edflex .progress-content__full {
    background-color: #216ab2 !important
}

.progress--edflex .progress-content__bar {
    background: rgba(211, 213, 228, .7)
}

.progress--cefora .progress-content__full {
    background-color: #251b5a !important
}

.progress--cefora .progress-content__bar {
    background: rgba(211, 213, 228, .7)
}

.progress--vertical {
    flex-wrap: wrap
}

.progress--vertical .progress-content {
    order: 1
}

.progress--vertical .progress-content--label {
    width: 100%
}

.progress--vertical .progress__label {
    order: 0;
    margin-bottom: calc(.5em*1.5)
}

.progress-stats {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: 7px;
    width: 145px;
    border-radius: 4px;
    background-color: #d3d5e4
}

.progress-stats__bar {
    width: auto;
    height: 7px;
    border-radius: 4px;
    position: absolute
}

.progress-stats__bar:first-child {
    z-index: 5
}

.progress-stats__bar:last-child {
    z-index: 1
}

.progress-stats__bar--green {
    background-color: #49e2af
}

.progress-stats__bar--blue {
    background-color: #8290fd
}

@media (-ms-high-contrast:none) {

    .progress-stats,
    ::-ms-backdrop {
        align-items: baseline
    }
}

.progress-time-lapse {
    display: inline-block;
    padding: 3px;
    background-color: #020d38;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px
}

.progress-time-lapse__content {
    font-size: .875rem;
    color: #fff
}

.progress-time-lapse__content i {
    font-size: 1rem
}

.resource {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(.5em*1);
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.resource img {
    margin-right: 10px;
    width: 2.5rem
}

.resource__desc {
    display: flex;
    flex-direction: column
}

.resource__name {
    margin-bottom: .5em
}

.resource__size {
    color: #b2b5cd;
    font-size: .75rem
}

.resource__status {
    font-size: .875rem;
    margin: .5rem 0 0
}

.resource--light .resource__desc .resource__size span {
    display: none
}

.resource--min {
    padding: 0;
    background-color: transparent
}

.resource--min img {
    max-width: 20px;
    margin-right: 8px;
    height: auto
}

.resource--min .resource__name {
    color: #8c91b4;
    font-size: .875rem;
    margin-bottom: 0
}

.resource--min .resource__size {
    display: none
}

.resource--bg {
    background-color: rgba(211, 213, 228, .1)
}

.resource--no-cursor {
    cursor: default
}

.skill {
    display: inline-block;
    border-radius: 16px;
    background-color: rgba(140, 145, 180, .3);
    padding: 6px 21px 6px 14px;
    margin: 4px
}

.skill__association-logo {
    display: block;
    height: 14px;
    margin-right: 8px
}

.skill__associations-number {
    margin-left: 8px;
    background: #656c9b;
    color: #fff;
    font-size: .7rem;
    padding: 4px 8px;
    border-radius: 10px
}

.skill--pointer {
    cursor: pointer
}

.skill__content {
    display: flex;
    justify-content: center;
    align-items: center
}

.skill__counter {
    color: #b2b5cd;
    font-family: Circular Std Book;
    font-size: .875rem
}

.skill__text {
    color: #b2b5cd;
    font-family: Circular Std Book;
    font-size: .875rem
}

.skill__counter {
    font-weight: 700;
    margin-left: 5px
}

.skill__title {
    margin: 15px 0
}

.skill__icon {
    margin-right: 5px
}

.skill__icon i {
    font-size: 1rem;
    color: #b2b5cd
}

.skill__close {
    margin-left: 5px;
    display: flex;
    align-items: center
}

.skill__close i {
    font-size: .625rem;
    color: #b2b5cd
}

.skill__level span {
    border-radius: 10px
}

.skill__level span.active {
    background-color: #ffa2a2;
    border: none
}

.skill__suggestion {
    margin-top: 20px
}

.skill__suggestion,
.skill__suggestion .skill__arrow {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.skill__suggestion .skill__arrow {
    margin: 0 8px
}

.skill__suggestion .skill__arrow i {
    color: #d3d5e4
}

.skill__suggestion .skill__arrow:before {
    content: "";
    width: 82px;
    height: 1px;
    margin-right: -8px;
    background-color: #d3d5e4
}

.skill--association {
    cursor: -webkit-grab;
    cursor: grab;
    border: 1px solid transparent;
    background: #fff;
    border: 1px solid #d3d5e4
}

.skill--association:hover {
    border: 1px solid #7d83aa
}

.skill--association.selected {
    border: 2px solid #4c77ff
}

.skill--association.no-cursor {
    cursor: auto !important
}

.skill--alternate.skill--level {
    cursor: pointer;
    border: none;
    background-color: rgba(211, 213, 228, .2)
}

.skill--alternate.skill--level .skill__text {
    color: #8c91b4
}

.skill--level {
    background-color: #fff;
    border: 1px solid #d3d5e4;
    padding: 3px 21px 3px 14px
}

.skill--level--beginner .skill__level span:first-child {
    background-color: #ffa2a2
}

.skill--level--expert .skill__level span:first-child {
    background-color: #ffa2a2
}

.skill--level--expert .skill__level span:nth-child(2) {
    background-color: #ffa2a2
}

.skill--level--expert .skill__level span:nth-child(3) {
    background-color: #ffa2a2
}

.skill--level--intermediate .skill__level span:first-child {
    background-color: #ffa2a2
}

.skill--level--intermediate .skill__level span:nth-child(2) {
    background-color: #ffa2a2
}

.skill--disabled {
    opacity: .6
}

.skill--disabled i {
    margin-right: 5px
}

.skill--disabled:hover {
    background-color: #020d38
}

.skill--disabled:hover .skill__text {
    color: #7d83aa
}

.skill--no-hover span:hover {
    background-color: #b2b5cd !important
}

.skill--no-hover span.active:hover {
    background-color: #ffa2a2 !important
}

.skill--level {
    position: relative;
    transition: .5s
}

.skill--level .skill__content .skill__icon {
    margin-right: 4px
}

.skill--level .skill__text {
    top: 5px;
    left: 7px;
    width: calc(100% - 14px);
    text-align: center;
    opacity: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    transition: all .5s ease-in-out
}

.skill--level .skill__rating {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto
}

.skill--level .skill__rating span {
    display: inline-block;
    width: 4px;
    height: 7px;
    margin-left: 1px;
    margin-right: 1px;
    border-radius: 2.5px;
    background-color: #d3d5e4
}

.skill--level .skill__rating span.active {
    background-color: #5150c4
}

.skill--level .skill__rating span:hover {
    background-color: #5150c4
}

.skill--level .skill__rating span:nth-child(2) {
    height: 9px
}

.skill--level .skill__rating span:nth-child(3) {
    height: 11px
}

.skill--level .skill__action {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -23px;
    left: 7px;
    width: calc(100% - 14px);
    height: 20px;
    opacity: 0;
    transition: all .5s ease-in-out
}

.skill--level:hover .skill__text {
    top: 23px
}

.skill--level:hover .skill__action {
    opacity: 1;
    top: 7px
}

.skill--hoverable {
    position: relative;
    overflow: hidden;
    min-width: 9rem;
    background-color: #fff
}

.skill--hoverable:hover .skill__hover-actions {
    transform: translateY(0)
}

.skill--hoverable .skill__hover-actions {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 .8rem;
    background-color: #fff;
    transform: translateY(-100%);
    transition: transform .3s ease-in-out
}

.skill--hoverable .skill__hover-actions p {
    margin-left: .6rem;
    padding-left: .6rem;
    color: #b2b5cd;
    font-size: .875rem;
    border-left: 1px solid #b2b5cd
}

.skill--square {
    border-radius: 4px;
    border: 0 solid transparent;
    padding: 12px 21px 12px 14px
}

.skill--selected {
    background: rgba(211, 213, 228, .2);
    border: transparent;
    padding: 1px 8px 2px 14px
}

.skill--selected .skill__text {
    color: #8c91b4;
    margin-right: 8px
}

.skill--selected .skill__close {
    cursor: pointer
}

.skill--selected .skill__close i {
    font-size: .875rem
}

.skill--rating {
    border: transparent;
    background: rgba(211, 213, 228, .2);
    padding: 4px 21px 4px 14px;
    overflow: hidden;
    cursor: pointer
}

.skill--rating .skill__text {
    color: #8c91b4;
    opacity: 1;
    transition: opacity .35s ease
}

.skill--rating .skill__rating span.active {
    background-color: #ffa2a2 !important
}

.skill--rating .skill__rating span:hover {
    background-color: #ffa2a2 !important
}

.skill--rating:hover .skill__text {
    opacity: 0
}

.skill--light {
    background-color: #fff
}

.skill--large {
    padding: 12px 14px
}

.skill--more {
    background-color: #fff;
    border: 1px solid #d3d5e4;
    padding: 4px 14px;
    transition: .5s
}

.skill--more .skill__text {
    margin-right: -.1rem;
    margin-top: -.1rem;
    transition: .5s
}

.skill--more .skill__text:after {
    content: "•••";
    font-size: .375rem;
    text-align: center;
    letter-spacing: .1rem
}

.skill--shadow-none {
    box-shadow: none
}

.skill--shadow-xxs {
    box-shadow: 0 13px 24px 0 rgba(0, 0, 0, .03)
}

.skill--shadow-xs {
    box-shadow: 0 1px 1px 0 rgba(10, 22, 70, .1)
}

.skill--shadow-sm {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.skill--tiny {
    padding: 4px 8px 4px 6px
}

.skill--tiny .skill__text {
    font-size: .75rem
}

.skill--available {
    padding: 4px 8px 4px 6px;
    background-color: transparent;
    border: 1px solid #49e2af
}

.skill--available .skill__text {
    font-size: .75rem
}

.skill--alert {
    padding: 4px 8px 4px 6px;
    background-color: transparent;
    border: 1px solid #ffa2a2
}

.skill--alert .skill__text {
    font-size: .75rem
}

.skill__level {
    display: flex;
    align-items: baseline;
    margin: 0 -1px
}

.skill__level span {
    width: 3px;
    height: 7px;
    border-radius: 2px;
    margin: 0 1px;
    background-color: #b2b5cd
}

.skill__level span.active {
    background-color: #ffa2a2
}

.skill__level span:hover {
    background-color: #ffa2a2
}

.skill__level span:nth-child(2) {
    height: 11px
}

.skill__level span:nth-child(3) {
    height: 14px;
    margin-right: 5px
}

.skill--level.skill--rating .skill__rating span.active {
    background-color: #ffa2a2 !important
}

.skill--level.skill--rating .skill__rating span:hover {
    background-color: #ffa2a2 !important
}

.slide .skill__list .skill:hover {
    background-color: rgba(211, 213, 228, .3)
}

.slide .skill__list .skill:hover .skill__text {
    color: #656c9b
}

.slide .skill__list .skill:hover .skill__close i {
    color: #d3d5e4
}

.slide .skill__list .skill .skill__level span {
    cursor: pointer
}

.slide .skill__list .skill .skill__level span:hover {
    background-color: #ffa2a2
}

.slide .skill__list .skill .skill__level span:hover.active {
    background-color: #d3d5e4
}

.slide .skill__list .skill--level {
    border-radius: 15px
}

.slide .skill__list .skill--level .skill__text {
    margin: 0
}

.slide .skill__list .skill--level .skill__rating span {
    background-color: #d3d5e4
}

.slide .skill__list .skill--level .skill__rating span:hover {
    background-color: #ffa2a2
}

@media (-ms-high-contrast:none) {

    .skill--level.skill__text,
    ::-ms-backdrop {
        width: 100%
    }
}

.spinner {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 8px
}

.spinner span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 3px;
    border-radius: 50%;
    -webkit-animation: spinner 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
    animation: spinner 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
    border: 3px solid transparent;
    border-top: 3px solid #fff
}

.spinner span:first-child {
    -webkit-animation-delay: -.45s;
    animation-delay: -.45s
}

.spinner span:nth-child(2) {
    -webkit-animation-delay: -.3s;
    animation-delay: -.3s
}

.spinner span:nth-child(3) {
    -webkit-animation-delay: -.15s;
    animation-delay: -.15s
}

.spinner-lines {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    top: 4px;
    left: 8px
}

.spinner-lines div {
    transform-origin: 12px 12px;
    -webkit-animation: spinner-lines 1.2s linear infinite;
    animation: spinner-lines 1.2s linear infinite
}

.spinner-lines div:after {
    content: "";
    display: block;
    width: 2px;
    height: 4px;
    position: absolute;
    top: 18px;
    left: 10px;
    background: #fff
}

.spinner-lines div:first-child {
    transform: rotate(0deg);
    -webkit-animation-delay: .15s;
    animation-delay: .15s
}

.spinner-lines div:nth-child(2) {
    transform: rotate(45deg);
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.spinner-lines div:nth-child(3) {
    transform: rotate(90deg);
    -webkit-animation-delay: .45s;
    animation-delay: .45s
}

.spinner-lines div:nth-child(4) {
    transform: rotate(135deg);
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

.spinner-lines div:nth-child(5) {
    transform: rotate(180deg);
    -webkit-animation-delay: .75s;
    animation-delay: .75s
}

.spinner-lines div:nth-child(6) {
    transform: rotate(225deg);
    -webkit-animation-delay: .9s;
    animation-delay: .9s
}

.spinner-lines div:nth-child(7) {
    transform: rotate(270deg);
    -webkit-animation-delay: 1.05s;
    animation-delay: 1.05s
}

.spinner-lines div:nth-child(8) {
    transform: rotate(315deg);
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s
}

@-webkit-keyframes spinner-lines {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes spinner-lines {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.table-headline {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .75rem;
    color: #8c91b4;
    max-width: 90px
}

.table-headline p {
    line-height: .75rem !important
}

.table-headline__sort {
    margin: 0 5px
}

.table-headline__sort .icon {
    display: block;
    color: #7d83aa;
    transition: color .2s ease;
    cursor: pointer;
    margin: -8px 0
}

.table-headline__sort .icon.active {
    color: #f4997d
}

.table-headline__sort .icon:hover {
    color: #f4997d
}

.table-headline__check-all .icon {
    font-size: 1.5rem
}

.tag {
    background-color: var(--v2-color-primary-600);
    border-radius: 12px;
    padding: 2px 8px;
    display: inline-block
}

.tag--icon {
    display: flex;
    cursor: pointer
}

.tag--icon .icon {
    margin: 0 10px 0 0
}

.tag--date {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center
}

.tag--date .tag__text {
    position: relative;
    display: flex;
    flex-direction: column
}

.tag--date .tag__text .text__day {
    font-size: 1.375rem;
    font-weight: 800;
    line-height: 1
}

.tag--multi-date {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px
}

.tag--multi-date .tag__text {
    position: relative;
    display: flex;
    flex-direction: column
}

.tag--multi-date .tag__text .text__day {
    font-size: 1rem;
    font-weight: 900
}

.tag--multi-date .tag__text .text__year {
    margin-top: 2px;
    padding-top: 2px;
    border-top: 1px solid #fff
}

.tag--multi-date.tag--year .text__year {
    margin-top: 2px;
    padding-top: 2px;
    position: relative;
    transform: rotate(0deg);
    border-top: none;
    top: 0;
    left: 0
}

.tag--multi-date.tag--min {
    width: 44px;
    height: 44px;
    text-align: center
}

.tag--multi-date.tag--min.tag--year {
    padding-right: 0
}

.tag--multi-date.tag--min.tag--year .tag__text .text__day {
    font-size: .75rem;
    font-weight: 900
}

.tag--multi-date.tag--min.tag--year .tag__text .text__year {
    top: 0;
    font-size: .625rem;
    margin-top: 0;
    padding-top: 0;
    border-top: 1px solid #fff
}

.tag--min {
    width: 44px;
    height: 44px;
    text-align: center
}

.tag--year .text__year {
    position: absolute;
    top: 13px;
    right: -4px;
    font-size: .75rem;
    padding-top: 0;
    border-top: 1px solid #fff;
    transform: rotate(-90deg)
}

.tag--year.tag--date {
    padding-right: 30px
}

.tag--year.tag--date .tag__text .text__year {
    top: 12px;
    right: -32px
}

.tag--year.tag--date .tag__text .text__month,
.tag--year.tag--date .tag__text .text__year {
    font-size: 1rem
}

.tag--year.tag--date .tag__text .text__day {
    font-size: 1.375rem
}

.tag--year.tag--min {
    padding-right: 22px
}

.tag--year.tag--min .tag__text .text__day,
.tag--year.tag--min .tag__text .text__month,
.tag--year.tag--min .tag__text .text__year {
    display: block;
    font-size: .75rem
}

.tag--year.tag--min .tag__text .text__day {
    line-height: 100%;
    font-weight: 900;
    font-size: 1.375rem
}

.tag--year.tag--min .tag__text .text__year {
    top: 10px;
    right: -23px
}

.tag--red {
    background-color: #ffa2a2
}

.tag--green {
    background-color: #49e2af
}

.tag--blue {
    background-color: #37beeb
}

.tag--blue-btn {
    background-color: #8290fd
}

.tag--orange {
    background-color: #ffc688
}

.tag--gray {
    background-color: #8c91b4
}

.tag--graylight {
    background-color: #d3d5e4
}

.tag--white .tag__text {
    color: #7d83aa
}

.tag--layer:after,
.tag--layer:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: inherit;
    border-radius: 4px
}

.tag--layer:before {
    top: 5px;
    left: 5px;
    z-index: 0;
    opacity: .6
}

.tag--layer:after {
    top: 10px;
    left: 10px;
    z-index: -1;
    opacity: .2
}

.tag__text {
    font-size: .75rem;
    color: #fff;
    text-align: center
}

.tag.tag-provider {
    background: #fff;
    padding: calc(.5em*0.5) calc(.5em*1.5);
    border-radius: 12px
}

.tag.tag-provider:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(211, 213, 228, .25)
}

.tag.tag-provider span {
    font-size: .875rem;
    line-height: 1.25rem;
    color: #b2b5cd;
    margin: 0 calc(.5em*1)
}

.tag.tag-provider i {
    cursor: pointer;
    font-size: 1rem;
    color: #d3d5e4;
    transition: color .25s ease
}

.tag.tag-provider i:hover {
    color: #7d83aa
}

.tag.tag-provider--orange:before {
    background: #feb88a
}

.tag.tag-provider--secondary-red:before {
    background-color: #ffa2a2
}

.tag.tag-provider--secondary-blue:before {
    background: #37beeb
}

.tag.tag-provider--secondary-orange:before {
    background: #feb88a
}

.tag.tag-provider--primary:before {
    background: #feb88a
}

@media (-ms-high-contrast:none) {

    .tag,
    ::-ms-backdrop {
        padding: 3px 8px 0
    }
}

.theme-tag {
    position: relative;
    padding: 12px 14px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    cursor: pointer;
    transition: all .3s ease
}

.theme-tag:hover {
    transform: scale(1.05);
    transition: all .3s ease
}

.theme-tag__content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem
}

.theme-tag__text {
    color: #535a8b;
    margin-right: 8px
}

.theme-tag__counter {
    color: #b2b5cd
}

.theme-tag__notification {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #ffa2a2
}

.slide-external-link__camp-block {
    display: flex;
    justify-content: space-between;
    border: .1rem solid #d3d5e4;
    border-radius: .4rem;
    padding: 1rem .5rem
}

.slide-external-link__camp-block:not(:first-of-type) {
    margin-top: 1rem
}

.slide-external-link__camp-block__name {
    width: 60%;
    line-height: 1.2rem
}

.slide-external-link__camp-block__infos {
    display: flex;
    color: #d3d5e4;
    width: 20%
}

.slide-external-link__camp-block__infos .icon {
    margin-right: .2rem
}

.slide-external-link__camp-block__infos span {
    margin-right: .4rem;
    color: #ffa2a2
}

.slide-external-link__camp-block .checkbox {
    width: 18px
}

.slide-external-link__choice-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    color: #d3d5e4;
    background: #fff;
    border: 1px solid #d3d5e4;
    border-radius: .4rem;
    cursor: pointer;
    transition: border .3s ease-out
}

.slide-external-link__choice-block:hover:before {
    opacity: 0
}

.slide-external-link__choice-block:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 3px;
    width: calc(100% - 6px);
    height: 100%;
    opacity: .5;
    border-radius: .4rem;
    box-shadow: 0 2px 2px 2px #d3d5e4;
    transition: opacity .3s ease-out
}

.slide-external-link__choice-block .checkbox {
    pointer-events: none
}

.slide-external-link__choice-block__infos {
    width: 100%
}

.slide-external-link__choice-block .icon {
    margin-right: .5rem;
    font-size: 1.8rem
}

.slide-external-link__choice-block p {
    color: #8c91b4
}

.slide-external-link__choice-block span {
    color: #d3d5e4;
    font-style: italic;
    font-size: .8rem
}

.slide-external-link__selected-skills {
    width: calc(100% + 4rem);
    margin-left: -2rem;
    padding: 1rem 2rem;
    border-bottom: .01rem solid #d3d5e4
}

.slide-external-link__selected-skills h2 {
    margin-bottom: 1rem
}

.slide-external-link__available-skills {
    padding-top: 1rem
}

.slide-external-link__available-skills h2 {
    margin-bottom: 1rem
}

.slide-external-link__available-skills__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem
}

.slide-external-link .skill {
    height: 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center
}

.slide-external-link .skill:hover {
    cursor: pointer
}

.slide-external-link {
    padding: 0 !important
}

.slide-external-link__header {
    position: relative;
    width: 100%;
    height: 72px;
    z-index: 10;
    padding: 16px;
    top: 30px
}

.slide-external-link__header--end {
    justify-content: flex-end
}

.slide-external-link__header--transparent {
    background: transparent
}

.slide-external-link__header__controls {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.slide-external-link__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 40%;
    overflow: hidden;
    background: linear-gradient(#feb88a, #db7564)
}

.slide-external-link__top-bg {
    position: absolute;
    left: -25px;
    bottom: -25px
}

.slide-external-link__top-bg img {
    width: 140px;
    height: auto;
    opacity: .6
}

.slide-external-link__top-content {
    display: block;
    justify-content: center;
    width: 100%
}

.slide-external-link__top-content img {
    display: block;
    margin: 0 auto
}

.slide-external-link__top-content .illus-phone {
    max-width: 100px;
    height: auto;
    margin-bottom: 16px
}

.slide-external-link__center {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 60%;
    transform: translateY(-50%)
}

.slide-external-link__bottom {
    padding: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60%;
    overflow: hidden;
    background: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px
}

.slide-external-link__intro {
    display: flex;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    color: #7d83aa;
    border-bottom: 1px solid #d3d5e4
}

.slide-external-link__intro .icon {
    color: #d3d5e4;
    margin-right: 1rem;
    font-size: 2rem
}

.slide-external-link__intro span {
    display: block;
    margin-top: .2rem;
    color: #d3d5e4;
    font-size: .8rem;
    font-style: italic
}

.slide-external-link__title {
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1rem
}

.slide-external-link__title:after {
    content: "";
    width: calc(100% + 2rem);
    height: 1px;
    background: #d3d5e4;
    position: absolute;
    left: 0;
    bottom: 0
}

.slide-external-link__text {
    text-align: center
}

.slide-external-link__text h1 {
    font-size: 68px;
    margin: 24px 0
}

.slide-external-link__text .subtitle {
    font-size: 1.25rem;
    font-weight: 900;
    color: #7d83aa;
    margin-bottom: 18px
}

.slide-external-link__text p {
    font-size: 1.25rem;
    font-weight: 900;
    color: #7d83aa;
    margin-bottom: 18px
}

.slide-external-link__text p {
    font-size: .875rem;
    color: #b2b5cd
}

.slide-external-link__body {
    padding: 2rem;
    height: calc(100% - 145px)
}

.slide-external-link__body.scrollable {
    height: calc(100% - 3rem);
    overflow-y: scroll
}

.slide-external-link__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #fff
}

.slide-external-link__logo img {
    width: 22px;
    height: auto
}

.slide-external-link__actions,
.slide-external-link__back {
    width: 28px
}

.slide-external-link__actions i,
.slide-external-link__back i {
    font-size: 1.375rem
}

.slide-external-link__paragraph {
    font-size: .875rem;
    color: #8c91b4
}

.slide-external-link__fieldset,
.slide-external-link__paragraph {
    margin-bottom: 32px;
    margin-left: -32px;
    margin-right: -32px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    border-bottom: 1px solid #d8dae6
}

.slide-external-link__fieldset:last-child {
    border-bottom: none
}

.slide-external-link__label {
    display: block;
    color: #8c91b4;
    margin-bottom: 4px;
    font-size: .875rem
}

.slide-external-link__input {
    margin: 16px 0;
    position: relative;
    padding: 0;
    height: auto;
    border: none;
    background: #fff;
    border-radius: 4px
}

.slide-external-link__input .select {
    position: relative;
    height: inherit !important;
    min-height: 40px;
    background: #fff;
    margin: 0;
    padding: 5px 10px;
    border-radius: 4px;
    border: 2px solid rgba(151, 155, 183, .5);
    color: #8c91b4;
    font-size: .875rem;
    display: flex;
    align-items: center
}

.slide-external-link__input .select:before {
    width: 30px;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    background: #fff;
    content: "";
    pointer-events: none
}

.slide-external-link__input .select select {
    font-size: .875rem;
    color: #8c91b4;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    height: 100%;
    width: 100%;
    cursor: pointer;
    outline: none;
    padding-right: 0;
    padding-left: 0
}

.slide-external-link__input .select-flag__value {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.slide-external-link__input .select-flag__icon {
    width: 24px;
    height: auto;
    margin-right: 12px
}

.slide-external-link .input-image {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 140px;
    margin-top: 8px;
    background-size: cover;
    background-position: 50%;
    border-radius: 4px;
    border: 2px solid rgba(151, 155, 183, .5)
}

.slide-external-link .input-image p {
    font-size: .875rem;
    color: #b2b5cd
}

.slide-external-link .input-image a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -11px;
    bottom: -11px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #d3d5e4;
    transform: scale(1) rotate(90deg);
    transition: transform .2s cubic-bezier(.175, .885, .32, 2)
}

.slide-external-link .input-image a:hover {
    transform: scale(1.05) rotate(90deg)
}

.slide-external-link .input-duration {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.slide-external-link .input-duration label {
    flex-basis: 100%
}

.slide-external-link .input-duration .select,
.slide-external-link .input-duration input {
    width: 45%;
    height: 40px
}

.slide-external-link .input-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 140px;
    margin-top: 8px;
    background-size: cover;
    background-position: 50%;
    border-radius: 4px
}

.slide-external-link .input-upload__preview {
    width: 100%;
    height: 100%
}

.slide-external-link .input-upload__preview img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.slide-external-link .input-upload__ctn {
    position: relative;
    width: 45%;
    height: 100%
}

.slide-external-link .input-upload__ctn .picture__delete {
    position: absolute;
    top: -.6rem;
    right: -.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%
}

.slide-external-link .input-upload .content__action msc-button {
    margin-bottom: 1rem
}

.slide-external-link .input-upload .content__action p {
    font-size: .9rem;
    color: #b2b5cd
}

.slide-external-link__buttons {
    margin: 64px 0 32px;
    text-align: center
}

.slide-external-link__buttons msc-button {
    display: block;
    margin: 8px auto
}

.slide-external-link__card {
    margin: 60px auto;
    max-width: 300px
}

.slide-external-link .slide__footer {
    margin: 0 !important
}

.slide-card-finished .finished-item {
    margin: 1.5rem 0
}

.slide-card-finished .finished-item__label {
    margin: 0 0 1rem
}

.slide-card-finished .finished-item__half {
    display: flex;
    justify-content: space-between
}

.slide-card-finished .finished-item__half .input__text {
    width: 50%
}

.slide-mentoring-programs .programs-list {
    margin: 2rem 0 0
}

.slide-mentoring-programs .programs-list__item {
    background-color: rgba(211, 213, 228, .3);
    margin: 0 0 1rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s ease
}

.slide-mentoring-programs .programs-list__item .item-title i {
    font-size: 1.125rem;
    color: #f4997d
}

.slide-mentoring-programs .programs-list__item .item-title span {
    margin: 0 0 0 1rem
}

.slide-mentoring-programs .programs-list__item .item-action i:before {
    transform: translateX(0);
    transition: all .3s ease
}

.slide-mentoring-programs .programs-list__item:hover {
    background-color: rgba(211, 213, 228, .7)
}

.slide-mentoring-programs .programs-list__item:hover .item-action i:before {
    transform: translateX(10px)
}

.slide-mentoring-programs .mentors-list {
    margin: 2rem 0 0
}

.slide-mentoring-programs .mentors-list__title {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.slide-mentoring-programs .mentors-list__item {
    background-color: rgba(211, 213, 228, .3);
    margin: 0 0 1rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s ease
}

.slide-mentoring-programs .mentors-list__item.selected {
    background-color: rgba(211, 213, 228, .7)
}

.slide-mentoring-programs .mentors-list__item .item-title span {
    margin: 0 0 0 1rem
}

.slide-mentoring-programs .mentors-list__item:hover {
    background-color: rgba(211, 213, 228, .7)
}

.slide-mentoring-programs .mentors-list__item:hover .item-action i:before {
    transform: translateX(10px)
}

.slide-watch-video .watch-video {
    margin: 1rem 0 0
}

.slide-watch-video .watch-video iframe {
    width: 100%;
    height: 300px
}

.slide-watch-video .watch-desc {
    margin: 1rem 0 0;
    color: #d3d5e4
}

@media screen and (min-width:64rem) {
    .slide-timeline-embed {
        width: calc(100% - 690px) !important
    }
}

@media screen and (min-width:80rem) {
    .slide-timeline-embed {
        width: calc(100% - 890px) !important
    }
}

@media screen and (min-width:90rem) {
    .slide-timeline-embed {
        width: calc(100% - 1090px) !important
    }
}

.slide-timeline-embed .embed-item {
    margin: 1.5rem 0
}

.slide-timeline-embed .embed-item__label {
    margin: 0 0 1rem
}

.slide-timeline-embed .embed-item iframe {
    width: 100%;
    height: 300px
}

@media screen and (min-width:64rem) {
    .slide-timeline-embed .slide__footer {
        width: calc(100% - 690px) !important
    }
}

@media screen and (min-width:80rem) {
    .slide-timeline-embed .slide__footer {
        width: calc(100% - 890px) !important
    }
}

@media screen and (min-width:90rem) {
    .slide-timeline-embed .slide__footer {
        width: calc(100% - 1090px) !important
    }
}

.slide-add-member .slide__title h2 {
    font-size: 1.25rem;
    border-bottom: 1px solid #d3d5e4;
    color: #656c9b;
    padding-bottom: 25px
}

.slide-add-member .slide__body__scroll-content {
    height: calc(100vh - 500px)
}

.slide-add-member .slide__actions {
    display: inline-block
}

.slide-add-member .slide__actions msc-button {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    margin-top: 12px;
    color: #b2b5cd
}

.slide-add-member .slide__actions msc-button i {
    font-size: 1.125rem;
    color: #b2b5cd;
    margin-right: 8px
}

.slide-add-member .form-search {
    width: 250px
}

.slide-add-member .form-search--square {
    border: 1px solid #d3d5e4
}

.slide-add-member .sort-header {
    color: #8c91b4;
    display: flex;
    padding: 20px
}

.slide-add-member .sort-header .sort-header__item {
    font-size: .875rem;
    padding-left: 20px;
    width: 50%
}

.slide-add-member .box-add-participant {
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    color: #d3d5e4;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 12px 18px
}

.slide-add-member .box-add-participant .checkbox {
    pointer-events: none
}

.slide-add-member .avatar-contact {
    width: 50%
}

.slide-add-participant .slide__title h3 {
    font-size: 1.25rem;
    font-weight: lighter;
    color: #020d38
}

.slide-add-participant .slide__actions {
    display: inline-block
}

.slide-add-participant .slide__actions msc-button {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    margin-top: 12px;
    color: #b2b5cd
}

.slide-add-participant .slide__actions msc-button i {
    font-size: 1.125rem;
    color: #b2b5cd;
    margin-right: 8px
}

.slide-add-participant .box-add-participant {
    padding: 12px 18px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    border: 1px solid #b2b5cd;
    color: #b2b5cd;
    cursor: pointer;
    transform: scale(1);
    box-shadow: none;
    transition: .2s cubic-bezier(.175, .885, .32, 2)
}

.slide-add-participant .box-add-participant.box--disabled {
    pointer-events: none
}

.slide-update-calendar .link-form-add {
    font-size: .875rem;
    color: #b2b5cd;
    margin-bottom: 4px;
    align-items: flex-start
}

.slide-update-calendar .slide__body {
    max-height: calc(100vh - 220px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 24px;
    margin-right: -24px
}

.slide-update-calendar .slide__input {
    margin: 15px 0
}

.slide-update-calendar .slide__input.flex>* {
    width: 100%
}

.slide-update-calendar .slide__input.flex .input__link {
    font-size: .75rem;
    color: #b2b5cd;
    transition: color .2s ease;
    text-decoration: underline;
    padding-bottom: 12px;
    display: inline-block;
    margin: 0 24px;
    width: 260px
}

.slide-update-calendar .slide__input.flex .input__link:hover {
    color: #7d83aa
}

.slide-update-calendar .slide__fieldset {
    border-radius: 4px;
    padding: 20px;
    background-color: hsla(0, 0%, 98.4%, .6)
}

.slide-update-calendar .select__label {
    display: block;
    color: #b2b5cd;
    margin-bottom: 4px;
    font-size: .875rem
}

.slide-update-calendar .select__date {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.slide-update-calendar .date {
    padding: 24px 24px 12px;
    text-align: center;
    margin: -4px 0;
    color: #b2b5cd;
    font-size: .75rem
}

.slide-update-calendar .date>* {
    margin: 4px 0
}

.slide-update-calendar .date__anchor {
    display: inline-block
}

.slide-update-calendar .date__icon {
    color: #414776;
    font-size: 1.125rem
}

.slide-update-calendar .date__hour {
    display: inline-block;
    border: 1px solid #b2b5cd;
    padding: 2px;
    font-size: .75rem;
    border-radius: 2px
}

.slide-update-calendar .form__toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.slide-update-calendar .form__toggle h3 {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #7d83aa;
    font-weight: lighter
}

.slide-update-calendar .form__toggle i {
    display: block;
    font-size: 1.375rem;
    color: #656c9b;
    margin-right: 12px;
    transform: rotate(0deg);
    transition: transform .2s cubic-bezier(.175, .885, .32, 2)
}

.slide-update-calendar .form__content {
    display: none;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #d3d5e4
}

.slide-update-calendar .form.active .form__toggle i {
    transform: rotate(180deg)
}

.slide-update-calendar .form.active .form__content {
    display: block
}

.slide-update-calendar .former-tag {
    margin: 12px -8px 0
}

.slide-update-calendar .former-tag>* {
    width: 200px;
    margin: 0 8px 8px;
    justify-content: space-between
}

.slide-update-calendar .input-upload-media__desc {
    color: #b2b5cd;
    font-size: .875rem
}

.slide-update-calendar .input-upload-media__label {
    color: #b2b5cd;
    font-size: .875rem
}

.slide-update-calendar .input-upload-media__desc {
    color: #7d83aa;
    margin-bottom: 4px
}

.slide-update-calendar .input-upload-media__container {
    position: relative;
    width: 100%;
    height: 190px;
    border-radius: 4px;
    border: 2px solid #d3d5e4;
    padding: 20px;
    background-color: #fff
}

.slide-update-calendar .input-upload-media__container:hover .input-upload-media__buttons {
    background-color: #5360bf;
    transform: scale(1.025);
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.slide-update-calendar .input-upload-media__image {
    width: 100%;
    height: 100%;
    opacity: .6;
    background-size: cover;
    background-position: 50%
}

.slide-update-calendar .input-upload-media__buttons {
    cursor: pointer;
    position: absolute;
    right: -16px;
    bottom: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    font-size: 1.625rem;
    border-radius: 50%;
    background-color: #6471d0;
    color: #fff;
    transform: scale(1);
    box-shadow: none;
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.slide-correction-activity .slide__body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 12px -12px
}

.slide-correction-activity .slide__body .slide__content {
    display: flex;
    margin-top: 2rem;
    font-size: .875rem
}

.slide-correction-activity .slide__body .statement {
    color: #d3d5e4;
    opacity: .5
}

.slide-reassign-manager .slide__title h2 {
    font-size: 1.25rem;
    border-bottom: 1px solid #d3d5e4;
    color: #656c9b;
    padding-bottom: 25px
}

.slide-reassign-manager .slide__body__scroll-content {
    height: calc(100vh - 500px)
}

.slide-reassign-manager .slide__info {
    border: 1px solid #656c9b;
    display: flex;
    padding: 20px
}

.slide-reassign-manager .slide__info .slide__info__item {
    width: 50%
}

.slide-reassign-manager .slide__info h3 {
    color: #656c9b;
    margin-bottom: 10px
}

.slide-reassign-manager .slide__actions {
    display: inline-block
}

.slide-reassign-manager .slide__actions msc-button {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    margin-top: 12px;
    color: #b2b5cd
}

.slide-reassign-manager .slide__actions msc-button i {
    font-size: 1.125rem;
    color: #b2b5cd;
    margin-right: 8px
}

.slide-reassign-manager .box-add-participant {
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    color: #d3d5e4;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 12px 18px
}

.slide-send-email .slide__mail-tab {
    margin-top: 2%;
    height: 50%
}

.slide-send-email .slide__mail-tab p {
    margin: 10px 0
}

.slide-send-email .slide__mail-tab .textarea {
    height: 60%
}

.slide-send-email .slide__mail-avatar {
    padding-top: 15px
}

.slide-team-export .slide {
    padding: 0
}

.slide-team-export .slide__title {
    font-size: 1.375rem
}

.slide-team-export .slide__form {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden
}

.slide-team-export .slide__access {
    padding: 16px 0
}

.slide-team-export .slide__access p {
    margin-bottom: 16px
}

.slide-team-export .slide__radios {
    display: inline-flex;
    flex-direction: column;
    padding-left: 20px
}

.slide-team-export .slide__filter {
    border: 1px solid #d3d5e4;
    border-radius: 6px;
    padding: 20px
}

.slide-team-export .slide__filter__title {
    border-bottom: 1px solid #d3d5e4;
    color: #535a8b;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 14px
}

.slide-team-export .slide__filter__title i {
    font-size: 1.5rem
}

.slide-team-export .slide__filter__item {
    color: #535a8b;
    padding-top: 20px
}

.slide-team-export .slide__filter__item .select {
    width: 100%;
    border: 1px solid #d3d5e4
}

.slide-team-export .slide__filter__item .select.active .select__dropdown {
    opacity: 1;
    visibility: visible
}

.slide-team-export .slide__filter__item .select .select__dropdown {
    opacity: 0;
    position: absolute;
    top: calc(100% + 8px);
    transition: all .3s ease;
    visibility: hidden;
    width: 100%;
    z-index: 10
}

.slide-team-export .slide__filter__label {
    font-size: 16px;
    padding-bottom: 15px
}

.slide-team-export .slide__filter__test--with-action-icon {
    display: flex;
    align-items: center;
    width: 100%
}

.slide-team-export .slide__filter__action-icon {
    color: #d3d5e4;
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: 10px;
    transition: all .3s ease
}

.slide-team-export .slide__filter__action-icon:hover {
    color: #b2b5cd
}

.slide-access-manage .slide__content {
    height: 95%;
    padding-bottom: 2rem
}

.slide-access-manage .scrollable {
    height: calc(100% - 3rem);
    overflow-y: scroll
}

.slide-access-manage-v2 .box-content {
    word-break: break-all;
    margin-top: 1rem;
    border: 1px solid #d3d5e4
}

.slide-access-manage-v2 .box-content__infos .icon {
    font-size: 1.375rem;
    color: #f44436;
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.slide-access-manage-v2 .box-content__infos p {
    position: relative;
    font-size: 1rem;
    color: #020d38
}

.slide-access-manage-v2 .box-content__infos p.notification:after {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: -8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #f44436
}

.slide-access-manage-v2 .box-content__infos p span {
    display: block;
    font-size: .875rem;
    color: #7d83aa
}

.slide-access-manage-v2 .box-content__participant {
    width: 4rem
}

@media screen and (min-width:32rem) {
    .slide-access-manage-v2 .box-content__participant {
        margin-right: calc(.5em*0.5)
    }
}

.slide-access-manage-v2 .box-content__participant .icon {
    font-size: 1.375rem;
    color: #b2b5cd;
    margin-right: calc(.5em*1)
}

.slide-access-manage-v2 .box-content__participant p {
    font-size: .875rem;
    color: #7d83aa
}

.slide-access-manage-v2 .box-content__line {
    margin-right: .3rem;
    border-left: 2px solid rgba(211, 213, 228, .5)
}

@media screen and (min-width:32rem) {
    .slide-access-manage-v2 .box-content__line {
        margin-right: calc(.5em*3)
    }
}

.slide-access-manage-v2 .box-content__plus {
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    transition: all .3s ease
}

.slide-access-manage-v2 .box-content__plus:hover {
    transform: scale(1.1);
    transition: all .3s ease
}

@media screen and (min-width:80rem) {
    .slide-access-manage-v2 .box-content__plus {
        margin-right: 1.5rem
    }
}

.slide-access-manage-v2 .box-content__plus--disabled {
    color: #8c91b4;
    background-color: #d3d5e4;
    cursor: not-allowed
}

.slide-access-manage-v2 .box-content__plus--disabled:hover {
    transform: none
}

.slide-access-manage .form-search {
    width: 13rem
}

.slide-add-address .slide__input {
    padding: 8px 0
}

.slide-add-address .slide__input label {
    display: block;
    margin-bottom: 8px;
    color: #000;
    font-size: .875rem
}

.upload-media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    padding: 0 10px 25px 0
}

.upload-media__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    min-height: 200px;
    height: auto;
    width: 100%;
    margin-top: 25px;
    margin-right: 25px;
    margin-left: 0;
    border-radius: 4px;
    border: 2px dotted rgba(151, 155, 183, .4)
}

.upload-media__container img {
    max-width: 200px;
    height: auto
}

.upload-media__image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-repeat: no-repeat
}

.upload-media__image--full {
    background-size: contain
}

.upload-media .favicon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.upload-media .favicon img {
    width: 16px;
    height: auto
}

.upload-media__delete {
    position: absolute;
    z-index: 10;
    top: -7px;
    right: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #ffa2a2;
    color: #fff;
    font-size: .875rem
}

.upload-media__button msc-button {
    border: 2px solid #4c77ff;
    color: #4c77ff
}

.upload-media__button msc-button i {
    color: #4c77ff
}

.upload-media__button p {
    margin-top: 20px;
    font-size: .875rem;
    color: #d3d5e4
}

.slide-add-link .slide__checkbox__anonymisation {
    margin: 1.5rem 0 2rem
}

.slide-add-link .slide__checkbox__anonymisation:after {
    display: inline-block;
    content: "";
    border-top: 1px solid #d3d5e4;
    width: 30%;
    transform: translateY(.5rem)
}

.slide-add-link .slide__checkbox__anonymisation__text {
    margin-top: .5rem;
    font-style: italic;
    font-size: .875rem;
    color: #b2b5cd
}

.slide-add-member-to-camp {
    overflow-y: scroll;
    height: calc(100% - 1rem) !important
}

.slide-add-member-to-camp .slide__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start
}

.slide-add-member-to-camp .slide__body {
    margin-top: 4rem
}

.slide-add-member-to-camp .slide__body .slide__choices__email {
    margin-top: 2rem
}

.slide-add-member-to-camp .slide__body .slide__choices__email:before {
    display: inline-block;
    content: "";
    border-top: 1px solid #d3d5e4;
    width: 30%;
    transform: translateY(-.5rem)
}

.slide-add-member-to-camp .checkbox,
.slide-add-member-to-camp .input {
    margin-top: 1rem
}

.slide-add-member-to-camp .slide__close {
    position: static;
    top: 0;
    left: 0
}

.slide-add-member-to-camp .form-search .input,
.slide-add-member-to-camp .select__user .checkbox {
    margin-top: 0
}

.slide-add-member-to-camp .select__user li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem
}

.slide-add-member-to-camp .select__user li:first-child {
    margin: 2rem 0
}

.slide-add-member-to-camp .select__user .invitation__name {
    display: flex;
    align-items: center
}

.slide-add-member-to-camp .select__user .invitation__name img {
    margin-right: 1rem
}

.slide-add-member-to-camp .dropdown__camp {
    top: 0
}

.slide-add-member-to-camp .dynamic-table {
    margin-top: 2rem;
    margin-left: -1rem;
    margin-right: -1rem
}

.slide-add-member-to-camp .dynamic-table__cell {
    display: flex;
    justify-content: center
}

.slide-add-member-to-camp .slide__footer {
    background-color: #e9eaf1;
    padding: 16px 50px
}

.slide-add-member-to-camp .slide__footer--justified {
    justify-content: flex-end
}

.slide-add-member-to-camp .slide__footer .checkbox {
    margin-top: 0
}

.slide-add-playlist .slide__body--scroll {
    max-height: 600px
}

.slide-add-playlist .slide__form,
.slide-add-playlist .slide__form.active {
    display: block
}

.slide-add-playlist .slide__form .slide__input--cat label,
.slide-add-playlist .slide__form .slide__input--cat p {
    margin: 0 0 1rem
}

.slide-add-playlist .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -20px -24px;
    border-bottom: none
}

.slide-add-playlist .tab>li {
    margin: 0 24px
}

.slide-add-playlist .tab a {
    font-size: .875rem;
    color: #7d83aa;
    transition: .2s ease
}

.slide-add-playlist .select-tab {
    display: flex;
    flex-direction: column;
    margin: 1rem 0
}

.slide-add-playlist .list-camp {
    max-height: calc(100vh - 450px)
}

.slide-add-playlist .list-camp__list {
    margin-top: 14px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.slide-add-playlist .list-camp__list:last-child {
    margin-bottom: 0;
    border-bottom: none
}

.slide-add-playlist .list-camp__list .card-camp {
    margin: 0 0 .5rem
}

.slide-add-playlist .slide__search {
    margin: 0 0 1.5rem
}

.slide-add-playlist .slide__video {
    margin: 1rem 0 0
}

.slide-add-playlist .slide__video .video-title {
    font-size: 1.125rem;
    text-align: center
}

.slide-add-playlist .slide__video .video-item {
    font-size: .875rem;
    margin: .5rem 0;
    color: #d3d5e4
}

.slide-camps-sessions-params__toggle {
    margin-bottom: 2rem;
    background: rgba(211, 213, 228, .5);
    padding: 1rem .8rem;
    border-radius: 4px
}

.slide-camps-sessions-params__toggle__header {
    display: flex;
    justify-content: space-between
}

.slide-camps-sessions-params__toggle__header .icon-arrow-ios-down {
    transform: rotate(0deg);
    transition: transform .3s ease
}

.slide-camps-sessions-params__toggle__content {
    opacity: 0;
    visibility: hidden;
    display: none;
    margin-top: 1.3rem
}

.slide-camps-sessions-params__toggle__content:first-child:before {
    content: "";
    border-top: 1px solid #d3d5e4;
    width: 93%;
    margin: 1rem;
    position: absolute;
    bottom: 23px
}

.slide-camps-sessions-params__toggle__content .slide__switch {
    display: flex
}

.slide-camps-sessions-params__toggle__content__text {
    padding: 0 1rem
}

.slide-camps-sessions-params__toggle__content__text p:first-child {
    font-size: .875rem
}

.slide-camps-sessions-params__toggle__content__text p:last-child {
    font-size: .75rem
}

.slide-camps-sessions-params__toggle__block {
    display: flex;
    align-items: center
}

.slide-camps-sessions-params__toggle__block i {
    font-size: 1.5rem
}

.slide-camps-sessions-params__toggle.active .slide-camps-sessions-params__toggle__header .icon-arrow-ios-down {
    transform: rotate(-180deg)
}

.slide-camps-sessions-params__toggle.active .slide-camps-sessions-params__toggle__content {
    display: flex;
    opacity: 1;
    visibility: visible;
    position: relative
}

.slide-create-skill .slide {
    padding: 0
}

.slide-create-skill .slide__form {
    height: calc(100vh - 250px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 30px;
    margin-right: -30px
}

.slide-create-skill .slide__title {
    font-size: 1.375rem
}

.slide-create-skill .slide__footer {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.slide-create-skill .slide__input {
    padding: 8px 0
}

.slide-create-skill .slide__switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #b2b5cd
}

.slide-create-skill .slide__switch i {
    font-size: 1.125rem;
    margin-right: 8px
}

.slide-create-skill .slide__switch p {
    color: #020d38;
    font-size: 1.125rem
}

.slide-create-skill .slide__action {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%)
}

.slide-create-skill .slide__access {
    padding: 16px 0
}

.slide-create-skill .slide__access p {
    margin-bottom: 16px
}

.slide-create-skill .slide__radios {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px 1rem
}

.slide-create-skill .slide__radios>* {
    margin: 0 8px
}

.slide-create-skill .slide__skill {
    padding: 16px 0;
    border-top: 1px solid #b2b5cd
}

.slide-create-skill .slide__skill-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px
}

.slide-create-skill .slide__skill-list .skill {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 24px;
    cursor: pointer;
    transition: all .3s ease
}

.slide-create-skill .slide__skill-list .skill.active {
    background-color: #d3d5e4
}

.slide-create-skill .slide__skill-list .skill:hover {
    background-color: #d3d5e4
}

.slide-export-recipient-email__text-zone {
    padding: 1rem;
    border: 2px solid #d3d5e4;
    border-radius: 4px
}

.slide-export-recipient-email__text-zone__header {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.slide-export-recipient-email__text-zone__header__element {
    display: flex;
    align-items: center;
    margin: .5rem;
    padding: .5rem;
    color: #8c91b4;
    border-radius: 4px;
    background: #f8f9ff
}

.slide-export-recipient-email__text-zone__header__element.exists {
    color: #fff
}

.slide-export-recipient-email__text-zone__header__element.invalid {
    color: #fff
}

.slide-export-recipient-email__text-zone__header__element.exists i {
    color: #fff !important
}

.slide-export-recipient-email__text-zone__header__element.invalid i {
    color: #fff !important
}

.slide-export-recipient-email__text-zone__header__element.exists {
    background: #ffc688
}

.slide-export-recipient-email__text-zone__header__element.invalid {
    background: #ffa2a2
}

.slide-export-recipient-email__text-zone__header__element .close {
    cursor: pointer;
    margin-left: .5rem;
    color: #b2b5cd;
    transition: all .3s ease-in-out
}

.slide-export-recipient-email__text-zone__header__element .close:hover {
    color: #8c91b4;
    transform: rotate(90deg)
}

.slide-export-recipient-email__text-zone__body textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 10rem;
    margin-top: 1rem;
    border: none;
    resize: none
}

.slide-export-recipient-email__infos {
    margin-top: 1rem;
    color: #8c91b4
}

.slide-export-recipient-email__infos p {
    margin: 1rem 0
}

.slide-experience-theme .slide__title {
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    padding: 0 0 1rem
}

.slide-experience-theme .slide__title h3 {
    display: flex;
    align-items: center
}

.slide-experience-theme .slide__theme__color {
    margin: 1rem 0 0
}

.slide-experience-theme .slide__theme__color span {
    color: #b2b5cd
}

.slide-experience-theme .slide__theme__color .watcher {
    margin: .5rem 0 0;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 5px 10px;
    width: 150px
}

.slide-experience-theme .slide__theme__color .watcher-color {
    width: 20px;
    height: 20px;
    border-radius: 100%
}

.slide-experience-theme .slide__theme__color .watcher-hex {
    margin: 0 0 0 1rem;
    color: #8c91b4
}

.slide-experience-theme .slide__theme__color .watcher-hex input {
    border: none;
    max-width: 80px;
    color: #8c91b4
}

.slide-experience-theme .slide__theme__color .watcher-preview {
    margin: 1.5rem 0 0
}

.slide-experience-theme .slide__theme__color .watcher-preview img,
.slide-experience-theme .slide__theme__color .watcher-preview svg {
    width: 100%;
    height: auto
}

.slide-filter-by-provider .slide__search {
    width: calc(50% - calc(.5em*2))
}

.slide-filter-by-provider .slide__cards {
    margin-top: calc(.5em*3) calc(calc(.5em*2)*-1) 0 calc(calc(.5em*2)*-1)
}

.slide-filter-by-provider .slide__cards li {
    width: calc(50% - calc(.5em*2));
    margin: calc(.5em*1)
}

.slide-forms__checkbox,
.slide-forms__text,
.slide-forms__textarea {
    margin: 0 0 1rem
}

.slide-history-export .slide__title {
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    padding: 0 0 1rem
}

.slide-history-export .slide__title h3 {
    display: flex;
    align-items: center
}

.slide-history-export .slide__content .slide__data__item .data-item__label {
    margin: 1rem 0 0 1rem
}

.slide-history-export .slide__content .slide__data__item .data-item__blocks {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0
}

.slide-history-export .slide__content .slide__data__item .data-item__blocks .block-figure {
    display: flex;
    width: 49%;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    transition: all .3s ease;
    padding: 20px
}

.slide-history-export .slide__content .slide__data__item .data-item__blocks .block-figure.active {
    border-color: #5150c4;
    transition: all .3s ease
}

.slide-history-export .slide__content .slide__data__item .data-item__blocks .block-figure:hover {
    border-color: #5150c4;
    transition: all .3s ease
}

.slide-history-export .slide__content .slide__data__item .data-item__blocks .block-figure__body h1 {
    font-weight: 500;
    color: #7d83aa
}

.slide-history-export .slide__content .slide__data__item .data-item__blocks .block-figure__body span {
    font-size: .75rem;
    color: #b2b5cd
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle {
    margin: 1rem 0 0;
    padding: 20px;
    border: 1px solid #d3d5e4;
    border-radius: 4px
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle.active .toggle-head {
    border-bottom: 1px solid #d3d5e4;
    padding: 0 0 10px
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle.active .toggle-head i {
    transform: rotate(-180deg)
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle.active .toggle-body {
    display: block
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-head:hover i {
    color: #656c9b;
    transition: all .3s ease
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-head h1 {
    display: flex;
    align-items: center;
    color: #656c9b;
    font-weight: 500
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-head h1 .head-tag {
    margin: 0 0 0 .5rem;
    padding: 1px 10px;
    border-radius: 12px;
    background-color: #e57373;
    color: #fff;
    font-size: .75rem
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-head i {
    color: #8c91b4;
    font-size: 1.5rem;
    transition: all .3s ease
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body {
    margin: 1rem 0;
    display: none
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item {
    margin: 1rem 0 0
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item:first-child {
    margin: 0
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-label {
    color: #8c91b4
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select {
    margin: .75rem 0 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select__actions {
    cursor: pointer
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select__actions:hover i {
    color: #7d83aa;
    transition: all .3s ease
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select__actions i {
    font-size: 1.5rem;
    color: #b2b5cd;
    transition: all .3s ease
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select .select {
    position: relative;
    cursor: pointer;
    border: 0;
    padding: 0;
    height: auto
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select .select:after,
.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select .select:before {
    display: none
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select .select__placeholder {
    color: #8c91b4;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select .select__placeholder .icon:before {
    transform: rotate(0deg);
    transition: transform .3s ease
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select .select__dropdown {
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    position: absolute;
    top: calc(100% + 8px);
    left: -100px;
    z-index: 10
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select .select__dropdown .dropdown--simple {
    left: 100px
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select .select.active .icon:before {
    transform: rotate(-180deg)
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-select .select.active .select__dropdown {
    opacity: 1;
    visibility: visible
}

.slide-history-export .slide__content .slide__data__item .data-item__toggle .toggle-body__item .item-radio {
    margin: 1rem 0 0 1rem
}

.slide-invite-user .slide__content {
    padding: 0 1rem;
    overflow: auto
}

.slide-invite-user .slide__content span {
    margin-bottom: 16px
}

.slide-invite-user .slide__footer {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.slide-invite-user .slide__input {
    padding: 8px 0
}

.slide-invite-user .slide__input label {
    display: block;
    margin-bottom: 8px;
    font-size: .875rem
}

.slide-invite-user .slide__input .avatar--min {
    margin-right: 10px
}

.slide-invite-user .slide__group-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -16px
}

.slide-invite-user .slide__group-form .slide__input {
    margin: 0 16px;
    width: 100%
}

.slide-invite-user .slide__subtitle {
    color: #656c9b;
    margin: 1rem 0;
    padding: 0 0 1rem;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s ease
}

.slide-invite-user .slide__subtitle:hover {
    color: #414776;
    transition: all .3s ease
}

.slide-invite-user .slide__subtitle i {
    font-size: 1.375rem;
    transition: all .3s ease
}

.slide-invite-user .slide__subtitle.active i {
    transform: rotate(-180deg);
    transition: all .3s ease
}

.slide-invite-user .slide__subtitle.active+.slide__group-list {
    display: block
}

.slide-invite-user .slide__group-list {
    display: none
}

.slide-manage-categories.slide .slide__subtitle {
    font-size: .875rem;
    margin: 32px 0 8px
}

.slide-manage-categories.slide .slide__content {
    overflow: auto;
    padding-bottom: 10rem
}

.slide-manage-categories.slide .slide__input {
    margin: 16px 0;
    width: 50%
}

.slide-manage-categories.slide .slide__desc {
    font-size: .875rem;
    color: #8c91b4
}

.slide-manage-categories.slide .slide__fieldset {
    padding-bottom: calc(.5em*3);
    border-bottom: 1px solid #d3d5e4
}

.slide-manage-categories.slide .slide__search {
    width: calc(50% - calc(.5em*2))
}

.slide-manage-categories.slide .skills {
    margin-top: 1rem
}

.slide-manage-categories.slide .skills__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap
}

.slide-manage-categories.slide .skills__item {
    margin: 4px
}

.slide-manage-categories.slide .skills__item .skill {
    display: flex;
    min-height: 34px;
    justify-content: center;
    align-items: center;
    transition: all .3s ease
}

.slide-manage-categories.slide .skills__item .skill--pointer {
    cursor: pointer
}

.slide-manage-categories.slide .skills__item .skill.active {
    background-color: #d3d5e4
}

.slide-manage-categories.slide .skills__item .skill:hover {
    background-color: #d3d5e4
}

.slide-manage-categories.slide .skills__item .skill .skill__icon {
    cursor: unset
}

.slide-manage-categories.slide .skills__item .skill .skill__icon .icon-checkmark {
    color: #49e2af
}

.slide-manage-categories.slide .skills__item .skill .skill__icon .icon-slash-1 {
    color: #ffa2a2
}

.slide-manage-categories.slide .skills__item .skill .skill__close {
    cursor: pointer
}

.slide-manage-categories.slide .skills__item .skill .skill__close>i {
    font-size: 1.25rem
}

.slide-merge-skill.slide .slide__subtitle {
    margin: 32px 0 8px
}

.slide-merge-skill.slide .slide__input {
    margin: 16px 0;
    width: 50%
}

.slide-merge-skill.slide .skills {
    margin: 1rem -8px 0
}

.slide-merge-skill.slide .skills__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap
}

.slide-merge-skill.slide .skills__item {
    margin: 4px
}

.slide-merge-skill.slide .skills__item .skill {
    display: flex;
    min-height: 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease
}

.slide-merge-skill.slide .skills__item .skill.active {
    background-color: #d3d5e4
}

.slide-merge-skill.slide .skills__item .skill:hover {
    background-color: #d3d5e4
}

.slide-organize-add-mentor .slide__search .form-search {
    max-width: 100%
}

@media screen and (min-width:32rem) {
    .slide-organize-add-mentor .slide__search .form-search {
        max-width: 250px
    }
}

.slide-organize-add-mentor .form {
    margin: 0 0 calc(calc(.5em*2.5)*-1) 0
}

.slide-organize-add-mentor .form__field {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: calc(.5em*2.5) 0 calc(.5em*2.5) 0
}

.slide-organize-add-mentor .form__label {
    display: block;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d3d5e4;
    border-radius: calc(.5em*0.5);
    padding: calc(.5em*2.5);
    width: 100%
}

@media screen and (min-width:32rem) {
    .slide-organize-add-mentor .form__label {
        display: flex
    }
}

.slide-organize-add-mentor .form__info {
    margin: calc(.5em*3) 0 0 0;
    padding-top: calc(.5em*3);
    padding-left: 0;
    padding-bottom: calc(calc(.5em*3)*-1);
    border-top: 1px solid #d3d5e4
}

@media screen and (min-width:32rem) {
    .slide-organize-add-mentor .form__info {
        margin: 0 0 0 calc(.5em*3);
        padding-left: calc(.5em*3);
        padding-top: 0;
        border-top: none;
        border-left: 1px solid #d3d5e4
    }
}

.slide-organize-add-mentor .form__info p {
    font-size: .875rem;
    color: #b2b5cd;
    margin-bottom: calc(.5em*0.5)
}

.slide-organize-add-mentor .form__info ul {
    margin-bottom: calc(.5em*2)
}

.slide-organize-add-mentor .form__skill {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap
}

.input-image__content--loaded {
    width: 240px;
    height: 180px
}

.input-image__content--loaded a,
.input-image__content--loaded p {
    display: none
}

.input-image__content--loaded .close {
    display: block !important
}

.slide-payment .slide__input {
    padding: 8px 0
}

.slide-payment .slide__input label {
    display: block;
    margin-bottom: 8px;
    color: #000;
    font-size: .875rem
}

.slide-payment .slide__input #stripe-element {
    margin: 0;
    width: 100%;
    height: 100%;
    color: #535a8b;
    border-radius: 4px;
    border: 2px solid #d3d5e4;
    padding: 12px;
    font-size: .875rem;
    background: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all .35s ease
}

.slide-payment .slide__input #stripe-element:focus {
    border: 2px solid #5150c4
}

.slide-prohibit-skill .slide__desc {
    font-size: .875rem;
    color: #8c91b4
}

.slide-prohibit-skill .slide__subtitle {
    margin: 32px 0 8px
}

.slide-prohibit-skill .slide__checkbox,
.slide-prohibit-skill .slide__form {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.slide-prohibit-skill .slide__form {
    margin: 16px -8px
}

.slide-prohibit-skill .slide__checkbox {
    margin: 0 8px
}

.slide-prohibit-skill .slide__input {
    margin: 1rem 0;
    width: 50%
}

.slide-prohibit-skill .slide__message {
    position: fixed;
    bottom: 70px;
    color: rgba(211, 213, 228, .9)
}

.slide-prohibit-skill .slide__message .color--orange {
    font-weight: 600
}

.slide-prohibit-skill .skills {
    display: flex;
    align-items: center;
    margin: 0 -8px
}

.slide-prohibit-skill .skills .skill--alternate {
    background-color: rgba(211, 213, 228, .5)
}

.slide-prohibit-skill .skills .skill--forbidden {
    background-color: rgba(211, 213, 228, .5)
}

.slide-prohibit-skill .skills--search {
    flex-direction: column
}

.slide-prohibit-skill .skills__arrow {
    display: flex;
    align-items: center;
    width: 100px;
    margin: 0 8px
}

.slide-prohibit-skill .skills__arrow:before {
    content: "";
    width: 90px;
    height: 1px;
    margin: 0 -8px 0 0;
    background-color: #d3d5e4
}

.slide-prohibit-skill .skills__arrow .icon {
    color: #d3d5e4
}

.slide-prohibit-skill .skills__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

.slide-prohibit-skill .skills__list .skill {
    display: flex;
    align-items: center;
    margin: 6px;
    min-height: 24px;
    transition: all .3s ease
}

.slide-prohibit-skill .skills__list .skill.active {
    background-color: #d3d5e4
}

.slide-prohibit-skill .skills__list .skill:hover {
    background-color: #d3d5e4
}

.slide-users-import .slide__form {
    margin-top: 32px
}

.slide-users-import .slide__input {
    margin: 8px 0
}

.box-access {
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    padding: 16px 8px 0;
    margin-bottom: calc(.5em*2.5)
}

@media screen and (min-width:32rem) {
    .box-access {
        margin-bottom: 0
    }
}

.box-access__title {
    font-size: 1rem
}

.box-access__list {
    padding: 25px 10px 15px
}

.box-access__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    color: #d3d5e4;
    transition: all .3s ease
}

.box-access__item.active {
    color: #feb88a;
    transition: all .3s ease
}

.box-access__item:hover {
    color: #feb88a;
    transition: all .3s ease
}

.box-access__item.active {
    background-color: rgba(211, 213, 228, .4)
}

.block-company-progress {
    padding: 16px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-company-progress__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    font-size: .875rem;
    font-weight: 300
}

.block-company-progress__header h5 {
    font-size: .875rem !important;
    color: #020d38 !important;
    margin: 0 !important
}

.block-company-progress__price {
    color: #ff6b70 !important
}

.block-company-progress__bar {
    margin: 4px 0
}

.block-company-progress__stats {
    font-size: .75rem;
    font-weight: 300;
    color: #020d38
}

.block-company-progress__stats span {
    color: #ff6b70
}

.block-company-settings {
    position: relative;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-company-settings__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -16px
}

.block-company-settings__content>* {
    width: 30%;
    margin: 16px
}

@media screen and (max-width:32rem) {
    .block-company-settings__content {
        flex-wrap: nowrap;
        flex-direction: column
    }

    .block-company-settings__content>* {
        width: 100% !important;
        margin: 8px 0
    }
}

.block-company-settings__header h1 {
    font-size: 1.375rem;
    color: #414776;
    /*
    white-space: nowrap;*/
    margin-bottom: 24px
}

.block-company-settings__advanced-modal .tip {
    margin-top: calc(.5em*3)
}

.block-company-settings__advanced-setting {
    width: 100%;
    color: #414776
}

.block-company-settings__advanced-setting h3 {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 16px
}

.block-company-settings__advanced-setting p {
    font-size: 1rem;
    margin-bottom: 16px
}

.block-company-settings__advanced-setting__status {
    display: flex;
    align-content: center;
    margin: calc(.5em*1.5) 0
}

.block-company-settings__advanced-setting__status i {
    margin-right: calc(.5em*1);
    color: #49e2af
}

.block-company-settings__data--item p {
    font-size: .875rem;
    color: #7d83aa
}

.block-company-settings__data--item.item-half {
    width: 50%
}

.block-company-settings__data--item.data-item__country .dropdown--flag {
    top: 50px;
    right: -30px
}

.block-company-settings .logo__editable {
    position: relative;
    width: auto;
    height: auto;
    margin-bottom: 24px;
    text-align: center
}

.block-company-settings .logo__editable img {
    max-width: 170px;
    height: auto
}

.block-company-settings .logo__editable msc-button {
    position: absolute;
    top: -15px;
    right: -25px
}

@media screen and (max-width:32rem) {
    .block-company-settings .logo__editable msc-button {
        right: 5px
    }
}

.block-company-settings .logo__upload .link__upload {
    margin-bottom: 10px;
    cursor: pointer;
    display: block
}

.block-company-settings .logo__upload p {
    font-size: .75rem;
    color: #b2b5cd
}

.block-company-subscription {
    background: #fff;
    border-radius: 4px
}

.block-company-subscription__header {
    background-color: rgba(211, 213, 228, .5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 3px 3px -1px #0a16461a;
}

.block-company-subscription__content {
    background: #fff
}

.block-company-subscription__bloc {
    padding: 32px 0;
    margin: 0 32px 32px;
    border-bottom: 1px solid #b2b5cd
}

.block-company-subscription__bloc.no-border {
    border: none
}

.block-company-subscription__bloc h5 {
    margin-bottom: 15px;
    color: #656c9b;
    font-size: 1rem
}

.block-company-subscription__bloc p {
    font-size: .875rem;
    color: #8c91b4
}

.block-company-subscription__price {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.block-company-subscription__price p:last-child {
    color: #ffa2a2
}

.block-company-subscription__footer {
    padding: 32px;
    text-align: right
}

.block-subscription-cards {
    position: relative;
    display: inline-block;
    padding: 50px;
    text-align: center;
    cursor: pointer;
    transition: all .3s ease;
    width: 250px;
    height: 250px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 3px -1px #0a16461a;
}

.block-subscription-cards:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 4px;
    transform: scale(1);
    transition: all .3s ease
}

.block-subscription-cards.active {
    color: #fff
}

.block-subscription-cards.active h4 {
    color: #fff
}

.block-subscription-cards:hover {
    color: #fff
}

.block-subscription-cards:hover h4 {
    /*color: #fff*/
}

.block-subscription-cards.active .block-subscription-cards__details {
    color: #ff6b70
}

.block-subscription-cards:hover .block-subscription-cards__details {
    color: #ff6b70
}

.block-subscription-cards.active .block-subscription-cards__checkbox,
.block-subscription-cards:hover .block-subscription-cards__checkbox {
    display: block
}

.block-subscription-cards__content {
    position: relative;
    z-index: 1
}

.block-subscription-cards h4 {
    font-size: 1.25rem
}

.block-subscription-cards__price {
    margin: 12px 0;
    color: #ff6b70;
    font-size: 2.5rem
}

.block-subscription-cards__details {
    font-size: 1rem;
    color: #7d83aa
}

.block-subscription-cards__recurrence {
    font-size: .875rem;
    color: #7d83aa;
    margin-top: 8px
}

.block-subscription-infos {
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    padding: 32px;
    margin-bottom: 40p;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block-subscription-infos__icon {
    padding: 0 10px
}

.block-subscription-infos__icon i {
    color: #fff
}

.block-subscription-infos__text {
    background-color: #fff;
    width: 100%;
    padding: 35px 0
}

.block-subscription-infos__title {
    color: #ffa2a2;
    font-size: 1rem;
    margin-left: 24px;
    padding-bottom: 16px
}

.block-subscription-infos__data {
    color: #414776;
    font-size: 1rem;
    margin-left: 24px
}

.block-subscription-infos__action {
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.block-add-content {
    position: relative;
    cursor: pointer;
    justify-content: center;
    width: 100%;
    min-height: 315px;
    height: auto;
    margin: 15px 0;
    background-color: #d3d5e4
}

.block-add-content,
.block-add-content__content {
    display: flex;
    align-items: center;
    border-radius: 4px
}

.block-add-content__content {
    justify-content: flex-start;
    padding: 25px 40px;
    background-color: #fff;
    transform: scale(1);
    box-shadow: none;
    transition: .4s cubic-bezier(.175, .885, .32, 2)
}

.block-add-content__content i {
    color: #ffa2a2;
    font-size: 1.25rem;
    margin-right: 8px
}

.block-add-content__content span {
    color: #535a8b;
    font-size: 1rem
}

.block-add-content__content:hover {
    transform: scale(1.1);
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1)
}

.blocks-notification {
    background: #f8f9ff;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    border-radius: 4px;
    padding: 16px
}

.blocks-notification__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #535a8b
}

.blocks-notification__header i {
    color: #5150c4
}

.blocks-notification__header p {
    color: #5150c4
}

.blocks-notification__header i {
    transform: rotate(0);
    transition: .2s cubic-bezier(.175, .885, .32, 2);
    font-size: 1.25rem
}

.blocks-notification__content {
    display: none;
    padding: 16px 0 16px 24px
}

.blocks-notification__list {
    margin: -16px 0
}

.blocks-notification__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    background: #f8f9ff;
    padding: 16px;
    margin: 16px 0
}

.blocks-notification__item p {
    color: #535a8b
}

.blocks-notification.active {
    border: 2px solid #d3d5e4;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    background: #fff
}

.blocks-notification.active .blocks-notification__header i {
    transform: rotate(180deg)
}

.blocks-notification.active .blocks-notification__content {
    display: block
}

.block-profile {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (max-width:32rem) {
    .block-profile__header .block-profile__infos {
        flex-direction: column
    }
}

@media screen and (max-width:32rem) {
    .block-profile__header .last-login {
        display: none
    }
}

.block-profile__name {
    /*margin: 0 0 0 1rem*/
}

.block-profile__action msc-button {
    height: 28px;
    padding: 0 10px
}

.block-profile__data {
    margin-bottom: 50px
}

.block-profile__avatar {
    position: relative
}

.block-profile__bio__content {
    font-size: .875rem;
    font-weight: 300;
    color: #7d83aa;
    padding: 5px 0;
    margin-top: 10px
}

.block-profile {
    position: relative;
    background: #fff;
    border-radius: 4px;
    padding: 50px 40px
}

.block-profile__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid #d3d5e4
}

.block-profile__header--editing {
    padding-bottom: 0
}

.block-profile__header .block-profile__infos {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.block-profile__header .block-profile__infos.edit {
    width: 100%
}

.block-profile__header .block-profile__infos.edit .block-profile__avatar {
    min-width: 25%
}

.block-profile__header .block-profile__infos .block-profile__dataset {
    width: 100%
}

.block-profile__header .last-login {
    font-size: .875rem;
    font-weight: 300;
    color: #7d83aa
}

.block-profile__action {
    position: absolute;
    top: 15px;
    right: 40px;
    cursor: pointer
}

.block-profile__avatar,
.block-profile__avatar .block-profile__add-avatar {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem
}

.block-profile__avatar .block-profile__add-avatar {
    /*margin-left: 30px;*/
    cursor: pointer
}

.block-profile__avatar .block-profile__add-avatar i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-right: 8px;
    border-radius: 50%;
    font-size: 1.25rem
}

.block-profile__avatar .block-profile__add-avatar p {
    margin-top: 0;
    padding-top: 0;
    font-size: 1.125rem
}

.block-profile__avatar .block-profile__add-avatar p span {
    display: block;
    font-size: .625rem;
    color: #b2b5cd
}

.block-profile__avatar .block-profile__add-avatar p span:first-child {
    margin-top: 5px
}

.block-profile__dataset {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px
}

.block-profile__data {
    min-width: 25%;
    margin: 0 16px 50px;
    flex: 1
}

.block-profile__data .input,
.block-profile__data .select {
    margin: 8px 0 0
}

.block-profile__data--block {
    width: 100%
}

.block-profile__bio textarea,
.block-profile__data--block msc-button {
    margin: 8px 0 0
}

.block-profile__infos p {
    font-size: .875rem;
    font-weight: 300;
    color: #7d83aa;
    padding: 5px 0;
    margin-top: 10px
}

.block-profile__roles {
    margin: 0 -4px;
    padding-top: 5px
}

.block-profile__cta {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0
}

.block-profile__cta msc-button {
    margin: 0 1rem
}

.block-settings {
    position: relative;
    background: #fff;
    padding: 50px 40px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-settings__select {
    margin: 16px 0
}

.block-settings__select--no-margin {
    margin: 0
}

.block-settings__select label {
    display: block;
    font-size: .875rem;
    color: #7d83aa;
    margin-bottom: 8px
}

.block-settings__select .form-select .select select {
    height: auto !important
}

.block-settings__link {
    margin-top: 24px
}

.blocks-switch-access {
    background: #fff;
    border-radius: 4px;
    padding: 16px
}

.blocks-switch-access__input {
    padding: 8px 0
}

.blocks-switch-access__switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0 8px;
    margin-bottom: 8px
}

.blocks-switch-access__switch i {
    color: #ed8371;
    font-size: 1.125rem;
    margin-right: 8px
}

.blocks-switch-access__switch p {
    color: #7d83aa;
    font-size: 1.125rem
}

.blocks-switch-access__action {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%)
}

.blocks-switch-access__access {
    padding: 16px 0
}

.blocks-switch-access__access p {
    position: relative;
    color: #7d83aa;
    margin-bottom: 16px
}

.blocks-switch-access__access p i {
    color: #ed8371;
    font-size: 1.125rem;
    margin-right: 8px
}

.blocks-switch-access__access p a {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%)
}

.blocks-switch-access__access p a i {
    color: #7d83aa
}

.blocks-switch-access__access p:last-child {
    margin-bottom: 0
}

.blocks-switch-access__radios {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.blocks-switch-access__radios>* {
    margin: 0 8px
}

.block-add-application h2 {
    margin-bottom: 20px;
    clear: both
}

.block-add-application .search-bar__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #d1d6ff;
    background: #fff;
    width: 260px;
    height: 31px;
    padding: 2px;
    border: 2px solid #d3d5e4;
    border-radius: 8px;
    line-height: 1em;
    margin-bottom: 30px
}

.block-add-application .search-bar__form .ico-search {
    margin: 0 10px
}

.block-add-application .search-bar__form .input-search {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 0 solid transparent;
    border-right: 1px solid #d3d5e4
}

.block-add-link {
    cursor: pointer;
    background-color: #fff;
    transition: all .3s ease;
    margin: 16px 8px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:64rem) {
    .block-add-link {
        margin: 0
    }
}

.block-add-link:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    transform: scale(1.055);
    transition: all .3s ease
}

.block-add-link .account__card .account__content {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 170px
}

@media (-ms-high-contrast:none) {

    .block-add-link .account__card .account__content,
    .block-add-link .account__card ::-ms-backdrop {
        display: table-cell;
        vertical-align: middle
    }
}

@media (-ms-high-contrast:none) {

    .block-add-link .account__card .account__content div,
    .block-add-link .account__card ::-ms-backdrop {
        margin-left: 0;
        margin-right: 0;
        width: 195px
    }
}

@media (-ms-high-contrast:none) {

    .block-add-link .account__card .account__content p,
    .block-add-link .account__card ::-ms-backdrop {
        text-align: center
    }
}

.block-add-link .account__card--add {
    color: #7d83aa
}

.block-add-link .account__card--add i {
    display: block;
    color: #5150c4;
    margin-bottom: 8px;
    text-align: center
}

@media screen and (min-width:48rem) {
    .block-add-link .account__card--add i {
        font-size: 1.25rem
    }
}

@media screen and (min-width:64rem) {
    .block-add-link .account__card--add i {
        font-size: 1.5rem
    }
}

@media screen and (min-width:80rem) {
    .block-add-link .account__card--add i {
        font-size: 1.75rem
    }
}

@media screen and (min-width:90rem) {
    .block-add-link .account__card--add i {
        font-size: 1.875rem
    }
}

@media screen and (min-width:64rem) {
    .block-add-link .account__card--add p {
        font-size: .875rem
    }
}

@media screen and (min-width:80rem) {
    .block-add-link .account__card--add p {
        font-size: 1rem
    }
}

.block-add-playlist {
    cursor: pointer;
    background-color: #fff;
    transition: all .3s ease;
    margin: 16px 8px;
    min-height: 170px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:64rem) {
    .block-add-playlist {
        min-height: 100%;
        margin: 0
    }
}

.block-add-playlist:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    transform: scale(1.055);
    transition: all .3s ease
}

.block-add-playlist__content {
    text-align: center
}

.block-add-playlist__content i {
    font-size: 1.625rem;
    color: #5150c4
}

.block-add-playlist__content p {
    font-size: 1rem;
    color: #7d83aa
}

.block-add-playlist .account__card .account__content {
    border-radius: 4px;
    min-height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative
}

.block-add-playlist .account__card .account__content>* {
    text-align: center
}

@media (-ms-high-contrast:none) {

    .block-add-playlist .account__card .account__content,
    .block-add-playlist .account__card ::-ms-backdrop {
        display: table-cell;
        vertical-align: middle
    }
}

@media (-ms-high-contrast:none) {

    .block-add-playlist .account__card .account__content div,
    .block-add-playlist .account__card ::-ms-backdrop {
        margin-left: 0;
        margin-right: 0;
        width: 195px
    }
}

@media (-ms-high-contrast:none) {

    .block-add-playlist .account__card .account__content p,
    .block-add-playlist .account__card ::-ms-backdrop {
        text-align: center
    }
}

.block-add-playlist .account__card--add {
    color: #7d83aa
}

.block-add-playlist .account__card--add i {
    display: block;
    color: #5150c4;
    margin-bottom: 8px;
    text-align: center
}

@media screen and (min-width:48rem) {
    .block-add-playlist .account__card--add i {
        font-size: 1.25rem
    }
}

@media screen and (min-width:64rem) {
    .block-add-playlist .account__card--add i {
        font-size: 1.5rem
    }
}

@media screen and (min-width:80rem) {
    .block-add-playlist .account__card--add i {
        font-size: 1.75rem
    }
}

@media screen and (min-width:90rem) {
    .block-add-playlist .account__card--add i {
        font-size: 1.875rem
    }
}

@media screen and (min-width:64rem) {
    .block-add-playlist .account__card--add p {
        font-size: .875rem
    }
}

@media screen and (min-width:80rem) {
    .block-add-playlist .account__card--add p {
        font-size: 1rem
    }
}

.block-associate-app {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 25px;
    background: #fff;
    height: 90px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-associate-app__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 30px
}

@media screen and (min-width:20rem) {
    .block-associate-app__content img {
        max-width: 100px
    }
}

@media screen and (min-width:80rem) {
    .block-associate-app__content img {
        max-width: 120px
    }
}

.block-associate-app__action msc-button {
    font-size: .625rem
}

@media screen and (min-width:64rem) {
    .block-associate-app__action msc-button {
        font-size: .75rem
    }
}

.block-connected-application msc-button {
    margin-top: 1rem
}

.block-connected-application .account__card {
    position: relative
}

.block-connected-application .account__card .account__content {
    background-color: #fff;
    min-height: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 10px;
    transition: all .3s ease;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-connected-application .account__card .account__content img {
    max-width: 60%;
    height: auto
}

@media screen and (min-width:20rem) {
    .block-connected-application .account__card .account__content img {
        max-width: 90%
    }
}

@media screen and (min-width:48rem) {
    .block-connected-application .account__card .account__content img {
        max-width: 40%
    }
}

@media screen and (min-width:64rem) {
    .block-connected-application .account__card .account__content img {
        max-width: 60%
    }
}

@media (-ms-high-contrast:none) {

    .block-connected-application .account__card .account__content ::-ms-backdrop,
    .block-connected-application .account__card .account__content img {
        width: 240px;
        margin-left: auto;
        margin-right: auto
    }
}

.block-connected-application .account__card .account__title {
    font-size: .8rem;
    color: #b2b5cd;
    text-transform: capitalize
}

@media (-ms-high-contrast:none) {

    .block-connected-application .account__card .account__content,
    .block-connected-application .account__card ::-ms-backdrop {
        display: table-cell;
        vertical-align: middle;
        text-align: center
    }
}

.block-connected-application .account__card .tooltip {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    z-index: 1
}

.block-connected-application .account__card .tooltip i {
    font-size: .875rem;
    color: #d3d5e4
}

.block-connected-application .account__card .tooltip:hover {
    z-index: 2000
}

.block-connected-application .account__card .link {
    display: block;
    text-align: center;
    margin-top: 10px;
    margin-right: 0;
    color: #b2b5cd
}

.block-connected-application .account__card:hover .account__content {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    transform: scale(1.055);
    cursor: pointer
}

.block-personal-link {
    background-color: #fff;
    padding: 8px;
    margin: 16px 8px;
    transition: all .3s ease;
    min-height: 100%;
    text-align: center;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:64rem) {
    .block-personal-link {
        margin: 0
    }
}

.block-personal-link__content {
    margin: 3rem 0 0
}

.block-personal-link__content img {
    max-width: 120px;
    height: auto;
    margin-bottom: 16px
}

.block-personal-link__content--full {
    margin: 0
}

.block-personal-link__content--full img {
    width: 100%
}

.block-personal-link__icon {
    position: absolute;
    top: 5px;
    left: 8px
}

.block-personal-link__icon i {
    color: #5150c4
}

.block-personal-link__actions {
    position: absolute;
    top: 0;
    right: 8px
}

.block-playlist-external {
    background-color: #fff;
    transition: all .3s ease;
    height: 100%;
    margin: 16px 8px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:64rem) {
    .block-playlist-external {
        margin: 0
    }
}

.block-playlist-external .account__card {
    position: relative;
    cursor: default
}

.block-playlist-external .account__card .account__content {
    background-color: #fff;
    border-radius: 4px;
    min-height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1
}

.block-playlist-external .account__card .account__content img {
    max-width: 120px;
    height: auto;
    width: 100%;
    margin-bottom: 30px
}

.block-playlist-external .account__card .account__content .content--bottom {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 10px;
    word-break: break-all
}

.block-playlist-external .account__card .account__action {
    position: absolute;
    top: 2px;
    right: 5px;
    z-index: 5;
    cursor: pointer
}

.block-playlist-external .account__card .account__action i {
    width: 20px;
    height: 20px;
    transition: all .3s ease
}

.block-playlist-external .account__card .account__action i:hover {
    color: #7d83aa;
    transition: all .3s ease
}

.block-playlist-external .account__card .account__header {
    display: flex;
    align-items: center;
    padding: 5px 0 0 2px
}

.block-playlist-external .account__card .account__header i {
    color: #b2b5cd
}

.block-playlist-external .account__card .account__header p {
    font-size: .75rem;
    color: #b2b5cd
}

.block-playlist-external .dropdown {
    position: absolute;
    padding: 2rem;
    border: 1px solid #656c9b;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    display: none;
    transition: all .4s ease;
    margin-top: 1rem;
    z-index: 100
}

.block-playlist-external .dropdown--account {
    width: 150px;
    margin-top: 0;
    padding: 5px;
    right: 0
}

.block-playlist-external .dropdown--account .list__action li {
    cursor: pointer
}

.block-playlist-external .dropdown--account .list__action li i {
    margin-right: 10px
}

.block-playlist-external .dropdown--account .list__action li i.icon-trash-1 {
    color: #ffa2a2
}

.block-playlist-external .dropdown.active {
    display: block
}

.block-provider {
    position: relative
}

.block-provider--editable {
    cursor: pointer;
    transition: all .3s ease
}

.block-provider--editable:hover {
    transform: scale(1.03);
    transition: all .3s ease
}

.block-provider--selectable {
    cursor: pointer;
    transition: all .3s ease
}

.block-provider--selectable:hover {
    transform: scale(1.03);
    transition: all .3s ease
}

.block-provider--selectable .block-provider__card {
    justify-content: center
}

.block-provider--link .block-provider__card {
    justify-content: flex-start
}

.block-provider--link .block-provider__card__picture {
    height: 18px
}

.block-provider--playlist .block-provider__card {
    justify-content: flex-start
}

.block-provider--playlist .block-provider__card__icon {
    display: flex;
    align-items: center;
    top: 0
}

.block-provider--playlist .block-provider__card__icon i {
    font-size: 1rem
}

.block-provider--playlist .block-provider__card__icon span {
    margin: 0 0 0 .5rem;
    font-size: .75rem;
    color: #b2b5cd
}

.block-provider--playlist .block-provider__card__picture {
    height: 50px
}

.block-provider__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 20px 25px;
    height: 90px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-provider__card__new {
    position: absolute;
    top: 2px;
    left: 10px;
    -webkit-animation: bounce-new 5s ease infinite;
    animation: bounce-new 5s ease infinite;
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.block-provider__card__icon {
    position: absolute;
    top: 10px;
    left: 10px
}

.block-provider__card__actions {
    position: absolute;
    top: 10px;
    right: 10px
}

.block-provider__card__spinner {
    position: absolute;
    top: 5px;
    right: 5px
}

.block-provider__card__logo {
    display: flex;
    justify-content: center;
    width: 150px
}

@media screen and (max-width:32rem) {
    .block-provider__card__logo {
        width: 100px
    }
}

.block-provider__card__picture {
    display: block;
    width: 150px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

@media screen and (max-width:32rem) {
    .block-provider__card__picture {
        width: 100px
    }
}

.block-provider__incoming {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.block-provider__incoming i {
    color: #8c91b4
}

.block-provider__incoming p {
    color: #8c91b4;
    font-size: .75rem;
    margin-left: 10px
}

@-webkit-keyframes bounce-new {

    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-8px)
    }
}

@keyframes bounce-new {

    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-8px)
    }
}

.block-contact-communities {
    border-radius: 4px;
    background-color: #fff;
    padding: 25px 15px;
    margin-top: 40px;
    text-align: center;
    max-height: 305px
}

.block-contact-communities.card--empty-state {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px
}

.block-contact-communities * {
    display: block
}

.block-contact-communities .avatar {
    width: 75px;
    height: 75px;
    margin: 0 auto
}

.block-contact-communities h2 {
    margin-bottom: 30px
}

.block-contact-communities p {
    font-size: .875rem;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 400
}

.block-contact-communities span {
    font-size: .875rem;
    color: #7d83aa
}

.block-contact-communities__link {
    display: block;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto
}

.block-contact-communities__link>* {
    display: flex;
    align-items: center;
    justify-content: center
}

.block-contact-communities__avatar {
    position: relative;
    display: inline-block
}

.block-contact-communities__avatar i {
    position: absolute;
    bottom: -10px;
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #5150c4;
    transform: scale(1), translateX(-50%);
    cursor: pointer
}

.block-contact-communities__avatar i:hover {
    transform: scale(1.1);
    transition: all .4s cubic-bezier(.175, .885, .32, 2)
}

.block-description-communities {
    background: #fff;
    padding: 32px 24px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-description-communities__icon {
    margin-right: 18px
}

.block-description-communities__title {
    color: #020d38;
    font-size: 1rem;
    margin-bottom: 8px
}

.block-description-communities__desc {
    color: #7d83aa;
    font-size: .875rem;
    margin-bottom: 8px
}

.block-description-communities .link {
    color: #feb88a
}

.block-group-details {
    padding: 30px 35px;
    background: #fff;
    margin: -16px 0;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-group-details__item {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #f4997d;
    margin: 16px 0
}

.block-group-details__item i {
    color: #b2b5cd;
    margin-right: 8px
}

.block-group-details__item span {
    color: #b2b5cd;
    margin-left: 8px
}

.block-group-details .session__subtitle {
    color: inherit;
    padding-bottom: 1rem;
    margin-top: 0;
    margin-bottom: 10px
}

@media screen and (max-width:48rem) {
    .block-group-details {
        box-shadow: none;
        margin-bottom: 15px;
        border-radius: 0
    }
}

.block-header-communities__hero {
    background-image: url(https://static.myskillcamp.com/images/group-cover.png);
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    height: 220px;
    padding: 20px
}

@media screen and (min-width:32rem) {
    .block-header-communities__hero {
        padding: 20px 75px;
        height: 320px
    }
}

.block-header-communities__title {
    color: #fff;
    font-size: 1.375rem;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #fff
}

.block-header-communities__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
    padding: 0 20px
}

@media screen and (min-width:32rem) {
    .block-header-communities__content {
        padding: 0 40px
    }
}

.block-header-communities__infos {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: -56px
}

.block-header-communities__curve {
    position: absolute;
    width: 222px;
    height: 125px;
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(https://static.myskillcamp.com/images/graphic-curve-avatar.svg)
}

@media screen and (min-width:64rem) {
    .block-header-communities__curve {
        position: relative
    }
}

.block-header-communities__logo {
    position: absolute;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 50%
}

.block-header-communities__logo img {
    max-width: 45px;
    height: auto
}

.block-header-communities__status {
    position: absolute
}

.block-header-communities__status .tag {
    margin-bottom: 68px
}

.block-header-communities__status .avatar-group {
    margin: 40px 0 0
}

@media screen and (min-width:20rem) {
    .block-header-communities__status {
        left: 180px
    }
}

@media (-ms-high-contrast:none) {

    .block-header-communities__status .avatar-group,
    ::-ms-backdrop {
        margin: 8px 0 0
    }
}

@media (-ms-high-contrast:none) {

    .block-header-communities__curve,
    ::-ms-backdrop {
        background-position: 0 7px
    }
}

@media (-ms-high-contrast:none) {

    .block-header-communities__infos,
    ::-ms-backdrop {
        margin-top: -50px
    }
}

.block-member {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    border-radius: 4px;
    padding: 15px;
    background: #fff;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-member .member {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.block-member .member .avatar {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-right: 10px
}

.block-member .member__name {
    font-family: Circular Std Book;
    font-size: .875rem;
    margin-bottom: 5px
}

.block-member .member__job {
    color: #7d83aa;
    font-size: .75rem;
    font-family: Circular Std Book;
    margin-top: 5px
}

.block-member__discussion i {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    transform: scale(1);
    cursor: pointer;
    transition: .2s cubic-bezier(.175, .885, .32, 2)
}

.block-member__discussion:hover i {
    transform: scale(1.05)
}

.block-resource-community {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(.5em*3.5) 0 .5em;
    border-radius: 8px
}

.block-resource-community i {
    background-color: rgba(211, 213, 228, .7);
    color: #656c9b;
    padding: calc(.5em*0.5);
    border-radius: 50%;
    font-size: 1.25rem;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s
}

.block-resource-community i:hover {
    background-color: #d3d5e4;
    color: #020d38
}

.block-tags {
    background: #fff;
    border-radius: 4px;
    padding: 32px 28px
}

.block-tags__title {
    font-size: 1rem;
    color: #020d38;
    margin-bottom: 16px
}

.block-tags__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: -4px
}

.block-tags__list .tag {
    margin: 4px
}

.block-correction {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    position: relative
}

@media screen and (min-width:32rem) {
    .block-correction {
        position: static
    }
}

.block-correction__header {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 18px 36px;
    border-radius: 4px
}

@media screen and (min-width:32rem) {
    .block-correction__header {
        position: relative;
        padding: 24px 48px 24px 36px;
        width: auto
    }
}

@media screen and (min-width:48rem) {
    .block-correction__header {
        flex-direction: row
    }
}

.block-correction__header>* {
    width: calc(50% - calc(.5em*3))
}

@media screen and (min-width:32rem) {
    .block-correction__header>* {
        width: auto
    }
}

.block-correction__header .block-correction__toggle {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-top-right-radius: O;
    border-bottom-right-radius: 4px;
    border-top-left-radius: O;
    border-bottom-left-radius: 4px;
    width: 100%;
    height: 24px;
    background: rgba(211, 213, 228, .5)
}

@media screen and (min-width:32rem) {
    .block-correction__header .block-correction__toggle {
        width: 32px;
        height: 100%;
        left: auto;
        top: 0;
        bottom: auto;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
}

.block-correction__header .block-correction__toggle i {
    transform: rotate(-180deg);
    transition: transform .3s ease-in-out;
    font-size: 1.125rem
}

.block-correction__header.active .block-correction__toggle {
    border-bottom-right-radius: 0
}

.block-correction__header.active .block-correction__toggle i {
    transform: rotate(0deg);
    transition: transform .3s ease-in-out
}

.block-correction__header.active+.block-correction__body {
    opacity: 1;
    visibility: visible;
    display: block
}

.block-correction__title {
    font-size: 1rem;
    color: #020d38
}

@media screen and (min-width:32rem) {
    .block-correction__title {
        width: auto;
        max-width: 75%
    }
}

.block-correction__title p {
    margin-top: 8px
}

.block-correction__body {
    opacity: 0;
    visibility: hidden;
    display: none;
    padding: 16px 16px 32px
}

@media screen and (min-width:32rem) {
    .block-correction__body {
        padding: 24px 48px 24px 36px
    }
}

.block-correction__statements {
    padding: 24px 0 48px
}

.block-correction__statements p {
    font-size: 1.125rem;
    color: #ed8371;
    margin-bottom: 24px
}

.block-correction__desc {
    color: #7d83aa;
    font-size: .875rem;
    margin-bottom: 24px
}

.block-correction__preview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -8px
}

.block-correction__preview img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin: 8px 0
}

@media screen and (min-width:32rem) {
    .block-correction__preview img {
        max-width: 200px;
        margin: 8px
    }
}

@media screen and (min-width:64rem) {
    .block-correction__preview img {
        max-width: 400px;
        margin: 8px
    }
}

.block-correction__answer {
    padding: 48px 0;
    border-top: 1px solid #d3d5e4
}

.block-correction__answer h3 {
    font-size: 1.125rem;
    color: #020d38;
    margin-bottom: 24px
}

.block-correction__answer p {
    color: #7d83aa;
    font-size: .875rem;
    margin-bottom: 24px
}

.block-correction__resources {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -20px
}

.block-correction__resources>* {
    margin: 0 20px
}

.block-correction__feedback {
    padding: 48px 0;
    border-top: 1px solid #d3d5e4
}

@media screen and (min-width:32rem) {
    .block-correction__feedback {
        padding: 48px 0
    }
}

.block-correction__feedback p:first-child {
    font-size: 1.125rem;
    color: #020d38;
    margin-bottom: 24px
}

.block-correction__profile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 24px -24px 48px
}

.block-correction__profile>* {
    margin: 16px 24px
}

@media screen and (min-width:32rem) {
    .block-correction__profile>* {
        margin: 0 24px
    }
}

.block-correction__timeline {
    margin: 0 -32px
}

@media screen and (min-width:32rem) {
    .block-correction__timeline {
        margin: 0
    }
}

.block-correction__score {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

@media screen and (min-width:20rem) {
    .block-correction__score p {
        display: none
    }
}

@media screen and (min-width:48rem) {
    .block-correction__score p {
        display: block
    }
}

.block-correction__chart {
    width: 150px
}

@media screen and (min-width:20rem) {
    .block-correction__chart {
        margin: 1rem 0 0;
        display: flex;
        justify-content: center
    }
}

@media screen and (min-width:48rem) {
    .block-correction__chart {
        margin: 0;
        display: block
    }
}

.block-contact {
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    background-color: #fff;
    padding: 25px 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.block-contact.card--empty-state {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px
}

.block-contact * {
    display: block
}

.block-contact .avatar {
    width: 75px;
    height: 75px;
    margin: 0 auto
}

.block-contact h2 {
    font-weight: 300;
    margin-bottom: 30px
}

.block-contact p {
    font-size: .875rem;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 400
}

.block-contact span {
    font-size: .875rem;
    color: #7d83aa
}

.block-contact msc-button {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto
}

.block-contact__avatar {
    position: relative;
    display: inline-block
}

.block-contact__avatar i {
    position: absolute;
    bottom: -10px;
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #5150c4;
    transform: scale(1), translateX(-50%);
    cursor: pointer
}

.block-contact__avatar i:hover {
    transform: scale(1.1);
    transition: all .4s cubic-bezier(.175, .885, .32, 2)
}

.block-event {
    max-width: 100%;
    height: calc(100% - 32px - 1em);
    background: #fff;
    border-radius: 4px;
    padding: 32px 28px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-event--centered {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;
}

.block-event__item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border-radius: 0;
    padding: 10px 0;
    margin: 10px 0;
    border-bottom: 1px solid #d3d5e4
}

.block-event__item:first-child {
    padding-top: 0;
    margin-top: 0
}

.block-event__item:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0
}

.block-event__item .event__content {
    display: flex;
    align-items: center
}

.block-event__item .event__action {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: all .3s ease
}

.block-event__item:hover .event__action {
    opacity: 1
}

.block-event__desc {
    position: relative;
    cursor: pointer
}

.block-event__desc:after {
    position: absolute;
    bottom: -15px;
    right: 20px;
    font-family: myskillcamp;
    content: "\e823";
    display: block;
    opacity: 0;
    color: #fec6a1
}

.block-event__desc:hover .event__camp {
    color: rgba(23, 16, 90, .5);
    transition: all .3s ease
}

.block-event__desc:hover .event__camp span {
    color: rgba(23, 16, 90, .5);
    transition: all .3s ease
}

.block-event__desc:hover .event__title {
    color: rgba(23, 16, 90, .5);
    transition: all .3s ease
}

.block-event__desc:hover .event__title span {
    color: rgba(23, 16, 90, .5);
    transition: all .3s ease
}

.block-event__desc:hover:after {
    right: 0;
    opacity: 1;
    transition: all .3s ease
}

.block-event__camp {
    margin-top: 10px;
    color: #7d83aa
}

.block-event__camp span {
    color: #dbdaff
}

.block-event .tag--date {
    margin-right: 20px
}

.block-resources-dashboard {
    padding: 16px;
    background-color: #fff;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-resources-dashboard li:not(:last-child) {
    border-bottom: 1px solid #d3d5e4
}

.block-resources-dashboard li:first-child {
    margin-top: 0
}

@media screen and (min-width:20rem) {
    .block-resources-dashboard {
        height: auto
    }
}

@media screen and (min-width:48rem) {
    .block-resources-dashboard {
        height: 100%
    }
}

.block-time {
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media screen and (min-width:20rem) {
    .block-time {
        flex-direction: column;
        justify-content: space-around
    }
}

@media screen and (min-width:48rem) {
    .block-time {
        flex-direction: row;
        justify-content: space-between
    }
}

.block-time__item {
    position: relative;
    background-color: #fff;
    font-size: 1.875rem;
    font-weight: 700;
    float: left;
    width: calc(50% - 10px);
    margin-right: 20px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    cursor: pointer;
    transition: all .3s ease
}

.block-time__item:before {
    display: block;
    content: " ";
    width: 100%;
    padding-top: 100%
}

.block-time__item>.stats__content,
.block-time__item>img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.block-time__item:hover {
    transform: scale(1.02);
    transition: all .3s ease
}

.block-time__item--gradient {
    background-image: linear-gradient(#feb88a, #db7564);
    color: #fff
}

@media screen and (min-width:20rem) {
    .block-time__item {
        width: calc(100% - 10px)
    }

    .block-time__item:first-child {
        margin: 0 0 1rem
    }
}

@media screen and (min-width:48rem) {
    .block-time__item {
        width: calc(50% - 10px)
    }

    .block-time__item:first-child {
        margin: 0
    }
}

.block-time__item:last-child {
    margin-right: 0
}

.block-time__item .block-time__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

@media screen and (max-width:20rem) {
    .block-time__item .block-time__content p {
        text-align: center
    }
}

.block-time__item .block-time__content--stats p {
    color: #7d83aa
}

.block-time__item .block-time__content--stats i {
    color: #7986ef
}

.block-time__item i {
    font-size: 1.5rem;
    margin-right: 5px
}

.block-time__item p {
    font-size: 1.5rem
}

.block-time__item span {
    display: block;
    font-size: .75rem
}

.block-time__item h2 {
    margin-bottom: 20px
}

.block-welcome {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 30px 20px;
    height: calc(100% - 32px - 1em);
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-welcome--bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(https://static.myskillcamp.com/lxp/images/training-area-illus.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px
}

.block-welcome__text {
    z-index: 1;
    padding: 16px;
    overflow: hidden;
}

.block-welcome__text h2 {
    margin-bottom: 20px;
    color: #fff
}

/*
.block-welcome__text p {
    margin-bottom: 30px
}*/

.block-welcome .link__showmore {
    cursor: pointer;
    display: block;
    text-align: center;
    background-color: #fff;
    color: #feb88a;
    padding: 8px;
    border-radius: 5px;
    transition: all .3s ease;
    max-width: 250px
}

.block-welcome .link__showmore span {
    font-weight: 700;
    margin-right: 5px
}

.block-welcome .link__showmore:hover {
    transform: scale(1.05);
    transition: all .3s ease
}

.box.box-experience {
    background-color: #fff;
    padding: 35px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.box.box-experience--spacing {
    margin: 20px 0 0
}

.box.box-experience .box-experience__title {
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    padding: 0 0 1rem
}

.box.box-experience .box-experience__title h3 {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    color: #7d83aa
}

.box.box-experience .box-experience__title h3 i {
    font-size: 1.625rem;
    margin: 0 .5rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__title h3 i:hover {
    color: #535a8b;
    transition: all .3s ease
}

.box.box-experience .box-experience__title--theme,
.box.box-experience .box-experience__title--url {
    display: flex;
    align-items: center;
    justify-content: space-between
}

@media screen and (max-width:32rem) {

    .box.box-experience .box-experience__title--theme,
    .box.box-experience .box-experience__title--url {
        flex-direction: column;
        align-items: flex-start
    }
}

.box.box-experience .box-experience__title--theme .box-experience__data {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #8c91b4
}

.box.box-experience .box-experience__title--url .box-experience__data {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #8c91b4
}

.box.box-experience .box-experience__title--theme .box-experience__data span {
    font-weight: 600;
    color: #edbf5d
}

.box.box-experience .box-experience__title--url .box-experience__data span {
    font-weight: 600;
    color: #edbf5d
}

.box.box-experience .box-experience__title--theme .box-experience__data i {
    font-size: 1.375rem;
    color: #edbf5d
}

.box.box-experience .box-experience__title--url .box-experience__data i {
    font-size: 1.375rem;
    color: #edbf5d
}

.box.box-experience .box-experience__title--theme .box-experience__data .tooltip,
.box.box-experience .box-experience__title--url .box-experience__data .tooltip {
    margin: 0 .5rem
}

.box.box-experience .box-experience__title--theme .box-experience__data__actions msc-button,
.box.box-experience .box-experience__title--url .box-experience__data__actions msc-button {
    height: 22px;
    padding: 4px;
    font-size: .75rem
}

.box.box-experience .box-experience__content {
    margin: 25px 0 0
}

.box.box-experience .box-experience__content p {
    color: #8c91b4;
    font-size: .875rem
}

.box.box-experience .box-experience__content .input,
.box.box-experience .box-experience__content textarea {
    margin: 15px 0 0
}

.box.box-experience .box-experience__content--clipboard .input msc-button {
    position: absolute;
    top: 6px;
    right: 15px
}

.box.box-experience .box-experience__content--contact .dropdown-actions {
    z-index: 0
}

.box.box-experience .box-experience__content--contact .dropdown-actions.active {
    z-index: 10
}

.box.box-experience .box-experience__content--contact .input {
    margin: 0
}

.box.box-experience .box-experience__content--contact .dropdown-actions__list {
    overflow-y: initial
}

.box.box-experience .box-experience__content .content__upload {
    margin: 15px 0 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.box.box-experience .box-experience__content .content__upload .content__picture {
    position: relative;
    width: 300px;
    min-height: 200px
}

.box.box-experience .box-experience__content .content__upload .content__picture .picture__delete {
    position: absolute;
    top: -5px;
    right: -5px;
    color: #fff;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: all .3s ease;
    cursor: pointer
}

.box.box-experience .box-experience__content .content__upload .content__picture .picture__delete i {
    transform: rotate(0);
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__upload .content__picture .picture__delete:hover {
    transform: scale(1.2);
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__upload .content__picture .picture__delete:hover i {
    transform: rotate(90deg);
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__upload .content__picture.content__picture--empty {
    border: 2px dashed rgba(211, 213, 228, .5);
    border-radius: 6px
}

.box.box-experience .box-experience__content .content__upload .content__picture.content__picture--empty:before {
    font-family: myskillcamp;
    font-size: 1.5rem;
    content: "\e97f";
    width: 30px;
    height: 30px;
    color: rgba(211, 213, 228, .6);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.box.box-experience .box-experience__content .content__upload .content__picture.content__picture--load {
    border: 2px dashed rgba(211, 213, 228, .5);
    border-radius: 6px
}

.box.box-experience .box-experience__content .content__upload .content__picture img {
    width: 100%;
    border-radius: 6px
}

.box.box-experience .box-experience__content .content__upload .content__action {
    padding: 10px
}

.box.box-experience .box-experience__content .content__upload .content__action p {
    font-size: .875rem;
    margin: 15px 0
}

.box.box-experience .box-experience__content .content__resources {
    margin: 15px 0 0;
    background-color: #fff;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__resources .resource__empty {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__resources .resource__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    margin: 10px 0 0;
    padding: 0 0 10px
}

.box.box-experience .box-experience__content .content__resources .resource__item .item__title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__resources .resource__item .item__delete {
    cursor: pointer
}

.box.box-experience .box-experience__content .content__resources .resource__item .item__delete i {
    font-size: 1.125rem;
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__resources .resource__item .item__delete:hover i {
    color: #020d38;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__resources .resource__loading {
    margin: 10px 0 0
}

.box.box-experience .box-experience__content .content__resources .resource__loading .loading__data {
    display: flex;
    justify-content: space-between;
    color: #656c9b
}

.box.box-experience .box-experience__content .content__resources .resource__loading .loading__data .loading__percentage {
    font-size: .75rem
}

.box.box-experience .box-experience__content .content__resources .resource__loading .loading__progress {
    margin: 10px 0 0;
    background-color: rgba(211, 213, 228, .5)
}

.box.box-experience .box-experience__content .content__resources .content__action {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0
}

.box.box-experience .box-experience__content .content__theme__title {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.box.box-experience .box-experience__content .content__theme__title p {
    display: flex
}

.box.box-experience .box-experience__content .content__theme__title p .content__theme-activate {
    margin: 0 0 0 .5rem;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #80dab4;
    box-shadow: 0 0 0 rgba(128, 218, 180, .5);
    -webkit-animation: theme-pulse 1.5s infinite;
    animation: theme-pulse 1.5s infinite
}

.box.box-experience .box-experience__content .content__theme__title .flex div {
    margin: 0 .2rem
}

.box.box-experience .box-experience__content .content__theme__title .flex i {
    font-size: 1.25rem;
    cursor: pointer;
    color: #b2b5cd;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__theme__title .flex i:hover {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__theme__title .flex .icon-alert-triangle {
    color: #edbf5d
}

.box.box-experience .box-experience__content .content__theme__title .flex .icon-alert-triangle:hover {
    color: #edbf5d
}

.box.box-experience .box-experience__content .content__theme__title .flex .icon-theme--red {
    color: #e57373;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__theme__title .flex .icon-theme--red:hover {
    color: #f44436
}

.box.box-experience .box-experience__content .content__theme__title .flex .icon-theme--green {
    color: #6cd4a8;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__theme__title .flex .icon-theme--green:hover {
    color: #3eba81
}

.box.box-experience .box-experience__content .content__theme.col-6 {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 1rem;
    margin-top: 0
}

.box.box-experience .box-experience__content .content__theme--left {
    margin-left: 0
}

.box.box-experience .box-experience__content .content__theme--right {
    margin-right: 0
}

@media screen and (max-width:64rem) {
    .box.box-experience .box-experience__content .content__theme--right {
        margin-left: 0
    }
}

.box.box-experience .box-experience__content .content__theme-row {
    clear: both;
    display: flex;
    flex-wrap: wrap
}

.box.box-experience .box-experience__content .content__theme--new .content__theme__picture img {
    opacity: .3
}

.box.box-experience .box-experience__content .content__theme--new .content__theme__overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 213, 228, .5);
    border: 1px dashed rgba(101, 108, 155, .5);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center
}

.box.box-experience .box-experience__content .content__theme__picture {
    position: relative;
    margin: 1rem 0
}

.box.box-experience .box-experience__content .content__theme__picture img,
.box.box-experience .box-experience__content .content__theme__picture svg {
    width: 100%;
    height: auto
}

.box.box-experience .box-experience__content .content__theme__picture__banner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    color: #656c9b;
    background-color: #d3d5e4;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 4px 4px
}

@media screen and (max-width:32rem) {
    .box.box-experience .box-experience__content .content__theme__picture__banner {
        font-size: .75rem
    }
}

@media screen and (min-width:80rem) {
    .box.box-experience .box-experience__content .content__theme__picture__banner {
        font-size: .875rem
    }
}

@media screen and (min-width:160rem) {
    .box.box-experience .box-experience__content .content__theme__picture__banner {
        font-size: 1rem
    }
}

.box.box-experience .box-experience__content .content__theme__menu {
    display: flex;
    align-items: center
}

@media screen and (max-width:32rem) {
    .box.box-experience .box-experience__content .content__theme__menu {
        flex-direction: column
    }
}

.box.box-experience .box-experience__content .content__theme__menu .theme__colors__watcher {
    display: flex;
    width: 100%;
    justify-content: flex-start
}

.box.box-experience .box-experience__content .content__theme__menu .theme__colors__watcher .watcher {
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 5px;
    height: 32px
}

.box.box-experience .box-experience__content .content__theme__menu .theme__colors__watcher .watcher:last-child {
    margin: 0 0 0 1rem
}

.box.box-experience .box-experience__content .content__theme__menu .theme__colors__watcher .watcher-color {
    width: 20px;
    height: 20px;
    border-radius: 100%
}

.box.box-experience .box-experience__content .content__theme__menu .theme__colors__watcher .watcher-hex {
    margin: 0 0 0 .5rem;
    color: #8c91b4
}

.box.box-experience .box-experience__content .content__login__description {
    font-size: .875rem;
    color: #8c91b4;
    line-height: 1.5rem;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__url__description {
    font-size: .875rem;
    color: #8c91b4;
    line-height: 1.5rem;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__login__description a {
    text-decoration: underline;
    color: #8c91b4;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__description a {
    text-decoration: underline;
    color: #8c91b4;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__description a:hover {
    color: #feb88a;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__description a:hover {
    color: #feb88a;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__active,
.box.box-experience .box-experience__content .content__login__other,
.box.box-experience .box-experience__content .content__url__active,
.box.box-experience .box-experience__content .content__url__other {
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__login__active--border {
    padding: 0 0 2rem;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.box.box-experience .box-experience__content .content__login__other--border {
    padding: 0 0 2rem;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.box.box-experience .box-experience__content .content__url__active--border {
    padding: 0 0 2rem;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.box.box-experience .box-experience__content .content__url__other--border {
    padding: 0 0 2rem;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.box.box-experience .box-experience__content .content__login__active-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #656c9b;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__login__other-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #656c9b;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__url__active-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #656c9b;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__url__other-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #656c9b;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__login__active-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #5150c4;
    border-radius: 4px;
    padding: 18px 30px;
    margin: 0 0 1.5rem;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__other-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #5150c4;
    border-radius: 4px;
    padding: 18px 30px;
    margin: 0 0 1.5rem;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__active-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #5150c4;
    border-radius: 4px;
    padding: 18px 30px;
    margin: 0 0 1.5rem;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__other-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #5150c4;
    border-radius: 4px;
    padding: 18px 30px;
    margin: 0 0 1.5rem;
    transition: all .3s ease
}

@media screen and (max-width:32rem) {

    .box.box-experience .box-experience__content .content__login__active-item,
    .box.box-experience .box-experience__content .content__login__other-item,
    .box.box-experience .box-experience__content .content__url__active-item,
    .box.box-experience .box-experience__content .content__url__other-item {
        flex-direction: column
    }
}

.box.box-experience .box-experience__content .content__login__active-item:last-child,
.box.box-experience .box-experience__content .content__login__other-item:last-child,
.box.box-experience .box-experience__content .content__url__active-item:last-child,
.box.box-experience .box-experience__content .content__url__other-item:last-child {
    margin: 0
}

.box.box-experience .box-experience__content .content__login__active-item:hover,
.box.box-experience .box-experience__content .content__login__other-item:hover,
.box.box-experience .box-experience__content .content__url__active-item:hover,
.box.box-experience .box-experience__content .content__url__other-item:hover {
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__active-item .item-actions,
.box.box-experience .box-experience__content .content__login__active-item .item-title,
.box.box-experience .box-experience__content .content__login__other-item .item-actions,
.box.box-experience .box-experience__content .content__login__other-item .item-title,
.box.box-experience .box-experience__content .content__url__active-item .item-actions,
.box.box-experience .box-experience__content .content__url__active-item .item-title,
.box.box-experience .box-experience__content .content__url__other-item .item-actions,
.box.box-experience .box-experience__content .content__url__other-item .item-title {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.box.box-experience .box-experience__content .content__login__active-item .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__login__other-item .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__url__active-item .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__url__other-item .item-title {
    color: #656c9b
}

@media screen and (max-width:32rem) {

    .box.box-experience .box-experience__content .content__login__active-item .item-title,
    .box.box-experience .box-experience__content .content__login__other-item .item-title,
    .box.box-experience .box-experience__content .content__url__active-item .item-title,
    .box.box-experience .box-experience__content .content__url__other-item .item-title {
        display: flex;
        flex-direction: column;
        margin: 0 0 1rem
    }

    .box.box-experience .box-experience__content .content__login__active-item .item-title i,
    .box.box-experience .box-experience__content .content__login__other-item .item-title i,
    .box.box-experience .box-experience__content .content__url__active-item .item-title i,
    .box.box-experience .box-experience__content .content__url__other-item .item-title i {
        margin: 0 !important
    }

    .box.box-experience .box-experience__content .content__login__active-item .item-title span,
    .box.box-experience .box-experience__content .content__login__other-item .item-title span,
    .box.box-experience .box-experience__content .content__url__active-item .item-title span,
    .box.box-experience .box-experience__content .content__url__other-item .item-title span {
        margin: .5rem 0 !important
    }
}

.box.box-experience .box-experience__content .content__login__active-item .item-title i,
.box.box-experience .box-experience__content .content__login__other-item .item-title i,
.box.box-experience .box-experience__content .content__url__active-item .item-title i,
.box.box-experience .box-experience__content .content__url__other-item .item-title i {
    font-size: 1.5rem;
    margin: 0 1rem 0 0
}

.box.box-experience .box-experience__content .content__login__active-item .item-title span,
.box.box-experience .box-experience__content .content__login__other-item .item-title span,
.box.box-experience .box-experience__content .content__url__active-item .item-title span,
.box.box-experience .box-experience__content .content__url__other-item .item-title span {
    font-size: .875rem;
    margin: 0 1rem 0 0
}

.box.box-experience .box-experience__content .content__login__active-item .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__login__other-item .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__url__active-item .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__url__other-item .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__login__active-item .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__login__other-item .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__url__active-item .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__url__other-item .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__login__active-item .item-title__status,
.box.box-experience .box-experience__content .content__login__other-item .item-title__status,
.box.box-experience .box-experience__content .content__url__active-item .item-title__status,
.box.box-experience .box-experience__content .content__url__other-item .item-title__status {
    margin: 0 1rem 0 0;
    display: flex;
    width: 8px;
    height: 8px;
    border-radius: 50%
}

.box.box-experience .box-experience__content .content__login__active-item .item-title__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__other-item .item-title__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__active-item .item-title__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__other-item .item-title__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__active-item .item-title__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__other-item .item-title__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__active-item .item-title__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__other-item .item-title__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__active-item .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__other-item .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__active-item .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__other-item .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__active-item .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__other-item .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__active-item .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__other-item .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__active-item .item-actions i:last-child,
.box.box-experience .box-experience__content .content__login__other-item .item-actions i:last-child,
.box.box-experience .box-experience__content .content__url__active-item .item-actions i:last-child,
.box.box-experience .box-experience__content .content__url__other-item .item-actions i:last-child {
    margin: 0
}

.box.box-experience .box-experience__content .content__login__other-item {
    cursor: pointer;
    border-color: #d3d5e4 !important
}

.box.box-experience .box-experience__content .content__url__other-item {
    cursor: pointer;
    border-color: #d3d5e4 !important
}

.box.box-experience .box-experience__content .content__login__other-item:hover {
    transform: scale(1.02);
    border-color: #5150c4 !important;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.box.box-experience .box-experience__content .content__url__other-item:hover {
    transform: scale(1.02);
    border-color: #5150c4 !important;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.box.box-experience .box-experience__content .content__login__other-item:hover .item-actions i {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__url__other-item:hover .item-actions i {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__login__link,
.box.box-experience .box-experience__content .content__login__picture,
.box.box-experience .box-experience__content .content__url__link,
.box.box-experience .box-experience__content .content__url__picture {
    margin: 0 0 2rem !important
}

.box.box-experience .box-experience__content .content__login__custom,
.box.box-experience .box-experience__content .content__url__custom {
    margin: 0 !important
}

.box.box-experience .box-experience__content .content__login__custom-url,
.box.box-experience .box-experience__content .content__login__define,
.box.box-experience .box-experience__content .content__login__favicon,
.box.box-experience .box-experience__content .content__login__link,
.box.box-experience .box-experience__content .content__login__login,
.box.box-experience .box-experience__content .content__login__picture,
.box.box-experience .box-experience__content .content__url__custom-url,
.box.box-experience .box-experience__content .content__url__define,
.box.box-experience .box-experience__content .content__url__favicon,
.box.box-experience .box-experience__content .content__url__link,
.box.box-experience .box-experience__content .content__url__login,
.box.box-experience .box-experience__content .content__url__picture {
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__login__custom-url .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__login__define .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__login__favicon .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__login__link .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__login__login .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__login__picture .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__url__custom-url .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__url__define .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__url__favicon .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__url__link .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__url__login .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__url__picture .content-title {
    color: #656c9b;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem
}

.box.box-experience .box-experience__content .content__login__custom-url .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__login__define .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__login__favicon .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__login__link .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__login__login .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__login__picture .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__url__custom-url .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__url__define .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__url__favicon .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__url__link .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__url__login .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__url__picture .content-description {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic;
    font-weight: 400;
    margin: .5rem 0 0
}

.box.box-experience .box-experience__content .content__login__custom-url .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__login__define .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__login__favicon .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__login__link .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__login__login .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__login__picture .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__url__custom-url .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__url__define .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__url__favicon .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__url__link .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__url__login .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__url__picture .content-description p {
    color: #d3d5e4;
    font-size: .75rem;
    line-height: 1rem
}

.box.box-experience .box-experience__content .content__login__custom-url .content-favicon,
.box.box-experience .box-experience__content .content__login__define .content-favicon,
.box.box-experience .box-experience__content .content__login__favicon .content-favicon,
.box.box-experience .box-experience__content .content__login__link .content-favicon,
.box.box-experience .box-experience__content .content__login__login .content-favicon,
.box.box-experience .box-experience__content .content__login__picture .content-favicon,
.box.box-experience .box-experience__content .content__url__custom-url .content-favicon,
.box.box-experience .box-experience__content .content__url__define .content-favicon,
.box.box-experience .box-experience__content .content__url__favicon .content-favicon,
.box.box-experience .box-experience__content .content__url__link .content-favicon,
.box.box-experience .box-experience__content .content__url__login .content-favicon,
.box.box-experience .box-experience__content .content__url__picture .content-favicon {
    margin: 1rem 0
}

.box.box-experience .box-experience__content .content__login__custom-url .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__login__define .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__login__favicon .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__login__link .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__login__login .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__login__picture .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__url__custom-url .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__url__define .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__url__favicon .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__url__link .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__url__login .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__url__picture .content-favicon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    width: 60px;
    height: 60px
}

.box.box-experience .box-experience__content .content__login__custom-url .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__login__define .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__login__favicon .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__login__link .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__login__login .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__login__picture .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__url__custom-url .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__url__define .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__url__favicon .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__url__link .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__url__login .content-favicon__container .loader__content,
.box.box-experience .box-experience__content .content__url__picture .content-favicon__container .loader__content {
    align-items: center;
    justify-content: center
}

.box.box-experience .box-experience__content .content__login__custom-url .content-favicon__picture,
.box.box-experience .box-experience__content .content__login__define .content-favicon__picture,
.box.box-experience .box-experience__content .content__login__favicon .content-favicon__picture,
.box.box-experience .box-experience__content .content__login__link .content-favicon__picture,
.box.box-experience .box-experience__content .content__login__login .content-favicon__picture,
.box.box-experience .box-experience__content .content__login__picture .content-favicon__picture,
.box.box-experience .box-experience__content .content__url__custom-url .content-favicon__picture,
.box.box-experience .box-experience__content .content__url__define .content-favicon__picture,
.box.box-experience .box-experience__content .content__url__favicon .content-favicon__picture,
.box.box-experience .box-experience__content .content__url__link .content-favicon__picture,
.box.box-experience .box-experience__content .content__url__login .content-favicon__picture,
.box.box-experience .box-experience__content .content__url__picture .content-favicon__picture {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.box.box-experience .box-experience__content .content__login__custom-url .content-logo,
.box.box-experience .box-experience__content .content__login__define .content-logo,
.box.box-experience .box-experience__content .content__login__favicon .content-logo,
.box.box-experience .box-experience__content .content__login__link .content-logo,
.box.box-experience .box-experience__content .content__login__login .content-logo,
.box.box-experience .box-experience__content .content__login__picture .content-logo,
.box.box-experience .box-experience__content .content__url__custom-url .content-logo,
.box.box-experience .box-experience__content .content__url__define .content-logo,
.box.box-experience .box-experience__content .content__url__favicon .content-logo,
.box.box-experience .box-experience__content .content__url__link .content-logo,
.box.box-experience .box-experience__content .content__url__login .content-logo,
.box.box-experience .box-experience__content .content__url__picture .content-logo {
    margin: 1rem 0
}

.box.box-experience .box-experience__content .content__login__custom-url .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    width: 300px;
    height: 200px
}

.box.box-experience .box-experience__content .content__login__define .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    width: 300px;
    height: 200px
}

.box.box-experience .box-experience__content .content__login__favicon .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    width: 300px;
    height: 200px
}

.box.box-experience .box-experience__content .content__login__link .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    width: 300px;
    height: 200px
}

.box.box-experience .box-experience__content .content__login__login .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    width: 300px;
    height: 200px
}

.box.box-experience .box-experience__content .content__login__picture .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 4px;
    width: 300px;
    min-height: 200px
}

.box.box-experience .box-experience__content .content__url__custom-url .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    width: 300px;
    height: 200px
}

.box.box-experience .box-experience__content .content__url__define .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    width: 300px;
    height: 200px
}

.box.box-experience .box-experience__content .content__url__favicon .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    width: 300px;
    height: 200px
}

.box.box-experience .box-experience__content .content__url__link .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    width: 300px;
    height: 200px
}

.box.box-experience .box-experience__content .content__url__login .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 4px;
    width: 300px;
    min-height: 200px
}

.box.box-experience .box-experience__content .content__url__picture .content-logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 4px;
    width: 300px;
    min-height: 200px
}

.box.box-experience .box-experience__content .content__login__custom-url .content-logo__picture,
.box.box-experience .box-experience__content .content__login__define .content-logo__picture,
.box.box-experience .box-experience__content .content__login__favicon .content-logo__picture,
.box.box-experience .box-experience__content .content__login__link .content-logo__picture,
.box.box-experience .box-experience__content .content__login__login .content-logo__picture,
.box.box-experience .box-experience__content .content__login__picture .content-logo__picture,
.box.box-experience .box-experience__content .content__url__custom-url .content-logo__picture,
.box.box-experience .box-experience__content .content__url__define .content-logo__picture,
.box.box-experience .box-experience__content .content__url__favicon .content-logo__picture,
.box.box-experience .box-experience__content .content__url__link .content-logo__picture,
.box.box-experience .box-experience__content .content__url__login .content-logo__picture,
.box.box-experience .box-experience__content .content__url__picture .content-logo__picture {
    border-radius: 4px;
    width: 298px;
    height: 198px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.box.box-experience .box-experience__content .content__login__custom-url .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__define .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__favicon .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__link .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__login .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__picture .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__custom-url .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__define .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__favicon .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__link .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__login .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__picture .content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 22px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__custom-url .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__define .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__favicon .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__link .content-link .content-url {
    color: #656c9b;
    font-size: .875rem;
    white-space: nowrap;
    overflow: hidden;
}

.box.box-experience .box-experience__content .content__login__login .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__picture .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__custom-url .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__define .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__favicon .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__link .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__login .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__picture .content-link .content-url {
    color: #656c9b;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-item,
.box.box-experience .box-experience__content .content__login__define .content-custom-item,
.box.box-experience .box-experience__content .content__login__favicon .content-custom-item,
.box.box-experience .box-experience__content .content__login__link .content-custom-item,
.box.box-experience .box-experience__content .content__login__login .content-custom-item,
.box.box-experience .box-experience__content .content__login__picture .content-custom-item,
.box.box-experience .box-experience__content .content__url__custom-url .content-custom-item,
.box.box-experience .box-experience__content .content__url__define .content-custom-item,
.box.box-experience .box-experience__content .content__url__favicon .content-custom-item,
.box.box-experience .box-experience__content .content__url__link .content-custom-item,
.box.box-experience .box-experience__content .content__url__login .content-custom-item,
.box.box-experience .box-experience__content .content__url__picture .content-custom-item {
    position: relative
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__login__define .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__login__favicon .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__login__link .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__login__login .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__login__picture .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__url__custom-url .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__url__define .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__url__favicon .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__url__link .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__url__login .content-custom-item .dropdown,
.box.box-experience .box-experience__content .content__url__picture .content-custom-item .dropdown {
    width: 100%
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__define .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__favicon .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__link .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__login .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__picture .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__define .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__favicon .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__link .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__login .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__picture .content-custom-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    padding: 12px 28px;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-title,
.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-actions,
.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-title {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-title {
    color: #656c9b
}

.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-title {
    color: #656c9b
}

@media screen and (max-width:32rem) {

    .box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__login__define .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__login__link .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__login__login .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__url__define .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__url__link .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__url__login .content-custom-url .item-title,
    .box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-title {
        display: flex;
        flex-direction: column;
        margin: 0 0 1rem
    }

    .box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__login__define .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__login__link .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__login__login .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__url__define .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__url__link .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__url__login .content-custom-url .item-title i,
    .box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-title i {
        margin: 0 !important
    }

    .box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__login__define .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__login__link .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__login__login .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__url__define .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__url__link .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__url__login .content-custom-url .item-title span,
    .box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-title span {
        margin: .5rem 0 !important
    }
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-title i,
.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-title i {
    font-size: 1.5rem;
    margin: 0 1rem 0 0
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-title span,
.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-title span {
    font-size: .875rem;
    margin: 0 1rem 0 0
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-title__tag {
    background-color: #656c9b;
    border-radius: 12px;
    padding: 2px 10px
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-title__tag__text {
    font-size: .75rem;
    color: #fff
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-actions i {
    color: #d3d5e4;
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-actions i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.box.box-experience .box-experience__content .content__login__custom-url .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__login__define .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__login__favicon .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__login__link .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__login__login .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__login__picture .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__url__custom-url .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__url__define .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__url__favicon .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__url__link .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__url__login .content-custom-url .item-actions i:last-child,
.box.box-experience .box-experience__content .content__url__picture .content-custom-url .item-actions i:last-child {
    margin: 0
}

.box.box-experience .box-experience__content .content__login__custom-url .content-input,
.box.box-experience .box-experience__content .content__login__define .content-input,
.box.box-experience .box-experience__content .content__login__favicon .content-input,
.box.box-experience .box-experience__content .content__login__link .content-input,
.box.box-experience .box-experience__content .content__login__login .content-input,
.box.box-experience .box-experience__content .content__login__picture .content-input,
.box.box-experience .box-experience__content .content__url__custom-url .content-input,
.box.box-experience .box-experience__content .content__url__define .content-input,
.box.box-experience .box-experience__content .content__url__favicon .content-input,
.box.box-experience .box-experience__content .content__url__link .content-input,
.box.box-experience .box-experience__content .content__url__login .content-input,
.box.box-experience .box-experience__content .content__url__picture .content-input {
    margin: 1.5rem 0
}

.box.box-experience .box-experience__content .content__login__custom-url .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__login__define .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__login__favicon .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__login__link .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__login__login .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__login__picture .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__url__custom-url .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__url__define .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__url__favicon .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__url__link .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__url__login .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__url__picture .content-input__container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4)
}

.box.box-experience .box-experience__content .content__login__custom-url .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__define .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__favicon .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__link .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__login .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__picture .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__custom-url .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__define .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__favicon .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__link .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__login .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__url__picture .content-input__container__grey {
    display: flex;
    justify-content: center;
    color: #7d83aa;
    font-size: .875rem
}

.box.box-experience .box-experience__content .content__login__custom-url .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__login__define .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__login__favicon .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__login__link .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__login__login .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__login__picture .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__url__custom-url .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__url__define .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__url__favicon .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__url__link .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__url__login .content-input__container__grey--https,
.box.box-experience .box-experience__content .content__url__picture .content-input__container__grey--https {
    width: 80px
}

.box.box-experience .box-experience__content .content__login__custom-url .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__login__define .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__login__favicon .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__login__link .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__login__login .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__login__picture .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__url__custom-url .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__url__define .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__url__favicon .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__url__link .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__url__login .content-input__container__grey--tld,
.box.box-experience .box-experience__content .content__url__picture .content-input__container__grey--tld {
    width: 200px
}

.box.box-experience .box-experience__content .content__login__custom-url .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__login__define .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__login__favicon .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__login__link .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__login__login .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__login__picture .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__url__custom-url .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__url__define .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__url__favicon .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__url__link .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__url__login .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__url__picture .content-input__container input {
    border: none;
    width: 220px;
    height: 36px;
    color: #656c9b;
    font-size: .875rem;
    padding: 0 .75rem
}

.box.box-experience .box-experience__content .content__login__custom-url .content-input__container input.full,
.box.box-experience .box-experience__content .content__login__define .content-input__container input.full,
.box.box-experience .box-experience__content .content__login__favicon .content-input__container input.full,
.box.box-experience .box-experience__content .content__login__link .content-input__container input.full,
.box.box-experience .box-experience__content .content__login__login .content-input__container input.full,
.box.box-experience .box-experience__content .content__login__picture .content-input__container input.full,
.box.box-experience .box-experience__content .content__url__custom-url .content-input__container input.full,
.box.box-experience .box-experience__content .content__url__define .content-input__container input.full,
.box.box-experience .box-experience__content .content__url__favicon .content-input__container input.full,
.box.box-experience .box-experience__content .content__url__link .content-input__container input.full,
.box.box-experience .box-experience__content .content__url__login .content-input__container input.full,
.box.box-experience .box-experience__content .content__url__picture .content-input__container input.full {
    width: 415px
}

.box.box-experience .box-experience__content .content__login__custom-url .content-textarea .textarea,
.box.box-experience .box-experience__content .content__login__define .content-textarea .textarea,
.box.box-experience .box-experience__content .content__login__favicon .content-textarea .textarea,
.box.box-experience .box-experience__content .content__login__link .content-textarea .textarea,
.box.box-experience .box-experience__content .content__login__login .content-textarea .textarea,
.box.box-experience .box-experience__content .content__login__picture .content-textarea .textarea,
.box.box-experience .box-experience__content .content__url__custom-url .content-textarea .textarea,
.box.box-experience .box-experience__content .content__url__define .content-textarea .textarea,
.box.box-experience .box-experience__content .content__url__favicon .content-textarea .textarea,
.box.box-experience .box-experience__content .content__url__link .content-textarea .textarea,
.box.box-experience .box-experience__content .content__url__login .content-textarea .textarea,
.box.box-experience .box-experience__content .content__url__picture .content-textarea .textarea {
    margin: 0
}

.box.box-experience .box-experience__content .content__login__custom-url .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__login__define .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__login__favicon .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__login__link .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__login__login .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__login__picture .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__url__custom-url .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__url__define .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__url__favicon .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__url__link .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__url__login .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__url__picture .content-textarea .textarea--disabled {
    color: #7d83aa;
    border: 2px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .4);
    cursor: pointer
}

.box.box-experience .box-experience__content .content__login__custom-url .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__login__define .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__login__favicon .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__login__link .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__login__login .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__login__picture .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__url__custom-url .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__url__define .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__url__favicon .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__url__link .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__url__login .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__url__picture .content-connection {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    margin: 0 0 2rem
}

.box.box-experience .box-experience__content .content__login__custom-url .content-connection__status,
.box.box-experience .box-experience__content .content__login__define .content-connection__status,
.box.box-experience .box-experience__content .content__login__favicon .content-connection__status,
.box.box-experience .box-experience__content .content__login__link .content-connection__status,
.box.box-experience .box-experience__content .content__login__login .content-connection__status,
.box.box-experience .box-experience__content .content__login__picture .content-connection__status,
.box.box-experience .box-experience__content .content__url__custom-url .content-connection__status,
.box.box-experience .box-experience__content .content__url__define .content-connection__status,
.box.box-experience .box-experience__content .content__url__favicon .content-connection__status,
.box.box-experience .box-experience__content .content__url__link .content-connection__status,
.box.box-experience .box-experience__content .content__url__login .content-connection__status,
.box.box-experience .box-experience__content .content__url__picture .content-connection__status {
    margin: 0 1rem 0 0;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%
}

.box.box-experience .box-experience__content .content__login__custom-url .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__define .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__favicon .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__link .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__login .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__picture .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__custom-url .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__define .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__favicon .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__link .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__login .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__picture .content-connection__status--enable {
    background: #80dab4;
    box-shadow: 0 0 0 rgba(#80dab4, .5);
    -webkit-animation: status-enable-pulse 2.5s infinite;
    animation: status-enable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__custom-url .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__define .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__favicon .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__link .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__login .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__picture .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__custom-url .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__define .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__favicon .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__link .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__login .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__url__picture .content-connection__status--disable {
    background: #e57373;
    box-shadow: 0 0 0 rgba(#e57373, .5);
    -webkit-animation: status-disable-pulse 2.5s infinite;
    animation: status-disable-pulse 2.5s infinite
}

.box.box-experience .box-experience__content .content__login__custom-url .content-actions,
.box.box-experience .box-experience__content .content__login__define .content-actions,
.box.box-experience .box-experience__content .content__login__favicon .content-actions,
.box.box-experience .box-experience__content .content__login__link .content-actions,
.box.box-experience .box-experience__content .content__login__login .content-actions,
.box.box-experience .box-experience__content .content__login__picture .content-actions,
.box.box-experience .box-experience__content .content__url__custom-url .content-actions,
.box.box-experience .box-experience__content .content__url__define .content-actions,
.box.box-experience .box-experience__content .content__url__favicon .content-actions,
.box.box-experience .box-experience__content .content__url__link .content-actions,
.box.box-experience .box-experience__content .content__url__login .content-actions,
.box.box-experience .box-experience__content .content__url__picture .content-actions {
    margin: 1rem 0 0;
    display: flex;
    align-items: center
}

.box.box-experience .box-experience__content .content__login__custom-url .content-actions msc-button,
.box.box-experience .box-experience__content .content__login__define .content-actions msc-button,
.box.box-experience .box-experience__content .content__login__favicon .content-actions msc-button,
.box.box-experience .box-experience__content .content__login__link .content-actions msc-button,
.box.box-experience .box-experience__content .content__login__login .content-actions msc-button,
.box.box-experience .box-experience__content .content__login__picture .content-actions msc-button,
.box.box-experience .box-experience__content .content__url__custom-url .content-actions msc-button,
.box.box-experience .box-experience__content .content__url__define .content-actions msc-button,
.box.box-experience .box-experience__content .content__url__favicon .content-actions msc-button,
.box.box-experience .box-experience__content .content__url__link .content-actions msc-button,
.box.box-experience .box-experience__content .content__url__login .content-actions msc-button,
.box.box-experience .box-experience__content .content__url__picture .content-actions msc-button {
    margin: 0 1rem 0 0;
    font-size: .875rem;
    max-height: 34px
}

.box.box-experience .box-experience__content .content__login__actions,
.box.box-experience .box-experience__content .content__url__actions {
    display: flex;
    align-items: center
}

.box.box-experience .box-experience__content .content__login__actions msc-button,
.box.box-experience .box-experience__content .content__url__actions msc-button {
    margin: 0 1rem 0 0
}

.box.box-experience .box-experience__content .content__login__actions msc-button:last-child,
.box.box-experience .box-experience__content .content__url__actions msc-button:last-child {
    margin: 0
}

.box.box-experience .box-experience__description {
    margin: 15px 0 0
}

.box.box-experience .box-experience__description p {
    color: #b2b5cd
}

.box.box-experience .box-experience__language {
    display: flex;
    align-items: center;
    margin: 15px 0 0
}

.box.box-experience .box-experience__language__warning {
    margin: 0 0 0 1rem
}

.box.box-experience .box-experience__language__warning i {
    font-size: 1.375rem;
    color: #e57373
}

.box.box-experience .box-experience__language .select {
    width: 220px
}

.box.box-experience .box-experience__language .select .dropdown-learning-language {
    min-width: 220px;
    width: 220px
}

.box.box-experience .box-experience__language .select .dropdown-learning-language__flag {
    align-items: center;
    justify-content: space-between
}

.box.box-experience .box-experience__language .select .dropdown-learning-language__flag i {
    font-size: 1.125rem;
    color: #e57373
}

.box.box-experience .box-experience__edit {
    margin: 10px 0 0;
    display: flex;
    justify-content: flex-end
}

.box.box-experience .box-experience__edit msc-button {
    background-color: transparent;
    color: #4443af;
    font-size: .75rem;
    font-weight: 400
}

.box.box-experience .box-experience__edit msc-button i:before {
    transform: translateX(0);
    transition: all .3s ease
}

.box.box-experience .box-experience__edit msc-button:hover i:before {
    transform: translateX(10px);
    transition: all .3s ease
}

@-webkit-keyframes theme-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(128, 218, 180, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(128, 218, 180, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(128, 218, 180, 0)
    }
}

@keyframes theme-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(128, 218, 180, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(128, 218, 180, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(128, 218, 180, 0)
    }
}

@-webkit-keyframes status-enable-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(128, 218, 180, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(128, 218, 180, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(128, 218, 180, 0)
    }
}

@keyframes status-enable-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(128, 218, 180, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(128, 218, 180, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(128, 218, 180, 0)
    }
}

@-webkit-keyframes status-disable-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(229, 115, 115, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(229, 115, 115, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(229, 115, 115, 0)
    }
}

@keyframes status-disable-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(229, 115, 115, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(229, 115, 115, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(229, 115, 115, 0)
    }
}

.link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 35px;
    font-size: .75rem;
    color: #7d83aa;
    cursor: pointer;
    text-decoration: none
}

.link:last-child {
    margin-right: 0
}

.link i {
    font-size: 1.5rem
}

.link.link-play i,
.link i {
    display: flex;
    align-items: center;
    justify-content: center
}

.link.link-play i {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    margin-left: 16px;
    color: #fff;
    transform: scale(1);
    transition: .4s cubic-bezier(.175, .885, .32, 2)
}

.link.link-play:hover i {
    transform: scale(1.1)
}

.link.link-add {
    font-size: .875rem;
    color: #7d83aa
}

.link.link-add,
.link.link-back {
    transition: all .2s cubic-bezier(.77, 0, .175, 1)
}

.link.link-add i {
    width: 24px;
    height: 24px;
    font-size: 1rem;
    border-radius: 50%;
    color: #fff;
    margin: 0 10px 0 0;
    position: relative;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    transition: all .3s ease
}

.link.link-back i {
    width: 24px;
    height: 24px;
    font-size: 1rem;
    border-radius: 50%;
    color: #fff;
    margin: 0 10px 0 0;
    position: relative;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    transition: all .3s ease
}

.link.link-add i:before,
.link.link-back i:before {
    z-index: 2
}

.link.link-add i:after,
.link.link-back i:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transform: scale(1);
    border-radius: 50%;
    transition: all .5s ease
}

.link.link-add:hover i:after,
.link.link-back:hover i:after {
    opacity: 1;
    transform: scale(1.1)
}

.link.link-add.link-add--icon-left i,
.link.link-back.link-add--icon-left i {
    transform: scale(1);
    transition: .2s cubic-bezier(.175, .885, .32, 2)
}

.link.link-add.link-add--icon-left i:after,
.link.link-back.link-add--icon-left i:after {
    transition: none
}

.link.link-add.link-add--icon-left:hover {
    color: #4443af
}

.link.link-back.link-add--icon-left:hover {
    color: #4443af
}

.link.link-add.link-add--icon-left:hover i,
.link.link-back.link-add--icon-left:hover i {
    transform: scale(1.1)
}

.link.link-add-session {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #4443af;
    color: #fff;
    margin-left: 10px;
    justify-content: center;
    cursor: pointer
}

.link.link-add-session i {
    font-size: 1rem
}

.link.link-back {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    transform: none;
    transform-origin: top right;
    border-radius: 4px;
    padding: 5px;
    min-width: 220px;
    margin-bottom: calc(.5em*3)
}

@media screen and (min-width:64rem) {
    .link.link-back {
        flex-direction: row;
        transform: rotate(-90deg) translateY(-220px);
        margin-bottom: 0;
        padding: 5px 25px
    }
}

@media screen and (min-width:80rem) {
    .link.link-back {
        padding: 20px
    }
}

.link.link-back i {
    width: 25px;
    height: 25px;
    margin-right: calc(.5em*2);
    margin-left: 0;
    transform: rotate(-90deg)
}

@media screen and (min-width:64rem) {
    .link.link-back i {
        transform: none;
        margin-right: 0;
        margin-left: calc(.5em*2)
    }
}

.link.link-back--blue {
    background: #5150c4
}

.link.link-back--blue span {
    color: #fff
}

.link.link-back--blue i {
    background: #fff;
    color: #5150c4
}

.link.link-back--transparent {
    background: transparent
}

.link.link-back--transparent i {
    background: transparent;
    color: #7d83aa;
    font-size: 1.25rem;
    box-shadow: none
}

.link.link-back--transparent:hover i {
    color: #fff
}

.link.link-back--horizontal {
    flex-direction: row-reverse;
    transform: none;
    padding-left: 0
}

.link.link-back--horizontal i {
    transform: rotate(-90deg);
    margin: 0
}

.link.link-back--horizontal span {
    margin-left: calc(.5em*2)
}

.link.link-beamer {
    display: flex;
    align-items: center;
    position: relative;
    font-size: .875rem;
    color: #7d83aa;
    cursor: pointer
}

.link.link-beamer:hover i {
    transform: translate(10px, -10px);
    transition: all .4s ease
}

.link.link-beamer i {
    font-size: 1.125rem;
    margin: 0 10px 0 0;
    transition: all .4s ease
}

.link.link-beamer .beamer_icon {
    top: -10px;
    right: -15px;
    background-color: #ed8371 !important
}

@media screen and (max-width:32rem) {
    .link.link-beamer:hover i {
        transform: none
    }

    .link.link-beamer .beamer_icon {
        display: none
    }
}

.link--add {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.link--add .link__text {
    font-size: .875rem;
    color: #7d83aa
}

.link--add .icon {
    font-size: 1.25rem;
    color: #afaef4;
    margin-right: 10px;
    transform: scale(1);
    transition: transform .3s ease
}

.link--add:hover .icon {
    transform: scale(1.2)
}

.link.link-showmore,
.link__contact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .875rem;
    white-space: nowrap
}

.link.link-showmore i,
.link__contact i {
    font-size: .875rem;
    margin-left: 8px;
    transition: all .3s ease
}

.link.link-showmore.link-showmore--blue a {
    color: #ffa2a2 !important
}

.link__contact.link-showmore--blue a {
    color: #ffa2a2 !important
}

.link.link-showmore.link-showmore--blue i:before {
    color: #ffa2a2
}

.link__contact.link-showmore--blue i:before {
    color: #ffa2a2
}

.link-request-declined {
    color: #ffc688
}

.link-request-declined span {
    text-decoration: underline;
    font-weight: 700
}

.link-request-declined i {
    font-size: 16px;
    margin-right: 5px
}

.link__group {
    justify-content: space-between
}

.link__group,
.link__tools {
    display: flex;
    align-items: center
}

.link__tools {
    position: absolute;
    top: calc(50% - 18px);
    right: -50px;
    justify-content: center;
    width: 35px;
    height: 36px;
    border-radius: 18px;
    background-color: #fff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.link__reply {
    color: #656c9b;
    font-size: .75rem
}

.link__more i {
    background-color: #4443af;
    color: #fff
}

.link__back i,
.link__more i {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center
}

.link__back i {
    background-color: rgba(101, 108, 155, .4)
}

.link__back i {
    color: #17105a
}

.link__download i {
    color: #17105a
}

.link__download i {
    background-color: rgba(211, 213, 228, .15);
    transform: scale(1);
    transition: all .35s
}

.link__download:hover i {
    color: #fff;
    background-color: #17105a;
    transform: scale(1.1);
    transition: all .35s
}

.link__leave-chat {
    font-size: .875rem;
    position: relative
}

.link__leave-chat span {
    color: #7d83aa;
    padding-right: 32px;
    transition: color .2s ease
}

@media screen and (min-width:20rem) {
    .link__leave-chat span {
        padding-right: 0;
        margin-top: 10px
    }
}

@media screen and (min-width:48rem) {
    .link__leave-chat span {
        padding-right: 32px;
        margin-top: 0
    }
}

.link__leave-chat i {
    color: #414776;
    margin: 0 .5rem 0 0;
    transition: all .3s ease;
    font-size: 1.125rem
}

.link__leave-chat:hover i {
    color: #e57373;
    transition: all .3s ease
}

.link__leave-chat:hover span {
    color: #e57373;
    transition: all .3s ease
}

.link__add-member {
    font-size: .75rem;
    margin-right: 10px;
    margin-left: 10px
}

.link__add-member span {
    color: #8290fd;
    transition: .2s ease
}

@media screen and (min-width:20rem) {
    .link__add-member span {
        display: none
    }
}

@media screen and (min-width:32rem) {
    .link__add-member span {
        display: none
    }
}

@media screen and (min-width:48rem) {
    .link__add-member span {
        display: block
    }
}

.link__add-member i {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8290fd;
    font-size: 1.25rem;
    margin-right: 8px;
    transition: .2s ease;
    width: 32px;
    height: 32px;
    cursor: pointer
}

.link__add-member i:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.link__add-member i:before {
    z-index: 100
}

@media screen and (min-width:20rem) {
    .link__add-member i {
        margin-left: -32px
    }

    .link__add-member i:after {
        box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
    }
}

@media screen and (min-width:32rem) {
    .link__add-member i {
        margin-left: -32px
    }

    .link__add-member i:after {
        box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
    }
}

@media screen and (min-width:48rem) {
    .link__add-member i {
        margin-left: 0
    }

    .link__add-member i:after {
        box-shadow: none
    }
}

.link__add-member:hover i {
    color: #b2b5cd
}

.link__add-member:hover span {
    color: #b2b5cd
}

.link-showmore {
    font-size: .75rem !important
}

.link-showmore:hover i {
    transform: translateX(5px);
    transition: all .3s ease
}

.link-showmore--light {
    color: #fff !important
}

.link-showmore--light i:before {
    color: #fff
}

.block-login {
    max-width: 500px;
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-login__title {
    font-size: 1.5rem;
    color: #414776;
    margin-bottom: 35px;
    font-weight: 300;
    text-align: center
}

.block-login__input {
    margin-bottom: 30px
}

.block-login__input:last-child {
    margin-bottom: -90px
}

.block-login__input .link {
    margin: .5rem 0 0;
    transition: all .3s ease
}

.block-login__input .link:hover {
    color: #656c9b
}

.block-login__label {
    color: #656c9b
}

.block-login__label {
    display: block;
    font-size: .875rem;
    text-align: left;
    margin-bottom: 8px
}

.block-login__submit {
    display: flex;
    align-items: center;
    justify-content: center
}

.block-mentor-profil {
    cursor: pointer;
    padding: 30px 50px;
    text-align: center;
    background: #fff;
    border-radius: 4px;
    transition: padding .35s ease
}

.block-mentor-profil:hover {
    padding: 40px 60px
}

.block-mentor-profil:hover .link {
    opacity: 1;
    display: block
}

.block-mentor-profil__avatar {
    position: relative;
    margin-bottom: 16px
}

.block-mentor-profil .tooltip {
    position: absolute;
    top: 0;
    right: 0;
    background: #feb88a;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center
}

.block-mentor-profil .tooltip i {
    color: #fff;
    font-size: 1rem
}

.block-mentor-profil .tag {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%)
}

.block-mentor-profil .link {
    opacity: 0;
    display: none;
    color: #ffa2a2;
    transition: all .35s ease
}

.block-mentor-profil p {
    font-size: 1rem;
    color: #020d38;
    margin: 16px 0 24px
}

.block-mentor-profil span {
    display: block;
    font-size: .875rem;
    color: #7d83aa;
    margin-top: 8px
}

.block-mentor__content {
    padding: 20px
}

.block-mentor__checkbox {
    margin: 0 60px 0 0
}

.block-mentor__content {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px
}

@media screen and (min-width:20rem) {
    .block-mentor__content {
        padding: 20px !important
    }
}

@media screen and (min-width:48rem) {
    .block-mentor__content {
        padding: 10px
    }
}

.block-mentor__company {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.block-mentor__company .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.375rem;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    color: #feb88a;
    border-radius: 50%
}

.block-mentor__company span {
    display: block;
    color: #8c91b4;
    font-size: .875rem;
    margin-top: 8px
}

.block-mentor__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0
}

@media screen and (min-width:20rem) {
    .block-mentor__action {
        margin: 0 -8px
    }
}

@media screen and (min-width:64rem) {
    .block-mentor__action {
        margin: 0 -25px
    }
}

.block-mentor__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px
}

@media screen and (min-width:32rem) {
    .block-mentor__checkbox {
        margin: 0 8px
    }
}

@media screen and (min-width:64rem) {
    .block-mentor__checkbox {
        margin: 0 25px
    }
}

.block-mentor__checkbox .icon {
    font-size: 1.375rem;
    color: #fec6a1;
    margin: 0 4px
}

.block-mentor .checkbox__container {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.block-module__skills {
    margin: 0 -6px
}

.block-module__skills>* {
    margin: 0 6px
}

.block-module__widgets .spinner {
    margin-right: 3rem
}

.block-section__wrapper .block-section__toggle i {
    transform: rotate(0deg);
    transition: transform .3s ease-in-out
}

.block-section__wrapper.active .block-section__toggle i {
    transform: rotate(-180deg);
    transition: transform .3s ease-in-out
}

.block-section__wrapper.active+.block-section__content {
    opacity: 1;
    visibility: visible;
    display: block
}

.block-section__content {
    opacity: 0;
    visibility: hidden;
    display: none
}

.block-section__header,
.block-section__title {
    display: flex
}

.block-section__widgets {
    display: flex;
    margin: 0 -32px
}

.block-section__widgets>* {
    margin: 0 32px
}

.block-task {
    padding: 30px 50px
}

.block-task__header {
    display: flex;
    padding: 32px
}

.block-task__footer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-task__widgets .spinner {
    margin-right: 5rem
}

.block-task--locked:after {
    content: "\e88d";
    font-family: myskillcamp;
    height: 35px;
    width: 35px;
    background-color: #d3d5e4;
    color: #7d83aa;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -18px;
    transform: translateY(-50%)
}

.block-task--planned .block-task__wrapper {
    align-items: center;
    padding: 32px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.block-task--planned .block-task__header,
.box-table {
    display: flex
}

.box-table {
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding: 16px 32px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.box-table>* {
    width: 20%
}

.box-table__activity {
    position: relative;
    font-size: .875rem;
    color: #8290fd;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.box-table__activity i {
    font-size: 1.375rem;
    color: #8290fd;
    margin: 0 20px 0 0
}

.box-table__activity span {
    font-size: .875rem;
    color: #282d58;
    display: block
}

.box-table__activity:before {
    display: none;
    content: "\e836";
    font-family: myskillcamp;
    font-size: 1.375rem;
    color: #8290fd;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%)
}

.box-table__activity--media {
    content: "\e8f6"
}

.box-table__activity--quizz {
    content: "\e836"
}

.box-table__activity--idea {
    content: "\e82a"
}

.box-table__activity--crop {
    content: "\e853"
}

.box-table__paragraph {
    font-size: .75rem;
    color: #b2b5cd
}

.box-table__paragraph--red {
    color: #ffa2a2
}

.box-table__paragraph--blue {
    color: #8290fd
}

.box-table__checkbox--first {
    width: 24px !important;
    margin-right: 44px
}

.box-table__icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.box-table__icons .icon {
    color: #7d83aa;
    margin: 0 4px
}

.box-table__icons .icon.active {
    color: #5150c4
}

.box-table__buttons {
    margin: 0 -8px
}

.box-table__buttons msc-button {
    margin: 0 8px
}

.box-table__img {
    height: 40px
}

.box-table__img img {
    max-width: 70px;
    max-height: 40px;
    height: auto
}

.box-table__bookmark {
    position: absolute;
    display: block;
    height: 25px;
    width: 25px;
    background-color: #d3d5e4;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(0 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 100%, 0 100%);
    transform: rotate(90deg);
    border-radius: 0 0 0 4px
}

.box-table__bookmark--active {
    background-color: #5150c4
}

.box-table__dropdown {
    text-align: right
}

.box-table__download {
    font-size: .875rem;
    color: rgba(255, 162, 162, .6);
    transition: color .2s cubic-bezier(.175, .885, .32, 2)
}

.box-table__link {
    font-size: .875rem;
    color: rgba(255, 162, 162, .6);
    transition: color .2s cubic-bezier(.175, .885, .32, 2)
}

.box-table__download:hover {
    color: #ffa2a2
}

.box-table__download i {
    color: #ffa2a2
}

.box-table__link:hover {
    color: #ffa2a2
}

.box-table__link i {
    color: #ffa2a2
}

.box-table__camps {
    font-size: .875rem;
    color: #7d83aa
}

.box-table__camps .icon {
    color: #ffa2a2;
    margin-right: 4px
}

.box-table__actions {
    position: absolute;
    top: 24.5px;
    right: 32px;
    width: auto !important
}

.box-table__actions--arrow {
    cursor: pointer
}

.box-table__actions--arrow i {
    font-size: 1.5rem;
    color: #b2b5cd;
    transition: color .2s cubic-bezier(.175, .885, .32, 2)
}

.box-table__actions--arrow:hover i {
    color: #282d58
}

.box-table__chart {
    width: 150px !important
}

.box-table--disabled {
    background-color: #f8f9ff
}

.box-table-community .box-table__actions {
    top: auto
}

.box-table-inscription {
    padding: 16px 32px
}

.box-table-inscription>* {
    margin-left: 0;
    width: 20%
}

.box-table-inscription .box-table-buttons {
    margin: 0 -8px
}

.blocks-footer-module-subscribe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 24px 48px
}

.blocks-footer-module-subscribe__infos {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -12px
}

.blocks-footer-module-subscribe__infos p {
    display: flex;
    align-items: center;
    color: #b2b5cd;
    font-size: .875rem;
    margin: 0 12px
}

.blocks-footer-module-subscribe__infos span {
    color: #db7564;
    margin: 0 5px
}

.blocks-footer-module-subscribe__infos i {
    color: #db7564;
    font-size: 1.125rem
}

.block-stats {
    background-color: #fff;
    padding: 25px;
    max-width: 460px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:20rem) {
    .block-stats {
        max-width: 100%
    }
}

@media screen and (min-width:64rem) {
    .block-stats {
        max-width: 460px
    }
}

.block-stats .stats {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px
}

.block-stats .stats:last-child {
    margin-bottom: 0
}

.block-stats .stats__size {
    position: relative;
    margin-right: -20px;
    margin-left: -40px;
    width: 180px;
    height: 90px
}

.block-stats .stats__icon {
    margin: 0 0 0 3rem
}

.block-stats .stats__icon i {
    font-size: 1.5rem;
    color: #d3d5e4
}

.block-stats .stats__duration {
    color: #7d83aa;
    font-size: .875rem;
    line-height: 18px;
    margin: 8px 0 0 3rem
}

.block-stats .stats__text p {
    color: #7d83aa;
    font-size: .875rem;
    line-height: 18px;
    margin: 8px 0
}

.block-stats .stats__text p:first-child {
    color: #020d38;
    font-size: 1rem
}

.box-learn-later {
    padding: 20px;
    display: block !important;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:64rem) {
    .box-learn-later {
        display: flex !important
    }
}

.box-learn-later__data {
    font-size: 1.125rem;
    color: rgba(2, 13, 56, .55);
    display: flex;
    align-items: center;
    margin: 0 15px 0 0
}

.box-learn-later__actions {
    width: auto;
    display: inline-block;
    float: right;
    margin-top: 16px;
    justify-content: flex-end
}

@media screen and (min-width:64rem) {
    .box-learn-later__actions {
        width: 40%;
        display: flex;
        float: none;
        margin-top: 0
    }
}

.box-learn-later__actions msc-button {
    border: 2px solid #feb88a;
    font-size: 1rem
}

.box-learn-later__actions msc-button {
    color: #feb88a;
    transition: all .3s ease
}

.box-learn-later__actions msc-button i {
    color: #feb88a;
    transition: all .3s ease
}

.box-learn-later__actions msc-button:hover {
    background-color: #feb88a
}

.box-learn-later__actions msc-button:hover {
    color: #fff;
    transition: all .3s ease
}

.box-learn-later__actions msc-button:hover i {
    color: #fff;
    transition: all .3s ease
}

.block-course-data {
    background: #fff;
    padding: 20px 24px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    display: flex;
    flex-direction: column
}

.block-course-data>div {
    margin-left: 12px
}

.block-course-data>div .block-course-data__title {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 32px 0 8px
}

.block-course-data>div .block-course-data__description {
    font-size: 1rem
}

.container__aside__preview {
    position: relative
}

.container__aside__preview .preview__image {
    background-size: cover;
    background-position: 50%;
    top: 50px;
    width: 100%;
    height: 300px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.container__aside__preview .preview__image msc-button {
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.container__aside__preview .preview__image msc-button i:before {
    transform: rotate(-180deg)
}

.block-course {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    padding: 20px
}

@media screen and (min-width:64rem) {
    .block-course {
        padding: 42px 36px
    }
}

.block-course__desc .card__skills {
    flex-wrap: wrap;
    margin: 0 0 .5rem
}

.block-course__details {
    margin: 10px 0 0
}

.block-course__text {
    margin-bottom: 10px
}

.block-course__infos {
    display: flex;
    align-items: center;
    font-size: 1.5rem
}

.block-course__infos .tag {
    display: block
}

.block-course__follow {
    margin-left: -55px;
    margin-bottom: 2rem
}

.block-course__follow p {
    display: inline-block;
    padding: .6rem .8rem;
    border-radius: .4rem;
    background: #f8f9ff;
    color: #ffc688
}

.block-course__follow p span {
    color: #ed8371
}

.block-course__header,
.block-course__header--left {
    display: flex;
    align-items: center
}

.block-course__header--left {
    justify-content: flex-start
}

.block-course__header--right {
    justify-content: flex-end
}

.block-course__header--between {
    justify-content: space-between
}

.block-course__header__tags {
    width: 100%
}

.block-course__header__tags__provider-picture {
    width: 150px;
    height: 90px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.block-course__header .tag {
    margin-right: 20px
}

.block-course__details {
    padding-left: 60px
}

@media screen and (min-width:20rem) {
    .block-course__details {
        margin-left: 0
    }
}

@media screen and (min-width:48rem) {
    .block-course__details {
        margin-left: 35px
    }
}

.block-course__title {
    font-size: 22px;
    color: #020d38;
    margin-bottom: 16px;
    font-weight: 700
}

@media screen and (max-width:20rem) {
    .block-course__title {
        margin-bottom: 5px
    }
}

.block-course__subtitle {
    color: #ffa2a2
}

.block-course__subtitle--no-margin {
    margin-top: 0
}

.block-course__start {
    margin-top: 16px
}

.block-course__text {
    color: #020d38;
    white-space: pre-line;
    font-size: 1.125rem;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 16px 0
}

.block-course__text strong {
    font-weight: 700 !important
}

.block-course__text dl,
.block-course__text ol,
.block-course__text ul {
    list-style: inside
}

.block-course__text ol {
    list-style-type: decimal
}

@media screen and (max-width:20rem) {
    .block-course__text {
        margin-bottom: 10px
    }
}

.block-course__start {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.block-course__start .tooltip i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #d3d5e4;
    color: #7d83aa
}

.block-course msc-button.demand {
    padding: 8px 15px;
    min-width: 180px
}

.block-date-presentation {
    padding: 12px
}

@media screen and (min-width:64rem) {
    .block-date-presentation {
        padding: 20px 24px
    }
}

.block-date-presentation__header {
    display: block;
    justify-content: space-between
}

@media screen and (min-width:64rem) {
    .block-date-presentation__header {
        display: flex
    }
}

.block-date-presentation__header--event {
    display: flex;
    align-items: center
}

@media screen and (min-width:64rem) {
    .block-date-presentation__header--light .block-date-presentation__desc {
        margin: -6px 0 -6px 3rem !important
    }
}

.block-date-presentation__desc {
    margin: 24px 0 24px 1rem
}

@media screen and (min-width:64rem) {
    .block-date-presentation__desc {
        margin: -6px 0 -6px 1rem
    }

    .block-date-presentation__desc>* {
        margin: 6px 0
    }
}

.block-date-presentation__desc .tooltip {
    display: inline-block !important;
    width: auto !important;
    height: auto !important
}

.block-date-presentation__desc .tooltip i {
    width: auto !important;
    height: auto !important;
    background: transparent !important
}

.block-date-presentation__title {
    display: inline-block
}

.block-date-presentation__title:not(:first-child) {
    margin-left: .8rem !important
}

.block-date-presentation__time {
    display: flex;
    position: absolute;
    top: 20px;
    right: 24px;
    text-align: right
}

@media screen and (min-width:48rem) {
    .block-date-presentation__time {
        display: block
    }
}

@media screen and (min-width:64rem) {
    .block-date-presentation__time {
        position: relative;
        top: 0;
        right: 0;
        text-align: left
    }
}

.block-date-presentation__time p {
    color: #7d83aa
}

.block-date-presentation__time p:first-child {
    position: relative;
    margin-right: .5rem;
    padding-right: .5rem
}

.block-date-presentation__time p:first-child:after {
    position: absolute;
    content: "|";
    right: 0
}

@media screen and (min-width:48rem) {
    .block-date-presentation__time p:first-child {
        margin-right: 0;
        padding-right: 0
    }

    .block-date-presentation__time p:first-child:after {
        display: none
    }
}

.block-date-presentation__camp {
    color: #7d83aa
}

.block-date-presentation__camp span {
    color: #b2b5cd
}

.block-date-presentation__localisation {
    font-size: .875rem;
    color: #b2b5cd
}

.block-date-presentation__stats {
    margin: 0 -12px
}

.block-date-presentation__stats span {
    font-size: .875rem;
    color: #7d83aa;
    margin: 0 12px
}

.block-date-presentation__stats i {
    color: #7d83aa
}

.block-date-presentation__toggle {
    width: 32px
}

.block-date-presentation__add {
    position: absolute;
    bottom: 24px;
    right: 20px
}

@media screen and (min-width:64rem) {
    .block-date-presentation__add {
        position: relative;
        bottom: 0;
        right: 0
    }
}

.block-date-presentation__icons {
    padding: 16px 16px 8px 60px
}

.block-date-presentation .tooltip i {
    background: #d3d5e4;
    color: #7d83aa
}

.block-date-presentation.active .block-date-presentation__body {
    margin-left: -60px
}

.block-date-presentation.active .block-date-presentation__item {
    margin: 20px 20px 20px 60px
}

.block-date-presentation {
    position: relative;
    background: #fff;
    padding: 12px 12px 40px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:64rem) {
    .block-date-presentation {
        padding: 12px
    }
}

@media screen and (min-width:80rem) {
    .block-date-presentation {
        padding: 20px 24px;
        box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    }
}

br.Apple-interchange-newline {
    display: none;
}

.block-date-presentation__header {
    display: flex;
    align-items: center;
    flex-wrap: nowrap
}

.block-date-presentation__subscription {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgba(211, 213, 228, .25);
    font-size: .75rem;
    line-height: 18px;
    padding: 4px 4px 4px 8px;
    display: inline-block;
    margin: 0 0 15px -25px
}

.block-date-presentation__subscription span {
    font-weight: 900;
    color: #7d83aa
}

.block-date-presentation__body {
    display: none
}

.block-date-presentation__infos {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: -30px
}

@media screen and (min-width:64rem) {
    .block-date-presentation__infos {
        margin: 0 20px 0 0
    }
}

.block-date-presentation__desc {
    margin: -6px 0 -6px 12px;
    font-size: .875rem
}

@media screen and (min-width:20rem) {
    .block-date-presentation__desc {
        font-size: .75rem;
        margin: 24px
    }
}

@media screen and (min-width:64rem) {
    .block-date-presentation__desc {
        font-size: .875rem;
        margin: -6px 0 -6px 12px
    }
}

.block-date-presentation__desc>* {
    margin: 6px 0
}

.block-date-presentation__desc>.block-date-presentation__title {
    max-width: 200px
}

@media screen and (min-width:20rem) {
    .block-date-presentation__desc>.block-date-presentation__title {
        max-width: 100%
    }
}

@media screen and (min-width:64rem) {
    .block-date-presentation__desc>.block-date-presentation__title {
        max-width: 300px
    }
}

.block-date-presentation__title {
    font-size: 1rem;
    color: #020d38;
    font-weight: 700
}

.block-date-presentation__time {
    margin: -8px 0 -8px 1rem
}

.block-date-presentation__time p {
    font-size: .875rem;
    color: #535a8b;
    margin: 8px 0
}

.block-date-presentation__camp {
    font-size: .875rem;
    color: #020d38
}

.block-date-presentation__camp span {
    color: #7d83aa
}

.block-date-presentation__events {
    font-size: .875rem;
    color: #b2b5cd
}

.block-date-presentation__events i {
    font-size: 1rem;
    color: #b2b5cd
}

.block-date-presentation__stats {
    margin-left: auto
}

@media screen and (min-width:null) {
    .block-date-presentation__stats {
        display: flex;
        flex-direction: column
    }
}

.block-date-presentation__stats span {
    font-size: 1rem;
    color: #020d38;
    margin-left: 30px
}

@media screen and (min-width:64rem) {
    .block-date-presentation__stats span {
        margin-left: 15px
    }
}

.block-date-presentation__stats i {
    font-size: 1rem;
    color: #020d38;
    margin-right: 10px
}

.block-date-presentation__actions {
    display: flex;
    margin-left: 30px
}

.block-date-presentation__toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    position: absolute;
    background-color: #f8f9ff;
    bottom: 0;
    left: 0;
    width: 100%
}

@media screen and (min-width:64rem) {
    .block-date-presentation__toggle {
        position: static;
        background-color: transparent;
        width: 32px
    }
}

.block-date-presentation__toggle i {
    transform: rotate(0);
    transition: .25s;
    font-size: 1.375rem
}

.block-date-presentation__add {
    cursor: pointer
}

.block-date-presentation__add i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: #fff;
    transition: all .3s ease
}

.block-date-presentation__add:hover i {
    transition: all .3s ease
}

.block-date-presentation__icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 12px
}

@media screen and (min-width:64rem) {
    .block-date-presentation__icons {
        justify-content: flex-start;
        padding: 16px 16px 8px 60px
    }
}

.block-date-presentation__icons p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #b2b5cd;
    font-size: .875rem;
    margin: 4px 8px
}

@media screen and (min-width:64rem) {
    .block-date-presentation__icons p {
        margin: 4px 1rem 4px 0
    }
}

.block-date-presentation__icons i {
    font-size: 1.125rem
}

.block-date-presentation .date {
    margin-right: 20px
}

.block-date-presentation .tooltip i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(211, 213, 228, .2);
    color: #b2b5cd;
    transition: .35s
}

.block-date-presentation .tooltip:hover i {
    color: #7d83aa;
    background: rgba(211, 213, 228, .7)
}

.block-date-presentation--details {
    padding: 20px 24px 20px 90px;
    margin-left: 0;
    border-radius: 4px
}

.block-date-presentation--details .date {
    left: 20px
}

.block-date-presentation.active .block-date-presentation__body {
    display: block;
    margin-top: 20px;
    margin-right: -24px;
    margin-left: -24px;
    padding: 0 12px;
    border-top: 1px solid #d3d5e4
}

@media screen and (min-width:20rem) {
    .block-date-presentation.active .block-date-presentation__body {
        border-top: none
    }
}

@media screen and (min-width:64rem) {
    .block-date-presentation.active .block-date-presentation__body {
        padding: 0;
        border-top: 1px solid #d3d5e4
    }
}

.block-date-presentation.active .block-date-presentation__item {
    margin: 20px;
    border-radius: 4px;
    border: 1px solid #d3d5e4
}

@media screen and (min-width:64rem) {
    .block-date-presentation.active .block-date-presentation__item {
        margin: 20px
    }
}

.block-date-presentation.active .block-date-presentation__item:last-child {
    margin-bottom: 0
}

.block-date-presentation.active .block-date-presentation__toggle i {
    transform: rotate(180deg)
}

.block-list-group {
    padding: 24px 56px;
    margin: -4px 0;
    background-color: #fff;
    border-radius: 4px
}

.block-list-group__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap
}

.block-list-group__item {
    font-size: .875rem;
    color: #7d83aa;
    margin: 4px 0;
    width: 50%
}

.block-list-group__item span {
    color: #db7564;
    margin: 0 4px
}

.block-list-group__item i {
    font-size: 1rem
}

.block-price {
    position: relative;
    background-color: #fff;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    padding: 15px;
    margin: 0 0 1rem
}

.block-price__content {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.block-price__title {
    font-size: 1.5rem;
    padding: 0 20px;
    color: #8c91b4
}

.block-price__title p {
    font-size: .875rem;
    margin: 1rem 0 0;
    font-style: italic
}

.block-price__request {
    width: auto
}

.block-price__request msc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    font-weight: 400;
    transition: .5s
}

.block-price__request msc-button i {
    color: #fff;
    margin-right: 10px;
    transition: .5s
}

.block-price__request msc-button:hover i {
    color: #fff
}

.block-price__icon {
    display: flex;
    align-items: center
}

.block-price__icon i {
    color: #feb88a;
    font-size: 1.375rem
}

.block-price__icon span {
    margin: 0 0 0 .5rem;
    color: #8c91b4;
    font-size: .75rem
}

.block-price__dropdown {
    position: absolute;
    bottom: -10px;
    left: 0
}

.block-price__card {
    margin-top: 6px;
    width: 100%
}

.block-price__card a {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #020d38;
    text-decoration: underline;
    transition: all .25ms ease
}

.block-price__card a:hover {
    color: #000
}

.block-price__card__dropdown .dropdown-virtual-card {
    right: -10px;
    top: 65px
}

.block__public {
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block__public .public__title {
    color: #020d38;
    font-size: 1.25rem;
    margin-bottom: 10px
}

.block__public .requirements__title {
    color: #020d38;
    font-size: 1.25rem;
    margin-bottom: 10px
}

.block__public .public__description {
    color: #020d38;
    white-space: pre-line;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 10px
}

.block__public .requirements__description {
    color: #020d38;
    white-space: pre-line;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 10px
}

.block-session-choice {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.block-session-choice>* {
    margin: 0 8px
}

.block-session-choice>:last-child {
    width: 100%
}

.block-share {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    padding: 15px;
    margin: 0 0 1rem
}

.block-share msc-button {
    background-color: rgba(211, 213, 228, .7);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #656c9b;
    font-weight: 400;
    transition: all .3s ease
}

.block-share msc-button i {
    color: #656c9b;
    margin-right: 10px;
    transition: all .3s ease
}

.block-share msc-button:hover {
    background-color: #d3d5e4
}

.block-share msc-button:hover {
    color: #414776;
    transition: all .3s ease
}

.block-share msc-button:hover i {
    color: #414776;
    transition: all .3s ease
}

.block-provider-skill {
    display: flex;
    flex-direction: column;
    border: 2px solid rgba(211, 213, 228, .4);
    border-radius: 4px;
    padding: 15px 0 15px 25px;
    transition: all .3s ease
}

.block-provider-skill--disable {
    background-color: rgba(211, 213, 228, .2) !important
}

.block-provider-skill--disable i {
    color: #d3d5e4 !important
}

.block-provider-skill--disable .block-provider-skill__actions .action-toggle,
.block-provider-skill--disable .block-provider-skill__actions .action-trash {
    cursor: default;
    pointer-events: none
}

.block-provider-skill--disable .block-provider-skill__tags .skill__content:hover.tooltip:after,
.block-provider-skill--disable .block-provider-skill__tags .skill__content:hover.tooltip:before {
    visibility: hidden
}

.block-provider-skill--padding {
    padding: 15px 25px
}

@media screen and (max-width:32rem) {
    .block-provider-skill {
        padding: 15px
    }
}

.block-provider-skill.active .block-provider-skill__body {
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.block-provider-skill.active .block-provider-skill__actions .action-toggle i:before {
    transform: rotate(-180deg);
    transition: all .3s ease
}

.block-provider-skill__header {
    position: relative
}

@media screen and (min-width:48rem) {
    .block-provider-skill__header {
        display: flex;
        align-items: center
    }
}

.block-provider-skill__header .icon-grid {
    font-size: 1.25rem;
    margin-right: 8px;
    color: #e57373
}

.block-provider-skill__title {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #656c9b;
    width: 33%
}

@media screen and (max-width:32rem) {
    .block-provider-skill__title {
        width: 40%
    }
}

.block-provider-skill__content {
    width: 67%;
    display: flex;
    align-items: center
}

@media screen and (min-width:48rem) {
    .block-provider-skill__content {
        justify-content: flex-end
    }
}

.block-provider-skill__content__empty {
    width: 75px;
    height: 24px;
    margin: 0 4px;
    border: 1px dashed #000;
    border-radius: 14px;
    opacity: .3
}

@media screen and (max-width:32rem) {
    .block-provider-skill__content {
        width: 60%
    }
}

.block-provider-skill__tags {
    width: 80%;
    display: flex;
    align-items: center
}

.block-provider-skill__tags .skill {
    margin: 5px 4px;
    height: 24px
}

.block-provider-skill__actions {
    position: absolute;
    top: 50%;
    right: 5%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: translateY(-50%)
}

@media screen and (min-width:48rem) {
    .block-provider-skill__actions {
        position: relative;
        transform: translateY(0)
    }
}

.block-provider-skill__actions .action-toggle {
    width: 20%;
    color: #b2b5cd;
    font-size: 1.375rem;
    transition: all .3s ease;
    cursor: pointer
}

.block-provider-skill__actions .action-trash {
    width: 20%;
    color: #b2b5cd;
    font-size: 1.375rem;
    transition: all .3s ease;
    cursor: pointer
}

.block-provider-skill__actions .action-toggle:hover {
    color: #656c9b
}

.block-provider-skill__actions .action-trash:hover {
    color: #656c9b
}

.block-provider-skill__actions .action-toggle {
    margin: 0 0 0 1rem
}

.block-provider-skill__actions .action-toggle i:before {
    transform: rotate(0);
    transition: all .3s ease
}

.block-provider-skill__body {
    display: none
}

.block-provider-skill__body .block-provider-skill__content {
    justify-content: flex-start
}

.block-provider-skill__body .block-provider-skill__tags {
    width: 100%
}

.block-provider-skill__empty {
    width: 33%
}

@media screen and (max-width:32rem) {
    .block-provider-skill__empty {
        width: 40%
    }
}

.block-details-list-calendar {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.block-details-list-calendar {
    background: #fff;
    border-radius: 4px;
    transition: all .3s ease
}

.block-details-list-calendar__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

@media screen and (min-width:20rem) {
    .block-details-list-calendar__header {
        flex-direction: column
    }
}

@media screen and (min-width:48rem) {
    .block-details-list-calendar__header {
        flex-direction: row
    }
}

.block-details-list-calendar__header .block-details-list-calendar__action {
    cursor: pointer
}

.block-details-list-calendar__header .block-details-list-calendar__action i:before {
    transform: rotate(0deg);
    transition: transform .3s ease-in-out
}

.block-details-list-calendar__header.active .block-details-list-calendar__action i:before {
    transform: rotate(-180deg);
    transition: transform .3s ease-in-out
}

.block-details-list-calendar__header.active+.block-details-list-calendar__body {
    opacity: 1;
    visibility: visible;
    display: block
}

.block-details-list-calendar__date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 24px;
    margin: 0 -8px;
    width: 25%
}

@media screen and (min-width:20rem) {
    .block-details-list-calendar__date {
        flex-direction: row;
        justify-content: space-around;
        width: 100%
    }
}

@media screen and (min-width:48rem) {
    .block-details-list-calendar__date {
        flex-direction: column;
        justify-content: flex-start;
        width: 25%
    }
}

@media screen and (min-width:90rem) {
    .block-details-list-calendar__date {
        flex-direction: row;
        justify-content: flex-start;
        width: 25%
    }
}

.block-details-list-calendar__details {
    margin: 0 8px
}

.block-details-list-calendar__details p {
    font-size: .875rem;
    font-weight: 300;
    color: #8c91b4;
    line-height: 28px
}

.block-details-list-calendar__details p:first-child {
    font-weight: 700;
    color: #656c9b
}

.block-details-list-calendar__details p span {
    font-size: 1.625rem;
    font-weight: 700;
    color: #7c6cde
}

.block-details-list-calendar__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    width: 70%
}

@media screen and (min-width:20rem) {
    .block-details-list-calendar__content {
        flex-direction: column;
        width: 100%
    }
}

@media screen and (min-width:48rem) {
    .block-details-list-calendar__content {
        flex-direction: column;
        width: 70%
    }
}

@media screen and (min-width:90rem) {
    .block-details-list-calendar__content {
        flex-direction: row;
        width: 70%
    }
}

.block-details-list-calendar__infos {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -24px 0 40px
}

@media screen and (max-width:90rem) {
    .block-details-list-calendar__infos {
        width: 45%
    }
}

@media screen and (min-width:20rem) {
    .block-details-list-calendar__infos {
        width: 100%;
        margin: 0
    }
}

@media screen and (min-width:90rem) {
    .block-details-list-calendar__infos {
        width: 60%;
        margin: 0 -24px 0 40px
    }
}

.block-details-list-calendar__hours {
    font-size: .875rem;
    color: #656c9b;
    margin: 0 24px;
    width: 50%
}

.block-details-list-calendar__score {
    font-size: .875rem;
    color: #656c9b;
    margin: 0 24px;
    width: 50%
}

@media screen and (min-width:20rem) {

    .block-details-list-calendar__hours,
    .block-details-list-calendar__score {
        width: 100%;
        margin: 0
    }
}

@media screen and (min-width:90rem) {

    .block-details-list-calendar__hours,
    .block-details-list-calendar__score {
        width: 45%;
        margin: 0 24px
    }
}

.block-details-list-calendar__hours span {
    display: block;
    font-size: 1.625rem;
    color: #656c9b;
    margin-top: 16px
}

.block-details-list-calendar__score span {
    display: block;
    font-size: 1.625rem;
    color: #656c9b;
    margin-top: 16px
}

.block-details-list-calendar__hours span i {
    font-size: 1.375rem;
    color: #d3d5e4
}

.block-details-list-calendar__score span i {
    font-size: 1.375rem;
    color: #d3d5e4
}

.block-details-list-calendar__title {
    width: 50%
}

@media screen and (min-width:20rem) {
    .block-details-list-calendar__title {
        width: 100%
    }
}

@media screen and (min-width:90rem) {
    .block-details-list-calendar__title {
        width: 50%
    }
}

.block-details-list-calendar__title .tag {
    margin-bottom: 12px
}

.block-details-list-calendar__title p {
    font-size: .875rem;
    color: #020d38
}

.block-details-list-calendar__body {
    opacity: 0;
    visibility: hidden;
    display: none;
    padding: 0 24px 24px
}

.block-details-list-calendar__body .block-details-list-calendar__action {
    position: relative;
    transform: none;
    top: 0;
    right: 0
}

.block-details-list-calendar__body .block-details-list-calendar__deleted p {
    font-size: .8rem;
    color: #656c9b;
    font-style: italic
}

.block-details-list-calendar__subtitle {
    font-size: 1rem;
    color: #db7564;
    margin-bottom: 24px
}

.block-details-list-calendar__title {
    font-size: 1.25rem;
    color: #020d38;
    margin-bottom: 24px
}

.block-details-list-calendar__text {
    font-size: .875rem;
    color: #656c9b;
    margin-bottom: 24px
}

.block-details-list-calendar__action {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%)
}

@media screen and (min-width:20rem) {
    .block-details-list-calendar__action {
        position: relative;
        transform: translateY(25%)
    }
}

@media screen and (min-width:48rem) {
    .block-details-list-calendar__action {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%)
    }
}

.block-details-list-calendar__action i {
    font-size: 1.375rem;
    color: #6c6bd4
}

.block-details-list-hour {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    transition: all .3s ease
}

.block-details-list-hour:hover {
    transform: scale(1.02)
}

.block-details-list-hour__date {
    width: 8%;
    padding: 16px 24px;
    border-right: 1px solid #d3d5e4
}

@media screen and (min-width:20rem) {
    .block-details-list-hour__date {
        width: 65px
    }
}

@media screen and (min-width:64rem) {
    .block-details-list-hour__date {
        width: 8%
    }
}

.block-details-list-hour__date p {
    font-size: .875rem;
    font-weight: 700;
    color: #656c9b;
    line-height: 18px
}

.block-details-list-hour__date span {
    font-size: .75rem;
    font-weight: 300;
    color: #b2b5cd;
    display: block
}

.block-details-list-hour__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 40px
}

@media screen and (min-width:20rem) {
    .block-details-list-hour__content {
        padding: 16px 10px
    }
}

@media screen and (min-width:64rem) {
    .block-details-list-hour__content {
        padding: 16px 40px
    }
}

.block-details-list-hour__title {
    min-width: 220px;
    max-width: 220px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-right: 60px
}

@media screen and (min-width:20rem) {
    .block-details-list-hour__title {
        min-width: 150px;
        max-width: 150px
    }
}

@media screen and (min-width:48rem) {
    .block-details-list-hour__title {
        min-width: 220px;
        max-width: 220px
    }
}

.block-details-list-hour__title .tag {
    margin-right: 10px
}

.block-details-list-hour__title p {
    padding-left: .5rem;
    font-size: .875rem;
    color: #020d38
}

.block-details-list-hour__desc {
    min-width: 350px;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: .75rem
}

.block-details-list-hour__infos {
    min-width: 200px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #7d83aa
}

.block-details-list-hour__infos i {
    font-size: 1.125rem;
    color: #b2b5cd
}

.block-details-list-hour__action {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%)
}

.block-details-list-hour__action i {
    font-size: 1.375rem
}

.block-table-header {
    padding: 0 32px;
    background: transparent
}

.block-table-header>* {
    width: 20%
}

.block-table-header__title {
    display: inline-block;
    font-size: .75rem;
    color: #8c91b4
}

.block-table-header__name>:first-child {
    height: 100%
}

.block-table-header__id {
    margin: 22px 22px 22px 10px
}

.block-table-header__id p {
    font-size: 1rem;
    color: #020d38
}

.block-table-header__id span {
    display: block;
    font-size: .75rem;
    color: #7d83aa;
    margin-top: 5px
}

.block-table-header__roles i {
    font-size: 1.25rem;
    color: #d3d5e4;
    margin: 0 4px
}

.block-table-header__roles i.active {
    color: #5150c4
}

.block-table-header__manager {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 22px -10px
}

.block-table-header__manager>* {
    margin: 0 10px
}

.block-table-header__manager i {
    font-size: 1.25rem;
    color: #f44436
}

.block-table-header__camp p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    color: #656c9b
}

.block-table-header__camp i {
    font-size: 1.25rem;
    color: #f44436;
    margin-right: 8px
}

.block-table-header__experience {
    font-size: .875rem;
    color: #656c9b
}

.block-table-header__more {
    width: 50px
}

.block-table-header__more i {
    margin-left: 10px;
    font-size: 1.25rem;
    color: #f44436
}

.block-table-header__toggle {
    width: 32px;
    border-left: 1px solid #d3d5e4
}

.block-table-header__toggle i {
    margin-left: 6px;
    font-size: 1.25rem;
    color: #d3d5e4
}

.block-table-knowledge {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px
}

.block-table-knowledge__th {
    min-width: 150px;
    margin: 0 20px;
    text-align: center
}

.block-table-knowledge__th:first-child {
    min-width: 20px;
    margin: 0;
    text-align: left
}

.block-table-knowledge__th:nth-child(2) {
    text-align: left
}

.block-table-knowledge__th div {
    display: flex;
    align-items: center;
    justify-content: center
}

.block-table-knowledge__th a {
    color: #b2b5cd;
    font-size: .875rem
}

.block-table-knowledge__th p {
    color: #b2b5cd;
    font-size: .875rem
}

.block-table-knowledge .filter,
.block-table-knowledge a {
    display: block
}

.block-table-knowledge .filter {
    margin-right: 8px
}

.block-table-knowledge .filter a i {
    font-size: 1rem
}

.block-table-knowledge .filter a:first-child {
    margin-bottom: -8px
}

.block-table-knowledge .filter a:last-child {
    margin-top: -8px
}

.block-table-knowledge .filter a:hover i {
    color: #020d38
}

.block-onboarding {
    display: flex;
    flex-direction: column
}

.block-onboarding__header h3 {
    font-size: 1.375rem;
    color: #7d83aa;
    font-weight: 700
}

.block-onboarding__header p {
    color: #7d83aa;
    margin-bottom: calc(.5em*3)
}

.block-onboarding__list {
    display: flex;
    flex-direction: column;
    margin: 35px 0
}

.block-onboarding__list h3 {
    color: #7d83aa;
    margin-bottom: calc(.5em*3);
    border-bottom: 1px solid #d3d5e4;
    padding-bottom: calc(.5em*1)
}

.block-onboarding__list .box-content__children {
    padding-left: 19px;
    margin-bottom: 12px
}

.block-onboarding__list .box-content {
    word-break: break-all;
    border: 1px solid #d3d5e4;
    margin: 0;
    height: 4rem
}

.block-onboarding__list .box-content:not(:last-child) {
    margin-bottom: 12px
}

.block-onboarding__list .box-content__infos .icon {
    font-size: 1.375rem;
    color: #f44436;
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.block-onboarding__list .box-content__infos p {
    position: relative;
    font-size: 1rem;
    color: #7d83aa
}

.block-onboarding__list .box-content__infos p span {
    display: block;
    font-size: .875rem;
    color: #7d83aa
}

.block-onboarding__list .box-content__participant {
    min-width: 4rem
}

.block-onboarding__list .box-content__participant .icon {
    font-size: 1.375rem;
    color: #b2b5cd;
    margin-right: calc(.5em*1)
}

.block-onboarding__list .box-content__participant p {
    font-size: .875rem;
    color: #7d83aa;
    white-space: nowrap
}

.block-onboarding__cards {
    margin-top: calc(.5em*3) calc(calc(.5em*2)*-1) 0 calc(calc(.5em*2)*-1)
}

.block-onboarding__cards .cards-container {
    margin: auto
}

.block-onboarding__cards .cards-container .card {
    width: 240px;
    margin: calc(.5em*1);
    padding: 0;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.block-onboarding__cards .cards-container .card--skip {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #535a8b;
    min-height: 300px;
    transition: all .3s ease
}

.block-onboarding__cards .cards-container .card--skip:hover {
    background-color: #414776;
    transition: all .3s ease
}

.block-onboarding__cards .cards-container .card--skip:hover .card__body {
    background-color: #414776;
    transition: all .3s ease
}

.block-onboarding__cards .cards-container .card--skip .card__body {
    background-color: #535a8b;
    color: #fff;
    font-weight: 700;
    transition: all .3s ease
}

.block-onboarding__cards .cards-container .card--skip--checked {
    background-color: #414776;
    transition: all .3s ease
}

.block-onboarding__cards .cards-container .card--skip--checked .card__body {
    background-color: #414776;
    transition: all .3s ease
}

.block-onboarding__cards .cards-container .card--onboarding {
    min-height: 300px
}

.block-onboarding__cards .cards-container .card--coach {
    min-height: 260px
}

.block-onboarding__cards .cards-container .card--coach .card__header .card__banner .tag__text {
    white-space: nowrap
}

.block-onboarding__cards .cards-container .card .card__header {
    height: 150px
}

.block-onboarding__cards .cards-container .card .card__header .card__image {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px
}

.block-onboarding__cards .cards-container .card .card__title {
    color: #020d38;
    margin-bottom: 0;
    font-size: 1rem
}

.block-onboarding__cards .cards-container .card .card__desc {
    margin-top: calc(.5em*1.5);
    font-size: .75rem
}

.block-onboarding__cards .cards-container .card .card__last-selection {
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;
    height: 25px;
    background-color: #656c9b;
    color: #fff;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center
}

.block-onboarding__cards .cards-container .card .card__radio {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: -9px;
    left: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #8290fd;
    box-sizing: border-box !important
}

.block-onboarding__cards .cards-container .card .card__radio:after {
    content: "\e935";
    font-family: myskillcamp;
    font-size: .875rem;
    color: #fff
}

.block-onboarding__cards .cards-container .card.card--checked {
    border: 2px solid #8290fd !important
}

.block-onboarding__cards .cards-container .card.card--checked .card__radio {
    display: flex
}

.block-onboarding__cards .cards-container .card__tags {
    justify-content: flex-end
}

.block-onboarding__cards .cards-container .card__widgets a {
    font-size: .875rem;
    color: #7d83aa
}

.block-onboarding__cards .cards-container .card__widgets a i {
    font-size: 1rem
}

.block-onboarding__cards .cards-container .card .card_time_text {
    padding-left: 5px
}

.block-onboarding .dynamic-table__container {
    padding: .2rem 1rem
}

.block-onboarding .dynamic-table__container.active .dynamic-table__body .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.block-onboarding .dynamic-table__container.active .dynamic-table__content {
    display: block
}

.block-onboarding .dynamic-table__body {
    display: block !important
}

.block-onboarding .dynamic-table__body .dynamic-table__row {
    margin: calc(.5em*1) 0
}

.block-onboarding .dynamic-table__body .cell-with-icon__container {
    display: flex;
    align-items: center;
    margin-right: .5rem
}

.block-onboarding .dynamic-table__body .cell-with-icon__container i {
    font-size: 1rem;
    margin: 0 .2rem 0 0
}

.block-onboarding .dynamic-table__body .cell-with-icon__container i:before {
    transition: all .3s ease
}

.block-onboarding .dynamic-table__body .cell-with-icon .icon-trash {
    font-size: 1rem !important;
    color: #8c91b4 !important
}

.block-onboarding .dynamic-table__body .cell-with-icon .icon-trash:hover:before {
    color: #535a8b
}

.block-onboarding .dynamic-table__body .cell-with-icon .color--orange {
    margin-right: .2rem
}

.block-onboarding .dynamic-table__content {
    display: none;
    width: 97%;
    margin: auto
}

.block-onboarding .dynamic-table-toggle {
    margin-top: 1rem
}

.block-onboarding .actions msc-button {
    margin-right: calc(.5em*3)
}

.card-highlight {
    display: flex;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.card-highlight [class^=label]:after {
    display: none !important
}

.card-highlight:hover {
    z-index: 5
}

.card-highlight .card__image {
    width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px
}

.card-highlight .card__header {
    position: relative;
    width: 50%
}

@media screen and (max-width:20rem) {
    .card-highlight .card__header {
        width: 100%;
        height: 150px !important
    }
}

.card-highlight .card__header .tag {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 5
}

.card-highlight .card__header .tag--date {
    left: 15px
}

@media (-ms-high-contrast:none) {

    .card-highlight .card__header .tag,
    .card-highlight .card__header ::-ms-backdrop {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5
    }
}

@media (-ms-high-contrast:none) {

    .card-highlight .card__header .tag--date,
    .card-highlight .card__header ::-ms-backdrop {
        left: 15px
    }
}

.card-highlight .card__content,
.card-highlight .card__header {
    padding: 0;
    height: auto
}

.card-highlight .card__content {
    position: relative;
    padding: 15px 25px 10px;
    background-color: #fff;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    height: 250px;
    width: 50%
}

@media screen and (max-width:20rem) {
    .card-highlight .card__content {
        width: 100%;
        height: 200px;
        border-radius: 0
    }
}

.card-highlight .card__desc {
    padding-bottom: 15px;
    max-height: 70px;
    overflow: hidden
}

.card-highlight .card__desc p {
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    min-height: 1.2em;
    max-height: 2.4em;
    color: #8c91b4;
    font-size: .875rem
}

.card-highlight .card__header {
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px
}

.card-highlight .card__footer {
    padding: 12px 0 0;
    position: absolute;
    width: calc(100% - 50px);
    bottom: 15px;
    display: flex;
    justify-content: flex-end
}

@media only screen and (max-width:768px) {
    .card-highlight .card__footer {
        flex-wrap: wrap
    }
}

.card-highlight .card__title {
    margin-top: 15px;
    margin-bottom: 15px
}

.card-highlight .card__brand>img {
    max-width: 120px
}

.card-highlight .card__timelapse {
    bottom: 3px
}

@media screen and (min-width:1024px) and (max-width:1280px) {
    .link.link-back {
        padding: 0px !important;
    }

    .card-highlight {
        overflow: hidden
    }

    .card-highlight .msc-card__skills {
        flex-wrap: wrap;
        margin: -3px -5px
    }

    .card-highlight .skill {
        margin-right: 5px;
        margin-bottom: 5px
    }

    .card-highlight .card__content {
        padding: 10px 5px 5px 15px
    }

    .card-highlight .card__desc {
        padding-bottom: 5px
    }

    .card-highlight .card__footer {
        width: calc(100% - 30px);
        bottom: -5px
    }
}

.cards-job__title {
    margin-top: 20px
}

.cards-job__desc {
    display: none
}

@media screen and (min-width:20rem) {
    .cards-job {
        background: #fff;
        box-shadow: 0 13px 24px 0 rgba(0, 0, 0, .03);
        transition: all .3s ease
    }

    .cards-job .cards-job__icon i {
        color: #fff;
        transition: all .3s ease
    }

    .cards-job .cards-job__desc {
        display: block;
        margin-top: 15px;
        color: #b2b5cd;
        font-size: .75rem;
        transition: all .3s ease
    }
}

@media screen and (min-width:48rem) {
    .cards-job {
        background: transparent;
        box-shadow: none
    }

    .cards-job .cards-job__icon i {
        background-color: transparent;
        color: #feb88a;
        transition: all .3s ease
    }

    .cards-job .cards-job__desc {
        opacity: 0;
        margin-top: 0;
        color: #b2b5cd;
        font-size: .75rem
    }
}

.cards-job:hover .cards-job__desc {
    opacity: 1;
    margin-top: 15px;
    color: #b2b5cd;
    font-size: .75rem;
    transition: all .3s ease
}

.card-camp {
    position: relative;
    width: 100%;
    cursor: pointer;
    background-color: #fff;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    transition: all .3s ease
}

.card-camp:hover {
    transform: scale(1.05);
    transition: all .3s ease
}

.card-camp__content .content__action {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    background-color: #ff6b70;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    transition: all .3s ease
}

.card-camp__content .content__action:hover {
    transform: scale(1.1);
    transition: all .3s ease
}

.card-camp__content .content__logo i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    border-radius: 50%;
    color: #fff;
    font-size: 1.25rem
}

.card-camp__content .content__data {
    display: flex;
    flex-direction: column;
    align-items: center
}

.card-camp__content .content__data p {
    text-align: center;
    margin: 10px 0;
    color: #414776;
    max-height: 1rem
}

@media screen and (min-width:20rem) {
    .card-camp__content .content__data p {
        max-height: none
    }
}

@media screen and (min-width:32rem) {
    .card-camp__content .content__data p {
        max-height: 1rem
    }
}

.card-camp__content .content__data .data__pictos {
    display: flex
}

.card-camp__content .content__data .data__pictos .data__members,
.card-camp__content .content__data .data__pictos .data__sessions {
    display: flex;
    align-items: center;
    margin: 0 15px
}

.card-camp__content .content__data .data__pictos .data__members p {
    font-size: .875rem;
    color: #ff6b70;
    padding: 0 0 0 5px
}

.card-camp__content .content__data .data__pictos .data__sessions p {
    font-size: .875rem;
    color: #ff6b70;
    padding: 0 0 0 5px
}

.card-camp__content .content__data .data__pictos .data__members i {
    color: #8c91b4
}

.card-camp__content .content__data .data__pictos .data__sessions i {
    color: #8c91b4
}

.card-camp__check {
    display: flex;
    justify-content: center;
    margin: 20px 0 0
}

.card-camp__radio {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #8290fd
}

.card-camp__radio:after {
    content: "\e935";
    font-family: myskillcamp;
    font-size: .875rem;
    color: #fff
}

.card-camp--checked {
    border: 1px solid #8290fd !important
}

.card-camp--no-effect {
    cursor: default
}

.card-camp--no-effect:hover {
    transform: scale(1)
}

.card-camp--flat .card-camp__content {
    display: flex
}

.card-camp--flat .card-camp__content .content__logo {
    margin: 0
}

.card-camp--flat .card-camp__content .content__data {
    align-items: flex-start;
    margin: 0 0 0 10px
}

.card-camp--flat .card-camp__content .content__data .data__pictos .data__members,
.card-camp--flat .card-camp__content .content__data p {
    margin: 0
}

.card-camp--flat .card-camp__check {
    display: inline-block
}

.card-camp--min {
    padding: 12px 8px 8px
}

.card-camp--min .card-camp__content .content__data {
    font-size: .75rem
}

.card-camp--min .card-camp__content .content__data>p {
    margin-bottom: 0
}

.card-camp--min .card-camp__content .content__data .data__members {
    color: #b2b5cd
}

.card-camp--min .card-camp__content .content__data .data__pictos {
    color: #b2b5cd
}

.card-camp--min .card-camp__content .content__data .data__members p {
    color: #656c9b
}

.card-camp--min .card-camp__content .content__data .data__pictos p {
    color: #656c9b
}

.card-group {
    background: #fff;
    border-radius: 4px;
    height: 270px;
    max-width: 220px;
    cursor: pointer;
    box-shadow: none;
    transition: all .25s ease
}

.card-group:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1)
}

.card-group.active {
    border: 2px solid #8290fd
}

.card-group__header {
    position: relative;
    height: 100px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.card-group__body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 100px)
}

.card-group__widgets {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.card-group__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -24px
}

.card-group__logo i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin: 0 auto;
    background-color: #feb88a;
    border-radius: 50%;
    color: #fff;
    font-size: 1.875rem
}

.card-group__avatar-group {
    margin: 16px 0
}

.card-group__title {
    text-align: center;
    font-size: 1rem;
    font-weight: 700
}

.card-group__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -8px
}

.card-group__icons p {
    margin: 0 8px;
    color: #7d83aa
}

.card-group__icons i {
    color: #feb88a
}

.card-staff {
    padding: 16px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.card-staff__content {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.modal {
    position: relative
}

.modal.active .modal__content,
.modal.active .modal__overlay {
    display: block
}

.modal__overlay {
    display: none;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(151, 155, 183, 0.5)
}

.modal__content {
    display: none;
    position: fixed;
    z-index: 500;
    top: 50%;
    left: calc(50% + 142.5px);
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .12);
    padding: 28px;
    min-height: 300px;
    max-width: 480px;
    width: 480px;
    transition: all .3s ease
}

@media screen and (min-width:20rem) {
    .modal__content {
        left: 50%;
        /*  width: 300px*/
    }
}

@media screen and (max-width:678rem) {
    .modal__content {
        left: 50%;
        width: 300px;
    }
}

@media screen and (min-width:48rem) {
    .modal__content {
        width: 480px
    }
}

@media screen and (min-width:80rem) {
    /*.modal__content {
        left: calc(50% + 142.5px)
    }*/
}

.modal__close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    transform: rotate(0);
    color: #b2b5cd;
    font-size: 1.125rem;
    transition: all .3s ease
}

.modal__close:hover {
    color: #7d83aa;
    transform: rotate(-90deg)
}

.modal__back {
    justify-content: flex-start;
    cursor: pointer;
    position: absolute;
    top: 16px;
    left: 16px
}

.modal__back,
.modal__title {
    display: flex;
    align-items: center
}

.modal__title {
    font-size: 1.125rem;
    color: #020d38;
    margin: 0 0 1rem
}

.modal__title i {
    font-size: 1.375rem;
    margin-right: 8px
}

.modal__body {
    font-size: .875rem;
    color: #7d83aa;
    margin-top: 16px
}

.modal__body--simplebar {
    height: 170px
}

.modal__body .modal__refused--manager {
    margin-bottom: 1rem
}

.modal__body .modal__refused--reason {
    margin-bottom: 1.5rem
}

.modal__body .textarea__content {
    margin: 1rem 0 0
}

.modal__granularity {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 8rem 1rem
}

.modal__granularity .select {
    position: relative;
    cursor: pointer;
    margin-left: calc(.5em*1);
    border: 0;
    padding: 0;
    height: auto
}

.modal__granularity .select:after,
.modal__granularity .select:before {
    display: none
}

.modal__granularity .select__placeholder {
    border: 1px solid #d3d5e4;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.modal__granularity .select__placeholder .icon:before {
    transform: rotate(0deg);
    transition: transform .3s ease
}

.modal__granularity .select__dropdown {
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    position: absolute;
    top: calc(100% + 8px);
    left: -100px;
    z-index: 10
}

.modal__granularity .select__dropdown .dropdown--simple {
    left: 100px;
    min-width: 150px
}

.modal__granularity .select.active .icon:before {
    transform: rotate(-180deg)
}

.modal__granularity .select.active .select__dropdown {
    opacity: 1;
    visibility: visible
}

.modal__contact {
    max-height: 225px;
    overflow-y: scroll
}

.modal__footer {
    position: absolute;
    bottom: 16px;
    right: 16px
}

.modal__buttons {
    margin: 0 -8px
}

.modal__buttons>* {
    margin: 0 8px
}

.modal__form .input {
    margin: 16px 0
}

.modal--center .modal__content,
.modal--delete .modal__content,
.modal--notify .modal__content,
.modal--success .modal__content,
.modal-with-picto .modal__content,
.modal.modal-form-group .modal__content {
    text-align: center
}

.modal--center .modal__title,
.modal--delete .modal__title,
.modal--notify .modal__title,
.modal--success .modal__title,
.modal-with-picto .modal__title,
.modal.modal-form-group .modal__title {
    display: block;
    text-align: center
}

.modal--center .modal__title i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    margin: 0 auto 24px;
    border-radius: 50%;
    background: #cef1e2;
    color: #6cd4a8
}

.modal--delete .modal__title i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    margin: 0 auto 24px;
    border-radius: 50%;
    background: #cef1e2;
    color: #6cd4a8
}

.modal--notify .modal__title i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    margin: 0 auto 24px;
    border-radius: 50%;
    background: #cef1e2;
    color: #6cd4a8
}

.modal--success .modal__title i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    margin: 0 auto 24px;
    border-radius: 50%;
    background: #cef1e2;
    color: #6cd4a8
}

.modal-with-picto .modal__title i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    margin: 0 auto 24px;
    border-radius: 50%;
    background: #cef1e2;
    color: #6cd4a8
}

.modal.modal-form-group .modal__title i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    margin: 0 auto 24px;
    border-radius: 50%;
    background: #cef1e2;
    color: #6cd4a8
}

.modal--center .modal__footer,
.modal--delete .modal__footer,
.modal--notify .modal__footer,
.modal--success .modal__footer,
.modal-with-picto .modal__footer,
.modal.modal-form-group .modal__footer {
    right: 50%;
    transform: translateX(50%)
}

.modal--delete .modal__title i {
    background: rgba(255, 205, 210, .2);
    color: #f44436
}

.modal--notify .modal__title i {
    background: rgba(81, 80, 196, .2);
    color: #5150c4
}

.modal-with-picto .modal__title i {
    background: rgba(81, 80, 196, .2);
    color: #5150c4
}

.modal.modal-form-group .modal__title i {
    background: rgba(81, 80, 196, .2);
    color: #5150c4
}

.modal-with-picto .modal__title i {
    font-size: 1.625rem
}

.modal-with-picto .modal__body p:not(:first-child) {
    margin: 1rem 0 0
}

.modal-with-picto .modal__buttons msc-button {
    min-width: 100px
}

.modal.modal-form-group .modal__body {
    padding-bottom: 22px
}

.modal.modal-form-group .modal__footer {
    position: relative;
    padding: 0;
    bottom: 0
}

.modal.modal-form-group .modal__participant {
    margin-bottom: 12px
}

.modal.modal-form-group .modal__buttons {
    text-align: right
}

.modal--large .modal__content {
    max-width: 600px;
    width: 600px
}

.modal--xlarge .modal__content {
    max-width: 1000px;
    width: 1000px
}

@media screen and (min-width:20rem) {
    .modal--xlarge .modal__content {
        max-width: 300px;
        width: 300px
    }
}

@media screen and (min-width:48rem) {
    .modal--xlarge .modal__content {
        max-width: 600px;
        width: 600px
    }
}

@media screen and (min-width:64rem) {
    .modal--xlarge .modal__content {
        max-width: 800px;
        width: 800px
    }
}

@media screen and (min-width:90rem) {
    .modal--xlarge .modal__content {
        max-width: 1000px;
        width: 1000px
    }
}

@media screen and (min-width:160rem) {
    .modal--xlarge .modal__content {
        max-width: 1500px;
        width: 1500px
    }
}

.modal--full-width .modal__content {
    height: calc(100vh - 120px);
    overflow: auto;
    text-align: left;
    top: 100px;
    transform: translateX(-50%)
}

@media screen and (min-width:20rem) {
    .modal--full-width .modal__content {
        max-width: calc(100vw - 50px);
        width: calc(100vw - 50px)
    }
}

@media screen and (min-width:80rem) {
    .modal--full-width .modal__content {
        max-width: calc(100vw - 350px);
        width: calc(100vw - 350px)
    }
}

.modal--lightbox .modal__content {
    background: none;
    box-shadow: none
}

.modal--lightbox .modal__content .modal__header .modal__close {
    top: 0;
    right: 0;
    color: #414776;
    font-size: 1.375rem
}

.modal--lightbox .modal__content .modal__lightbox {
    display: flex;
    justify-content: center;
    align-items: center
}

.modal--lightbox .modal__content .modal__lightbox .picture {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.modal--lightbox .modal__content .modal__lightbox .picture img {
    border-radius: 4px;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.modal--lightbox .modal__content .modal__lightbox .picture__desc {
    margin: 2rem 0;
    padding: 1rem;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.modal--lightbox .modal__content .modal__lightbox .picture__desc .picture__name {
    font-size: 1rem
}

.modal--lightbox .modal__content .modal__lightbox .picture__desc .picture__size {
    margin: .5rem 0 0;
    color: #b2b5cd;
    font-size: .75rem
}

.modal--full .modal__content {
    left: 50%
}

.modal--over .modal__overlay {
    z-index: 3000
}

.modal--over .modal__content {
    z-index: 4000
}

.modal.modal-delete-subscription p {
    margin: .5rem 0
}

.modal-on-demand .modal__body--simplebar {
    height: 250px
}

.modal-player-light-iframe {
    z-index: 2000
}

.modal-player-light-iframe .modal__body {
    overflow-y: scroll;
    margin: 0;
    height: calc(100vh - 80px)
}

.modal-player-light-iframe .modal__body iframe {
    width: 100%;
    height: 90vh
}

.modal-player-light-iframe .modal__body .modal__wrapper {
    width: 65%;
    margin: 1rem auto 0
}

.modal-player-light-iframe .modal__body__picture {
    display: flex;
    justify-content: center
}

.modal-player-light-iframe .modal__body__picture img {
    border: 1px solid #d3d5e4
}

.modal-player-light-iframe .modal__body__audio {
    display: flex;
    justify-content: center;
    margin: 2rem 0 0
}

.modal-player-light-iframe .modal__body__description {
    max-width: 80rem;
    margin: 1rem auto 0;
    padding: 1rem 1rem 3rem;
    border-top: 1px solid #d3d5e4
}

.modal-player-light-iframe .modal__body__description p {
    line-height: 1.25rem
}

.modal-player-light-iframe .modal__content {
    width: 100%;
    height: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    transform: none
}

.modal-player-light-iframe .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    padding: 0 2rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(10, 22, 70, .1)
}

.modal-player-light-iframe .modal__header h1 {
    font-size: 1.375rem;
    color: #656c9b;
    font-weight: 500
}

.modal-player-light-iframe .modal__header__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%
}

.modal-player-light-iframe .modal__header__actions--small {
    width: 20%
}

.modal-player-light-iframe .modal__header__actions .input-number,
.modal-player-light-iframe .modal__header__actions .select,
.modal-player-light-iframe .modal__header__actions msc-button {
    margin: 0 .5rem;
    height: 35px
}

.modal-player-light-iframe .modal__header__actions .select {
    min-width: 120px
}

.modal-player-light-iframe .modal__header__actions msc-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    padding: 2px 8px
}

.modal-player-light-iframe .modal__header__actions msc-button i {
    font-size: 1.375rem;
    margin: 0 .5rem 0 0
}

.modal-player-light-iframe .modal__header__actions .actions__time {
    display: flex;
    align-items: center;
    font-size: .875rem;
    color: #d3d5e4
}

.modal-player-light-iframe .modal__header__actions .actions__time i {
    font-size: 1.25rem;
    margin: 0 .5rem 0 0
}

.modal-player-light-iframe .modal__header__actions .actions__close {
    cursor: pointer
}

.modal-player-light-iframe .modal__header__actions .actions__close i {
    font-size: 1.375rem
}

.modal-player-light-iframe .modal__header__actions .actions__close i:before {
    color: #8c91b4;
    transform: rotate(0deg);
    transition: all .5s ease
}

.modal-player-light-iframe .modal__header__actions .actions__close:hover i:before {
    transform: rotate(90deg)
}

.navigation-tabs {
    position: relative;
    width: 100%;
    max-width: 485px;
    display: flex;
    flex-wrap: wrap
}

.navigation-tabs__item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    color: #7d83aa;
    padding: 16px 0;
    border-bottom: 1px solid #7d83aa
}

.navigation-tabs__item,
.navigation-tabs__item i {
    transition: color .2s cubic-bezier(.175, .885, .32, 2)
}

.navigation-tabs__item i {
    display: block;
    font-size: 1.25rem
}

.navigation-tabs__item.active {
    color: #5150c4;
    border-bottom: 1px solid #5150c4
}

.navigation-tabs__item:hover {
    color: #5150c4;
    border-bottom: 1px solid #5150c4
}

.logo-external,
.logo-msc,
.logo-msc__small,
.logo-msc__white {
    max-width: 100px;
    max-height: 30px
}

.slide-sessions-members__button {
    font-size: .625rem
}

.slide-sessions-members .slide__footer .slide__submit .footer-checkbox {
    padding-left: 2.5rem
}

.slide-sessions-members .slide__footer .slide__submit .footer-checkbox:last-child {
    margin-top: 1rem
}

.slide-sessions-members .dynamic-table__body {
    display: block !important
}

.slide-sessions-members .dynamic-table__body .cell-with-icon__container {
    display: flex;
    align-items: center;
    margin-right: .5rem
}

.slide-sessions-members .dynamic-table__body .cell-with-icon__container i {
    font-size: 1rem;
    margin: 0 .2rem 0 0
}

.slide-sessions-members .dynamic-table__body .cell-with-icon .icon-trash {
    font-size: 1rem !important;
    color: #8c91b4 !important
}

.slide-sessions-members .dynamic-table__body .cell-with-icon .icon-trash:hover:before {
    color: #535a8b
}

.slide-sessions-members .dynamic-table__body .cell-with-icon .color--orange {
    margin-right: .2rem
}

.slide-sessions-members .dynamic-table-toggle {
    margin-top: 1rem
}

.slide-sessions-members .dynamic-table-toggle__content {
    display: none;
    width: 97%;
    margin: auto
}

.slide-sessions-members .dynamic-table-toggle__container {
    padding: .5rem 1rem;
    background-color: rgba(211, 213, 228, .2)
}

.slide-sessions-members .dynamic-table-toggle__container.active .dynamic-table__body .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.slide-sessions-members .dynamic-table-toggle__container.active .dynamic-table-toggle__content {
    display: block
}

.slide-sessions-members .dynamic-table-toggle .dynamic-table__body .cell-with-icon__container {
    display: flex;
    align-items: center;
    margin-right: .5rem
}

.slide-sessions-members .dynamic-table-toggle .dynamic-table__body .cell-with-icon__container i {
    font-size: 1rem;
    margin: 0 .2rem 0 0
}

.slide-sessions-members .dynamic-table-toggle .dynamic-table__body .cell-with-icon__container i:before {
    transition: all .3s ease
}

.slide-sessions-members .dynamic-table-toggle .dynamic-table__body .cell-with-icon .icon-trash {
    font-size: 1rem !important;
    color: #8c91b4 !important
}

.slide-sessions-members .dynamic-table-toggle .dynamic-table__body .cell-with-icon .icon-trash:hover:before {
    color: #535a8b
}

.slide-sessions-members .dynamic-table-toggle .dynamic-table__body .cell-with-icon .color--orange {
    margin-right: .2rem
}

.slide-sessions-community .dynamic-table__body {
    display: block !important
}

.slide-sessions-community .load-more {
    margin: 0 !important
}

.slide-sessions-community .button-add {
    justify-content: flex-end
}

.slide-sessions-members-journey__button {
    font-size: .65rem
}

.slide-sessions-members-journey .slide__footer {
    justify-content: space-between
}

.slide-sessions-members-journey .slide__footer .slide__submit .footer-checkbox {
    padding-left: 2.5rem
}

.slide-sessions-members-journey .slide__footer .text-error {
    max-width: 50%
}

.slide-sessions-members-journey .dynamic-table__container {
    padding: .5rem 1rem;
    background-color: rgba(211, 213, 228, .2)
}

.slide-sessions-members-journey .dynamic-table__container.active .dynamic-table__body .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.slide-sessions-members-journey .dynamic-table__container.active .dynamic-table__content {
    display: block
}

.slide-sessions-members-journey .dynamic-table__body {
    display: block !important
}

.slide-sessions-members-journey .dynamic-table__body .cell-with-icon__container {
    display: flex;
    align-items: center;
    margin-right: .5rem
}

.slide-sessions-members-journey .dynamic-table__body .cell-with-icon__container i {
    font-size: 1rem;
    margin: 0 .2rem 0 0
}

.slide-sessions-members-journey .dynamic-table__body .cell-with-icon__container i:before {
    transition: all .3s ease
}

.slide-sessions-members-journey .dynamic-table__body .cell-with-icon .icon-trash {
    font-size: 1rem !important;
    color: #8c91b4 !important
}

.slide-sessions-members-journey .dynamic-table__body .cell-with-icon .icon-trash:hover:before {
    color: #535a8b
}

.slide-sessions-members-journey .dynamic-table__body .cell-with-icon .color--orange {
    margin-right: .2rem
}

.slide-sessions-members-journey .dynamic-table__content {
    display: none;
    width: 97%;
    margin: auto
}

.slide-sessions-members-journey .dynamic-table-toggle {
    margin-top: 1rem
}

.slide-sessions-members-journey .load-more {
    margin: 0 !important
}

.slide-community-resource h2 {
    margin: 0 0 1rem
}

.slide-community-resource .add-resource {
    display: flex;
    justify-content: space-between
}

.slide-community-resource .add-resource msc-button {
    width: 100px;
    color: #fff
}

.slide-community-resource .add-resource input {
    width: 80%
}

.slide-community-resource .message-caution {
    color: #ffa2a2;
    margin: 1rem 0
}

.slide-aside-on-demand__title {
    display: flex
}

.slide-aside-on-demand__subtitle {
    margin-left: 1rem
}

.slide-aside-on-demand .dynamic-table__body {
    display: block !important
}

.slide-aside-on-demand .dynamic-table__body__more {
    display: flex;
    justify-content: center
}

.slide-community-settings h2 {
    display: block;
    color: #b2b5cd;
    margin: 0 0 .5rem;
    font-size: .875rem
}

.slide-community-settings .slide__content {
    padding-right: 1em
}

.slide-community-settings .community-name {
    padding: 1rem 0
}

.slide-community-settings .toggle-choice {
    margin: 1rem 0;
    display: flex;
    width: 100%
}

.slide-community-settings .toggle-choice>* {
    width: 50%
}

.slide-community-settings .image {
    padding: .5rem 0
}

.slide-community-settings .image img {
    max-width: 100%
}

.slide-community-settings .media__avatar .input-upload-media__container {
    width: 10em;
    height: 10em
}

.slide-community-settings .media__avatar .input-image-loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.slide-community-settings .input-upload-media__desc {
    color: #b2b5cd;
    font-size: .875rem
}

.slide-community-settings .input-upload-media__label {
    color: #b2b5cd;
    font-size: .875rem
}

.slide-community-settings .input-upload-media__label {
    margin: 0 0 .5rem
}

.slide-community-settings .input-upload-media__desc {
    color: #7d83aa;
    margin-bottom: 4px
}

.slide-community-settings .input-upload-media__container {
    position: relative;
    width: 100%;
    height: 10em;
    border-radius: 4px;
    border: 2px solid #d3d5e4;
    padding: 20px;
    background-color: #fff
}

.slide-community-settings .input-upload-media__container:hover .input-upload-media__buttons {
    background-color: #5360bf;
    transform: scale(1.025);
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.slide-community-settings .input-upload-media__image {
    width: 100%;
    height: 100%;
    opacity: .6;
    background-size: cover;
    background-position: 50%
}

.slide-community-settings .input-upload-media__buttons {
    cursor: pointer;
    position: absolute;
    right: -16px;
    bottom: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    font-size: 1.625rem;
    border-radius: 50%;
    background-color: #6471d0;
    color: #fff;
    transform: scale(1);
    box-shadow: none;
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.slide-community-settings .community-message {
    margin-top: 2rem
}

.slide-community-course .box-add-participant {
    padding: 12px 18px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    color: #b2b5cd;
    cursor: pointer;
    transform: scale(1);
    box-shadow: none;
    transition: .2s cubic-bezier(.175, .885, .32, 2)
}

.slide-community-course .box-add-participant .tag {
    margin-right: .5rem
}

.slide-community-course .box-add-participant .profile__name {
    margin-bottom: 0
}

.slide-community-course .slide__footer .slide__submit .footer-checkbox {
    padding-left: 42px
}

.slide-community-course .content-camps__list span.separator {
    margin: 0 .5rem
}

.slide {
    position: fixed;
    top: 80px;
    left: -400px;
    background: #fff;
    height: 100%;
    z-index: 500;
    transition: all .5s ease;
    width: 100%
}

@media screen and (min-width:64rem) {
    .slide {
        width: 645px
    }
}

.slide.active {
    left: 285px;
    border: none;
    border-radius: 0;
    transition: all .5s ease
}

@media screen and (min-width:20rem) {
    .slide.active {
        left: 0
    }
}

@media screen and (min-width:80rem) {
    .slide.active {
        left: 285px
    }
}

.slide--medium {
    width: 100%
}

@media screen and (min-width:64rem) {
    .slide--medium {
        width: 565px
    }
}

.slide--medium .slide__footer {
    width: 100%
}

@media screen and (min-width:64rem) {
    .slide--medium .slide__footer {
        width: 565px
    }
}

.slide--large {
    width: 100%
}

@media screen and (min-width:64rem) {
    .slide--large {
        width: 765px
    }
}

.slide--large .slide__footer {
    width: 100%
}

@media screen and (min-width:64rem) {
    .slide--large .slide__footer {
        width: 765px
    }
}

.slide--right {
    right: -650px;
    left: inherit;
    padding: 30px 40px
}

.slide--right.active {
    left: inherit;
    right: 0
}

.slide--right .slide__footer {
    margin: 0 -40px
}

.slide--small {
    max-width: 100%
}

@media screen and (min-width:64rem) {
    .slide--small {
        max-width: 450px
    }
}

.slide--small .slide__footer {
    width: 100% !important
}

@media screen and (min-width:64rem) {
    .slide--small .slide__footer {
        width: 450px !important
    }
}

.slide__alert {
    position: absolute;
    top: 0;
    background-color: #e9eaf1;
    width: 100%;
    height: 100px;
    z-index: 100
}

.slide__alert__message {
    margin: 1rem 0 0;
    text-align: center
}

.slide__alert__actions {
    margin: .5rem 0 0;
    display: flex;
    justify-content: center
}

.slide__alert__actions a:first-child {
    margin: 0 1rem 0 0
}

.slide__alert__actions a:last-child {
    margin: 0 0 0 1rem
}

.slide__ariane {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    margin: 0 auto
}

.slide__ariane:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    z-index: 1;
    background: #fff
}

.slide__ariane__step {
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 5;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    cursor: pointer
}

.slide__ariane__step--current {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.slide__ariane__step--current:after {
    content: "";
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: center center;
    transform: scale(.5)
}

.slide__ariane__step--success:after {
    content: "\e935";
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: myskillcamp;
    color: #fff;
    font-size: .75rem;
    transform: translate(-50%, -50%)
}

.slide__ariane__step:not(:last-child) {
    margin-right: 100px
}

.slide__ariane__step:not(:last-child):before {
    content: "";
    position: absolute;
    top: calc(50% - 2px);
    left: calc(100% + 2px);
    z-index: -1;
    display: block;
    width: 100px;
    height: 4px;
    background: #d3d5e4
}

.slide__ariane__step.disabled {
    cursor: auto;
    pointer-events: none
}

.slide__ariane__step .tooltip {
    z-index: 100;
    width: 20px;
    height: 20px
}

.slide__close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #b2b5cd;
    font-size: 1.25rem;
    cursor: pointer;
    transform: rotate(0);
    transition: all .3s ease;
    z-index: 10
}

.slide__close:hover {
    color: #7d83aa;
    transform: scale(1.15) rotate(90deg)
}

.slide__actions {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.slide__title {
    position: relative;
    font-size: 1.125rem;
    color: #020d38;
    margin-bottom: 30px
}

.slide__title h2 {
    font-size: 1.25rem;
    font-weight: lighter;
    color: #020d38
}

.slide__title h3 {
    font-size: 1.25rem;
    font-weight: lighter;
    color: #020d38
}

.slide__title p {
    font-size: 1.375rem;
    font-weight: lighter;
    color: #7d83aa;
    line-height: 140%;
    text-align: center;
    margin-top: 30px
}

.slide__title .link {
    position: absolute;
    left: -25px;
    right: 0
}

.slide__title .link i {
    color: #b2b5cd;
    font-size: 1.375rem;
    transform: scale(1);
    transition: .2s cubic-bezier(.175, .885, .32, 2)
}

.slide__title .link:hover i {
    color: #7d83aa;
    transform: scale(1.15)
}

.slide__title--thin {
    margin-bottom: 10px
}

.slide__title--icon {
    font-size: 1.375rem;
    font-weight: 300 !important
}

.slide__title--icon h2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #b2b5cd;
    margin-left: -40px;
    cursor: pointer
}

.slide__title--icon h2 .link {
    display: flex;
    justify-content: center;
    width: 40px;
    transform: scale(1);
    transition: transform .2s cubic-bezier(.175, .885, .32, 2)
}

.slide__title--icon h2 .link:hover,
.slide__title--icon h2:hover .link {
    transform: scale(1.15)
}

.slide__title--icon h3 {
    color: #7d83aa;
    margin: 8px 0
}

.slide__title--icon .desc {
    color: #7d83aa;
    margin: 16px 0;
    text-align: center
}

.slide__subtitle {
    margin-bottom: 12px
}

.slide__search {
    margin: 12px 0
}

.slide__content {
    width: 100%;
    height: calc(100% - 170px)
}

.slide__content--large-footer {
    height: calc(100% - 230px)
}

.slide__content__wrapper {
    height: 100%;
    overflow-y: scroll
}

.slide__body--separator {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgba(211, 213, 228, .25)
}

.slide__body--scroll-on {
    height: calc(100vh - 380px)
}

.slide__body--scroll {
    max-height: 700px
}

@media screen and (min-width:20rem) {
    .slide__body--scroll {
        max-height: 350px
    }
}

@media screen and (min-width:64rem) {
    .slide__body--scroll {
        max-height: 400px
    }
}

@media screen and (min-width:80rem) {
    .slide__body--scroll {
        max-height: 500px
    }
}

@media screen and (min-width:90rem) {
    .slide__body--scroll {
        max-height: 650px
    }
}

.slide__form .slide__input {
    margin: 12px 0 0
}

.slide__form .slide__label {
    display: block;
    color: #b2b5cd;
    margin-bottom: 4px;
    font-size: .875rem
}

.slide__tab {
    border-bottom: 1px solid #d3d5e4;
    padding: 26px 38px
}

.slide__tab:last-child {
    border-bottom: none
}

.slide__tab span {
    display: block;
    font-size: .75rem;
    color: #020d38;
    margin-bottom: 4px
}

.slide__load-more,
.slide__more {
    margin: 12px 0;
    text-align: center
}

.slide__buttons {
    display: flex;
    align-items: center;
    margin: 0 -4px
}

.slide__buttons msc-button {
    margin: 0 4px
}

.slide__settings {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin: 20px 0 20px 16px;
    border-radius: 4px;
    background: rgba(211, 213, 228, .4);
    color: #7c6cde;
    box-shadow: none;
    transition: all .3s ease
}

.slide__settings i {
    font-size: 1.375rem
}

.slide__settings:hover {
    transform: scale(1.1)
}

.slide__skills-list {
    margin: 32px -8px
}

.slide__skills-list>* {
    margin: 8px
}

.slide__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    background-color: rgba(211, 213, 228, .5);
    z-index: 100
}

@media screen and (min-width:64rem) {
    .slide__footer {
        width: 645px
    }
}

.slide__footer .button-close {
    margin: 0 20px
}

.slide__footer .slide__summary {
    width: 100%;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #d3d5e4
}

.slide__footer .slide__submit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.slide__footer--large {
    padding: 30px
}

@media screen and (min-width:64rem) {
    .slide__footer--large {
        padding: 40px
    }
}

.slide__footer--light {
    background-color: rgba(211, 213, 228, .1)
}

.slide__select {
    overflow-y: scroll;
    height: calc(100vh - 420px)
}

.slide__section {
    margin: 24px 0
}

.slide__section:last-child {
    margin-bottom: 0
}

.box-attendance {
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(10, 22, 70, .1);
    cursor: pointer;
    margin: 12px 0;
    padding: 12px;
    transform: scale(1);
    transition: .2s cubic-bezier(.175, .885, .32, 2)
}

.box-attendance:hover .box-attendance__action i {
    color: #fff
}

.box-attendance__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 50px
}

.box-attendance__date {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.box-attendance__time p {
    margin: 0 22px;
    font-size: .75rem;
    color: #ffa2a2
}

.box-attendance__infos ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -10px
}

.box-attendance__infos ul li {
    margin: 0 10px;
    font-size: .75rem;
    color: #b2b5cd
}

.box-attendance__infos ul li i {
    font-size: .875rem;
    margin-right: 6px
}

.box-attendance__infos ul li.notification {
    border-radius: 9px;
    padding: 1px 6px;
    text-align: center;
    background: rgba(255, 0, 0, .6)
}

.box-attendance__infos ul li.notification i {
    color: #fff;
    font-size: .75rem;
    margin: 0 auto
}

.box-attendance__action {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 24px;
    background: rgba(211, 213, 228, .4);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: all .3s ease
}

.box-attendance__action i {
    transform: scale(1);
    color: #b2b5cd;
    font-size: 1.375rem;
    transition: all .3s ease
}

.box-attendance__action i:hover {
    transform: scale(1.5)
}

.box-attendance-check-all {
    background-color: rgba(211, 213, 228, .4);
    box-shadow: none;
    cursor: default;
    margin: 12px 0;
    padding: 12px;
    transition: none
}

.box-attendance-check-all:hover {
    transform: scale(1)
}

.box-attendance-check-all .box-attendance__content {
    padding-left: 12px;
    padding-right: 60px
}

.box-attendance-check-all .box-attendance__infos {
    margin-left: -32px
}

.box-attendance-user {
    padding: 12px 12px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(211, 213, 228, .25)
}

.box-attendance-user:last-child {
    border-bottom: none
}

.box-attendance-user__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 60px
}

.box-attendance-user__avatar {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.box-attendance-user__infos {
    margin: -5px 0 -5px 24px
}

.box-attendance-user__infos p {
    margin: 5px 0;
    font-weight: 600;
    color: #020d38
}

.box-attendance-user__infos .status {
    padding: 2px 5px;
    border-radius: 8px;
    background-color: rgba(255, 0, 0, .6);
    color: #fff;
    font-size: .75rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.box-attendance-user__infos .status--confirmed {
    padding: 2px 0;
    border-radius: 0;
    background-color: transparent;
    color: #d3d5e4
}

.box-params {
    padding: 12px 18px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    border: 1px solid #b2b5cd;
    color: #b2b5cd;
    cursor: pointer;
    transform: scale(1);
    box-shadow: none;
    transition: .2s cubic-bezier(.175, .885, .32, 2)
}

.box-params i {
    font-size: 1.375rem;
    margin-right: 18px
}

.box-params p {
    font-size: 1.25rem
}

.slide.slide-params .slide__fieldset {
    margin: calc(.5em*3.5) 0
}

.slide.slide-params .slide__fieldset .title {
    display: block;
    width: 100%;
    margin-bottom: calc(.5em*3);
    padding-bottom: calc(.5em*3);
    border-bottom: 1px solid #d3d5e4
}

.slide.slide-params .slide__radio {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: calc(.5em*3) calc(calc(.5em*3)*-1)
}

.slide.slide-params .slide__radio>* {
    margin: 0 calc(.5em*3)
}

.input-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 240px;
    height: 180px;
    font-size: .75rem;
    color: #7d83aa;
    border: 1px dotted #d3d5e4
}

.input-image__content {
    text-align: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative
}

.input-image__content p {
    margin-bottom: calc(.5em*3)
}

.input-image__content .close {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px
}

.input-image i {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.375rem;
    border-radius: 50%;
    color: #fff;
    margin: 0 auto;
    transform: scale(1) rotate(180deg);
    transition: .2s ease
}

.input-image i:hover {
    transform: scale(1.05) rotate(180deg)
}

.slide-community-members .slide__footer .slide__submit .footer-checkbox {
    padding-left: 2.5rem
}

@media screen and (max-width:64rem) {
    .container-applications {
        margin: 2rem 0 0
    }
}

.container-applications .content-application__title {
    color: #020d38;
    font-size: 1.25rem;
    font-weight: 300;
    margin: 0 0 20px
}

.container-applications__section {
    margin-left: 0;
    margin-bottom: 8px
}

.container-applications__title {
    margin-bottom: 24px
}

.container-applications__search {
    margin-bottom: 24px;
    width: 100%
}

@media screen and (min-width:64rem) {
    .container-applications__search {
        width: calc(33.33333% - 12px)
    }
}

.container-applications__list {
    margin: 0;
    display: block;
    flex-wrap: wrap
}

@media screen and (min-width:64rem) {
    .container-applications__list {
        display: flex;
        margin: 0 -16px
    }
}

.container-applications__list--large {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: -12px
}

@media screen and (min-width:64rem) {
    .container-applications__list--large {
        justify-content: flex-start
    }
}

.container-applications__list--large .container-applications__item {
    margin: 10px 0 !important;
    width: calc(100% - 24px)
}

@media screen and (min-width:64rem) {
    .container-applications__list--large .container-applications__item {
        margin: 12px !important
    }
}

.container-applications__item {
    margin: 12px;
    min-width: calc(100% - 16px)
}

@media screen and (min-width:64rem) {
    .container-applications__item {
        min-width: calc(50% - 32px)
    }
}

@media screen and (min-width:90rem) {
    .container-applications__item {
        min-width: calc(20% - 32px)
    }
}

@media (-ms-high-contrast:none) {

    .container-applications .container-applications__item,
    .container-applications ::-ms-backdrop {
        margin-left: 50px
    }
}

.add-application .container-applications__item {
    margin: 12px;
    min-width: calc(100% - 32px)
}

@media screen and (min-width:64rem) {
    .add-application .container-applications__item {
        min-width: calc(50% - 32px)
    }
}

@media screen and (min-width:90rem) {
    .add-application .container-applications__item {
        min-width: calc(20% - 32px)
    }
}

.content-applications .container-applications__title {
    margin-bottom: 24px
}

.content-applications .container-applications__list {
    align-items: stretch
}

@media (-ms-high-contrast:none) {

    .content-applications .container-applications__item,
    .content-applications ::-ms-backdrop {
        margin-left: 50px
    }
}

.provider-dynamic-table__picture {
    width: 150px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.login.login-company {
    position: relative;
    padding-top: 50px;
    height: 100vh
}

.login__title {
    font-size: 1.875rem;
    color: #000;
    margin-top: 24px;
    margin-bottom: 48px;
    text-align: center
}

.login__logout {
    position: absolute;
    top: 40px;
    right: 40px
}

.login__search {
    width: 300px;
    margin: 1rem auto 3rem
}

.login .job {
    margin-top: 100px
}

.login .job__list {
    display: flex;
    align-items: center;
    justify-content: center
}

.login .job__list .job__item {
    margin: 0 8px;
    min-width: 295px;
    min-height: 195px
}

@media screen and (min-width:20rem) {
    .login .job__list .job__item {
        margin: .5rem auto
    }
}

@media screen and (min-width:48rem) {
    .login .job__list .job__item {
        margin: 0 8px
    }
}

.maintenance {
    height: 100vh;
    width: 100%;
    position: relative
}

.maintenance__content {
    width: 100%;
    height: auto;
    padding: 40px 0 80px;
    display: flex;
    align-items: flex-start;
    justify-content: center
}

@media screen and (min-width:48rem) {
    .maintenance__content {
        width: 40%;
        height: 100vh;
        padding: 0;
        align-items: center
    }
}

.maintenance__content .box {
    position: relative;
    padding: 25px 30px;
    background-color: #fff;
    border-radius: 4px;
    max-width: 300px
}

@media screen and (min-width:80rem) {
    .maintenance__content .box {
        max-width: 360px
    }
}

.maintenance__content .box__flag {
    position: absolute;
    top: 10px;
    right: 0
}

.maintenance__content .box__icon {
    width: 30px;
    height: 30px
}

.maintenance__content .box__message h1 {
    margin: 1rem 0;
    font-weight: 600;
    font-size: 1.25rem
}

.maintenance__content .box__message h2 {
    color: rgba(140, 145, 180, .5);
    font-size: 1rem
}

.maintenance__illus {
    position: absolute;
    right: -20px;
    transform: none;
    max-width: 80%;
    margin-top: -100px
}

.maintenance__illus img {
    width: 100%;
    height: auto
}

@media screen and (max-width:32rem) {
    .maintenance__illus {
        position: relative;
        right: auto;
        max-width: 100%;
        margin-top: -60px
    }
}

@media screen and (min-width:48rem) {
    .maintenance__illus {
        bottom: 50%;
        right: 0;
        transform: translateY(50%);
        width: 62%
    }
}

@media screen and (min-width:80rem) {
    .maintenance__illus {
        bottom: 50%;
        right: 0;
        transform: translateY(50%);
        width: 50%
    }
}

.login {
    height: 100vh;
    width: 100%;
    padding-top: 60px;
    position: relative
}

.login--background {
    background-image: url(https://static.myskillcamp.com/lxp/images/login-bg-img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 50vh;
    background-color: #f9f9f9;
    z-index: 1
}

.login--background:after {
    content: "";
    width: 100%;
    height: 50vh;
    background: linear-gradient(var(--v2-color-primary-200), var(--v2-color-primary-600));
    opacity: .9;
    position: absolute;
    top: 50vh;
    left: 0;
    z-index: -1
}

.login__header {
    position: relative;
    max-width: 500px;
    margin: 64px auto;
    text-align: center
}

.login__header .login__logo {
    display: flex;
    justify-content: center
}

.login__header .login__logo__container {
    width: 100%;
    height: 150px;
    max-width: 100%;
    max-height: 150px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain
}

@media screen and (max-width:20rem) {
    .login__header .login__logo__container {
        height: 80px;
        max-height: 80px
    }
}

.login__flag {
    position: absolute;
    top: 2rem;
    right: 2rem
}

.login__flag .dropdown-flag {
    width: auto !important;
    font-size: .875rem
}

.login__flag .dropdown-flag__content,
.login__flag .dropdown-flag__selected {
    width: 88px
}

.login__flag .dropdown-flag__content img {
    width: 36px
}

.login__custom {
    max-width: 500px;
    margin: 0 auto 1.5rem;
    background: #fff;
    border-radius: 30px;
    /*border-radius: 4px;*/
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    position: relative
}

.login__custom__content {
    margin: 15px 0 0;
    font-size: .875rem;
    color: #656c9b;
    max-height: 150px;
    overflow: hidden
}

.login__custom__content .froala-view {
    padding: 24px
}

.login__custom__content .froala-view p {
    word-break: break-word
}

.login__custom__more {
    margin: 1rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(211, 213, 228, .3);
    cursor: pointer
}

.login__custom__more.active i:before {
    transform: rotate(-180deg);
    transition: all .3s ease
}

.login__custom__more:hover i {
    color: #656c9b;
    transition: all .3s ease
}

.login__custom__more i {
    font-size: 1.25rem;
    color: #8c91b4
}

.login__custom__more i,
.login__custom__more i:before {
    transition: all .3s ease
}

.login__body {
    max-width: 500px;
    margin: 0 auto
}

.login__body--corporate {
    max-width: 900px !important
}

.reset-password .logo-link {
    display: block;
    width: 180px;
    height: 80px;
    margin: 0 auto
}

.reset-password .logo-link img {
    max-width: 100%;
    max-height: 80px;
    height: auto;
    width: auto;
    vertical-align: top
}

.reset-password .reset__form {
    margin-top: 2rem;
    text-align: left
}

.reset-password .slide__input:not(:first-child) {
    margin-top: 1rem
}

.reset-password .bullet-success {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #49e2af;
    color: #fff;
    font-size: 1.875rem;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    margin: 0 auto 25px
}

.reset-password .reset__content {
    text-align: center
}

.reset-password .reset__content h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #261e6d
}

.reset-password .reset__content p {
    font-size: .875rem;
    color: #d3d5e4;
    line-height: 20px
}

.reset-password .form__submit {
    display: flex;
    justify-content: flex-end
}

.reset-password .invalid-feedback {
    text-align: left;
    margin-top: 2rem;
    line-height: 1rem;
    font-size: .875rem
}

.reset-password .invalid-feedback .active {
    color: #ffa2a2 !important
}

.reset-password .invalid-feedback .valid {
    color: #49e2af !important
}

.login--signup .login__header {
    margin: 0 auto 32px
}

.login--signup .login__body {
    max-width: 1000px
}

.login--signup .login__body .signup__form {
    max-width: 550px;
    margin: 0 auto;
    padding: 50px 20px
}

.login--signup .login__body .signup__form .form__item {
    margin: 0 0 20px
}

.login--signup .login__body .signup__form .form__item label {
    color: #b2b5cd
}

.login--signup .login__body .signup__form .form__item--half {
    display: flex;
    justify-content: space-between;
    margin: 0 -12px 20px
}

.login--signup .login__body .signup__form .form__item--half>div {
    width: 50%;
    margin: 0 12px
}

@media screen and (min-width:20rem) {
    .login--signup .login__body .signup__form .form__item--half {
        margin: 0;
        flex-direction: column
    }

    .login--signup .login__body .signup__form .form__item--half>div {
        width: 100%;
        margin: 0 0 20px
    }
}

@media screen and (min-width:48rem) {
    .login--signup .login__body .signup__form .form__item--half {
        margin: 0 -12px 20px;
        flex-direction: row
    }

    .login--signup .login__body .signup__form .form__item--half>div {
        width: 50%;
        margin: 0 12px
    }
}

.login--signup .login__body .signup__form .form__item--privacy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem
}

.login--signup .login__body .signup__form .form__item--privacy .link-privacy {
    color: #f9aa81;
    transition: all .3s ease
}

.login--signup .login__body .signup__form .form__item--privacy .link-privacy:hover {
    color: #ed8371;
    transition: all .3s ease
}

.login--signup .login__body .signup__form .form__item__content {
    display: flex
}

.dashboard__header {
    min-height: 360px;
    display: flex;
    align-items: stretch
}

@media screen and (min-width:20rem) {
    .dashboard__header {
        flex-direction: column
    }
}

@media screen and (min-width:48rem) {
    .dashboard__header {
        flex-direction: row
    }
}

.dashboard__recent {
    display: flex
}

.dashboard__recent .col-card:first-child {
    margin-left: 0
}

@media screen and (min-width:20rem) {
    .dashboard__recent {
        flex-direction: column
    }

    .dashboard__recent .col-card {
        margin-left: 0;
        margin-right: 0
    }

    .dashboard__recent .col-card.col-xxs-12 {
        width: 100%
    }
}

@media screen and (min-width:48rem) {
    .dashboard__recent {
        flex-direction: row
    }
}

@media screen and (min-width:64rem) {
    .dashboard__recent .col-card {
        margin: 12px
    }

    .dashboard__recent .col-card.col-xxs-12 {
        width: calc(50% - 24px)
    }
}

.dashboard__contact .block-contact {
    height: calc(100% - 24px);
    margin: 12px 0 0
}

.dashboard__contact .block-contact .empty__state {
    width: auto
}

.dashboard__contact .block-contact .empty__state img {
    width: 100%
}

.dashboard__stats {
    display: flex
}

@media screen and (min-width:20rem) {
    .dashboard__stats {
        flex-direction: column
    }
}

@media screen and (min-width:48rem) {
    .dashboard__stats {
        flex-direction: row
    }
}

.dashboard__resources {
    background: #fff;
    border-radius: 4px
}

.dashboard__resources .empty__state {
    width: auto
}

.dashboard .content__title {
    color: #000;
    margin: 0 0 15px
}

.dashboard .content__title--recent {
    margin: 0 0 0 12px
}

.dashboard .content-dashboard__timeline {
    margin-top: 25px
}

@media (-ms-high-contrast:none) {

    .dashboard__header,
    ::-ms-backdrop {
        min-height: auto !important
    }
}

.container-dashboard {
    position: relative
}

.container-dashboard__banner {
    position: absolute;
    top: 0;
    left: 285px;
    width: calc(100% - 285px);
    height: 500px
}

.container-dashboard__banner:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(315deg, #17105a, #feb88a);
    opacity: .9
}

.container-dashboard__banner .banner__picture {
    width: 100%;
    height: 500px;
    background-image: url(https://mscprod.blob.core.windows.net/production/image/raw/b48c227a-d9a9-59c1-8bd1-77070f3056a3.png?size=raw);
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat
}

.container-dashboard__content {
    position: absolute;
    padding: 0 40px 0 20px
}

.container-dashboard__content .content__top {
    margin: 0 0 50px
}

.container-dashboard__content .content__top-data {
    color: #fff
}

.container-dashboard__content .content__top-data h1 {
    color: #fff;
    font-size: 1.25rem
}

.container-dashboard__content .content__top-data p {
    margin: 30px 0 0
}

.container-dashboard__content .content__top-data .data-item {
    display: flex;
    justify-content: space-between
}

.container-dashboard__content .content__top-data .data-item .data__feed {
    display: flex;
    align-items: center
}

.container-dashboard__content .content__top-data .data-item .data__feed span {
    margin: 0 10px;
    font-size: .75rem
}

.container-dashboard__content .content__top-data .data-item .data__action {
    margin: 50px 0 0
}

.container-dashboard__content .content__top-data .data-item .data__stats {
    display: flex;
    justify-content: space-between;
    width: 35%
}

.container-dashboard__content .content__top-data .data-item .data__stats .stats-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(2, 13, 56, .1);
    padding: 10px;
    color: #fff;
    border-radius: 6px
}

.container-dashboard__content .content__top-data .data-item .data__stats .stats-item__title {
    font-size: .75rem;
    text-align: center
}

.container-dashboard__content .content__top-data .data-item .data__stats .stats-item__data {
    font-size: 1.375rem;
    text-align: center
}

.container-dashboard__content .content__top-data .data-item .data__stats .stats-item__data i {
    color: #f9aa81
}

.container-dashboard__content .content__top-data .data-item .data__stats .stats-item__data span {
    margin: 0 0 0 10px
}

.container-dashboard__content .content__middle {
    display: flex;
    justify-content: space-between
}

.container-dashboard__content .content__bottom {
    margin: 40px 0 0
}

.container-dashboard__content .content__bottom__title {
    margin: 0 0 20px;
    display: flex;
    align-items: center
}

.container-dashboard__content .content__bottom__title .title-item {
    margin: 0 10px 0 0;
    font-size: 1.125rem
}

.content-history__action {
    text-align: right;
    margin-bottom: 16px
}

.content-history__item {
    margin-bottom: 20px
}

.content-history__load-more {
    text-align: center
}

.content-my-card__header {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

@media screen and (max-width:32rem) {
    .content-my-card__header {
        display: block
    }
}

.content-my-card__block {
    background-color: #fff;
    min-height: 220px;
    border-radius: 4px;
    margin: 0 15px;
    padding: 16px;
    width: calc(35% - 30px)
}

.content-my-card__block:last-child {
    background: none;
    border-radius: none;
    padding: 0;
    width: calc(30% - 30px)
}

@media screen and (max-width:32rem) {

    .content-my-card__block,
    .content-my-card__block:last-child {
        width: 100%;
        margin: 0
    }
}

.content-my-card .card img {
    max-width: 100%;
    height: auto
}

.content-my-card .chart {
    text-align: center
}

.content-my-card .chart__title {
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #7d83aa
}

.content-my-card .request {
    display: flex;
    margin-bottom: 24px;
    width: 100%
}

@media screen and (max-width:32rem) {
    .content-my-card .request {
        display: block
    }
}

.content-my-card .request__item {
    padding: 16px 26px;
    background-color: #fff;
    width: 33.33333%;
    margin: 12px;
    border-radius: 4px
}

@media screen and (max-width:32rem) {
    .content-my-card .request__item {
        width: 100%;
        margin: 0
    }
}

.content-my-card .request__item .icon {
    font-size: 1.375rem;
    color: #656c9b;
    margin-right: calc(.5em*1)
}

.content-my-card .request__item p {
    font-size: .875rem;
    line-height: 1rem;
    color: #656c9b
}

.content-my-card .request__item--balance {
    border-left: 8px solid #feb88a
}

.content-my-card .request__item--balance .icon {
    color: #feb88a
}

.content-my-card .request__item--balance .request__number {
    color: #feb88a
}

.content-my-card .request__item--balance .request__progress {
    margin-top: 8px
}

.content-my-card .request__item--balance span {
    color: #feb88a
}

.content-my-card .request__item--pending {
    border-left: 8px solid #8290fd
}

.content-my-card .request__item--pending .icon {
    color: #8290fd
}

.content-my-card .request__item--pending .request__number {
    color: #8290fd
}

.content-my-card .request__item--success {
    border-left: 8px solid #6cd4a8
}

.content-my-card .request__item--success .icon {
    color: #6cd4a8
}

.content-my-card .request__item--success .request__number {
    color: #6cd4a8
}

.content-my-card .request__number {
    font-size: 1.875rem;
    color: #656c9b
}

.content-my-card__history {
    margin: 0 20px
}

.content-my-card__title {
    font-size: 1.375rem;
    line-height: 1.5rem
}

.content-my-card__filter {
    margin: 20px 0 40px;
    padding: 20px 0;
    border-top: 1px solid rgba(125, 131, 170, .1);
    border-bottom: 1px solid rgba(125, 131, 170, .1)
}

.content-my-card__search {
    margin-right: 25px
}

.content-my-card__empty {
    background-color: #fff;
    padding: 80px 0 110px
}

.content-my-card__table .dynamic-table__row.row-with-contents {
    justify-content: center
}

.content-my-card__table .dynamic-table__row .dynamic-table__cell {
    font-size: .875rem;
    color: #7d83aa
}

.content-my-card__table .dynamic-table__row .dynamic-table__cell .logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f8f9ff;
    margin-right: 16px
}

.content-my-card__table .dynamic-table__row .dynamic-table__cell .logo img {
    max-width: 60%;
    height: auto
}

.content-my-card__table .dynamic-table__row .dynamic-table__cell.cell-actions {
    display: flex;
    justify-content: flex-start;
    border-left: 1px solid #ebeff4;
    padding-left: 8px;
    margin: 0 -4px;
    width: 72px
}

.content-my-card__table .dynamic-table__row .dynamic-table__cell.cell-actions a {
    margin: 0 4px
}

.content-my-card__table .dynamic-table__row .dynamic-table__cell.cell-actions a i {
    font-size: 1.375rem;
    transform: scale(1);
    color: #d8daeb;
    transition: all .2s ease-in-out
}

.content-my-card__table .dynamic-table__row .dynamic-table__cell.cell-actions a:hover i {
    transform: scale(1.1);
    color: #020d38
}

.content-my-card__table .dynamic-table .cell-actions__down .cell-actions__icon:before {
    transition: all .3s ease
}

.content-my-card__table .dynamic-table .cell-actions__down .cell-actions__icon.active {
    color: #535a8b
}

.content-my-card__table .dynamic-table .cell-actions__down .cell-actions__icon.active:before {
    transform: rotate(180deg)
}

.content-my-card__table .dynamic-table .cell-history-data {
    border-top: 1px solid rgba(211, 213, 228, .3);
    width: calc(100% + 60px);
    margin: 0 -40px;
    padding-top: 12px
}

.content-my-card__table .dynamic-table .cell-history-data__title {
    margin: 0 0 0 1.5rem
}

.content-my-card__table .dynamic-table .cell-history-data__title h1 {
    color: #656c9b;
    font-size: .875rem;
    display: flex;
    align-items: center
}

.content-my-card__table .dynamic-table .cell-history-data__title h1 i {
    margin: 0 .5rem 0 0;
    font-size: 1.25rem
}

.content-my-card__table .dynamic-table .cell-history-data__list {
    margin: 1rem 3rem 0
}

.content-my-card__table .dynamic-table .cell-history-data__head,
.content-my-card__table .dynamic-table .cell-history-data__row {
    display: flex
}

.content-my-card__table .dynamic-table .cell-history-data__cell {
    width: 20%
}

.content-my-card__table .dynamic-table .cell-history-data__row {
    margin: 1rem 0 0;
    padding: 0 0 1rem;
    color: #535a8b;
    border-bottom: 1px solid rgba(211, 213, 228, .3)
}

.content-my-card__table .dynamic-table .cell-history-data__row:last-child {
    border: none
}

.content-profile .footer-privacy {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0 0;
    font-size: .875rem
}

@media (-ms-high-contrast:none) {

    .content-profile .block-profile__avatar .block-profile__add-avatar,
    .content-profile ::-ms-backdrop {
        display: block
    }
}

@media (-ms-high-contrast:none) {

    .content-profile .block-profile__avatar .block-profile__add-avatar i,
    .content-profile ::-ms-backdrop {
        margin-bottom: 12px
    }
}

.content-account-settings {
    max-width: 400px
}

.content-account-settings .block-settings__input {
    margin-bottom: 24px
}

.container-account {
    position: relative;
    height: auto;
    margin-top: calc(.5em*3.5)
}

@media screen and (min-width:64rem) {
    .container-account {
        margin-top: 0
    }
}

.container-account__avatar {
    margin-right: calc(.5em*2)
}

.container-account__name {
    font-size: 1rem;
    color: #020d38
}

.container-account__body {
    margin-top: 12px
}

.container-account__main {
    margin-top: calc(.5em*3.5);
    margin-left: calc(0.5em*3.5);
}

header.container-account__header {
    margin-left: calc(0.5em*3.5);

}

.container-account__back {
    position: relative;
    top: 0;
    left: 0
}

@media screen and (min-width:64rem) {
    .container-account__back {
        position: absolute;
        top: 0;
        left: -15px
    }
}

@media screen and (min-width:80rem) {
    .container-account__back {
        top: 12px;
        left: -45px
    }
}

.container-account__row {
    position: relative;
    padding-left: 0
}

@media screen and (min-width:64rem) {
    .container-account__row {
        padding-left: 20px
    }
}

.container-account__aside {
    margin-bottom: calc(.5em*3)
}

@media screen and (min-width:64rem) {
    .container-account__aside {
        margin-bottom: 0
    }
}

.container-catalogue-calendar__title,
.content-catalogue-calendar__title {
    margin-bottom: 32px;
    font-size: 1.25rem
}

.container-catalogue-calendar__subtitle,
.content-catalogue-calendar__subtitle {
    margin: 32px 0
}

.container-catalogue-calendar__subtitle span,
.content-catalogue-calendar__subtitle span {
    font-size: 1.5rem
}

.container-catalogue-calendar__section,
.content-catalogue-calendar__section {
    margin-bottom: 32px
}

.container-catalogue-calendar__list,
.content-catalogue-calendar__list {
    margin: -16px 0
}

.container-catalogue-calendar__item,
.content-catalogue-calendar__item {
    margin: 16px 0
}

.container-catalog-internal {
    z-index: 5
}

.container-catalog-internal__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.375rem;
    font-weight: 300;
    color: #020d38;
    margin-bottom: 30px
}

.container-catalog-internal__title--white {
    color: #fff
}

.container-catalog-internal__title span {
    margin-right: 45px
}

.container-catalog__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.375rem;
    font-weight: 300;
    color: #020d38;
    /* margin-bottom:30px */
}

.container-catalog-internal__list.row.animate {
    padding-top: 2rem;
}

.container-catalog__title--white {
    color: #fff
}

.container-catalog__title span {
    margin-right: 45px;
    border-left: 3px solid transparent;
    padding: 2px 20px;
    border-style: solid;
    border-image: linear-gradient(to bottom, var(--v2-color-primary-500), rgba(0, 0, 0, 0)) 0.5 100%;
}

.container-catalog__session {
    margin: 50px 0
}

.container-catalog__session:first-child {
    margin: 0
}

.container-catalog__themes .themes-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -16px
}

.container-catalog__themes .themes-list__item {
    margin: 16px
}

.container-catalog .navigation-breadcrumb li:after,
.container-catalog .navigation-breadcrumb li a {
    color: #fff
}

.container-catalog .navigation-breadcrumb li a:after {
    background: #fff
}

.container-communities .navigation-settings__list {
    display: flex
}

@media screen and (min-width:20rem) {
    .container-communities .navigation-settings__list {
        flex-direction: column;
        align-items: center
    }
}

@media screen and (min-width:48rem) {
    .container-communities .navigation-settings__list {
        flex-direction: row
    }
}

.container-community .container__content--search {
    width: 25%;
    margin: 0 0 2rem
}

@media screen and (min-width:20rem) {
    .container-community .container__content--search {
        width: 90%;
        margin-left: auto;
        margin-right: auto
    }
}

@media screen and (min-width:48rem) {
    .container-community .container__content--search {
        margin: 0 0 2rem;
        width: 50%
    }
}

@media screen and (min-width:64rem) {
    .container-community .container__content--search {
        width: 25%
    }
}

.container-community .community-details__content .block-resource-community {
    margin: 0 0 1rem
}

.container-community .community-details__content .block-resource-community .resource {
    justify-content: space-between
}

.obsolete-container-detail .container__main--full {
    width: 98.333%;
    margin-left: 20px
}

@media screen and (min-width:20rem) {
    .obsolete-container-detail .container__main--full {
        padding: 0 10px
    }
}

@media screen and (min-width:80rem) {
    .obsolete-container-detail .container__main--full {
        padding: 0
    }
}

.content-correction__section {
    margin-bottom: 80px
}

.content-correction__title {
    font-size: 1.375rem;
    font-weight: 300;
    color: #020d38;
    margin-bottom: 24px
}

.content-correction__quizz-name {
    font-size: 1.125rem;
    font-weight: 300;
    color: #7c6cde;
    margin-bottom: 16px
}

.content-correction__correction {
    margin: 16px 0
}

.obsolete-container-detail .row {
    display: flex;
    flex-wrap: wrap
}

.obsolete-container-detail .container__item--line:before {
    display: inline-block;
    content: "";
    border-top: 1px solid #b2b5cd;
    width: 95%;
    margin: 0 1rem;
    transform: translateY(-1rem)
}

.obsolete-container-detail .container__main {
    float: none;
    order: 1
}

@media screen and (min-width:64rem) {
    .obsolete-container-detail .container__main {
        float: left;
        order: 0
    }
}

.obsolete-container-detail .container__aside {
    float: none;
    order: 0
}

@media screen and (min-width:64rem) {
    .obsolete-container-detail .container__aside {
        float: left;
        order: 1
    }
}

.obsolete-container-detail .container__aside__content {
    margin-top: 20px
}

.obsolete-container-detail .container__aside__share {
    margin: 1rem 0
}

.obsolete-container-detail .container__aside__more {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    margin: 20px 0
}

.obsolete-container-detail .container__aside__more__title {
    color: #020d38;
    font-size: 1.25rem
}

.obsolete-container-detail .container__aside__more__skills {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap
}

.obsolete-container-detail .container__aside__more__skills .skill {
    background: none;
    border: none;
    padding: 0;
    margin-right: 5px
}

.obsolete-container-detail .container__aside__more__skills .skill__text {
    color: #020d38;
    font-size: 1rem
}

.obsolete-container-detail .container__aside__more__details {
    width: 100%;
    display: flex;
    flex-direction: column
}

.obsolete-container-detail .container__aside__more__details span {
    display: flex;
    align-items: center;
    color: #020d38;
    margin-top: 16px;
    font-size: 1rem
}

.obsolete-container-detail .container__aside__more__details i {
    margin-right: 4px;
    font-size: 1.25rem
}

.obsolete-container-detail .container__aside__more__details img {
    width: 25px
}

@media screen and (min-width:48rem) {
    .time-container .dynamic-table__cell {
        font-size: 1rem
    }
}

.time-container .dynamic-table__cell span {
    text-transform: capitalize;
    color: #656c9b;
    margin-right: 1rem
}

.time-container__time__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 10rem;
    padding-right: 3rem;
    margin-bottom: 1rem;
    cursor: pointer
}

@media screen and (min-width:48rem) {
    .time-container__time__header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 5rem
    }
}

.time-container__time__header h2 {
    display: flex;
    align-items: center
}

.time-container__time__header h2 div {
    margin: 0 0 0 1rem
}

.time-container__time__header h2 div i {
    font-size: 1.25rem
}

.time-container__time__header__data {
    display: flex;
    align-items: center
}

@media screen and (max-width:48rem) {
    .time-container__time__header__data {
        margin-top: 2rem
    }
}

.time-container__time__header__data i {
    margin-right: .5rem;
    font-size: 1.25rem
}

.time-container__time__header .open-action {
    position: absolute;
    right: 1rem;
    font-size: 1.625rem;
    transform: rotate(0);
    transition: all .3s ease
}

.time-container__time__actions {
    flex: 0;
    font-size: 1.25rem;
    border-left: 1px solid #d3d5e4
}

.time-container__time__actions i {
    cursor: pointer;
    opacity: .5;
    transition: opacity .3s ease-in-out;
    font-size: 1.375rem
}

.time-container__time__actions i:not(:first-child) {
    margin-left: 1.3rem
}

.time-container__time__actions i:hover {
    opacity: 1
}

.time-container__time__content {
    display: none
}

.time-container__time__add {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 8px 20px;
    border: 1px dashed #d3d5e4;
    cursor: pointer
}

.time-container__time__add-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    background: #fff;
    padding: 1rem;
    border: 1px solid #d3d5e4;
    border-radius: 4px
}

@media screen and (min-width:48rem) {
    .time-container__time__add-modal {
        flex-direction: row;
        justify-content: space-between
    }
}

.time-container__time__add-modal__actions {
    display: flex;
    align-items: center
}

@media screen and (max-width:48rem) {
    .time-container__time__add-modal__actions {
        margin-top: 1rem
    }
}

.time-container__time__add-modal__actions msc-button:not(:first-child) {
    margin-left: .5rem
}

.time-container__time__add-modal__actions msc-button {
    display: flex;
    align-items: center
}

.time-container__time__add-modal__actions i {
    font-size: 1.125rem
}

.time-container__time__add-modal__inputs {
    display: flex;
    justify-content: center;
    align-items: center
}

.time-container__time__add-modal__inputs :not(:first-child) {
    margin-left: 1rem
}

.time-container__time__add-modal__inputs .input-number,
.time-container__time__add-modal__inputs .select {
    height: 44px
}

.time-container__block {
    padding: 0 1rem 1rem;
    background: rgba(211, 213, 228, .2);
    border-radius: 4px;
    height: 5rem
}

@media screen and (max-width:32rem) {
    .time-container__block {
        height: 10rem
    }
}

.time-container__block:not(:first-child) {
    margin-top: 1rem
}

.time-container__block.active {
    height: auto
}

.time-container__block.active .open-action {
    transform: rotate(180deg)
}

.time-container__block.active .time-container__time__content {
    display: block
}

.content-mentoring-program .dynamic-table__cell--header {
    flex: initial;
    width: 200px
}

.content-mentoring-program .dynamic-table__cell--skill {
    flex-wrap: wrap
}

.content-mentoring-program .dynamic-table__cell--padding {
    padding-right: 4rem
}

.content-mentoring-program .dynamic-table__row {
    display: flex;
    flex-direction: column
}

.content-mentoring-program .dynamic-table__row .dynamic-table__subcontent {
    align-items: normal;
    flex-wrap: wrap
}

.content-mentoring-program .dynamic-table__row .dynamic-table__subcontent:before {
    display: inline-block;
    content: "";
    border-top: 1px solid #d3d5e4;
    width: 100%;
    margin: auto;
    transform: translateY(-1rem)
}

.content-mentoring-program .dynamic-table__row .dynamic-table__subcontent .skill__title {
    margin: .5rem 0
}

.content-mentoring-program .dynamic-table__row .dynamic-table__content.active .icon-arrow-ios-down:before {
    transform: rotate(0deg)
}

.content-mentoring-program .dynamic-table__row .dynamic-table__mentor {
    flex-direction: column;
    color: #b2b5cd
}

.content-mentoring-program .dynamic-table__row .display-dropdown {
    margin-left: 5px
}

.content-mentoring-program .dynamic-table__row .display-dropdown .icon-arrow-ios-down {
    font-size: 1.125rem;
    color: #8c91b4
}

.content-mentoring-program .dynamic-table__row .display-dropdown .icon-arrow-ios-down:before {
    transition: all .25s ease-in-out;
    transform: rotate(180deg)
}

.container-account .content-mentoring__section,
.container-mentoring .content-mentoring__section {
    margin-bottom: 40px
}

.container-account .content-mentoring__title {
    font-size: 1rem;
    color: #020d38;
    font-weight: lighter;
    margin-bottom: 24px
}

.container-mentoring .content-mentoring__title {
    font-size: 1rem;
    color: #020d38;
    font-weight: lighter;
    margin-bottom: 24px
}

.container-account .content-mentoring__filter,
.container-mentoring .content-mentoring__filter {
    margin: 0 -8px 16px
}

.container-account .content-mentoring__filter>*,
.container-mentoring .content-mentoring__filter>* {
    margin: 0 8px
}

@media screen and (min-width:64rem) {

    .container-account .content-mentoring__filter,
    .container-mentoring .content-mentoring__filter {
        margin: 0 -8px
    }
}

.container-account .content-mentoring__action .link i,
.container-mentoring .content-mentoring__action .link i {
    box-shadow: none !important
}

.container-account .content-mentoring__action .link span,
.container-mentoring .content-mentoring__action .link span {
    display: block !important
}

.container-account .content-mentoring__actions,
.container-mentoring .content-mentoring__actions {
    display: block;
    justify-content: flex-end
}

@media screen and (min-width:64rem) {

    .container-account .content-mentoring__actions,
    .container-mentoring .content-mentoring__actions {
        display: flex
    }
}

.container-account .content-mentoring__coach-list .coach__item,
.container-mentoring .content-mentoring__coach-list .coach__item {
    position: relative;
    z-index: 1
}

.container-account .content-mentoring__coach-list .coach__item:hover,
.container-mentoring .content-mentoring__coach-list .coach__item:hover {
    z-index: 10
}

.container-account .mentoring-program,
.container-mentoring .mentoring-program {
    margin: 0 -24px
}

.container-account .skill__list,
.container-mentoring .skill__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 0 24px
}

.container-account .skill__item,
.container-mentoring .skill__item {
    cursor: default
}

.container-account .skill--show,
.container-mentoring .skill--show {
    cursor: pointer
}

.container-account .box-mentoring,
.container-mentoring .box-mentoring {
    padding: 16px
}

@media screen and (min-width:64rem) {

    .container-account .box-mentoring,
    .container-mentoring .box-mentoring {
        padding: 25px 20px
    }
}

.container-account .box-mentoring__title {
    font-weight: lighter;
    font-size: 1.125rem;
    color: #020d38;
    margin-bottom: 22px
}

.container-mentoring .box-mentoring__title {
    font-weight: lighter;
    font-size: 1.125rem;
    color: #020d38;
    margin-bottom: 22px
}

.container-account .box-mentoring__tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #b2b5cd;
    margin-bottom: 12px
}

.container-mentoring .box-mentoring__tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #b2b5cd;
    margin-bottom: 12px
}

.container-account .box-mentoring__tabs li,
.container-mentoring .box-mentoring__tabs li {
    margin-bottom: -1px;
    padding-bottom: 12px;
    border-bottom: 2px solid transparent;
    display: inline-block
}

.container-account .box-mentoring__tabs li.active {
    border-color: #8290fd
}

.container-mentoring .box-mentoring__tabs li.active {
    border-color: #8290fd
}

.container-account .box-mentoring__tabs li.active a {
    color: #8290fd
}

.container-mentoring .box-mentoring__tabs li.active a {
    color: #8290fd
}

.container-account .box-mentoring__tabs a {
    font-size: .875rem;
    font-weight: lighter;
    color: #b2b5cd
}

.container-mentoring .box-mentoring__tabs a {
    font-size: .875rem;
    font-weight: lighter;
    color: #b2b5cd
}

.container-account .box-mentoring__details,
.container-mentoring .box-mentoring__details {
    margin: 0;
    padding: 0
}

@media screen and (min-width:64rem) {

    .container-account .box-mentoring__details,
    .container-mentoring .box-mentoring__details {
        margin: 35px 0 0;
        padding: 0 20px
    }
}

.container-account .box-mentoring__news,
.container-mentoring .box-mentoring__news {
    margin: 35px 0 0
}

.container-account .box-mentoring__news .news__item,
.container-mentoring .box-mentoring__news .news__item {
    margin: 22px 0
}

.container-account .box-mentoring__calendar,
.container-account .box-mentoring__resources,
.container-mentoring .box-mentoring__calendar,
.container-mentoring .box-mentoring__resources {
    margin: 35px 0 0
}

.container-account .box-mentoring__calendar .box-mentorinq__action,
.container-account .box-mentoring__resources .box-mentorinq__action,
.container-mentoring .box-mentoring__calendar .box-mentorinq__action,
.container-mentoring .box-mentoring__resources .box-mentorinq__action {
    display: flex;
    justify-content: flex-end
}

.container-account .box-mentoring__list,
.container-mentoring .box-mentoring__list {
    margin: 16px -4px 0
}

.container-account .box-mentoring__list>*,
.container-mentoring .box-mentoring__list>* {
    margin: 4px
}

.container-account .box-mentoring__section,
.container-mentoring .box-mentoring__section {
    margin-bottom: 32px
}

.container-account .box-mentoring__subtitle {
    font-family: Helvetica;
    font-size: .875rem;
    color: #020d38;
    letter-spacing: 0;
    line-height: 18px
}

.container-mentoring .box-mentoring__subtitle {
    font-family: Helvetica;
    font-size: .875rem;
    color: #020d38;
    letter-spacing: 0;
    line-height: 18px
}

.container-account .box-mentoring__paragraph {
    font-family: Helvetica;
    font-size: .875rem;
    color: #8c91b4;
    letter-spacing: 0;
    line-height: 20px
}

.container-mentoring .box-mentoring__paragraph {
    font-family: Helvetica;
    font-size: .875rem;
    color: #8c91b4;
    letter-spacing: 0;
    line-height: 20px
}

.container-account .box-mentoring__action,
.container-mentoring .box-mentoring__action {
    display: inline-block;
    margin-top: 32px
}

.container-account .box-event-mentor {
    background-color: rgba(211, 213, 228, .1);
    border-radius: 4px;
    margin: 16px 0
}

.container-mentoring .box-event-mentor {
    background-color: rgba(211, 213, 228, .1);
    border-radius: 4px;
    margin: 16px 0
}

.container-account .box-event-mentor--orange {
    border-left: 6px solid #feb88a
}

.container-mentoring .box-event-mentor--orange {
    border-left: 6px solid #feb88a
}

.container-account .box-event-mentor--red {
    border-left: 6px solid #ffa2a2
}

.container-mentoring .box-event-mentor--red {
    border-left: 6px solid #ffa2a2
}

.container-account .box-event-mentor__title {
    font-size: 1rem;
    color: #020d38
}

.container-mentoring .box-event-mentor__title {
    font-size: 1rem;
    color: #020d38
}

.container-account .box-event-mentor__date {
    font-size: .875rem;
    color: #656c9b
}

.container-mentoring .box-event-mentor__date {
    font-size: .875rem;
    color: #656c9b
}

.container-account .box-event-mentor__group,
.container-mentoring .box-event-mentor__group {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px
}

.container-mentoring .content-mentoring__header i {
    display: flex;
    font-size: 1.75rem;
    color: #feb88a;
    margin-right: calc(.5em*1.5)
}

.container-mentoring .content-mentoring__header h1 {
    font-size: 1.375rem;
    color: #020d38;
    margin-bottom: 0
}

.container-mentoring .content-mentoring__nav {
    margin-bottom: calc(.5em*2.5);
    padding-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4
}

.container-mentoring .content-mentoring__nav p {
    font-size: 1rem;
    color: #7d83aa;
    margin: calc(.5em*2.5) 0
}

.container-mentoring .content-mentoring__search {
    width: 100%
}

@media screen and (min-width:32rem) {
    .container-mentoring .content-mentoring__search {
        width: 210px
    }
}

.container-mentoring .content-mentoring .programs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: calc(calc(.5em*1)*-1) 0
}

.container-mentoring .content-mentoring .programs__item {
    border: 1px solid #d3d5e4;
    cursor: pointer;
    transition: all .3s ease;
    width: 100%;
    margin: calc(.5em*1) 0
}

@media screen and (min-width:64rem) {
    .container-mentoring .content-mentoring .programs__item {
        width: calc(50% - calc(.5em*1))
    }
}

.container-mentoring .content-mentoring .programs__item i {
    font-size: 1.75rem;
    color: #feb88a;
    margin-right: calc(.5em*1.5)
}

.container-mentoring .content-mentoring .programs__item p {
    font-size: 1rem;
    color: #7d83aa
}

.container-mentoring .content-mentoring .programs__item:hover {
    z-index: 5;
    transform: scale(1.02)
}

.container-mentoring .content-mentoring .programs__item:hover .programs__item {
    border-color: #8c91b4
}

.container-mentoring .content-mentoring .programs__item:hover .programs__action i {
    color: #020d38
}

.container-mentoring .content-mentoring .programs__action i {
    color: #7d83aa
}

.container-mentoring .mentoring-program .block-mentor__content {
    width: 500px
}

.container-mentoring .navigation-mentoring {
    padding: 20px 30px 20px 0;
    margin: -15px 0 -15px -12px;
    background: #fff;
    border-radius: 4px
}

@media screen and (min-width:64rem) {
    .container-mentoring .navigation-mentoring {
        position: fixed;
        width: 20%
    }
}

@media screen and (min-width:80rem) {
    .container-mentoring .navigation-mentoring {
        width: 17%
    }
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__separator {
    width: 100%;
    height: 1px;
    background-color: #d3d5e4;
    margin: 1rem 0 .5rem 15px
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 35px;
    cursor: pointer
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item:hover .toggle i:before {
    color: #feb88a
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item:hover p {
    color: #feb88a
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item p {
    font-size: .875rem;
    color: #656c9b;
    transition: all .3s ease
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item .toggle i:before {
    transform: rotate(0deg);
    font-size: 1.125rem;
    color: #5150c4;
    transition: all .3s ease
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item.active {
    border-left: 3px solid #feb88a;
    padding-left: 31px
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item.active .toggle i:before {
    color: #feb88a
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item.active p {
    color: #feb88a
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item.displayed .toggle i:before {
    transform: rotate(180deg)
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigation-mentoring__item.displayed+.navigations-mentoring__sub {
    display: block
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub {
    display: none
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub li {
    width: 100%;
    padding: 10px 0 10px 50px;
    transition: all .3s ease;
    cursor: pointer
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub li p {
    font-size: .875rem;
    color: #656c9b;
    transition: all .3s ease;
    display: flex
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub li i:before {
    font-size: 1.25rem;
    color: #656c9b;
    margin-right: 8px;
    transition: all .3s ease
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub li.active {
    color: #feb88a
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub li.active p {
    color: #feb88a
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub li.active p i:before {
    color: #feb88a
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub li:hover {
    color: #feb88a
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub li:hover p {
    color: #feb88a
}

.container-mentoring .navigation-mentoring .navigation-mentoring__content .navigations-mentoring__sub li:hover p i:before {
    color: #feb88a
}

.content-participants {
    margin: 0 0 0 1rem
}

.content-participants__header {
    display: flex;
    justify-content: space-between
}

.content-participants__header msc-button .icon-person-add {
    font-size: 1rem
}

.content-participants__actions,
.content-participants__filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.content-participants__actions>*,
.content-participants__filter>* {
    margin: 0 8px
}

.content-participants__actions msc-button {
    height: 30px
}

.content-participants__table {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(2, 13, 56, .08)
}

.content-participants__table .dynamic-table .row-disabled {
    background-color: rgba(211, 213, 228, .2)
}

@media screen and (max-width:32rem) {
    .content-participants__table .dynamic-table .cell-title--small {
        width: auto
    }
}

.content-participants__table .dynamic-table .cell-color-orange {
    color: #feb88a
}

.content-manage-follow-up__menu {
    width: 30%
}

.content-manage-follow-up__content {
    margin: 1rem 0 0;
    display: flex;
    justify-content: space-between
}

@media screen and (max-width:32rem) {
    .content-manage-follow-up__content {
        flex-direction: column
    }
}

.content-manage-follow-up__list {
    width: 38%
}

@media screen and (max-width:32rem) {
    .content-manage-follow-up__list {
        width: 100%
    }
}

.content-manage-follow-up__list__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    transition: all .3s ease;
    margin: 0 0 1rem
}

.content-manage-follow-up__list__item.active {
    border-color: #8c91b4
}

.content-manage-follow-up__list__item:hover {
    border-color: #8c91b4
}

.content-manage-follow-up__list__item:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: #d3d5e4
}

.content-manage-follow-up__list__item--progress:before {
    background-color: #8290fd
}

.content-manage-follow-up__list__item--success:before {
    background-color: #49e2af
}

.content-manage-follow-up__list__item .item-avatar {
    margin-right: 20px;
    width: 50%;
    max-width: 50%
}

.content-manage-follow-up__list__item .item-login {
    font-size: .875rem;
    color: #d3d5e4
}

.content-manage-follow-up__list__item .item-arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%)
}

.content-manage-follow-up__list__item .item-arrow i {
    font-size: 1.125rem;
    color: #d3d5e4
}

.content-manage-follow-up__body {
    width: 60%;
    background-color: #fff
}

@media screen and (max-width:32rem) {
    .content-manage-follow-up__body {
        width: 100%
    }
}

.content-manage-follow-up__body .dynamic-table__row {
    border: 1px solid #d3d5e4;
    border-radius: .2rem
}

@media screen and (min-width:48rem) {
    .content-manage-follow-up__body .dynamic-table__cell {
        font-size: 1rem
    }
}

.content-manage-follow-up__body .dynamic-table__cell span {
    text-transform: capitalize;
    color: #656c9b;
    margin-right: 1rem
}

.content-manage-follow-up__body .dynamic-table .cell-time-actions {
    flex: 0;
    font-size: 1.25rem;
    border-left: 1px solid #d3d5e4
}

@media screen and (max-width:90rem) {
    .content-manage-follow-up__body .dynamic-table .cell-time-actions {
        flex-direction: column
    }

    .content-manage-follow-up__body .dynamic-table .cell-time-actions a,
    .content-manage-follow-up__body .dynamic-table .cell-time-actions i:not(:first-child) {
        margin: 0 !important
    }
}

.content-manage-follow-up__body .dynamic-table .cell-time-actions i {
    cursor: pointer;
    opacity: .5;
    transition: opacity .3s ease-in-out;
    font-size: 1.375rem
}

.content-manage-follow-up__body .dynamic-table .cell-time-actions i:not(:first-child) {
    margin: 0 0 0 1.3rem
}

.content-manage-follow-up__body .dynamic-table .cell-time-actions i:hover {
    opacity: 1
}

.content-manage-follow-up__body .dynamic-table .cell-time-actions a {
    margin: 0 0 0 1.3rem
}

.content-manage-follow-up__body .time-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 5rem;
    padding-right: 3rem;
    margin-bottom: 1rem;
    cursor: pointer
}

@media screen and (min-width:160rem) {
    .content-manage-follow-up__body .time-header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center
    }
}

.content-manage-follow-up__body .time-header h2 {
    display: flex;
    align-items: center
}

.content-manage-follow-up__body .time-header h2 div {
    margin: 0 0 0 1rem
}

.content-manage-follow-up__body .time-header h2 div i {
    font-size: 1.25rem
}

.content-manage-follow-up__body .time-header__data {
    display: flex;
    align-items: center
}

@media screen and (max-width:48rem) {
    .content-manage-follow-up__body .time-header__data {
        margin-top: 2rem
    }
}

.content-manage-follow-up__body .time-header__data i {
    margin-right: .5rem;
    font-size: 1.25rem
}

.content-manage-follow-up__body .time-header .open-action {
    position: absolute;
    right: 1rem;
    font-size: 1.625rem;
    transform: rotate(0deg);
    transition: all .3s ease
}

.content-manage-follow-up__body .time-block {
    padding: 0 1rem 1rem;
    background: rgba(211, 213, 228, .2);
    border-radius: .2rem;
    height: 5rem
}

.content-manage-follow-up__body .time-block:not(:first-child) {
    margin-top: 1rem
}

.content-manage-follow-up__body .time-block.active {
    height: auto
}

.content-manage-follow-up__body .time-block.active .open-action {
    transform: rotate(180deg)
}

.content-manage-follow-up__body .time-block.active .time-content {
    display: block
}

.content-manage-follow-up__body .time-content {
    display: none
}

.content-manage-follow-up__body .time-add {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 8px 20px;
    border: 1px dashed #d3d5e4;
    cursor: pointer
}

.content-manage-follow-up__body .time-add-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    background: #fff;
    padding: 1rem;
    border: 1px solid #d3d5e4;
    border-radius: .2rem
}

@media screen and (min-width:160rem) {
    .content-manage-follow-up__body .time-add-modal {
        flex-direction: row;
        justify-content: space-between;
        align-items: center
    }
}

.content-manage-follow-up__body .time-add-modal__actions {
    display: flex;
    align-items: center;
    margin-top: 1rem
}

@media screen and (min-width:160rem) {
    .content-manage-follow-up__body .time-add-modal__actions {
        margin: 0
    }
}

.content-manage-follow-up__body .time-add-modal__actions msc-button:not(:first-child) {
    margin-left: .5rem
}

.content-manage-follow-up__body .time-add-modal__actions msc-button {
    display: flex;
    align-items: center
}

.content-manage-follow-up__body .time-add-modal__actions i {
    font-size: 1.125rem
}

.content-manage-follow-up__body .time-add-modal__inputs {
    display: flex;
    justify-content: center;
    align-items: center
}

.content-manage-follow-up__body .time-add-modal__inputs :not(:first-child) {
    margin: 0 0 0 1rem
}

.content-manage-follow-up__body .time-add-modal__inputs .input-number,
.content-manage-follow-up__body .time-add-modal__inputs .select {
    height: 44px
}

.content-manage-follow-up__time {
    padding: 1rem
}

.content-calendar {
    margin: 0 0 0 1rem
}

.content-calendar .calendar .tag--date {
    min-width: 68px;
    height: 68px;
    border-radius: 6px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center
}

.content-calendar .calendar .tag--date .text__day,
.content-calendar .calendar .tag--date .text__month {
    display: block
}

.content-calendar .calendar .tag--date .text__day {
    font-size: 1.75rem;
    line-height: 100%;
    font-weight: 900
}

.content-calendar .calendar .tag--purple {
    background-color: #7c6cde
}

.content-calendar .calendar .tag--orange {
    background-color: #ffc688
}

.content-calendar .calendar .tag--grey {
    background-color: #d3d5e4
}

.content-calendar .calendar__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 -16px
}

.content-calendar .calendar__actions>* {
    margin: 0 16px
}

.content-calendar .calendar__more {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 1.25rem;
    background-color: rgba(211, 213, 228, .5)
}

.content-calendar .calendar__list {
    margin: -20px 0 -44px
}

.content-calendar .calendar__item {
    position: relative;
    padding: 44px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start
}

.content-calendar .calendar__item:last-child .calendar__progress:after {
    display: none
}

.content-calendar .calendar__item--done .calendar__progress:before {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: myskillcamp;
    font-size: .75rem;
    content: "\e837";
    position: absolute;
    z-index: 5;
    top: 72px;
    left: 70px;
    width: 18px;
    height: 18px;
    margin: 0 15px;
    border-radius: 50%;
    border: 2px solid #d3d5e4 !important;
    background-color: #d3d5e4 !important;
    color: #fff;
    box-shadow: none !important
}

.content-calendar .calendar__item--done .calendar__progress:after {
    border-right: 2px dashed #d3d5e4
}

.content-calendar .calendar__item--in-progress .calendar__progress:before {
    background-color: #ffa2a2;
    border: 5px solid #fff;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.content-calendar .calendar__item--come-up .calendar__progress:after {
    border-right: 2px dashed #7c6cde
}

.content-calendar .calendar__item--in-progress .calendar__progress:after {
    border-right: 2px dashed #7c6cde
}

.content-calendar .calendar__sessions {
    width: 100%;
    margin: -10px 0
}

.content-calendar .calendar__empty,
.content-calendar .calendar__progress {
    width: 56px
}

.content-calendar .calendar__progress:after {
    display: block;
    content: "";
    position: absolute;
    z-index: 1;
    margin-top: 74px;
    top: 0;
    bottom: 0;
    left: 93px;
    right: 0;
    height: 100%;
    width: 1px;
    border-right: 2px solid #7c6cde
}

.content-calendar .calendar__progress:before {
    content: "";
    position: absolute;
    z-index: 5;
    top: 72px;
    left: 70px;
    width: 18px;
    height: 18px;
    margin: 0 15px;
    border-radius: 50%;
    background-color: #f9f9f9
}

.content-calendar .box-sessions {
    padding: 0
}

.content-calendar .box-sessions__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin: 10px 0 0
}

.content-calendar .box-sessions__body {
    display: none;
    background-color: rgba(211, 213, 228, .3);
    padding: 15px;
    align-items: baseline;
    justify-content: space-between
}

.content-calendar .box-sessions__body>* {
    width: 50%
}

.content-calendar .box-sessions__body h4 {
    color: #656c9b;
    font-size: .875rem;
    margin: 5px 0
}

.content-calendar .box-sessions__body p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #8c91b4;
    font-size: .875rem
}

.content-calendar .box-sessions__body p>* {
    margin: 5px
}

.content-calendar .box-sessions__body p img {
    max-width: 20px;
    height: auto
}

.content-calendar .box-sessions__infos {
    margin: -20px 0
}

.content-calendar .box-sessions__infos-item {
    margin: 10px;
    padding: 10px 0;
    border-bottom: 1px solid #d3d5e4
}

.content-calendar .box-sessions__infos-item:last-child {
    border-bottom: none
}

.content-calendar .box-sessions__infos-item .resource {
    background-color: transparent
}

.content-calendar .box-sessions__infos-item .resource img {
    width: 10%
}

.content-calendar .box-sessions__infos-item .resource__desc p {
    font-size: .75rem
}

.content-calendar .box-sessions__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -18px
}

.content-calendar .box-sessions__title>* {
    margin: 0 10px
}

.content-calendar .box-sessions__title p {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.content-calendar .box-sessions__time {
    max-width: 40px
}

.content-calendar .box-sessions__time span {
    color: #ffa2a2;
    display: block;
    margin-bottom: 4px
}

.content-calendar .box-sessions__action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 -9px
}

.content-calendar .box-sessions__action>* {
    margin: 0 9px
}

.content-calendar .box-sessions__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    background-color: rgba(211, 213, 228, .3)
}

.content-calendar .box-sessions__toggle i:before {
    transition: all .3s ease
}

.content-calendar .box-sessions__toggle:hover i:before {
    transform: scale(1.2)
}

.content-calendar .box-sessions__add i,
.content-calendar .box-sessions__checked i {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px
}

.content-calendar .box-sessions__add i:before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    z-index: 10;
    font-size: 1rem;
    color: #fff
}

.content-calendar .box-sessions__checked i:before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    z-index: 10;
    font-size: 1rem;
    color: #fff
}

.content-calendar .box-sessions__add i:after,
.content-calendar .box-sessions__checked i:after {
    content: "";
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transform: scale(1);
    border-radius: 50%;
    transition: all .5s cubic-bezier(.175, .885, .32, 2)
}

.content-calendar .box-sessions__add i:hover:after,
.content-calendar .box-sessions__checked i:hover:after {
    transform: scale(1.1)
}

.content-calendar .box-sessions__more {
    font-size: 1.25rem;
    color: #ffa2a2;
    margin: 0 24px 0 18px !important
}

.content-calendar .box-sessions__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #b2b5cd;
    font-size: .875rem
}

.content-calendar .box-sessions__localisation {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #b2b5cd;
    font-size: .875rem
}

.content-calendar .box-sessions__link i,
.content-calendar .box-sessions__localisation i {
    font-size: 1.125rem;
    margin-top: 8px;
    margin-right: 6px
}

.content-calendar .box-sessions__link a {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #b2b5cd;
    transition: color all .25s
}

.content-calendar .box-sessions__link p {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #b2b5cd;
    transition: color all .25s
}

.content-calendar .box-sessions__localisation a {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #b2b5cd;
    transition: color all .25s
}

.content-calendar .box-sessions__localisation p {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #b2b5cd;
    transition: color all .25s
}

.content-calendar .box-sessions__link:hover a {
    color: #020d38
}

.content-calendar .box-sessions__link:hover p {
    color: #020d38
}

.content-calendar .box-sessions__localisation:hover a {
    color: #020d38
}

.content-calendar .box-sessions__localisation:hover p {
    color: #020d38
}

.content-calendar .box-sessions.active .box-sessions__body {
    display: flex
}

.content-calendar .box-sessions.active .box-sessions__toggle i:before {
    transform: rotate(-180deg)
}

.content-corrections-list.content-corrections-session .content-corrections__list .box-session-correction-details__title .box-session-correction-details__notification {
    margin-top: -1rem
}

.content-corrections-details.content-corrections {
    margin-top: -1.5rem
}

.content-corrections-details.content-corrections .content-corrections__title {
    display: flex;
    justify-content: space-between
}

.content-corrections-details.content-corrections .content-corrections__title msc-button {
    margin-left: auto;
    margin-right: 1rem;
    align-items: center
}

.content-corrections-details.content-corrections .content-corrections__title msc-button i.icon {
    font-size: .75rem
}

.content-corrections-details.content-corrections .content-corrections__section .content-corrections__input .textarea__content .content-correction__feedback .feedback__content {
    margin: 1.5rem 0
}

.content-corrections-details.content-corrections .content-corrections__section .content-corrections__input .textarea__content .content-correction__feedback .feedback__content p.feedback__content {
    margin-left: .75rem
}

.content-corrections-details.content-corrections .content-corrections__section .file-upload {
    padding-left: 0
}

@media screen and (min-width:80rem) {
    .content-corrections-details .container-sessions__back {
        left: -1.25rem
    }
}

.content-corrections-details .box-session-correction {
    cursor: default
}

.content-corrections-details .box-session-correction__breadcrumb {
    display: flex;
    align-items: center
}

.content-corrections-details .box-session-correction__breadcrumb p {
    line-height: normal
}

.content-corrections-details .box-session-correction__breadcrumb p:not(.activity):hover {
    text-decoration: underline;
    cursor: pointer
}

.content-corrections-details .upload-media {
    margin-bottom: 3rem
}

.content-corrections-details .content-corrections__content .empty {
    margin-top: 2rem;
    width: 100%
}

.content-corrections-details .content-corrections__resources {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap
}

.content-corrections-details .content-corrections__resources .resource {
    position: relative;
    margin: 0 1rem 1rem 0;
    max-width: 45%;
    transition: all .3s ease
}

@media screen and (max-width:64rem) {
    .content-corrections-details .content-corrections__resources .resource {
        max-width: 100%
    }
}

.content-corrections-details .content-corrections__resources .resource:hover {
    background-color: rgba(211, 213, 228, .5)
}

.content-corrections-details .content-corrections__resources .resource__desc .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    color: #7d83aa;
    transform: translateY(-50%)
}

@media screen and (max-width:32rem) {
    .content-corrections-details .content-corrections__resources .resource__desc .icon {
        display: none
    }
}

.content-corrections-details .content-corrections__input.validate {
    display: flex
}

.content-corrections-details .content-corrections__input.validate .input-number {
    border-radius: 1rem;
    width: 10rem
}

.content-corrections-details .content-corrections__input.validate msc-button {
    margin-top: auto
}

.content-corrections-list.content-corrections {
    margin-top: -2.25rem
}

.content-corrections-list.content-corrections .content-corrections__filter {
    margin-top: 2em
}

.content-corrections-list.content-corrections .content-corrections__table.no-top {
    padding-top: 0;
    border-top: none
}

.content-corrections-list .box-session-correction__content {
    margin-right: 2rem
}

.content-corrections-list .box-session-correction-details__item {
    cursor: pointer
}

.content-corrections-list .box-session-correction-details__item.colour-less:after {
    background-color: #fff
}

.content-corrections-list .box-session-correction-details__title {
    margin-right: auto
}

.content-corrections-list .box-session-correction-details__header {
    justify-content: flex-start
}

.content-corrections-list .box-session-correction-details__header [class*=" icon-"]:before,
.content-corrections-list .box-session-correction-details__header [class^=icon-]:before {
    position: relative
}

.content-corrections-list .box-session-correction-details__infos ul li {
    display: flex;
    align-items: center
}

.content-details {
    margin: 0 0 0 1rem
}

.content-details__title {
    color: #020d38;
    font-size: 1.125rem;
    font-weight: lighter;
    min-height: auto;
    margin-top: -12px
}

.content-details__block {
    background: #fff;
    padding: 16px 24px;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.content-details .settings__list {
    margin: -16px 0
}

.content-details .settings__item {
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    display: flex;
    align-items: center;
    justify-content: space-between
}

.content-details .settings__item:first-child {
    margin-top: 18px;
    padding-top: 18px
}

.content-details .settings__item:last-child {
    border-bottom: none
}

.content-details .settings__item p {
    color: #656c9b;
    font-size: .875rem
}

.content-details .settings__item i {
    font-size: 1.375rem;
    margin-right: 8px
}

.content-details .settings__form {
    width: 100%
}

.content-details .settings__form p {
    margin-bottom: 18px
}

.content-details .settings p.settings__label {
    color: #656c9b;
    font-size: .875rem;
    margin-bottom: 8px
}

.content-details .settings__checkbox {
    display: inline-block;
    margin: 0 8px
}

.content-details .settings__inputs {
    padding-left: 35px
}

.content-details .settings__conditional-form {
    padding: 10px 14px;
    background: #f8f9ff
}

.content-details .settings__conditional-form .settings__label {
    margin: 16px 0
}

.content-details .settings__conditional-form .settings__label:first-child {
    margin-top: 0
}

.content-details .trainers__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px
}

.content-details .trainers__header p {
    display: flex;
    align-items: center;
    color: #656c9b;
    font-size: .875rem
}

.content-details .trainers__header p i {
    font-size: 1.375rem;
    margin-right: 8px
}

.content-details .trainers__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.content-details .trainers__item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0
}

.content-details .trainers__action {
    margin: 0 -4px
}

.content-details .trainers__action a {
    margin: 0 4px
}

.content-details .trainers__action a i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    box-shadow: none;
    transition: all .3s ease
}

.content-discussions {
    margin: 0 0 0 1rem
}

.content-discussions__contact {
    width: 100%;
    margin: -16px 0;
    float: none
}

@media screen and (min-width:32rem) {
    .content-discussions__contact {
        float: left;
        width: calc(33.33333% - 24px)
    }
}

.content-discussions__search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: -17px -12px 12px;
    height: 30px
}

.content-discussions__search msc-button {
    height: 30px
}

.content-discussions__search>* {
    margin: 0 12px
}

.content-discussions__timeline {
    width: 100%;
    margin: 24px auto;
    float: none
}

@media screen and (min-width:32rem) {
    .content-discussions__timeline {
        float: left;
        width: calc(66.66667% - 24px);
        margin: 12px
    }
}

.content-discussions__alert {
    position: relative;
    font-size: .75rem;
    color: #7d83aa;
    white-space: nowrap
}

.content-discussions__alert:after {
    position: absolute;
    top: -1px;
    right: -6px;
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #ff6b70
}

.content-discussions .block-contact-discussions {
    margin: calc(.5em*3) 0
}

.content-discussions .block-contact-discussions__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px
}

.content-discussions .block-contact-discussions__header h3 {
    font-size: .875rem;
    color: #656c9b
}

.content-discussions .block-contact-discussions__header a {
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.content-discussions .block-contact-discussions__header a i {
    font-size: 1.25rem;
    color: #656c9b
}

.content-discussions .block-contact-discussions__header:hover a {
    transform: scale(1.1)
}

.content-discussions .block-contact-discussions__body {
    padding: 6px;
    background: #fff
}

.content-discussions .block-contact-discussions__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .875rem;
    color: #b2b5cd;
    margin-bottom: 8px
}

.content-discussions .block-contact-discussions__list {
    margin: -5px 0;
    display: none
}

.content-discussions .block-contact-discussions__list.active {
    display: block
}

.content-discussions .block-contact-discussions__item {
    margin: 12px 0;
    border: 1px solid #d3d5e4;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px
}

.content-discussions .block-contact-discussions__infos {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.content-discussions .block-contact-discussions__status {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #b2b5cd;
    margin: 0 5px
}

.content-discussions .block-contact-discussions__status--online {
    background-color: #49e2af
}

.content-discussions .block-contact-discussions__status--offline {
    background-color: #ffa2a2
}

.content-discussions .block-contact-discussions__actions {
    margin: 0 0 0 -8px
}

.content-discussions .block-contact-discussions__actions a {
    margin: 0 8px
}

.content-discussions .block-contact-discussions__actions a i {
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2);
    color: #d3d5e4
}

.content-discussions .block-contact-discussions__actions a:hover i {
    color: #414776;
    transform: scale(1.5)
}

@media screen and (max-width:32rem) {
    .container-sessions .content-sessions .dynamic-table .cell-title {
        width: 100%;
        max-width: 300px
    }
}

@media screen and (max-width:20rem) {
    .container-sessions .content-sessions .dynamic-table .cell-title {
        width: 100%;
        max-width: 240px
    }
}

.container-sessions .content-sessions .dynamic-table .cell-sessions-icon i {
    padding: 0 0 0 1.5rem;
    font-size: 1.25rem;
    color: #feb88a
}

.container-sessions .content-sessions .dynamic-table .cell-sessions-icon .cell-provider {
    margin: 0 0 0 1.5rem
}

.container-sessions .content-sessions .dynamic-table .cell-sessions-icon .cell-provider img {
    width: 25px;
    height: 25px
}

.container-sessions .content-sessions .dynamic-table .cell-bookmark {
    position: absolute;
    top: 0;
    left: 0
}

.container-sessions .content-sessions .dynamic-table .cell-bookmark .bookmark {
    position: relative;
    display: block;
    height: 30px;
    width: 30px;
    background-color: #d3d5e4;
    -webkit-clip-path: polygon(0 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 100%, 0 100%);
    transform: rotate(90deg);
    border-radius: 0 0 0 4px;
    transition: all .3s ease
}

.container-sessions .content-sessions .dynamic-table .cell-bookmark .bookmark--active {
    background-color: #5150c4
}

.container-sessions .content-sessions .dynamic-table .cell-bookmark .bookmark:hover {
    background-color: #5150c4
}

.container-sessions .content-sessions .dynamic-table .cell-bookmark .bookmark__star {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3px;
    color: #fff;
    font-size: .75rem;
    transform: rotate(-20deg)
}

@media (-ms-high-contrast:none) {

    .container-sessions .content-sessions .dynamic-table .cell-bookmark .bookmark,
    .container-sessions .content-sessions .dynamic-table .cell-bookmark ::-ms-backdrop {
        width: 20px;
        height: 20px
    }
}

.container-sessions .content-sessions .dynamic-table .cell-sessions {
    padding: 0 0 0 10px
}

.container-sessions .content-sessions .dynamic-table .cell-sessions__title {
    font-size: 1rem;
    color: #282d58
}

.container-sessions .content-sessions .dynamic-table .cell-sessions--notif {
    position: relative
}

.container-sessions .content-sessions .dynamic-table .cell-sessions--notif:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #feb88a;
    border-radius: 50%;
    width: 4px;
    height: 4px
}

.container-sessions .content-sessions__title span {
    position: absolute;
    color: #f9aa81;
    font-size: .875rem
}

.content-stats {
    margin: 0 0 0 1rem
}

.content-stats__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px
}

.content-stats__breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.125rem;
    margin: 0 -8px 12px
}

.content-stats__breadcrumb>* {
    margin: 0 8px
}

.content-stats__breadcrumb .icon {
    font-size: 1.125rem;
    color: #656c9b;
    transition: transform .2s ease;
    transform: rotate(0deg)
}

.content-stats__breadcrumb .icon-checkmark-square {
    margin: 0 8px 0 -8px
}

.content-stats__stats-title {
    cursor: pointer
}

.content-stats__avatar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.125rem
}

.content-stats__avatar>* {
    margin: 0 8px
}

.content-stats__profile {
    cursor: pointer
}

.content-stats__buttons {
    display: flex;
    align-items: center
}

.content-stats__list {
    margin: -20px 0
}

.content-stats__item {
    margin: 20px 0
}

.content-stats__table {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid rgba(2, 13, 56, .08)
}

.content-stats__table-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 50px 12px 80px
}

.content-stats__table-title p {
    font-size: .75rem;
    color: #b2b5cd
}

.content-stats__table-title:first-child {
    min-width: calc(50% - 20px)
}

.content-stats__table-title:nth-child(2) {
    min-width: calc(25% + 30px)
}

.content-stats__filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.content-stats__filter>* {
    margin: 0 8px
}

.content-corrections {
    margin: 0 0 0 1rem;
    position: relative
}

.content-corrections .dynamic-table__head {
    display: none
}

@media screen and (min-width:32rem) {
    .content-corrections .dynamic-table__head {
        display: flex
    }
}

.content-corrections__back {
    position: absolute;
    top: 0;
    left: -16px;
    display: none
}

@media screen and (min-width:64rem) {
    .content-corrections__back {
        display: block
    }
}

.content-corrections__title {
    padding-bottom: 20px;
    color: #020d38;
    font-size: 1.5rem
}

.content-corrections__list {
    margin: -20px 0
}

.content-corrections__table {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(2, 13, 56, .08)
}

.content-corrections__table-header {
    display: none;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 50px 12px 80px
}

@media screen and (min-width:48rem) {
    .content-corrections__table-header {
        display: flex
    }
}

.content-corrections__table-title p {
    font-size: .75rem;
    color: #b2b5cd
}

.content-corrections__table-title:first-child {
    min-width: calc(50% - 20px)
}

.content-corrections__table-title:nth-child(2) {
    min-width: calc(25% + 30px)
}

.content-corrections__filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.content-corrections__filter>* {
    margin: 0 8px
}

.content-corrections__header .box-session-correction {
    margin-top: -14px
}

.content-corrections__header .box-session-correction:hover {
    transform: scale(1)
}

.content-corrections__header .box-session-correction:after {
    display: none
}

.content-corrections__header .box-session-correction__header i {
    margin-left: 0
}

.content-corrections__header .box-session-correction__content {
    width: auto;
    padding-right: 0
}

@media screen and (min-width:32rem) {
    .content-corrections__header .box-session-correction__content {
        padding-right: 60px
    }
}

.content-corrections__content {
    margin-top: 24px
}

.content-corrections__content .tabs {
    margin: calc(calc(.5em*0.5)*-1) 0
}

.content-corrections__content .tabs__header {
    float: none;
    width: 100%;
    margin: calc(.5em*0.5) 0
}

@media screen and (min-width:64rem) {
    .content-corrections__content .tabs__header {
        float: left;
        width: 35%
    }
}

.content-corrections__content .tabs__body {
    float: none;
    width: 100%;
    min-height: auto;
    padding: calc(.5em*2);
    background-color: #fff
}

@media screen and (min-width:64rem) {
    .content-corrections__content .tabs__body {
        float: right;
        width: 63%;
        min-height: 60vh
    }
}

.content-corrections__main {
    width: 100%
}

.content-corrections__cta {
    display: flex;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto
}

.content-corrections__aside {
    margin-right: 16px;
    margin-top: -8px;
    margin-bottom: -8px;
    min-width: 300px
}

.content-corrections__aside .box {
    margin: 8px 0
}

.content-corrections__aside--simplebar {
    max-height: 400px
}

.content-corrections__toggle {
    cursor: pointer;
    transform: rotate(0);
    transition: all .2s ease
}

.content-corrections .content-corrections__section {
    display: none
}

.content-corrections__details.active .content-corrections__title {
    border-bottom: 1px solid #d3d5e4;
    margin-bottom: 12px;
    padding-bottom: 12px
}

.content-corrections__details.active .content-corrections__toggle {
    color: red;
    transform: rotate(180deg)
}

.content-corrections__details.active .content-corrections__section {
    display: block
}

.content-corrections msc-button i {
    font-size: .75rem;
    margin-right: 8px
}

.content-corrections msc-button.active {
    color: #fff;
    border-color: #5150c4;
    background-color: #5150c4
}

.content-corrections msc-button.active i {
    color: #fff
}

.content-corrections .box-session-correction {
    position: relative;
    cursor: pointer;
    padding: 16px;
    display: block;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    margin: 20px 0;
    transform: scale(1);
    transition: all .4s cubic-bezier(.175, .885, .32, 2)
}

@media screen and (min-width:48rem) {
    .content-corrections .box-session-correction {
        display: flex
    }
}

.content-corrections .box-session-correction .dynamic-table__cell {
    padding-left: 0;
    width: 30%
}

.content-corrections .box-session-correction .dynamic-table__cell.cell-actions {
    width: auto
}

.content-corrections .box-session-correction:hover {
    transform: scale(1.0025)
}

.content-corrections .box-session-correction:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.content-corrections .box-session-correction:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 8px;
    background: #d3d5e4;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.content-corrections .box-session-correction--done:after {
    background: #49e2af
}

.content-corrections .box-session-correction--progress:after {
    background: #8290fd
}

.content-corrections .box-session-correction--empty:after,
.content-corrections .box-session-correction--no-progress:after {
    background: unset
}

.content-corrections .box-session-correction__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 10
}

.content-corrections .box-session-correction__header i {
    font-size: 1.5rem;
    color: #f44436;
    margin: 0 20px 0 16px
}

.content-corrections .box-session-correction__title {
    position: relative;
    font-size: 1rem
}

.content-corrections .box-session-correction__title .box-session-correction__notification {
    position: absolute;
    top: 2px;
    left: -6px
}

.content-corrections .box-session-correction__notification {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #f09a9a
}

.content-corrections .box-session-correction__content {
    position: relative;
    z-index: 1;
    display: block
}

@media screen and (min-width:48rem) {
    .content-corrections .box-session-correction__content {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

.content-corrections .box-session-correction__breadcrumb i {
    font-size: 1.25rem;
    margin: 0 4px;
    color: #8c91b4
}

.content-corrections .box-session-correction__infos {
    z-index: 100
}

.content-corrections .box-session-correction__infos ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0
}

@media screen and (min-width:48rem) {
    .content-corrections .box-session-correction__infos ul {
        justify-content: space-between
    }
}

.content-corrections .box-session-correction__infos li {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin: 0 8px;
    color: #535a8b
}

.content-corrections .box-session-correction__infos li i {
    font-size: 1.375rem;
    color: #b2b5cd;
    margin-right: 4px
}

.content-corrections .box-session-correction__arrow {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.content-corrections .box-session-correction__arrow i {
    color: #d3d5e4;
    font-size: 1.25rem
}

.content-corrections .box-session-correction__arrow:hover {
    transform: translateY(-50%) scale(1.1)
}

.content-corrections .box-session-correction__progress {
    width: 160px
}

.content-corrections .progress-correction {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 7px;
    min-width: 145px;
    border-radius: 4px;
    background-color: #d3d5e4
}

.content-corrections .progress-correction__bar {
    position: absolute;
    width: auto;
    height: 7px
}

.content-corrections .progress-correction__bar:first-child {
    z-index: 5;
    border-radius: 4px;
    width: 33%
}

.content-corrections .progress-correction__bar:last-child {
    z-index: 1;
    border-radius: 4px;
    width: 66%
}

.content-corrections .progress-correction__bar--green {
    background-color: #49e2af
}

.content-corrections .progress-correction__bar--blue {
    background-color: #37beeb
}

.content-corrections .box-correction-user {
    cursor: pointer;
    position: relative;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    transition: all .3s ease
}

.content-corrections .box-correction-user.active {
    border-color: #8c91b4
}

.content-corrections .box-correction-user:hover {
    border-color: #8c91b4
}

.content-corrections .box-correction-user__avatar {
    margin-right: 50px
}

.content-corrections .box-correction-user__login {
    font-size: .875rem;
    color: #d3d5e4
}

.content-corrections .box-correction-user__arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%)
}

.content-corrections .box-correction-user__arrow i {
    font-size: 1.125rem;
    color: #d3d5e4
}

.content-corrections .box-correction-user:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: #d3d5e4
}

.content-corrections .box-correction-user--progress:before {
    background-color: #8290fd
}

.content-corrections .box-correction-user--success:before {
    background-color: #49e2af
}

.content-corrections .block-table-header__title {
    color: #8c91b4;
    font-size: .75rem;
    min-width: 200px
}

.content-corrections .block-table-header__title:first-child {
    min-width: calc(100% - 350px)
}

.content-corrections__body .box-session-correction-details__body box-session-correction-details__list .box-session-correction-details__arrow {
    display: none;
    background-color: transparent
}

.content-corrections-list,
.content-corrections-session {
    margin: -36px 0 0;
    padding-left: 0
}

@media screen and (min-width:64rem) {
    .content-corrections-list {
        padding-left: calc(.5em*6)
    }

    .content-corrections-session {
        padding-left: calc(.5em*6)
    }
}

.content-corrections-list .content-corrections__search {
    background: #fff
}

.content-corrections-session .content-corrections__search {
    background: #fff
}

.content-corrections-list .content-corrections__filter,
.content-corrections-session .content-corrections__filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.content-corrections-list .content-corrections__filter>*,
.content-corrections-session .content-corrections__filter>* {
    margin: 0 8px
}

.content-corrections-list .content-corrections__list,
.content-corrections-session .content-corrections__list {
    margin: -20px 0
}

.content-corrections-list .content-corrections__table,
.content-corrections-session .content-corrections__table {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(2, 13, 56, .08)
}

.content-corrections-list .box-session-correction-details,
.content-corrections-session .box-session-correction-details {
    position: relative
}

.content-corrections-list .box-session-correction-details__header,
.content-corrections-session .box-session-correction-details__header {
    cursor: pointer;
    display: block;
    flex-wrap: wrap;
    position: relative;
    padding: 16px 16px 60px;
    margin: 20px 0;
    min-height: 88px;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    border-radius: 4px
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__header,
    .content-corrections-session .box-session-correction-details__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px
    }
}

.content-corrections-list .box-session-correction-details__header i {
    font-size: 1.5rem;
    color: #f44436;
    margin: 0 20px 0 16px
}

.content-corrections-session .box-session-correction-details__header i {
    font-size: 1.5rem;
    color: #f44436;
    margin: 0 20px 0 16px
}

.content-corrections-list .box-session-correction-details__header:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.content-corrections-session .box-session-correction-details__header:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.content-corrections-list .box-session-correction-details__header .box-session-correction-details__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(.5em*5);
    background-color: #f8f9ff;
    transform: translateY(-50%);
    bottom: -14px;
    left: 0;
    padding: 0
}

.content-corrections-session .box-session-correction-details__header .box-session-correction-details__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(.5em*5);
    background-color: #f8f9ff;
    transform: translateY(-50%);
    bottom: -14px;
    left: 0;
    padding: 0
}

.content-corrections-list .box-session-correction-details__header .box-session-correction-details__arrow i,
.content-corrections-session .box-session-correction-details__header .box-session-correction-details__arrow i {
    transform: rotate(0);
    transition: transform .2s ease
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__header .box-session-correction-details__arrow,
    .content-corrections-session .box-session-correction-details__header .box-session-correction-details__arrow {
        left: auto;
        bottom: auto;
        right: 8px;
        top: 50%;
        background-color: transparent;
        width: auto;
        height: auto;
        display: block
    }
}

.content-corrections-list .box-session-correction-details__title {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.125rem;
    color: #8c91b4;
    margin-bottom: calc(.5em*1)
}

.content-corrections-session .box-session-correction-details__title {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.125rem;
    color: #8c91b4;
    margin-bottom: calc(.5em*1)
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__title,
    .content-corrections-session .box-session-correction-details__title {
        margin-bottom: 0
    }
}

.content-corrections-list .box-session-correction-details__notification {
    display: inline-flex;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #f09a9a;
    margin-right: 8px
}

.content-corrections-session .box-session-correction-details__notification {
    display: inline-flex;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #f09a9a;
    margin-right: 8px
}

.content-corrections-list .box-session-correction-details__desc,
.content-corrections-session .box-session-correction-details__desc {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__desc,
    .content-corrections-session .box-session-correction-details__desc {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: 40px;
        margin: 0 -25px
    }
}

@media screen and (min-width:80rem) {

    .content-corrections-list .box-session-correction-details__desc,
    .content-corrections-session .box-session-correction-details__desc {
        position: relative;
        padding-right: 60px;
        margin: 0 -25px
    }
}

.content-corrections-list .box-session-correction-details__desc>*,
.content-corrections-session .box-session-correction-details__desc>* {
    margin: 0 15px
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__desc>*,
    .content-corrections-session .box-session-correction-details__desc>* {
        margin: 0 25px
    }
}

.content-corrections-list .box-session-correction-details__progress {
    margin: calc(.5em*3) 0 !important
}

.content-corrections-session .box-session-correction-details__progress {
    margin: calc(.5em*3) 0 !important
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__progress,
    .content-corrections-session .box-session-correction-details__progress {
        margin: 0
    }
}

.content-corrections-list .box-session-correction-details__infos,
.content-corrections-session .box-session-correction-details__infos {
    margin: 0 -8px !important
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__infos,
    .content-corrections-session .box-session-correction-details__infos {
        margin: 0
    }
}

.content-corrections-list .box-session-correction-details__infos ul,
.content-corrections-session .box-session-correction-details__infos ul {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

@media screen and (min-width:64rem) {
    .content-corrections-list .box-session-correction-details__infos ul {
        justify-content: space-between;
        margin: 0 calc(.5em*5)
    }

    .content-corrections-session .box-session-correction-details__infos ul {
        justify-content: space-between;
        margin: 0 calc(.5em*5)
    }
}

.content-corrections-list .box-session-correction-details__infos li {
    margin: 0 8px;
    color: #535a8b
}

.content-corrections-session .box-session-correction-details__infos li {
    margin: 0 8px;
    color: #535a8b
}

@media screen and (min-width:32rem) {

    .content-corrections-list .box-session-correction-details__infos li,
    .content-corrections-session .box-session-correction-details__infos li {
        margin: 0 16px
    }
}

.content-corrections-list .box-session-correction-details__infos li i {
    color: #b2b5cd;
    margin-right: 4px;
    margin-left: 0;
    font-size: 1rem
}

.content-corrections-session .box-session-correction-details__infos li i {
    color: #b2b5cd;
    margin-right: 4px;
    margin-left: 0;
    font-size: 1rem
}

@media screen and (min-width:32rem) {

    .content-corrections-list .box-session-correction-details__infos li i,
    .content-corrections-session .box-session-correction-details__infos li i {
        margin-left: 8px;
        font-size: 1.375rem
    }
}

.content-corrections-list .box-session-correction-details__arrow,
.content-corrections-session .box-session-correction-details__arrow {
    cursor: pointer;
    transform: translateX(0) rotate(180deg);
    position: absolute
}

.content-corrections-list .box-session-correction-details__arrow i {
    color: #d3d5e4;
    font-size: 1.25rem
}

.content-corrections-session .box-session-correction-details__arrow i {
    color: #d3d5e4;
    font-size: 1.25rem
}

.content-corrections-list .box-session-correction-details__content {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding: calc(.5em*1)
}

.content-corrections-session .box-session-correction-details__content {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding: calc(.5em*1)
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__content,
    .content-corrections-session .box-session-correction-details__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 80px 0 0
    }
}

.content-corrections-list .box-session-correction-details__content .box-session-correction-details__title {
    font-size: .875rem;
    color: #7d83aa
}

.content-corrections-session .box-session-correction-details__content .box-session-correction-details__title {
    font-size: .875rem;
    color: #7d83aa
}

.content-corrections-list .box-session-correction-details__content .box-session-correction-details__title i {
    color: #d3d5e4;
    margin: 0 16px 0 0
}

.content-corrections-session .box-session-correction-details__content .box-session-correction-details__title i {
    color: #d3d5e4;
    margin: 0 16px 0 0
}

.content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: calc(.5em*1.5) 0
}

.content-corrections-session .box-session-correction-details__content .box-session-correction-details__infos {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: calc(.5em*1.5) 0
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos,
    .content-corrections-session .box-session-correction-details__content .box-session-correction-details__infos {
        justify-content: space-between;
        margin: 0
    }
}

.content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos li,
.content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos li i,
.content-corrections-session .box-session-correction-details__content .box-session-correction-details__infos li,
.content-corrections-session .box-session-correction-details__content .box-session-correction-details__infos li i {
    font-size: .875rem
}

.content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos li .icon-checkmark-circle-2 {
    color: #49e2af
}

.content-corrections-session .box-session-correction-details__content .box-session-correction-details__infos li .icon-checkmark-circle-2 {
    color: #49e2af
}

.content-corrections-list .box-session-correction-details__content .box-session-correction-details__progress {
    font-size: .875rem;
    color: #7d83aa;
    margin-left: calc(.5em*2)
}

.content-corrections-session .box-session-correction-details__content .box-session-correction-details__progress {
    font-size: .875rem;
    color: #7d83aa;
    margin-left: calc(.5em*2)
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__content .box-session-correction-details__progress,
    .content-corrections-session .box-session-correction-details__content .box-session-correction-details__progress {
        margin-left: 0
    }
}

.content-corrections-list .box-session-correction-details__content .box-session-correction-details__arrow,
.content-corrections-session .box-session-correction-details__content .box-session-correction-details__arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%)
}

.content-corrections-list .box-session-correction-details__content .box-session-correction-details__arrow i,
.content-corrections-session .box-session-correction-details__content .box-session-correction-details__arrow i {
    font-size: 1rem
}

.content-corrections-list .box-session-correction-details__list,
.content-corrections-session .box-session-correction-details__list {
    display: none;
    margin: 0 -12px -12px
}

.content-corrections-list .box-session-correction-details__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 8px;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    margin: 8px;
    min-height: 74px
}

.content-corrections-session .box-session-correction-details__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 8px;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    margin: 8px;
    min-height: 74px
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__item,
    .content-corrections-session .box-session-correction-details__item {
        margin: 12px 12px 12px 50px
    }
}

.content-corrections-list .box-session-correction-details__item i {
    font-size: 1.5rem;
    color: #f44436;
    margin: 0 20px 0 16px
}

.content-corrections-session .box-session-correction-details__item i {
    font-size: 1.5rem;
    color: #f44436;
    margin: 0 20px 0 16px
}

.content-corrections-list .box-session-correction-details__item:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-radius: 4px
}

.content-corrections-session .box-session-correction-details__item:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-radius: 4px
}

.content-corrections-list .box-session-correction-details__item:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background: #4443af;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.content-corrections-session .box-session-correction-details__item:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background: #4443af;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.content-corrections-list .box-session-correction-details__item--done:after {
    background: #49e2af
}

.content-corrections-session .box-session-correction-details__item--done:after {
    background: #49e2af
}

.content-corrections-list .box-session-correction-details__item--progress:after {
    background: #8290fd
}

.content-corrections-session .box-session-correction-details__item--progress:after {
    background: #8290fd
}

.content-corrections-list .box-session-correction-details__item--empty:after {
    background: #b2b5cd
}

.content-corrections-session .box-session-correction-details__item--empty:after {
    background: #b2b5cd
}

.content-corrections-list .box-session-correction-details__item--no-progress:after,
.content-corrections-session .box-session-correction-details__item--no-progress:after {
    background: unset
}

.content-corrections-list .box-session-correction-details__item .box-session-correction-details__infos ul,
.content-corrections-session .box-session-correction-details__item .box-session-correction-details__infos ul {
    margin: 8px 0
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__item .box-session-correction-details__infos ul,
    .content-corrections-session .box-session-correction-details__item .box-session-correction-details__infos ul {
        margin: 0 32px 0 16px
    }
}

.content-corrections-list .box-session-correction-details__item .box-session-correction-details__infos li,
.content-corrections-session .box-session-correction-details__item .box-session-correction-details__infos li {
    margin: 0 4px
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__item .box-session-correction-details__infos li,
    .content-corrections-session .box-session-correction-details__item .box-session-correction-details__infos li {
        margin: 0
    }
}

.content-corrections-list .box-session-correction-details__item .box-session-correction-details__progress,
.content-corrections-session .box-session-correction-details__item .box-session-correction-details__progress {
    position: absolute;
    top: 25px;
    left: 180px
}

@media screen and (min-width:64rem) {

    .content-corrections-list .box-session-correction-details__item .box-session-correction-details__progress,
    .content-corrections-session .box-session-correction-details__item .box-session-correction-details__progress {
        position: static
    }
}

.content-corrections-list .box-session-correction-details .box-session-correction-details__arrow a,
.content-corrections-session .box-session-correction-details .box-session-correction-details__arrow a {
    transition: all .2s ease;
    transform: rotate(0deg)
}

.content-corrections-list .box-session-correction-details .box-session-correction-details__arrow i {
    color: #535a8b
}

.content-corrections-session .box-session-correction-details .box-session-correction-details__arrow i {
    color: #535a8b
}

.content-corrections-list .box-session-correction-details.active .box-session-correction-details__arrow a,
.content-corrections-session .box-session-correction-details.active .box-session-correction-details__arrow a {
    transform: rotate(180deg)
}

.content-corrections-list .box-session-correction-details.active .box-session-correction-details__arrow i {
    color: #8c91b4
}

.content-corrections-session .box-session-correction-details.active .box-session-correction-details__arrow i {
    color: #8c91b4
}

.content-corrections-list .box-session-correction-details.active .box-session-correction-details__list,
.content-corrections-session .box-session-correction-details.active .box-session-correction-details__list {
    display: block
}

.content-corrections-list .box-session-correction-details .dynamic-table__row,
.content-corrections-session .box-session-correction-details .dynamic-table__row {
    background: transparent;
    padding: 0;
    margin: 0
}

.content-corrections-list .box-session-correction-details .dynamic-table__content,
.content-corrections-session .box-session-correction-details .dynamic-table__content {
    width: auto
}

.content-corrections-list .box-session-correction-details .dynamic-table__cell.cell-large,
.content-corrections-session .box-session-correction-details .dynamic-table__cell.cell-large {
    width: 100%
}

@media screen and (min-width:32rem) {

    .content-corrections-list .box-session-correction-details .dynamic-table__cell.cell-large,
    .content-corrections-session .box-session-correction-details .dynamic-table__cell.cell-large {
        width: 30%
    }
}

.content-corrections-details {
    margin: -36px 0 0;
    padding-left: 0
}

@media screen and (min-width:64rem) {
    .content-corrections-details {
        padding-left: calc(.5em*6)
    }
}

.content-corrections-details .content-corrections__main {
    padding: 20px;
    background: #fff;
    margin: 0
}

.content-corrections-details .content-corrections__details {
    padding: 20px;
    background: #fbfbfb;
    border-radius: 4px;
    margin: 20px 0
}

.content-corrections-details .content-corrections__details p {
    font-size: .875rem;
    color: #7d83aa
}

.content-corrections-details .content-corrections__nav {
    margin: 16px 0
}

.content-corrections-details .content-corrections__title {
    margin-bottom: 0;
    padding-bottom: 0
}

.content-corrections-details .content-corrections__title h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    color: #7d83aa
}

.content-corrections-details .content-corrections__title h3 i {
    font-size: 1.25rem;
    color: #7d83aa;
    margin-right: 8px
}

.content-corrections-details .content-corrections__title i {
    font-size: 1.5rem;
    color: #535a8b
}

.content-corrections-details .content-corrections__evaluation h4 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    color: #7d83aa;
    margin: 16px 0
}

.content-corrections-details .content-corrections__input h4 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    color: #7d83aa;
    margin: 16px 0
}

.content-corrections-details .content-corrections__evaluation h4:before {
    content: "•";
    font-size: .75rem;
    color: #7d83aa;
    margin-right: 6px
}

.content-corrections-details .content-corrections__input h4:before {
    content: "•";
    font-size: .75rem;
    color: #7d83aa;
    margin-right: 6px
}

.content-corrections-details .content-corrections__evaluation h4 span {
    font-size: .875rem;
    color: #b2b5cd;
    margin-left: 6px
}

.content-corrections-details .content-corrections__input h4 span {
    font-size: .875rem;
    color: #b2b5cd;
    margin-left: 6px
}

.content-corrections-details .content-corrections__evaluation h4 msc-button,
.content-corrections-details .content-corrections__input h4 msc-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%)
}

.content-corrections-details .content-corrections__resources {
    margin: 20px -8px;
    padding-top: 0
}

.content-corrections-details .content-corrections__resources>* {
    margin: 0 8px
}

.content-corrections-details .content-corrections__search {
    background: #fff
}

.content-corrections-details .comment {
    display: flex;
    align-items: flex-start;
    margin: 0 -8px
}

.content-corrections-details .comment>* {
    margin: 0 8px
}

.content-corrections-details .comment__name {
    font-family: Circular Std Book;
    color: #020d38;
    margin: 8px 0
}

.content-corrections-details .comment__paragraph {
    font-size: .875rem;
    color: #b2b5cd
}

.content-corrections-details .comment__date {
    margin: 8px 0;
    white-space: nowrap
}

.content-corrections-session .content-corrections__list {
    margin: 16px 0
}

.content-corrections-session .content-corrections__list .box-session-correction__arrow a {
    transition: all .2s ease;
    transform: rotate(90deg)
}

.content-corrections-session .content-corrections__list .box-session-correction__arrow i {
    color: #535a8b
}

.content-corrections-session .box-session-correction-details__list {
    margin-bottom: 24px
}

.content-corrections-session .box-session-correction-details__list .cell-actions {
    transform: translateY(-50%) rotate(180deg)
}

.content-corrections-session .content-corrections__list.active .box-session-correction-details__list {
    display: block
}

.content-corrections-session .content-corrections__list.active .box-session-correction-details__infos li {
    display: flex
}

.content-corrections-session .content-corrections__list.active .box-session-correction-details__item {
    margin-left: 40px
}

.content-corrections-session .content-corrections__list.active .box-session-correction-details__arrow {
    padding-right: 0 !important;
    right: 0
}

.content-corrections-session .content-corrections__list.active .box-session-correction__arrow a {
    transform: rotate(270deg)
}

.content-corrections-session .content-corrections__list.active .box-session-correction__arrow i {
    color: #b2b5cd
}

.container-sessions {
    position: relative;
    height: auto;
    margin-top: calc(.5em*2)
}

@media screen and (min-width:64rem) {
    .container-sessions {
        margin-top: 0
    }
}

.container-sessions__body {
    margin-top: 12px
}

.container-sessions__main {
    margin-top: calc(.5em*3.5)
}

.container-sessions__back {
    position: relative;
    top: 0;
    left: 0
}

@media screen and (min-width:64rem) {
    .container-sessions__back {
        position: absolute;
        top: 0;
        left: -15px
    }
}

@media screen and (min-width:80rem) {
    .container-sessions__back {
        top: 12px;
        left: -45px
    }
}

.container-sessions__row {
    position: relative;
    padding-left: 0
}

@media screen and (min-width:64rem) {
    .container-sessions__row {
        padding-left: 20px
    }
}

.container-sessions__aside {
    margin-bottom: calc(.5em*3)
}

@media screen and (min-width:64rem) {
    .container-sessions__aside {
        margin-bottom: 0
    }
}

.navigation-filter {
    padding: 15px 0;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.navigation-filter--white {
    border-bottom: 1px solid rgba(255, 255, 255, .5) !important
}

.navigation-filter--white .separator__actions__text i {
    color: #fff !important
}

.navigation-filter__separator {
    justify-content: space-between;
    width: 100%
}

.navigation-filter__separator,
.navigation-filter__separator .separator__filters {
    display: flex;
    align-items: center
}

.navigation-filter__separator .separator__filters msc-button {
    margin: 0 0 0 15px;
    height: 30px;
    z-index: 10
}

.navigation-filter__separator .separator__filters__item {
    position: relative;
    z-index: 10
}

.navigation-filter__separator .separator__filters__item .daterangepicker--menu {
    top: 40px;
    left: -80px
}

.navigation-filter__separator .separator__filters__item .daterangepicker--menu.daterangepicker:before {
    left: 120px
}

.navigation-filter__separator .separator__actions {
    display: flex;
    align-items: center
}

.navigation-filter__separator .separator__actions--background .divider {
    background-color: rgba(255, 255, 255, .5) !important
}

.navigation-filter__separator .separator__actions .divider {
    width: 1px;
    height: 18px;
    background-color: rgba(211, 213, 228, .5)
}

.navigation-filter__separator .separator__actions .divider--margin {
    margin: 0 20px
}

.navigation-filter__separator .separator__actions__select .selector {
    cursor: pointer;
    min-width: 180px;
    height: 30px;
    border: none;
    border-radius: 4px;
    position: relative;
    background: #fff;
    margin: 0;
    padding: 12px;
    color: #8c91b4;
    font-size: .875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.navigation-filter__separator .separator__actions__select .selector.active .selector__icon i:before {
    transform: rotate(-180deg);
    transition: all .3s ease
}

.navigation-filter__separator .separator__actions__select .selector__icon {
    margin: 0 0 0 .5rem
}

.navigation-filter__separator .separator__actions__select .selector__icon i {
    font-size: 1.125rem;
    transition: all .3s ease
}

.navigation-filter__separator .separator__actions__select .selector__icon i:before {
    transition: all .3s ease
}

.navigation-filter__separator .separator__actions__select .selector:hover .selector__icon i {
    color: #535a8b;
    transition: all .3s ease
}

.navigation-filter__separator .separator__actions__select .selector .dropdown {
    top: 31px;
    left: 0;
    width: 100%;
    min-width: 180px
}

.navigation-filter__separator .separator__actions__later {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #343392;
    border-radius: 50%;
    margin: 0 20px 0 0;
    -webkit-animation: none;
    animation: none;
    transition: all .3s ease
}

.navigation-filter__separator .separator__actions__later .icon {
    font-size: 1rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.navigation-filter__separator .separator__actions__later .icon span {
    position: absolute;
    background-color: #020d38;
    color: #fff;
    font-size: .75rem;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    margin-top: .5rem;
    top: 40px;
    right: -10px;
    visibility: hidden;
    font-family: Circular Std Book;
    font-weight: 500;
    z-index: 1
}

.navigation-filter__separator .separator__actions__later:hover {
    -webkit-animation: pulsing-blue 2s infinite;
    animation: pulsing-blue 2s infinite;
    background-color: #17105a;
    transition: all .3s ease
}

.navigation-filter__separator .separator__actions__later:hover span {
    visibility: visible
}

.navigation-filter__separator .separator__actions__extension {
    margin: 0 20px 0 0
}

.navigation-filter__separator .separator__actions__extension msc-button {
    -webkit-animation: none;
    animation: none
}

.navigation-filter__separator .separator__actions__extension msc-button:hover {
    -webkit-animation: pulsing-blue 2s infinite;
    animation: pulsing-blue 2s infinite
}

.navigation-filter__separator .separator__actions__extension i {
    font-size: 1.125rem
}

.navigation-filter__separator .separator__actions__text {
    margin: 0 20px
}

.navigation-filter__separator .separator__actions__text i {
    font-size: 1.125rem;
    color: #343392
}

@-webkit-keyframes pulsing-blue {
    0% {
        box-shadow: 0 0 0 0 rgba(68, 67, 175, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(68, 67, 175, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(68, 67, 175, 0)
    }
}

@keyframes pulsing-blue {
    0% {
        box-shadow: 0 0 0 0 rgba(68, 67, 175, .4)
    }

    70% {
        box-shadow: 0 0 0 10px rgba(68, 67, 175, 0)
    }

    to {
        box-shadow: 0 0 0 0 rgba(68, 67, 175, 0)
    }
}

.team-member {
    display: flex;
    flex-direction: column
}

.team-member__header {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.team-member__header msc-button {
    margin-left: calc(.5em*2)
}

.team-member__navigation {
    display: flex;
    align-items: center;
    justify-content: center
}

.team-member__navigation__container {
    display: flex
}

.team-member__navigation__container .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(.5em*2)
}

.team-member__navigation__container .navigation a {
    padding: calc(.5em*0.5);
    background: #d3d5e4;
    border-radius: 100%
}

.team-member__navigation__container .navigation a i {
    color: #414776;
    box-shadow: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem
}

.team-member__navigation__container .current-user {
    display: flex;
    padding: calc(.5em*2);
    width: 350px
}

.team-member__navigation__container .current-user__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: calc(.5em*2)
}

.team-member__navigation__container .current-user__details p {
    font-size: 1.125rem;
    color: #020d38;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
    text-overflow: ellipsis
}

.team-member__navigation__container .current-user__details div {
    font-size: .875rem;
    color: #656c9b
}

.team-member__navigation__container .current-user__job {
    font-size: .875rem;
    color: #b2b5cd;
    margin-left: auto
}

.team-member__filters {
    display: flex
}

.team-member__filters.navigation-filter {
    border-bottom: none
}

.team-member__filters.navigation-filter .separator__filters .form-search {
    padding: 5px 10px;
    border-width: 1px
}

.team-member__filters.navigation-filter .separator__filters .separator__filters__item {
    z-index: 3
}

.team-member__filters.navigation-filter .separator__filters .separator__filters__item>msc-button {
    border: 1px solid #d3d5e4;
    color: #464c79
}

.team-member__filters.navigation-filter .separator__filters .separator__filters__item>msc-button.active {
    color: #fff
}

.team-member__filters.navigation-filter .separator__filters .separator__filters__item>msc-button:hover {
    color: #fff
}

.team-member__filters.navigation-filter .separator__filters .separator__filters__item>msc-button i {
    color: #414776
}

.team-member__filters.navigation-filter .separator__filters .separator__filters__item>msc-button span {
    font-weight: 700;
    margin-right: calc(.5em*1.5)
}

.team-member__filters.navigation-filter .separator__filters .separator__filters__item>.checkbox-filter {
    border: 1px solid #d3d5e4;
    display: flex;
    align-items: center;
    color: #464c79;
    margin: 0 0 0 15px;
    padding: 5px 15px;
    background-color: #fff;
    border-radius: 4px
}

.team-member__board {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden
}

.team-member__board .board__row {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column
}

.team-member__board .board__row:nth-child(2) {
    border-right: 1px;
    border-left: 1px;
    border-color: #e5e6e9;
    border-style: solid
}

.team-member__board .board__row .row__header {
    display: flex;
    align-items: center;
    margin-bottom: calc(.5em*1.5);
    padding: calc(.5em*1.5)
}

.team-member__board .board__row .row__header .tag__text {
    color: #464c79;
    font-weight: 700
}

.team-member__board .board__row .row__header h3 {
    font-size: 1.25rem;
    font-weight: 700;
    padding-right: calc(.5em*2);
    color: #464c79
}

.team-member__board .board__row .row__header .navigation-filter {
    padding: 0;
    margin: 0;
    border: 0;
    justify-content: flex-end
}

.team-member__board .board__row .row__header .navigation-filter .navigation-filter__separator {
    width: auto
}

.team-member__board .board__row .row__header .navigation-filter .navigation-filter__separator .selector {
    border: 1px solid #d3d5e4
}

.team-member__board .board__row .row__header .navigation-filter .navigation-filter__separator .selector__content {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.team-member__board .board__row .row__container {
    overflow-y: auto;
    overflow-x: hidden;
    padding: calc(.5em*1.5)
}

.team-member__board .board__row .row__item {
    margin-bottom: calc(.5em*1.5);
    border: 1px solid #eaecf4;
    border-radius: 6px;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    width: 100%
}

.team-member__board .board__row .row__item .item__header {
    border-radius: 6px 6px 0 0;
    padding: calc(.5em*1);
    background: #f5f6fa;
    display: flex;
    align-items: center
}

.team-member__board .board__row .row__item .item__header__icon {
    padding: calc(.5em*1.5);
    min-width: 2.5rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 2rem
}

.team-member__board .board__row .row__item .item__header__icon i {
    font-size: 1rem;
    color: #414776
}

.team-member__board .board__row .row__item .item__header__details p {
    font-size: .875rem;
    color: #020d38;
    font-weight: 700
}

.team-member__board .board__row .row__item .item__header__details div {
    font-size: .75rem;
    color: #414776
}

.team-member__board .board__row .row__item .item__header__help {
    margin-left: auto;
    align-self: flex-start;
    display: flex
}

.team-member__board .board__row .row__item .item__header__help a {
    display: flex
}

.team-member__board .board__row .row__item .item__header__help a i {
    color: #c0c3d9;
    font-size: .875rem;
    margin-left: calc(.5em*0.5)
}

.team-member__board .board__row .row__item .item__content {
    border-radius: 0 0 6px 6px;
    padding: calc(.5em*1) calc(.5em*1) calc(.5em*2) calc(.5em*1);
    background: #fff;
    display: flex;
    align-items: center
}

.team-member__board .board__row .row__item .item__content__icon {
    padding: calc(.5em*1.5);
    min-width: 2.5rem
}

.team-member__board .board__row .row__item .item__content__description p {
    font-size: .75rem;
    color: #414776
}

.team-member__board .board__row .row__item .item__content__description p span {
    font-weight: 700
}

.team-member__board .board__row .row__item .item__content__description p.item__content__content-refused {
    color: #ffa2a2;
    font-weight: 700
}

.team-member__board .board__row .row__item .item__content__description p.item__content__content-refused i {
    margin-right: 5px
}

.team-member__board .board__row .row__item .item__content__actions {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end
}

.team-member__board .board__row .row__item .item__content__actions img {
    margin-bottom: calc(.5em*1)
}

.team-member__board .board__row .row__item .item__content__actions msc-button {
    font-size: .75rem;
    margin-left: calc(.5em*1)
}

.team-member__board .board__row .row__item .item__content__progress {
    margin: 0 calc(.5em*1) 0 2.5rem
}

.team-member__board .board__row .row__item .item__progress {
    height: 6px;
    display: flex;
    background: #eaecf4
}

.team-member__board .board__row .row__item .item__progress__status {
    width: 0;
    height: 6px;
    background: #6471d0
}

.team-member__board .board__row .row__item__separator {
    width: 100%;
    border-bottom: 1px solid #d3d5e4;
    margin: calc(.5em*2) 0
}

.team-members__actions {
    display: flex;
    flex-direction: column
}

.team-members__actions__top {
    display: flex
}

.team-members__actions__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 10px
}

.team-members__actions__bottom div {
    color: #414776;
    display: flex;
    align-items: center;
    font-size: 14px
}

.team-members__actions__bottom div i {
    font-size: 25px;
    margin-right: 10px
}

.team-members__actions__bottom msc-button {
    display: flex;
    align-items: center;
    font-size: .875rem
}

.team-members__actions__bottom msc-button i {
    font-size: 1.1rem
}

.team-members__actions msc-button__filter {
    box-shadow: none;
    border: 1px solid #d3d5e4
}

.team-members .form-search--square {
    border: 1px solid #d3d5e4
}

.team-members .team-members__dropdown-filter {
    margin-left: 15px;
    min-width: 240px
}

.team-members .multi-select__content {
    padding-left: 10px
}

.team-members .multi-select__content .icon {
    color: #4443af;
    font-size: 1.45rem;
    margin-right: 5px
}

.team-members .multi-select__container .multi-select__options .options__item {
    margin-right: 0;
    padding-left: calc(.5em*0.5)
}

.team-members .select {
    border: 1px solid #d3d5e4
}

.team-members .select.active {
    border: 2px solid #5150c4
}

.team-members .select.active:after {
    color: #5150c4
}

.team-members .select:after {
    top: auto
}

.team-members .select .select__content {
    font-size: .875rem
}

.team-members .select .select__dropdown {
    position: absolute;
    top: calc(100% + 8px);
    transition: all .3s ease;
    width: 100%;
    z-index: 10
}

.team-members__table {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(2, 13, 56, .08)
}

.team-members__table .dynamic-table .dynamic-table__row {
    min-height: 70px
}

.team-members__table .dynamic-table .dynamic-table__cell {
    font-size: 14px;
    word-break: break-all
}

.team-members__table .dynamic-table .dynamic-table__cell.cell-head-icon i {
    font-size: 1.45rem
}

.team-members__table .dynamic-table .cell__avatar-and-name {
    display: flex;
    align-items: center
}

.team-members__table .dynamic-table .cell__avatar-and-name div {
    color: #020d38;
    font-size: 16px;
    margin-left: 10px
}

.team-members__table .dynamic-table .cell__action-to-process {
    background-color: #4443af;
    color: #fff;
    border-radius: 15px;
    font-size: .65rem;
    padding: 2px 10px
}

.team-members__table .dynamic-table__head {
    padding: 8px 30px
}

.team-members__table .dynamic-table__body {
    height: calc(100vh - 500px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px
}

.team-members__table .dynamic-table__body .dynamic-table__cell {
    color: #656c9b
}

.team-members__footer {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 25px;
    position: absolute;
    left: 285px;
    bottom: 0;
    width: calc(100% - 285px);
    color: #b2b5cd
}

@media screen and (min-width:20rem) {
    .team-members__footer {
        left: 0;
        width: 100%
    }
}

@media screen and (min-width:80rem) {
    .team-members__footer {
        left: 285px;
        width: calc(100% - 285px)
    }
}

.team-members__footer>div {
    flex-grow: 1
}

.team-members__footer .team-members__footer__info {
    display: flex;
    align-items: center;
    text-transform: uppercase
}

.team-members__footer .team-members__footer__info p {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 700
}

.team-members__footer .team-members__footer__info span {
    color: #ffa2a2;
    font-size: 18px
}

.team-members__footer .team-members__footer__icons {
    cursor: pointer;
    display: flex
}

.team-members__footer .team-members__footer__icons>div {
    margin: 0 10px
}

.team-members__footer .icon {
    font-size: 30px
}

.input {
    position: relative;
    padding: 0;
    height: auto;
    border: none;
    background: #fff;
    border-radius: 4px
}

.input__label {
    display: block;
    color: #b2b5cd;
    margin-bottom: 4px;
    font-size: .875rem
}

.input__text {
    width: 100%;
    height: 100%;
    color: #535a8b;
    border-radius: 4px;
    border: 2px solid #d3d5e4;
    padding: 12px;
    font-size: .875rem;
    background: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all .3s ease
}

.input__text:focus {
    border: 2px solid #5150c4
}

.input__text::-moz-placeholder {
    font-family: Circular Std Book !important;
    color: #7d83aa
}

.input__text:-ms-input-placeholder {
    font-family: Circular Std Book !important;
    color: #7d83aa
}

.input__text::placeholder {
    font-family: Circular Std Book !important;
    color: #7d83aa
}

.input__password {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%
}

.input__password .input__text:focus {
    border: 2px solid #5150c4
}

.input__password .input__icon {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%)
}

.input__password .input__icon .icon {
    font-size: 1.5rem;
    transition: all .3s ease
}

.input__favicon {
    float: left;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center
}

.input__favicon img {
    width: 16px;
    height: 16px
}

.input__favicon+.input__text {
    width: calc(100% - 45px)
}

.input__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #7d83aa;
    font-size: 1.25rem;
    display: none;
    padding: 8px;
    margin-left: -12px
}

.input__icon--displayed {
    cursor: pointer;
    display: block;
    right: 0
}

.input__icon--displayed i {
    transition: all .3s ease
}

.input__icon--displayed:hover i {
    color: #ed8371 !important
}

.input__notification {
    color: #f44436;
    font-size: .75rem;
    display: none;
    transition: display .3s ease;
    margin: 5px 0
}

.input--icon-left .input__text {
    padding: 12px 12px 12px 36px
}

.input--icon-left .input__icon {
    left: 14px;
    display: block
}

.input--icon-right .input__text {
    padding: 12px 36px 12px 12px
}

.input--icon-right .input__icon {
    left: auto;
    right: 0;
    display: block
}

.input--light .input__label {
    color: #b2b5cd;
    font-size: .875rem
}

.input--light .input__text {
    border: 1px solid #d3d5e4;
    color: #8c91b4
}

.input--link {
    display: flex;
    align-items: center;
    justify-content: center
}

.input--error .input__text {
    border: 2px solid #f44436
}

.input--error .input__icon {
    left: auto;
    right: 0;
    display: block
}

.input--error .input__icon i {
    color: #f44436
}

.input--error .input__notification {
    display: block
}

.input--error .input__message {
    color: #f44436 !important;
    margin-top: 1rem;
    line-height: 1rem;
    font-size: .875rem
}

.input--success .input__text {
    border: 2px solid #6cd4a8
}

.input--success .input__icon {
    left: auto;
    right: 0;
    display: block
}

.input--success .input__icon i {
    color: #6cd4a8
}

.input--disabled .input__text {
    cursor: not-allowed;
    background: rgba(211, 213, 228, .3)
}

.input--custom {
    background: #fff
}

.input--custom .input__text {
    border: none
}

.input--custom .input__icon {
    color: #feb88a
}

.input--no-border .input__text {
    border: none
}

.select {
    position: relative;
    height: 45px;
    background: #fff;
    margin: 0;
    padding: 12px;
    border-radius: 4px;
    border: 2px solid #d3d5e4;
    color: #b2b5cd;
    font-size: .875rem;
    display: flex;
    align-items: center;
    cursor: pointer
}

.select--tiny {
    height: 30px
}

.select.active {
    border-color: #6c6bd4
}

.select.active:after {
    transform: rotate(180deg)
}

.select:after {
    font-family: myskillcamp;
    display: inline-block;
    content: "\e80b";
    font-size: 1.25rem;
    color: #d3d5e4;
    right: 10px;
    top: 10px;
    transform: rotate(0deg);
    transition: all .3s ease
}

.select:after,
.select:before {
    position: absolute;
    pointer-events: none
}

.select:before {
    width: 30px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    content: ""
}

.select:before {
    background: #fff
}

.select:hover:before {
    background: #fff
}

.select:focus-within {
    border: 2px solid #6c6bd4 !important
}

.select select {
    font-size: .875rem;
    color: #7d83aa;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    width: 100%;
    height: inherit !important;
    cursor: pointer;
    outline: none;
    padding-right: 0;
    padding-left: 0
}

.select select:focus {
    border-color: #7d83aa
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .select select {
        outline: 1px solid #d3d5e4
    }
}

.select__text {
    color: #8c91b4
}

.select--tiny {
    padding: 2px;
    font-size: .75rem
}

.select--no-border {
    border: none
}

.select--shadow {
    box-shadow: 0 13px 24px 0 rgba(0, 0, 0, .03)
}

.select--shadow-sm {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.select__lang {
    display: flex;
    align-items: center
}

.select__lang>img {
    width: 25px;
    margin-right: .5rem
}

.select__camp .radio__container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.select__camp input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    border: 1px solid #7d83aa
}

.select__camp .radio__checkbox {
    position: absolute;
    top: 0;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #7d83aa
}

.select__camp input:checked~.radio__checkbox {
    background-color: #261e6d;
    border: none
}

.select__camp .radio__checkbox:after {
    content: "";
    position: absolute;
    display: none
}

.select__camp input:checked~.radio__checkbox:after {
    display: block
}

.select__camp .radio__checkbox:after {
    left: 0;
    top: 1px;
    font-size: 1.125rem;
    font-family: myskillcamp;
    content: "\e855";
    color: #fff
}

.input--flag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%
}

.input--flag .input__text {
    padding: 12px 36px
}

.input--flag .input__icon {
    right: 12px;
    display: block
}

.input--flag .input__icon i {
    font-size: 1.125rem
}

.input--flag .input__flag {
    left: 6px;
    width: 24px;
    height: auto
}

.input--flag .input__flag,
.input--flag .input__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.input--flag .input__icon {
    cursor: pointer;
    right: 0
}

.input--flag .select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%
}

.input--flag .select__text {
    padding-left: 28px;
    color: #8c91b4
}

.input--flag__warning {
    margin: 0 0 0 1rem
}

.input--flag__warning i {
    font-size: 1.375rem;
    color: #e57373
}

.input .select {
    transition: .3s ease
}

.input.active .select {
    border: 2px solid #5150c4
}

.input.active .select:after {
    color: #5150c4;
    transform: rotate(180deg)
}

.invalid-feedback {
    color: #f44436 !important;
    margin-top: 1rem;
    line-height: 1rem;
    font-size: .875rem
}

.team-settings {
    margin: 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    padding: calc(.5em*2.5)
}

.team-settings .separator {
    width: 100%;
    border-bottom: 1px solid #ebeff4;
    margin: calc(.5em*2.5) 0
}

.team-settings .setting {
    padding: calc(.5em*2);
    color: #656c9b
}

.team-settings .setting__icon {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-right: calc(.5em*0.5);
    font-size: 1.875rem
}

.team-settings .setting__label p {
    font-size: .875rem;
    margin: calc(.5em*1) 0
}

.team-settings .setting__label span {
    font-size: .75rem
}

.team-settings .setting__action {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-left: auto
}

.team-settings .setting .input {
    width: 100%
}

.team-settings .setting .input__text {
    padding: 0 calc(.5em*1.5)
}

.team-settings .setting .input__icon {
    display: block;
    transform: none;
    margin: 0;
    right: 0;
    cursor: pointer
}

.team-settings .setting .input .icon-close {
    color: #ffa2a2;
    cursor: default
}

.team-settings .media__avatar .input-upload-media__container {
    width: 10em;
    height: 10em
}

.team-settings .media__avatar .input-image-loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.team-settings .input-upload-media__desc {
    color: #b2b5cd;
    font-size: .875rem
}

.team-settings .input-upload-media__label {
    color: #b2b5cd;
    font-size: .875rem
}

.team-settings .input-upload-media__label {
    margin: 0 0 .5rem
}

.team-settings .input-upload-media__desc {
    color: #7d83aa;
    margin-bottom: 4px
}

.team-settings .input-upload-media__container {
    position: relative;
    width: 100%;
    height: 10em;
    border-radius: 4px;
    border: 2px solid #d3d5e4;
    padding: 20px;
    background-color: #fff
}

.team-settings .input-upload-media__container:hover .input-upload-media__buttons {
    background-color: #5360bf;
    transform: scale(1.025);
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.team-settings .input-upload-media__image {
    width: 100%;
    height: 100%;
    opacity: .6;
    background-size: cover;
    background-position: 50%
}

.team-settings .input-upload-media__buttons {
    cursor: pointer;
    position: absolute;
    right: -16px;
    bottom: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    font-size: 1.625rem;
    border-radius: 50%;
    background-color: #6471d0;
    color: #fff;
    transform: scale(1);
    box-shadow: none;
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.container-teams .container-teams__title {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px
}

.container-teams .container-teams__title h2 {
    color: #020d38;
    font-size: 1.375rem
}

.container-teams .container-teams__title div {
    color: #656c9b;
    display: flex;
    align-items: center
}

.container-teams .container-teams__title i {
    font-size: 25px;
    margin-right: 10px
}

.container-teams .nav-teams {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    padding: 20px 30px 20px 0
}

.container-teams .nav-teams .nav-teams__item {
    color: #656c9b;
    cursor: pointer;
    font-size: .875rem;
    padding: 15px 0 15px 35px;
    transition: all .3s ease
}

.content-camps__members .content-camps__list .cell-actions i {
    font-size: 1rem;
    color: #8c91b4;
    transition: all .3s ease
}

.content-camps__members .content-camps__list .cell-actions i:hover {
    color: #535a8b
}

.content-camps__members .content-camps__list .cell-actions .dropdown {
    right: 0;
    min-width: 0
}

.content-camps__members .content-camps__list .cell-actions .dropdown .item__action a {
    display: flex
}

.content-camps__members .content-camps__list .cell-actions .dropdown .item__action a i {
    margin-right: .2rem
}

.content-camps__sessions .content-camps__title {
    display: flex;
    align-items: center
}

.content-camps__sessions .content-camps__title i {
    font-size: 1.5rem;
    padding-right: 5px
}

.content-camps__sessions .navigation-breadcrumb {
    margin: 0 0 1rem
}

.content-camps__sessions .dynamic-table__row {
    z-index: 0
}

.content-camps__sessions .dynamic-table__row--margin {
    margin: 1rem
}

.content-camps__sessions .dynamic-table__row:hover {
    z-index: 1
}

.content-camps__sessions .dynamic-table__container {
    margin: 0 0 2rem
}

.content-camps__sessions .dynamic-table__container.active .dynamic-table__displayer .icon-displayer:before {
    transform: rotate(180deg)
}

.content-camps__sessions .dynamic-table__container.active .dynamic-table__body {
    display: block
}

.content-camps__sessions .dynamic-table__displayer {
    display: flex;
    padding-right: 10px;
    align-items: center;
    cursor: pointer
}

.content-camps__sessions .dynamic-table__displayer__title {
    padding-right: 10px;
    color: #535a8b;
    display: flex;
    align-items: center
}

.content-camps__sessions .dynamic-table__displayer__alert {
    margin: 0 0 0 1rem
}

.content-camps__sessions .dynamic-table__displayer__alert i {
    font-size: 1.25rem !important;
    color: #e57373
}

.content-camps__sessions .dynamic-table__displayer:hover .icon-displayer {
    color: #656c9b
}

.content-camps__sessions .dynamic-table__displayer i {
    font-size: 1.5rem;
    padding-right: 5px
}

.content-camps__sessions .dynamic-table__displayer i.icon-displayer {
    position: absolute;
    right: 40px;
    color: #d3d5e4;
    transition: all .3s ease;
    background-color: #f9f9f9
}

.content-camps__sessions .dynamic-table__displayer i.icon-displayer:before {
    transition: all .3s ease
}

.content-camps__sessions .dynamic-table__displayer:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #d3d5e4
}

.content-camps__sessions .dynamic-table__body {
    display: none;
    transition: all .3s ease
}

.content-camps__sessions .dynamic-table__body__ondemand {
    margin: 30px 1rem 1rem;
    display: flex;
    justify-content: space-between;
    color: #282d58
}

.content-camps__sessions .dynamic-table__body__ondemand__block {
    color: #535a8b;
    display: flex
}

.content-camps__sessions .dynamic-table__body__ondemand__block i {
    font-size: 1.25rem
}

.content-camps__sessions .dynamic-table__body__ondemand__block .icon-trash:hover {
    color: #282d58;
    cursor: pointer
}

.content-camps__sessions .dynamic-table__body__ondemand__member {
    padding-right: 50px
}

.content-camps__sessions .dynamic-table__body__ondemand__member:hover {
    color: #282d58;
    cursor: pointer
}

.content-camps__sessions .dynamic-table__body__more {
    display: flex;
    justify-content: center
}

.content-camps__sessions .dynamic-table .cell-session__title {
    font-size: 1rem
}

.content-camps__sessions .dynamic-table .cell-session__options {
    margin: .3rem 0;
    display: flex
}

.content-camps__sessions .dynamic-table .cell-session__options .tooltip {
    padding: 0 .3rem 0 0
}

.content-camps__sessions .dynamic-table .cell-session__options .tooltip i {
    font-size: 1rem;
    transition: all .3s ease
}

.content-camps__sessions .dynamic-table .cell-session__options .tooltip i:hover {
    color: #feb88a
}

.content-camps__sessions .dynamic-table .cell-actions-icon {
    z-index: 1;
    cursor: pointer
}

.content-camps__sessions .dynamic-table .cell-actions-icon i {
    font-size: 1.25rem;
    padding: 0 .5rem 0 0
}

@media screen and (max-width:32rem) {
    .content-camps__sessions .dynamic-table .cell-actions-icon {
        display: none
    }
}

.content-camps__sessions .dynamic-table .cell-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    max-width: 60px;
    padding: 0 1rem 0 0
}

.content-camps__sessions .dynamic-table .cell-logo i {
    font-size: 1.5rem
}

.content-camps__sessions .dynamic-table .cell-logo__picture {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

@media screen and (max-width:32rem) {
    .content-camps__sessions .dynamic-table .cell-logo {
        display: none
    }
}

@media screen and (max-width:32rem) {
    .content-camps__sessions .dynamic-table .cell-actions-dropdown .dropdown-manage {
        width: 30px !important
    }
}

.content-camps__sessions .dynamic-table .cell-actions-dropdown .dropdown-actions__selected:hover i:before {
    color: #535a8b !important
}

.content-camps__sessions .dynamic-table .cell-actions-dropdown .dropdown-actions__selected i {
    margin-left: 0 !important
}

.content-camps__sessions .dynamic-table .cell-actions-dropdown .dropdown-actions__selected i:before {
    color: #8c91b4
}

.content-camps__sessions .dynamic-table .cell-actions-dropdown--responsive {
    display: none
}

@media screen and (max-width:32rem) {
    .content-camps__sessions .dynamic-table .cell-actions-dropdown--responsive {
        display: block;
        position: absolute;
        right: 10px
    }
}

.content-camps__sessions .dropdown--filter-duration {
    top: 200px !important
}

.content-camps-stats__header {
    margin-bottom: calc(.5em*2.5);
    padding-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4
}

.content-stats-adoption__header {
    margin-bottom: calc(.5em*2.5);
    padding-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4
}

.content-camps-stats__search {
    margin: 0 calc(calc(.5em*1)*-1)
}

.content-stats-adoption__search {
    margin: 0 calc(calc(.5em*1)*-1)
}

@media screen and (max-width:80rem) {
    .content-camps-stats__search {
        margin: 0 calc(calc(.5em*0.5)*-1)
    }

    .content-stats-adoption__search {
        margin: 0 calc(calc(.5em*0.5)*-1)
    }
}

.content-camps-stats__search>* {
    margin: 0 calc(.5em*1)
}

.content-stats-adoption__search>* {
    margin: 0 calc(.5em*1)
}

@media screen and (max-width:80rem) {
    .content-camps-stats__search>* {
        margin: 0 calc(.5em*0.5)
    }

    .content-stats-adoption__search>* {
        margin: 0 calc(.5em*0.5)
    }
}

.content-camps-stats__search p {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #8c91b4
}

.content-stats-adoption__search p {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #8c91b4
}

.content-camps-stats__search p span {
    margin-left: calc(.5em*1);
    padding: 6px 10px;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .5)
}

.content-stats-adoption__search p span {
    margin-left: calc(.5em*1);
    padding: 6px 10px;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .5)
}

.content-camps-stats__search .date-till .select {
    position: relative;
    cursor: pointer;
    margin-left: calc(.5em*1);
    border: 0;
    padding: 0;
    height: auto
}

.content-stats-adoption__search .date-till .select {
    position: relative;
    cursor: pointer;
    margin-left: calc(.5em*1);
    border: 0;
    padding: 0;
    height: auto
}

.content-camps-stats__search .date-till .select:after,
.content-camps-stats__search .date-till .select:before,
.content-stats-adoption__search .date-till .select:after,
.content-stats-adoption__search .date-till .select:before {
    display: none
}

.content-camps-stats__search .date-till .select__placeholder {
    border: 1px solid #d3d5e4;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.content-stats-adoption__search .date-till .select__placeholder {
    border: 1px solid #d3d5e4;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.content-camps-stats__search .date-till .select__placeholder .icon:before,
.content-stats-adoption__search .date-till .select__placeholder .icon:before {
    transform: rotate(0deg);
    transition: transform .3s ease
}

.content-camps-stats__search .date-till .select__dropdown,
.content-stats-adoption__search .date-till .select__dropdown {
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    position: absolute;
    top: calc(100% + 8px);
    z-index: 10
}

.content-camps-stats__search .date-till .select.active .icon:before,
.content-stats-adoption__search .date-till .select.active .icon:before {
    transform: rotate(-180deg)
}

.content-camps-stats__search .date-till .select.active .select__dropdown,
.content-stats-adoption__search .date-till .select.active .select__dropdown {
    opacity: 1;
    visibility: visible
}

.content-camps-stats__action msc-button,
.content-stats-adoption__action msc-button {
    padding: 3px 7px
}

.content-camps-stats__action msc-button i,
.content-stats-adoption__action msc-button i {
    font-size: 1.25rem
}

.content-camps-stats__canvas {
    position: relative;
    min-height: 80vh;
    width: 100%;
    margin-top: calc(.5em*3);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0
}

.content-stats-adoption__canvas {
    position: relative;
    min-height: 80vh;
    width: 100%;
    margin-top: calc(.5em*3);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0
}

@media screen and (max-width:32rem) {

    .content-camps-stats__canvas,
    .content-stats-adoption__canvas {
        width: 100% !important;
        /*  width: 70%*/
    }
}

.content-camps-stats__sidebar {
    position: absolute;
    top: 80px;
    right: 30px;
    margin: calc(calc(.5em*0.5)*-1) 0
}

.content-stats-adoption__sidebar {
    position: absolute;
    top: 80px;
    right: 30px;
    margin: calc(calc(.5em*0.5)*-1) 0
}

@media screen and (min-width:160rem) {

    .content-camps-stats__sidebar,
    .content-stats-adoption__sidebar {
        left: 40%
    }
}

@media screen and (min-width:32rem) {

    .content-camps-stats .box-stats-adoption,
    .content-stats-adoption .box-stats-adoption {
        width: 330px
    }
}

.content-camps-stats .box-stats-adoption__number,
.content-stats-adoption .box-stats-adoption__number {
    font-size: 2.5rem
}

.content-camps-stats .box-stats-adoption p:first-child {
    font-size: .875rem;
    color: #656c9b;
    margin-right: calc(.5em*2.5);
    padding-right: calc(.5em*2.5);
    border-right: 1px solid #d3d5e4
}

.content-stats-adoption .box-stats-adoption p:first-child {
    font-size: .875rem;
    color: #656c9b;
    margin-right: calc(.5em*2.5);
    padding-right: calc(.5em*2.5);
    border-right: 1px solid #d3d5e4
}

.content-camps-stats .box-stats-adoption i {
    font-size: 1.375rem;
    margin-right: calc(.5em*1.5)
}

.content-stats-adoption .box-stats-adoption i {
    font-size: 1.375rem;
    margin-right: calc(.5em*1.5)
}

.content-camps-stats .box-adoption-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 175px;
    min-height: 125px;
    background-color: #f8f9ff;
    margin: calc(.5em*3) 0
}

.content-stats-adoption .box-adoption-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 175px;
    min-height: 125px;
    background-color: #f8f9ff;
    margin: calc(.5em*3) 0
}

.content-camps-stats .box-adoption-details>* {
    margin: calc(calc(.5em*0.5)/2) 0
}

.content-stats-adoption .box-adoption-details>* {
    margin: calc(calc(.5em*0.5)/2) 0
}

.content-camps-stats .box-adoption-details__status {
    color: #8c91b4;
    font-size: .75rem
}

.content-stats-adoption .box-adoption-details__status {
    color: #8c91b4;
    font-size: .75rem
}

.content-camps-stats .box-adoption-details__status i {
    margin-right: calc(.5em*1);
    font-size: 1.25rem
}

.content-stats-adoption .box-adoption-details__status i {
    margin-right: calc(.5em*1);
    font-size: 1.25rem
}

.content-camps-stats .box-adoption-details__stats {
    color: #8c91b4;
    font-size: 1.5rem
}

.content-stats-adoption .box-adoption-details__stats {
    color: #8c91b4;
    font-size: 1.5rem
}

.content-camps-stats .box-adoption-details__rank,
.content-stats-adoption .box-adoption-details__rank {
    font-size: .75rem
}

.content-camps-stats--provider .content-camps-stats__header {
    margin-bottom: 0;
    margin-top: calc(.5em*3);
    padding-bottom: 0;
    border: none
}

.content-stats-providers .content-camps-stats__header {
    margin-bottom: 0;
    margin-top: calc(.5em*3);
    padding-bottom: 0;
    border: none
}

@media screen and (min-width:32rem) {

    .content-camps-stats--provider .content-camps-stats__header,
    .content-stats-providers .content-camps-stats__header {
        margin-top: 0
    }
}

.content-camps-stats--provider .content-camps-stats__filter {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-camps-stats--provider .content-camps-stats__filter-by {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-stats-providers .content-camps-stats__filter {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-stats-providers .content-camps-stats__filter-by {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-camps-stats--provider .content-camps-stats__filter-by msc-button__filter {
    margin: 0 calc(.5em*5) 0 calc(.5em*2)
}

.content-camps-stats--provider .content-camps-stats__filter msc-button__filter {
    margin: 0 calc(.5em*5) 0 calc(.5em*2)
}

.content-stats-providers .content-camps-stats__filter-by msc-button__filter {
    margin: 0 calc(.5em*5) 0 calc(.5em*2)
}

.content-stats-providers .content-camps-stats__filter msc-button__filter {
    margin: 0 calc(.5em*5) 0 calc(.5em*2)
}

.content-camps-stats--provider .content-camps-stats__filter-by .filter-by__item {
    margin: calc(.5em*1)
}

.content-camps-stats--provider .content-camps-stats__filter .filter-by__item {
    margin: calc(.5em*1)
}

.content-stats-providers .content-camps-stats__filter-by .filter-by__item {
    margin: calc(.5em*1)
}

.content-stats-providers .content-camps-stats__filter .filter-by__item {
    margin: calc(.5em*1)
}

@media screen and (min-width:32rem) {
    .content-camps-stats--provider .content-camps-stats__filter-by .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-camps-stats--provider .content-camps-stats__filter .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-stats-providers .content-camps-stats__filter-by .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-stats-providers .content-camps-stats__filter .filter-by__item {
        margin: 0 calc(.5em*1)
    }
}

.content-camps-stats--provider .content-camps-stats__filter-by p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-camps-stats--provider .content-camps-stats__filter p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-stats-providers .content-camps-stats__filter-by p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-stats-providers .content-camps-stats__filter p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-camps-stats--provider .content-camps-stats__filter-by .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-camps-stats--provider .content-camps-stats__filter .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-stats-providers .content-camps-stats__filter-by .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-stats-providers .content-camps-stats__filter .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-camps-stats--provider .content-camps-stats__filter-by .multi-select {
    width: 140px;
    margin-right: calc(.5em*2)
}

.content-camps-stats--provider .content-camps-stats__filter .multi-select {
    width: 140px;
    margin-right: calc(.5em*2)
}

.content-stats-providers .content-camps-stats__filter-by .multi-select {
    width: 140px;
    margin-right: calc(.5em*2)
}

.content-stats-providers .content-camps-stats__filter .multi-select {
    width: 140px;
    margin-right: calc(.5em*2)
}

.content-camps-stats--provider .content-camps-stats__filter-by .multi-select .select,
.content-camps-stats--provider .content-camps-stats__filter .multi-select .select,
.content-stats-providers .content-camps-stats__filter-by .multi-select .select,
.content-stats-providers .content-camps-stats__filter .multi-select .select {
    margin: 0
}

.content-camps-stats--provider .content-camps-stats__filter-by .search,
.content-camps-stats--provider .content-camps-stats__filter .search,
.content-stats-providers .content-camps-stats__filter-by .search,
.content-stats-providers .content-camps-stats__filter .search {
    min-width: calc(100% - 48px)
}

@media screen and (min-width:32rem) {

    .content-camps-stats--provider .content-camps-stats__filter-by .search,
    .content-camps-stats--provider .content-camps-stats__filter .search,
    .content-stats-providers .content-camps-stats__filter-by .search,
    .content-stats-providers .content-camps-stats__filter .search {
        min-width: auto;
        width: 210px
    }
}

.content-camps-stats--provider .content-camps-stats__search {
    margin-left: 0;
    display: block;
    margin-top: calc(.5em*3);
    flex-wrap: wrap
}

.content-stats-providers .content-camps-stats__search {
    margin-left: 0;
    display: block;
    margin-top: calc(.5em*3);
    flex-wrap: wrap
}

@media screen and (min-width:32rem) {

    .content-camps-stats--provider .content-camps-stats__search,
    .content-stats-providers .content-camps-stats__search {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 0
    }
}

.content-camps-stats--provider .content-camps-stats__list {
    margin: 0 calc(calc(.5em*1.5)*-1) calc(.5em*1.5) calc(calc(.5em*1.5)*-1)
}

.content-stats-providers .content-camps-stats__list {
    margin: 0 calc(calc(.5em*1.5)*-1) calc(.5em*1.5) calc(calc(.5em*1.5)*-1)
}

.content-camps-stats--provider .content-camps-stats__item {
    width: 100%;
    margin: calc(.5em*1.5)
}

.content-stats-providers .content-camps-stats__item {
    width: 100%;
    margin: calc(.5em*1.5)
}

@media screen and (min-width:32rem) {
    .content-camps-stats--provider .content-camps-stats__item {
        width: calc(33.33333% - 24px);
        margin: 0 calc(.5em*1.5) calc(.5em*3) calc(.5em*1.5)
    }

    .content-stats-providers .content-camps-stats__item {
        width: calc(33.33333% - 24px);
        margin: 0 calc(.5em*1.5) calc(.5em*3) calc(.5em*1.5)
    }
}

.content-camps-stats--provider .content-camps-stats__resume {
    margin-bottom: calc(.5em*2);
    flex-wrap: wrap
}

.content-camps-stats--provider .content-stats-providers__resume {
    margin-bottom: calc(.5em*2);
    flex-wrap: wrap
}

.content-stats-providers .content-camps-stats__resume {
    margin-bottom: calc(.5em*2);
    flex-wrap: wrap
}

.content-stats-providers .content-stats-providers__resume {
    margin-bottom: calc(.5em*2);
    flex-wrap: wrap
}

@media screen and (min-width:32rem) {

    .content-camps-stats--provider .content-camps-stats__resume,
    .content-camps-stats--provider .content-stats-providers__resume,
    .content-stats-providers .content-camps-stats__resume,
    .content-stats-providers .content-stats-providers__resume {
        flex-wrap: nowrap
    }
}

.content-camps-stats--provider .content-camps-stats__label {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #7d83aa
}

.content-camps-stats--provider .content-stats-providers__label {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #7d83aa
}

.content-stats-providers .content-camps-stats__label {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #7d83aa
}

.content-stats-providers .content-stats-providers__label {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #7d83aa
}

.content-camps-stats--provider .content-camps-stats__data {
    margin-top: calc(.5em*2.5);
    width: 120%;
    order: 1
}

.content-camps-stats--provider .content-stats-providers__data {
    margin-top: calc(.5em*2.5);
    width: 120%;
    order: 1
}

.content-stats-providers .content-camps-stats__data {
    margin-top: calc(.5em*2.5);
    width: 120%;
    order: 1
}

.content-stats-providers .content-stats-providers__data {
    margin-top: calc(.5em*2.5);
    width: 120%;
    order: 1
}

@media screen and (min-width:32rem) {

    .content-camps-stats--provider .content-camps-stats__data,
    .content-camps-stats--provider .content-stats-providers__data,
    .content-stats-providers .content-camps-stats__data,
    .content-stats-providers .content-stats-providers__data {
        margin-top: 0;
        order: 0
    }
}

.content-camps-stats--provider .content-camps-stats__data>*,
.content-camps-stats--provider .content-stats-providers__data>*,
.content-stats-providers .content-camps-stats__data>*,
.content-stats-providers .content-stats-providers__data>* {
    display: inline-block
}

.content-camps-stats--provider .content-camps-stats__progress {
    width: 70%;
    margin: 0 calc(.5em*2)
}

.content-camps-stats--provider .content-stats-providers__progress {
    width: 70%;
    margin: 0 calc(.5em*2)
}

.content-stats-providers .content-camps-stats__progress {
    width: 70%;
    margin: 0 calc(.5em*2)
}

.content-stats-providers .content-stats-providers__progress {
    width: 70%;
    margin: 0 calc(.5em*2)
}

.content-camps-stats--provider .content-camps-stats__duration {
    color: #7d83aa;
    font-size: .875rem
}

.content-camps-stats--provider .content-stats-providers__duration {
    color: #7d83aa;
    font-size: .875rem
}

.content-stats-providers .content-camps-stats__duration {
    color: #7d83aa;
    font-size: .875rem
}

.content-stats-providers .content-stats-providers__duration {
    color: #7d83aa;
    font-size: .875rem
}

.content-camps-stats--provider .content-camps-stats__edit,
.content-camps-stats--provider .content-stats-providers__edit,
.content-stats-providers .content-camps-stats__edit,
.content-stats-providers .content-stats-providers__edit {
    cursor: pointer
}

.content-camps-stats--provider .content-camps-stats__edit i {
    color: #7d83aa;
    transform: scale(1);
    transition: all .25s ease
}

.content-camps-stats--provider .content-stats-providers__edit i {
    color: #7d83aa;
    transform: scale(1);
    transition: all .25s ease
}

.content-stats-providers .content-camps-stats__edit i {
    color: #7d83aa;
    transform: scale(1);
    transition: all .25s ease
}

.content-stats-providers .content-stats-providers__edit i {
    color: #7d83aa;
    transform: scale(1);
    transition: all .25s ease
}

.content-camps-stats--provider .content-camps-stats__edit:hover i,
.content-camps-stats--provider .content-stats-providers__edit:hover i,
.content-stats-providers .content-camps-stats__edit:hover i,
.content-stats-providers .content-stats-providers__edit:hover i {
    transform: scale(2)
}

.content-camps-stats--provider .content-camps-stats__logo {
    width: 32px;
    height: 32px;
    margin: 0 calc(.5em*2) 0 calc(.5em*1)
}

.content-camps-stats--provider .content-stats-providers__logo {
    width: 32px;
    height: 32px;
    margin: 0 calc(.5em*2) 0 calc(.5em*1)
}

.content-stats-providers .content-camps-stats__logo {
    width: 32px;
    height: 32px;
    margin: 0 calc(.5em*2) 0 calc(.5em*1)
}

.content-stats-providers .content-stats-providers__logo {
    width: 32px;
    height: 32px;
    margin: 0 calc(.5em*2) 0 calc(.5em*1)
}

.content-camps-stats--provider .content-camps-stats__logo img,
.content-camps-stats--provider .content-stats-providers__logo img,
.content-stats-providers .content-camps-stats__logo img,
.content-stats-providers .content-stats-providers__logo img {
    width: auto;
    max-height: 100%
}

.content-camps-stats--provider .content-camps-stats__nav {
    margin: 0 calc(calc(.5em*0.5)*-1)
}

.content-camps-stats--provider .content-stats-providers__nav {
    margin: 0 calc(calc(.5em*0.5)*-1)
}

.content-stats-providers .content-camps-stats__nav {
    margin: 0 calc(calc(.5em*0.5)*-1)
}

.content-stats-providers .content-stats-providers__nav {
    margin: 0 calc(calc(.5em*0.5)*-1)
}

.content-camps-stats--provider .content-camps-stats__nav li {
    margin: 0 calc(.5em*0.5)
}

.content-camps-stats--provider .content-stats-providers__nav li {
    margin: 0 calc(.5em*0.5)
}

.content-stats-providers .content-camps-stats__nav li {
    margin: 0 calc(.5em*0.5)
}

.content-stats-providers .content-stats-providers__nav li {
    margin: 0 calc(.5em*0.5)
}

.content-camps-stats--provider .content-camps-stats__nav a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(254, 184, 138, .15)
}

.content-camps-stats--provider .content-stats-providers__nav a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(254, 184, 138, .15)
}

.content-stats-providers .content-camps-stats__nav a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(254, 184, 138, .15)
}

.content-stats-providers .content-stats-providers__nav a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(254, 184, 138, .15)
}

.content-camps-stats--provider .content-camps-stats__nav a i {
    font-size: 1.375rem;
    color: #feb88a;
    transform: scale(1);
    transition: all .25s ease
}

.content-camps-stats--provider .content-stats-providers__nav a i {
    font-size: 1.375rem;
    color: #feb88a;
    transform: scale(1);
    transition: all .25s ease
}

.content-stats-providers .content-camps-stats__nav a i {
    font-size: 1.375rem;
    color: #feb88a;
    transform: scale(1);
    transition: all .25s ease
}

.content-stats-providers .content-stats-providers__nav a i {
    font-size: 1.375rem;
    color: #feb88a;
    transform: scale(1);
    transition: all .25s ease
}

.content-camps-stats--provider .content-camps-stats__nav a:hover i,
.content-camps-stats--provider .content-stats-providers__nav a:hover i,
.content-stats-providers .content-camps-stats__nav a:hover i,
.content-stats-providers .content-stats-providers__nav a:hover i {
    transform: scale(1.25)
}

.content-camps-stats--provider__set,
.content-stats-providers__set {
    display: flex;
    flex-direction: column;
    width: 30%
}

.content-camps-stats--provider__set__title {
    font-size: .875rem;
    color: #656c9b
}

.content-stats-providers__set__title {
    font-size: .875rem;
    color: #656c9b
}

.content-camps-stats--provider__set__actions,
.content-stats-providers__set__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .5rem 0 0
}

.content-camps-stats--provider__set__actions input,
.content-stats-providers__set__actions input {
    width: 4.5rem;
    height: 2rem
}

.content-camps-stats--provider__set__actions i,
.content-stats-providers__set__actions i {
    font-size: 1.25rem;
    cursor: pointer
}

.content-camps-stats--provider__set__actions i:before,
.content-stats-providers__set__actions i:before {
    transition: all .3s ease
}

.content-camps-stats--provider__set__actions i:hover:before,
.content-stats-providers__set__actions i:hover:before {
    transform: scale(1.3);
    transition: all .3s ease
}

.content-camps-stats--provider__set__actions .icon-checkmark {
    color: #6cd4a8
}

.content-stats-providers__set__actions .icon-checkmark {
    color: #6cd4a8
}

.content-camps-stats--provider__set__actions .icon-close {
    color: #f44436
}

.content-stats-providers__set__actions .icon-close {
    color: #f44436
}

.card-graphic-provider__header {
    padding-bottom: calc(.5em*1.5);
    margin-bottom: calc(.5em*1.5);
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.card-graphic-provider__title {
    width: 70%
}

.card-graphic-provider__title i {
    font-size: 1.375rem;
    color: #535a8b;
    margin-right: calc(.5em*1)
}

.card-graphic-provider__title span {
    font-size: .875rem;
    line-height: 1rem;
    color: #7d83aa
}

.card-graphic-provider__number {
    font-size: 1.875rem;
    line-height: 1.125rem;
    color: #535a8b
}

.card-graphic-provider__action i {
    font-size: 1.125rem;
    color: #7d83aa;
    transition: all .25s ease
}

.card-graphic-provider__action:hover i {
    color: #020d38
}

.card-graphic-provider--orange .card-graphic-provider__number {
    color: #feb88a
}

.card-graphic-provider--orange .card-graphic-provider__title i {
    color: #feb88a
}

.card-graphic-provider--secondary-orange .card-graphic-provider__number {
    color: #feb88a
}

.card-graphic-provider--secondary-orange .card-graphic-provider__title i {
    color: #feb88a
}

.card-graphic-provider--blue .card-graphic-provider__number {
    color: #216ab2
}

.card-graphic-provider--blue .card-graphic-provider__title i {
    color: #216ab2
}

.card-graphic-provider--green .card-graphic-provider__number {
    color: #49e2af
}

.card-graphic-provider--green .card-graphic-provider__title i {
    color: #49e2af
}

.content-camps-stats--provider .content-camps-stats__header,
.content-camps-stats--provider .content-stats-providers__header,
.content-stats-providers .content-camps-stats__header,
.content-stats-providers .content-stats-providers__header {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none
}

.content-camps-stats--provider .content-camps-stats__filter {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-camps-stats--provider .content-camps-stats__filter-by {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-camps-stats--provider .content-stats-providers__filter {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-camps-stats--provider .content-stats-providers__filter-by {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-stats-providers .content-camps-stats__filter {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-stats-providers .content-camps-stats__filter-by {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-stats-providers .content-stats-providers__filter {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-stats-providers .content-stats-providers__filter-by {
    padding-bottom: calc(.5em*2.5);
    margin-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4;
    max-width: 100%
}

.content-camps-stats--provider .content-camps-stats__filter-by .filter-by__item {
    margin: calc(.5em*1)
}

.content-camps-stats--provider .content-camps-stats__filter .filter-by__item {
    margin: calc(.5em*1)
}

.content-camps-stats--provider .content-stats-providers__filter-by .filter-by__item {
    margin: calc(.5em*1)
}

.content-camps-stats--provider .content-stats-providers__filter .filter-by__item {
    margin: calc(.5em*1)
}

.content-stats-providers .content-camps-stats__filter-by .filter-by__item {
    margin: calc(.5em*1)
}

.content-stats-providers .content-camps-stats__filter .filter-by__item {
    margin: calc(.5em*1)
}

.content-stats-providers .content-stats-providers__filter-by .filter-by__item {
    margin: calc(.5em*1)
}

.content-stats-providers .content-stats-providers__filter .filter-by__item {
    margin: calc(.5em*1)
}

@media screen and (min-width:32rem) {
    .content-camps-stats--provider .content-camps-stats__filter-by .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-camps-stats--provider .content-camps-stats__filter .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-camps-stats--provider .content-stats-providers__filter-by .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-camps-stats--provider .content-stats-providers__filter .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-stats-providers .content-camps-stats__filter-by .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-stats-providers .content-camps-stats__filter .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-stats-providers .content-stats-providers__filter-by .filter-by__item {
        margin: 0 calc(.5em*1)
    }

    .content-stats-providers .content-stats-providers__filter .filter-by__item {
        margin: 0 calc(.5em*1)
    }
}

.content-camps-stats--provider .content-camps-stats__filter-by p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-camps-stats--provider .content-camps-stats__filter p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-camps-stats--provider .content-stats-providers__filter-by p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-camps-stats--provider .content-stats-providers__filter p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-stats-providers .content-camps-stats__filter-by p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-stats-providers .content-camps-stats__filter p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-stats-providers .content-stats-providers__filter-by p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-stats-providers .content-stats-providers__filter p {
    font-size: .875rem;
    line-height: 1rem;
    color: #8c91b4
}

.content-camps-stats--provider .content-camps-stats__filter-by .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-camps-stats--provider .content-camps-stats__filter .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-camps-stats--provider .content-stats-providers__filter-by .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-camps-stats--provider .content-stats-providers__filter .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-stats-providers .content-camps-stats__filter-by .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-stats-providers .content-camps-stats__filter .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-stats-providers .content-stats-providers__filter-by .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-stats-providers .content-stats-providers__filter .select {
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-camps-stats--provider .content-camps-stats__filter-by .multi-select {
    width: 140px;
    margin-right: calc(.5em*2);
    margin-bottom: .5rem
}

.content-camps-stats--provider .content-camps-stats__filter .multi-select {
    width: 140px;
    margin-right: calc(.5em*2);
    margin-bottom: .5rem
}

.content-camps-stats--provider .content-stats-providers__filter-by .multi-select {
    width: 140px;
    margin-right: calc(.5em*2);
    margin-bottom: .5rem
}

.content-camps-stats--provider .content-stats-providers__filter .multi-select {
    width: 140px;
    margin-right: calc(.5em*2);
    margin-bottom: .5rem
}

.content-stats-providers .content-camps-stats__filter-by .multi-select {
    width: 140px;
    margin-right: calc(.5em*2);
    margin-bottom: .5rem
}

.content-stats-providers .content-camps-stats__filter .multi-select {
    width: 140px;
    margin-right: calc(.5em*2);
    margin-bottom: .5rem
}

.content-stats-providers .content-stats-providers__filter-by .multi-select {
    width: 140px;
    margin-right: calc(.5em*2);
    margin-bottom: .5rem
}

.content-stats-providers .content-stats-providers__filter .multi-select {
    width: 140px;
    margin-right: calc(.5em*2);
    margin-bottom: .5rem
}

.content-camps-stats--provider .content-camps-stats__filter-by .multi-select .select,
.content-camps-stats--provider .content-camps-stats__filter .multi-select .select,
.content-camps-stats--provider .content-stats-providers__filter-by .multi-select .select,
.content-camps-stats--provider .content-stats-providers__filter .multi-select .select,
.content-stats-providers .content-camps-stats__filter-by .multi-select .select,
.content-stats-providers .content-camps-stats__filter .multi-select .select,
.content-stats-providers .content-stats-providers__filter-by .multi-select .select,
.content-stats-providers .content-stats-providers__filter .multi-select .select {
    margin: 0
}

.content-camps-stats--provider .content-camps-stats__filter-by .multi-select .dropdown-actions__content,
.content-camps-stats--provider .content-camps-stats__filter .multi-select .dropdown-actions__content,
.content-camps-stats--provider .content-stats-providers__filter-by .multi-select .dropdown-actions__content,
.content-camps-stats--provider .content-stats-providers__filter .multi-select .dropdown-actions__content,
.content-stats-providers .content-camps-stats__filter-by .multi-select .dropdown-actions__content,
.content-stats-providers .content-camps-stats__filter .multi-select .dropdown-actions__content,
.content-stats-providers .content-stats-providers__filter-by .multi-select .dropdown-actions__content,
.content-stats-providers .content-stats-providers__filter .multi-select .dropdown-actions__content {
    top: 5px;
    left: 0
}

.content-camps-stats--provider .content-camps-stats__filter-by .search,
.content-camps-stats--provider .content-camps-stats__filter .search,
.content-camps-stats--provider .content-stats-providers__filter-by .search,
.content-camps-stats--provider .content-stats-providers__filter .search,
.content-stats-providers .content-camps-stats__filter-by .search,
.content-stats-providers .content-camps-stats__filter .search,
.content-stats-providers .content-stats-providers__filter-by .search,
.content-stats-providers .content-stats-providers__filter .search {
    min-width: calc(100% - 48px)
}

@media screen and (min-width:32rem) {

    .content-camps-stats--provider .content-camps-stats__filter-by .search,
    .content-camps-stats--provider .content-camps-stats__filter .search,
    .content-camps-stats--provider .content-stats-providers__filter-by .search,
    .content-camps-stats--provider .content-stats-providers__filter .search,
    .content-stats-providers .content-camps-stats__filter-by .search,
    .content-stats-providers .content-camps-stats__filter .search,
    .content-stats-providers .content-stats-providers__filter-by .search,
    .content-stats-providers .content-stats-providers__filter .search {
        min-width: auto;
        width: 210px
    }
}

.content-camps-stats--provider .content-camps-stats__search {
    display: block;
    margin-top: calc(.5em*3)
}

.content-camps-stats--provider .content-stats-providers__search {
    display: block;
    margin-top: calc(.5em*3)
}

.content-stats-providers .content-camps-stats__search {
    display: block;
    margin-top: calc(.5em*3)
}

.content-stats-providers .content-stats-providers__search {
    display: block;
    margin-top: calc(.5em*3)
}

@media screen and (min-width:32rem) {

    .content-camps-stats--provider .content-camps-stats__search,
    .content-camps-stats--provider .content-stats-providers__search,
    .content-stats-providers .content-camps-stats__search,
    .content-stats-providers .content-stats-providers__search {
        display: flex;
        margin-top: 0
    }
}

.content-camps-stats--provider .content-camps-stats__list {
    margin: 0 calc(calc(.5em*1.5)*-1) calc(.5em*1.5) calc(calc(.5em*1.5)*-1)
}

.content-camps-stats--provider .content-stats-providers__list {
    margin: 0 calc(calc(.5em*1.5)*-1) calc(.5em*1.5) calc(calc(.5em*1.5)*-1)
}

.content-stats-providers .content-camps-stats__list {
    margin: 0 calc(calc(.5em*1.5)*-1) calc(.5em*1.5) calc(calc(.5em*1.5)*-1)
}

.content-stats-providers .content-stats-providers__list {
    margin: 0 calc(calc(.5em*1.5)*-1) calc(.5em*1.5) calc(calc(.5em*1.5)*-1)
}

.content-camps-stats--provider .content-camps-stats__item {
    width: 100%;
    margin: calc(.5em*1.5)
}

.content-camps-stats--provider .content-stats-providers__item {
    width: 100%;
    margin: calc(.5em*1.5)
}

.content-stats-providers .content-camps-stats__item {
    width: 100%;
    margin: calc(.5em*1.5)
}

.content-stats-providers .content-stats-providers__item {
    width: 100%;
    margin: calc(.5em*1.5)
}

@media screen and (min-width:32rem) {
    .content-camps-stats--provider .content-camps-stats__item {
        width: calc(33.33333% - 24px);
        margin: 0 calc(.5em*1.5) calc(.5em*3) calc(.5em*1.5)
    }

    .content-camps-stats--provider .content-stats-providers__item {
        width: calc(33.33333% - 24px);
        margin: 0 calc(.5em*1.5) calc(.5em*3) calc(.5em*1.5)
    }

    .content-stats-providers .content-camps-stats__item {
        width: calc(33.33333% - 24px);
        margin: 0 calc(.5em*1.5) calc(.5em*3) calc(.5em*1.5)
    }

    .content-stats-providers .content-stats-providers__item {
        width: calc(33.33333% - 24px);
        margin: 0 calc(.5em*1.5) calc(.5em*3) calc(.5em*1.5)
    }
}

.card-stats-provider {
    padding: calc(.5em*1.5);
    background: #fff;
    min-height: 7rem
}

.card-stats-provider__logo {
    min-width: 4rem;
    padding: calc(.5em*2);
    margin-right: calc(.5em*2);
    border-right: 1px solid rgba(211, 213, 228, .25)
}

.card-stats-provider__logo img {
    max-width: 35px;
    height: 35px
}

.card-stats-provider__content {
    width: 100%
}

.card-stats-provider__progress {
    margin: calc(.5em*1) 0
}

.card-stats-provider__set {
    display: flex;
    flex-direction: column
}

.card-stats-provider__set__title {
    font-size: .875rem;
    color: #656c9b
}

.card-stats-provider__set__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .5rem 0 0
}

.card-stats-provider__set__actions input {
    width: 4.5rem;
    height: 2rem
}

.card-stats-provider__set__actions i {
    font-size: 1.25rem;
    cursor: pointer
}

.card-stats-provider__set__actions i:before {
    transition: all .3s ease
}

.card-stats-provider__set__actions i:hover:before {
    transform: scale(1.3);
    transition: all .3s ease
}

.card-stats-provider__set__actions .icon-checkmark {
    color: #6cd4a8
}

.card-stats-provider__set__actions .icon-close {
    color: #f44436
}

.card-stats-provider__text {
    font-size: .875rem;
    line-height: 1rem;
    color: #656c9b
}

.card-stats-provider__text i {
    cursor: pointer;
    color: #b2b5cd;
    transform: scale(1);
    transition: all .25s ease
}

.card-stats-provider__text i:hover {
    transform: scale(1.15);
    color: #656c9b
}

.card-stats-provider__number {
    font-size: .75rem;
    line-height: .875rem;
    color: #656c9b
}

.card-stats-provider__number i {
    cursor: pointer;
    font-size: 1rem;
    margin-left: calc(.5em*0.5);
    color: #b2b5cd;
    transform: scale(1);
    transition: color .25s ease
}

.card-stats-provider__number i:hover {
    transform: scale(1.15);
    color: #656c9b
}

.card-graphic-provider {
    min-height: 350px
}

.card-graphic-provider__header {
    margin-bottom: calc(.5em*2);
    padding-bottom: calc(.5em*2);
    border-bottom: 1px solid rgba(178, 181, 205, .25);
    min-height: 55px
}

.card-graphic-provider__header h3 {
    font-size: .875rem;
    line-height: 1.25rem;
    color: #656c9b
}

.card-graphic-provider__header h3 i {
    font-size: 1.25rem;
    color: #feb88a;
    margin-right: calc(.5em*1)
}

.card-graphic-provider__action {
    cursor: pointer;
    font-size: 1.125rem;
    color: #656c9b;
    transform: scale(1);
    transition: all .25s ease
}

.card-graphic-provider__action:hover {
    color: #020d38;
    transform: scale(1.15)
}

.tag-provider {
    background: #fff;
    padding: calc(.5em*0.5) calc(.5em*1.5);
    border-radius: 12px
}

.tag-provider:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(211, 213, 228, .25)
}

.tag-provider span {
    font-size: .875rem;
    line-height: 1.25rem;
    color: #b2b5cd;
    margin: 0 calc(.5em*1)
}

.tag-provider i {
    cursor: pointer;
    font-size: 1rem;
    color: #d3d5e4;
    transition: color .25s ease
}

.tag-provider i:hover {
    color: #7d83aa
}

.tag-provider--primary:before {
    background-color: #feb88a !important
}

.tag-provider--default:before {
    background-color: #feb88a !important
}

.tag-provider--bookboon:before {
    background-color: #f59833 !important
}

.tag-provider--udemy:before {
    background-color: #ea5252 !important
}

.tag-provider--openclassroom:before {
    background-color: #7550eb !important
}

.tag-provider--edflex:before {
    background-color: #216ab2 !important
}

.tag-provider--cefora:before {
    background-color: #251b5a !important
}

.content-camps__stats__header {
    display: block;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px
}

@media screen and (min-width:32rem) {
    .content-camps__stats__header {
        display: flex
    }
}

.content-camps__stats__menu {
    margin: 1rem 0
}

.content-camps__stats__title {
    color: #020d38;
    font-size: 1.25rem;
    font-weight: 300;
    margin: 16px 0
}

.content-camps__stats .box-camps-stats__picture {
    display: block;
    margin: 0 auto;
    max-width: 2.5rem;
    max-height: 2rem
}

.content-camps__stats .box-camps-stats__header {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 32px 0 16px;
    color: #7d83aa;
    font-size: 1.25rem;
    position: relative
}

.content-camps__stats .box-camps-stats__header:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    width: calc(100% - 40px);
    height: 1px;
    background-color: #d3d5e4
}

.content-camps__stats .box-camps-stats__header p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px 0 0;
    z-index: 1;
    background-color: #f9f9f9;
    font-size: 1rem;
    line-height: 1;
    color: #535a8b
}

.content-camps__stats .box-camps-stats__header .box-camps-stats__toggle {
    position: absolute;
    top: 50%;
    right: -16px;
    transform: translateY(-50%);
    cursor: pointer
}

.content-camps__stats .box-camps-stats__header .box-camps-stats__toggle i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: #d3d5e4;
    font-size: 1.5rem;
    transform: rotate(0deg) scale(1);
    transition: all .35s ease
}

.content-camps__stats .box-camps-stats__header i {
    margin: 0 calc(.5em*1) 0 0;
    font-size: 1.5rem;
    color: #f9aa81
}

.content-camps__stats .box-camps-stats__header:hover .box-camps-stats__toggle i {
    transform: scale(1.1);
    color: #282d58
}

.content-camps__stats .box-camps-stats__header.active .box-camps-stats__toggle i {
    transform: rotate(-180deg);
    color: #282d58
}

.content-camps__stats .box-camps-stats__body {
    display: none;
    margin-left: 0
}

.content-camps__stats .box-camps-stats__body.active {
    display: block;
    margin-left: 0
}

@media screen and (min-width:32rem) {
    .content-camps__stats .box-camps-stats__body.active {
        margin-left: calc(.5em*3)
    }
}

.content-camps__stats .box-camps-stats__body .dynamic-table__cell {
    margin: -8px 0
}

.content-camps__stats .box-camps-stats__body .dynamic-table__cell p {
    margin: 8px 0
}

.content-camps__stats .box-camps-stats__body .dynamic-table__cell p:nth-child(2) {
    margin: 8px -4px
}

.content-camps__stats .box-camps-stats__body .dynamic-table__cell p span {
    display: inline-flex;
    align-items: center;
    margin: 0 4px;
    color: #535a8b
}

.content-camps__stats .box-camps-stats__body .dynamic-table__cell p i {
    font-size: 1.25rem;
    margin-right: 8px;
    color: #b2b5cd
}

.content-camps__stats .box-camps-stats__body .dynamic-table .cell-title p {
    font-size: 1rem;
    color: #535a8b
}

.content-camps__stats .box-camps-stats__body .dynamic-table .cell-actions {
    transform: translateY(50%)
}

.content-camps__stats .dynamic-table__row .cell-activity {
    min-width: 30%;
    margin-left: 0;
    color: #8290fd
}

.content-camps__stats .dynamic-table__row .cell-activity span {
    color: #8c91b4;
    font-size: .75rem;
    margin-top: 5px
}

.content-camps__stats .dynamic-table__row .cell-activity--media .tag {
    display: flex;
    background: none;
    padding: 0
}

.content-camps__stats .dynamic-table__row .cell-activity--media .tag__text {
    color: #8290fd
}

.content-camps__stats .dynamic-table__row .cell-activity--media .tag i {
    margin-right: calc(.5em*1)
}

.content-camps__stats__content .dynamic-table {
    margin-bottom: 16px
}

@media screen and (min-width:32rem) {
    .content-camps__stats__content .dynamic-table {
        margin-bottom: 40px
    }
}

.content-camps__stats__content .dynamic-table__row {
    margin: 0
}

.content-camps__stats__content .dynamic-table__cell p {
    margin-right: calc(.5em*3)
}

.content-camps__stats__content .dynamic-table__header .cell-title {
    padding-left: 1rem
}

.content-camps__stats__content .dynamic-table span {
    margin-left: 8px
}

.content-camps__stats__content .dynamic-table .progress-stats span {
    margin-left: 0
}

.content-camps__stats__box .dynamic-table__row .cell-title span {
    font-weight: 900;
    display: block;
    margin: 0 0 8px
}

.content-camps__stats__box .dynamic-table__row.done {
    border-left: 6px solid #49e2af
}

.content-camps__stats__box .dynamic-table__row.done .cell-title span {
    color: #49e2af
}

.content-camps__stats__box .dynamic-table__row.progress {
    border-left: 6px solid #8290fd
}

.content-camps__stats__box .dynamic-table__row.progress .cell-title span {
    color: #8290fd
}

.content-camps__stats__box .dynamic-table__row.empty {
    border-left: 6px solid #b2b5cd
}

.content-camps__stats__box .dynamic-table__row.empty .cell-title span {
    color: #b2b5cd
}

.content-camps__stats__box .dynamic-table__cell span {
    margin-left: 8px
}

.content-camps__stats .highlight {
    color: #49e2af
}

.content-camps__stats .cell-progress span {
    margin: 0
}

.content-camps__stats .cell-score {
    padding: .5rem 0;
    display: flex;
    flex-direction: column
}

.content-camps__stats .cell-score .chart-pie {
    margin: .2rem 0 0
}

.content-camps__stats .box-stats {
    display: block;
    background-color: transparent
}

.content-camps__stats .box-stats__item {
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
    padding-top: 32px;
    padding-bottom: 32px
}

.content-camps__stats .box-stats__item:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0
}

.content-camps__stats .box-stats__item i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: none;
    color: #fff;
    font-size: 1.375rem;
    margin: 0 auto;
    background: #f9aa81
}

.content-camps__stats .box-stats__item p {
    font-size: 1.25rem;
    color: #535a8b;
    margin: 16px auto 0
}

.content-camps__stats .box-stats__item p span {
    margin-right: 8px
}

.content-camps-subscription .dropdown-subscription {
    background-color: #fff;
    color: #7d83aa;
    border-radius: 4px;
    padding: .2rem;
    min-width: 17rem;
    font-size: .875rem
}

.content-camps-subscription .dropdown-subscription .dropdown-actions__selected {
    display: flex;
    justify-content: space-between
}

.content-camps-subscription .dropdown-subscription .dropdown-actions__selected .icon {
    transition: all .3s ease
}

.content-camps-subscription .dropdown-subscription.active .icon {
    transform: rotate(180deg)
}

.content-camps-subscription .dropdown-subscription__item {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between
}

.content-camps-subscription .dropdown-subscription__value {
    color: #feb88a
}

.content-camps-subscription .dropdown-subscription ul {
    overflow: hidden
}

.content-camps-subscription .camp-subscribe.content-camps__list .content-camps__subtitle {
    margin-top: 2rem
}

.content-camps-subscription .camp-subscribe.content-camps__list .separator {
    margin: 0 .5rem
}

.content-camps-subscription .camp-subscribe.content-camps__list .dynamic-table__cell.cell-multiline {
    flex-direction: column
}

.content-camps-subscription .camp-subscribe.content-camps__list .dynamic-table__cell.cell-multiline .title {
    width: 100%
}

.content-camps-stats__header {
    margin-bottom: calc(.5em*2.5);
    padding-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4
}

.content-stats-providers__header {
    margin-bottom: calc(.5em*2.5);
    padding-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4
}

.content-stats-usage__header {
    margin-bottom: calc(.5em*2.5);
    padding-bottom: calc(.5em*2.5);
    border-bottom: 1px solid #d3d5e4
}

.content-camps-stats__search {
    margin: 0 calc(calc(.5em*1)*-1)
}

.content-stats-providers__search {
    margin: 0 calc(calc(.5em*1)*-1)
}

.content-stats-usage__search {
    margin: 0 calc(calc(.5em*1)*-1)
}

@media screen and (max-width:80rem) {
    .content-camps-stats__search {
        margin: 0 calc(calc(.5em*0.5)*-1)
    }

    .content-stats-providers__search {
        margin: 0 calc(calc(.5em*0.5)*-1)
    }

    .content-stats-usage__search {
        margin: 0 calc(calc(.5em*0.5)*-1)
    }
}

.content-camps-stats__search>* {
    margin: 0 calc(.5em*1)
}

.content-stats-providers__search>* {
    margin: 0 calc(.5em*1)
}

.content-stats-usage__search>* {
    margin: 0 calc(.5em*1)
}

@media screen and (max-width:80rem) {
    .content-camps-stats__search>* {
        margin: 0 calc(.5em*0.5)
    }

    .content-stats-providers__search>* {
        margin: 0 calc(.5em*0.5)
    }

    .content-stats-usage__search>* {
        margin: 0 calc(.5em*0.5)
    }
}

.content-camps-stats__search p {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #8c91b4
}

.content-stats-providers__search p {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #8c91b4
}

.content-stats-usage__search p {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #8c91b4
}

.content-camps-stats__search p span {
    margin-left: calc(.5em*1);
    padding: 6px 10px;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .5)
}

.content-stats-providers__search p span {
    margin-left: calc(.5em*1);
    padding: 6px 10px;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .5)
}

.content-stats-usage__search p span {
    margin-left: calc(.5em*1);
    padding: 6px 10px;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .5)
}

.content-camps-stats__search .date-till .select {
    position: relative;
    cursor: pointer;
    margin-left: calc(.5em*1);
    border: 0;
    padding: 0;
    height: auto
}

.content-camps-stats__search .step-till .select {
    position: relative;
    cursor: pointer;
    margin-left: calc(.5em*1);
    border: 0;
    padding: 0;
    height: auto
}

.content-stats-providers__search .date-till .select {
    position: relative;
    cursor: pointer;
    margin-left: calc(.5em*1);
    border: 0;
    padding: 0;
    height: auto
}

.content-stats-providers__search .step-till .select {
    position: relative;
    cursor: pointer;
    margin-left: calc(.5em*1);
    border: 0;
    padding: 0;
    height: auto
}

.content-stats-usage__search .date-till .select {
    position: relative;
    cursor: pointer;
    margin-left: calc(.5em*1);
    border: 0;
    padding: 0;
    height: auto
}

.content-stats-usage__search .step-till .select {
    position: relative;
    cursor: pointer;
    margin-left: calc(.5em*1);
    border: 0;
    padding: 0;
    height: auto
}

.content-camps-stats__search .date-till .select:after,
.content-camps-stats__search .date-till .select:before,
.content-camps-stats__search .step-till .select:after,
.content-camps-stats__search .step-till .select:before,
.content-stats-providers__search .date-till .select:after,
.content-stats-providers__search .date-till .select:before,
.content-stats-providers__search .step-till .select:after,
.content-stats-providers__search .step-till .select:before,
.content-stats-usage__search .date-till .select:after,
.content-stats-usage__search .date-till .select:before,
.content-stats-usage__search .step-till .select:after,
.content-stats-usage__search .step-till .select:before {
    display: none
}

.content-camps-stats__search .date-till .select__placeholder {
    border: 1px solid #d3d5e4;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.content-camps-stats__search .step-till .select__placeholder {
    border: 1px solid #d3d5e4;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.content-stats-providers__search .date-till .select__placeholder {
    border: 1px solid #d3d5e4;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.content-stats-providers__search .step-till .select__placeholder {
    border: 1px solid #d3d5e4;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.content-stats-usage__search .date-till .select__placeholder {
    border: 1px solid #d3d5e4;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.content-stats-usage__search .step-till .select__placeholder {
    border: 1px solid #d3d5e4;
    background-color: #fff;
    border-radius: 4px;
    padding: calc(calc(.5em*1.5)/2) calc(.5em*1.5)
}

.content-camps-stats__search .date-till .select__placeholder .icon:before,
.content-camps-stats__search .step-till .select__placeholder .icon:before,
.content-stats-providers__search .date-till .select__placeholder .icon:before,
.content-stats-providers__search .step-till .select__placeholder .icon:before,
.content-stats-usage__search .date-till .select__placeholder .icon:before,
.content-stats-usage__search .step-till .select__placeholder .icon:before {
    transform: rotate(0deg);
    transition: transform .3s ease
}

.content-camps-stats__search .date-till .select__dropdown,
.content-camps-stats__search .step-till .select__dropdown,
.content-stats-providers__search .date-till .select__dropdown,
.content-stats-providers__search .step-till .select__dropdown,
.content-stats-usage__search .date-till .select__dropdown,
.content-stats-usage__search .step-till .select__dropdown {
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    position: absolute;
    top: calc(100% + 8px);
    left: -100px;
    z-index: 10
}

.content-camps-stats__search .date-till .select__dropdown .dropdown--simple,
.content-camps-stats__search .step-till .select__dropdown .dropdown--simple,
.content-stats-providers__search .date-till .select__dropdown .dropdown--simple,
.content-stats-providers__search .step-till .select__dropdown .dropdown--simple,
.content-stats-usage__search .date-till .select__dropdown .dropdown--simple,
.content-stats-usage__search .step-till .select__dropdown .dropdown--simple {
    left: 100px
}

.content-camps-stats__search .date-till .select.active .icon:before,
.content-camps-stats__search .step-till .select.active .icon:before,
.content-stats-providers__search .date-till .select.active .icon:before,
.content-stats-providers__search .step-till .select.active .icon:before,
.content-stats-usage__search .date-till .select.active .icon:before,
.content-stats-usage__search .step-till .select.active .icon:before {
    transform: rotate(-180deg)
}

.content-camps-stats__search .date-till .select.active .select__dropdown,
.content-camps-stats__search .step-till .select.active .select__dropdown,
.content-stats-providers__search .date-till .select.active .select__dropdown,
.content-stats-providers__search .step-till .select.active .select__dropdown,
.content-stats-usage__search .date-till .select.active .select__dropdown,
.content-stats-usage__search .step-till .select.active .select__dropdown {
    opacity: 1;
    visibility: visible
}

.content-camps-stats__action msc-button,
.content-stats-providers__action msc-button,
.content-stats-usage__action msc-button {
    padding: 3px 7px
}

.content-camps-stats__action msc-button i,
.content-stats-providers__action msc-button i,
.content-stats-usage__action msc-button i {
    font-size: 1.25rem
}

.content-camps-stats__body .stats-usage .col-separator,
.content-stats-providers__body .stats-usage .col-separator,
.content-stats-usage__body .stats-usage .col-separator {
    display: flex
}

@media screen and (max-width:48rem) {

    .content-camps-stats__body .stats-usage .col-separator,
    .content-stats-providers__body .stats-usage .col-separator,
    .content-stats-usage__body .stats-usage .col-separator {
        flex-direction: column
    }
}

@media screen and (max-width:64rem) {

    .content-camps-stats__body .stats-usage .col-separator,
    .content-stats-providers__body .stats-usage .col-separator,
    .content-stats-usage__body .stats-usage .col-separator {
        margin: 1rem 0
    }
}

.content-camps-stats__body .stats-usage__item {
    padding: 15px 0;
    background-color: #fff;
    border-radius: 4px
}

.content-stats-providers__body .stats-usage__item {
    padding: 15px 0;
    background-color: #fff;
    border-radius: 4px
}

.content-stats-usage__body .stats-usage__item {
    padding: 15px 0;
    background-color: #fff;
    border-radius: 4px
}

@media screen and (max-width:48rem) {

    .content-camps-stats__body .stats-usage__item,
    .content-stats-providers__body .stats-usage__item,
    .content-stats-usage__body .stats-usage__item {
        margin: 1rem 0
    }
}

.content-camps-stats__body .stats-usage__item.usage-item--green .stats-usage__item__header__count {
    color: #6cd4a8
}

.content-camps-stats__body .stats-usage__item.usage-item--green .stats-usage__item__header__title i {
    color: #6cd4a8
}

.content-stats-providers__body .stats-usage__item.usage-item--green .stats-usage__item__header__count {
    color: #6cd4a8
}

.content-stats-providers__body .stats-usage__item.usage-item--green .stats-usage__item__header__title i {
    color: #6cd4a8
}

.content-stats-usage__body .stats-usage__item.usage-item--green .stats-usage__item__header__count {
    color: #6cd4a8
}

.content-stats-usage__body .stats-usage__item.usage-item--green .stats-usage__item__header__title i {
    color: #6cd4a8
}

.content-camps-stats__body .stats-usage__item.usage-item--red .stats-usage__item__header__count {
    color: #f44436
}

.content-camps-stats__body .stats-usage__item.usage-item--red .stats-usage__item__header__title i {
    color: #f44436
}

.content-stats-providers__body .stats-usage__item.usage-item--red .stats-usage__item__header__count {
    color: #f44436
}

.content-stats-providers__body .stats-usage__item.usage-item--red .stats-usage__item__header__title i {
    color: #f44436
}

.content-stats-usage__body .stats-usage__item.usage-item--red .stats-usage__item__header__count {
    color: #f44436
}

.content-stats-usage__body .stats-usage__item.usage-item--red .stats-usage__item__header__title i {
    color: #f44436
}

.content-camps-stats__body .stats-usage__item.usage-item--grey .stats-usage__item__header__count {
    color: #8c91b4
}

.content-camps-stats__body .stats-usage__item.usage-item--grey .stats-usage__item__header__title i {
    color: #8c91b4
}

.content-stats-providers__body .stats-usage__item.usage-item--grey .stats-usage__item__header__count {
    color: #8c91b4
}

.content-stats-providers__body .stats-usage__item.usage-item--grey .stats-usage__item__header__title i {
    color: #8c91b4
}

.content-stats-usage__body .stats-usage__item.usage-item--grey .stats-usage__item__header__count {
    color: #8c91b4
}

.content-stats-usage__body .stats-usage__item.usage-item--grey .stats-usage__item__header__title i {
    color: #8c91b4
}

.content-camps-stats__body .stats-usage__item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    padding: 0 1.5rem 1rem;
    margin: 0 0 1rem
}

.content-stats-providers__body .stats-usage__item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    padding: 0 1.5rem 1rem;
    margin: 0 0 1rem
}

.content-stats-usage__body .stats-usage__item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    padding: 0 1.5rem 1rem;
    margin: 0 0 1rem
}

.content-camps-stats__body .stats-usage__item__header__title,
.content-stats-providers__body .stats-usage__item__header__title,
.content-stats-usage__body .stats-usage__item__header__title {
    display: flex;
    align-items: center
}

.content-camps-stats__body .stats-usage__item__header__title i,
.content-stats-providers__body .stats-usage__item__header__title i,
.content-stats-usage__body .stats-usage__item__header__title i {
    font-size: 1.375rem
}

.content-camps-stats__body .stats-usage__item__header__title span {
    margin: 0 0 0 1rem;
    font-size: .875rem;
    color: #656c9b
}

.content-stats-providers__body .stats-usage__item__header__title span {
    margin: 0 0 0 1rem;
    font-size: .875rem;
    color: #656c9b
}

.content-stats-usage__body .stats-usage__item__header__title span {
    margin: 0 0 0 1rem;
    font-size: .875rem;
    color: #656c9b
}

.content-camps-stats__body .stats-usage__item__header__count,
.content-stats-providers__body .stats-usage__item__header__count,
.content-stats-usage__body .stats-usage__item__header__count {
    font-size: 1.5rem
}

.content-camps-stats__body .stats-usage__item__chart,
.content-stats-providers__body .stats-usage__item__chart,
.content-stats-usage__body .stats-usage__item__chart {
    padding: 0 .5rem
}

.content-camps-stats__body .stats-usage__item__empty,
.content-stats-providers__body .stats-usage__item__empty,
.content-stats-usage__body .stats-usage__item__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1.1rem 1.5rem
}

.content-camps-stats__body .stats-usage__item__empty__icon,
.content-stats-providers__body .stats-usage__item__empty__icon,
.content-stats-usage__body .stats-usage__item__empty__icon {
    margin: 2rem 0 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center
}

.content-camps-stats__body .stats-usage__item__empty__icon i,
.content-stats-providers__body .stats-usage__item__empty__icon i,
.content-stats-usage__body .stats-usage__item__empty__icon i {
    font-size: 1.875rem
}

.content-camps-stats__body .stats-usage__item__empty__title {
    font-size: 1rem;
    color: #656c9b;
    margin: 1rem 0
}

.content-stats-providers__body .stats-usage__item__empty__title {
    font-size: 1rem;
    color: #656c9b;
    margin: 1rem 0
}

.content-stats-usage__body .stats-usage__item__empty__title {
    font-size: 1rem;
    color: #656c9b;
    margin: 1rem 0
}

.content-camps-stats__body .stats-usage__item__empty__desc {
    font-size: .875rem;
    color: #8c91b4;
    text-align: center;
    margin: 0 0 1rem
}

.content-stats-providers__body .stats-usage__item__empty__desc {
    font-size: .875rem;
    color: #8c91b4;
    text-align: center;
    margin: 0 0 1rem
}

.content-stats-usage__body .stats-usage__item__empty__desc {
    font-size: .875rem;
    color: #8c91b4;
    text-align: center;
    margin: 0 0 1rem
}

.content-camps-stats__body .stats-usage__item__actions,
.content-stats-providers__body .stats-usage__item__actions,
.content-stats-usage__body .stats-usage__item__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0 0;
    padding: 0 1.5rem
}

@media screen and (max-width:48rem) {

    .content-camps-stats__body .stats-usage__item__actions,
    .content-stats-providers__body .stats-usage__item__actions,
    .content-stats-usage__body .stats-usage__item__actions {
        display: none
    }
}

.content-camps-stats__body .stats-usage__item__actions .actions-legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d3d5e4;
    padding: 5px 10px;
    border-radius: 10px
}

.content-stats-providers__body .stats-usage__item__actions .actions-legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d3d5e4;
    padding: 5px 10px;
    border-radius: 10px
}

.content-stats-usage__body .stats-usage__item__actions .actions-legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d3d5e4;
    padding: 5px 10px;
    border-radius: 10px
}

.content-camps-stats__body .stats-usage__item__actions .actions-legend .legend-color {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 10px 0 0;
    background-color: #ed8371
}

.content-stats-providers__body .stats-usage__item__actions .actions-legend .legend-color {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 10px 0 0;
    background-color: #ed8371
}

.content-stats-usage__body .stats-usage__item__actions .actions-legend .legend-color {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 10px 0 0;
    background-color: #ed8371
}

.content-camps-stats__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--green {
    background-color: #6cd4a8
}

.content-stats-providers__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--green {
    background-color: #6cd4a8
}

.content-stats-usage__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--green {
    background-color: #6cd4a8
}

.content-camps-stats__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--blue {
    background-color: #5150c4
}

.content-stats-providers__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--blue {
    background-color: #5150c4
}

.content-stats-usage__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--blue {
    background-color: #5150c4
}

.content-camps-stats__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--red {
    background-color: #f44436
}

.content-stats-providers__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--red {
    background-color: #f44436
}

.content-stats-usage__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--red {
    background-color: #f44436
}

.content-camps-stats__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--grey {
    background-color: #8c91b4
}

.content-stats-providers__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--grey {
    background-color: #8c91b4
}

.content-stats-usage__body .stats-usage__item__actions .actions-legend .legend-color.legend-color--grey {
    background-color: #8c91b4
}

.content-camps-stats__body .stats-usage__item__actions .actions-legend .legend-title {
    color: #d3d5e4;
    font-size: .875rem;
    font-weight: 600
}

.content-stats-providers__body .stats-usage__item__actions .actions-legend .legend-title {
    color: #d3d5e4;
    font-size: .875rem;
    font-weight: 600
}

.content-stats-usage__body .stats-usage__item__actions .actions-legend .legend-title {
    color: #d3d5e4;
    font-size: .875rem;
    font-weight: 600
}

.content-camps-stats__body .stats-usage__item__actions i {
    font-size: 1.375rem;
    color: #656c9b;
    cursor: pointer;
    transition: all .3s ease
}

.content-stats-providers__body .stats-usage__item__actions i {
    font-size: 1.375rem;
    color: #656c9b;
    cursor: pointer;
    transition: all .3s ease
}

.content-stats-usage__body .stats-usage__item__actions i {
    font-size: 1.375rem;
    color: #656c9b;
    cursor: pointer;
    transition: all .3s ease
}

.content-camps-stats__body .stats-usage__item__actions i:hover {
    color: #020d38
}

.content-stats-providers__body .stats-usage__item__actions i:hover {
    color: #020d38
}

.content-stats-usage__body .stats-usage__item__actions i:hover {
    color: #020d38
}

.container-camps__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -16px 32px
}

.container-camps__action>* {
    margin: 0 16px
}

.container-camps .navigation-camps {
    /* padding: 20px 30px 20px 0;
    margin: -15px 0 -15px -12px;
    background: #fff;
    border-radius: 4px;
   /* box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)*/
}

@media screen and (min-width:64rem) {
    .container-camps .navigation-camps {
        /* position: fixed;*/
        width: 100%
    }
}

@media screen and (min-width:80rem) {
    .container-camps .navigation-camps {
        /*width: 17%*/
        width: 100%;
    }
}

.container-camps .navigation-camps .navigation-camps__content .navigation-camps__separator {
    width: 100%;
    height: 1px;
    background-color: #d3d5e4;
    margin: 1rem 0 .5rem 15px
}

.container-camps .navigation-camps .navigation-camps__content .navigation-camps__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.container-camps .navigation-camps .navigation-camps__content .navigation-camps__item .toggle i:before {
    transform: rotate(0deg);
    font-size: 1.125rem;
    transition: all .3s ease
}

.container-camps .navigation-camps .navigation-camps__content .navigation-camps__item.displayed .toggle i:before {
    transform: rotate(180deg)
}

.container-camps .navigation-camps .navigation-camps__content .navigation-camps__item.displayed+.navigations-camps__sub {
    display: block
}

.container-camps .navigation-camps .navigation-camps__content .navigations-camps__sub {
    display: none
}

.container-camps .navigation-camps .navigation-camps__content .navigations-camps__sub li {
    width: 100%;
    padding: 10px 0 10px 50px;
    transition: all .3s ease;
    cursor: pointer
}

.container-camps .navigation-camps .navigation-camps__content .navigations-camps__sub li p {
    font-size: .875rem;
    color: #656c9b;
    transition: all .3s ease;
    display: flex
}

.container-camps .navigation-camps .navigation-camps__content .navigations-camps__sub li i:before {
    font-size: 1.25rem;
    color: #656c9b;
    margin-right: 8px;
    transition: all .3s ease
}

.content-invoice {
    margin-top: 0
}

.content-invoice .dynamic-table__body .cell-actions a {
    color: #b2b5cd;
    transition: all .3s ease;
    display: flex;
    align-items: center
}

.content-invoice .dynamic-table__body .cell-actions .icon {
    color: #b2b5cd;
    font-size: 1.125rem;
    transition: all .3s ease
}

.content-invoice .dynamic-table__body .cell-actions:hover .icon {
    color: #8c91b4
}

.content-invoice .dynamic-table__body .cell-actions:hover a {
    color: #8c91b4
}

.content-settings__main {
    margin-top: 0
}

.content-settings__main .link {
    display: block;
    margin-top: 24px;
    font-size: .875rem
}

.content-settings__aside {
    margin-top: 0
}

@media screen and (min-width:48rem) {
    .content-settings__aside {
        display: flex;
        justify-content: space-between
    }
}

@media screen and (min-width:64rem) {
    .content-settings__aside {
        display: block
    }
}

@media screen and (min-width:48rem) {
    .content-settings__aside .block-company-progress {
        width: 30%
    }
}

@media screen and (min-width:64rem) {
    .content-settings__aside .block-company-progress {
        width: 100%
    }
}

.content-settings__contact {
    margin-top: 32px;
    padding: 16px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:48rem) {
    .content-settings__contact {
        margin-top: 0;
        width: 50%
    }
}

@media screen and (min-width:64rem) {
    .content-settings__contact {
        margin-top: 32px;
        width: 100%
    }
}

.content-settings__contact__header {
    font-size: .875rem;
    color: #020d38
}

.content-settings__contact__dropdown {
    margin-top: 32px
}

.content-settings__contact__dropdown .dropdown-contact__content {
    top: 60px;
    right: -15px;
    min-width: 240px
}

.content-subscription__main {
    margin-top: 0
}

.content-subscription__title {
    display: block;
    margin: 24px 0 12px;
    font-size: 1.375rem;
    font-weight: 300
}

.content-subscription .link {
    margin-top: 24px;
    font-size: .75rem;
    cursor: pointer
}

.container-company {
    position: relative;
    height: auto;
    margin-top: calc(.5em*3.5)
}

@media screen and (min-width:64rem) {
    .container-company {
        margin-top: 0
    }
}

.container-company__header {
    display: flex;
    align-items: center
}

.container-company__header__title {
    display: flex;
    justify-content: flex-start;
    width: 45%
}

.container-company__header__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 55%
}

.container-company__header__actions p {
    color: #8c91b4;
    margin: 0 8px
}

.container-company__header__actions i {
    font-size: 1.375rem;
    transition: all .3s ease
}

.container-company__name {
    font-size: 1.375rem;
    color: #020d38
}

.container-company__clickable,
.container-company__counts {
    display: flex;
    align-items: center
}

.container-company__clickable i {
    cursor: pointer
}

.container-company__clickable i:hover {
    color: #656c9b
}

.container-company__nav {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: calc(.5em*2.5) calc(.5em*2) 0 calc(.5em*2) !important
}

@media screen and (min-width:32rem) {
    .container-company__nav {
        margin: 0 calc(.5em*2) !important
    }
}

.container-company__nav a {
    display: inline-block;
    outline: none;
    margin: 10px;
    font-size: 1.5rem;
    color: #feb88a;
    background-color: rgba(254, 184, 138, .1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.container-company__nav a:hover {
    transform: scale(1.04);
    color: #ed8371
}

.container-company__nav a i {
    font-size: 1.5rem
}

.container-company__main {
    margin-top: calc(.5em*3.5)
}

.container-company__back {
    position: relative;
    top: 0;
    left: 0
}

@media screen and (min-width:64rem) {
    .container-company__back {
        position: absolute;
        top: 0;
        left: -15px
    }
}

@media screen and (min-width:80rem) {
    .container-company__back {
        top: 12px;
        left: -45px
    }
}

.container-company__row {
    position: relative;
    padding-left: 0
}

@media screen and (min-width:64rem) {
    .container-company__row {
        padding-left: 20px
    }
}

.container-company__aside {
    margin-bottom: calc(.5em*3)
}

@media screen and (min-width:64rem) {
    .container-company__aside {
        margin-bottom: 0
    }
}

@media screen and (min-width:64rem) {
    .content-experience--dashboard .content-experience__item--grouped {
        display: flex;
        justify-content: space-between
    }
}

.content-experience--dashboard .content-experience__item--grouped .col-md-6 {
    margin: 0
}

.container-experience .content-experience .dynamic-table__row {
    min-height: 100px
}

@media screen and (max-width:32rem) {
    .container-experience .content-experience .dynamic-table .cell-title {
        width: 60%
    }
}

.container-experience .content-experience .dynamic-table .cell-picture {
    width: 5.5rem;
    height: 5.5rem;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain
}

.container-experience .content-experience .dynamic-table .cell-details {
    margin: 0 5rem 0 0;
    color: #feb88a;
    font-size: 1rem;
    cursor: pointer;
    transition: all .3s ease
}

.container-experience .content-experience .dynamic-table .cell-details:hover {
    color: #ed8371
}

@media screen and (max-width:32rem) {
    .container-experience .content-experience .dynamic-table .cell-details {
        font-size: .75rem;
        margin: 0 1rem 0 0
    }
}

.container-experience {
    position: relative;
    height: auto;
    margin-top: calc(.5em*3.5)
}

@media screen and (min-width:32rem) {
    .container-experience {
        margin-top: 0
    }
}

.container-experience__header {
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.container-experience__header__title {
    display: flex;
    justify-content: flex-start;
    width: 45%
}

.container-experience__header__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 55%
}

.container-experience__header__actions p {
    color: #8c91b4;
    margin: 0 8px
}

.container-experience__header__actions i {
    font-size: 1.375rem;
    transition: all .3s ease
}

.container-experience__name {
    font-size: 1.375rem;
    color: #020d38
}

.container-experience__name .logo-provider {
    max-width: 26px;
    height: auto;
    margin: 0 calc(.5em*3) 0 calc(.5em*1.5)
}

.container-experience__clickable,
.container-experience__counts {
    display: flex;
    align-items: center
}

.container-experience__clickable i {
    cursor: pointer
}

.container-experience__clickable i:hover {
    color: #656c9b
}

.container-experience__nav {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: calc(.5em*2.5) calc(.5em*2) 0 calc(.5em*2) !important
}

@media screen and (min-width:32rem) {
    .container-experience__nav {
        margin: 0 calc(.5em*2) !important
    }
}

.container-experience__nav a {
    display: inline-block;
    outline: none;
    margin: 10px;
    font-size: 1.5rem;
    color: #feb88a;
    background-color: rgba(254, 184, 138, .1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.container-experience__nav a:hover {
    transform: scale(1.04);
    color: #ed8371
}

.container-experience__nav a i {
    font-size: 1.5rem
}

.container-experience__main {
    margin-top: calc(.5em*3.5)
}

.container-experience__back {
    position: relative;
    top: 0;
    left: 0
}

@media screen and (min-width:64rem) {
    .container-experience__back {
        position: absolute;
        top: 0;
        left: -15px
    }
}

@media screen and (min-width:80rem) {
    .container-experience__back {
        top: 12px;
        left: -45px
    }
}

.container-experience__row {
    position: relative;
    padding-left: 0
}

@media screen and (min-width:64rem) {
    .container-experience__row {
        padding-left: 20px
    }
}

.container-experience__aside {
    margin-bottom: calc(.5em*3)
}

@media screen and (min-width:64rem) {
    .container-experience__aside {
        margin-bottom: 0
    }
}

.content-integration-catalogue-export__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: calc(.5em*3);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.content-integration-catalogue-export__title {
    color: #7d83aa;
    font-size: 1.125rem
}

.content-integration-catalogue-export__description {
    margin: calc(.5em*3) 0;
    color: #8c91b4;
    font-size: .875rem;
    line-height: 1.3
}

.content-integration-mapping {
    padding: 2rem;
    color: #656c9b
}

.content-integration-mapping__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: calc(.5em*3);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.content-integration-mapping__header msc-button {
    display: flex;
    align-items: center;
    height: 30px
}

.content-integration-mapping__header msc-button i {
    font-size: 1.25rem
}

@media screen and (min-width:90rem) {
    .content-integration-mapping--default {
        height: 75vh
    }
}

@media screen and (min-width:90rem) {
    .content-integration-mapping--default .content-integration-mapping__list__items {
        height: 48vh
    }
}

@media screen and (min-width:160rem) {
    .content-integration-mapping--default .content-integration-mapping__list__items {
        height: 53vh
    }
}

.content-integration-mapping__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #7d83aa
}

@media screen and (max-width:32rem) {
    .content-integration-mapping__title {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        font-size: 1rem;
        margin: 0 0 1rem
    }
}

.content-integration-mapping__list__actions {
    margin: 1rem 0 0;
    width: 20%
}

@media screen and (max-width:32rem) {
    .content-integration-mapping__list__actions {
        width: 100%
    }
}

.content-integration-mapping__list__items {
    margin: 1rem 0 0;
    padding: 0 1rem 0 0;
    overflow-y: auto;
    height: 40rem
}

.content-integration-mapping__list__items.empty {
    display: flex;
    flex-direction: column;
    align-items: center
}

.content-integration-mapping__list__items .block-provider-skill {
    margin: 1rem 0
}

@media screen and (min-width:64rem) {
    .content-integration-mapping__wrapper {
        display: flex
    }
}

@media (-ms-high-contrast:none) {

    .content-integration-mapping .content-integration-mapping__wrapper,
    .content-integration-mapping ::-ms-backdrop {
        height: 1000px
    }
}

.content-integration-mapping__description {
    margin: 1rem 0 0;
    display: flex;
    align-items: center
}

.content-integration-mapping__description i {
    font-size: 1.375rem
}

.content-integration-mapping__description span {
    margin: 0 0 0 .5rem
}

.content-integration-mapping .content-integration-mapping__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 1.5rem;
    background: rgba(211, 213, 228, .2);
    border-radius: 4px;
    border: 1px solid #d3d5e4;
    min-width: 250px
}

@media screen and (min-width:64rem) {
    .content-integration-mapping .content-integration-mapping__block {
        margin-right: 1rem
    }
}

.content-integration-mapping .content-integration-mapping__block__header {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #d3d5e4
}

.content-integration-mapping .content-integration-mapping__block__body {
    flex: 1
}

.content-integration-mapping .content-integration-mapping__block__footer {
    padding-top: 1.5rem
}

.content-integration-mapping .content-integration-mapping__block h2 {
    color: #656c9b
}

@media screen and (min-width:64rem) {
    .content-integration-mapping .content-integration-mapping__block__wrapper {
        width: 40%
    }
}

.content-integration-mapping .content-integration-mapping__skills {
    flex: 1
}

.content-integration-mapping .content-integration-mapping__skills__title:not(:first-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid #b2b5cd
}

.content-integration-mapping .content-integration-mapping__skills__list {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 700px;
    margin: 1rem 0
}

.content-integration-mapping .content-integration-mapping__skills__list:after {
    clear: both;
    content: "";
    display: block
}

.content-integration-mapping .content-integration-mapping__skills__list__category .mapping-category__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 5px);
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    cursor: pointer
}

.content-integration-mapping .content-integration-mapping__skills__list__category .mapping-category__title .skill {
    display: flex;
    justify-content: space-between;
    margin-right: 1rem;
    width: 110px;
    font-size: .875rem
}

.content-integration-mapping .content-integration-mapping__skills__list__category .mapping-category__title .action-toggle {
    transition: all .3s ease
}

.content-integration-mapping .content-integration-mapping__skills__list__category .mapping-category__title.active .action-toggle {
    transform: rotate(-180deg)
}

@media screen and (max-width:80rem) {
    .content-integration-mapping .content-integration-mapping__skills__list__category .mapping-category__title {
        display: block
    }
}

@media screen and (min-width:64rem) {
    .content-integration-mapping .content-integration-mapping__selector {
        width: 60%
    }
}

.content-integration-mapping .content-integration-mapping__selector__map {
    margin: 1rem 0
}

.content-integration-mapping .content-integration-mapping__selector__map .block-provider-skill {
    background-color: #fff;
    margin: 1rem 0;
    height: 60px;
    display: flex;
    justify-content: center
}

.content-integration-mapping .content-integration-mapping__selector__map .block-provider-skill.active {
    height: auto !important
}

.content-integration-mapping .content-integration-mapping__selector__map .block-provider-skill.active .block-provider-skill__tags {
    flex-wrap: wrap
}

.content-integration-mapping__actions {
    margin-top: 2rem
}

.content-integration .spinner {
    height: 100px;
    width: 100px
}

.content-integration .spinner span {
    height: 80px;
    width: 80px
}

.content-integration.box {
    padding: calc(.5em*3.5)
}

.content-integration__loading {
    display: flex;
    flex-direction: column;
    align-items: center
}

.content-integration__loading__heading {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 20px;
    color: #9aa0c9
}

.content-integration__loading__description {
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    text-align: center;
    color: #9aa0c9
}

.content-integration--settings .content-integration__header {
    padding-bottom: calc(.5em*3);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.content-integration--settings .content-integration__faq-link {
    color: #b2b5cd;
    margin: 1rem 0 0
}

.content-integration--settings .content-integration__faq-link a {
    color: #b2b5cd;
    text-decoration: underline;
    transition: all .3s ease
}

.content-integration--settings .content-integration__faq-link a:hover {
    color: #656c9b;
    transition: all .3s ease
}

.content-integration--settings .content-integration__title {
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #7d83aa
}

.content-integration--settings .content-integration__fieldset,
.content-integration--settings .content-integration__form {
    margin: 1rem 0 0
}

.content-integration--settings .content-integration__fieldset:first-child {
    margin: 0
}

.content-integration--settings .content-integration__fieldset__label {
    color: #535a8b;
    margin: 0 0 .7rem
}

.content-integration--settings .content-integration__fieldset__description {
    color: #b2b5cd;
    font-size: .875rem
}

.content-integration--settings .content-integration__fieldset__description a {
    color: #b2b5cd;
    text-decoration: underline;
    transition: all .3s ease
}

.content-integration--settings .content-integration__fieldset__description a:hover {
    color: #656c9b;
    transition: all .3s ease
}

.content-integration--settings .content-integration__fieldset__export {
    margin: 1rem 0 0
}

.content-integration--settings .content-integration__fieldset .input__label {
    margin: 0 0 0 1rem
}

.content-integration--settings .content-integration__actions {
    margin: 2.5rem 0 0
}

.content-integration--settings .content-integration__actions msc-button {
    margin: 0 1rem 0 0
}

.content-integration--settings .content-integration__actions msc-button:last-child {
    margin: 0
}

.content-integration-exception {
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.content-integration-exception__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: calc(.5em*3);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.content-integration-exception__title {
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #7d83aa
}

.content-integration-exception__description {
    margin: calc(.5em*3) 0;
    color: #8c91b4;
    font-size: .875rem;
    line-height: 1.3
}

.content-integration-exception__filters {
    margin: 1rem 0;
    display: flex;
    align-items: center
}

.content-integration-exception__filters .filter-by {
    margin: calc(.5em*1)
}

@media screen and (min-width:32rem) {
    .content-integration-exception__filters .filter-by {
        margin: 0 calc(.5em*1)
    }
}

.content-integration-exception__filters .filter-by.form-search {
    border: 1px solid #d3d5e4
}

.content-integration-exception__filters .filter-by.multi-select {
    width: 93px
}

.content-integration-exception__filters .filter-by.multi-select .multi-select__content {
    padding: 6px 16px;
    margin-top: 0
}

.content-integration-exception__filters .filter-by.multi-select .multi-select__data {
    min-width: 200px
}

.content-integration-exception__table .dynamic-table__cell.cell-main {
    width: 40%
}

.content-integration-exception__table .dynamic-table__cell.cell-main span {
    color: #656c9b;
    font-size: 1rem;
    white-space: nowrap
}

.content-integration-exception__table .dynamic-table__cell.cell-main .tooltip {
    margin-left: calc(.5em*1.5)
}

.content-integration-exception__table .dynamic-table__cell.cell-skills {
    flex-wrap: wrap;
    width: 50%
}

.content-integration-exception__table .dynamic-table__cell .cell-actions__edit i {
    font-size: 1.375rem;
    color: #8c91b4;
    transition: all .3s ease
}

.content-integration-exception__table .dynamic-table__cell .cell-actions__edit:hover i {
    color: #656c9b;
    transition: all .3s ease
}

.content-integration-exception__table .dynamic-table__row {
    border: 2px solid rgba(211, 213, 228, .4)
}

.content-integration-exception__load {
    margin: 1rem 0 0
}

.content-integration {
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.content-integration__header {
    justify-content: space-between
}

.content-integration__deploy,
.content-integration__header {
    display: flex;
    align-items: center
}

.content-integration__deploy i {
    color: #6cd4a8;
    font-size: 1.5rem
}

.content-integration__deploy span {
    margin: 0 1rem 0 .5rem;
    color: #7d83aa;
    font-size: .875rem
}

.content-integration--rules .content-integration__header {
    padding-bottom: calc(.5em*3);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.content-integration--rules .content-integration__title {
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #7d83aa
}

.content-integration--rules .dynamic-table__header .dynamic-table__row {
    margin-top: 0
}

.content-integration--rules .dynamic-table__header .dynamic-table__cell {
    color: #7d83aa;
    font-size: .875rem;
    line-height: 1.25rem
}

.content-integration--rules .dynamic-table__body .dynamic-table__row {
    border: 2px solid rgba(211, 213, 228, .4)
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--disable {
    background-color: rgba(211, 213, 228, .2)
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--disable .dynamic-table__cell {
    color: #b2b5cd
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--disable .dynamic-table__cell span {
    color: #b2b5cd
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--disable .dynamic-table__cell i {
    color: #d3d5e4 !important
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--disable .dynamic-table__cell a {
    cursor: default;
    pointer-events: none
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--disable .dynamic-table__cell a:hover.tooltip:after,
.content-integration--rules .dynamic-table__body .dynamic-table__row--disable .dynamic-table__cell a:hover.tooltip:before {
    visibility: hidden
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--add {
    cursor: pointer;
    border-style: dashed;
    transform: scale(1);
    transition: all .3s ease
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--add:hover {
    border-color: #d3d5e4;
    transform: scale(1.005);
    transition: all .3s ease
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--add:hover .dynamic-table__cell span {
    color: #020d38
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--add .dynamic-table__cell span {
    font-size: .875rem;
    line-height: 1.25rem;
    color: #656c9b;
    transition: all .3s ease
}

.content-integration--rules .dynamic-table__body .dynamic-table__row--add .dynamic-table__cell i {
    font-size: 1.375rem;
    padding-left: calc(calc(.5em*1.5)*-1);
    padding-right: calc(.5em*2)
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell {
    color: #7d83aa;
    font-size: .875rem;
    line-height: 1.125rem
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell a {
    margin-right: calc(.5em*2)
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell span.tooltip {
    margin-right: calc(.5em*2)
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell i {
    font-size: 1.375rem;
    padding-left: calc(calc(.5em*1.5)*-1);
    transition: all .3s ease
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell.cell-actions {
    margin: 0 calc(calc(.5em*1)*-1);
    padding-left: calc(.5em*3);
    border-left: 1px solid rgba(211, 213, 228, .5)
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell.cell-actions>* {
    margin: 0 calc(.5em*1)
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell .cell-actions__edit i {
    color: #d3d5e4;
    transition: all .3s ease
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell .cell-actions__edit:hover i {
    color: #8c91b4;
    transition: all .3s ease
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell .cell-actions__delete i {
    color: #e57373;
    transition: all .3s ease
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell .cell-actions__delete:hover i {
    color: #f44436;
    transition: all .3s ease
}

.content-integration--rules .dynamic-table__body .dynamic-table__cell--main span {
    color: #020d38;
    font-size: .875rem;
    line-height: 1.125rem
}

.form-filter-fieldset>.form-filter-fieldset__radio {
    margin: calc(.5em*2) 0
}

.form-selection__body {
    padding: 0 1rem;
    overflow-y: auto;
    overflow-x: hidden
}

@media screen and (min-width:90rem) {
    .form-selection__body {
        max-height: 36vh
    }
}

@media screen and (min-width:160rem) {
    .form-selection__body {
        max-height: 53vh
    }
}

.form-selection__list {
    margin: calc(.5em*3.5) 0
}

@media screen and (min-width:32rem) {
    .form-selection__list {
        margin: calc(.5em*3.5) calc(calc(.5em*2)*-1)
    }
}

.form-selection__item {
    width: 100%;
    margin: calc(.5em*1)
}

@media screen and (min-width:48rem) {
    .form-selection__item {
        width: calc(50% - calc(.5em*2));
        margin: calc(.5em*1)
    }
}

@media screen and (min-width:64rem) {
    .form-selection__item {
        width: calc(33.33333% - calc(.5em*2));
        margin: calc(.5em*1)
    }
}

.form-selection__item .card--min:hover {
    border: 2px solid #8290fd !important;
    transform: scale(1.03)
}

.form-selection__warning {
    margin-top: 1rem;
    font-size: .875rem;
    font-style: italic
}

@media screen and (min-width:90rem) {
    .container-integration__body {
        height: 75vh;
        max-height: 75vh
    }
}

.container-integration__header {
    display: block !important;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:32rem) {
    .container-integration__header {
        display: flex !important
    }
}

.container-integration__picture {
    width: 150px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.container-integration__logo {
    width: 15px;
    max-height: 15px;
    margin-right: calc(.5em*1.5);
    margin-left: calc(.5em*1)
}

@media screen and (min-width:32rem) {
    .container-integration__logo {
        width: 30px;
        max-height: 30px;
        margin-right: calc(.5em*3);
        margin-left: calc(.5em*2)
    }
}

.container-integration__breadcrumb {
    align-items: center
}

.container-integration__breadcrumb h3 {
    display: flex;
    font-size: .875rem;
    line-height: 1rem;
    color: #7d83aa
}

.container-integration__breadcrumb h3:before {
    content: "\e80d";
    font-family: myskillcamp;
    font-size: 1rem;
    padding-right: calc(.5em*1);
    padding-left: calc(.5em*1)
}

@media screen and (min-width:32rem) {
    .container-integration__breadcrumb h3 {
        font-size: 1.125rem;
        line-height: 1.25rem
    }

    .container-integration__breadcrumb h3:before {
        font-size: 1.375rem
    }
}

.container-integration__provider {
    margin: 0 0 0 1rem
}

.container-integration__form .container-integration__breadcrumb {
    position: relative
}

.container-integration__form .container-integration__breadcrumb h2 {
    font-size: .875rem;
    color: #8c91b4
}

@media screen and (min-width:32rem) {
    .container-integration__form .container-integration__breadcrumb {
        position: absolute;
        top: 35px;
        left: 30px
    }

    .container-integration__form .container-integration__breadcrumb h2 {
        font-size: 1.125rem
    }
}

.container-integration__resume {
    margin-top: calc(.5em*3)
}

@media screen and (min-width:32rem) {
    .container-integration__resume {
        margin-top: 0
    }
}

.container-integration__company-picture {
    max-height: 40px
}

.container-integration .form-filter__header {
    position: relative;
    text-align: center;
    margin-bottom: calc(.5em*3);
    padding-bottom: calc(.5em*3);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.container-integration .form-filter__header__cancel {
    cursor: pointer;
    position: absolute;
    top: -25px;
    right: -25px
}

.container-integration .form-filter__header__cancel i {
    font-size: 1.5rem;
    color: #b2b5cd;
    transition: all .3s ease
}

.container-integration .form-filter__header__cancel i:before {
    transition: all .3s ease
}

.container-integration .form-filter__header__cancel:hover i {
    color: #8c91b4;
    transition: all .3s ease
}

.container-integration .form-filter__header__cancel:hover i:before {
    transform: rotate(90deg);
    transition: all .3s ease
}

@media screen and (max-width:48rem) {
    .container-integration .form-filter__header .slide__ariane__step {
        margin-right: 25px;
        width: 12px;
        height: 12px
    }
}

@media screen and (max-width:48rem) {
    .container-integration .form-filter__header .slide__ariane__step:before {
        width: 25px
    }
}

.container-integration .form-filter__actions {
    margin-top: .5rem;
    display: flex !important;
    align-items: center;
    justify-content: space-between
}

.container-integration .form-filter__actions__wrapper {
    display: inline-block;
    float: right
}

.container-integration .form-filter__actions__wrapper msc-button {
    margin-left: 1rem
}

.container-integration .form-filter-step {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.container-integration .form-filter-step:before {
    content: "";
    position: absolute;
    z-index: 0;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: calc(100% - 50px);
    background-color: #d3d5e4
}

@media screen and (min-width:32rem) {
    .container-integration .form-filter-step:before {
        width: calc(100% - 108px);
        right: 54px
    }
}

.container-integration .form-filter-step__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin: 0 25px;
    border-radius: 50%;
    border: 2px solid #d3d5e4;
    background-color: #fff
}

@media screen and (min-width:32rem) {
    .container-integration .form-filter-step__item {
        margin: 0 54px
    }
}

.container-integration .form-filter-step__item--current {
    position: relative;
    border: 6px solid #fff;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.container-integration .form-filter-step__item--success {
    border: none
}

.container-integration .form-filter-step__item--success span:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 20px;
    width: 50px;
    height: 2px
}

@media screen and (min-width:32rem) {
    .container-integration .form-filter-step__item--success span:before {
        width: 108px
    }
}

.container-integration .form-filter-step__item--success span:after {
    font-family: myskillcamp;
    content: "\e935";
    font-size: .875rem;
    color: #fff
}

.container-integration .form-filter-resume {
    padding: calc(.5em*1.5);
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .25)
}

.container-integration .form-filter-resume__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #8290fd;
    font-size: 1rem;
    margin-bottom: calc(.5em*1.5);
    padding-bottom: calc(.5em*1.5);
    border-bottom: 1px solid rgba(211, 213, 228, .4)
}

.container-integration .form-filter-resume__title i {
    margin-right: .75rem;
    font-size: 1.375rem
}

.container-integration .form-filter-resume__list {
    overflow-y: auto;
    padding-right: calc(.5em*4)
}

@media screen and (min-width:90rem) {
    .container-integration .form-filter-resume__list {
        height: 42vh;
        max-height: 42vh
    }
}

@media screen and (min-width:160rem) {
    .container-integration .form-filter-resume__list {
        height: 50vh;
        max-height: 50vh
    }
}

.container-integration .form-filter-resume__list>li {
    color: #656c9b;
    font-size: 1rem
}

.container-integration .form-filter-resume__list>li i {
    font-size: 1.25rem;
    margin-right: calc(.5em*0.5)
}

.container-integration .form-filter-resume__list li {
    margin: calc(.5em*2) 0 calc(.5em*2.5) 0
}

.container-integration .form-filter-resume__list .form-filter-resume__subitem {
    color: #b2b5cd;
    margin: calc(.5em*2) 0 calc(.5em*2) calc(.5em*4);
    font-size: .75rem
}

.container-integration .form-filter-resume__list .form-filter-resume__subitem ul li {
    color: #b2b5cd;
    margin: calc(.5em*2) 0 calc(.5em*2) calc(.5em*4);
    font-size: .75rem
}

.container-integration .form-filter-resume__list .form-filter-resume__subitem--flag {
    display: flex;
    align-items: center
}

.container-integration .form-filter-resume__list .form-filter-resume__subitem--flag img {
    max-width: 18px;
    height: auto;
    margin-right: calc(.5em*1)
}

.container-integration .form-filter-resume .form-filter-resume__sublist ul {
    display: none
}

.container-integration .form-filter-resume .form-filter-resume__sublist a {
    width: 20px;
    height: 16px;
    transform: rotate(0deg);
    transition: transform .5s ease
}

.container-integration .form-filter-resume .form-filter-resume__sublist a i {
    margin-right: 0
}

.container-integration .form-filter-resume .form-filter-resume__sublist.active ul {
    display: block
}

.container-integration .form-filter-resume .form-filter-resume__sublist.active a {
    transform: rotate(180deg)
}

.container-integration .form-filter-fieldset {
    padding: 0 1rem;
    overflow-y: auto;
    overflow-x: hidden
}

@media screen and (min-width:90rem) {
    .container-integration .form-filter-fieldset {
        max-height: 52vh
    }
}

@media screen and (min-width:160rem) {
    .container-integration .form-filter-fieldset {
        max-height: 55vh
    }
}

.container-integration .form-filter-fieldset__list>li {
    margin: calc(.5em*3) 0;
    padding: 0 0 calc(.5em*3) 0;
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

.container-integration .form-filter-fieldset__list li .form-filter-fieldset__content.active {
    display: block
}

.container-integration .form-filter-fieldset__radio {
    width: 100%
}

@media screen and (min-width:32rem) {
    .container-integration .form-filter-fieldset__radio {
        width: 50%
    }
}

.container-integration .form-filter-fieldset__radio-margin {
    margin: 0 0 0 2.25rem
}

.container-integration .form-filter-fieldset__label {
    cursor: pointer;
    margin-left: calc(.5em*1);
    display: block;
    font-size: 1rem
}

.container-integration .form-filter-fieldset__label p {
    font-size: 1rem;
    line-height: 1.125rem;
    color: #282d58
}

.container-integration .form-filter-fieldset__label span {
    display: block;
    margin-top: calc(.5em*0.5);
    font-size: .875rem;
    color: #b2b5cd
}

.container-integration .form-filter-fieldset__content {
    display: none;
    margin-top: calc(.5em*3)
}

.container-integration .form-filter-fieldset__search {
    width: 100%;
    margin: calc(.5em*3) 0
}

@media screen and (min-width:32rem) {
    .container-integration .form-filter-fieldset__search {
        width: 20%
    }
}

.container-integration .form-filter-fieldset__camps {
    margin: 0 calc(calc(.5em*2)*-1)
}

.container-integration .form-filter-fieldset__camps li {
    margin: calc(.5em*2);
    width: 100%
}

@media screen and (min-width:32rem) {
    .container-integration .form-filter-fieldset__camps li {
        width: 20%
    }
}

.container-integration .form-filter-fieldset__camps li .card-camp {
    border: 1px solid rgba(211, 213, 228, .5)
}

.container-integration .form-filter-fieldset__camps li .card-camp:hover {
    border: 1px solid #8290fd
}

.container-integration .form-selection__header {
    display: block !important
}

@media screen and (min-width:32rem) {
    .container-integration .form-selection__header {
        display: flex !important
    }
}

@media screen and (max-width:32rem) {
    .container-integration .form-selection__language {
        display: block !important
    }
}

.container-integration .form-selection__title {
    font-size: .875rem;
    color: #8c91b4;
    margin-top: calc(.5em*3);
    margin-bottom: calc(.5em*3);
    padding-bottom: calc(.5em*3);
    border-bottom: 1px solid #d3d5e4
}

.container-integration .form-selection__all {
    justify-content: flex-end;
    margin-top: calc(.5em*2)
}

@media screen and (min-width:32rem) {
    .container-integration .form-selection__all {
        margin-top: 0
    }
}

.container-integration .form-selection__cat {
    overflow: auto
}

@media screen and (min-width:90rem) {
    .container-integration .form-selection__cat {
        height: 20vh
    }
}

@media screen and (min-width:160rem) {
    .container-integration .form-selection__cat {
        height: 35vh
    }
}

.container-integration .form-selection__cat .form-selection__checkbox+.form-selection__sublist {
    display: none
}

.container-integration .form-selection__cat .form-selection__checkbox.active+.form-selection__sublist {
    display: block
}

.container-integration .form-selection__cat .form-selection__checkbox.active .icon-arrow-ios-down {
    transform: rotate(180deg)
}

.container-integration .form-selection__cat .form-selection__sublist {
    padding-left: 30px
}

.container-integration .form-selection__cat .form-selection__sublist .form-selection__label {
    padding: calc(.5em*2) calc(.5em*3)
}

.container-integration .form-selection__cat .form-selection__checkbox {
    align-items: center;
    margin: calc(.5em*1.5) 0
}

.container-integration .form-selection__cat .form-selection__checkbox i {
    color: #8c91b4;
    font-size: 1.25rem
}

.container-integration .form-selection__cat .form-selection__checkbox .icon-eye {
    color: #535a8b
}

.container-integration .form-selection__cat .form-selection__label {
    width: calc(100% - 30px);
    padding: calc(.5em*2.5) calc(.5em*3);
    border: 1px solid rgba(211, 213, 228, .5);
    border-radius: 4px
}

.container-integration .form-selection__cat .form-selection__label .icon-arrow-ios-down {
    cursor: pointer;
    transform: rotate(0);
    transition: transform .3s ease
}

.container-integration .form-selection__cat .form-selection__label .tooltip {
    cursor: pointer
}

.container-integration .form-selection__search {
    margin: 0 calc(calc(.5em*2)*-1)
}

.container-integration .form-selection__search>* {
    margin: 0 calc(.5em*2)
}

.container-integration .form-selection__switch {
    margin: 0 calc(calc(.5em*1)*-1)
}

.container-integration .form-selection__switch>* {
    margin: 0 calc(.5em*1)
}

.container-integration .form-selection__language {
    display: block !important;
    margin: 0 calc(calc(.5em*3.5)*-1)
}

@media screen and (min-width:32rem) {
    .container-integration .form-selection__language {
        display: flex !important;
        flex-wrap: wrap
    }
}

.container-integration .form-selection__language .form-selection__checkbox {
    align-items: center;
    justify-content: flex-start;
    margin: calc(.5em*3) calc(.5em*3.5)
}

@media screen and (min-width:32rem) {
    .container-integration .form-selection__language .form-selection__checkbox {
        margin: 0 calc(.5em*3.5) 15px;
        width: 25%
    }
}

.container-integration .form-selection__language .form-selection__checkbox label {
    font-size: .875rem;
    color: #656c9b
}

.container-integration .form-selection__language .form-selection__checkbox img {
    width: 18px;
    height: auto;
    margin-right: calc(.5em*1)
}

.container-integration {
    position: relative;
    height: auto;
    margin-top: calc(.5em*3.5)
}

@media screen and (min-width:64rem) {
    .container-integration {
        margin-top: 0
    }
}

.container-integration__fieldset {
    margin-top: 0
}

.container-integration__fieldset__title {
    font-size: 1.375rem;
    color: #656c9b
}

.container-integration__fieldset__description {
    margin-top: .6rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    font-size: .875rem;
    font-style: italic;
    color: #8c91b4;
    border-bottom: 1px solid #d3d5e4
}

.container-integration .card {
    height: 290px;
    border: 2px solid transparent
}

.container-integration__back {
    position: relative;
    top: 0;
    left: 0
}

@media screen and (min-width:64rem) {
    .container-integration__back {
        position: absolute;
        top: 0;
        left: -15px
    }
}

@media screen and (min-width:80rem) {
    .container-integration__back {
        top: 12px;
        left: -45px
    }
}

.container-integration__main {
    position: relative;
    /*  margin-top: 1rem;*/
    padding-left: 0
}

.container-integration__main__form .switch {
    margin-right: 1rem
}

.container-integration__main__form .input__label {
    margin: 0
}

@media screen and (min-width:32rem) {
    .container-integration__main {
        padding-left: calc(.5em*2.5)
    }
}

.container-integration__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(.5em*3) !important
}

@media screen and (min-width:20rem) {
    .container-integration__header {
        flex-direction: column
    }
}

@media screen and (min-width:64rem) {
    .container-integration__header {
        flex-direction: row
    }
}

.container-integration__deploy {
    display: flex;
    align-items: center;
    margin: 0 2rem 0 0
}

.container-integration__deploy i {
    color: #6cd4a8;
    font-size: 1.5rem
}

.container-integration__deploy__alert {
    margin: 0 1rem 0 0
}

.container-integration__deploy__alert i {
    color: #e57373
}

.container-integration__deploy span {
    margin: 0 1rem 0 .5rem;
    color: #7d83aa;
    font-size: .875rem
}

.container-integration__logo {
    width: 30px;
    max-height: 30px;
    margin-right: calc(.5em*3);
    margin-left: calc(.5em*2)
}

.container-integration__logo img {
    max-width: 100%;
    height: auto
}

@media screen and (max-width:32rem) {
    .container-integration__rule-title {
        width: 50% !important
    }
}

.container-integration__actions p {
    color: #8c91b4;
    margin: 0 8px
}

.container-integration__actions i {
    font-size: 1.375rem;
    transition: all .3s ease
}

.container-integration__provider {
    font-size: 1.375rem;
    color: #020d38;
    text-transform: capitalize
}

.container-integration__provider .logo-provider {
    max-width: 26px;
    height: auto;
    margin: 0 calc(.5em*3) 0 calc(.5em*1.5)
}

.container-integration__nav a {
    display: inline-block;
    outline: none;
    margin: 10px;
    font-size: 1.5rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.container-integration__nav a:hover {
    transform: scale(1.04)
}

.container-integration__nav a i {
    font-size: 1.5rem
}

.container-integration .navigation-integration {
    padding: 20px 30px 20px 0;
    margin: -15px 0 -15px -12px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.container-integration .navigation-integration li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 35px;
    cursor: pointer
}

.container-integration .navigation-integration li.displayed .toggle i:before {
    transform: rotate(180deg)
}

.container-integration .navigation-integration p {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #656c9b;
    transition: all .3s ease;
    display: flex;
    align-items: center
}

.container-integration .navigation-integration p i {
    font-size: 1.25rem;
    color: #656c9b;
    margin-right: 8px;
    transition: all .3s ease
}

.container-integration .navigation-integration .toggle i:before {
    transform: rotate(0deg);
    transition: all .3s ease;
    font-size: 1.125rem;
    color: #5150c4
}

.container-integration .navigation-integration .navigation-integration-sub li {
    padding: 10px 10px 10px 20px;
    width: 100%;
    transition: all .3s ease
}

.container-integration .navigation-integration .navigation-integration-sub li.active {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.container-integration .navigation-integration .navigation-integration-sub li:hover {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.container-integration .navigation-integration .navigation-integration-sub li.active p {
    color: #656c9b
}

.container-integration .navigation-integration .navigation-integration-sub li:hover p {
    color: #656c9b
}

.container-integration .navigation-integration .navigation-integration-sub__item {
    margin: 5px 0
}

.container-integration .navigation-integration .navigation-integration__content__item.displayed+.navigation-integration-sub {
    display: block
}

.card {
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    padding-bottom: 64px;
    max-height: 380px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.card,
.card__header {
    position: relative
}

.card__delete {
    justify-content: center;
    align-items: center;
    left: 16px;
    top: -12px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    transition: all .3s ease
}

.card__delete,
.card__tags {
    display: flex;
    position: absolute
}

.card__tags {
    align-items: flex-start;
    justify-content: space-between;
    z-index: 1;
    padding: 16px;
    width: 100%;
    top: 0;
    left: 0
}

.card__tags--banner {
    width: auto;
    top: -10px !important;
    right: 0;
    left: auto
}

.card__tags--date {
    top: 35px !important
}

.card__banner {
    padding: 8px 16px;
    background: #fff;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.card__banner--between {
    justify-content: space-between
}

.card__banner--container__picture,
.card__banner--external__picture {
    width: 90px;
    height: 35px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.card__banner .avatar--min {
    width: 28px !important;
    max-height: 28px !important
}

.card__banner .icon--card {
    font-size: 1.25rem;
    margin: 0 10px 0 0;
    color: #535a8b
}

.card__banner+.card__tags {
    top: 40px
}

.card__pinned {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -4px
}

.card__pinned>* {
    margin: 0 4px
}

.card__image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.card__image div {
    position: relative;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: 50%;
    transform: scale(1);
    transition: transform .8s ease
}

.card__image--min div {
    height: 140px
}

@media screen and (min-width:64rem) {
    .card__image--cefora {
        background-position: 0 0 !important
    }
}

.card__play {
    position: absolute !important;
    top: 15px
}

.card__play,
.card__play i:before {
    display: flex;
    justify-content: center;
    align-items: center
}

.card__play i:before {
    font-size: 1.125rem;
    box-shadow: none;
    transform: rotate(-180deg) scale(1);
    transition: all .4s ease
}

.card__body {
    position: relative;
    z-index: 1;
    background: #fff;
    padding: 16px;
    top: 0;
    transition: top .4s ease
}

.card__time-lapse {
    position: absolute;
    right: 0;
    top: -14px
}

.card__progress {
    display: flex;
    justify-content: center;
    width: calc(100% + 32px);
    padding: 8px 16px 4px;
    margin: 8px -16px -12px;
    border-top: 1px solid #d3d5e4;
    text-align: center
}

.card__skills {
    display: flex;
    align-items: center;
    margin: 0 -4px
}

.card__title {
    font-size: 1rem;
    color: #020d38;
    margin: 8px 0 16px
}

.card__desc {
    font-size: .875rem;
    color: #7d83aa;
    word-break: break-word
}

.card__footer {
    bottom: 0;
    z-index: 2;
    background: #fff;
    padding: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

.card__footer,
.card__footer:before {
    width: 100%;
    position: absolute;
    left: 0
}

.card__footer:before {
    content: "";
    background: linear-gradient(hsla(0, 0%, 100%, 0), #fff);
    top: -25px;
    height: 25px
}

.card__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -8px
}

.card__notification p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .75rem;
    color: #7d83aa
}

.card__notification .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #feb88a;
    color: #fff
}

.card__widgets i {
    margin: 0 4px;
    color: #d3d5e4;
    font-size: 1.5rem;
    transition: all .3s ease
}

.card__widgets i:hover {
    color: #656c9b;
    transition: all .3s ease
}

.card__states {
    width: 100%;
    position: absolute;
    top: -30px;
    left: 0;
    padding: 8px 16px
}

.card__states--registered {
    background: rgba(249, 249, 249, .8)
}

.card__states--registered p {
    color: #535a8b;
    font-size: .875rem
}

.card__states--refused {
    background: rgba(229, 115, 115, .8)
}

.card__states--refused p {
    color: #fff;
    font-size: .875rem
}

.card__states--refused i {
    color: #fff;
    font-size: 1.125rem
}

.card__states--refused span {
    color: #fff;
    margin-top: .5rem;
    font-size: .75rem;
    display: flex;
    align-items: center
}

.card__states--refused span:hover {
    text-decoration: underline
}

.card__states--locked {
    background: rgba(211, 213, 228, .8)
}

.card__states--locked p {
    color: #7d83aa;
    font-size: .875rem
}

.card__states--locked i {
    color: #7d83aa;
    font-size: 1.125rem
}

.card__states--pending p {
    color: #fff;
    font-size: .875rem
}

.card__states--pending i {
    font-size: 1.125rem
}

.card--shadow {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.card--progress {
    min-height: 400px
}

.card--progress .card__footer:before {
    height: 16px;
    top: -16px
}

.card--hover-effect {
    transition: all .4s ease
}

.card--hover-effect:hover .card__image div {
    transform: scale(1.15)
}

.card--hover-effect:hover .card__image--cefora {
    transform: scale(1.15) translateY(-20px) !important
}

.card--hover-effect:hover .card__body {
    top: -33px
}

.card--hover-effect.hovering .tooltip:after,
.card--hover-effect.hovering .tooltip:before {
    transition-delay: .4s
}

.card--hover-effect.hovering:hover .card__image {
    transform: scale(1)
}

.card--hover-effect.hovering:hover .card__body {
    top: 0
}

.card--community {
    overflow: visible !important;
    transition: all .3s ease
}

.card--community:hover {
    transform: scale(1.05)
}

.card--community .card__image {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    transition: all .4s ease
}

.card--community .card__body {
    min-height: 150px;
    transition: all .4s ease
}

.card--community .card__tags {
    justify-content: flex-end
}

.card--community .card__widgets {
    margin: 0 4px
}

.card--community .card__widgets a {
    font-size: .875rem;
    font-weight: 900;
    color: #ffa2a2;
    margin: 0 4px
}

.card--community .card__widgets i {
    font-size: 1rem;
    color: #7d83aa
}

.card--community .card__footer {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px
}

.card--community .card__footer:before {
    display: none
}

.card__time {
    vertical-align: middle;
    background: #535a8b;
    color: #fff;
    position: absolute;
    top: -12px;
    right: 0;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    padding: 2px 12px;
    z-index: 1
}

.card--min {
    position: relative;
    transition: all .25s ease-in-out;
    padding-bottom: 20px;
    border-radius: 6px
}

.card--min,
.card--min:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.card--min .card__body {
    max-height: 60px
}

.card--min .card__image {
    height: 160px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.card--min .card__image div {
    height: 160px
}

.card--min .card__time {
    background-color: #020d38
}

.card--min .card__radio {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #8290fd
}

.card--min .card__radio:after {
    content: "\e935";
    font-family: myskillcamp;
    font-size: .875rem;
    color: #fff
}

.card--min.card--checked {
    border: 2px solid #8290fd !important
}

.card--min.card--checked .card__radio {
    display: flex
}

.card--min.card--excluded {
    opacity: .5
}

@media (-ms-high-contrast:none) {

    .card__banner,
    ::-ms-backdrop {
        height: auto !important
    }
}

.content-knowledge-add-content {
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    background: #fff;
    margin-bottom: calc(.5em*3.5)
}

.content-knowledge-add-content__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: calc(.5em*2.5);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.content-knowledge-add-content__sub-header {
    margin: calc(.5em*2) 0;
    color: #7d83aa;
    font-size: .875rem
}

.content-knowledge-add-content__title {
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #7d83aa
}

.content-knowledge-add-content .container-add-content {
    display: flex;
    flex-direction: column
}

.content-knowledge-add-content .container-add-content__filters {
    display: flex;
    margin: calc(.5em*3) 0
}

.content-knowledge-add-content .container-add-content__filters .filters-actions {
    width: 75%;
    display: flex
}

.content-knowledge-add-content .container-add-content__filters .filters-actions .switcher {
    margin: 0 .5rem 0 1rem;
    display: flex;
    align-items: center
}

.content-knowledge-add-content .container-add-content__filters .filters-actions .switcher .switch {
    margin: 0 .5rem 0 0
}

.content-knowledge-add-content .container-add-content__filters .filters-actions .switcher__label {
    color: #656c9b
}

.content-knowledge-add-content .container-add-content__filters .filters-actions msc-button {
    margin-left: calc(.5em*2.5)
}

.content-knowledge-add-content .container-add-content__filters .separator {
    width: 35%;
    display: flex;
    justify-content: flex-end
}

.content-knowledge-add-content .container-add-content__filters .separator__actions .selector {
    cursor: pointer;
    min-width: 180px;
    height: 30px;
    border: none;
    border-radius: 4px;
    position: relative;
    background: #fff;
    margin: 0;
    padding: 12px;
    color: #8c91b4;
    font-size: .875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.content-knowledge-add-content .container-add-content__filters .separator__actions .selector.active .selector__icon i:before {
    transform: rotate(-180deg);
    transition: all .3s ease
}

.content-knowledge-add-content .container-add-content__filters .separator__actions .selector__icon {
    margin: 0 0 0 .5rem
}

.content-knowledge-add-content .container-add-content__filters .separator__actions .selector__icon i {
    font-size: 1.125rem;
    transition: all .3s ease
}

.content-knowledge-add-content .container-add-content__filters .separator__actions .selector__icon i:before {
    transition: all .3s ease
}

.content-knowledge-add-content .container-add-content__filters .separator__actions .selector:hover .selector__icon i {
    color: #535a8b;
    transition: all .3s ease
}

.content-knowledge-add-content .container-add-content__filters .separator__actions .selector .dropdown {
    top: 31px;
    left: 0;
    width: 100%;
    min-width: 180px
}

.content-knowledge-add-content .container-add-content__cards {
    margin-top: calc(.5em*3) calc(calc(.5em*2)*-1) 0 calc(calc(.5em*2)*-1)
}

.content-knowledge-add-content .container-add-content__cards li {
    width: calc(25% - calc(.5em*2));
    margin: calc(.5em*1)
}

.content-knowledge-add-content .container-add-content__cards li .card {
    padding: 0;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.content-knowledge-add-content .container-add-content__cards li .card--add-content {
    min-height: 310px
}

.content-knowledge-add-content .container-add-content__cards li .card .card__header {
    height: 130px
}

.content-knowledge-add-content .container-add-content__cards li .card .card__header .card__image {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px
}

.content-knowledge-add-content .container-add-content__cards li .card .card__last-selection {
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;
    height: 25px;
    background-color: #656c9b;
    color: #fff;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center
}

.content-knowledge-add-content .container-add-content__cards li .card .card__radio {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: -10px;
    right: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #8290fd;
    box-sizing: border-box !important
}

.content-knowledge-add-content .container-add-content__cards li .card .card__radio:after {
    content: "\e935";
    font-family: myskillcamp;
    font-size: .875rem;
    color: #fff
}

.content-knowledge-add-content .container-add-content__cards li .card.card--checked {
    border: 2px solid #8290fd !important
}

.content-knowledge-add-content .container-add-content__cards li .card.card--checked .card__radio {
    display: flex
}

.content-knowledge-add-content .container-add-content__cards li .card__picture {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 1;
    display: block;
    width: 100px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.content-knowledge-add-content .container-add-content__cards li .card__tags {
    justify-content: flex-end
}

.content-knowledge-add-content .container-add-content__cards li .card__widgets a {
    font-size: .875rem;
    color: #7d83aa
}

.content-knowledge-add-content .container-add-content__cards li .card__widgets a i {
    font-size: 1rem
}

.content-knowledge-add-content .container-add-content__cards li .card .card_time_text {
    padding-left: 5px
}

.content-knowledge-add-content .container-add-content .dynamic-table__container {
    padding: .2rem 1rem
}

.content-knowledge-add-content .container-add-content .dynamic-table__container.active .dynamic-table__body .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.content-knowledge-add-content .container-add-content .dynamic-table__container.active .dynamic-table__content {
    display: block
}

.content-knowledge-add-content .container-add-content .dynamic-table__body {
    display: block !important
}

.content-knowledge-add-content .container-add-content .dynamic-table__body .dynamic-table__row {
    margin: calc(.5em*1) 0
}

.content-knowledge-add-content .container-add-content .dynamic-table__body .cell-with-icon__container {
    display: flex;
    align-items: center;
    margin-right: .5rem
}

.content-knowledge-add-content .container-add-content .dynamic-table__body .cell-with-icon__container i {
    font-size: 1rem;
    margin: 0 .2rem 0 0
}

.content-knowledge-add-content .container-add-content .dynamic-table__body .cell-with-icon__container i:before {
    transition: all .3s ease
}

.content-knowledge-add-content .container-add-content .dynamic-table__body .cell-with-icon .icon-trash {
    font-size: 1rem !important;
    color: #8c91b4 !important
}

.content-knowledge-add-content .container-add-content .dynamic-table__body .cell-with-icon .icon-trash:hover:before {
    color: #535a8b
}

.content-knowledge-add-content .container-add-content .dynamic-table__body .cell-with-icon .color--orange {
    margin-right: .2rem
}

.content-knowledge-add-content .container-add-content .dynamic-table__content {
    display: none;
    width: 97%;
    margin: auto
}

.content-knowledge-add-content .container-add-content .dynamic-table-toggle {
    margin-top: 1rem
}

.content-knowledge-add-content .actions {
    padding: 1rem;
    background: #f8f9ff;
    color: #7d83aa
}

.content-knowledge-add-content .actions i {
    margin-right: .5rem;
    font-size: 1.375rem
}

.content-knowledge-add-content .actions__buttons {
    height: 35px;
    display: flex
}

.content-knowledge-add-content .actions__buttons msc-button {
    display: flex;
    align-items: center
}

.content-knowledge-add-content .actions__buttons msc-button:last-child {
    margin-left: calc(.5em*3)
}

.content-knowledge-content {
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    background: #fff
}

.content-knowledge-content__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: calc(.5em*2.5);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.content-knowledge-content__filters {
    margin: calc(.5em*3) 0
}

.content-knowledge-content__filters msc-button {
    margin-left: calc(.5em*2.5)
}

.content-knowledge-content__list .dynamic-table__row {
    border: 1px solid #d3d5e4
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-title-content {
    display: flex;
    width: 400px;
    max-width: 400px;
    font-size: .875rem;
    color: #656c9b;
    padding: 0
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-title-content i {
    font-size: 1.625rem;
    margin-right: calc(.5em*2)
}

@media screen and (min-width:20rem) {
    .content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-title-content {
        width: 150px;
        display: block
    }
}

@media screen and (min-width:32rem) {
    .content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-title-content {
        width: 200px
    }
}

@media screen and (min-width:48rem) {
    .content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-title-content {
        width: 250px
    }
}

@media screen and (min-width:64rem) {
    .content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-title-content {
        width: 400px;
        display: flex
    }
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell .cell-title-content__desc {
    margin: 0 0 0 .5rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow: visible
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell .cell-title-content__picture {
    display: block;
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-skills {
    flex-wrap: wrap;
    width: 100%
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-skills .skill:first-child {
    border-color: #414776
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-skills .skill:first-child .skill__text {
    color: #414776
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-actions {
    position: static
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-actions a {
    margin-right: calc(.5em*2.5)
}

.content-knowledge-content__list .dynamic-table__row .dynamic-table__cell.cell-actions a i {
    font-size: 1.5rem
}

.content-knowledge-content__sub-header {
    margin: calc(.5em*2) 0;
    color: #7d83aa;
    font-size: .875rem
}

.content-knowledge-content__title {
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #7d83aa
}

.content-knowledge-content__description {
    margin: calc(.5em*3) 0;
    color: #8c91b4;
    font-size: .875rem;
    line-height: 1.3
}

@media screen and (max-width:32rem) {
    .container-knowledge .content-knowledge .dynamic-table .cell-title {
        width: 100%
    }
}

.container-knowledge .content-knowledge .dynamic-table .thematic-mark {
    position: absolute;
    top: -16px;
    left: -13px;
    height: 30px;
    width: 25px;
    overflow: hidden;
    transform: rotate(45deg)
}

.container-knowledge .content-knowledge .dynamic-table .thematic-mark:before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    left: 20px;
    background: #ed8371;
    transform: rotate(45deg);
    border-radius: 5px
}

.container-knowledge .content-knowledge .dynamic-table .cell-thematic {
    display: flex;
    align-items: center
}

.container-knowledge .content-knowledge .dynamic-table .cell-thematic i {
    font-size: 1.25rem;
    margin: 0 .5rem 0 0;
    color: #ed8371
}

.container-knowledge .content-knowledge .dynamic-table .cell-resources {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .875rem
}

.container-knowledge .content-knowledge .dynamic-table .cell-resources i {
    font-size: 1.25rem;
    margin: 0 .125rem 0 0
}

.container-knowledge .content-knowledge .dynamic-table .cell-resources .tooltip {
    margin: 0 .5rem 0 0;
    display: flex;
    align-items: center
}

.container-knowledge .content-knowledge .dynamic-table .cell-authorize i {
    color: #feb88a
}

.container-knowledge .content-knowledge .dynamic-table .cell-authorize.disable {
    color: #d3d5e4
}

.container-knowledge .content-knowledge .dynamic-table .cell-authorize.disable i {
    color: #d3d5e4
}

.container-knowledge .content-knowledge .dynamic-table .cell-authorize.disable i:before {
    content: "\e9d6";
    font-family: myskillcamp
}

.content-knowledge-summary {
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    background: #fff;
    margin: 0 0.75rem !important;
    padding: 20px 30px;
}

.content-knowledge-summary__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: calc(.5em*2.5);
    border-bottom: 2px solid rgba(211, 213, 228, .2)
}

.content-knowledge-summary__title {
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #656C9B;
    font-weight: 300;
}

.content-knowledge-summary__description {
    margin: calc(.5em*3) 0;
    color: #8c91b4;
    font-size: .875rem;
    line-height: 1.3
}

.content-knowledge-summary__content {
    overflow: auto;
    margin-top: calc(.5em*3)
}

.content-knowledge-summary__content aside {
    padding-left: calc(.5em*3)
}

.content-knowledge-summary__container {
    padding: 20px;
    margin: 10px 0;
    border: 1px solid #d3d5e4;
    border-radius: 4px
}

.content-knowledge-summary .box {
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    margin-bottom: 20px
}

.content-knowledge-summary .box--info {
    flex: 1;
    justify-content: space-between;
    align-content: center;
    margin: calc(.5em*1)
}

.content-knowledge-summary .box--info .box__content {
    margin-top: 0
}

@media screen and (max-width:32rem) {
    .content-knowledge-summary .box--info .box__content {
        flex-direction: column
    }
}

.content-knowledge-summary .box--info .box__title {
    display: flex;
    align-content: center
}

.content-knowledge-summary .box--info .box__title span {
    color: #656c9b
}

.content-knowledge-summary .box--info .box__title i {
    font-size: 1.875rem;
    margin-right: 10px
}

.content-knowledge-summary .box__title {
    font-size: 1rem;
    color: #656c9b
}

.content-knowledge-summary .box__title span {
    display: inline-flex;
    align-items: center
}

.content-knowledge-summary .box__avatar {
    margin-right: 16px
}

.content-knowledge-summary .box__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px
}

.content-knowledge-summary .box__content p span {
    display: block
}

.content-knowledge-summary .box__text {
    font-size: 1rem;
    color: #656c9b
}

.content-knowledge-summary .content-knowledge__input {
    margin-bottom: calc(.5em*3)
}

.content-knowledge-summary .content-knowledge__input .content-knowledge__label {
    color: #656c9b;
    margin-bottom: calc(.5em*1.5)
}

.content-knowledge-summary .content-knowledge__switch--flex {
    display: flex;
    align-items: center
}

.content-knowledge-summary .content-knowledge__switch--flex p {
    margin: 0 0 0 .75rem;
    color: #b2b5cd;
    font-size: .875rem
}

.container-knowledge {
    position: relative;
    height: auto;
    margin-top: calc(.5em*3.5)
}

@media screen and (min-width:64rem) {
    .container-knowledge {
        margin-top: 0
    }
}

.container-knowledge__fieldset {
    margin-top: 0
}

.container-knowledge__fieldset__title {
    font-size: 1.375rem;
    color: #656c9b
}

.container-knowledge__fieldset__description {
    margin-top: .6rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    font-size: .875rem;
    font-style: italic;
    color: #8c91b4;
    border-bottom: 1px solid #d3d5e4
}

.container-knowledge .card {
    height: 290px;
    border: 2px solid transparent
}

.container-knowledge__back {
    position: relative;
    top: 0;
    left: 0
}

@media screen and (min-width:64rem) {
    .container-knowledge__back {
        position: absolute;
        top: 0;
        left: -15px
    }
}

@media screen and (min-width:80rem) {
    .container-knowledge__back {
        top: 12px;
        left: -45px
    }
}

.container-knowledge__main {
    position: relative;
    margin-top: 1rem;
    padding-left: 0
}

.container-knowledge__main__form .switch {
    margin-right: 1rem
}

.container-knowledge__main__form .input__label {
    margin: 0
}

@media screen and (min-width:32rem) {
    .container-knowledge__main {
        padding-left: calc(.5em*2.5)
    }
}

.container-knowledge__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(.5em*3) !important
}

@media screen and (min-width:20rem) {
    .container-knowledge__header {
        flex-direction: column
    }
}

@media screen and (min-width:64rem) {
    .container-knowledge__header {
        flex-direction: row
    }
}

.container-knowledge__deploy {
    display: flex;
    align-items: center;
    margin: 0 2rem 0 0
}

.container-knowledge__deploy i {
    color: #6cd4a8;
    font-size: 1.5rem
}

.container-knowledge__deploy__alert {
    margin: 0 1rem 0 0
}

.container-knowledge__deploy__alert i {
    color: #e57373
}

.container-knowledge__deploy span {
    margin: 0 1rem 0 .5rem;
    color: #7d83aa;
    font-size: .875rem
}

.container-knowledge__logo {
    width: 30px;
    max-height: 30px;
    margin-right: calc(.5em*3);
    margin-left: calc(.5em*2)
}

.container-knowledge__logo img {
    max-width: 100%;
    height: auto
}

@media screen and (max-width:32rem) {
    .container-knowledge__rule-title {
        width: 50% !important
    }
}

.container-knowledge__actions p {
    color: #8c91b4;
    margin: 0 8px
}

.container-knowledge__actions i {
    font-size: 1.375rem;
    transition: all .3s ease
}

.container-knowledge__provider {
    font-size: 1.375rem;
    color: #020d38;
    text-transform: capitalize
}

.container-knowledge__provider .logo-provider {
    max-width: 26px;
    height: auto;
    margin: 0 calc(.5em*3) 0 calc(.5em*1.5)
}

.container-knowledge__nav a {
    display: inline-block;
    outline: none;
    margin: 10px;
    font-size: 1.5rem;
    color: #feb88a;
    background-color: rgba(254, 184, 138, .1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.container-knowledge__nav a:hover {
    transform: scale(1.04);
    color: #ed8371
}

.container-knowledge__nav a i {
    font-size: 1.5rem
}

.container-knowledge .navigation-knowledge {
    padding: 20px 30px 20px 0;
    margin: -15px 0 -15px -12px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.container-knowledge .navigation-knowledge li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 35px;
    cursor: pointer
}

.container-knowledge .navigation-knowledge li.displayed .toggle i:before {
    transform: rotate(180deg)
}

.container-knowledge .navigation-knowledge p {
    font-size: .875rem;
    line-height: 1.125rem;
    color: #656c9b;
    transition: all .3s ease;
    display: flex;
    align-items: center
}

.container-knowledge .navigation-knowledge p i {
    font-size: 1.25rem;
    color: #656c9b;
    margin-right: 8px;
    transition: all .3s ease
}

.container-knowledge .navigation-knowledge .toggle i:before {
    transform: rotate(0deg);
    transition: all .3s ease;
    font-size: 1.125rem;
    color: #5150c4
}

.container-knowledge .navigation-knowledge .navigation-knowledge-sub li {
    padding: 10px 10px 10px 20px;
    width: 100%;
    transition: all .3s ease
}

.container-knowledge .navigation-knowledge .navigation-knowledge-sub li.active {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.container-knowledge .navigation-knowledge .navigation-knowledge-sub li:hover {
    background-color: #f8f9ff;
    border-radius: 28px;
    border-left: 0 solid transparent
}

.container-knowledge .navigation-knowledge .navigation-knowledge-sub li.active p {
    color: #656c9b
}

.container-knowledge .navigation-knowledge .navigation-knowledge-sub li:hover p {
    color: #656c9b
}

.container-knowledge .navigation-knowledge .navigation-knowledge-sub__item {
    margin: 5px 0
}

.container-knowledge .navigation-knowledge .navigation-knowledge__content__item.displayed+.navigation-knowledge-sub {
    display: block
}

.content-configuration {
    position: relative;
    background: #fff;
    border-radius: 4px;
    padding: 50px 40px
}

.content-configuration__header {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    margin-bottom: calc(.5em*2);
    border-bottom: 1px solid #d3d5e4
}

.content-configuration__header h3 {
    font-size: 1.375rem;
    color: #7d83aa;
    font-weight: 700
}

.content-configuration__subheader {
    font-weight: 300;
    font-size: 1rem;
    color: #7d83aa;
    margin-bottom: 40px
}

.content-configuration__content {
    display: flex;
    flex-direction: column
}

.content-configuration__content h3 {
    font-size: 1.25rem;
    color: #7d83aa;
    margin-bottom: 19px;
    font-weight: 700
}

.content-configuration__actions msc-button {
    margin-right: 10px
}

.content-configuration__dataset {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px
}

.content-configuration__dataset .skill {
    background-color: #fff;
    border: 1px solid #d3d5e4;
    padding: 4px 8px
}

.content-configuration__dataset .group__radio .content-configuration__dataset {
    margin-bottom: 0
}

.content-configuration__dataset .group__radio .content-configuration__dataset label {
    font-size: .875rem
}

.content-configuration__dataset .group__radio .radio .tag {
    margin-left: calc(.5em*1.5)
}

.content-configuration__dataset .multi-select--error {
    border: 2px solid #f44436
}

.content-configuration__configurable-tag {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.content-configuration__configurable-tag>div {
    margin-bottom: 10px
}

.content-configuration__configurable-tag .content-configuration__item__checkbox {
    margin-left: 30px
}

.content-configuration__input {
    margin: 0 calc(.5em*3) 8px 0
}

.content-configuration__input .helper {
    margin: .5rem 0 0
}

.content-configuration__input .select__tag {
    display: flex;
    overflow: hidden
}

.content-configuration__label {
    display: block;
    font-size: 1rem;
    color: #656c9b;
    text-align: left;
    margin-bottom: 10px
}

.content-configuration__item__checkbox {
    margin: 12px 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start
}

.content-configuration__item__checkbox>div {
    width: 100%
}

.content-configuration__item__checkbox .checkbox__container {
    padding: 10px 20px;
}

.content-configuration__item__checkbox .checkbox__container--border {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #b2b5cd
}

.content-configuration__item__checkbox .checkbox__container .content-configuration__dataset {
    margin-bottom: 0
}

.content-configuration__item__checkbox .checkbox__label {
    color: #7d83aa;
    font-size: 1rem;
    margin: 0 !important
}

.content-configuration__item__checkbox .checkbox__label i {
    color: #656c9b
}

.content-configuration__tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d1d6ff;
    border-radius: 2px;
    padding: 0 11px;
    color: #656c9b;
    margin: 5px 10px 5px 0
}

.content-configuration__tag span {
    cursor: pointer;
    margin-left: 5px
}

.content-configuration__tag p {
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
    color: #7d83aa;
    padding: 5px 0;
}

.content-configuration .form-filter {
    margin-left: 70px
}

.content-configuration .form-filter-step {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.content-configuration .form-filter-step:before {
    content: "";
    position: absolute;
    z-index: 0;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: calc(100% - 50px);
    background-color: #d3d5e4
}

@media screen and (min-width:32rem) {
    .content-configuration .form-filter-step:before {
        width: calc(100% - 108px);
        right: 54px
    }
}

.content-configuration .form-filter-step__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin: 0 25px;
    z-index: 1;
    border-radius: 50%;
    border: 2px solid #d3d5e4;
    background-color: #fff;
    position: relative
}

@media screen and (min-width:32rem) {
    .content-configuration .form-filter-step__item {
        margin: 0 54px
    }
}

.content-configuration .form-filter-step__item span.label {
    position: absolute;
    bottom: calc(50% - 30px);
    color: #7d83aa;
    font-size: .875rem;
    white-space: nowrap
}

.content-configuration .form-filter-step__item--current {
    border: 6px solid #fff;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    color: #7d83aa
}

.content-configuration .form-filter-step__item--success {
    border: none
}

.content-configuration .form-filter-step__item--success span.anchor:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: calc(50% - 1px);
    left: 20px;
    width: 50px;
    height: 2px
}

@media screen and (min-width:32rem) {
    .content-configuration .form-filter-step__item--success span.anchor:before {
        width: 108px
    }
}

.content-configuration .form-filter-step__item--success span.anchor:after {
    font-family: myskillcamp;
    content: "\e935";
    font-size: .875rem;
    color: #fff
}

.content-configuration__resume {
    margin-top: calc(.5em*3)
}

@media screen and (min-width:32rem) {
    .content-configuration__resume {
        margin-top: 0
    }
}

.content-configuration .form-filter-resume {
    padding: calc(.5em*1.5);
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    background-color: rgba(211, 213, 228, .25);
    margin: 0 0 26px
}

.content-configuration .form-filter-resume__list {
    padding-right: calc(.5em*4)
}

.content-configuration .form-filter-resume__list>li {
    color: #656c9b;
    font-size: 1rem
}

.content-configuration .form-filter-resume__list>li i {
    font-size: 1.25rem;
    margin-right: calc(.5em*0.5)
}

.content-configuration .form-filter-resume__list li {
    margin: calc(.5em*2) 0 calc(.5em*2.5) 0
}

.content-configuration .form-filter-resume__list .form-filter-resume__subitem {
    color: #b2b5cd;
    margin: calc(.5em*2) 0 calc(.5em*2) calc(.5em*4);
    font-size: .75rem
}

.content-configuration .form-filter-resume__list .form-filter-resume__subitem ul li {
    color: #b2b5cd;
    margin: calc(.5em*2) 0 calc(.5em*2) calc(.5em*4);
    font-size: .75rem
}

.content-configuration .form-filter-resume__list .form-filter-resume__subitem--flag {
    display: flex;
    align-items: center
}

.content-configuration .form-filter-resume__list .form-filter-resume__subitem--flag img {
    max-width: 18px;
    height: auto;
    margin-right: calc(.5em*1)
}

.content-configuration .form-filter-resume .form-filter-resume__sublist ul {
    display: none
}

.content-configuration .form-filter-resume .form-filter-resume__sublist a {
    width: 20px;
    height: 16px;
    transform: rotate(0deg);
    transition: transform .5s ease
}

.content-configuration .form-filter-resume .form-filter-resume__sublist a i {
    margin-right: 0
}

.content-configuration .form-filter-resume .form-filter-resume__sublist.active ul {
    display: block
}

.content-configuration .form-filter-resume .form-filter-resume__sublist.active a {
    transform: rotate(180deg)
}

.content-invitation {
    position: relative;
    background: #fff;
    border-radius: 4px;
    padding: 50px 40px
}

.content-invitation .content-configuration {
    padding: 0
}

.content-invitation__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid #d3d5e4
}

.content-invitation__header h3 {
    font-size: 1.375rem;
    /*color: #7d83aa;*/
    margin-right: 20px
}

.content-invitation__subheader {
    font-weight: 300;
    font-size: 1rem;
    color: #7d83aa;
    margin-bottom: 40px
}

.content-invitation__dataset {
    display: flex;
    flex-direction: column;
    margin-bottom: 26px
}

.content-invitation__dataset .group__radio .content-invitation__dataset {
    margin-bottom: 0
}

.content-invitation__dataset .group__radio .content-invitation__dataset label {
    font-size: .875rem
}

.content-invitation__dataset .group__radio .radio .tag {
    margin-left: calc(.5em*1.5)
}

.content-invitation__dataset p {
    font-size: .875rem;
    font-weight: 300;
    color: #7d83aa;
    padding: 5px 0;
    margin-top: 10px
}

.content-invitation__dataset .multi-select--error {
    border: 2px solid #f44436
}

.content-invitation__group {
    display: flex
}

.content-invitation__group>div {
    margin-right: 8px
}

.content-invitation__input {
    margin: 0 0 8px
}

.content-invitation__input .helper {
    margin: .5rem 0 0
}

.content-invitation__label {
    display: block;
    font-size: 1rem;
    color: #656c9b;
    text-align: left;
    margin-bottom: 10px
}

.content-invitation__item__checkbox {
    margin: 12px 0;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.content-invitation__item__checkbox label {
    color: #7d83aa;
    font-size: 1rem;
    margin: 0 !important
}

.content-invitation__item__checkbox label i {
    color: #656c9b
}

.content-invitation__switch p {
    font-size: 1rem;
    padding-left: 5px;
    margin: 0
}

.content-invitation__tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d1d6ff;
    border-radius: 2px;
    padding: 0 11px;
    margin: 5px 10px 5px 0
}

.content-invitation__tag--file {
    padding: 11px 19px
}

.content-invitation__tag--error {
    background-color: #f09a9a
}

.content-invitation__tag--error i {
    color: #fff !important
}

.content-invitation__tag--error p {
    color: #fff !important
}

.content-invitation__tag span {
    cursor: pointer;
    margin-left: 5px
}

.content-invitation__tag p {
    margin: 0;
    font-size: 1rem;
    color: #656c9b
}

.content-invitation__tag i {
    color: #656c9b
}

.container-users .content-users .dynamic-table__body {
    height: calc(100vh - 380px);
    overflow: auto
}

.container-users .content-users .dynamic-table__subcontent {
    align-items: baseline
}

.container-users .content-users .dynamic-table .cell-user {
    display: flex;
    align-items: center
}

@media screen and (max-width:32rem) {
    .container-users .content-users .dynamic-table .cell-user {
        width: 100%
    }
}

.container-users .content-users .dynamic-table .cell-user i {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
    font-size: .75rem;
    width: 14px;
    height: 14px
}

.container-users .content-users .dynamic-table .cell-user__profile {
    margin: 0 0 0 1rem
}

.container-users .content-users .dynamic-table .cell-user__profile--name {
    font-size: .875rem;
    color: #282d58
}

.container-users .content-users .dynamic-table .cell-status {
    display: flex;
    flex-direction: column
}

.container-users .content-users .dynamic-table .cell-status__date {
    margin: .5rem 0 0
}

@media screen and (max-width:32rem) {
    .container-users .content-users .dynamic-table .cell-dropdown-users {
        margin: 0 40px 0 0
    }
}

@media screen and (min-width:20rem) {
    .container-users .content-users .dynamic-table .cell-dropdown-users {
        display: flex
    }
}

.container-users .content-users .dynamic-table .cell-dropdown-users .dropdown-actions__content {
    left: -120px
}

@media screen and (max-width:48rem) {
    .container-users .content-users .dynamic-table .cell-dropdown-users .dropdown-actions__content {
        left: -150px
    }
}

.container-users .content-users .dynamic-table .cell-actions__down i {
    font-size: 1.25rem
}

.container-users .content-users .dynamic-table .cell-actions__down i:before {
    transition: all .3s ease
}

.container-users .content-users .dynamic-table .cell-actions__down i.active {
    color: #535a8b
}

.container-users .content-users .dynamic-table .cell-actions__down i.active:before {
    transform: rotate(180deg)
}

.container-users .content-users .dynamic-table .cell-profile-data__block {
    margin: 1rem 0 0
}

.container-users .content-users .dynamic-table .cell-profile-data__block:first-child {
    margin: 0
}

.container-users .content-users .dynamic-table .cell-profile-data__title {
    margin: 0 0 .5rem;
    font-size: .875rem;
    color: #282d58
}

.container-users .content-users .dynamic-table .cell-profile-data__icon {
    display: flex;
    align-items: center
}

.container-users .content-users__menu .dropdown-filter-managers {
    margin-left: 1.5rem;
    min-width: 240px
}

@media screen and (min-width:20rem) {
    .container-users .content-users__menu .dropdown-filter-managers {
        display: none
    }
}

@media screen and (min-width:48rem) {
    .container-users .content-users__menu .dropdown-filter-managers {
        display: block
    }
}

.container-users .content-users__menu .dropdown-filter-managers .multi-select__content {
    padding-left: 10px
}

.container-users .content-users__menu .dropdown-filter-managers .multi-select__content .icon {
    color: #4443af;
    font-size: 1.45rem;
    margin-right: 5px
}

.container-users .content-users__menu .dropdown-filter-managers .multi-select__options p {
    font-size: 100%
}

.container-users .content-users__menu .dropdown-filter-managers .data-list__item p {
    font-size: 12px
}

.container-users .content-users__menu .dropdown-invitation-users>msc-button i {
    margin-right: calc(.5em*1)
}

.container-users .content-users__menu .dropdown-actions__content {
    top: 25px;
    min-width: 280px
}

.container-users .content-users__menu .dropdown-actions__item {
    display: flex;
    align-items: flex-start;
    padding: calc(.5em*1.5);
    cursor: pointer
}

.container-users .content-users__menu .dropdown-actions__item .icon {
    font-size: 1.5rem;
    margin-right: calc(.5em*1.5)
}

.container-users .content-users__menu .dropdown-actions__item p {
    font-size: .875rem;
    color: #7d83aa
}

.container-users .content-users__menu .dropdown-actions__item .description {
    margin-top: calc(.5em*1.5);
    font-size: .625rem;
    color: #7d83aa
}

.container-users .content-users__menu .dropdown-actions__item .description .italic {
    font-style: italic;
    color: #7d83aa
}

.container-users__footer {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 25px;
    position: absolute;
    left: 285px;
    bottom: 0;
    width: calc(100% - 285px);
    color: #b2b5cd
}

@media screen and (min-width:20rem) {
    .container-users__footer {
        left: 0;
        width: 100%
    }
}

@media screen and (min-width:80rem) {
    .container-users__footer {
        left: 285px;
        width: calc(100% - 285px)
    }
}

.container-users__footer>div {
    flex-grow: 1
}

.container-users__footer .container-users__footer__info {
    display: flex;
    align-items: center;
    text-transform: uppercase
}

.container-users__footer .container-users__footer__info p {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 700
}

.container-users__footer .container-users__footer__info span {
    color: #ffa2a2;
    font-size: 18px
}

.container-users__footer .container-users__footer__icons {
    cursor: pointer;
    display: flex
}

.container-users__footer .container-users__footer__icons>div {
    margin: 0 10px
}

.container-users__footer .icon {
    font-size: 30px
}

.container-user {
    position: relative;
    height: auto;
    margin-top: calc(.5em*3.5)
}

@media screen and (min-width:32rem) {
    .container-user {
        margin-top: 0
    }
}

.container-user__header {
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.container-user__header__title {
    display: flex;
    justify-content: flex-start;
    width: 45%
}

.container-user__name {
    font-size: 1.375rem;
    color: #020d38
}

.container-user__name .logo-provider {
    max-width: 26px;
    height: auto;
    margin: 0 calc(.5em*3) 0 calc(.5em*1.5)
}

.container-user__clickable,
.container-user__counts {
    display: flex;
    align-items: center
}

.container-user__clickable i {
    cursor: pointer
}

.container-user__clickable i:hover {
    color: #656c9b
}

.container-user__nav {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: calc(.5em*2.5) calc(.5em*2) 0 calc(.5em*2) !important
}

@media screen and (min-width:32rem) {
    .container-user__nav {
        margin: 0 calc(.5em*2) !important
    }
}

.container-user__nav a {
    display: inline-block;
    outline: none;
    margin: 10px;
    font-size: 1.5rem;
    color: #feb88a;
    background-color: rgba(254, 184, 138, .1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transform: scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.container-user__nav a:hover {
    transform: scale(1.04);
    color: #ed8371
}

.container-user__nav a i {
    font-size: 1.5rem
}

.container-user__main {
    margin-top: calc(.5em*3.5)
}

.container-user__back {
    position: relative;
    top: 0;
    left: 0
}

@media screen and (min-width:64rem) {
    .container-user__back {
        position: absolute;
        top: 0;
        left: -15px
    }
}

@media screen and (min-width:80rem) {
    .container-user__back {
        top: 12px;
        left: -45px
    }
}

.container-user__row {
    position: relative;
    padding-left: 0
}

@media screen and (min-width:64rem) {
    .container-user__row {
        padding-left: 20px
    }
}

.container-user__aside {
    margin-bottom: calc(.5em*3)
}

@media screen and (min-width:64rem) {
    .container-user__aside {
        margin-bottom: 0
    }
}

.content-access__create__header {
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media screen and (max-width:32rem) {
    .content-access__create__header {
        flex-direction: column
    }

    .content-access__create__header msc-button {
        margin-top: 1rem;
        width: 11rem
    }

    .content-access__create__header h3 {
        margin-top: 1rem
    }
}

.content-access__create__enable {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.content-access__create__enable p {
    margin-right: calc(.5em*2.5);
    font-size: .875rem;
    color: #535a8b
}

.content-access__create__data {
    margin: 30px 0 0;
    display: flex
}

@media screen and (max-width:32rem) {
    .content-access__create__data {
        flex-direction: column
    }
}

.content-access__create__data .data__logo {
    width: 250px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff
}

.content-access__create__data .data__logo .logo__society {
    width: 200px;
    height: 100px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain
}

@media screen and (max-width:32rem) {
    .content-access__create__data .data__logo {
        margin: auto
    }
}

.content-access__create__data .data__form {
    margin: 0 0 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center
}

@media screen and (max-width:32rem) {
    .content-access__create__data .data__form {
        margin-top: 1rem
    }
}

.content-access__learn__header {
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media screen and (max-width:32rem) {
    .content-access__learn__header {
        flex-direction: column
    }

    .content-access__learn__header msc-button {
        margin: 0 0 1rem;
        width: 11rem
    }

    .content-access__learn__header h3 {
        margin-top: 1rem
    }
}

.content-access__learn__header h3 {
    display: flex;
    margin-bottom: 1rem
}

.content-access__learn__header h3 div {
    margin: 0 0 0 1rem;
    cursor: pointer
}

.content-access__learn__header h3 div i {
    color: #b2b5cd;
    transition: all .3s ease
}

.content-access__learn__header h3 div:hover i {
    color: #656c9b
}

.content-access__learn__enable {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.content-access__learn__enable p {
    margin-right: calc(.5em*2.5);
    font-size: .875rem;
    color: #535a8b
}

.content-access__learn__actions {
    display: flex;
    align-items: center
}

@media screen and (max-width:32rem) {
    .content-access__learn__actions {
        margin: auto;
        width: 11rem
    }
}

.content-access__learn__list {
    margin: 30px 0;
    display: block;
    flex-wrap: wrap
}

@media screen and (min-width:32rem) {
    .content-access__learn__list {
        display: flex
    }
}

.content-access__learn__list .list-item {
    margin: 12px 24px 12px 0
}

.content-access__learn__list .card-camp {
    min-height: 120px
}

.content-access__learn__available {
    display: block;
    flex-wrap: wrap
}

@media screen and (min-width:32rem) {
    .content-access__learn__available {
        display: flex
    }
}

@media screen and (max-width:32rem) {
    .content-access__learn__available {
        margin: 12px 0
    }
}

.content-access__learn__available .list-item {
    margin: 12px 24px 12px 0
}

@media screen and (max-width:32rem) {
    .content-access__learn .content__data p:first-child {
        width: 8rem
    }
}

.content-access__manage__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem
}

@media screen and (max-width:32rem) {
    .content-access__manage__header {
        flex-direction: column-reverse
    }

    .content-access__manage__header msc-button {
        margin-top: 1rem;
        width: 11rem
    }
}

.content-access__manage__search {
    max-width: 100%
}

@media screen and (min-width:32rem) {
    .content-access__manage__search {
        max-width: 200px
    }
}

@media screen and (max-width:32rem) {
    .content-access__manage__search {
        margin-top: 1rem
    }
}

.content-access__manage__enable {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 5px
}

.content-access__manage__enable p {
    margin-right: calc(.5em*2.5);
    font-size: .875rem;
    color: #535a8b
}

.content-access__manage__tab {
    margin-bottom: calc(.5em*3)
}

.content-access__manage__title {
    border-bottom: 1px solid #d3d5e4;
    margin-bottom: calc(.5em*2);
    padding-bottom: calc(.5em*2)
}

@media screen and (max-width:32rem) {
    .content-access__manage__title {
        flex-direction: column-reverse
    }

    .content-access__manage__title msc-button {
        margin: 0 auto 1rem;
        width: 11rem
    }
}

.content-access__manage__title .arrow {
    margin-right: 1rem;
    opacity: .5;
    transition: all .3s ease-in-out
}

.content-access__manage__title h3 {
    display: flex;
    font-size: 1.375rem;
    color: #020d38;
    margin-right: calc(.5em*2);
    align-items: baseline
}

.content-access__manage__title h3 div {
    font-size: 1rem;
    margin: 0 0 0 1rem;
    cursor: pointer
}

.content-access__manage__title h3 div i {
    color: #b2b5cd;
    transition: all .3s ease
}

.content-access__manage__title h3 div:hover i {
    color: #656c9b
}

.content-access__manage__title.active+.content-access__manage__list {
    display: flex !important
}

.content-access__manage__title.active+.content-access__manage__list+.content-access__manage__more {
    display: block
}

.content-access__manage__title.active .content-access__manage__toggle {
    transform: rotate(0deg)
}

.content-access__manage__title.active .arrow {
    transform: rotate(180deg)
}

.content-access__manage__title__block {
    cursor: pointer
}

.content-access__manage__toggle {
    margin: 0 calc(.5em*2);
    transform: rotate(180deg);
    transition: transform .2s ease
}

.content-access__manage__toggle i {
    font-size: 1.375rem;
    color: #7d83aa
}

.content-access__manage__list {
    display: none;
    align-items: center;
    justify-content: flex-start;
    margin-left: -12px;
    margin-right: -12px
}

.content-access__manage__item {
    width: 100%;
    margin: 12px 24px 12px 0;
    word-break: break-all
}

@media screen and (min-width:32rem) {
    .content-access__manage__item {
        width: calc(50% - 24px)
    }
}

@media screen and (max-width:32rem) {
    .content-access__manage__item {
        margin: 0 12px
    }
}

.content-access__manage__more {
    display: none
}

.content-access__manage .box {
    min-height: 76px
}

.content-access__manage .box-content__picture {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    border-radius: 50%
}

.content-access__manage .box-content__infos .icon {
    font-size: 1.375rem;
    color: #f44436;
    margin-left: calc(.5em*1);
    margin-right: calc(.5em*2)
}

.content-access__manage .box-content__infos p {
    position: relative;
    font-size: .75rem;
    color: #020d38
}

@media screen and (min-width:64rem) {
    .content-access__manage .box-content__infos p {
        font-size: 1rem
    }
}

.content-access__manage .box-content__infos p.notification:after {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: -8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #f44436
}

.content-access__manage .box-content__infos p span {
    display: block;
    font-size: .625rem;
    color: #7d83aa
}

@media screen and (min-width:64rem) {
    .content-access__manage .box-content__infos p span {
        font-size: .875rem
    }
}

.content-access__manage .box-content__participant {
    width: 4rem
}

@media screen and (min-width:32rem) {
    .content-access__manage .box-content__participant {
        margin-right: calc(.5em*1)
    }
}

.content-access__manage .box-content__participant .icon {
    font-size: 1.375rem;
    color: #b2b5cd;
    margin-right: calc(.5em*1)
}

.content-access__manage .box-content__participant p {
    font-size: .875rem;
    color: #7d83aa
}

.content-access__manage .box-content__line {
    margin-right: .3rem;
    border-left: 2px solid rgba(211, 213, 228, .5)
}

@media screen and (min-width:32rem) {
    .content-access__manage .box-content__line {
        margin-right: calc(.5em*3)
    }
}

.content-access__manage .box-content__trash .icon {
    font-size: 1.375rem;
    color: #d3d5e4
}

.content-access__manage .box-content__trash .icon:hover {
    color: #ffa2a2;
    cursor: pointer
}

@media screen and (min-width:80rem) {
    .content-access__manage .box-content__trash {
        margin-right: 1.5rem
    }
}

.content-access__manage .box-content__collaborator-name {
    margin-bottom: .5rem
}

.content-access__manage .box-content__company-role {
    opacity: .5
}

.content-access__manage__user-authorization {
    padding: .25rem;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    background: #fff
}

.content-access__manage .box-community__avatar p {
    margin-left: calc(.5em*1.5)
}

.content-access__manage .box-community__pictos {
    margin-right: calc(.5em*1.5)
}

.content-access__manage .box-community__pictos p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .75rem;
    color: #8c91b4;
    margin-left: .5em;
    margin-right: calc(.5em*1.5)
}

.content-access__manage .box-community__pictos .icon {
    font-size: .75rem;
    color: #b2b5cd;
    margin-left: calc(.5em*1)
}

.content-access__manage__list {
    display: none !important;
    margin: 30px 0;
    flex-wrap: wrap
}

.content-access__manage__list h3 {
    color: #7d83aa;
    padding: 0 5%;
    font-style: italic
}

@media screen and (min-width:32rem) {
    .content-access__manage__list {
        display: flex
    }
}

.content-access__manage__list .list-item {
    margin: 0 10px 20px
}

.content-access__manage__list .card-camp {
    min-height: 120px
}

.content-access__manage__list span {
    color: #8c91b4
}

.content-access__manage__available {
    flex-wrap: wrap
}

@media screen and (min-width:32rem) {
    .content-access__manage__available {
        display: flex
    }
}

.content-access__manage__available .list-item {
    margin: 0 10px 20px
}

.content-access__manage span {
    color: #8c91b4
}

.content-access__manage .card-camp--flat {
    display: flex;
    align-items: center;
    height: 90px
}

.content-access__manage .card-camp__content .content__logo i {
    background-color: transparent;
    color: #feb88a
}

.content-access__organize .col6 {
    margin: 12px 24px 12px 0 !important
}

.content-access__organize__header {
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media screen and (max-width:32rem) {
    .content-access__organize__header {
        flex-direction: column
    }

    .content-access__organize__header msc-button {
        margin-top: 1rem;
        width: 11rem
    }

    .content-access__organize__header h3 {
        margin-top: 1rem
    }
}

.content-access__organize__header h3 {
    display: flex;
    margin-bottom: 1rem
}

.content-access__organize__header h3 div {
    margin: 0 0 0 1rem;
    cursor: pointer
}

.content-access__organize__header h3 div i {
    color: #b2b5cd;
    transition: all .3s ease
}

.content-access__organize__header h3 div:hover i {
    color: #656c9b
}

.content-access__organize__enable {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.content-access__organize__enable p {
    margin-right: calc(.5em*2.5);
    font-size: .875rem;
    color: #535a8b
}

.content-access__organize__actions {
    margin: 1rem 0 0;
    display: flex;
    align-items: center
}

.content-access__organize__data {
    margin: 30px 0 0;
    display: flex
}

@media screen and (max-width:32rem) {
    .content-access__organize__data {
        flex-direction: column;
        margin-bottom: 1rem
    }
}

.content-access__organize__data .data__logo {
    width: 250px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff
}

.content-access__organize__data .data__logo .logo__society {
    width: 200px;
    height: 100px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain
}

@media screen and (max-width:32rem) {
    .content-access__organize__data .data__logo {
        margin: auto
    }
}

.content-access__organize__data .data__form {
    margin: 0 0 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.content-access__organize__data .data__form__desc {
    font-size: .75rem;
    margin: 0 0 0 30px;
    color: rgba(178, 181, 205, .8)
}

@media screen and (max-width:32rem) {
    .content-access__organize__data .data__form {
        margin-top: 1rem
    }
}

.content-access__organize__list {
    margin: 30px 0
}

.content-access__organize__available .camps__list,
.content-access__organize__list .camps__list {
    margin: 30px 0 0;
    display: flex;
    flex-wrap: wrap
}

.content-access__organize__available .camps__list .list-item,
.content-access__organize__list .camps__list .list-item {
    margin: 12px 24px 12px 0
}

@media screen and (max-width:32rem) {

    .content-access__organize__available .camps__list .list-item,
    .content-access__organize__list .camps__list .list-item {
        margin: 12px 0
    }
}

.content-access__organize .card-camp__content .content__data__available {
    width: 65%
}

.content-access__settings .block-access-settings {
    position: relative;
    background: #fff;
    border-radius: 4px;
    padding: 20px 25px
}

.content-access__settings .block-access-settings.col-6 {
    margin-top: 0;
    margin-left: 0
}

.content-access__settings .block-access-settings h1 {
    font-size: 1.125rem
}

.content-access__settings .block-access-settings__list {
    margin: 2rem 0
}

.content-access__settings .block-access-settings__item {
    margin-bottom: 26px;
    padding-bottom: 26px;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    display: flex;
    align-items: center;
    justify-content: space-between
}

.content-access__settings .block-access-settings__item p {
    color: #656c9b;
    font-size: .875rem
}

.content-access__settings .block-access-settings__item p i {
    font-size: 1.375rem;
    margin-right: 8px
}

.content-access__settings .block-access-settings__form {
    width: 100%;
    margin-bottom: 1rem
}

.content-access__settings .block-access-settings__form p {
    margin-bottom: 18px
}

.content-access__settings .block-access-settings__actions {
    margin: 5rem 0 0;
    display: flex;
    justify-content: flex-end
}

.container-detail__aside .block-share {
    margin: 20px 0
}

.obsolete-container-detail .content-journey {
    margin-bottom: 5rem
}

.obsolete-container-detail .content-journey .session__subtitle--line {
    overflow: hidden;
    position: relative;
    margin-bottom: 3rem;
    padding: 1rem;
    color: #7d83aa;
    font-size: 1.125rem
}

.obsolete-container-detail .content-journey .session__subtitle--line:after {
    content: "";
    display: block;
    width: 100%;
    height: .1rem;
    background: #d3d5e4;
    margin-top: -12px
}

.obsolete-container-detail .content-journey .session__subtitle--line span {
    position: relative;
    background-color: #f9f9f9;
    padding-right: 1.5rem
}

.obsolete-container-detail .content-journey__title {
    color: #020d38;
    font-size: 1.25rem
}

.obsolete-container-detail .content-journey__item {
    justify-content: space-between;
    margin: 1rem 0
}

.obsolete-container-detail .content-journey__item__label {
    margin-left: 3rem
}

.obsolete-container-detail .content-journey__item .block-date-presentation {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.obsolete-container-detail .content-journey__item .block-date-presentation__body {
    margin: 20px 24px
}

.obsolete-container-detail .content-journey__item .block-date-presentation__item {
    margin: 0 0 0 20px
}

.obsolete-container-detail .content-journey__item .block-date-presentation__stats {
    min-width: 180px
}

.obsolete-container-detail .content-journey__list__info {
    margin: 3rem;
    font-size: .9rem;
    line-height: 1.2rem
}

.obsolete-container-detail .footer-run {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background-color: #fff;
    padding: 25px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 0 35px 0 rgba(0, 0, 0, .1)
}

@media screen and (min-width:80rem) {
    .obsolete-container-detail .footer-run {
        left: 285px;
        width: calc(100% - 285px)
    }
}

.obsolete-container-detail .footer-run__message {
    font-size: .8rem;
    margin: 0 1rem;
    color: #ffa2a2
}

.obsolete-container-detail .footer-run__infos {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -16px
}

.obsolete-container-detail .footer-run__infos li {
    margin: 0 16px;
    font-size: 1rem;
    color: #535a8b
}

.obsolete-container-detail .footer-run__infos li span {
    color: #ffa2a2
}

.content-template-instance-summary {
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap
}

.content-template-instance-summary .summary__main {
    background-color: #fff;
    padding: 0;
    min-width: 100%;
    order: 1;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:64rem) {
    .content-template-instance-summary .summary__main {
        padding: 20px;
        min-width: auto;
        order: 0
    }
}

.content-template-instance-summary .summary__aside {
    min-width: 100%;
    order: 0
}

@media screen and (min-width:64rem) {
    .content-template-instance-summary .summary__aside {
        min-width: auto;
        order: 1
    }
}

.content-template-instance-summary .block-section__header {
    background-color: #f8f9ff;
    border-top-left-radius: 4px;
    border-bottom: 0;
    margin-top: 20px
}

.content-template-instance-summary .block-section__toggle {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    transform: rotate(0) scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2);
    color: #7d83aa
}

.content-template-instance-summary .block-section__footer {
    background-color: #f8f9ff
}

.content-template-instance-summary .content-summary__content {
    display: none;
    transition: transform .2s ease-in-out
}

.content-template-instance-summary .block-task {
    padding: 10px
}

@media screen and (min-width:32rem) {
    .content-template-instance-summary .block-task {
        padding: 10px 40px
    }
}

.content-template-instance-summary .icon-arrow-ios-down:before {
    transition: all .3s ease-out
}

.content-template-instance-summary .dropdown-module {
    margin-top: 20px
}

.content-template-instance-summary .dropdown-module.active .content-summary__content {
    display: block;
    margin: 0;
    background-color: rgba(211, 213, 228, .5)
}

.content-template-instance-summary .dropdown-module.active .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.content-template-instance-summary .block-section__toggle-active {
    background-color: #f8f9ff;
    cursor: default
}

.content-template-instance-summary .link:last-child {
    margin-right: 40px !important
}

.obsolete-container-detail .content-summary__main {
    display: flex;
    flex-wrap: wrap
}

.content-summary__main .journey-timeline {
    position: relative;
    padding-left: 35px;
    order: 1
}

@media screen and (min-width:32rem) {
    .content-summary__main .journey-timeline {
        padding-left: 90px;
        padding-bottom: 1.5rem;
    }
}

@media screen and (min-width:64rem) {
    .content-summary__main .journey-timeline {
        order: 0
    }
}

.content-summary__main .journey-timeline:before {
    content: "";
    position: absolute;
    left: 10px;
    width: 2px;
}

@media screen and (min-width:32rem) {
    .content-summary__main .journey-timeline:before {
        top: 50px;
        transform: translateY(75px);
        height: 90%;
    }
}

.content-summary__main .journey-timeline .first-block {
    margin-top: 0 !important;
    padding-top: 0 !important
}

.content-summary__main .journey-timeline__item {
    position: relative;
    min-height: 170px
}

.content-summary__main .journey-timeline__item:after {
    position: absolute;
    left: -36px;
    top: 50%;
    content: attr(data-timeline);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 24px;
    height: 24px;
    font-size: .625rem;
    border-radius: 50%;
    transform: translateY(-25%)
}

@media screen and (min-width:32rem) {
    .content-summary__main .journey-timeline__item:after {
        font-size: .75rem;
        left: -96px;
        width: 35px;
        height: 35px
    }
}

.content-summary__main .journey-timeline__item--progress:after {
    font-size: .625rem
}

@media screen and (min-width:32rem) {
    .content-summary__main .journey-timeline__item--progress:after {
        font-size: .75rem
    }
}

.content-summary__main .journey-timeline__item--success:after {
    content: "\e837";
    font-family: myskillcamp
}

.content-summary__main .journey-timeline__item--locked:after {
    content: "\e88d";
    font-family: myskillcamp
}

.content-summary__main .journey-timeline__item--locked .summary__locked {
    position: absolute;
    top: 40px;
    right: 30px
}

@media screen and (max-width:32rem) {
    .content-summary__main .journey-timeline__item--locked .summary__locked {
        position: static
    }
}

.content-summary__main .journey-timeline__item--last:before {
    content: "";
    position: absolute;
    left: -5rem;
    top: 60%;
    width: 2px;
    border-left: 2px solid #f9f9f9;
    height: 50%
}

.content-summary__main .journey-timeline__item.block-task {
    padding: 25px 0 0
}

.content-summary__main .journey-timeline__item .block-task__header--media {
    display: block;
    overflow:hidden;

}

.content-summary__main .journey-timeline__item .block-task__top {
    display: flex
}

.content-summary__main .journey-timeline__item .block-task__title {
    width: 100%
}

.content-summary__main .journey-timeline__item .block-task__title .block-task__tag {
    width: 30%
}

.content-summary__main .journey-timeline__item .block-task__title .block-task__data {
    width: 70%;
    display: flex;
    align-items: center;
}

.content-summary__main .journey-timeline__item .block-task__description {
    max-height: 120px;
    overflow: auto;
    margin: 1rem 0;
    color: #b2b5cd;
    font-size: .875rem
}

.content-summary__main .journey-timeline__item .block-task__media {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0 0
}

.content-summary__main .journey-timeline__item .block-task__media img {
    margin: 0 .5rem 0 0;
    width: 2rem
}

.content-summary__main .journey-timeline__item .block-task__media i {
    cursor: pointer;
    font-size: 1.25rem
}

.content-summary__main .journey-timeline__item .block-task__media i:first-child {
    margin: 0 .5rem 0 0
}

.content-summary__main .journey-timeline__item .block-task__media i:before {
    transition: all .3s ease
}

.content-summary__main .journey-timeline__item .block-task__media i:hover:before {
    transform: scale(1.2);
    transition: all .3s ease
}

.content-summary__main .journey-timeline .block-module__wrapper {
    display: block;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    min-height: 170px
}

@media screen and (min-width:32rem) {
    .content-summary__main .journey-timeline .block-module__wrapper {
        display: flex
    }
}

.content-summary__main .journey-timeline .block-event {
    padding-right: 1rem
}

.content-summary__main .journey-timeline .block-module__image {
    min-height: 220px;
    width: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0
}

@media screen and (min-width:32rem) {
    .content-summary__main .journey-timeline .block-module__image {
        min-height: auto;
        width: 40%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }
}

.content-summary__main .journey-timeline .block-module__desc {
    width: 100%
}

@media screen and (min-width:32rem) {
    .content-summary__main .journey-timeline .block-module__desc {
        width: 60%
    }
}

.content-summary__main .journey-timeline .block-module__desc .journey__date {
    font-size: .875rem;
    color: #8c91b4
}

.content-summary__main .journey-timeline .block-module__desc .journey__adress {
    margin: .5rem 0 1rem
}

.content-summary__main .journey-timeline .block-module__desc .block-module__subtitle {
    font-size: .875rem;
    color: #b2b5cd;
    margin-bottom: 1rem
}

.content-summary__main .journey-timeline .block-module__desc .block-module__title {
    margin: 1rem 0
}

.content-summary__main .journey-timeline .block-section__toggle {
    border-bottom-right-radius: 0 !important
}

.content-summary__main .journey-timeline .dropdown-event .block-task__widgets msc-button {
    right: 5px !important;
    top: 35px !important
}

.content-summary__main .journey-timeline .dropdown-event .journey-event__subcontent {
    display: none;
    border-top: 1px solid #d3d5e4;
    background-color: #fff
}

.content-summary__main .journey-timeline .dropdown-event .journey-event__subcontent__content {
    padding: 1rem;
    word-break: break-word;
}

.content-summary__main .journey-timeline .dropdown-event .journey-event__subcontent__resource {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap
}

.content-summary__main .journey-timeline .dropdown-event .journey-event__subcontent h2 {
    margin: 1rem 0;
    color: #8c91b4
}

.content-summary__main .journey-timeline .dropdown-event .icon-arrow-ios-down:before {
    transform: rotate(0);
    transition: all .3s ease
}

.content-summary__main .journey-timeline .dropdown-event.active .journey-event__subcontent {
    display: block
}

.content-summary__main .journey-timeline .dropdown-event.active .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.content-summary__main .journey-timeline .dropdown-event__progress-bar {
    background-color: #fff
}

.content-summary__main .summary__aside {
    order: 0
}

@media screen and (min-width:64rem) {
    .content-summary__main .summary__aside {
        order: 1
    }
}

.content-summary__main .block-task__duration {
    display: flex;
    align-items: center
}

.content-summary__main .block-task__duration i {
    font-size: .875rem;
    color: #7d83aa
}

.content-summary__main .block-task__duration span {
    font-size: .875rem;
    margin: 0 0 0 .5rem;
    color: #7d83aa
}

.link:last-child {
    margin-right: 40px
}
.link-play.link:last-child {
    margin-right: 10px !important;
}

.block-module {
    margin: 30px 0 0
}

.block-module:after {
    transform: translateY(0) !important
}

.block-external-content__banner {
    padding: 8px 16px;
    background: rgba(211, 213, 228, .5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    border-top-left-radius: 2px
}

.block-external-content__banner__logo {
    width: 90px;
    height: 35px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain
}

.block-external-content__banner .tag {
    position: relative;
    top: auto;
    left: auto
}

.block-external-content .block-module__widgets {
    justify-content: flex-end
}

.block-activity-event {
    border-bottom: 1px solid #d3d5e4;
    padding: 32px 40px 32px 32px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

@media screen and (max-width:32rem) {
    .block-activity-event {
        padding: 32px 55px 32px 32px
    }
}

.block-activity-event .block-section__toggle {
    background-color: #fff
}

.block-activity-event .block-task__widgets msc-button {
    display: flex;
    justify-content: center;
    align-items: center
}

.block-activity-event .block-task__widgets .spinner {
    margin-right: 5rem
}

.block-activity-event .block-task__presence {
    color: #49e2af
}

.block-activity-event .block-task__calendar {
    cursor: pointer;
    transition: all .2s ease
}

.block-activity-event .block-task__calendar:hover {
    color: #000
}

.content-template-summary {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.content-template-summary .block-section__header {
    background-color: #f8f9ff;
    border-top-left-radius: 4px;
    border-bottom: 0
}

.content-template-summary .block-section__toggle {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0
}

.content-template-summary .content-summary__content {
    display: none;
    transition: transform .3s cubic-bezier(.175, .885, .32, 2)
}

.content-template-summary .block-task {
    padding: 10px !important
}

@media screen and (min-width:64rem) {
    .content-template-summary .block-task {
        padding: 10px 40px !important
    }
}

.content-template-summary .icon-arrow-ios-down:before {
    transition: all .3s ease-out
}

.content-template-summary .dropdown-module {
    margin-top: 20px
}

.content-template-summary .dropdown-module.active .content-summary__content {
    display: block;
    margin: 0;
    background-color: rgba(211, 213, 228, .5)
}

.content-template-summary .dropdown-module.active .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.content-template-summary .block-section__toggle-active {
    background-color: #f8f9ff;
    cursor: default
}

.info-tooltip {
    margin-left: calc(.5em*0.5);
    display: flex;
    align-items: center
}

.info-tooltip i {
    color: #c0c3d9;
    font-size: .875rem;
    cursor: pointer
}

.info-tooltip__anchor {
    position: fixed;
    z-index: 10
}

.info-tooltip__container {
    min-width: 400px;
    position: absolute;
    top: calc(calc(.5em*3)*-1);
    left: calc(.5em*3.5);
    z-index: 20;
    background: #fff;
    border: 1px solid #414776;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: calc(.5em*2);
    color: #414776;
    font-size: .75rem
}

.info-tooltip__container--flipped {
    transform: scaleX(-1);
    right: calc(.5em*3);
    left: unset
}

.info-tooltip__container--flipped .info-tooltip__content,
.info-tooltip__container--flipped .info-tooltip__header {
    transform: scaleX(-1)
}

.info-tooltip__container:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #414776;
    left: -10px;
    top: calc(.5em*1)
}

.info-tooltip__container:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid #fff;
    left: -8.5px;
    top: calc(calc(.5em*1) + 1px)
}

.info-tooltip__header__skills .skill {
    margin-left: 0 !important
}

.info-tooltip__header__skills .skill .skill__text {
    font-size: .75rem
}

.info-tooltip__header__details {
    margin: calc(.5em*2) 0;
    display: flex;
    align-items: center
}

.info-tooltip__header__info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: calc(.5em*1)
}

.info-tooltip__header__info .icon {
    margin-right: calc(.5em*0.5)
}

.score-progress {
    position: relative;
    height: 65px;
    width: 65px
}

.score-progress .circle-progress {
    height: 100%;
    width: 100%;
    position: relative;
    transform: rotate(-180deg);
    -webkit-clip-path: polygon(50% 50%, 78.5% 0, 100% 0, 100% 100%, 0 100%, 0 0, 21.5% 0);
    clip-path: polygon(50% 50%, 78.5% 0, 100% 0, 100% 100%, 0 100%, 0 0, 21.5% 0)
}

.score-progress .circle-progress .inner {
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    height: 85%;
    width: 85%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 100%
}

.score-progress .circle-progress .circle--disabled .circle__bar {
    background: transparent
}

.score-progress .circle-progress .circle__bar {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #eaecf4;
    border-radius: 100%;
    -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%)
}

.score-progress .circle-progress .circle__bar .progress {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    -webkit-clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
    background: #6471d0
}

.score-progress .circle-progress .circle .left {
    transform: rotate(30deg)
}

.score-progress .circle-progress .circle .left .progress {
    z-index: 1;
    transition: transform 1s linear
}

.score-progress .circle-progress .circle .right {
    transform: rotate(180deg);
    z-index: 3
}

.score-progress .circle-progress .circle .right .progress {
    transition: transform 1s linear;
    transition-delay: 1s
}

.score-progress .circle-progress--0 .circle .left .progress,
.score-progress .circle-progress--0 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--1 .circle .left .progress {
    transform: rotate(3deg)
}

.score-progress .circle-progress--1 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--2 .circle .left .progress {
    transform: rotate(6deg)
}

.score-progress .circle-progress--2 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--3 .circle .left .progress {
    transform: rotate(9deg)
}

.score-progress .circle-progress--3 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--4 .circle .left .progress {
    transform: rotate(12deg)
}

.score-progress .circle-progress--4 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--5 .circle .left .progress {
    transform: rotate(15deg)
}

.score-progress .circle-progress--5 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--6 .circle .left .progress {
    transform: rotate(18deg)
}

.score-progress .circle-progress--6 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--7 .circle .left .progress {
    transform: rotate(21deg)
}

.score-progress .circle-progress--7 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--8 .circle .left .progress {
    transform: rotate(24deg)
}

.score-progress .circle-progress--8 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--9 .circle .left .progress {
    transform: rotate(27deg)
}

.score-progress .circle-progress--9 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--10 .circle .left .progress {
    transform: rotate(30deg)
}

.score-progress .circle-progress--10 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--11 .circle .left .progress {
    transform: rotate(33deg)
}

.score-progress .circle-progress--11 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--12 .circle .left .progress {
    transform: rotate(36deg)
}

.score-progress .circle-progress--12 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--13 .circle .left .progress {
    transform: rotate(39deg)
}

.score-progress .circle-progress--13 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--14 .circle .left .progress {
    transform: rotate(42deg)
}

.score-progress .circle-progress--14 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--15 .circle .left .progress {
    transform: rotate(45deg)
}

.score-progress .circle-progress--15 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--16 .circle .left .progress {
    transform: rotate(48deg)
}

.score-progress .circle-progress--16 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--17 .circle .left .progress {
    transform: rotate(51deg)
}

.score-progress .circle-progress--17 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--18 .circle .left .progress {
    transform: rotate(54deg)
}

.score-progress .circle-progress--18 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--19 .circle .left .progress {
    transform: rotate(57deg)
}

.score-progress .circle-progress--19 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--20 .circle .left .progress {
    transform: rotate(60deg)
}

.score-progress .circle-progress--20 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--21 .circle .left .progress {
    transform: rotate(63deg)
}

.score-progress .circle-progress--21 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--22 .circle .left .progress {
    transform: rotate(66deg)
}

.score-progress .circle-progress--22 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--23 .circle .left .progress {
    transform: rotate(69deg)
}

.score-progress .circle-progress--23 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--24 .circle .left .progress {
    transform: rotate(72deg)
}

.score-progress .circle-progress--24 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--25 .circle .left .progress {
    transform: rotate(75deg)
}

.score-progress .circle-progress--25 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--26 .circle .left .progress {
    transform: rotate(78deg)
}

.score-progress .circle-progress--26 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--27 .circle .left .progress {
    transform: rotate(81deg)
}

.score-progress .circle-progress--27 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--28 .circle .left .progress {
    transform: rotate(84deg)
}

.score-progress .circle-progress--28 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--29 .circle .left .progress {
    transform: rotate(87deg)
}

.score-progress .circle-progress--29 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--30 .circle .left .progress {
    transform: rotate(90deg)
}

.score-progress .circle-progress--30 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--31 .circle .left .progress {
    transform: rotate(93deg)
}

.score-progress .circle-progress--31 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--32 .circle .left .progress {
    transform: rotate(96deg)
}

.score-progress .circle-progress--32 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--33 .circle .left .progress {
    transform: rotate(99deg)
}

.score-progress .circle-progress--33 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--34 .circle .left .progress {
    transform: rotate(102deg)
}

.score-progress .circle-progress--34 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--35 .circle .left .progress {
    transform: rotate(105deg)
}

.score-progress .circle-progress--35 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--36 .circle .left .progress {
    transform: rotate(108deg)
}

.score-progress .circle-progress--36 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--37 .circle .left .progress {
    transform: rotate(111deg)
}

.score-progress .circle-progress--37 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--38 .circle .left .progress {
    transform: rotate(114deg)
}

.score-progress .circle-progress--38 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--39 .circle .left .progress {
    transform: rotate(117deg)
}

.score-progress .circle-progress--39 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--40 .circle .left .progress {
    transform: rotate(120deg)
}

.score-progress .circle-progress--40 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--41 .circle .left .progress {
    transform: rotate(123deg)
}

.score-progress .circle-progress--41 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--42 .circle .left .progress {
    transform: rotate(126deg)
}

.score-progress .circle-progress--42 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--43 .circle .left .progress {
    transform: rotate(129deg)
}

.score-progress .circle-progress--43 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--44 .circle .left .progress {
    transform: rotate(132deg)
}

.score-progress .circle-progress--44 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--45 .circle .left .progress {
    transform: rotate(135deg)
}

.score-progress .circle-progress--45 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--46 .circle .left .progress {
    transform: rotate(138deg)
}

.score-progress .circle-progress--46 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--47 .circle .left .progress {
    transform: rotate(141deg)
}

.score-progress .circle-progress--47 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--48 .circle .left .progress {
    transform: rotate(144deg)
}

.score-progress .circle-progress--48 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--49 .circle .left .progress {
    transform: rotate(147deg)
}

.score-progress .circle-progress--49 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--50 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--50 .circle .right .progress {
    transform: rotate(0deg)
}

.score-progress .circle-progress--51 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--51 .circle .right .progress {
    transform: rotate(3deg)
}

.score-progress .circle-progress--52 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--52 .circle .right .progress {
    transform: rotate(6deg)
}

.score-progress .circle-progress--53 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--53 .circle .right .progress {
    transform: rotate(9deg)
}

.score-progress .circle-progress--54 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--54 .circle .right .progress {
    transform: rotate(12deg)
}

.score-progress .circle-progress--55 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--55 .circle .right .progress {
    transform: rotate(15deg)
}

.score-progress .circle-progress--56 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--56 .circle .right .progress {
    transform: rotate(18deg)
}

.score-progress .circle-progress--57 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--57 .circle .right .progress {
    transform: rotate(21deg)
}

.score-progress .circle-progress--58 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--58 .circle .right .progress {
    transform: rotate(24deg)
}

.score-progress .circle-progress--59 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--59 .circle .right .progress {
    transform: rotate(27deg)
}

.score-progress .circle-progress--60 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--60 .circle .right .progress {
    transform: rotate(30deg)
}

.score-progress .circle-progress--61 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--61 .circle .right .progress {
    transform: rotate(33deg)
}

.score-progress .circle-progress--62 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--62 .circle .right .progress {
    transform: rotate(36deg)
}

.score-progress .circle-progress--63 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--63 .circle .right .progress {
    transform: rotate(39deg)
}

.score-progress .circle-progress--64 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--64 .circle .right .progress {
    transform: rotate(42deg)
}

.score-progress .circle-progress--65 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--65 .circle .right .progress {
    transform: rotate(45deg)
}

.score-progress .circle-progress--66 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--66 .circle .right .progress {
    transform: rotate(48deg)
}

.score-progress .circle-progress--67 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--67 .circle .right .progress {
    transform: rotate(51deg)
}

.score-progress .circle-progress--68 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--68 .circle .right .progress {
    transform: rotate(54deg)
}

.score-progress .circle-progress--69 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--69 .circle .right .progress {
    transform: rotate(57deg)
}

.score-progress .circle-progress--70 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--70 .circle .right .progress {
    transform: rotate(60deg)
}

.score-progress .circle-progress--71 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--71 .circle .right .progress {
    transform: rotate(63deg)
}

.score-progress .circle-progress--72 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--72 .circle .right .progress {
    transform: rotate(66deg)
}

.score-progress .circle-progress--73 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--73 .circle .right .progress {
    transform: rotate(69deg)
}

.score-progress .circle-progress--74 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--74 .circle .right .progress {
    transform: rotate(72deg)
}

.score-progress .circle-progress--75 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--75 .circle .right .progress {
    transform: rotate(75deg)
}

.score-progress .circle-progress--76 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--76 .circle .right .progress {
    transform: rotate(78deg)
}

.score-progress .circle-progress--77 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--77 .circle .right .progress {
    transform: rotate(81deg)
}

.score-progress .circle-progress--78 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--78 .circle .right .progress {
    transform: rotate(84deg)
}

.score-progress .circle-progress--79 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--79 .circle .right .progress {
    transform: rotate(87deg)
}

.score-progress .circle-progress--80 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--80 .circle .right .progress {
    transform: rotate(90deg)
}

.score-progress .circle-progress--81 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--81 .circle .right .progress {
    transform: rotate(93deg)
}

.score-progress .circle-progress--82 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--82 .circle .right .progress {
    transform: rotate(96deg)
}

.score-progress .circle-progress--83 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--83 .circle .right .progress {
    transform: rotate(99deg)
}

.score-progress .circle-progress--84 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--84 .circle .right .progress {
    transform: rotate(102deg)
}

.score-progress .circle-progress--85 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--85 .circle .right .progress {
    transform: rotate(105deg)
}

.score-progress .circle-progress--86 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--86 .circle .right .progress {
    transform: rotate(108deg)
}

.score-progress .circle-progress--87 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--87 .circle .right .progress {
    transform: rotate(111deg)
}

.score-progress .circle-progress--88 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--88 .circle .right .progress {
    transform: rotate(114deg)
}

.score-progress .circle-progress--89 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--89 .circle .right .progress {
    transform: rotate(117deg)
}

.score-progress .circle-progress--90 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--90 .circle .right .progress {
    transform: rotate(120deg)
}

.score-progress .circle-progress--91 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--91 .circle .right .progress {
    transform: rotate(123deg)
}

.score-progress .circle-progress--92 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--92 .circle .right .progress {
    transform: rotate(126deg)
}

.score-progress .circle-progress--93 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--93 .circle .right .progress {
    transform: rotate(129deg)
}

.score-progress .circle-progress--94 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--94 .circle .right .progress {
    transform: rotate(132deg)
}

.score-progress .circle-progress--95 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--95 .circle .right .progress {
    transform: rotate(135deg)
}

.score-progress .circle-progress--96 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--96 .circle .right .progress {
    transform: rotate(138deg)
}

.score-progress .circle-progress--97 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--97 .circle .right .progress {
    transform: rotate(141deg)
}

.score-progress .circle-progress--98 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--98 .circle .right .progress {
    transform: rotate(144deg)
}

.score-progress .circle-progress--99 .circle .left .progress {
    transform: rotate(150deg)
}

.score-progress .circle-progress--99 .circle .right .progress {
    transform: rotate(147deg)
}

.score-progress .circle-progress--100 .circle .left .progress,
.score-progress .circle-progress--100 .circle .right .progress {
    transform: rotate(150deg)
}

.score-progress .number {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    font-size: .625rem;
    font-weight: 700;
    color: #414776
}

.score-progress .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 50%;
    transform: translateY(-50%)
}

.score-progress .content .logo {
    padding: calc(.5em*0.5);
    background: #6471d0;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%);
    clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%)
}

.score-progress .content .logo i {
    color: #fff
}

.content-camps .content-corrections {
    margin: 0 0 0 1rem;
    position: relative
}

.content-camps .content-corrections__back {
    position: absolute;
    top: 0;
    left: -16px;
    display: none
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections__back {
        display: block
    }
}

@media screen and (min-width:80rem) {
    .content-camps .content-corrections__back {
        padding: 25px 25px 25px 0
    }
}

.content-camps .content-corrections__title {
    padding-bottom: 20px;
    color: #020d38;
    font-size: 1.5rem
}

.content-camps .content-corrections__list {
    margin: -20px 0
}

.content-camps .content-corrections__table {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(2, 13, 56, .08)
}

.content-camps .content-corrections__table-header {
    display: none;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 50px 12px 80px
}

@media screen and (min-width:48rem) {
    .content-camps .content-corrections__table-header {
        display: flex
    }
}

.content-camps .content-corrections__table-title p {
    font-size: .75rem;
    color: #b2b5cd
}

.content-camps .content-corrections__table-title:first-child {
    min-width: calc(50% - 20px)
}

.content-camps .content-corrections__table-title:nth-child(2) {
    min-width: calc(25% + 30px)
}

.content-camps .content-corrections__filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.content-camps .content-corrections__filter>* {
    margin: 0 8px
}

.content-camps .content-corrections__header .box-session-correction {
    margin-top: -14px
}

.content-camps .content-corrections__header .box-session-correction:hover {
    transform: scale(1)
}

.content-camps .content-corrections__header .box-session-correction:after {
    display: none
}

.content-camps .content-corrections__header .box-session-correction__header i {
    margin-left: 0
}

.content-camps .content-corrections__header .box-session-correction__content {
    width: auto;
    padding-right: 60px
}

.content-camps .content-corrections__content {
    margin-top: 24px
}

.content-camps .content-corrections__content .tabs {
    margin: calc(calc(.5em*0.5)*-1) 0
}

.content-camps .content-corrections__content .tabs__header {
    float: none;
    width: 100%;
    margin: calc(.5em*0.5) 0
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections__content .tabs__header {
        float: left;
        width: 35%
    }
}

.content-camps .content-corrections__content .tabs__body {
    float: none;
    width: 100%;
    min-height: auto;
    padding: calc(.5em*2);
    background-color: #fff
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections__content .tabs__body {
        float: right;
        width: 63%;
        min-height: 60vh
    }
}

.content-camps .content-corrections__main {
    width: 100%
}

.content-camps .content-corrections__cta {
    display: flex;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto
}

.content-camps .content-corrections__aside {
    margin-right: 16px;
    margin-top: -8px;
    margin-bottom: -8px;
    min-width: 300px
}

.content-camps .content-corrections__aside .box {
    margin: 8px 0
}

.content-camps .content-corrections__toggle {
    cursor: pointer;
    transform: rotate(0);
    transition: all .3s ease
}

.content-camps .content-corrections .content-corrections__section {
    display: none
}

.content-camps .content-corrections__details.active .content-corrections__title {
    border-bottom: 1px solid #d3d5e4;
    margin-bottom: 12px;
    padding-bottom: 12px
}

.content-camps .content-corrections__details.active .content-corrections__toggle {
    color: red;
    transform: rotate(180deg)
}

.content-camps .content-corrections__details.active .content-corrections__section {
    display: block
}

.content-camps .content-corrections msc-button i {
    font-size: .75rem;
    margin-right: 8px
}

.content-camps .content-corrections msc-button.active {
    color: #fff;
    border-color: #5150c4;
    background-color: #5150c4
}

.content-camps .content-corrections msc-button.active i {
    color: #fff
}

.content-camps .content-corrections .box-session-correction {
    position: relative;
    cursor: pointer;
    padding: 16px;
    display: block;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1);
    margin: 20px 0;
    transform: scale(1);
    transition: all .4s cubic-bezier(.175, .885, .32, 2)
}

@media screen and (min-width:48rem) {
    .content-camps .content-corrections .box-session-correction {
        display: flex
    }
}

.content-camps .content-corrections .box-session-correction:hover {
    transform: scale(1.0025)
}

.content-camps .content-corrections .box-session-correction:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.content-camps .content-corrections .box-session-correction:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 8px;
    background: #d3d5e4;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.content-camps .content-corrections .box-session-correction--done:after {
    background: #49e2af
}

.content-camps .content-corrections .box-session-correction--progress:after {
    background: #37beeb
}

.content-camps .content-corrections .box-session-correction__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 10
}

.content-camps .content-corrections .box-session-correction__header i {
    font-size: 1.5rem;
    color: #f44436;
    margin: 0 20px 0 16px
}

.content-camps .content-corrections .box-session-correction__title {
    position: relative
}

.content-camps .content-corrections .box-session-correction__title h3 {
    font-size: 1.125rem
}

.content-camps .content-corrections .box-session-correction__title .box-session-correction__notification {
    position: absolute;
    top: 2px;
    left: -6px
}

.content-camps .content-corrections .box-session-correction__notification {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #f09a9a
}

.content-camps .content-corrections .box-session-correction__content {
    position: relative;
    z-index: 1;
    display: block
}

@media screen and (min-width:48rem) {
    .content-camps .content-corrections .box-session-correction__content {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

.content-camps .content-corrections .box-session-correction__breadcrumb {
    display: flex;
    align-items: center
}

.content-camps .content-corrections .box-session-correction__breadcrumb i {
    font-size: 1.25rem;
    margin: 0 4px;
    color: #8c91b4
}

.content-camps .content-corrections .box-session-correction__infos ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: calc(.5em*2) -16px
}

@media screen and (min-width:48rem) {
    .content-camps .content-corrections .box-session-correction__infos ul {
        margin: 0 -16px;
        justify-content: space-between
    }
}

.content-camps .content-corrections .box-session-correction__infos li {
    margin: 0 16px;
    color: #535a8b
}

.content-camps .content-corrections .box-session-correction__infos li i {
    color: #b2b5cd;
    margin-right: 4px
}

.content-camps .content-corrections .box-session-correction__arrow {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) scale(1);
    transition: all .2s cubic-bezier(.175, .885, .32, 2)
}

.content-camps .content-corrections .box-session-correction__arrow i {
    color: #d3d5e4;
    font-size: 1.25rem
}

.content-camps .content-corrections .box-session-correction__arrow:hover {
    transform: translateY(-50%) scale(1.1)
}

.content-camps .content-corrections .box-session-correction__progress {
    margin: 0 40px
}

.content-camps .content-corrections .progress-correction {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 7px;
    min-width: 145px;
    border-radius: 4px;
    background-color: #d3d5e4
}

.content-camps .content-corrections .progress-correction__bar {
    position: absolute;
    width: auto;
    height: 7px
}

.content-camps .content-corrections .progress-correction__bar:first-child {
    z-index: 5;
    border-radius: 4px;
    width: 33%
}

.content-camps .content-corrections .progress-correction__bar:last-child {
    z-index: 1;
    border-radius: 4px;
    width: 66%
}

.content-camps .content-corrections .progress-correction__bar--green {
    background-color: #49e2af
}

.content-camps .content-corrections .progress-correction__bar--blue {
    background-color: #37beeb
}

.content-camps .content-corrections .box-correction-user {
    cursor: pointer;
    position: relative;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    box-shadow: none;
    transform: scale(1);
    transition: .2s cubic-bezier(.175, .885, .32, 2)
}

.content-camps .content-corrections .box-correction-user.active {
    border-color: #4c77ff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    transform: scale(1.01)
}

.content-camps .content-corrections .box-correction-user:hover {
    border-color: #4c77ff;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    transform: scale(1.01)
}

.content-camps .content-corrections .box-correction-user__avatar {
    margin-right: 50px
}

.content-camps .content-corrections .box-correction-user__login {
    font-size: .875rem;
    color: #d3d5e4
}

.content-camps .content-corrections .box-correction-user__arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%)
}

.content-camps .content-corrections .box-correction-user__arrow i {
    font-size: 1.125rem;
    color: #d3d5e4
}

.content-camps .content-corrections .box-correction-user:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: #d3d5e4
}

.content-camps .content-corrections .box-correction-user--progress:before {
    background-color: #37beeb
}

.content-camps .content-corrections .box-correction-user--success:before {
    background-color: #49e2af
}

.content-camps .content-corrections .block-table-header__title {
    color: #8c91b4;
    font-size: .75rem;
    min-width: 200px
}

.content-camps .content-corrections .block-table-header__title:first-child {
    min-width: calc(100% - 350px)
}

.content-camps .content-corrections__body .box-session-correction-details__body box-session-correction-details__list .box-session-correction-details__arrow {
    display: none;
    background-color: transparent
}

.content-camps .content-corrections-list {
    margin: -36px 0 0;
    padding-left: 0
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list {
        padding: 0 0 0 1rem
    }
}

.content-camps .content-corrections-list .content-corrections__search {
    background: #fff
}

.content-camps .content-corrections-list .content-corrections__filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px
}

.content-camps .content-corrections-list .content-corrections__filter>* {
    margin: 0 8px
}

.content-camps .content-corrections-list .content-corrections__list {
    margin: -20px 0
}

.content-camps .content-corrections-list .content-corrections__table {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(2, 13, 56, .08)
}

.content-camps .content-corrections-list .box-session-correction-details {
    position: relative
}

.content-camps .content-corrections-list .box-session-correction-details__header {
    display: block;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 16px;
    margin: 20px 0;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    border-radius: 4px
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__header {
        display: flex
    }
}

.content-camps .content-corrections-list .box-session-correction-details__header i {
    font-size: 1.5rem;
    color: #f44436;
    margin: 0 20px 0 16px
}

.content-camps .content-corrections-list .box-session-correction-details__header:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.content-camps .content-corrections-list .box-session-correction-details__header .box-session-correction-details__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(.5em*5);
    background-color: #f8f9ff;
    bottom: calc(calc(.5em*2)*-1 - calc(.5em*1));
    left: calc(calc(.5em*2)*-1)
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__header .box-session-correction-details__arrow {
        background-color: transparent;
        width: auto;
        height: auto;
        transform: translateY(-50%) rotate(0deg);
        display: block;
        top: 50%;
        right: -8px;
        bottom: auto;
        left: auto
    }
}

.content-camps .content-corrections-list .box-session-correction-details__title {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.125rem;
    color: #8c91b4
}

.content-camps .content-corrections-list .box-session-correction-details__notification {
    display: inline-flex;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #f09a9a;
    margin-right: 8px
}

.content-camps .content-corrections-list .box-session-correction-details__desc {
    position: static;
    z-index: 1;
    display: block;
    align-items: center;
    justify-content: flex-start;
    padding-right: 0;
    margin: calc(.5em*3) 0
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__desc {
        position: relative;
        display: flex;
        padding-right: 40px;
        margin: 0 -25px
    }
}

@media screen and (min-width:80rem) {
    .content-camps .content-corrections-list .box-session-correction-details__desc {
        position: relative;
        padding-right: 60px;
        margin: 0 -25px
    }
}

.content-camps .content-corrections-list .box-session-correction-details__desc>* {
    margin: 0 15px
}

@media screen and (min-width:48rem) {
    .content-camps .content-corrections-list .box-session-correction-details__desc>* {
        margin: 0 25px
    }
}

.content-camps .content-corrections-list .box-session-correction-details__progress {
    margin: calc(.5em*3) 0 !important
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__progress {
        margin: 0
    }
}

.content-camps .content-corrections-list .box-session-correction-details__infos {
    margin: 0 calc(.5em*2) !important
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__infos {
        margin: 0
    }
}

.content-camps .content-corrections-list .box-session-correction-details__infos ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -16px
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__infos ul {
        justify-content: space-between
    }
}

.content-camps .content-corrections-list .box-session-correction-details__infos li {
    margin: 0 16px;
    color: #535a8b
}

.content-camps .content-corrections-list .box-session-correction-details__infos li i {
    color: #b2b5cd;
    margin-right: 4px
}

.content-camps .content-corrections-list .box-session-correction-details__arrow {
    cursor: pointer;
    transform: translateX(0) rotate(180deg);
    position: absolute
}

.content-camps .content-corrections-list .box-session-correction-details__arrow i {
    color: #d3d5e4;
    font-size: 1.25rem
}

.content-camps .content-corrections-list .box-session-correction-details__content {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding: calc(.5em*1)
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 80px 0 0
    }
}

.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__title {
    font-size: .875rem;
    color: #7d83aa
}

.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__title i {
    color: #d3d5e4;
    margin-right: 16px
}

.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: calc(.5em*1.5) 0
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos {
        justify-content: space-between;
        margin: 0
    }
}

.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos li,
.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos li i {
    font-size: .875rem
}

.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos li .icon-checkmark-circle-2 {
    color: #49e2af
}

.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__infos li .icon-clock {
    color: #7986ef
}

.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__progress {
    font-size: .875rem;
    color: #7d83aa;
    margin-left: calc(.5em*2)
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__progress {
        margin-left: 0
    }
}

.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%)
}

.content-camps .content-corrections-list .box-session-correction-details__content .box-session-correction-details__arrow i {
    font-size: 1rem
}

.content-camps .content-corrections-list .box-session-correction-details__list {
    display: none;
    margin: 0 -12px -12px
}

.content-camps .content-corrections-list .box-session-correction-details__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 8px;
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    margin: 8px
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-list .box-session-correction-details__item {
        margin: 12px 12px 12px 50px
    }
}

.content-camps .content-corrections-list .box-session-correction-details__item i {
    font-size: 1.5rem;
    color: #f44436;
    margin: 0 20px 0 16px
}

.content-camps .content-corrections-list .box-session-correction-details__item:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-radius: 4px
}

.content-camps .content-corrections-list .box-session-correction-details__item:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background: #8290fd;
    transform: scale(1);
    transition: all .5s .175, .885, .32, 2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.content-camps .content-corrections-list .box-session-correction-details__item--done:after {
    background: #49e2af
}

.content-camps .content-corrections-list .box-session-correction-details__item--progress:after {
    background: #8290fd
}

.content-camps .content-corrections-list .box-session-correction-details.active .box-session-correction-details__arrow {
    transform: translateY(-50%) rotate(180deg)
}

.content-camps .content-corrections-list .box-session-correction-details.active .box-session-correction-details__arrow i {
    color: #7d83aa
}

.content-camps .content-corrections-list .box-session-correction-details.active .box-session-correction-details__list {
    display: block
}

.content-camps .content-corrections-details {
    margin: -36px 0 0;
    padding-left: 0
}

@media screen and (min-width:64rem) {
    .content-camps .content-corrections-details {
        padding: 0 0 0 1rem
    }
}

.content-camps .content-corrections-details .content-corrections__main {
    padding: 20px;
    background: #fff;
    margin: 0
}

.content-camps .content-corrections-details .content-corrections__details {
    padding: 20px;
    background: #fbfbfb;
    border-radius: 4px;
    margin: 20px 0
}

.content-camps .content-corrections-details .content-corrections__details p {
    font-size: .875rem;
    color: #7d83aa
}

.content-camps .content-corrections-details .content-corrections__nav {
    margin: 16px 0
}

.content-camps .content-corrections-details .content-corrections__title {
    margin-bottom: 0;
    padding-bottom: 0
}

.content-camps .content-corrections-details .content-corrections__title h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    color: #7d83aa
}

.content-camps .content-corrections-details .content-corrections__title h3 i {
    font-size: 1.25rem;
    color: #7d83aa;
    margin-right: 8px
}

.content-camps .content-corrections-details .content-corrections__title i {
    font-size: 1.5rem;
    color: #535a8b
}

.content-camps .content-corrections-details .content-corrections__evaluation h4 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    color: #7d83aa;
    margin: 16px 0
}

.content-camps .content-corrections-details .content-corrections__input h4 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    color: #7d83aa;
    margin: 16px 0
}

.content-camps .content-corrections-details .content-corrections__evaluation h4:before {
    content: "•";
    font-size: .75rem;
    color: #7d83aa;
    margin-right: 6px
}

.content-camps .content-corrections-details .content-corrections__input h4:before {
    content: "•";
    font-size: .75rem;
    color: #7d83aa;
    margin-right: 6px
}

.content-camps .content-corrections-details .content-corrections__evaluation h4 span {
    font-size: .875rem;
    color: #b2b5cd;
    margin-left: 6px
}

.content-camps .content-corrections-details .content-corrections__input h4 span {
    font-size: .875rem;
    color: #b2b5cd;
    margin-left: 6px
}

.content-camps .content-corrections-details .content-corrections__evaluation h4 msc-button,
.content-camps .content-corrections-details .content-corrections__input h4 msc-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%)
}

.content-camps .content-corrections-details .content-corrections__resources {
    margin: 20px -8px;
    padding-top: 0
}

.content-camps .content-corrections-details .content-corrections__resources>* {
    margin: 0 8px
}

.content-camps .content-corrections-details .content-corrections__search {
    background: #fff
}

.content-camps .content-corrections-details .comment {
    display: flex;
    align-items: flex-start;
    margin: 0 -8px
}

.content-camps .content-corrections-details .comment>* {
    margin: 0 8px
}

.content-camps .content-corrections-details .comment__name {
    font-family: Circular Std Book;
    color: #020d38;
    margin: 8px 0
}

.content-camps .content-corrections-details .comment__paragraph {
    font-size: .875rem;
    color: #b2b5cd
}

.content-camps .content-corrections-details .comment__date {
    margin: 8px 0;
    white-space: nowrap
}

@media screen and (max-width:32rem) {
    .content-camps .content-corrections-temp {
        margin: 2rem 0 0
    }
}

.content-camps .content-corrections-temp__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px
}

.content-camps .content-corrections-temp__actions .action-correction {
    background-color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: .875rem;
    color: #8c91b4;
    cursor: pointer;
    transition: all .3s ease
}

.content-camps .content-corrections-temp__list {
    margin: 1rem 0 0
}

.content-camps .content-corrections-temp__list .list-accounts {
    margin-left: 0
}

.content-camps .content-corrections-temp__list .list-accounts .box {
    margin: 0 0 1rem;
    transition: all .3s ease
}

.content-camps .content-corrections-temp__list .list-accounts .box .title {
    font-size: 1rem;
    margin: 0 0 0 3rem;
    transition: all .3s ease
}

.content-camps .content-corrections-temp__list .list-accounts .box .time {
    color: rgba(211, 213, 228, .9);
    font-size: .75rem;
    margin: 0 0 0 3rem
}

.content-camps .content-corrections-temp__list .list-correction__type {
    font-size: 1.125rem
}

.content-camps .content-corrections-temp__list .list-correction__desc {
    margin: 1rem 0;
    font-size: .875rem;
    color: #8c91b4
}

.content-camps .content-corrections-temp__list .list-correction__desc img {
    width: 100%
}

.content-camps .content-corrections-temp__list .list-correction__media {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 1rem
}

.content-camps .content-corrections-temp__list .list-correction__media .media-item {
    margin: 0 1rem 0 0
}

.content-camps .content-corrections-temp__list .list-correction__media .media-item .resource {
    background-color: rgba(211, 213, 228, .3);
    transition: all .3s ease;
    margin: 1rem 0 0
}

.content-camps .content-corrections-temp__list .list-correction__media .media-item .resource:hover {
    background-color: rgba(211, 213, 228, .5)
}

.content-camps .content-corrections-temp__list .list-correction__feedback .feedback-label {
    margin: 0 0 1rem;
    font-size: 1.125rem;
    color: #4443af
}

.content-camps .content-corrections-temp__list .list-correction__feedback .textarea__content .textarea {
    margin-bottom: 0
}

.content-camps .content-corrections-temp__list .list-correction__note {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    margin-bottom: 20px
}

.content-camps .content-corrections-temp__list .list-correction__note .note-label {
    color: #d3d5e4;
    font-size: .75rem;
    font-style: italic
}

.content-camps .content-corrections-temp__list .list-correction__note .note-input {
    margin: 1rem 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.content-camps .content-corrections-temp__list .list-correction__timeline {
    margin: 1rem 0 0;
    border-top: 1px solid rgba(211, 213, 228, .6)
}

.content-mentoring-find .dynamic-table__subcontent {
    width: 100%
}

.content-mentoring-find .dynamic-table--find__subcontent {
    width: 100%;
    border-top: 1px solid #f7f7fa;
    margin-top: calc(.5em*2.5);
    padding-top: calc(.5em*2.5)
}

.content-mentoring-find .dynamic-table--find__subcontent p {
    font-size: .875rem;
    color: #7d83aa
}

.content-mentoring-find .dynamic-table__body--find .icon-arrow-ios-down {
    font-size: 1.125rem;
    color: #8c91b4;
    transform: scale(1);
    transition: all .25s ease
}

.content-mentoring-find .dynamic-table__body--find .icon-arrow-ios-down:hover {
    color: #020d38;
    transform: scale(1.3)
}

.content-mentoring-find .dynamic-table__body--find .icon-arrow-ios-down:before {
    transform: rotate(0deg);
    transition: all .25s ease
}

.content-mentoring-find .cell-actions {
    border-left: 0 !important;
    position: static !important;
    transform: none !important;
    justify-content: flex-end
}

.content-mentoring-find .block-mentor__checkbox {
    display: flex
}

.content-mentoring-find .block-mentor__checkbox .checkbox__container {
    display: flex;
    margin: 0 1rem
}

.content-mentoring-find .cell-actions--find {
    justify-content: flex-end !important
}

.content-mentoring-find .dynamic-table__cell--find-mentor {
    width: 2rem
}

.content-mentoring-find .dynamic-table__cell--skills {
    width: 15%
}

.content-mentoring-find .table-find-mentor .dynamic-table__row {
    padding-right: 0 !important
}

.content-mentoring-find .table-find-mentor .dynamic-table__row.active .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.content-mentoring-find .blue {
    border: 1px solid #5150c4 !important
}

.content-mentoring-find .dynamic-table__cell {
    width: 50% !important
}

.dynamic-table__cell--display {
    padding-right: 30px !important
}

.content-camps .content-mentoring__header {
    margin: calc(.5em*4) calc(calc(.5em*1.5)*-1) calc(.5em*3.5) calc(calc(.5em*1.5)*-1)
}

.content-camps .content-mentoring__header>* {
    margin: 0 calc(.5em*1.5)
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring__header {
        margin-top: 0
    }
}

.content-camps .content-mentoring__search {
    max-width: 100%
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring__search {
        max-width: 210px
    }
}

.content-camps .content-mentoring__box {
    display: block
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring__box {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

.content-camps .content-mentoring__avatar {
    position: relative;
    z-index: 1;
    font-size: .75rem;
    margin-bottom: calc(.5em*3.5)
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring__avatar {
        margin-bottom: 0
    }
}

.content-camps .content-mentoring__avatar>div {
    margin-left: calc(.5em*2)
}

.content-camps .content-mentoring__avatar .profile__name {
    color: #17105a;
    margin-bottom: 3px
}

.content-camps .content-mentoring__avatar .profile__job {
    color: #b2b5cd
}

.content-camps .content-mentoring__avatar--ltr>div {
    margin-left: 0;
    margin-right: calc(.5em*2)
}

.content-camps .content-mentoring__skills {
    max-width: 80%
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring__skills {
        max-width: 100%
    }
}

.content-camps .content-mentoring__swap {
    cursor: pointer;
    position: relative;
    z-index: 5;
    width: 28px;
    height: 28px;
    min-width: 28px;
    margin: 0 calc(calc(.5em*1)*-1);
    border: 2px solid #fff;
    color: #fff;
    border-radius: 50%;
    background-color: #5150c4
}

.content-camps .content-mentoring__trash {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background-color: #fff;
    border-radius: 50%;
    top: -12px;
    right: 16px
}

.content-camps .content-mentoring__trash i {
    color: #656c9b;
    font-size: .75rem;
    transition: color .25s ease
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring__trash {
        top: 16px;
        right: 20px
    }

    .content-camps .content-mentoring__trash i {
        font-size: 1rem
    }
}

.content-camps .content-mentoring__trash:hover i {
    color: #020d38
}

.content-camps .content-mentoring__status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: calc(.5em*2);
    font-size: 1rem;
    color: #fff;
    border-radius: 50%;
    background-color: #5150c4
}

.content-camps .content-mentoring__status-icon.red {
    background-color: #ffa2a2
}

.content-camps .content-mentoring__status-icon.green {
    background-color: #49e2af
}

.content-camps .content-mentoring__status-icon.blue {
    background-color: #6c6bd4
}

.content-camps .content-mentoring__status-text {
    margin: calc(calc(.5em*1)*-1) 0
}

.content-camps .content-mentoring__status-text p {
    font-size: .875rem;
    color: #020d38;
    margin: calc(.5em*1) 0
}

.content-camps .content-mentoring__status-text span {
    display: block;
    font-size: .75rem;
    margin: calc(.5em*1) 0
}

.content-camps .content-mentoring__item {
    display: block
}

.content-camps .content-mentoring__relationship .content-mentoring__avatar {
    margin-bottom: 0
}

.content-camps .content-mentoring.content-mentoring-find {
    position: relative
}

.content-camps .content-mentoring.content-mentoring-find .content-mentoring__header .dynamic-table__cell {
    width: auto !important
}

.content-camps .content-mentoring.content-mentoring-find .content-mentoring__header .dynamic-table__cell:last-child {
    max-width: 18px !important
}

.content-camps .content-mentoring.content-mentoring-find .content-mentoring__back {
    position: absolute;
    top: 0;
    left: 0
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-find .content-mentoring__back {
        left: -54px
    }
}

.content-camps .content-mentoring.content-mentoring-find .content-mentoring__header {
    margin: calc(.5em*3) 0 calc(.5em*3) 0;
    padding-bottom: calc(.5em*3);
    padding-top: calc(.5em*4.5);
    border-bottom: 1px solid rgba(211, 213, 228, .5)
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-find .content-mentoring__header {
        margin: 0 0 calc(.5em*3) 0;
        padding-top: 0
    }
}

.content-camps .content-mentoring.content-mentoring-find .content-mentoring__header .box {
    display: block;
    margin: 0 0 calc(.5em*3) 0
}

.content-camps .content-mentoring.content-mentoring-find .content-mentoring__header .box>* {
    margin: 0 calc(.5em*3)
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-find .content-mentoring__header .box {
        display: flex
    }
}

.content-camps .content-mentoring.content-mentoring-find .content-mentoring__header p {
    color: #535a8b
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__subcontent {
    border-top: 1px solid #f7f7fa;
    margin-top: calc(.5em*2.5);
    padding-top: calc(.5em*2.5)
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__subcontent p {
    font-size: .875rem;
    color: #7d83aa
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__subcontent.active {
    display: block
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .disabled {
    background-color: #d3d5e4;
    padding: 1.5rem;
    align-items: center
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .disabled p {
    font-size: .875rem;
    color: #020d38
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .disabled msc-button {
    margin-left: 1rem
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__row {
    display: flex;
    flex-direction: column;
    border: 1px solid #d3d5e4;
    padding-bottom: 60px
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__row {
        padding-bottom: 8px
    }
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__row.active {
    border: 1px solid #4c77ff
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__row.active .dynamic-table--find__subcontent {
    display: block
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__row.disabled .cell-actions {
    border-left: none
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__row.disabled .cell-actions>* {
    margin: 0 18px !important
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__row.disabled .cell-actions>* {
        margin: 0 4px !important
    }
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__content {
    display: block;
    padding-bottom: calc(.5em*2)
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__content {
        display: flex;
        padding-bottom: 0
    }
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table--find__subcontent {
    display: none
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell {
    width: 100%;
    margin: calc(.5em*2) 0
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell {
        margin: auto;
        width: auto;
        padding-bottom: 0
    }
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto !important;
    border-left: none;
    margin-left: 0;
    padding-left: 0;
    margin-right: calc(.5em*3)
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find {
        left: auto;
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
        border-left: 1px solid #b2b5cd;
        margin-left: calc(.5em*2.5);
        padding-left: calc(.5em*2.5);
        margin-right: 0
    }
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find>* {
    margin: -12px 18px 12px
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find>* {
        margin: 0 4px
    }
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    transition: all .25s ease
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find .close .icon-trash {
    margin: 0;
    color: #b2b5cd;
    font-size: .875rem;
    transition: all .25s ease
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find .close:hover {
    border-color: #8c91b4
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find .close:hover .icon-trash {
    color: #656c9b
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find .swap {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100% !important;
    width: 32px;
    height: 32px;
    padding: 5px;
    background-color: #feb88a;
    color: #fff;
    transition: all .5s ease
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--find .swap:hover {
    background-color: #db7564
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__body--find .dynamic-table__cell.cell-actions--add {
    min-width: 90px
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell--find {
    border-left: 1px solid #d3d5e4;
    padding-left: calc(.5em*2);
    margin-right: calc(calc(.5em*2)*-1)
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell--find>* {
    margin: 0 calc(.5em*1)
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell--find .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: 2px solid #d3d5e4;
    border-radius: 50%;
    transition: all .25s ease
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell--find .close i {
    margin: 0;
    color: #b2b5cd;
    font-size: .875rem;
    transition: all .25s ease
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell--find .close:hover {
    border-color: #8c91b4
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell--find .close:hover i {
    color: #656c9b
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell--find msc-button {
    box-shadow: none;
    transition: all .25s ease
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell--find msc-button:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1)
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell {
    padding-left: 0
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell msc-button {
    width: auto
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell msc-button--circle {
    width: 32px
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell--text {
    width: 3rem !important
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell.cell-actions {
    transform: rotate(0);
    transition: all .25s ease-in-out
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell.cell-actions.active {
    transform: rotate(180deg)
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__cell.cell-medium .tooltip {
    color: #020d38
}

.content-camps .content-mentoring.content-mentoring-find .dynamic-table__head .dynamic-table__cell {
    padding-left: 0
}

.content-camps .content-mentoring .dynamic-table .content-mentoring__skills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-right: -4px;
    margin-left: -4px
}

.content-camps .content-mentoring .dynamic-table .content-mentoring__skills>* {
    margin: 4px !important
}

.content-camps .content-mentoring .dynamic-table.table-mentor .avatar,
.content-camps .content-mentoring .dynamic-table.table-relationship .avatar {
    min-width: 28px;
    width: 28px;
    height: 28px
}

@media screen and (min-width:32rem) {

    .content-camps .content-mentoring .dynamic-table.table-mentor .avatar,
    .content-camps .content-mentoring .dynamic-table.table-relationship .avatar {
        min-width: 42px;
        width: 42px;
        height: 42px
    }
}

.content-camps .content-mentoring .dynamic-table.table-mentor .profile__job,
.content-camps .content-mentoring .dynamic-table.table-relationship .profile__job {
    display: none
}

@media screen and (min-width:32rem) {

    .content-camps .content-mentoring .dynamic-table.table-mentor .profile__job,
    .content-camps .content-mentoring .dynamic-table.table-relationship .profile__job {
        display: block
    }
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__content.active+.dynamic-table__subcontent,
.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__content.active+.dynamic-table__subcontent {
    display: block
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__content.active .cell-actions .icon-arrow-ios-down {
    color: #020d38
}

.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__content.active .cell-actions .icon-arrow-ios-down {
    color: #020d38
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__content.active .cell-actions .icon-arrow-ios-down:before,
.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__content.active .cell-actions .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__header.active+.dynamic-table__subcontent,
.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__header.active+.dynamic-table__subcontent {
    display: block
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__header.active .cell-actions .icon-arrow-ios-down {
    color: #020d38
}

.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__header.active .cell-actions .icon-arrow-ios-down {
    color: #020d38
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__header.active .cell-actions .icon-arrow-ios-down:before,
.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__header.active .cell-actions .icon-arrow-ios-down:before {
    transform: rotate(180deg)
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__cell.cell-actions,
.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__cell.cell-actions {
    transition: all .25s ease
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__cell.cell-actions .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100% !important;
    width: 32px;
    height: 32px;
    padding: 5px;
    margin: 0 4px;
    background-color: #feb88a;
    color: #fff;
    transition: all .5s ease
}

.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__cell.cell-actions .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100% !important;
    width: 32px;
    height: 32px;
    padding: 5px;
    margin: 0 4px;
    background-color: #feb88a;
    color: #fff;
    transition: all .5s ease
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__cell.cell-actions .circle i {
    color: #fff;
    font-size: .875rem
}

.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__cell.cell-actions .circle i {
    color: #fff;
    font-size: .875rem
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__cell.cell-actions .circle:hover {
    background-color: #db7564
}

.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__cell.cell-actions .circle:hover {
    background-color: #db7564
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__cell.cell-actions .circle:hover i {
    color: #fff !important;
    font-size: .875rem !important
}

.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__cell.cell-actions .circle:hover i {
    color: #fff !important;
    font-size: .875rem !important
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__cell.cell-actions .icon-arrow-ios-down:before,
.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__cell.cell-actions .icon-arrow-ios-down:before {
    transform: rotate(0deg);
    transition: all .25s ease
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__cell.cell-actions i {
    font-size: 1.125rem;
    color: #8c91b4;
    transform: scale(1);
    transition: all .25s ease
}

.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__cell.cell-actions i {
    font-size: 1.125rem;
    color: #8c91b4;
    transform: scale(1);
    transition: all .25s ease
}

.content-camps .content-mentoring .dynamic-table.table-mentor .dynamic-table__cell.cell-actions i:hover {
    color: #020d38;
    transform: scale(1.3)
}

.content-camps .content-mentoring .dynamic-table.table-relationship .dynamic-table__cell.cell-actions i:hover {
    color: #020d38;
    transform: scale(1.3)
}

.content-camps .content-mentoring .dynamic-table.table-newsfeed .dynamic-table__cell:last-child {
    color: #020d38
}

.content-camps .content-mentoring .dynamic-table.table-newsfeed .dynamic-table__cell.cell-large {
    width: calc(100% - 30px)
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring .dynamic-table.table-newsfeed .dynamic-table__cell.cell-large {
        width: 30%
    }
}

.content-camps .content-mentoring .dynamic-table.table-newsfeed .dynamic-table__cell.cell-icon {
    width: 30px
}

.content-camps .content-mentoring .dynamic-table.table-newsfeed .dynamic-table__cell.cell-icon .content-mentoring__status-icon {
    margin-right: 0
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring .dynamic-table.table-newsfeed .dynamic-table__cell.cell-icon {
        width: 30%
    }

    .content-camps .content-mentoring .dynamic-table.table-newsfeed .dynamic-table__cell.cell-icon .content-mentoring__status-icon {
        margin-right: calc(.5em*2)
    }
}

.content-camps .content-mentoring .dynamic-table.table-newsfeed .dynamic-table__row {
    justify-content: space-between
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring .dynamic-table.table-newsfeed .dynamic-table__row {
        justify-content: flex-start
    }
}

.content-camps .content-mentoring .dynamic-table.table-find-mentor .dynamic-table__head {
    margin-left: 0
}

.content-camps .content-mentoring .dynamic-table.table-find-mentor .dynamic-table__head .no-border {
    border: none
}

.content-camps .content-mentoring .dynamic-table.table-find-mentor .dynamic-table__head .dynamic-table__cell {
    padding-left: 0
}

.content-camps .content-mentoring .dynamic-table.table-find-mentor .dynamic-table__head .dynamic-table__cell.cell-small {
    width: 5.5%
}

.content-camps .content-mentoring .dynamic-table.table-find-mentor .dynamic-table__body .dynamic-table__cell:nth-child(2) {
    margin-right: 1rem
}

.content-camps .content-mentoring .dynamic-table.table-find-mentor .dynamic-table__row {
    padding-right: 0
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring .dynamic-table.table-find-mentor .dynamic-table__row {
        padding-right: 195px
    }
}

.content-camps .content-mentoring .dynamic-table.table-find-mentor .content-mentoring__skills {
    width: 80%
}

.content-camps .content-mentoring .dynamic-table .skill--show {
    cursor: pointer
}

.content-camps .content-mentoring.content-mentoring-add .dynamic-table__head {
    position: relative;
    padding-left: 45px
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-add .dynamic-table__head {
        padding-left: 60px
    }
}

.content-camps .content-mentoring.content-mentoring-add .dynamic-table__head .cell-form {
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%)
}

.content-camps .content-mentoring.content-mentoring-add .dynamic-table__head .dynamic-table__cell:last-child {
    border: none;
    padding-left: 0;
    margin-left: 0
}

.content-camps .content-mentoring.content-mentoring-add .dynamic-table__row {
    position: relative;
    margin-left: 45px;
    cursor: pointer
}

@media screen and (min-width:32rem) {
    .content-camps .content-mentoring.content-mentoring-add .dynamic-table__row {
        margin-left: 60px
    }
}

.content-camps .content-mentoring.content-mentoring-add .dynamic-table__row .cell-form {
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%)
}

@media (-ms-high-contrast:none) {

    .content-camps .dynamic-table.table-relationship .dynamic-table__cell.cell-actions,
    ::-ms-backdrop {
        top: 16px
    }
}

@media (-ms-high-contrast:none) {

    .content-camps.dynamic-table.table-mentor .dynamic-table__cell.cell-actions,
    ::-ms-backdrop {
        top: 20px
    }
}

@media (-ms-high-contrast:none) {

    .content-camps .content-mentoring__status-icon,
    ::-ms-backdrop {
        margin-top: 3px
    }
}

.table-newsfeed .dynamic-table__subcontent--title {
    margin: 1rem 0
}

.table-newsfeed .dynamic-table__cell--relation-newsfeed {
    width: 60% !important
}

.table-newsfeed .content-mentoring__avatar--ltr {
    width: 12rem !important
}

.table-newsfeed .content-mentoring__status-icon {
    margin-right: -5px !important;
    margin-left: -5px !important
}

.table-newsfeed .content-mentoring__avatar--ltr .avatar {
    margin-right: 0 !important
}

.table-newsfeed .content-mentoring__avatar .avatar {
    margin-left: 0 !important
}

.table-newsfeed .content-mentoring__text {
    margin-left: 1rem
}

.content-params {
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.content-params__button {
    width: 80%;
    border-top: 1px solid #d3d5e4
}

.content-params__button msc-button {
    margin-top: 1rem
}

.content-params__button msc-button i:before {
    font-size: 1rem
}

.content-params {
    position: relative;
    margin: calc(.5em*3.5) 0;
    padding: calc(.5em*3);
    background-color: #fff
}

.content-params-functionalities,
.content-params-notification {
    background-color: transparent;
    padding: 0;
    border-radius: 0 !important;
    box-shadow: none !important
}

.content-params-functionalities .content-params__list {
    margin: 1rem 0 0
}

.content-params-functionalities .content-params__notifications {
    margin-left: 0
}

/*to 512px*/
@media screen and (min-width:32rem) {
    .content-params {
        margin: calc(.5em*3.5) 0;
    }
}

/*to 768px*/
@media screen and (max-width:768px) {
    .content-params__header {
        flex-direction: column;
        gap: 1rem;
    }

    .content-params .tabs {
        flex-direction: column;
    }
}

.content-params__infos.tooltip--left:after {
    top: -6px
}

.content-params__header {
    border-bottom: 1px solid #b2b5cd;
    margin: calc(.5em*3) 0;
    padding-bottom: calc(.5em*1.5)
}

@media screen and (min-width:32rem) {
    .content-params__header {
        margin: calc(.5em*1.5) 0
    }
}

.content-params__title {
    font-size: 1rem;
    color: #7d83aa
}

.content-params__all p {
    margin-right: calc(.5em*2.5);
    font-size: .875rem;
    color: #535a8b
}

.content-params__fieldset {
    margin-bottom: calc(.5em*3)
}

.content-params__fieldset .input__label {
    display: block;
    color: #b2b5cd;
    margin-bottom: calc(.5em*1);
    font-size: .875rem
}

.content-params__checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 calc(calc(.5em*2.5)*-1)
}

.content-params__checkbox>* {
    margin: 0 calc(.5em*2.5)
}

.content-params__infos {
    position: absolute;
    top: 4px;
    right: 6px
}

.content-params__infos i {
    font-size: 1.125rem;
    color: #5150c4
}

.content-params__notifications {
    min-height: 70px;
    padding: calc(.5em*2.5) calc(.5em*3);
    margin-top: calc(.5em*3);
    margin-bottom: calc(.5em*3)
}

@media screen and (min-width:32rem) {
    .content-params__notifications {
        margin-top: calc(.5em*1.5);
        margin-bottom: calc(.5em*1.5)
    }
}

.content-params .tabs {
    display: flex
}

@media screen and (max-width:32rem) {
    .content-params .tabs {
        flex-direction: column
    }
}

@media screen and (min-width:64rem) {
    .content-params .tabs__aside {
        margin-left: 0 !important
    }
}

.content-params .tabs__header {
    cursor: pointer;
    position: relative;
    border: 1px solid #d3d5e4;
    transform: scale(1);
    margin: calc(.5em*2) 0;
    color: #b2b5cd;
    width: 100%;
    transition: .3s cubic-bezier(.175, .885, .32, 2)
}

.content-params .tabs__header:first-child {
    margin-top: 0
}

.content-params .tabs__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(.5em*3);
    padding-right: 0
}

@media screen and (min-width:32rem) {
    .content-params .tabs__title {
        padding-right: calc(.5em*3)
    }
}

.content-params .tabs__title a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 1.25rem;
    border: 1px solid #7d83aa;
    width: 34px;
    height: 34px;
    transition: all .2s cubic-bezier(.77, 0, .175, 1)
}

.content-params .tabs__infos {
    color: #b2b5cd
}

.content-params .tabs__arrow {
    color: #282d58
}

.content-params .tabs__body {
    width: 100%;
    min-height: 60vh;
    padding: calc(.5em*2);
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

@media screen and (min-width:64rem) {
    .content-params .tabs__body {
        margin-right: 0 !important
    }
}

.content-params .box-notification {
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;
    transform: scale(1);
    transition: .2s cubic-bezier(.175, .885, .32, 2);
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1)
}

.content-params .box-notification__title {
    font-size: .875rem;
    color: #535a8b;
    margin-bottom: calc(.5em*1)
}

.content-params .box-notification__actions .icon {
    font-size: 1.375rem;
    color: #7d83aa;
    transition: .2s ease
}

.content-params .box-notification--hover.active {
    border-color: #4c77ff
}

.content-params .box-notification--hover:hover {
    border-color: #4c77ff
}

.content-params .box-notification-settings {
    border: 1px solid #d3d5e4;
    border-radius: 4px;
    padding-left: calc(.5em*3);
    padding-right: calc(.5em*3);
    margin-bottom: calc(.5em*2.5)
}

.content-params .box-notification-settings__content {
    width: 70%
}

.content-params .box-notification-settings__title {
    font-size: .875rem;
    color: #535a8b;
    margin-bottom: calc(.5em*1.5)
}

.content-params .box-notification-settings__desc {
    font-style: italic;
    font-size: .75rem;
    color: #d3d5e4
}

.container-params .navigation-camps {
    width: 100%;
    margin-bottom: calc(.5em*3)
}

@media screen and (min-width:32rem) {
    .container-params .navigation-camps {
        width: 19%
    }
}

.content-wallet-feature__main {
    margin-top: 0
}

.content-wallet-feature__main .block-wallet-balance {
    position: relative;
    padding: 40px;
    border-radius: 4px;
    background-color: #fff;
    margin: 0 0 1rem
}

.content-wallet-feature__main .block-wallet-balance__head {
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    display: flex
}

.content-wallet-feature__main .block-wallet-balance__card {
    width: 65px;
    height: 55px;
    background-color: #feb88a;
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center
}

.content-wallet-feature__main .block-wallet-balance__card i {
    color: #fff;
    font-size: 2.5rem
}

.content-wallet-feature__main .block-wallet-balance__data {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 1rem
}

.content-wallet-feature__main .block-wallet-balance__data .data-balance__text {
    color: #656c9b
}

.content-wallet-feature__main .block-wallet-balance__data .data-balance__figure {
    color: #656c9b;
    font-size: 1.375rem;
    font-weight: 600
}

.content-wallet-feature__main .block-wallet-balance__body {
    display: flex;
    align-items: center
}

.content-wallet-feature__main .block-wallet-balance__body .amount-limit {
    margin: 0 1rem 0 0;
    color: #656c9b
}

.content-wallet-feature__main .block-wallet-balance__body .input {
    height: 35px
}

.content-wallet-feature__main .block-wallet-balance__body .input__icon {
    height: 35px;
    padding: 0 8px;
    cursor: pointer;
    display: flex;
    align-items: center
}

.content-wallet-feature__main .block-wallet-balance__body .input__icon i {
    transition: all .3s ease
}

.content-wallet-feature__main .block-wallet-balance__body .input__icon:hover i {
    color: #343392;
    transition: all .3s ease
}

.content-wallet-feature__main .block-wallet-feature {
    position: relative;
    padding: 40px;
    border-radius: 4px;
    background-color: #fff
}

.content-wallet-feature__main .block-wallet-feature__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    padding: 0 0 1rem;
    margin: 0 0 1rem
}

.content-wallet-feature__main .block-wallet-feature__head h1 {
    color: #414776;
    font-size: 1.125rem;
    font-weight: 600
}

.content-wallet-feature__main .block-wallet-feature .feature-text {
    color: #656c9b
}

.content-wallet-feature__main .block-wallet-feature .feature-text p {
    margin: 0 0 1rem
}

.content-wallet-feature__main .block-wallet-feature .feature-form {
    width: 65%
}

.content-wallet-feature__main .block-wallet-feature .feature-form__item {
    margin: 0 0 20px
}

.content-wallet-feature__main .block-wallet-feature .feature-form--margin {
    margin: 2rem 0 0
}

.content-wallet-feature__main .block-wallet-feature .weavr-pin {
    margin: 2rem 0 0;
    width: 250px
}

.content-wallet-feature__main .block-wallet-feature .list-camp__list {
    display: flex;
    flex-wrap: wrap
}

.content-wallet-feature__main .block-wallet-feature .list-camp__list .card-camp {
    box-shadow: none;
    border-bottom: 1px solid #d3d5e4;
    margin: 1rem .5rem 0
}

.content-wallet-feature__main .block-wallet-feature .list-camp__list .card-camp:last-child {
    border: none
}

.content-wallet-feature__main .block-wallet-feature .list-camp__list .card-camp__content .content__logo i {
    width: 40px;
    height: 40px;
    font-size: 1.5rem
}

.content-wallet-feature__main .block-wallet-feature .list-camp__list .card-camp__content .content__data p {
    color: #656c9b;
    font-size: 1.25rem
}

.content-wallet-feature__main .block-wallet-feature .list-camp__list .card-camp__content .content__data .data__pictos {
    margin: .5rem 0 0
}

.content-wallet-feature__aside {
    margin-top: 0
}

.content-wallet-feature__aside .block-wallet-status {
    position: relative;
    padding: 20px;
    border-radius: 4px;
    background-color: #fff
}

.content-wallet-feature__aside .block-wallet-status__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    padding: 0 0 1rem;
    margin: 0 0 1rem
}

.content-wallet-feature__aside .block-wallet-status__head h1 {
    color: #656c9b;
    font-size: 1.125rem;
    display: flex;
    align-items: center
}

.content-wallet-feature__aside .block-wallet-status__head h1 i {
    color: #6cd4a8;
    font-size: 1.75rem;
    margin: 0 1rem 0 0
}

.content-wallet-feature__aside .block-wallet-status__body div {
    color: #656c9b;
    margin: 0 0 1rem
}

.content-wallet__table .cell-price {
    display: flex;
    align-items: center;
    font-size: .875rem;
    font-weight: 500
}

.content-wallet__table .cell-price div {
    margin-right: .5rem
}

.content-wallet__table .cell-price div i {
    color: #feb88a;
    font-size: 1.125rem
}

.content-wallet__table .cell-button msc-button {
    display: flex;
    align-items: center;
    padding: 6px 10px 6px 6px
}

.content-wallet__table .cell-button msc-button i {
    margin-right: 6px;
    font-size: 1.125rem
}

.content-wallet__table .cell-button msc-button:first-child {
    margin-right: .5rem
}

.content-wallet .logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f8f9ff;
    margin-right: 16px
}

.content-wallet .logo img {
    max-width: 90%;
    height: auto
}

.content-wallet .logo span {
    cursor: pointer
}

.animate {
    -webkit-animation: fadeInUp 1s cubic-bezier(.77, 0, .175, 1);
    animation: fadeInUp 1s cubic-bezier(.77, 0, .175, 1)
}

.animate--fast {
    -webkit-animation: fadeInUp .5s cubic-bezier(.77, 0, .175, 1);
    animation: fadeInUp .5s cubic-bezier(.77, 0, .175, 1)
}

.animate--base {
    -webkit-animation: fadeInUp 1s cubic-bezier(.77, 0, .175, 1);
    animation: fadeInUp 1s cubic-bezier(.77, 0, .175, 1)
}

.animate--slow {
    -webkit-animation: fadeInUp 1.5s cubic-bezier(.77, 0, .175, 1);
    animation: fadeInUp 1.5s cubic-bezier(.77, 0, .175, 1)
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 30px, 0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 30px, 0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@-webkit-keyframes spinner {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

@-webkit-keyframes pulse {
    0% {
        transform: scale(.95);
        box-shadow: 0 0 0 0 rgba(254, 184, 138, .3)
    }

    70% {
        transform: scale(1.22);
        box-shadow: 0 0 0 10px rgba(254, 184, 138, .75)
    }

    to {
        transform: scale(1);
        box-shadow: 0 0 0 0 #feb88a
    }
}

@keyframes pulse {
    0% {
        transform: scale(.95);
        box-shadow: 0 0 0 0 rgba(254, 184, 138, .3)
    }

    70% {
        transform: scale(1.22);
        box-shadow: 0 0 0 10px rgba(254, 184, 138, .75)
    }

    to {
        transform: scale(1);
        box-shadow: 0 0 0 0 #feb88a
    }
}

.container {
    transition: margin-left .25s ease;
    height: 100vh;
    max-width: calc(100% - calc(.5em*3));
    margin-right: auto;
    margin-left: auto
}

@media screen and (min-width:20rem) {
    .container {
        padding: 100px 0 0
    }
}

@media screen and (min-width:80rem) {
    .container {
        padding: 100px 25px 40px 310px
    }
}

.container__title {
    font-size: 1rem;
    font-weight: 300;
    color: #020d38;
    margin-bottom: 20px
}

.container__title--left {
    text-align: left
}

.container__desc {
    margin-bottom: 3.5rem;
    border-radius: 8px;
}

.container__list {
    margin: -30px 0
}

.container__item {
    margin: 30px 0
}

.container-community {
    padding: 80px 0 0;
    max-width: 100%
}

@media screen and (min-width:80rem) {
    .container-community {
        padding: 80px 0 40px 285px
    }
}

.container-community header {
    margin-bottom: 60px
}

.container-community__content {
    text-align: center;
    padding: 0 50px
}

@media screen and (min-width:20rem) {
    .container-community__content {
        padding: 0
    }
}

@media screen and (min-width:48rem) {
    .container-community__content {
        padding: 0 50px
    }
}

.container-community__content>div {
    text-align: center
}

@media screen and (min-width:20rem) {
    .container-community__aside {
        margin-top: 2.5rem
    }
}

@media screen and (min-width:80rem) {
    .container-community__aside {
        margin-top: 2rem
    }
}

.container-community__details {
    margin-bottom: 32px
}

.content-camps__title {
    margin: 0 0 1.5rem
}

.content-camps__list {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(2, 13, 56, .08)
}

.content-experience__title {
    font-size: 1.5rem;
    font-weight: 300;
    color: #414776;
    margin-bottom: 32px
}

.content-knowledge__title {
    font-size: 1.5rem;
    font-weight: 300;
    color: #414776;
    margin-bottom: 32px
}

.content-sessions__title {
    font-size: 1.5rem;
    font-weight: 300;
    color: #414776;
    margin-bottom: 32px
}

.content-users__title {
    font-size: 1.5rem;
    font-weight: 300;
    color: #414776;
    margin-bottom: 32px
}

.content-experience__list,
.content-knowledge__list,
.content-sessions__list,
.content-users__list {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(2, 13, 56, .08)
}

.content-access {
    padding: 0 1rem
}

.block-section__wrapper {
    position: relative;
    background: #fff;
    border-radius: 4px;
    padding-right: 32px
}

.block-section__header {
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #d3d5e4
}

@media screen and (min-width:32rem) {
    .block-section__header {
        display: flex
    }
}

.block-section__title {
    display: block;
    justify-content: flex-start;
    align-items: center
}

@media screen and (min-width:32rem) {
    .block-section__title {
        display: flex
    }
}

.block-section__link {
    position: absolute;
    right: 8px;
    top: 16px
}

@media screen and (min-width:32rem) {
    .block-section__link {
        position: static
    }
}

.block-section__footer {
    min-height: 36px;
    padding: 8px 32px
}

.block-section__widgets {
    display: block;
    align-items: center;
    justify-content: space-between;
    margin: 8px -32px
}

@media screen and (min-width:32rem) {
    .block-section__widgets {
        display: flex;
        margin: 0 -32px
    }
}

.block-section__widgets>* {
    margin: 8px 32px
}

.block-section__locked {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #d3d5e4
}

.block-section__locked i {
    font-size: 1.125rem;
    color: #7d83aa
}

.block-section__toggle {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 100%;
    cursor: pointer;
    background: rgba(211, 213, 228, .5);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.block-section__toggle i {
    font-size: 1.125rem
}

.block-task {
    position: relative
}

.block-task__wrapper {
    position: relative;
    background: #fff;
    border-radius: 4px
}

.block-task__header {
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #d3d5e4
}

@media screen and (min-width:32rem) {
    .block-task__header {
        display: flex;
        padding: 15px;
    }
}

.block-task__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.block-task__footer {
    min-height: 36px;
    padding: 8px 32px
}

.block-task__widgets {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.block-task__widgets>* {
    margin: 10px
}

.block-task msc-button {
    position: absolute;
    right: -16px;
    top: 16px
}

@media screen and (min-width:32rem) {
    .block-task msc-button {
        position: static
    }
}

.block-task--locked .block-task__widgets i {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #d3d5e4;
    color: #7d83aa;
    display: flex;
    justify-content: center;
    align-items: center
}

.block-task--planned .block-task__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px
}

@media screen and (min-width:32rem) {
    .block-task--planned .block-task__wrapper {
        align-items: center;
        padding: 32px
    }
}

.block-task--planned .block-task__header {
    display: block;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border: none
}

@media screen and (min-width:32rem) {
    .block-task--planned .block-task__header {
        display: flex
    }
}

.block-task--planned .block-task__header>* {
    margin-right: 24px
}

.block-task--planned .block-task__title {
    display: block;
    font-size: .875rem;
    color: #020d38
}

.block-task--planned .block-task__title span {
    display: block;
    font-size: .75rem;
    color: #7d83aa;
    margin-top: 12px
}

.block-task--planned .block-task__title i {
    margin: 0;
    font-size: .75rem;
    color: #7d83aa
}

.block-module {
    position: relative
}

.block-module__wrapper {
    position: relative;
    display: flex;
    background: #fff;
    border-radius: 4px
}

.block-module__image {
    position: relative;
    width: 40%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-size: cover;
    background-position: 50%
}

.block-module__image .tag {
    position: absolute;
    top: 16px;
    left: 24px
}

.block-module__desc {
    width: 60%;
    padding: 24px
}

.block-module__skills {
    margin: 0 -5px
}

.block-module__skills>* {
    margin: 0 5px
}

.block-module__title {
    color: #020d38;
    font-size: 1rem;
    margin: 24px 0 40px
}

.block-module__widgets {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px
}

.content-wallet-history__table .dynamic-table__row.row-with-contents {
    justify-content: center
}

.content-wallet-history__table .cell-justification {
    display: flex;
    align-items: center
}

.content-wallet-history__table .cell-justification i {
    margin: 0 .3rem 0 0;
    font-size: 1.125rem
}

.content-wallet-history__table .cell-actions__down i:before {
    transition: all .3s ease
}

.content-wallet-history__table .cell-actions__down i.active {
    color: #535a8b
}

.content-wallet-history__table .cell-actions__down i.active:before {
    transform: rotate(180deg)
}

.content-wallet-history__table .cell-medium .logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f8f9ff;
    margin-right: 16px
}

.content-wallet-history__table .cell-medium .logo img {
    max-width: 90%;
    height: auto
}

.content-wallet-history__table .cell-medium .logo span {
    cursor: pointer
}

.content-wallet-history__table .cell-profile-data {
    border-top: 1px solid rgba(211, 213, 228, .3);
    width: calc(100% + 60px);
    margin: -12px -40px 1rem;
    padding-top: 12px
}

.content-wallet-history__table .cell-profile-data__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 8px -6px;
    font-size: .875rem;
    line-height: 1.125rem
}

.content-wallet-history__table .cell-profile-data__title>* {
    margin: 0 6px
}

.content-wallet-history__table .cell-profile-data__title i {
    font-size: 1.5rem;
    color: #6cd4a8;
    margin-right: 6px
}

.content-wallet-history__table .cell-profile-data__title i.icon-close {
    color: #f44436
}

.content-wallet-history__table .cell-profile-data__title.pending i {
    font-size: 1.375rem;
    color: #8290fd
}

.content-wallet-history__table .cell-profile-data__user {
    margin: 16px -12px
}

.content-wallet-history__table .cell-profile-data__user>* {
    margin: 0 12px
}

.content-wallet-history__table .cell-profile-data__block {
    margin: 0 60px;
    width: calc(50% - 60px)
}

.content-wallet-history__table .cell-profile-data__files {
    margin: 0 -6px
}

.content-wallet-history__table .cell-profile-data__files>* {
    margin: 0 6px
}

.content-wallet-history__table .cell-profile-data__upload .file-upload {
    margin: .5rem 0 0;
    height: 34px;
    padding-left: 0
}

.content-wallet-history__table .cell-profile-data__upload .file-upload img {
    width: 1rem
}

.content-wallet-history__table .cell-profile-data__buttons {
    margin: .5rem 0 0
}

.content-wallet-history__table .cell-profile-data__buttons msc-button {
    height: 32px
}

.content-wallet-history__table .cell-profile-data__buttons msc-button:last-child {
    margin: 0 0 0 1rem;
    display: inline-flex;
    align-items: center
}

.content-wallet-history__table .cell-profile-data__text {
    border: 1px solid #d8dae6;
    margin: 15px 0;
    padding: 10px 16px;
    font-size: .875rem;
    line-height: 1.125rem;
    color: #7d83aa;
    border-radius: 4px
}

.content-wallet-history__table .cell-profile-data__user {
    margin: 0 -6px
}

.content-wallet-history__table .cell-profile-data__user>* {
    margin: 0 6px
}

.content-wallet-history__table .cell-history-data {
    border-top: 1px solid rgba(211, 213, 228, .3);
    width: calc(100% + 60px);
    margin: 0 -40px;
    padding-top: 12px
}

.content-wallet-history__table .cell-history-data__title {
    margin: 0 0 0 1.5rem
}

.content-wallet-history__table .cell-history-data__title h1 {
    color: #656c9b;
    font-size: .875rem;
    display: flex;
    align-items: center
}

.content-wallet-history__table .cell-history-data__title h1 i {
    margin: 0 .5rem 0 0;
    font-size: 1.25rem
}

.content-wallet-history__table .cell-history-data__list {
    margin: 1rem 3rem 0
}

.content-wallet-history__table .cell-history-data__head,
.content-wallet-history__table .cell-history-data__row {
    display: flex
}

.content-wallet-history__table .cell-history-data__cell {
    width: 20%
}

.content-wallet-history__table .cell-history-data__row {
    margin: 1rem 0 0;
    padding: 0 0 1rem;
    color: #535a8b;
    border-bottom: 1px solid rgba(211, 213, 228, .3)
}

.content-wallet-history__table .cell-history-data__row:last-child {
    border: none
}

.content-wallet-history .dynamic-table__cell {
    align-items: flex-start
}

.content-wallet-history .dynamic-table__content {
    min-height: 45px
}

.content-wallet-history .dynamic-table__subcontent {
    margin-right: 0;
    margin-left: auto
}

@font-face {
    font-family: Circular Std Book;
    font-style: normal;
    font-weight: 400;
    src: local("Circular Std Book"), url(https://static.myskillcamp.com/lxp/fonts/circular/CircularStd-Book.woff) format("woff")
}

@font-face {
    font-family: Circular Std Black;
    font-style: normal;
    font-weight: 400;
    src: local("Circular Std Book"), url(https://static.myskillcamp.com/lxp/fonts/circular/CircularStd-Black.woff) format("woff")
}

@font-face {
    font-family: Circular Std Bold;
    font-style: normal;
    font-weight: 400;
    src: local("Circular Std Book"), url(https://static.myskillcamp.com/lxp/fonts/circular/CircularStd-Bold.woff) format("woff")
}

@font-face {
    font-family: Circular Std Medium;
    font-style: normal;
    font-weight: 400;
    src: local("Circular Std Book"), url(https://static.myskillcamp.com/lxp/fonts/circular/CircularStd-Medium.woff) format("woff")
}

.content-wallet-timeline__menu {
    border-bottom: 1px solid rgba(211, 213, 228, .5);
    margin-bottom: calc(.5em*2);
    padding-bottom: calc(.5em*2)
}

.content-wallet-timeline__timeline {
    position: relative;
    margin: calc(.5em*2) 0
}

.content-wallet-timeline__timeline:after {
    position: absolute;
    top: calc(.5em*1);
    left: 45%;
    content: "";
    height: 100%;
    width: 2px;
    background-color: rgba(211, 213, 228, 5)
}

.content-wallet-timeline .transaction {
    position: relative;
    width: 100%;
    margin: calc(.5em*1.5) 0
}

.content-wallet-timeline .transaction:before {
    position: absolute;
    top: 34px;
    left: calc(45% - 50px);
    content: "";
    width: 50px;
    height: 2px;
    background-color: rgba(211, 213, 228, .5)
}

.content-wallet-timeline .transaction p {
    font-size: .875rem;
    color: #8c91b4
}

.content-wallet-timeline .transaction p span {
    display: block
}

.content-wallet-timeline .transaction__header {
    position: relative;
    background-color: #fff;
    border: 1px solid rgba(211, 213, 228, .75);
    border-radius: 4px;
    padding: calc(.5em*1);
    min-height: 68px
}

.content-wallet-timeline .transaction__header:before {
    content: "";
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -61px;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff
}

.content-wallet-timeline .transaction__header:after {
    content: "";
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -56px;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #818dff
}

.content-wallet-timeline .transaction__header.active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0
}

.content-wallet-timeline .transaction__header.active+.transaction__body {
    display: block
}

.content-wallet-timeline .transaction__purchases {
    margin: 0 calc(.5em*2.5);
    width: 150px
}

.content-wallet-timeline .transaction__purchases p {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.content-wallet-timeline .transaction__purchases p>* {
    margin-right: calc(.5em*1.5)
}

.content-wallet-timeline .transaction__purchases i {
    font-size: 1.25rem
}

.content-wallet-timeline .transaction__purchases span {
    color: #ffa2a2
}

.content-wallet-timeline .transaction__action {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%)
}

.content-wallet-timeline .transaction__action i {
    font-size: 1.5rem;
    color: rgba(211, 213, 228, .75)
}

.content-wallet-timeline .transaction__action i:before {
    transform: rotate(0deg);
    transition: all .25s ease-in-out
}

.content-wallet-timeline .transaction__action.active {
    transform: translateY(-50%)
}

.content-wallet-timeline .transaction__action.active i:before {
    transform: rotate(180deg)
}

.content-wallet-timeline .transaction__body {
    display: none;
    background-color: #fff;
    padding: calc(.5em*1.5);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid rgba(211, 213, 228, .75);
    border-top: none;
    margin: calc(calc(.5em*2.5)*-1) 0;
    margin-bottom: calc(.5em*2.5)
}

.content-wallet-timeline .transaction__body .transaction__infos {
    position: relative;
    background-color: transparent
}

.content-wallet-timeline .transaction__body .transaction__infos:after {
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 1px;
    height: 40px;
    background-color: rgba(211, 213, 228, .75)
}

.content-wallet-timeline .transaction__item {
    padding-top: calc(.5em*2.5);
    padding-bottom: calc(.5em*2.5);
    border-bottom: 1px solid rgba(211, 213, 228, .75)
}

.content-wallet-timeline .transaction__item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.content-wallet-timeline .transaction__bank {
    margin: 0 calc(.5em*2.5)
}

.content-wallet-timeline .transaction__bank p {
    font-size: .625rem;
    margin: calc(.5em*1) 0
}

.content-wallet-timeline .transaction__reference {
    margin: 0 calc(.5em*1.5)
}

.content-wallet-timeline .transaction__reference p {
    color: #c2c5d8;
    font-size: .625rem;
    line-height: .875rem;
    font-style: italic
}

.content-wallet-timeline .transaction__reference p:last-child {
    font-size: 1.125rem;
    font-style: normal
}

.content-wallet-timeline .transaction__infos {
    min-width: 120px;
    height: 100%;
    padding: calc(.5em*1) calc(.5em*0.5);
    margin: -8px;
    background-color: rgba(211, 213, 228, .4);
    text-align: center
}

.content-wallet-timeline .transaction__infos p {
    color: #7d83aa;
    font-size: .875rem;
    line-height: 1.125rem
}

.content-wallet-timeline .transaction__infos p span {
    color: #7d83aa;
    font-size: 1rem
}

.content-wallet-timeline .transaction__infos p span:last-child {
    color: #b2b5cd;
    font-size: .625rem
}

.content-wallet-timeline .transaction__avatar {
    margin: 0 calc(.5em*2);
    width: 140px;
    max-width: 140px
}

.content-wallet-timeline .transaction__provider {
    width: 220px;
    max-width: 220px
}

.content-wallet-timeline .transaction__provider .logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f8f9ff;
    margin-right: 8px
}

.content-wallet-timeline .transaction__provider .logo img {
    max-width: 90%;
    height: auto
}

.content-wallet-timeline .transaction__provider span {
    font-size: .75rem;
    color: #8c91b4
}

.content-wallet-timeline .transaction--inbound {
    padding-right: 33px
}

.content-wallet-timeline .transaction--inbound .transaction__body,
.content-wallet-timeline .transaction--inbound .transaction__header {
    width: calc(45% - 33px)
}

.content-wallet-timeline .transaction--inbound .transaction__body:before,
.content-wallet-timeline .transaction--inbound .transaction__header:before {
    left: auto;
    right: -60px
}

.content-wallet-timeline .transaction--inbound .transaction__body:after {
    left: auto;
    right: -55px;
    background-color: #ffa2a2
}

.content-wallet-timeline .transaction--inbound .transaction__header:after {
    left: auto;
    right: -55px;
    background-color: #ffa2a2
}

.content-wallet-timeline .transaction--inbound .transaction__item .transaction__infos {
    position: relative;
    right: 0;
    left: 0
}

.content-wallet-timeline .transaction--outbound {
    padding-left: 33px
}

.content-wallet-timeline .transaction--outbound:before {
    left: 45%
}

.content-wallet-timeline .transaction--outbound .transaction__body,
.content-wallet-timeline .transaction--outbound .transaction__header {
    width: calc(55% - 33px);
    margin-right: 0;
    margin-left: auto
}

.content-wallet-timeline .transaction--outbound .transaction__avatar {
    margin: 0 calc(.5em*2.5)
}

.content-wallet-timeline .transaction--outbound .transaction__item .transaction__infos {
    position: relative;
    right: 0;
    left: 0
}
